import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import { groupBy } from 'lodash'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { shallowEqual, useSelector } from 'react-redux'
import { ACTION_COLOR, SORT_BY } from 'station/constants/CampaignConstants'
import { getI18nTitleDataLength, i18nize } from 'utils/StringUtil'
import { getLabel } from 'utils/StoreUtils'
import { CAMPAIGN_STATUT_FOR_FILTER, CAMPAIGN_TYPES } from 'campaign/constants/CampaignConstants'
import { hasValue } from 'utils/NumberUtil'
import { getYearOrString } from 'utils/DateUtil'
import { mediumGrey } from 'utils/constants/ColorTheme'
import DtoCampaignWithStats from 'pgsse/dto/DtoPgsseCampaignWithStats'
import PgsseCampaignCard from './PgsseCampaignCard'

const AccordionDisplay = ({
    groupCard = [],
    title = '',
    color = '',
    openFirst = false,
}) => {
    return (
        <AccordionMUI defaultExpanded={openFirst}>
            <AccordionSummaryMUI style={{ backgroundColor: color }}>
                {title} {`(${groupCard.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, groupCard.length)})`}
            </AccordionSummaryMUI>
            <AccordionDetailsMUI style={{ padding: 0 }}>
                {groupCard}
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

AccordionDisplay.propTypes = {
    groupCard: PropTypes.arrayOf(PropTypes.element),
    title: PropTypes.string,
    color: PropTypes.string,
    openFirst: PropTypes.bool,
}

const GroupCampaignByStatus = ({
    campaigns = [],
}) => {
    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const grouped = groupBy(campaigns, 'status')
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(camp => {
            const contact = contacts.find(c => campaigns.contactCode === c.code)
            return (
                <Grid2 key={camp.name}>
                    <PgsseCampaignCard
                        campaign={camp}
                        contact={contact}
                    />
                </Grid2>
            )
        })
        return (
            <Grid2 key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={i18n[getLabel(CAMPAIGN_STATUT_FOR_FILTER, key) || 'notDefined']}
                    color={ACTION_COLOR[parseInt(key)] || mediumGrey}
                    openFirst={i === 0}
                />
            </Grid2>
        )
    })
}

GroupCampaignByStatus.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignWithStats)),
}

const GroupCampaignByReferent = ({
    campaigns = [],
}) => {
    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const grouped = groupBy(campaigns, c => hasValue(c.contactCode) ? getLabel(contacts, c.contactCode) : i18n.unknownReferent)
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(camp => {
            const contact = contacts.find(c => campaigns.contactCode === c.code)
            return (
                <Grid2 key={camp.name}>
                    <PgsseCampaignCard
                        campaign={camp}
                        contact={contact}
                    />
                </Grid2>
            )
        })
        return (
            <Grid2 key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={key}
                    openFirst={i === 0}
                />
            </Grid2>
        )
    })
}

GroupCampaignByReferent.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignWithStats)),
}

const GroupCampaignByDate = ({
    campaigns = [],
}) => {
    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const grouped = groupBy(campaigns, c => getYearOrString(c.startDate, i18n.thisYear))
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(camp => {
            const contact = contacts.find(c => campaigns.contactCode === c.code)
            return (
                <Grid2 key={camp.name}>
                    <PgsseCampaignCard
                        campaign={camp}
                        contact={contact}
                    />
                </Grid2>
            )
        })
        return (
            <Grid2 key={key} style={{ marginBottom: i === 0 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={key}
                    openFirst={i === keys.length - 1}
                />
            </Grid2>
        )
    }).reverse()
}

GroupCampaignByDate.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignWithStats)),
}

const GroupCampaignByType = ({
    campaigns = [],
}) => {
    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)
    const grouped = groupBy(campaigns, ({ campaignType = 4 }) => campaignType)
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(camp => {
            const contact = contacts.find(c => campaigns.contactCode === c.code)
            return (
                <Grid2 key={camp.name}>
                    <PgsseCampaignCard
                        campaign={camp}
                        contact={contact}
                    />
                </Grid2>
            )
        })
        const title = getLabel(i18nize(CAMPAIGN_TYPES), key) || i18n.unknownType
        return (
            <Grid2 key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={title}
                    openFirst={i === 0}
                />
            </Grid2>
        )
    })
}

GroupCampaignByType.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignWithStats)),
}


const CampaignTabs = ({
    campaigns = [],
    sortBy = 0,
    setSortBy = () => { },
}) => {
    return (
        <TabList
            onChangeTab={setSortBy}
            tabs={[{
                value: SORT_BY.STATUS,
                label: i18n.byStatus,
                icon: 'edit',
            }, {
                value: SORT_BY.DATE,
                label: i18n.byDate,
                icon: 'insert_invitation',
            }, {
                value: SORT_BY.REFERENT,
                label: i18n.byReferent,
                icon: 'person',
            }, {
                value: SORT_BY.TYPE,
                label: i18n.byCampaignType,
                icon: 'business_center',
            }]}
        >
            <Grid2 className='padding-1'>
                {!campaigns.length
                    ? (
                        <Grid2 style={{ padding: '10%' }}>
                            <Grid2 className='text-align-center'>
                                <i className='material-icons medium'>nature_people</i>
                            </Grid2>
                            <Grid2 className='center font-size-20'>{i18n.NoneResultForThisResearch}</Grid2>
                        </Grid2>
                    ) : <>
                        {sortBy === SORT_BY.STATUS && <GroupCampaignByStatus campaigns={campaigns} />}
                        {sortBy === SORT_BY.REFERENT && <GroupCampaignByReferent campaigns={campaigns} />}
                        {sortBy === SORT_BY.TYPE && <GroupCampaignByType campaigns={campaigns} />}
                        {sortBy === SORT_BY.DATE && <GroupCampaignByDate campaigns={campaigns} />}
                    </>
                }
            </Grid2>
        </TabList>
    )
}

CampaignTabs.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignWithStats)),
    sortBy: PropTypes.number,
    setSortBy: PropTypes.func,
}

export { CampaignTabs, GroupCampaignByStatus }