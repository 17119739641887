/* eslint-disable max-nested-callbacks */
import { Grid, Grid2 } from '@mui/material'
import CampaignAction from 'campaign/actions/CampaignAction'
import DtoCampaignFile from 'campaign/dto/DtoCampaignFile'
import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import ExportFileModal from 'components/modal/ExportFileModal'
import ProgressBar from 'components/progress/ProgressBar'
import ExportAction from 'export/actions/ExportAction'
import { orderBy, take } from 'lodash'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import { getFullDate } from 'utils/DateUtil'
import { formatData } from 'utils/ExportDataUtil'
import { getExecStatusIcon, getExecStatusLabel } from 'utils/JobUtils'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import QualityIntegrationOverviewAction from '../integrationOverview/actions/QualityIntegrationOverviewAction'
import OperationAction from '../operation/actions/OperationAction'
import { useParams } from 'react-router'
import AnalysisAction from 'quality/actions/AnalysisAction'
import { arrayLightToObject } from 'utils/ActionUtils'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import useAbortController from 'utils/customHook/useAbortController'
import { ControlTable, HEADER_CONFORM, HEADER_NON_CONFORM } from '../control/ControlAnalysis'

const LIST = 'LIST'
const DETAIL = 'DETAIL'

const List = ({
    campaignFiles = [],
    setPanel = () => { },
    setFile = () => { },
}) => {
    const {
        edilaboFiles,
    } = useSelector(store => ({
        edilaboFiles: store.CampaignReducer.edilaboFiles,
    }), shallowEqual)

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useActions(() => ({
        exportList: [{
            onClick: openExportModal,
            label: i18n.excel,
        }],
        links: take(orderBy(edilaboFiles, 'updateDate', 'desc'), 5),
    }), [])

    const filesFormated = campaignFiles.map(file => {
        const objExecution = { status: file.statusCode, date: file.date }
        const tooltipStatus = getExecStatusLabel(objExecution)
        return {
            fileLabel: file.nameFile,
            jobExecutionId: file.jobExecutionId,
            date: getFullDate(file.date),
            state: getExecStatusIcon(objExecution, 30, tooltipStatus),
            stations: file.nbStation,
            operations: file.nbOperation,
            analysis: file.nbAnalyse,
            parameters: file.nbParameter,
            nullValue: <i className='material-icons medium clickable' style={{ fontSize: '30px' }} onClick={() => {
                setPanel(DETAIL)
                setFile(file)
            }}
            >offline_pin</i>,
            timestamp: file.date,
        }
    })

    return (
        <>
            <Table
                title={i18n.importedFiles}
                data={orderBy(filesFormated, 'timestamp', 'desc')}
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: ['fileLabel', 'date', 'jobExecutionId', 'state', 'stations', 'operations', 'analysis', 'parameters', 'nullValue'] }}
                condensed
                sortable
                id='imported_files_table'
            />
            <ExportModal
                campaignFiles={campaignFiles}
                open={isExportModalOpen}
                onClose={closeExportModal}
            />
        </>
    )
}

List.propTypes = {
    campaignFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignFile)),
    setPanel: PropTypes.func,
    setFile: PropTypes.func,
}

const keysAnalysis = ['qualitometer', 'id', 'sample', 'operation', 'sampleDate', 'analysisDate', 'parameter', 'unit', 'result', 'remark', 'support', 'localization', 'lq', 'ld', 'saturationThreshold', 'qualification', 'status', 'fraction', 'labo', 'jobexecutionid', 'importDate', 'updateDate', 'updateLogin', 'numSampling', 'errors']
const specificFormat = { errors: v => v ? v.split(',') : [] }

const Detail = ({
    file = {},
    setPanel = () => { },
    setFile = () => { },
    improbabilityThreshold,
    impossibilityThreshold,
}) => {
    const {
        campaign,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const [progress, setProgress] = useState(0)
    const { value: isLoaded, setTrue: loaded } = useBoolean(false)
    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        setProgress(0)

        if (controllerRef.current.signal.aborted) {
            initController()
        }

        const controlObject = {
            conformitySandre: true,
            outlier: true,
            multiParam: true,
            improbabilityThreshold,
            impossibilityThreshold,
        }

        const filter = {
            jobExecutionId: file.jobExecutionId,
        }

        AnalysisAction.getControledAnalysis(controlObject, filter, controllerRef.current.signal)
            .catch(() => [])
            .then((list = []) => {
                setAnalysis(list.map(a => arrayLightToObject(a, keysAnalysis, specificFormat)))
            })
            .finally(loaded)

        return () => controllerRef.current.abort()
    }, [improbabilityThreshold, impossibilityThreshold, campaign.id, file.jobExecutionId])

    const formattedAnalysis = useMemo(() => {
        return analysis.map(a => ({ ...a, ...calculateThresholdResult(a) }))
    }, [analysis])

    const undefinedConformity = useMemo(() => formattedAnalysis.filter(a => a.errors.includes('UNDEFINED_CONFORMITY')), [formattedAnalysis])
    const conformData = useMemo(() => formattedAnalysis.filter(a => !a.errors.length), [formattedAnalysis])
    const nonConformData = useMemo(() => formattedAnalysis.filter(a => a.errors.length && !a.errors.includes('UNDEFINED_CONFORMITY')), [formattedAnalysis])

    return (
        <Card
            title={`${file.nameFile} - ${getFullDate(file.date)}`}
            actions={[{
                iconName: 'keyboard_backspace',
                onClick: () => {
                    setPanel(LIST)
                    setFile(undefined)
                },
            }]}
        >
            {!isLoaded && (
                <div style={{ padding: 10 }}>
                    <ProgressBar progress={progress} withMessage />
                </div>
            )}
            {isLoaded && (
                <Grid2 container spacing={2} padding={1}>
                    {nonConformData.length !== 0 && (
                        <Grid2 size={12}>
                            <ControlTable
                                title={i18n.nonConformAnalysis}
                                analysis={nonConformData}
                                headers={HEADER_NON_CONFORM}
                                defaultExpanded
                            />
                        </Grid2>
                    )}
                    {conformData.length !== 0 && (
                        <Grid2 size={12}>
                            <ControlTable
                                title={i18n.conforms}
                                analysis={conformData}
                                headers={HEADER_CONFORM}
                            />
                        </Grid2>
                    )}
                    {undefinedConformity.length !== 0 && (
                        <Grid2 size={12}>
                            <ControlTable
                                title={i18n.undefinedConformity}
                                analysis={undefinedConformity}
                                headers={HEADER_CONFORM}
                            />
                        </Grid2>
                    )}
                </Grid2>
            )}
        </Card>
    )
}

Detail.propTypes = {
    file: PropTypes.instanceOf(DtoCampaignFile),
    setPanel: PropTypes.func,
    setFile: PropTypes.func,
    improbabilityThreshold: PropTypes.number,
    impossibilityThreshold: PropTypes.number,
}

const ExportModal = ({
    open = false,
    onClose = () => { },
    campaignFiles = [],
}) => {
    const dispatch = useDispatch()
    const {
        campaign,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
    }), shallowEqual)

    const onExport = (type) => {
        if (!campaignFiles.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const data = campaignFiles.map(file => {
            const objExecution = { status: file.statusCode, date: file.date }
            const tooltipStatus = getExecStatusLabel(objExecution)
            return {
                fileLabel: file.nameFile,
                jobExecutionId: file.jobExecutionId,
                date: getFullDate(file.date),
                state: tooltipStatus,
                stations: file.nbStation,
                operations: file.nbOperation,
                analysis: file.nbAnalyse,
                parameters: file.nbParameter,
            }
        })
        const dataWithHeader = data.length ? [{ ...data[0], headers: Object.keys(data[0]) }, ...data.slice(1)] : []
        return dispatch(ExportAction.export(formatData(dataWithHeader), type, `${campaign.name} - ${i18n.file}`))
    }

    const tableExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => onExport('xlsx'),
        }, {
            type: i18n.csvFile,
            callback: () => onExport('csv'),
        }],
    }]

    return (
        <ExportFileModal
            open={open}
            onClose={onClose}
            data={tableExport}
        />
    )
}

ExportModal.propTypes = {
    open: PropTypes.boolean,
    onClose: PropTypes.func,
    campaignFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignFile)),
}

const QualityCampaignFileApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        campaign,
        campaignFiles,
        qualitometers,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignFiles: store.CampaignReducer.campaignFiles,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    const [searchStation, setSearchStation] = useState('')
    const [panel, setPanel] = useState(LIST)
    const [file, setFile] = useState()
    const [improbabilityThreshold, setImprobabilityThreshold] = useState()
    const [impossibilityThreshold, setImpossibilityThreshold] = useState()

    useTitle(() => [
        {
            title: i18n.quality,
            href: '/quality',
        },
        {
            title: i18n.campaigns,
            href: '/quality/campaign',
        },
        {
            title: campaign.name || id,
            href: `/quality/campaign/${id}/dashboard`,
        },
        {
            title: i18n.files,
            href: `/quality/campaign/${id}/file`,
        },
        ...(file ? [{
            title: file.nameFile,
            href: `/quality/campaign/${id}/file`,
        }] : []),
    ], [campaign, file])

    const {
        progress,
        isLoaded,
    } = useProgressDispatch(() => {
        return [
            dispatch(QualityAction.fetchQualitometersLight()),
            dispatch(QualityAction.fetchStatus()),
            dispatch(QualityAction.fetchQualifications()),
            dispatch(FractionAction.fetchFractions()),
            dispatch(SupportAction.fetchSupports()),
            dispatch(UnitAction.fetchUnits()),
            dispatch(OperationAction.fetchRemarks()),
            dispatch(QualityIntegrationOverviewAction.fetchAnalyseErrorTypes()),
            dispatch(CampaignAction.fetchCampaignFiles(id)),
        ]
    }, [])

    const filesFormat = useMemo(() => campaignFiles.map(f => {
        const labelSearch = f.qualitometers.map(code => {
            const qualito = qualitometers.find(q => q.code === code)
            return qualito && `${code}${qualito.name || ''}` || ''
        }).join('')
        return {
            ...f,
            stationSearch: searchAllCharacters(labelSearch),
        }
    }), [campaignFiles, qualitometers])

    const filesFiltered = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        const searchStationFormat = searchAllCharacters(searchStation)
        const searchFilter = hasValue(searchValueFormat) ? filesFormat.filter(({ nameFile }) => searchAllCharacters(nameFile).includes(searchValueFormat)) : filesFormat
        return hasValue(searchStationFormat) ? searchFilter.filter(f => f.stationSearch.includes(searchStationFormat)) : searchFilter
    }, [filesFormat, searchStation, searchValue])

    return (
        <Grid container sx={{ padding: '5 10' }}>
            <Grid item xs={12}>
                <Card>
                    <Grid container sx={{ padding: '10' }} spacing={2} alignItems='center'>
                        {panel === LIST && (
                            <>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.research}
                                        value={searchValue}
                                        onChange={setSearchValue}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.station}
                                        value={searchStation}
                                        onChange={setSearchStation}
                                    />
                                </Grid>
                            </>
                        )}
                        {panel === DETAIL && (
                            <>
                                <Grid item xs={3}>
                                    <ThresholdSelect
                                        title={i18n.improbability}
                                        selected={improbabilityThreshold}
                                        onChange={setImprobabilityThreshold}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ThresholdSelect
                                        title={i18n.impossibility}
                                        selected={impossibilityThreshold}
                                        onChange={setImpossibilityThreshold}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {
                    !isLoaded && (
                        <div style={{ marginTop: '5' }}>
                            <Card cardStyle={{ padding: '10' }}>
                                <ProgressBar withMessage progress={progress} />
                            </Card>
                        </div>
                    )
                }
                {
                    isLoaded && panel === LIST && (
                        <List
                            campaignFiles={filesFiltered}
                            setPanel={setPanel}
                            setFile={setFile}
                        />
                    )
                }
                {
                    isLoaded && panel === DETAIL && (
                        <Detail
                            file={file}
                            setPanel={setPanel}
                            setFile={setFile}
                            improbabilityThreshold={improbabilityThreshold}
                            impossibilityThreshold={impossibilityThreshold}
                        />
                    )
                }
            </Grid>
        </Grid>
    )
}

export default QualityCampaignFileApp