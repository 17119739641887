import React, { useEffect, useMemo, useState } from 'react'
import { push } from '@lagunovsky/redux-react-router'
import ContactDto from 'referencial/components/contact/dto/LocationApiDataGouvDto'
import i18n from 'simple-react-i18n'
import { compact, keys, orderBy, some, uniq, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { H_HYDRO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import MessageCard from '../../../components/card/MessageCard'
import ProgressCard from '../../../components/card/ProgressCard'
import CartographyPanel from '../../../components/map/CartographyPanel'
import HomeAction from '../../../home/actions/HomeAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import StationStatisticPanel from '../../../station/components/dashboard/component/keyfigure/StationStatisticPanel'
import { MAP, STATION_LIST, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import DtoFilter from '../../../station/dto/DtoFilter'
import { getDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getBookmarks, getStationType, hasLocalisationStation } from '../../../utils/StationUtils'
import { arrayOf, getLabel, getObjectLabel } from '../../../utils/StoreUtils'
import { getI18nTitleData, searchAllCharacters } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import { WhiteCard } from 'components/styled/Card'
import CampaignPortletPanel from 'campaign/components/CampaignPortletPanel'
import { getCampaignIcon, getCampaignProgress, getProgressBar } from 'campaign/utils/CampaignUtils'
import { getColorCampaign } from 'utils/CampaignUtil'
import StationFilterFields from 'station/components/search/StationFilterFields'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import StationAction from 'station/actions/StationAction'
import NetworkLinkDto from 'referencial/components/network/dto/NetworkLinkDto'
import NetworkDto from 'referencial/components/network/dto/NetworkDto'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'
import AddHydroStepper from '../addHydro/AddHydroStepper'
import DtoCampaignProgress from 'campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from 'campaign/dto/DtoStationCampaign'
import DtoUserBookmark from 'administration/components/user/dto/DtoUserBookmark'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { Box, Grid2 } from '@mui/material'
import Icon from 'components/icon/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { CardTable, sortRows } from 'components/datatable/NewTable'
import { nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import useBoolean from 'utils/customHook/useBoolean'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import SimpleTabList from 'components/list/SimpleTabList'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'

const DEFAULT_HEADERS = ['nullValue', 'nullValue2', 'code', 'city', 'name', 'creationDate', 'closeDate', 'stationType']
const CAMPAIGN_HEADERS = ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent']
const STATION_HEADERS = ['code', 'city', 'name', 'creationDate', 'closeDate', 'stationType']

const LegendPanel = () => (
    <Grid2 container spacing={1} style={{ padding: '4px' }} alignItems='center'>
        <Grid2 size='auto'>
            <Icon icon='wifi' />
        </Grid2>
        <Grid2 size='auto'>
            {i18n.automaticUpdate}
        </Grid2>
    </Grid2>
)

const TablePanel = ({
    stations = [],
}) => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
        filterResults,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        filterResults: store.StationReducer.filterResults,
    }), shallowEqual)

    const searchValues = selectedSearchValues.hydrometry
    const initialSort = searchValues?.columnName && { column: searchValues.columnName, sort: searchValues.sort }

    const filterHeaders = keys(filterResults?.[0] || {}).filter(d => d !== 'id')
    const headers = [...DEFAULT_HEADERS, ...filterHeaders]

    const onTableSort = sort => {
        dispatch(AdministrationAction.setSelectedSearchValues(STATION_TYPE_NAME.hydrometry, { stations: sortRows(stations, sort), sort }))
    }

    const onSaveSearchValue = () => {
        dispatch(AdministrationAction.setSelectedSearchValues(STATION_TYPE_NAME.hydrometry, { stations: sortRows(stations, initialSort), initialSort }))
    }

    useEffect(() => {
        onSaveSearchValue(stations)
    }, [stations])

    return (
        <CardTable
            title={i18n.hydrometricStations}
            actions={[{
                icon: 'info_outlined',
                tooltip: <LegendPanel />,
            }]}

            rows={stations}
            headers={headers}

            rowsPerPageOptions={nbPerPageLabelMedium}
            onClickRow={({ id }) => dispatch(push(`/station/hydrometry/${id}`))}
            defaultSort={initialSort}
            onSort={onTableSort}
        />
    )
}

TablePanel.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({})),
}

const CartoPanel = ({
    stations = [],
}) => {
    const filteredStations = stations.map(s => ({ ...s, code: s.code.value, name: s.name.value })).filter(s => s && (hasLocalisationStation(s) || s.townCode))

    return filteredStations.length > 20000 ? (
        <MessageCard >{i18n.tooMuchDataToShow}</MessageCard>
    ) : (
        <WhiteCard
            title={(
                <>
                    <span>{i18n.hydrometricStations}</span>
                    <span style={{ fontWeight: 'normal' }}>{` (${stations.length} ${getI18nTitleData(i18n.element, i18n.elements, stations)})`}</span>
                </>
            )}
            round
        >
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType={STATION_TYPE_NAME.hydrometry}
                stationsPoints={filteredStations}
                stationsPanelTitle={i18n.stations}
                heightToSubstract={450}
            />
        </WhiteCard>
    )
}

CartoPanel.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({})),
}

const HydrometersDashboardApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        hydrometricStations,
        hydrometryCampaigns,
        hydrometryCampaignsProgress,
        citiesIndex,
        cities,
        globalResearch,
        userBookmarks,
        contacts,
        filterResults,
        contributorLinks,
        hydrometry,
        accountUser,
        accountHabilitations,
        networks,
        networkLinks,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
        hydrometryCampaignsProgress: store.HydrometryReducer.hydrometryCampaignsProgress,
        citiesIndex: store.CityReducer.citiesIndex,
        cities: store.CityReducer.cities,
        globalResearch: store.HomeReducer.globalResearch,
        userBookmarks: store.UserReducer.userBookmarks,
        contacts: store.ContactReducer.contacts,
        filterResults: store.StationReducer.filterResults,
        contributorLinks: store.StationReducer.contributorLinks,
        hydrometry: store.AdministrationReducer.hydrometry,
        accountUser: store.AccountReducer.accountUser,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        networks: store.NetworkReducer.networks,
        networkLinks: store.HydrometryReducer.networkLinks,
    }), shallowEqual)

    const [filter, setFilter] = useState(() => {
        const {
            filter: filterCode = -1,
            searchValue = '',
        } = hydrometry || {}
        return {
            filter: filterCode,
            searchValue: globalResearch || searchValue,
        }
    })

    const {
        value: isAddStationPopupOpen,
        setTrue: openAddStationPopup,
        setValue: setAddStationPopupOpen,
    } = useBoolean(false)

    const referentType = useApplicationSetting('contributorTypeAdministrator', value => parseInt(value) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR)
    const operatorType = useApplicationSetting('contributorTypeOperator', value => parseInt(value) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR)

    const [referents, operators] = useMemo(() => ([
        contributorLinks.filter(l => l.contributorType === referentType),
        contributorLinks.filter(l => l.contributorType === operatorType),
    ]), [contributorLinks, operatorType, referentType])

    useEffect(() => {
        if (!componentHasHabilitations(H_HYDRO_DASHBOARD)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
        }
    }, [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => compact([
        dispatch(HydrometryAction.loadHydrometersTable()),
        !!globalResearch && dispatch(HomeAction.updateGlobalResearch()),
        dispatch(StationAction.fetchAllContributors(STATION_TYPE_CONSTANT.hydrometry)),
        !cities.length && dispatch(CityAction.fetchCities()),
        !networks.length && dispatch(NetworkAction.fetchNetworks()),
        !networkLinks.length && dispatch(HydrometryAction.fetchHydrometryNetworkLinks()),
    ]))

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: i18n.dashboard,
        href: 'hydrometry',
    }], [])

    const campaigns = useMemo(() => {
        const currentCampaigns = hydrometryCampaigns.reduce((acc, c) => {
            const { nbStation = 0, nbStationValidated = 0 } = hydrometryCampaignsProgress.find(cp => cp.id === c.id) || {}

            if (nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2) {
                return [
                    ...acc,
                    c,
                ]
            } return acc
        }, [])

        return currentCampaigns.map(campaign => {
            const {
                nbStation,
                progress: campaignProgress = 0,
                progressTotal = 0,
            } = hydrometryCampaignsProgress.find(p => p.id === campaign.id) || {}

            const progressValue = getCampaignProgress(campaignProgress, progressTotal)
            return {
                id: campaign.id,
                qualificationTableColor: {
                    color: getColorCampaign(false, progressValue, 2),
                },
                nullValue: getCampaignIcon(campaign.campaignType, 'small'),
                name: campaign.name,
                nbStations: nbStation,
                startDate: campaign.beginningApplication,
                progression: getProgressBar(campaignProgress, progressTotal, progressValue, i18n.analyseOn, i18n.analysisOn),
                referent: getLabel(contacts, campaign.contactCode, 'mnemonique'),
                contactCode: campaign.contactCode,
            }
        })
    }, [contacts, hydrometryCampaigns, hydrometryCampaignsProgress])

    const hydrometricStationsFormatted = useMemo(() => orderBy(hydrometricStations, 'name').map(station => {
        const filterValuesFound = filterResults.find(fd => fd.id === station.id) || {}
        const filterValues = keys(filterValuesFound).reduce((acc, key) => {
            const value = filterValuesFound[key]
            if (key !== 'id') {
                return { ...acc, [key]: { value: value !== 'null' ? value : '' } }
            } return acc
        }, {})

        const referentLink = referents.find(link => link.idStation === station.id) || {}
        const operatorLink = operators.find(link => link.idStation === station.id) || {}

        return {
            ...station,

            code: station.code,
            nullValue: getBookmarks(station.code, getUserBookmarksByStationType(userBookmarks, 'hydrometry', station.code)),
            nullValue2: hasValue(station.jobExecutionId) && <i className='material-icons little'>wifi</i>,
            city: getObjectLabel(citiesIndex[station.townCode], 'labelWithCode'),
            name: station.name || '',
            creationDate: getDate(station.creationDate),
            closeDate: getDate(station.closeDate),
            stationType: station.stationType ? getStationType(parseInt(station.stationType)).libelle : '',
            referentId: referentLink.idContributor,
            operatorId: operatorLink.idContributor,
            headers: STATION_HEADERS,
            ...filterValues,
        }
    }), [citiesIndex, filterResults, hydrometricStations, operators, referents, userBookmarks])

    const containsSearchValue = (station, search) => some(STATION_HEADERS, head => searchAllCharacters(station[head]).includes(search))

    const filteredStations = useMemo(() => {
        const {
            searchValue,
            filter: filterCode = -1,
            filterResults: resultFilter = [],
            network,
            referentIds = [],
            operatorIds = [],
        } = filter

        const ids = resultFilter.map(p => p.id)
        const search = searchAllCharacters(searchValue)
        const filterSearch = search ? hydrometricStationsFormatted.filter(s => containsSearchValue(s, search)) : hydrometricStationsFormatted
        const filterStation = filterCode !== -1 ? filterSearch.filter(p => ids.includes(p.id)) : filterSearch
        const filterNetwork = hasValue(network) ? filterStation.filter(p => networkLinks.filter(n => n.idStation === p.id).some(n => n.idNetwork === network)) : filterStation
        const filterReferent = referentIds.length ? filterNetwork.filter(p => referentIds.includes(p.referentId)) : filterNetwork
        const filterOperator = operatorIds.length ? filterReferent.filter(p => operatorIds.includes(p.operatorId)) : filterReferent

        return filterOperator
    }, [filter, hydrometricStationsFormatted, networkLinks])

    useActions(() => {
        const exportAction = {
            export: () => {
                return {
                    data: filteredStations,
                    exportType: 'xlsx',
                    titleFile: i18n.hydrometricStations,
                }
            },
        }
        if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
            return {
                ...exportAction,
                new: openAddStationPopup,
            }
        }
        return exportAction
    }, [filteredStations])

    const networksHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^HYDRO_NETWORK_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const contributorsHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^HYDRO_CONTRIB_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const networksContributors = networks.filter(({ contributorCode }) => contributorsHabilitation.includes(contributorCode))
    const networksId = [...networkLinks.map(({ idNetwork }) => idNetwork), ...networksHabilitation]
    const networklistFound = uniq(networksId).map(id => networks.find(n => n.id === id)).filter(n => !!n)
    const piezoNetworks = uniqBy([...networksContributors, ...networklistFound], 'id')

    return (
        <Box sx={{ marginRight: '5px', paddingBottom: '100px' }}>
            {!isLoaded && <ProgressCard progress={progress} withMessage />}
            {isLoaded && (
                <Grid2 container spacing={2}>
                    <Grid2 size={9}>
                        <CampaignPortletPanel
                            campaigns={campaigns}
                            stationType={STATION_TYPE_NAME.hydrometry}
                            tableHeader={{ headers: CAMPAIGN_HEADERS }}
                            noHeader
                            customHeaders={{
                                name: i18n.campaign,
                            }}
                            round
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <StationStatisticPanel stationType={STATION_TYPE_NAME.hydrometry} round />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTabList
                            defaultTab={STATION_LIST}
                            tabs={[
                                {
                                    constant: STATION_LIST,
                                    label: i18n.table,
                                    icon: 'list',
                                },
                                {
                                    constant: MAP,
                                    label: i18n.map,
                                    icon: 'map',
                                },
                            ]}
                            headerComponent={(
                                <StationFilterFields
                                    defaultFilter={filter}
                                    onValidate={setFilter}
                                    stationType={STATION_TYPE_NAME.hydrometry}
                                    contributorLinks={contributorLinks}
                                    stations={filteredStations}
                                    networklist={piezoNetworks}
                                />
                            )}
                            noPadding
                        >
                            {tab => (
                                <>
                                    {tab === STATION_LIST && <TablePanel stations={filteredStations} />}
                                    {tab === MAP && <CartoPanel stations={filteredStations} />}
                                </>
                            )}
                        </SimpleTabList>
                    </Grid2>
                </Grid2>
            )}
            <AddHydroStepper
                isOpen={isAddStationPopupOpen}
                setIsOpen={setAddStationPopupOpen}
            />
        </Box>
    )
}

HydrometersDashboardApp.propTypes = {
    hydrometricStations: arrayOf(DtoHydrometricStation),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    globalResearch: PropTypes.string,
    filters: arrayOf(DtoFilter),
    push: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    loadHydrometersTable: PropTypes.func,
    updateGlobalResearch: PropTypes.func,
    setTitle: PropTypes.func,
    createHydrometer: PropTypes.func,
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributorLink)),
    contributorsIndex: PropTypes.objectOf(PropTypes.instanceOf(ContributorItem)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),
    network: PropTypes.objectOf(PropTypes.instanceOf(NetworkDto)),
    settings: PropTypes.objectOf(PropTypes.instanceOf(SieauParameterDto)),
    filterResults: PropTypes.arrayOf(Object),
    hydrometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    fetchAllContributors: PropTypes.func,
    userBookmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserBookmark)),
}

export default HydrometersDashboardApp
