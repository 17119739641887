import DtoHydroMeasureLight from '../../dto/chronicMeasures/DtoHydroMeasureLight'
import PropTypes from 'prop-types'
import { isUndefined, maxBy, meanBy, minBy, range, reverse, round, sumBy, zip } from 'lodash'
import moment from 'moment/moment'
import DtoMonthValues from '../../../piezometry/components/suiviTable/dto/DtoMonthValues'
import i18n from 'simple-react-i18n'
import DtoYearValues from '../../../piezometry/components/suiviTable/dto/DtoYearValues'
import React, { useState } from 'react'
import { setModal } from '../../../utils/FormUtils'
import { BLUE, GREY, ORANGE, RED, WHITE } from '../../../components/constants/ColorConstant'
import useActions from '../../../utils/customHook/useActions'
import { getLinks } from '../../../utils/StationUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { getBeginingOfTheYear, getEndOfTheYear, getFullDate } from '../../../utils/DateUtil'
import ExportAction from '../../../export/actions/ExportAction'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import VirtualizedTable from '../../../components/datatable/virtualizedTable/VirtualizedTable'
import { Card } from '@mui/material'
import { CardTitle } from '../../../components/card/NewCard'
import MessageCard from '../../../components/card/MessageCard'
import { HISTORIC } from '../../../utils/constants/MeasureConstants'
import StatisticalIndicatorsLegend from '../../../station/components/legends/StatisticalIndicatorsLegend'
import { getLabel } from '../../../utils/StoreUtils'
import { getQualifications, getStatuses } from '../../../utils/QualityUtils'
import DtoHydroStats from '../../dto/chronicMeasures/DtoHydroStats'
import { MONTHS } from '../../constants/HydrometryConstants'

const getAnnualData = (measures) => {
    const allDaysByMonth = MONTHS.map(() => {
        return new Array(31).fill(undefined)
    })

    measures.map(measure => {
        const date = moment(measure.date)
        allDaysByMonth[date.month()][date.date() - 1] = measure.value
    })

    const coloredData = allDaysByMonth.map(monthDays => {
        const coloredMonthDays = monthDays.map(value => {
            return { value, color: WHITE }
        })
        const dataWithValues = coloredMonthDays.filter(data => data.value && !isUndefined(data.value))
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredMonthDays, 'value').value
            const minValue = minBy(coloredMonthDays, 'value').value
            coloredMonthDays.map(data => {
                if (data.value === maxValue) {
                    data.color = BLUE
                }
                if (data.value === minValue) {
                    data.color = RED
                }
                return data
            })
            coloredMonthDays.push({ value: round(minBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredMonthDays.push({ value: round(meanBy(dataWithValues, 'value'), 3), color: ORANGE })
            coloredMonthDays.push({ value: round(maxBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredMonthDays.push({ value: round(sumBy(dataWithValues, 'value'), 3), color: ORANGE })
        } else {
            range(4).map(() => coloredMonthDays.push({ value: undefined, color: ORANGE }))
        }
        return coloredMonthDays
    })

    const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
        .map((months, index) => new DtoMonthValues({ value: index + 1, color: GREY }, months))

    values[31] = Object.assign({}, values[31], {
        day: { value: i18n.min, color: GREY },
        min: { value: round(values[31].min.value, 3), color: RED },
        mean: undefined,
        max: undefined,
        sum: undefined,
    })
    values[32] = Object.assign({}, values[32], {
        day: { value: i18n.mean, color: GREY },
        min: undefined,
        mean: { value: round(values[32].mean.value, 3), color: ORANGE },
        max: undefined,
        sum: undefined,
    })
    values[33] = Object.assign({}, values[33], {
        day: { value: i18n.max, color: GREY },
        min: undefined,
        mean: undefined,
        max: { value: round(values[33].max.value, 3), color: BLUE },
        sum: undefined,
    })
    values[34] = Object.assign({}, values[34], {
        day: { value: i18n.sum, color: GREY },
        min: undefined,
        mean: undefined,
        max: undefined,
        sum: { value: round(values[34].sum.value, 3), color: ORANGE },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const getHistoricData = (measures, minYear, maxYear) => {
    const allDaysByYear = range(minYear, maxYear+1).map(year => {
        return {
            year,
            data: new Array(366).fill(undefined),
        }
    })

    measures.map(measure => {
        const correspondingYearData = allDaysByYear.find(year => parseInt(year.year) === parseInt(moment(measure.date).year())).data
        const bisextileDate = moment(measure.date).year(2016)
        correspondingYearData[bisextileDate.dayOfYear() - 1] = measure.value
    })

    const coloredData = allDaysByYear.map(yearData => {
        const coloredYearData = yearData.data.map(value => {
            return { value, color: WHITE }
        })
        const dataWithValues = coloredYearData.filter(data => data.value)
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredYearData, 'value').value
            const minValue = minBy(coloredYearData, 'value').value
            coloredYearData.map(data => {
                if (data.value === maxValue) {
                    data.color = BLUE
                }
                if (data.value === minValue) {
                    data.color = RED
                }
                return data
            })
            coloredYearData.push({ value: round(minBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredYearData.push({ value: round(meanBy(dataWithValues, 'value'), 3), color: ORANGE })
            coloredYearData.push({ value: round(maxBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredYearData.push({ value: round(sumBy(dataWithValues, 'value'), 3), color: ORANGE })
        } else {
            range(4).map(() => coloredYearData.push({ value: undefined, color: ORANGE }))
        }
        return coloredYearData
    })

    const values = Reflect.apply(zip, {}, reverse(coloredData)) // transpose matrice
        .map((days, index) => new DtoYearValues({ value: moment('2016-01-01').dayOfYear(index + 1).format('DD/MM'), color: GREY }, days))

    values[366] = Object.assign({}, values[366], {
        day: { value: i18n.min, color: GREY },
        min: { value: round(values[366].min.value, 3), color: RED },
        mean: undefined,
        max: undefined,
        sum: undefined,
    })
    values[367] = Object.assign({}, values[367], {
        day: { value: i18n.mean, color: GREY },
        min: undefined,
        mean: { value: round(values[367].mean.value, 3), color: ORANGE },
        max: undefined,
        sum: undefined,
    })
    values[368] = Object.assign({}, values[368], {
        day: { value: i18n.max, color: GREY },
        min: undefined,
        mean: undefined,
        max: { value: round(values[368].max.value, 3), color: BLUE },
        sum: undefined,
    })
    values[369] = Object.assign({}, values[368], {
        day: { value: i18n.sum, color: GREY },
        min: undefined,
        mean: undefined,
        max: undefined,
        sum: { value: round(values[369].sum.value, 3), color: ORANGE },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const displayLegend = () => {
    setModal({
        title: i18n.legend,
        actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
        content: (
            <StatisticalIndicatorsLegend />
        ),
    })
}

const HydroSuiviTable = ({
    measures,
    horizon,
    dataType,
    dataLoaded,
    minYear,
    maxYear,
    selectedYear,
}) => {
    const {
        hydrometricStation,
        hydrometricStations,
        typeEnvironmentModels,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)
    const dispatch = useDispatch()
    const [openExportModal, setOpenExportModal] = useState(false)

    useActions(() => hydrometricStation.id ? ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
        links: getLinks(hydrometricStation),
        arrowNav: getStationArrowNav(STATION_TYPE_NAME.hydrometry, hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/hydroSuiviTable`))),
    }) : ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
    }), [hydrometricStation, hydrometricStations])


    if (dataLoaded) {
        if (measures.length !== 0) {
            const data = horizon === HISTORIC ? getHistoricData(measures, minYear, maxYear) : getAnnualData(measures)
            if (data.nbMeasures === 0) {
                return (
                    <>
                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                        {/* {this.state.openExportModal && this.getExportModal(data)}*/}
                    </>
                )
            }
            const exportData = (datas, type) => {
                if (!measures.length) {
                    return dispatch(ToastrAction.error(i18n.noDataToExport))
                }
                const measuresFiltered = horizon === 'historic' ? measures : measures.filter(m => m.date >= getBeginingOfTheYear(selectedYear) && m.date <= getEndOfTheYear(selectedYear))
                if (!measuresFiltered.length) {
                    return dispatch(ToastrAction.error(i18n.noDataToExport))
                }
                const exportDatas = measuresFiltered.map(line => {
                    return {
                        ...line,
                        headers: ['code', 'dataType', 'name', 'date', 'value', 'status', 'qualification'],
                        dataType: dataType.label,
                        date: getFullDate(line.date),
                        code: hydrometricStation.code,
                        name: hydrometricStation.name,
                        qualification: getLabel(getQualifications(), line.qualification),
                        status: getLabel(getStatuses(), line.status),
                    }
                })
                const dataFormatted = formatData(exportDatas, type)
                return dispatch(ExportAction.export(dataFormatted, type, i18n.graphicTracking))
            }

            const getExportModal = () => {
                const tableExport = [{
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.csvFile,
                        callback: () => exportData(data, 'csv'),
                    },
                    {
                        type: i18n.excelFile,
                        callback: () => exportData(data, 'xlsx'),
                    }],
                }]

                const exportModel = typeEnvironmentModels.map((model) => {
                    const fileNameSplit = model.split('.')
                    const name = fileNameSplit.slice(0, -1).join('')
                    const ext = fileNameSplit[fileNameSplit.length - 1]
                    return {
                        name,
                        formats: [{
                            type: getModelFileType(ext),
                            callback: () => exportModelFile({
                                stationId: hydrometricStation.id.toString(),
                                stationCode: hydrometricStation.code,
                                stationType: hydrometricStation.typeName,
                                environmentModels: typeEnvironmentModels,
                                filenameModelExport: model,
                            }),
                        }],
                    }
                })

                return openExportModal && (
                    <ExportFileModal
                        open={openExportModal}
                        onClose={() => setOpenExportModal(false)}
                        data={[ ...tableExport, ...exportModel ]}
                    />
                )
            }

            return (
                <>
                    <Card>
                        <CardTitle
                            title={`${i18n.dataFollowUp} (${data.nbMeasures} ${i18n.measures})`}
                            actions={[{
                                icon: 'info',
                                onClick: displayLegend,
                                tooltip: i18n.legend,
                            }]}
                        />
                        <VirtualizedTable
                            data={data.values}
                            headers={data.values[0].headers}
                            columnWidth={100}
                            tableHeight={'100%'}
                        />
                    </Card>
                    {openExportModal && getExportModal(measures)}
                    {/* {this.state.openExportModal && this.getExportModal(data)}*/}
                </>
            )
        }
        return (
            <MessageCard>{i18n.noDataToDisplay}</MessageCard>
        )
    }
    return null
}

HydroSuiviTable.propTypes = {
    measures: PropTypes.arrayOf(DtoHydroMeasureLight),
    horizon: PropTypes.string,
    dataType: PropTypes.arrayOf(DtoHydroStats),
    dataLoaded: PropTypes.bool,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    selectedYear: PropTypes.number,
}

export default HydroSuiviTable
