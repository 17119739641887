import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import useTitle from '../../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PerimeterDto from '../../dto/PerimeterDto'
import useActions from '../../../utils/customHook/useActions'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import PerimetersStepper from './components/PerimetersStepper'
import { capitalize, compact, maxBy, orderBy } from 'lodash'
import PerimetersThunk from '../../action/PerimetersThunk'
import { NEW_PERIMETER, PERIMETERS_STATES_COLORS } from '../constants/PerimetersConstants'
import useSandreList from '../../../utils/customHook/useSandreList'
import ContributorThunk from '../../../referencial/components/contributor/actions/ContributorThunk'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import PerimetersImportStepper from '../perimeters/components/PerimetersImportStepper'
import { exportFile, formatData } from '../../../utils/ExportDataUtil'
import { push } from '@lagunovsky/redux-react-router'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import UpdatePanel from 'components/updatePanel/UpdatePanel'
import ReferencialAction from 'referencial/action/ReferencialAction'
import PerimeterStatus from '../../../components/PerimeterStatus'
import Card from 'components/card/Card'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { useParams } from 'react-router'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import PerimetersAction from 'perimeters/action/PerimetersAction'
import { CardTable } from 'components/datatable/NewTable'
import ExportAction from 'export/actions/ExportAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getFullDateMini } from 'utils/DateUtil'

const PERIMETER_HEADER = ['name', 'type', 'zoning', 'cities', 'nbParcels', 'nbOwners', 'nbOperators', 'status']
const PARCELS_HEADER = ['perimeter', 'identifier', 'code', 'section', 'parcel', 'area', 'ownerIndemnity', 'operatorIndemnity', 'subdivision', 'classOfDirt', 'owners', 'ownersGroup', 'city', 'address', 'hypothecaryState']
const OWNERS_HEADER = ['type', 'name', 'firstname', 'comment', 'birthdate', 'placeOfBirth', 'address', 'additionalAddress', 'postalCode', 'cityCode', 'usualCityName', 'propertyType']

const PerimetersTable = ({
    perimeters = [],
    setPerimeter,
    setDeletePerimeterId,
    readMode,
}) => {
    const {
        selectedSearchValues,
        citiesIndex,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const { perimeter: searchValues } = selectedSearchValues
    const perimeterTypes = useSandreList('PERIMETRES.TYPE')

    const dispatch = useDispatch()

    const lexiconStatus = useSandreList('PARCELLES.STATUT')

    const perimetersFormated = perimeters.map(perimeter => {
        const perimeterCities = perimeter.cities?.map(p => capitalize(citiesIndex[p]?.name))

        return {
            perimeterId: perimeter.perimeterId,
            folderId: perimeter.folderId,
            name: perimeter.name,
            type: perimeterTypes.find(pt => pt.code === perimeter.perimeterType)?.name || perimeter.perimeterType || '',
            perimeterType: perimeter.perimeterType,
            zoning: perimeter.zoning,
            cities: perimeterCities?.join(', ') ?? '',
            nbParcels: perimeter.parcels,
            nbOwners: perimeter.ownersNumber,
            nbOperators: '',
            status: (
                <PerimeterStatus
                    color={PERIMETERS_STATES_COLORS[perimeter.status] || PERIMETERS_STATES_COLORS[0]}
                    label={lexiconStatus.find(s => s.code === perimeter.status)?.name ?? ''}
                />
            ),
        }
    })

    const initialSort = searchValues?.columnName && { column: searchValues.columnName, sort: searchValues.sort }

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(perimetersFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'perimeter', {
                stations: sortedStations,
                columnName,
                sort,
            }))
        }
    }

    const exportData = () => exportFile({
        data: perimeters.length ? perimeters.map(perimeter => {
            const perimeterCities = perimeter.cities?.map(p => capitalize(citiesIndex[p]?.name))

            return {
                identifier: { value: perimeter.perimeterId },
                name: { value: perimeter.name || '' },
                type: perimeterTypes.find(pt => pt.code === perimeter.perimeterType)?.name || perimeter.perimeterType || '',
                zoning: { value: perimeter.zoning || '' },
                cities: { value: perimeterCities?.join(', ') ?? '' },
                nbParcels: { value: perimeter.parcels.toString() },
                nbOwners: { value: perimeter.ownersNumber.toString() },
                nbOperators: { value: '0' },
                status: { value: lexiconStatus.find(s => s.code === perimeter.status)?.name ?? '' },
                headers: ['identifier', ...PERIMETER_HEADER],
            }
        }) : [],
        exportType: 'xlsx',
        titleFile: i18n.perimeters,
    })

    return (
        <CardTable
            title={i18n.perimeters}
            actions={[{
                icon: 'download',
                tooltip: i18n.export,
                onClick: exportData,
            }, {
                icon: 'note_add',
                tooltip: i18n.add,
                onClick: () => {
                    setPerimeter({ perimeterId: NEW_PERIMETER })
                },
                displayed: !readMode,
            }]}

            rows={perimetersFormated}
            rowsPerPageOptions={nbPerPageLabel}
            defaultSort={initialSort}
            onClickRow={setPerimeter}
            lineActions={[
                { icon: 'delete', onClick: (p) => setDeletePerimeterId(p.perimeterId), displayed: !readMode },
            ]}
            onSort={onTableSort}
            headers={PERIMETER_HEADER}
            headersLabel={{
                nbParcels: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbParcelsReturnLine}</span>),
                nbOwners: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOwnersReturnLine}</span>),
                nbOperators: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOperatorsReturnLine}</span>),
            }}
        />
    )
}

PerimetersTable.propTypes = {
    perimeters: PropTypes.arrayOf(PerimeterDto),
    setPerimeter: PropTypes.func,
    setDeletePerimeterId: PropTypes.func,
    readMode: PropTypes.bool,
}

const PerimeterDashboard = () => {
    const { id } = useParams()

    const {
        cities,
        contributors,
        perimetersFolder,
        perimeters,
        sandreCodes,
        perimeterFolderLastEvent,
        mortgages,
        privateOwners,
        companyOwners,
        citiesIndex,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        perimeters: store.PerimetersReducer.perimeters,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        perimeterFolderLastEvent: store.PerimetersReducer.perimeterFolderLastEvent,
        mortgages: store.PerimetersReducer.mortgages,
        privateOwners: store.PerimetersReducer.perimetersFolderPrivateOwners,
        companyOwners: store.PerimetersReducer.perimetersFolderCompanyOwners,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const parsedId = parseInt(id)

    const lexiconStatus = useSandreList('PARCELLES.STATUT')
    const lexiconStates = useSandreList('PARCELLES.ETAT')
    const lexiconOwnerTypes = useSandreList('PARCELLES.TYPE_PROPRIETE')

    const [perimeterFolder, setPerimeterFolder] = useState({})

    const [readMode, setReadMode] = useState(true)
    const [importStepperIsOpen, setImportStepperIsOpen] = useState(false)
    const [perimeter, setPerimeter] = useState({})
    const [deletePerimeterId, setDeletePerimeterId] = useState()

    const [folderParcels, setFolderParcels] = useState([])

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        dispatch(PerimetersThunk.getPerimeters(id)),
        !cities.length && dispatch(CityAction.fetchCities()),
        !perimetersFolder.length && dispatch(PerimetersThunk.getPerimetersFolders()),
        !sandreCodes.length && dispatch(ReferencialAction.fetchSandreCodes()),
        !contributors.length && dispatch(ContributorThunk.fetchContributors()),
        !perimeterFolderLastEvent?.perimeterFolderId && dispatch(PerimetersThunk.getPerimeterFolderLastEvent(parsedId)),
        !mortgages.length && dispatch(PerimetersThunk.getMortgages()),
    ]), [])

    const findedPerimeterFolder = useMemo(() => perimetersFolder.find(pf => pf.folderId === parsedId) || {}, [parsedId, perimetersFolder])

    useEffect(() => {
        if (perimetersFolder.length) {
            setPerimeterFolder(findedPerimeterFolder)
        }
    }, [findedPerimeterFolder, perimetersFolder.length])

    useEffect(() => {
        dispatch(PerimetersThunk.getPerimetersFolderPrivateOwners(parsedId))
        dispatch(PerimetersThunk.getPerimetersFolderCompanyOwners(parsedId))
        dispatch(PerimetersAction.getFolderParcels(parsedId)).then(setFolderParcels)
    }, [parsedId])

    useTitle(() => [{
        title: i18n.perimetersFolder,
        href: 'perimeter',
    }, {
        title: perimeterFolder.folderId ? perimeterFolder.name : id,
        href: `perimeter/${id}/dashboard`,
    }], [id, perimeterFolder])

    const onExportParcels = (type) => {
        if (!folderParcels.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const data = orderBy(folderParcels, ['perimeter', 'parcelId']).map(p => {
            const privateOwnersLength = p.privateOwners?.length || 0
            const companyOwnersLength = p.companyOwners?.length || 0
            const ownersLength = privateOwnersLength + companyOwnersLength

            return {
                ...p,
                perimeter: perimeters.find(pe => pe.perimeterId === p.perimeterId)?.name,
                identifier: p.parcelId,
                city: citiesIndex[p.cityCode]?.name,
                code: `${p.section ? `${p.section}-` : ''}${p.parcel ?? ''}`,
                owners: ownersLength,
                ownersGroup: { value: p.communalAccount, cellType: 'string' },
                hypothecaryState: p.hypothecary && i18n.entered,
            }
        })
        const dataWithHeader = data.length ? [{ ...data[0], headers: PARCELS_HEADER }, ...data.slice(1)] : []
        return dispatch(ExportAction.export(formatData(dataWithHeader), type, `${i18n.parcels} - ${i18n.folder} ${perimeterFolder?.name} - ${i18n.file}`))
    }

    const formatOwner = owner => ({
        ...owner,
        propertyType: lexiconOwnerTypes.find(lot => lot.code === owner.propertyType)?.name,
        birthdate: getFullDateMini(owner.dateOfBirth),
    })

    const onExportOwners = (type) => {
        if (!privateOwners.length && !companyOwners.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }

        const formattedPrivateOwners = privateOwners?.map(p => ({
            ...formatOwner(p),
            type: i18n.privateOwner,
        })) || []
        const formattedCompanyOwners = companyOwners?.map(p => ({
            ...formatOwner(p),
            type: i18n.companyOwner,
        })) || []

        const data = [...formattedPrivateOwners, ...formattedCompanyOwners]
        const dataWithHeader = data.length ? [{ ...data[0], headers: OWNERS_HEADER }, ...data.slice(1)] : []
        return dispatch(ExportAction.export(formatData(dataWithHeader), type, `${i18n.owners} - ${i18n.folder} ${perimeterFolder?.name} - ${i18n.file}`))
    }

    const exportChoices = useMemo(() => [{
        name: i18n.plotsList,
        formats: [{
            type: i18n.excelFile,
            callback: () => onExportParcels('xlsx'),
        }, {
            type: i18n.csvFile,
            callback: () => onExportParcels('csv'),
        }],
    }, {
        name: i18n.ownersList,
        formats: [{
            type: i18n.excelFile,
            callback: () => onExportOwners('xlsx'),
        }, {
            type: i18n.csvFile,
            callback: () => onExportOwners('csv'),
        }],
    }, {
        name: i18n.operatorsList,
        formats: [{
            type: i18n.excelFile,
            callback: () => {},
        }, {
            type: i18n.csvFile,
            callback: () => {},
        }],
        disabled: true,
    }], [folderParcels, citiesIndex, lexiconOwnerTypes, privateOwners, companyOwners, perimeters])

    useActions(() => !readMode ? ({
        save: () => {
            dispatch(PerimetersAction.updatePerimetersFolder({
                folderId: parsedId,
                name: perimeterFolder.name,
                collectivity: perimeterFolder.collectivity,
                state: perimeterFolder.state,
                cityName: perimeterFolder.cityName,
            })).then(() => {
                dispatch(PerimetersThunk.getPerimetersFolders())
                setReadMode(true)
            })
        },
        cancel: () => {
            setReadMode(true)
            setPerimeterFolder(findedPerimeterFolder)
        },
        delete: () => dispatch(PerimetersAction.deletePerimetersFolder(parsedId)).then(() => {
            dispatch(push('/perimeter/dashboard'))
        }),
    }) : (findedPerimeterFolder.state !== maxBy(lexiconStates, 'code')?.code) ? ({
        import: () => setImportStepperIsOpen(true),
        exportChoice: { exportChoices },
        edit: () => setReadMode(false),
    }) : ({
        exportChoice: { exportChoices },
    }), [readMode, parsedId, perimeterFolder, findedPerimeterFolder, lexiconStates, exportChoices])

    return isLoaded ? (
        <Grid2 container columnSpacing={2} alignItems='flex-start' sx={{ padding: '0.75rem 1rem 0 0' }}>
            <Grid2 container size={9} direction='column' rowSpacing={1}>
                <Grid2>
                    <WhiteCard round>
                        <Grid2 container alignItems='center' sx={{ padding: '1rem' }} spacing={1}>
                            <Grid2 size={8}>
                                <Input
                                    title={i18n.name}
                                    value={perimeterFolder.name}
                                    onChange={name => setPerimeterFolder(prev => ({ ...prev, name }))}
                                    disabled={readMode}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={8}>
                                <Input
                                    title={i18n.city}
                                    value={perimeterFolder.cityName}
                                    onChange={cityName => setPerimeterFolder(prev => ({ ...prev, cityName }))}
                                    disabled={readMode}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={8}>
                                <MultiContributorsAutocomplete
                                    label={i18n.collectivity}
                                    onChange={collectivity => setPerimeterFolder(prev => ({ ...prev, collectivity }))}
                                    values={perimeterFolder.collectivity}
                                    options={contributors}
                                    disabled={readMode}
                                />
                            </Grid2>
                            <Grid2 container size={8} columnSpacing={2} rowSpacing={1}>
                                <Grid2 size={6}>
                                    <Select
                                        options={lexiconStates}
                                        label={i18n.stateOfProgressOfProcedure}
                                        nullLabel='&nbsp;'
                                        value={perimeterFolder.state}
                                        disabled={readMode}
                                        noSort
                                        onChange={state => setPerimeterFolder(prev => ({ ...prev, state }))}
                                    />
                                </Grid2>
                                <Grid2 size={6} sx={{ '& label': { color: readMode ? 'rgba(0, 0, 0, 0.42)' : '#000', fontWeight: 'bold', fontSize: '1rem' } }}>
                                    <label>{i18n.status}</label>
                                    <PerimeterStatus
                                        color={PERIMETERS_STATES_COLORS[perimeterFolder.status] || PERIMETERS_STATES_COLORS[0]}
                                        label={lexiconStatus.find(s => s.code === perimeterFolder.status)?.name ?? ''}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </WhiteCard>
                </Grid2>
                <Grid2>
                    <ConfirmModal
                        isOpen={!!deletePerimeterId}
                        title={i18n.sureDeletePerimeter}
                        onValidate={() => {
                            dispatch(PerimetersAction.deletePerimeter({
                                folderId: perimeterFolder.folderId,
                                perimeterId: deletePerimeterId,
                            }))
                            setDeletePerimeterId(undefined)
                        }}
                        onClose={() => setDeletePerimeterId(undefined)}
                    />
                    <PerimetersTable
                        perimeters={perimeters}
                        setPerimeter={setPerimeter}
                        setDeletePerimeterId={setDeletePerimeterId}
                        readMode={readMode}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container direction='column' size={3} rowSpacing={1.25}>
                <Grid2>
                    <UpdatePanel
                        updateDate={perimeterFolder.updateDate}
                        updateLogin={perimeterFolder.updateLogin}
                        round
                    />
                </Grid2>
                {!!perimeterFolderLastEvent?.comment && (
                    <Grid2>
                        <Card noMargin cardStyle={{ width: '100%', padding: '10px', borderRadius: MAIN_RADIUS }}>
                            <span>{perimeterFolderLastEvent.comment}</span>
                        </Card>
                    </Grid2>
                )}
            </Grid2>
            <PerimetersImportStepper
                folderId={parsedId}
                open={importStepperIsOpen}
                setOpen={setImportStepperIsOpen}
            />
            <PerimetersStepper
                folderId={parsedId}
                perimeter={perimeter}
                setPerimeter={setPerimeter}
                refreshParcels={() => {
                    dispatch(PerimetersThunk.getPerimeters(id))
                    dispatch(PerimetersAction.getFolderParcels(parsedId)).then(setFolderParcels)
                }}
            />
        </Grid2>
    ) : (
        <Grid2 size={12} sx={{ marginTop: '1rem' }}>
            <ProgressCard progress={progress} />
        </Grid2>
    )
}

export default PerimeterDashboard