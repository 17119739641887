/* eslint-disable camelcase */

import DtoAnswer from 'installation/components/campaign/dto/DtoAnswer'
import DtoDanger from 'installation/components/campaign/dto/DtoDanger'


export default class DtoPgsseForm {
    constructor(obj) {
        this.idForm = obj.idForm // Option[Long] = None,
        this.idDiag = obj.idDiag // Option[Long] = None,
        this.stationType = obj.stationType // Option[Long] = None,
        this.code = obj.code // Option[Long] = None,
        this.idCampaign = obj.idCampaign // Option[Long] = None,
        this.formDate = obj.formDate // Option[DateTime] = None,
        this.technicianLogin = obj.technicianLogin // Option[String] = None,
        this.otherTechnicians = obj.otherTechnicians // Option[String] = None,
        this.participants = obj.participants // Option[String] = None,
        this.comments = obj.comments // Option[String] = None,
        this.formStatus = obj.formStatus // Option[Long] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None,
        this.eventCode = obj.eventCode // Option[String] = None,

        this.idSurvey = obj.idSurvey // Option[Long] = None,

        this.link_answers = obj.link_answers ? obj.link_answers.map(a => new DtoAnswer(a)) : [] // Option[Seq[VisitModification]]
        this.link_dangers = obj.link_dangers ? obj.link_dangers.map(a => new DtoDanger(a)) : [] // Option[Seq[VisitModification]]
    }
}
