import { Button, Dialog } from '@mui/material'
import { THRESHOLDS } from 'alerting/constants/AlertConstants'
import Table from 'components/datatable/Table'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PiezometerStationAction from 'station/actions/PiezometerStationAction'
import StationAction from 'station/actions/StationAction'
import { getThresholdColorCode, getThresholdColorCodes } from 'utils/ColorUtil'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import { execByType } from 'utils/StationUtils'
import { getLabel } from 'utils/StoreUtils'
import { MEASURE_COTE } from '../../../../piezometry/constants/PiezometryConstants'
import { round } from '../../../../utils/NumberUtil'
import { darkBlue } from '../../../../utils/constants/ColorTheme'
import Icon from '../../../../components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import ColorPicker from '../../../../components/forms/ColorPicker'
import Select from 'components/forms/Select'

const ColorModal = ({
    setThreshold = () => { },
    threshold = {},
}) => {
    const [isOpen, setIsOpen] = useState(false)
    if (!isOpen) {
        const color = getThresholdColorCode(threshold.color)
        return (
            <span
                className='arrests-level-panel clickable'
                style={{ color, 'background-color': color, border: '1px solid black' }}
                onClick={() => setIsOpen(true)}
            >
                O
            </span>
        )
    }
    const elements = getThresholdColorCodes().map((c, i) => (
        <div className='col s1'>
            <span
                className='arrests-level-panel modal-close clickable'
                style={{ color: c, backgroundColor: c, border: '1px solid black' }}
                onClick={() => {
                    setThreshold(p => ({ ...p, color: i }))
                    setIsOpen(false)
                }}
            >O</span>
        </div>
    ))
    return (
        <div className='row no-margin'>
            {elements}
        </div>
    )
}

ColorModal.propTypes = {
    setThreshold: PropTypes.func,
    threshold: PropTypes.shape({}),
}

const ThresholdsTab = ({
    thresholds = [],
    station = {},
    whiteCard = false,
    onReload = () => { },
    withoutDataTypeAnnotation = false,
    selectedDataType, // String
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        hydrometryDataTypes,
        piezometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)
    const [threshold, setThreshold] = useState({
        code: '',
        color: 4,
        value: 0,
        dataType: selectedDataType ? selectedDataType : (station.typeName === 'piezometry' ? '-1' : (station.typeName === 'hydrometry' ? '4' : '1')),
        isOverrunThreshold: '0',
        name: '',
    })

    const fetchThreshold = stationType => {
        switch (stationType) {
            case 'hydrometry':
                dispatch(HydrometryAction.fetchHydrometricThresholds())
                break
            case 'pluviometry':
                dispatch(PluviometryAction.fetchPluviometerAllThresholds())
                break
            default:
                dispatch(PiezometerStationAction.fetchAllPiezometerThreshold(MEASURE_COTE.DEPTH))
                dispatch(PiezometerStationAction.fetchPiezometerThresholds(station.id))
        }
    }

    const dataTypes = execByType(station.typeName, {
        pluviometry: () => pluviometryDataTypes,
        piezometry: () => [...getHardPiezoDataTypes(), ...piezometryDataTypes],
        hydrometry: () => [...hydrometryDataTypes],
        default: () => [],
    })

    const isAuthorize = accountUser.isAdmin === '1' || accountUser.metadata === '1'
    const deleteThreshold = thresh => {
        const newThresholds = [...thresholds.filter(t => t.id !== thresh.id)]

        dispatch(StationAction.updateThresholds(station.id, newThresholds, station.typeName, MEASURE_COTE.DEPTH)).then(() => {
            fetchThreshold(station.typeName)
            onReload(station, THRESHOLDS)
        })
    }
    const thresholdsFormated = thresholds.map(t => {
        const color = t.htmlColor ?? getThresholdColorCode(t.color)
        return {
            id: t.id,
            nullValue: (
                <span style={{ color, backgroundColor: color, border: color === '#FFFFFF' ? '1px solid black' : undefined, borderRadius: '10px', padding: '0px 6px' }}>OO</span>
            ),
            name: t.name,
            value: round(t.value, 5),
            dataType: getLabel(dataTypes, parseInt(t.dataType), 'label', 'id'),
            thresholdOverrun: (
                <i className='material-icons small'>{t.isOverrunThreshold === '1' ? 'check_box' : 'check_box_outline_blank'}</i>
            ),
        }
    })
    const reset = () => {
        setIsOpen(false)
        setThreshold({
            code: '',
            color: 4,
            value: 0,
            dataType: selectedDataType ? selectedDataType : (station.typeName === 'piezometry' ? '-1' : (station.typeName === 'hydrometry' ? '4' : '1')),
            isOverrunThreshold: '0',
            name: '',
        })
    }
    const dataType = selectedDataType || threshold.dataType

    return (
        <>
            <Table
                title={i18n.thresholds}
                data={thresholdsFormated}
                actions={isAuthorize ? [{
                    onClick: () => setIsOpen(true),
                    iconName: 'note_add',
                    color: darkBlue,
                }] : []}
                type={{ headers: withoutDataTypeAnnotation ? ['nullValue', 'name', 'value', 'thresholdOverrun'] : ['nullValue', 'name', 'value', 'dataType', 'thresholdOverrun'] }}
                alterable={isAuthorize}
                onAlter={({ id }) => {
                    const thresholdFound = thresholds.find(t => t.id === id)
                    setThreshold(thresholdFound)
                    setIsOpen(true)
                }}
                deletable={isAuthorize}
                onDelete={({ id }) => {
                    const thresholdFound = thresholds.find(t => t.id === id)
                    deleteThreshold(thresholdFound)
                }}
                condensed
                sortable
                id='thresholds_table'
                invertedHeaderStyle={whiteCard}
            />
            <Dialog
                fullWidth
                maxWidth='lg'
                onClose={reset}
                open={isOpen}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.addOrUpdateThreshold}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={ () => setIsOpen(false) } />
                </DialogTitleMUI>
                <DialogContentMUI style={{ paddingBottom: 0 }}>
                    <div className='row no-margin' style={{ minHeight: '25vh' }}>
                        <div className='row no-margin padding-top-1'>
                            <Input
                                col={6}
                                value={threshold.name}
                                title={i18n.title}
                                onChange={v => setThreshold(p => ({ ...p, name: v }))}
                                obligatory
                            />
                            <NumberField
                                col={6}
                                value={round(threshold.value, 5)}
                                title={i18n.value}
                                floatValue
                                onChange={v => setThreshold(p => ({ ...p, value: v }))}
                            />
                        </div>
                        <div className='row no-margin padding-top-1 valign-wrapper'>
                            <Select
                                col={8}
                                label={i18n.type}
                                options={dataTypes}
                                value={dataType}
                                keyValue='id'
                                keyLabel='label'
                                onChange={v => setThreshold(p => ({ ...p, dataType: v.toString() }))}
                                obligatory
                                disabled={!!selectedDataType}
                            />
                            <Checkbox
                                col={2}
                                label={i18n.thresholdOverrun}
                                checked={threshold.isOverrunThreshold === '1'}
                                onChange={e => setThreshold(p => ({ ...p, isOverrunThreshold: e ? '1' : '0' }))}
                            />
                            <ColorPicker
                                col={3}
                                label={i18n.color}
                                value={threshold.htmlColor ?? getThresholdColorCode(threshold.color)}
                                onChange={e => setThreshold(p => ({ ...p, htmlColor: e }))}
                            />
                        </div>
                    </div>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button
                        variant='outlined'
                        onClick={reset}
                    >
                        {i18n.cancel}
                    </Button>
                    <Button
                        variant='contained'
                        disabled={!threshold.dataType || !threshold.name?.replaceAll(' ', '')?.length}
                        onClick={() => {
                            const newThresholds = [
                                ...thresholds.filter(t => t.id !== threshold.id),
                                threshold,
                            ]
                            if (selectedDataType) {
                                dispatch(StationAction.updateThresholdsWithDataType(station.id, newThresholds.map(t => ({ ...t, dataType: selectedDataType })), station.typeName, selectedDataType)).then(() => {
                                    fetchThreshold(station.typeName)
                                    onReload(station, THRESHOLDS)
                                    reset()
                                })
                            } else {
                                dispatch(StationAction.updateThresholds(station.id, newThresholds, station.typeName, MEASURE_COTE.DEPTH)).then(() => {
                                    fetchThreshold(station.typeName)
                                    onReload(station, THRESHOLDS)
                                    reset()
                                })
                            }
                        }}
                    >
                        {i18n.save}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        </>
    )
}

ThresholdsTab.propTypes = {
    thresholds: PropTypes.arrayOf(PropTypes.shape({})),
    station: PropTypes.shape({}),
    onReload: PropTypes.func,
    whiteCard: PropTypes.bool,
    withoutDataTypeAnnotation: PropTypes.bool,
    selectedDataType: PropTypes.string,
}

export default ThresholdsTab