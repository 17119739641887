import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Other from '../../../../../components/actions/Other'
import DtoFile from '../../../../../components/file/dto/DtoFile'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import { getResultFormat } from '../../../../../utils/AnalyseUtils'
import { getLabel } from '../../../../../utils/StoreUtils'
import OperationAction from '../../actions/OperationAction'
import QualityAction from 'quality/actions/QualityAction'
import useBoolean from 'utils/customHook/useBoolean'
import useAbortController from 'utils/customHook/useAbortController'
import { isNil, isUndefined, keys, max, min, orderBy } from 'lodash'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2, Icon } from '@mui/material'
import moment from 'moment'
import { YEAR, getIntervalFormatter } from 'quality/constants/ChartConstant'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import useActions from 'utils/customHook/useActions'
import { NewTable } from 'components/datatable/NewTable'
import ConfirmModal from 'components/modal/ConfirmModal'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import Checkbox from 'components/forms/Checkbox'

const headers = ['nullValue', 'nullValue3', 'parameter', 'result', 'comment']

const Graph = ({
    indexes = [],
    parameter,
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const defaultTimestamp = moment().valueOf()

    const allDate = indexes.map(i => i.sampleDate ?? defaultTimestamp)

    const minDate = moment(min(allDate))
    const maxDate = moment(max(allDate))
    const chartMinDate = minDate.startOf(YEAR)
    const chartMaxDate = maxDate.endOf(YEAR)

    const {
        formatter,
        interval,
    } = getIntervalFormatter(chartMaxDate, chartMinDate)

    const name = parameters.find(p => p.code === parameter)?.displayName ?? ''

    const options = {
        series: [{
            type: 'bar',
            name,
            data: indexes.map(i => [i.sampleDate, i.result]),
            barMaxWidth: 10,
        }],
        xAxis: [{
            type: 'time',
            boundaryGap: true,
            axisLabel: {
                formatter,
                rotate: 50,
            },
            splitLine: {
                show: true,
            },
            interval,
            min: chartMinDate.valueOf(),
            max: chartMaxDate.valueOf(),
        }],
        yAxis: [{
            type: 'value',
            minInterval: 1,
            showSplitLine: true,
        }],
        grid: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '50',
            containLabel: true,
            height: 290,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                animation: false,
                label: {
                    backgroundColor: '#505765',
                },
            },
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge
            lazyUpdate
            style={{ height: 300 }}
        />
    )
}

Graph.propTypes = {
    indexes: PropTypes.arrayOf(PropTypes.shape({})),
    parameter: PropTypes.string,
}

const IndexesGraphPopin = ({
    isOpen = false,
    close = () => {},
    parameter,
    qualitometer,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const [indexes, setIndexes] = useState([])
    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        const code = qualitometers.find(q => q.id === qualitometer)?.code
        if (isUndefined(parameter) || isUndefined(code) || !isOpen) {
            return
        }

        notLoaded()
        setIndexes([])
        initController()

        const indexesFilter = {
            lightMode: true, // Option[Boolean] = None,

            parameters: [parameter], // Option[Seq[String]] = None,
            stations: [code], // Option[Seq[Int]] = None
        }

        QualityAction.getIndices(indexesFilter, controllerRef.current.signal)
            .then(setIndexes)
            .finally(loaded)

        // eslint-disable-next-line consistent-return
        return () => controllerRef.current.abort()
    }, [dispatch, parameter, qualitometer, isOpen])

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DefaultDialogTitle
                title={parameters.find(p => p.code === parameter)?.name ?? ''}
                onClose={close}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <div style={{ position: 'relative' }}>
                            {(!isLoaded) && (<CircularProgressWithLabel />)}
                            <Graph indexes={indexes} parameter={parameter}/>
                        </div>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    )
}

IndexesGraphPopin.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    parameter: PropTypes.string,
    qualitometer: PropTypes.number,
}

const IndexDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    title = '',
    index: indexProps = {},
}) => {
    const {
        parameters,
        units,
        remarks,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const [index, setIndex] = useState(indexProps)

    useUpdateEffect(() => {
        if (isOpen) setIndex(indexProps)
    }, [isOpen])

    const onChange = obj => setIndex(prevIndex => ({ ...prevIndex, ...obj }))

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DefaultDialogTitle
                title={title}
                onClose={onClose}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container rowSpacing={1} columnSpacing={2} alignItems='center'>
                            <Grid2 size={4}>
                                <Select
                                    value={`${index.parameter}`}
                                    options={parameters}
                                    label={i18n.parameter}
                                    onChange={v => onChange({ parameter: v && parseInt(v) })}
                                    disabled={!isNil(indexProps.parameter)}
                                    keyValue='code'
                                    keyLabel='labelWithCode'
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <NumberField
                                    value={index.result}
                                    title={i18n.result}
                                    onChange={v => onChange({ result: v })}
                                    floatValue
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    value={index.unit}
                                    options={units}
                                    label={i18n.unit}
                                    onChange={v => onChange({ unit: v })}
                                    disabled={!isNil(indexProps.parameter)}
                                    keyLabel='symbolWithName'
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    value={index.remarkCode}
                                    label={i18n.remarkCode}
                                    options={orderBy(remarks, 'code')}
                                    onChange={v => onChange({ remarkCode: v })}
                                    keyValue='id'
                                    noSort
                                />
                            </Grid2>
                            <Grid2 size={9}>
                                <Textarea
                                    value={index.comment}
                                    title={i18n.comment}
                                    onChange={v => onChange({ comment: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Checkbox
                                    checked={index.accreditation === 1}
                                    label={i18n.accreditation}
                                    onChange={v => onChange({ accreditation: v ? 1 : 0 })}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(index)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

IndexDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    title: PropTypes.string,
    index: PropTypes.shape({}),
}

const IndexesTable = ({
    openPopin,
}) => {
    const dispatch = useDispatch()

    const {
        indices,
        parameters,
    } = useSelector(store => ({
        indices: store.OperationReducer.operationIndices,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        value: isOpenConfirmDelete,
        setFalse: onCloseDelete,
        setTrue: onOpenDelete,
    } = useBoolean(false)
    const {
        value: isEditModalOpen,
        setFalse: onCloseEditModal,
        setTrue: onOpenEditModal,
    } = useBoolean(false)
    const [selectedIndex, setSelectedIndex] = useState()

    const data = indices.map(i => {
        return {
            nullValue: i.accreditation === 1 ? <Icon size='small'>verified</Icon> : '',
            nullValue3: { value: i.calculated ? <Icon size='small'>wifi</Icon> : '', tooltip: 'Open Data' },
            parameter: getLabel(parameters, i.parameter, 'labelWithCode'),
            result: getResultFormat(i),
            comment: i.comment,
            colorLine: i.calculated ? '#b8d2ff' : '#fff',
            indice: i,
        }
    })
    return (
        <>
            <NewTable
                rows={data}
                headers={headers}
                lineActions={[
                    {
                        icon: 'delete',
                        onClick: ({ indice }) => {
                            setSelectedIndex(indice)
                            onOpenDelete()
                        },
                    },
                    {
                        icon: 'edit',
                        onClick: ({ indice }) => {
                            setSelectedIndex(indice)
                            onOpenEditModal()
                        },
                    },
                ]}
                onClickRow={openPopin}
                rowsPerPageOptions={nbPerPageLabel}
            />
            <ConfirmModal
                isOpen={isOpenConfirmDelete}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    dispatch(OperationAction.updateOperationIndex(selectedIndex, 'DELETE')).then(() => {
                        onCloseDelete()
                        setSelectedIndex()
                    })
                }}
                onClose={() => {
                    onCloseDelete()
                    setSelectedIndex()
                }}
            />
            <IndexDialog
                isOpen={isEditModalOpen}
                onClose={() => {
                    onCloseEditModal()
                    setSelectedIndex()
                }}
                onValidate={updatedIndex => {
                    dispatch(OperationAction.updateOperationIndex(updatedIndex, 'PUT')).then(() => {
                        onCloseEditModal()
                        setSelectedIndex()
                    })
                }}
                title={i18n.updateIndex}
                index={selectedIndex}
            />
        </>
    )
}

IndexesTable.propTypes = {
    isEditMode: PropTypes.bool,
    onDelete: PropTypes.func,
    onAlter: PropTypes.func,
    openPopin: PropTypes.func,
}

const OperationIndicesPanel = ({
    qualitometerId,
    operationId,
    support,

    uploadFile,
    files = [],
    getOperationFiles,
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        indices,
        parameters,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        indices: store.OperationReducer.operationIndices,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        value: isNewModalOpen,
        setFalse: onCloseNewModal,
        setTrue: onOpenNewModal,
    } = useBoolean(false)

    useActions(() => {
        const importFileAction = isNil(uploadFile) ? {} : {
            importFile: {
                onClick: uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
        }
        const openFileAction = files.length === 0 || isNil(getOperationFiles) ? {} : {
            other: {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={getOperationFiles}
                    />
                ),
            },
        }
        const exportAction = {
            export: () => {
                const data = indices.map(i => {
                    const param = parameters.find(p => p.code === `${i.parameter}`)
                    return {
                        accreditation: i.accreditation === 1 ? i18n.yes : i18n.no,
                        calculated: i.calculated ? i18n.yes : i18n.no,
                        parameterCode: i.parameter,
                        parameter: param?.name ?? '',
                        result: getResultFormat(i),
                        comment: i.comment,
                    }
                })
                return {
                    data: data.length ? [{ ...data[0], headers: keys(data[0]) }, ...data.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: `operation ${i18n.indices}`,
                }
            },
        }

        if (accountUser.consultant === '1') return { ...importFileAction, ...openFileAction, ...exportAction }
        if (![4, 10, 11, 13, 27].includes(support)) return { new: onOpenNewModal, ...importFileAction, ...openFileAction, ...exportAction }
        return {
            calculateIndexes: {
                qualitometer: qualitometerId,
                operations: [operationId],
                support,
            },
            new: onOpenNewModal,
            ...importFileAction,
            ...openFileAction,
            ...exportAction,
        }
    })

    return (
        <>
            <IndexesTable />
            <IndexDialog
                isOpen={isNewModalOpen}
                onClose={() => {
                    onCloseNewModal()
                }}
                onValidate={newIndex => {
                    dispatch(OperationAction.updateOperationIndex({ id: -1, ...newIndex }, 'POST')).then(() => {
                        onCloseNewModal()
                    })
                }}
                title={i18n.newIndex}
                index={{ qualitometerId, operationId }}
            />
        </>
    )
}

OperationIndicesPanel.propTypes = ({
    qualitometerId: PropTypes.number,
    operationId: PropTypes.number,
    support: PropTypes.number,

    uploadFile: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    getOperationFiles: PropTypes.func,
})

export default OperationIndicesPanel
