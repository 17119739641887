module.exports = {
    RECEIVE_INSTALLATION_DATATYPE: 'installationDataTypes',
    RECEIVE_ALL_INSTALLATIONS: 'RECEIVE_ALL_INSTALLATIONS',
    RECEIVE_ALL_INSTALLATIONS_GEO: 'RECEIVE_ALL_INSTALLATIONS_GEO',
    RECEIVE_EXPLOITATION_INSTALLATIONS_GEO: 'RECEIVE_EXPLOITATION_INSTALLATIONS_GEO',
    RECEIVE_INSTALLATION: 'RECEIVE_INSTALLATION',
    RESET_INSTALLATION: 'RESET_INSTALLATION',
    RECEIVE_INSTALLATION_ASSOCIATIONS: 'RECEIVE_INSTALLATION_ASSOCIATIONS',
    RECEIVE_INSTALLATION_BOREHOLE: 'RECEIVE_INSTALLATION_BOREHOLE',
    RECEIVE_INSTALLATION_ANALYSIS: 'RECEIVE_INSTALLATION_ANALYSIS',
    RECEIVE_INSTALLATIONS_ANALYSIS: 'RECEIVE_INSTALLATIONS_ANALYSIS',
    UPDATE_INSTALLATION_ARRANGEMENTS: 'UPDATE_INSTALLATION_ARRANGEMENTS',
    UPDATE_INSTALLATION: 'UPDATE_INSTALLATION',
    DELETE_INSTALLATION: 'DELETE_INSTALLATION',
    CREATE_INSTALLATION: 'CREATE_INSTALLATION',
    UPDATE_INSTALLATION_BOREHOLE: 'UPDATE_INSTALLATION_BOREHOLE',
    UPDATE_INSTALLATION_CAPTURE: 'UPDATE_INSTALLATION_CAPTURE',
    UPDATE_INSTALLATION_LIFTING: 'UPDATE_INSTALLATION_LIFTING',
    UPDATE_INSTALLATION_PUMPING: 'UPDATE_INSTALLATION_PUMPING',
    UPDATE_INSTALLATION_STEP: 'UPDATE_INSTALLATION_STEP',
    UPDATE_INSTALLATION_TANK: 'UPDATE_INSTALLATION_TANK',
    UPDATE_INSTALLATION_TREATMENT: 'UPDATE_INSTALLATION_TREATMENT',
    RECEIVE_INSTALLATION_ASSOCIATED_PIEZOMETER_MEASURES: 'RECEIVE_INSTALLATION_ASSOCIATED_PIEZOMETER_MEASURES',
    RECEIVE_BOREHOLE_TABLE: 'RECEIVE_BOREHOLE_TABLE',
    RECEIVE_INSTALLATION_EVENT: 'RECEIVE_INSTALLATION_EVENT',
    CREATE_INSTALLATION_EVENT: 'CREATE_INSTALLATION_EVENT',
    UPDATE_INSTALLATION_EVENT: 'UPDATE_INSTALLATION_EVENT',
    RESET_INSTALLATION_EVENT: 'RESET_INSTALLATION_EVENT',
    DELETE_INSTALLATION_EVENT: 'DELETE_INSTALLATION_EVENT',
    RECEIVE_INSTALLATIONS_TYPES: 'RECEIVE_INSTALLATIONS_TYPES',
    RECEIVE_CAVITY: 'RECEIVE_CAVITY',
    RECEIVE_INDUSTRIAL_SITE: 'RECEIVE_INDUSTRIAL_SITE',
    RECEIVE_INDUSTRIAL_SITES: 'RECEIVE_INDUSTRIAL_SITES',
    RECEIVE_POLLUTED_SOIL: 'RECEIVE_POLLUTED_SOIL',
    RECEIVE_POLLUTED_SOILS: 'RECEIVE_POLLUTED_SOILS',
    RECEIVE_FLOW_OBSTRUCTION: 'RECEIVE_FLOW_OBSTRUCTION',
    RECEIVE_INSTALLATION_ACTIVITIES: 'RECEIVE_INSTALLATION_ACTIVITIES',
    RECEIVE_INSTALLATION_CAPTURE: 'RECEIVE_INSTALLATION_CAPTURE',
    RECEIVE_INSTALLATION_LIFTING: 'RECEIVE_INSTALLATION_LIFTING',
    RECEIVE_INSTALLATION_PUMPING: 'RECEIVE_INSTALLATION_PUMPING',
    RECEIVE_INSTALLATION_STEP: 'RECEIVE_INSTALLATION_STEP',
    RECEIVE_INSTALLATION_TANK: 'RECEIVE_INSTALLATION_TANK',
    RECEIVE_INSTALLATION_AGRI_TANKS: 'RECEIVE_INSTALLATION_AGRI_TANKS',
    RECEIVE_INSTALLATION_TREATMENT: 'RECEIVE_INSTALLATION_TREATMENT',
    RECEIVE_CAPTURE_ALIM_AREAS: 'RECEIVE_CAPTURE_ALIM_AREAS',
    RECEIVE_CONTRIBUTOR_LINKS: 'RECEIVE_CONTRIBUTOR_LINKS',
    EDITION_INSTALLATION: 'EDITION_INSTALLATION',
    RECEIVE_VISIT: 'RECEIVE_VISIT',
    UPDATE_VISIT: 'UPDATE_VISIT',
    RECEIVE_INSTALLATION_INDUSTRIAL_SITES: 'RECEIVE_INSTALLATION_INDUSTRIAL_SITES',
    RECEIVE_INDUSTRIAL_SITE_TABLE: 'RECEIVE_INDUSTRIAL_SITE_TABLE',
    RECEIVE_ALL_INSTALLATIONS_LIGHT: 'RECEIVE_ALL_INSTALLATIONS_LIGHT',
    RECEIVE_INSTALLATION_TABLE: 'RECEIVE_INSTALLATION_TABLE',
    RECEIVE_TERRITORY_ACTIVITIES: 'RECEIVE_TERRITORY_ACTIVITIES',
    RECEIVE_TERRITORY_POLLUTANTS: 'RECEIVE_TERRITORY_POLLUTANTS',
    RECEIVE_TERRITORY_POLLUTANTS_EMISSIONS: 'RECEIVE_TERRITORY_POLLUTANTS_EMISSIONS',
    RECEIVE_VISIT_MODE: 'RECEIVE_VISIT_MODE',
    RECEIVE_TERRITORY_EMISSIONS_ACTIVITY: 'RECEIVE_TERRITORY_EMISSIONS_ACTIVITY',
    RECEIVE_TERRITORY_EMISSIONS_DECLARATIONS: 'RECEIVE_TERRITORY_EMISSIONS_DECLARATIONS',
    RECEIVE_DISTRIBUTION_ETABLISHMENTS_ACTIVITY_SECTION: 'RECEIVE_DISTRIBUTION_ETABLISHMENTS_ACTIVITY_SECTION',
    RECEIVE_TERRITORY_CONVENTIONS_ANALYSIS: 'RECEIVE_TERRITORY_CONVENTIONS_ANALYSIS',
    RECEIVE_TERRITORY_DIAGNOSIS: 'RECEIVE_TERRITORY_DIAGNOSIS',
    RECEIVE_STEP_TERRITORY_ARTISANS: 'RECEIVE_STEP_TERRITORY_ARTISANS',
    RECEIVE_STEP_TERRITORY_INDUSTRIES: 'RECEIVE_STEP_TERRITORY_INDUSTRIES',
    RECEIVE_STEP_TERRITORY_SUBSCRIBERS: 'RECEIVE_STEP_TERRITORY_SUBSCRIBERS',
    RECEIVE_STEP_TERRITORY_DIAGNOSIS: 'RECEIVE_STEP_TERRITORY_DIAGNOSIS',
    RECEIVE_ESTABLISHMENT_DISCHARGES: 'RECEIVE_ESTABLISHMENT_DISCHARGES',
    DASHBOARD: 'dashboard',
    RECEIVE_DANGERS_TYPE: 'RECEIVE_DANGERS_TYPE',
    RECEIVE_DANGERS_LOCATION: 'RECEIVE_DANGERS_LOCATION',
    UPDATE_DANGER: 'UPDATE_DANGER',
    RECEIVE_DANGER: 'RECEIVE_DANGER',
    DELETE_DANGER: 'DELETE_DANGER',
    RECEIVE_BASINS: 'RECEIVE_BASINS',
    RECEIVE_STEP_TABLE: 'RECEIVE_STEP_TABLE',
    RECEIVE_TANK_TABLE: 'RECEIVE_TANK_TABLE',
    RAW_INSTALLATION_DATA: 1,
    RECEIVE_INSTALLATION_NETWORK: 'RECEIVE_INSTALLATION_NETWORK',
    UPDATE_INSTALLATION_NETWORK: 'UPDATE_INSTALLATION_NETWORK',
    INSTALLATION_ORIGIN: {
        LIST: [
            { code: 0, label: 'automatic' },
            { code: 1, label: 'manual' },
        ],
        AUTOMATIC: 0,
        MANUAL: 1,
    },
    INSTALLATION_TYPE_CRITERIA: 'INSTALLATION_TYPE_CRITERIA',
    INSTALLATION_TYPES: [
        { code: 0, label: 'borehole', key: 'BOREHOLE' },
        { code: 1, label: 'industrialSites', key: 'INDUSTRIAL_SITE' },
        { code: 2, label: 'STEP', key: 'STEP' },
        { code: 3, label: 'flowObstruction', key: 'FLOW_OBSTRUCTION' },
        { code: 4, label: 'pollutedSoils', key: 'POLLUTED_SOIL' },
        { code: 5, label: 'fishingSpots', key: 'FISHING_SPOT' },
        { code: 6, label: 'cavities', key: 'CAVITY' },
        { code: 7, label: 'sampleWorks', key: 'SAMPLE_WORK' },
        { code: 8, label: 'capture', key: 'CAPTURE' },
        { code: 9, label: 'liftingStation', key: 'LIFTING_STATION' },
        { code: 10, label: 'pumpingStation', key: 'PUMPING' },
        { code: 11, label: 'treatmentStation', key: 'TREATMENT' },
        { code: 12, label: 'tank', key: 'TANK' },
        { code: 13, label: 'others', key: 'OTHERS' },
        { code: 14, label: 'installation', key: 'AEP_PRODUCTION' },
        { code: 16, label: 'installation', key: 'AEP_OVERPRESSURE' },
        { code: 17, label: 'installation', key: 'AEP_DISINFECTION' },
        { code: 18, label: 'pointsPrelevement', key: 'AGRI_PREL' },
        { code: 20, label: 'sectorCount', key: 'SECTOR_COUNT' },
        { code: 22, label: 'elecCount', key: 'ELEC_COUNTER' },
        { code: 23, label: 'network', key: 'NETWORK' },
        { code: 24, label: 'strait', key: 'STRAIT' },
        { code: 25, label: 'bridge', key: 'BRIDGE' },
        { code: 26, label: 'sluice', key: 'SLUICE' },
    ],
    INSTALLATION_DESCRIPTION: [
        { code: 0, description: '#BoreholeDescriptionPanel' },
        { code: 9, description: '#LiftingStationDescriptionPanel' },
        { code: 8, description: '#CaptureStationDescriptionPanel' },
        { code: 12, description: '#TankStationDescriptionPanel' },
        { code: 11, description: '#TreatmentStationDescriptionPanel' },
        { code: 10, description: '#PumpingStationDescriptionPanel' },
        { code: 2, description: '#STEPDescriptionPanel' },
        { code: 1, description: '#IndustrialSiteDescriptionPanel' },
        { code: 23, description: '#NetworkDescriptionPanel' },
        { code: undefined, description: '#InstallationDescriptionPanel' },
    ],
    INSTALLATION_TYPE: {
        BOREHOLE: 0, // aep
        INDUSTRIAL_SITE: 1,
        STEP: 2, // aep
        FLOW_OBSTRUCTION: 3,
        POLLUTED_SOIL: 4,
        FISHING_SPOT: 5,
        CAVITY: 6,
        SAMPLE_WORK: 7,
        CAPTURE: 8, // aep
        LIFTING_STATION: 9, // aep
        PUMPING: 10, // aep
        TREATMENT: 11, // aep
        TANK: 12, // aep
        OTHERS: 13,
        AEP_PRODUCTION: 14, // aep
        AEP_OVERPRESSURE: 16, // aep
        AEP_DISINFECTION: 17, // aep
        AGRI_PREL: 18,
        INDUS_PREL: 19,
        SECTOR_COUNT: 20, // aep
        DOM_SUB: 21,
        ELEC_COUNTER: 22,
        NETWORK: 23, // aep
        STRAIT: 24,
        BRIDGE: 25,
        SLUICE: 26,
    },
    DANGER_ELEMENT_TYPE: {
        INSTALLATION: 1,
        CONTRIBUTOR: 2,
        SECTOR: 3,
    },
    PGSSE_ENTITIES_TYPE: [
        { i18n: 'installations', code: 1 },
        { i18n: 'contributors', code: 2 },
        { i18n: 'sectors', code: 3 },
    ],
    RECEIVE_INSTALLATION_EVENTS: 'RECEIVE_INSTALLATION_EVENTS',
    USAGE_MANDATORY_FIELDS: [
        { field: 'startDate', i18n: 'startDate' },
        { field: 'usageCode', i18n: 'usage' },
    ],
    DEPARTEMENT_TAG: '#DEPARTEMENT',
    ARRANGEMENT_MANDATORY_FIELDS: [
        { field: 'startDate', i18n: 'startDate' },
        { field: 'arrangementType', i18n: 'type' },
    ],
    PANELS_OPTIONS: [
        {
            code: 0,
            components: [
                { type: 'INFOS_COMPL', label: 'complInfos' },
                { type: 'ELEC_COM', label: 'communicationElectricity' },
                { type: 'ACCESS_SITE', label: 'accessSite' },
                { type: 'ACCESS_CASING', label: 'accessCasing' },
                { type: 'STAFF_SAFETY', label: 'staffSafety' },
                { type: 'CONTRIBUTORS', label: 'contributors' },
                { type: 'USAGES', label: 'usages' },
                { type: 'ARRANGEMENTS', label: 'arrangements' },
                { type: 'SAMPLERS', label: 'samplers' },
                { type: 'DRILLINGS', label: 'drillings' },
                { type: 'COMMENTS', label: 'comments' },
                { type: 'LITHOLOGY', label: 'lithology' },
                { type: 'STRATIGRAPHY', label: 'stratigraphy' },
                { type: 'CASINGS', label: 'casings' },
            ],
        },
        {
            code: 8,
            components: [
                { type: 'INFOS_COMPL', label: 'complInfos' },
                { type: 'CAPTURE_CIVIL_ENG', label: 'civilEngineering' },
                { type: 'CAPTURE_EQUIPMENTS', label: 'equipments' },
                { type: 'ELEC_COM', label: 'communicationElectricity' },
                { type: 'ACCESS_SITE', label: 'accessSite' },
                { type: 'ACCESS_CASING', label: 'accessCasing' },
                { type: 'STAFF_SAFETY', label: 'staffSafety' },
                { type: 'CONTRIBUTORS', label: 'contributors' },
                { type: 'USAGES', label: 'usages' },
                { type: 'ARRANGEMENTS', label: 'arrangements' },
                { type: 'SAMPLERS', label: 'samplers' },
            ],
        },
        {
            code: 9,
            components: [
                { type: 'INFOS_COMPL', label: 'complInfos' },
                { type: 'LIFTING_CIVIL_ENG', label: 'civilEngineering' },
                { type: 'LIFTING_EQUIPMENTS', label: 'equipments' },
                { type: 'ELEC_COM', label: 'communicationElectricity' },
                { type: 'ACCESS_SITE', label: 'accessSite' },
                { type: 'ACCESS_CASING', label: 'accessCasing' },
                { type: 'STAFF_SAFETY', label: 'staffSafety' },
                { type: 'CONTRIBUTORS', label: 'contributors' },
                { type: 'USAGES', label: 'usages' },
                { type: 'ARRANGEMENTS', label: 'arrangements' },
                { type: 'SAMPLERS', label: 'samplers' },
            ],
        },
        {
            code: 10,
            components: [
                { type: 'INFOS_COMPL', label: 'complInfos' },
                { type: 'ELECMEC', label: 'elecmecEquipments' },
                { type: 'PUMPING_EQUIPMENTS', label: 'equipments' },
                { type: 'ELEC_COM', label: 'communicationElectricity' },
                { type: 'ACCESS_SITE', label: 'accessSite' },
                { type: 'ACCESS_CASING', label: 'accessCasing' },
                { type: 'STAFF_SAFETY', label: 'staffSafety' },
                { type: 'CONTRIBUTORS', label: 'contributors' },
                { type: 'USAGES', label: 'usages' },
                { type: 'ARRANGEMENTS', label: 'arrangements' },
                { type: 'SAMPLERS', label: 'samplers' },
            ],
        },
        {
            code: 12,
            components: [
                { type: 'INFOS_COMPL', label: 'complInfos' },
                { type: 'ELECMEC', label: 'elecmecEquipments' },
                { type: 'ELEC_COM', label: 'communicationElectricity' },
                { type: 'ACCESS_SITE', label: 'accessSite' },
                { type: 'ACCESS_CASING', label: 'accessCasing' },
                { type: 'STAFF_SAFETY', label: 'staffSafety' },
                { type: 'CONTRIBUTORS', label: 'contributors' },
                { type: 'USAGES', label: 'usages' },
                { type: 'ARRANGEMENTS', label: 'arrangements' },
                { type: 'SAMPLERS', label: 'samplers' },
                { type: 'TANK_FILLING', label: 'filling' },
                { type: 'TANK_CIVIL_ENG', label: 'civilEngineering' },
                { type: 'TANK_EQUIPMENTS', label: 'equipments' },
            ],
        },
    ],
    DEFAULT_PANEL_OPTION: [
        { type: 'INFOS_COMPL', label: 'complInfos' },
        { type: 'ELEC_COM', label: 'communicationElectricity' },
        { type: 'ACCESS_SITE', label: 'accessSite' },
        { type: 'ACCESS_CASING', label: 'accessCasing' },
        { type: 'STAFF_SAFETY', label: 'staffSafety' },
        { type: 'CONTRIBUTORS', label: 'contributors' },
        { type: 'USAGES', label: 'usages' },
        { type: 'ARRANGEMENTS', label: 'arrangements' },
        { type: 'SAMPLERS', label: 'samplers' },
    ],

    IMPORT_STEPS: {
        IMPORT: 0,
        OPTIONS: 1,
        EXECUTION: 2,
    },
}
