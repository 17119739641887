import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ConfirmModal from 'components/modal/ConfirmModal'
import { sieauTooltip } from 'utils/FormUtils'

const Delete = ({ onDelete = () => {} }) => {
    const [open, setOpen] = useState(false)

    const id = 'delete_action_navbar'

    return (
        <>
            <ConfirmModal
                isOpen={open}
                title={i18n.deletingConfirmation}
                onValidate={onDelete}
                onClose={() => setOpen(false)}
            />
            <div className='action-wrapper'>
                <a
                    className='right waves-effect nav-actions blue-arrow dropdown-button'
                    {...sieauTooltip(i18n.delete, id, 'bottom')}
                    onClick={() => setOpen(true)}
                    id={id}
                    data-cy={id}
                >
                    <i className='material-icons left no-margin'>delete</i>
                </a>
            </div>
        </>
    )
}

Delete.propTypes = {
    onDelete: PropTypes.func.isRequired,
}

export default Delete