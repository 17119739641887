import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import useTitle from '../../../utils/customHook/useTitle'
import { last, maxBy, minBy, range } from 'lodash'
import moment from 'moment/moment'
import PluviometryAction from '../../actions/PluviometryAction'
import PluvioSuiviCriterias from './PluvioSuiviCriterias'
import PluvioSuiviTable from './PluvioSuiviTable'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import { Grid2 } from '@mui/material'
import ProgressCard from '../../../components/card/ProgressCard'
import MessageCard from '../../../components/card/MessageCard'
import { SUM, YEAR } from '../../../utils/constants/MeasureConstants'
import PropTypes from 'prop-types'
import DtoStation from '../../../station/dto/DtoStation'
import DtoPluviometerStats from '../../dto/DtoPluviometerStats'
import PluviometerDto from '../../dto/PluviometerDto'

const HEIGHT_TABLE = '70vh'

const PluvioSuiviTableApp = ({}) => {
    const {
        pluviometer,
        pluviometerStatistics,
        pluviometers,
    } = useSelector(store => ({
        pluviometer: store.PluviometryReducer.pluviometer,
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [measures, setMeasures] = useState([])
    const [years, setYears] = useState([])
    const [filter, setFilter] = useState({ group: SUM, horizon: YEAR })
    const currentDataType = filter.dataType ?? (pluviometerStatistics.some(h => h.typeId === 1) ? 1 : (pluviometerStatistics[0]?.typeId || 1))

    useTitle(() => [{
        title: i18n.pluviometry,
        href: 'pluviometry',
    }, {
        title: getStationTitle(pluviometer),
        href: `station/pluviometry/${pluviometer.id}`,
    }, {
        title: i18n.followUpTable,
        href: `station/pluviometry/${pluviometer.id}/pluviometricSuiviTable`,
    }], [])

    useActions(() => ({
        links: getLinks(pluviometer, this),
        arrowNav: getStationArrowNav('pluviometry', pluviometers, pluviometer.id, s => dispatch(push(`/station/pluviometry/${s.id}/pluviometricSuiviTable`))),
    }), [pluviometer.id])


    const reloadMeasures = (tmpFilter, usedYear = filter.year) => {
        setDataLoaded(false)
        setFilter(tmpFilter)
        PluviometryAction.promisePluvioChronicMeasuresLight({
            stationId: pluviometer.id,
            dataType: currentDataType,
            groupFunc: tmpFilter.group,
            ...(tmpFilter.horizon === YEAR ? {
                startDate: moment().year(tmpFilter.year ?? usedYear).startOf(YEAR).valueOf(),
                endDate: moment().year(tmpFilter.year ?? usedYear).endOf(YEAR).valueOf(),
            } : {}),
            chartMode: true,
        }).then(json => {
            const filteredStatus = tmpFilter.status ? json.filter(s => s.status === tmpFilter.status) : json
            const filteredQualification = tmpFilter.qualification ? filteredStatus.filter(s => s.qualification === tmpFilter.qualification) : filteredStatus
            setMeasures(filteredQualification)
            setDataLoaded(true)
        })
    }

    useEffect(() => {
        if (pluviometerStatistics.length) {
            const minDate = minBy(pluviometerStatistics, 'startDate')?.startDate
            const maxDate = maxBy(pluviometerStatistics, 'endDate')?.endDate
            const calculatedYears = range(parseInt(moment(minDate).year()), moment(maxDate).year() + 1).map(y => ({ code: y, name: y })).reverse()
            setYears(calculatedYears)
            reloadMeasures({ ...filter, year: calculatedYears[0].code }, calculatedYears[0].code)
        }
    }, [pluviometerStatistics])

    useEffect(() => {
        if (!pluviometerStatistics.length) {
            dispatch(PluviometryAction.fetchPluviometerMeasuresStats(pluviometer.id)).then(() => {
                setDataLoaded(true)
            })
        }
    }, [])

    const horizon = useMemo(() => {
        return filter.horizon
    }, [measures])

    return (
        <Grid2 container padding={1} spacing={1}>
            <Grid2 size={12}>
                <PluvioSuiviCriterias
                    filter={filter}
                    setFilter={setFilter}
                    pluviometerStatistics={pluviometerStatistics}
                    reloadMeasures={reloadMeasures}
                    years={years}
                />
            </Grid2>
            {
                !dataLoaded ? (
                    <Grid2 size={12}>
                        <ProgressCard withMessage />
                    </Grid2>
                ): (
                    <>
                        { !measures.length ? (
                            <Grid2 size={12}>
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            </Grid2>
                        ) : (
                            <Grid2 size={12} sx={{ height: HEIGHT_TABLE }}>
                                <PluvioSuiviTable
                                    pluviometer={pluviometer}
                                    dataType={currentDataType}
                                    measures={measures}
                                    horizon={horizon}
                                    dataLoaded={dataLoaded}
                                    minYear={last(years)?.code}
                                    maxYear={years[0]?.code}
                                    selectedYear={filter.year}
                                />
                            </Grid2>
                        )}
                    </>
                )
            }
        </Grid2>
    )
}

PluvioSuiviTableApp.propTypes = {
    pluviometer: PropTypes.instanceOf(DtoStation),
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
}

export default PluvioSuiviTableApp
