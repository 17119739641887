import { difference, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ProgressCard from '../../../../components/card/ProgressCard'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import { INSTALLATION_TYPES } from '../../../../installation/constants/InstallationConstants'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { setModal } from '../../../../utils/FormUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import { getIntegrationModes, getMeasureCoteList } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { execByType, getStations } from '../../../../utils/StationUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { arrayOf, getSandreList, removeNullKeys } from '../../../../utils/StoreUtils'
import JobAction from '../../../actions/JobAction'
import Job from '../../../dto/DtoJob'
import CSVGenericAdvancedModal from './CSVGenericAdvancedModal'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import DtoInstallationLight from '../../../../installation/dto/installation/DtoInstallationLight'
import {
    DefaultDialogTitle,
    DialogActionsMUI,
    DialogContentMUI,
    DialogMUI,
    DialogTitleMUI,
} from '../../../../components/styled/Dialog'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { nbPerPageLabelMedium, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'


class CSVGenericPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            progress: 0,
            importParamsPopupIsOpen: false,
            fileContent: null,
            tmpDataCategory: undefined,
        }
    }

    componentDidMount() {
        AppStore.dispatch(JobAction.loadCSVImportPanel(p => this.setState({ progress: p }), () => this.setState({ dataLoaded: true })))
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    clearParameters = () => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: [],
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parametersToUpdate = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters: parametersToUpdate })
    }

    updateDataCategory = (dataCategory) => {
        const parametersToUpdate = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                dataCategory,
            })],
            parameters: [],
        }
        this.setState({ tmpDataCategory: undefined })
        this.props.onChangeJob({ parameters: parametersToUpdate })
    }

    onChangeColumns = (parameter, index, id, column) => {
        const actualData = parameter.data || []
        const newData = (() => {
            if (!hasValue(column)) {
                return actualData.filter(d => d.id !== id)
            }
            return uniqBy([{ id, column }, ...actualData], 'id')
        })()
        this.onChangeParametersNew(parameter, index, { data: newData })
    }

    findData = (parameter, id) => {
        const found = (parameter.data || []).find(d => d.id === id)
        return found ? found.column : null
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { measureCote: 2, unwantedColumns: ['conditionColumn', 'requiredValue', 'autoDetectCode', 'pointPrelevement', 'status', 'qualification', 'initialPoint'], ...newFilters }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    getDataTypes = (filters) => execByType(filters.dataCategory, {
        pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => uniqBy([ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ], 'id'),
        hydrometry: () => uniqBy([ ...this.props.hydrometryDataTypes, ...getHardHydroDataTypes() ], 'id'),
        installation: () => this.props.installationDataTypes,
        default: () => [],
    })

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.props.job.parameters.path } />,
        })
    }

    getStationsByDataCategory = (dg) => {
        const filters = this.getFilters()
        const dataCategory = dg || filters.dataCategory
        return getStations(this.props, dataCategory)
            .map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name }))
            .filter(s => dataCategory !== 'installation' || s.installationType === filters.installationType)
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            const columnFields = this.getDataTypes(filters).reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <NumberField value={ this.findData(p, val.id) } onChange={ v => this.onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => this.onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => this.onChangeParametersNew(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => this.onChangeParametersNew(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParametersNew(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } floatValue {...disabled }/>,
                date: <NumberField value={ p.dateColumn } onChange={ v => this.onChangeParametersNew(p, index, { dateColumn: v }) } {...disabled }/>,
                hour: <NumberField value={ p.hourColumn } onChange={ v => this.onChangeParametersNew(p, index, { hourColumn: v }) } {...disabled }/>,
                station: (<Select options={ this.getStationsByDataCategory() }
                    onChange={ (_, v) => v && v.id ? this.onChangeParametersNew(p, index, { stationCode: v.code, stationId: v.id }) : null }
                    value={ p.stationId } keyValue='id' disabled={ disabled.disabled || p.autoDetectCode }
                />),
                excelTab: <Input value={ p.sheet } onChange={ v => this.onChangeParametersNew(p, index, { sheet: v }) } {...disabled }/>,
                sandreCode: <NumberField value={ p.sandreCode } onChange={ v => this.onChangeParametersNew(p, index, { sandreCode: v }) } {...disabled }/>,
                value: <NumberField value={ p.value } onChange={ v => this.onChangeParametersNew(p, index, { value: v }) } {...disabled }/>,
                unit: <NumberField value={ p.unit } onChange={ v => this.onChangeParametersNew(p, index, { unit: v }) } {...disabled }/>,
                conditionColumn: <NumberField value={ p.conditionColumn } onChange={ v => this.onChangeParametersNew(p, index, { conditionColumn: v }) } {...disabled }/>,
                requiredValue: <Input value={ p.requiredValue } onChange={ v => this.onChangeParametersNew(p, index, { requiredValue: v }) } {...disabled }/>,
                autoDetectCode: <Checkbox checked={ p.autoDetectCode } label={i18n.autoDetectCode} onChange={ v => this.onChangeParametersNew(p, index, { autoDetectCode: v }) } {...disabled } />,
                predictDate: <NumberField value={ p.simulationDateColumn } onChange={ v => this.onChangeParametersNew(p, index, { simulationDateColumn: v }) } {...disabled }/>,
                horizon: <NumberField value={ p.predictHorizon } onChange={ v => this.onChangeParametersNew(p, index, { predictHorizon: v }) } {...disabled }/>,
                pointPrelevement: <Input value={ p.pointPrelevement } onChange={ v => this.onChangeParametersNew(p, index, { pointPrelevement: v }) } {...disabled }/>,
                status: <NumberField value={ p.status } onChange={ v => this.onChangeParametersNew(p, index, { status: v }) } {...disabled }/>,
                qualification: <NumberField value={ p.qualification } onChange={ v => this.onChangeParametersNew(p, index, { qualification: v }) } {...disabled }/>,
                initialPoint: <NumberField value={ p.initialPoint } onChange={ v => this.onChangeParametersNew(p, index, { initialPoint: v }) } {...disabled }/>,
                ...columnFields,
            }
        })
    }

    setAdvancedModal = (filters) => {
        setModal({
            title: i18n.settingsToDisplay,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                </div>
            ),
            content: <CSVGenericAdvancedModal unwantedColumns={ filters.unwantedColumns } onChange={ v => this.onChangeFilters({ unwantedColumns: v }) } dataTypes={ this.getDataTypes(filters) } />,
        })
    }

    onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            reader.onloadend = (event) => {
                const split = event.target.result.split(',')
                this.setState({ fileContent: split[1] })
            }
            reader.readAsDataURL(file)
        }
    }

    importParams = (headers, dataCategory) => {
        JobAction.jobCsvImport(this.props.job.id, headers, this.state.fileContent, dataCategory).then(() => this.props.fetchFullJob(this.props.job.id, 10).then(job => {
            const parameters = JSON.parse(job.parameters).parameters
            const jobParameters = {
                ...this.props.job.parameters,
                parameters,
            }
            this.props.onChangeJob({ parameters: jobParameters })
            this.setState({ importParamsPopupIsOpen: false })
        }))
    }

    importParamsPopin = (fileHeaders, dataCategory) => {
        return (
            <DialogMUI maxWidth='lg' open={this.state.importParamsPopupIsOpen}>
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            {i18n.importParameters}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={() => this.setState({ importParamsPopupIsOpen: false })} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    <Grid item xs={12} className='padding-top-1'>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.fileLabel}</StyledLegend>
                            <Grid item className='padding-left-1'>
                                <h6>{i18n.pleaseUseSameFileExmport}</h6>
                            </Grid>
                            <div className='file-field input-field'>
                                <div className='btn'>
                                    <span>{i18n.select}</span>
                                    <input
                                        type='file'
                                        onChange={this.onUploadFile}
                                    />
                                </div>
                                <div className='file-path-wrapper'>
                                    <input className='file-path validate' type='text' />
                                </div>
                            </div>
                        </StyledFieldSet>
                    </Grid>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid container justifyContent='flex-end' alignItems='flex-end'>
                        <Grid container justifyContent='flex-end'>
                            <Button variant='contained' onClick={() => this.importParams(fileHeaders, dataCategory) }>
                                {i18n.importLabel}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }

    onChangeDataCategory = (value) => {
        const { job } = this.props
        if (!job.parameters.parameters.length) {
            return this.updateDataCategory(value)
        }
        return this.setState({ tmpDataCategory: value })
    }

    render() {
        const { tmpDataCategory } = this.state

        const filters = this.getFilters()
        const parameters = this.getParametersData(filters, this.getParametersNew())
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const dataTypes = [
                { value: 'pluviometry', label: i18n.pluviometry },
                { value: 'piezometry', label: i18n.piezometry },
                { value: 'hydrometry', label: i18n.hydrometry },
                { value: 'installation', label: i18n.installation },
            ]
            const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'autoDetectCode', 'station', 'pointPrelevement', 'excelTab', 'conditionColumn', 'requiredValue', 'coeff', 'offset', 'date', 'sandreCode', 'value', 'unit', 'status', 'qualification', 'initialPoint']
            const hourColumn = filters.hourWithDate ? [] : ['hour']
            const columnHeaders = this.getDataTypes(filters).map(type => `data${type.id}`)
            const customHeaders = this.getDataTypes(filters).reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
            const predictColumns = filters.predictMode ? ['predictDate', 'horizon'] : []
            const filtersHeaders = difference([ ...baseHeaders, ...hourColumn, ...predictColumns, ...columnHeaders ], filters.unwantedColumns)
            const fileHeaders = difference([ ...baseHeaders, ...hourColumn, ...predictColumns, ...columnHeaders ], [...filters.unwantedColumns, 'nullValue', 'nullValue2', 'rename', 'realVolume'])


            return (
                <div>
                    <div className='row no-margin padding-top-1'>
                        <fieldset>
                            <legend>&nbsp;{i18n.defaultValue}&nbsp;</legend>
                            <div className='row no-margin'>
                                <Row className='padding-top-1'>
                                    <Select col={ 3 } label={ i18n.status } value={ filters.status } onChange={ v => this.onChangeFilters({ status: v }) } options={ getStatusSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 3 } label={ i18n.qualification } value={ filters.qualification } onChange={ v => this.onChangeFilters({ qualification: v }) } options={ getQualificationSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 6 } label={ i18n.producer } values={ filters.producer } onChange={ v => this.onChangeFilters({ producer: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                                <Row>
                                    <Select col={ 2 } label={ i18n.measureNature } value={ filters.nature } onChange={ v => this.onChangeFilters({ nature: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 2 } label={ i18n.obtainningMode } value={ filters.obtainingMode } onChange={ v => this.onChangeFilters({ obtainingMode: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.OBTAINING_MODE) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.administrator } values={ filters.manager } onChange={ v => this.onChangeFilters({ manager: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.validator } values={ filters.validator } onChange={ v => this.onChangeFilters({ validator: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ i18n.separator }&nbsp;</legend>
                            <Row className='valign-wrapper'>
                                <Checkbox col={ 2 } label={ i18n.comma } onChange={ v => this.onChangeFilters({ commaSeparator: v }) } disabled={ disabled } checked={ filters.commaSeparator } />
                                <Checkbox col={2} label={i18n.semicolon } onChange={ v => this.onChangeFilters({ semicolonSeparator: v }) } disabled={ disabled } checked={ filters.semicolonSeparator } />
                                <Checkbox col={ 2 } label={ i18n.space } onChange={ v => this.onChangeFilters({ spaceSeparator: v }) } disabled={ disabled } checked={ filters.spaceSeparator } />
                                <Checkbox col={ 2 } label={ i18n.tabulation } onChange={ v => this.onChangeFilters({ tabulationSeparator: v }) } disabled={ disabled } checked={ filters.tabulationSeparator } />
                                <Input col={4} title={ i18n.otherSeparator } onChange={ v => this.onChangeFilters({ customSeparator: v !== '' ? v : null }) } disabled={ disabled } value={ filters.customSeparator } />
                            </Row>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Checkbox col={ 4 } label={ i18n.renameFic } onChange={ v => this.onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ i18n.dontfindFic } onChange={ v => this.onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <Checkbox col={ 2 } label={ i18n.hasHeader } onChange={ v => this.onChangeFilters({ hasHeader: v }) } disabled={ disabled } checked={ filters.hasHeader } />
                        <NumberField col={ 2 } title={ i18n.nbLine } value={ filters.nbHeaders } onChange={ v => this.onChangeFilters({ nbHeaders: v }) } disabled={ disabled } floatValue />
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <Input col={ 3 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } obligatory={true} />
                        <a className='btn btn-small secondary-color-back ' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                        <Input col={ 3 } title={ i18n.formatDate } value={ filters.dateFormat } onChange={ v => this.onChangeFilters({ dateFormat: v }) } disabled={ disabled } placeholder='ex: dd/MM/YYYY-HH:mm:ss'/>
                        <Checkbox col={ 3 } label={ i18n.dateandHour } onChange={ v => this.onChangeFilters({ hourWithDate: v }) } disabled={ disabled } checked={ filters.hourWithDate } />
                        <Input col={ 3 } title={ i18n.defaultHour } value={ filters.defaultHour } onChange={ v => this.onChangeFilters({ defaultHour: v }) } disabled={ disabled } placeholder='ex: 15:00:00'/>
                    </div>
                    <div className='row no-margin'>
                        <Select col={ 2 } label={ i18n.dataType } options={ dataTypes } obligatory={true} value={ filters.dataCategory } onChange={ this.onChangeDataCategory } disabled={ disabled }/>
                        <Select col={ 3 } options={ getIntegrationModes() } obligatory={true} label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                            onChange={ v => this.onChangeFilters({ importMode: v }) }
                            disabled={ !this.props.isEditMode }
                        />
                        <Input col={ 2 } title={ i18n.excludedValue } value={ filters.excludedValue } onChange={ v => this.onChangeFilters({ excludedValue: v }) } disabled={ disabled } />
                        { filters.dataCategory && filters.dataCategory != 'pluviometry' &&
                        <RadioButtons col={3} elements={getMeasureCoteList()} selected={filters.measureCote}
                            onChange={v => this.onChangeFilters({ measureCote: v })}
                            title={i18n.ratingExpression} disabled={disabled}
                        />
                        }
                        <Checkbox col={ 2 } label='Dézipper les fichiers .zip' onChange={ v => this.onChangeFilters({ unzipFiles: v }) } disabled={ disabled } checked={ filters.unzipFiles } />
                    </div>
                    <div className='row no-margin'>
                        { filters.dataCategory && filters.dataCategory == 'installation' &&
                        <Select col={ 2 } options={ INSTALLATION_TYPES } label={ i18n.installationType } value={ filters.installationType }
                            onChange={ v => this.onChangeFilters({ installationType: v }) }obligatory={true}
                            disabled={ !this.props.isEditMode }
                        />
                        }
                    </div>
                    <div className='row no-margin'>
                        <Checkbox col={ 4 } label={ i18n.predictMode } onChange={ v => this.onChangeFilters({ predictMode: v }) } disabled={ disabled } checked={ filters.predictMode } />
                        <Input col={ 4 } title={ i18n.dataSource } value={ filters.predSource } onChange={ v => this.onChangeFilters({ predSource: v }) } disabled={ disabled || !filters.predictMode } />
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <div className='padding-left-2' />
                        <h6>{ i18n.indexStartsAt0 }</h6>
                    </div>
                    <div className='job-parameter-table'>
                        <CardTable
                            rows={ parameters }
                            headers={ filtersHeaders }
                            headersLabel = { customHeaders }
                            title={ i18n.credentials }
                            rowsPerPageOptions={ nbPerPageLabelMedium }
                            data-cy='CsvGeneric_table'
                            actions={[{
                                icon: 'add_box',
                                onClick: () =>{
                                    if (this.props.isEditMode) {
                                        const jobParameters = {
                                            ...this.props.job.parameters,
                                            parameters: [ ...this.props.job.parameters.parameters, '{}'],
                                        }
                                        this.props.onChangeJob({ parameters: jobParameters })
                                    }
                                },
                                tooltip: i18n.add,
                                displayed: this.props.isEditMode,
                            },
                            {
                                icon: 'build',
                                onClick: () => this.setAdvancedModal(filters),
                                tooltip: i18n.advanced,
                                displayed: this.props.isEditMode,
                            }, {
                                icon: 'clear',
                                onClick: this.clearParameters,
                                tooltip: i18n.deleteAll,
                                displayed: this.props.isEditMode,
                            },
                            {
                                icon: 'file_upload',
                                onClick: () => this.setState({ importParamsPopupIsOpen: true }),
                                tooltip: i18n.importFile,
                                displayed: this.props.isEditMode,
                            },
                            {
                                icon: 'download',
                                onClick: () => {
                                    const headersTranslate = fileHeaders.map(h => customHeaders[h] || i18n[h])
                                    JobAction.jobCsvExport(fileHeaders, headersTranslate, this.getParametersNew())
                                },
                                tooltip: i18n.download,
                            }]}
                        />
                    </div>
                    { this.importParamsPopin(fileHeaders, filters.dataCategory) }
                    <Dialog
                        fullWidth
                        maxWidth='lg'
                        open={hasValue(tmpDataCategory)}
                    >
                        <DefaultDialogTitle title={i18n.payAttention} onClose={() => this.setState({ tmpDataCategory: undefined })}/>
                        <DialogContent className='padding-top-1 padding-bottom-0'>
                            <h4 style={{ textAlign: 'center' }}>{i18n.ifChangeDataTypeFollowingItemsRemoved}</h4>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ tmpDataCategory: undefined })} variant='outlined' color='primary'>
                                {i18n.cancel}
                            </Button>
                            <Button onClick={() => this.updateDataCategory(tmpDataCategory)} variant='contained' color='primary'>
                                {i18n.validate}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

CSVGenericPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometers: arrayOf(DtoPiezometerLight),
    pluviometers: arrayOf(PluviometerDto),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    installationDataTypes: arrayOf(DtoParametrageDataType),
    installations: arrayOf(DtoInstallationLight),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    isEditMode: PropTypes.bool,
    fetchFullJob: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    installationDataTypes: store.InstallationReducer.installationDataTypes,
    installations: store.InstallationReducer.installationsLight,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    fetchFullJob: JobAction.fetchFullJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVGenericPanel)
