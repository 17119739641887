import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { Card, CardContent, Grid2 } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { useParams } from 'react-router'
import PgsseAction from 'pgsse/action/PgsseAction'
import { isNil } from 'lodash'
import { H_PGSSE_MODULE } from 'account/constants/AccessRulesConstants'
import LastUpdateCard from 'components/card/LastUpdateCard'
import { CardTitle } from 'components/card/NewCard'
import ProgressCircleChart from 'components/progress/ProgressCircleChart'
import { getPercentage } from 'utils/NumberUtil'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import useTitle from 'utils/customHook/useTitle'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { getUdiSectorName } from 'pgsse/util/PgsseUtil'
import FormResourcePanel from './campaign/FormResourcePanel'
import FormInstallationPanel from './campaign/FormInstallationPanel'
import FormNetworkPanel from './campaign/FormNetworkPanel'

const PgsseCampaignApp = () => {
    const dispatch = useDispatch()
    const { idDiagnostic, idCampaign } = useParams()

    const {
        campaign: propsCampaign,
        distributionUnits,
        sectors,
        diagnostic,
        surveys,
    } = useSelector(store => ({
        campaign: store.PgsseReducer.campaign,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        diagnostic: store.PgsseReducer.diagnostic,
        surveys: store.PgsseReducer.surveys,
    }), shallowEqual)

    const [campaign, setCampaign] = useState(propsCampaign)
    const [readMode, setReadMode] = useState(true)

    useEffect(() => setCampaign(propsCampaign), [propsCampaign])

    useEffect(() => {
        if (!componentHasHabilitations(H_PGSSE_MODULE)) {
            this.props.push('/unauthorized')
            return
        }

        if (!(isNil(idCampaign) || isNil(idDiagnostic))) {
            dispatch(PgsseAction.fetchCampaign(idCampaign))
            dispatch(PgsseAction.fetchDiagnostic(idDiagnostic))
        }
    }, [idCampaign, idDiagnostic])

    useActions(() => {
        return readMode ? {
            edit: () => setReadMode(false),
        } : {
            cancel: () => {
                setCampaign(propsCampaign)
                setReadMode(true)
            },
            save: () => {
                setReadMode(true)
                dispatch(PgsseAction.updateCampaign(idCampaign, campaign)).then(() => setReadMode(true))
            },
        }
    }, [readMode, campaign])

    useTitle(() => {
        const udi = distributionUnits.find(du => du.id === diagnostic.idUdi)
        const sector = sectors.find(s => s.id === diagnostic.idSector)
        return [{
            title: i18n.pgsse,
            href: PATH_PGSSE,
        }, {
            title: i18n.phase1,
            href: `${PATH_PGSSE}/phase1`,
        }, {
            title: getUdiSectorName(udi, sector),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}`,
        }, {
            title: campaign.name,
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}/campaign/${campaign.idCampaign}`,
        }]
    }, [diagnostic, distributionUnits, sectors, campaign])

    const onChangeCampaign = values => setCampaign(prev => ({ ...prev, ...values }))
    const campaignStatus = useSandreList(SANDRE.CAMPAGNES_STATUT)

    return (
        <Grid2 container sx={{ padding: '10 10 10 20' }} spacing={2}>
            <Grid2 container size={9} alignContent='flex-start'>
                <Grid2 size={12}>
                    <Card>
                        <CardContent>
                            <Grid2 container columnSpacing={1}>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.name}
                                        value={campaign.name}
                                        onChange={v => onChangeCampaign({ name: v })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        integerValue
                                        options={campaignStatus}
                                        value={campaign.status}
                                        label={i18n.status}
                                        returnNull
                                        onChange={value => onChangeCampaign({ status: value })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <SimpleDatePicker
                                        label={i18n.startDate}
                                        value={campaign.startDate}
                                        onChange={v => onChangeCampaign({ startDate: v })}
                                        max={campaign.endDate}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <SimpleDatePicker
                                        label={i18n.endDate}
                                        value={campaign.endDate}
                                        onChange={v => onChangeCampaign({ endDate: v })}
                                        min={campaign.startDate}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={surveys}
                                        label={i18n.pgsse}
                                        value={diagnostic.idSurvey}
                                        keyLabel='description'
                                        keyValue='idSurvey'
                                        readMode
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <SimpleTextArea
                                        title={i18n.comment}
                                        value={campaign.comment}
                                        onChange={value => onChangeCampaign({ comment: value })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <FormResourcePanel
                        idDiagnostic={parseInt(idDiagnostic)}
                        idCampaign={parseInt(idCampaign)}
                        link_stations={campaign.link_stations}
                        readMode={readMode}
                        onChange={onChangeCampaign}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormInstallationPanel
                        idDiagnostic={parseInt(idDiagnostic)}
                        idCampaign={parseInt(idCampaign)}
                        link_stations={campaign.link_stations}
                        readMode={readMode}
                        onChange={onChangeCampaign}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <FormNetworkPanel
                        idDiagnostic={parseInt(idDiagnostic)}
                        idCampaign={parseInt(idCampaign)}
                        link_stations={campaign.link_stations}
                        readMode={readMode}
                        onChange={onChangeCampaign}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container size={3} alignContent='flex-start'>
                <Grid2 size={12}>
                    <LastUpdateCard element={campaign} />
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.installationsProgress} />
                        <ProgressCircleChart
                            total={campaign.link_stations.length}
                            complete={campaign.link_stations.filter(lf => lf.status > 2).length}
                            title={`${getPercentage(campaign.link_stations.filter(lf => lf.status > 2).length, campaign.link_stations.length)} %`}
                        />
                    </Card>
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

PgsseCampaignApp.propTypes = {
}


export default PgsseCampaignApp
