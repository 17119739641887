/* eslint-disable camelcase */

import DtoCampaignWithStats from './DtoPgsseCampaignWithStats'
import DtoPgsseFormWithStats from './DtoPgsseFormWithStats'

export default class DtoPgsseDiagnostic {
    constructor(obj) {
        this.idDiag = obj.idDiag // Option[Long] = None,
        this.idSurvey = obj.idSurvey // Option[Long] = None,
        this.idContributor = obj.idContributor // Option[Long] = None,
        this.idSector = obj.idSector // Option[Long] = None,
        this.idUdi = obj.idUdi // Option[Long] = None,
        this.status = obj.status // Option[Long] = None,
        this.comment = obj.comment // Option[String] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None,

        this.link_campaigns = obj.link_campaigns ? obj.link_campaigns.map(d => new DtoCampaignWithStats(d)) : [] // Option[Seq[CampaignWithStats]]
        this.link_forms = obj.link_forms ? obj.link_forms.map(d => new DtoPgsseFormWithStats(d)) : [] // Option[Seq[FormWithStats]]
    }
}
