import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ceil, chunk, groupBy, last, range } from 'lodash'
import Checkbox from '../../../../../components/forms/Checkbox'
import i18n from 'simple-react-i18n'
import Line from '../../../../../components/echart/series/Line'
import { getColorFromPalette } from '../../../../../utils/ColorUtil'
import { arrayOf } from '../../../../../utils/StoreUtils'
import { Grid2 } from '@mui/material'
import { WhiteCard } from '../../../../../components/styled/Card'
import { PREVIOUSYEARS } from '../../constants/HydroSuiviConstants'
import DtoHydroMeasureLight from '../../../../dto/chronicMeasures/DtoHydroMeasureLight'

const HydroSuiviPastYearsTab2 = ({
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    measures,
    time, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
    tab,
    typeId,
}) => {
    const [activeYears, setActiveYears] = useState([])
    const [groupedCivil, setGroupedCivil] = useState({})
    const [groupedHydro, setGroupedHydro] = useState({})

    const yearRange = useMemo(() => range(moment(measures[0].date).year(), moment(last(measures).date).year() + 1), [measures])

    useEffect(() => {
        setGroupedCivil(groupBy(measures, m => moment(m.date).year()))
        setGroupedHydro(groupBy(measures, m => moment(m.date).add(3, 'month').year()))
        setActiveYears([])
    }, [measures, typeId])

    useEffect(() => {
        const toEchartMeasure = (m) => ({ value: [m.date, m.value,] })
        const group = time === 'CIVIL_YEAR' || time === 'OTHER' ? groupedCivil : (time === 'HYDRO_YEAR' ? groupedHydro : null)
        if (!group) {
            changeParent({ yearSeries: [] })
        } else {
            const yearSeries = yearRange.flatMap((y, idx) => {
                if (!activeYears.includes(y)) {
                    return []
                }
                const yearMeasures = (() => {
                    if (time === 'CIVIL_YEAR' || time === 'OTHER') {
                        const nowYear = time === 'OTHER' && (minDate ?? maxDate) ? moment(minDate ?? maxDate).year() : moment().year()
                        return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).year(nowYear).valueOf() }))
                    }
                    return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).add(3, 'month').year(moment().year()).subtract(3, 'month').valueOf() }))
                })()
                if (!yearMeasures.length) {
                    return []
                }
                return [Line({
                    data: yearMeasures,
                    name: y.toString(),
                    connectNulls: false,
                    showSymbol: false,
                    color: getColorFromPalette(idx),
                })]
            })
            changeParent({ yearSeries })
        }
    }, [yearRange, activeYears, time, minDate, maxDate]) // recalculé lorsqu'on change les checkbox, ou la tab en haut à gauche

    const changeYear = (y, v) => setActiveYears(v ? [...activeYears, y] : activeYears.filter(y2 => y2 !== y))

    if (!measures.length || tab !== PREVIOUSYEARS) {
        return null
    }
    const yearCheckboxes = yearRange.map((y, idx) => (
        <Grid2
            key={`year${y}`}
            container
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() => changeYear(y, !activeYears.includes(y)) }
        >
            <Checkbox checked={ activeYears.includes(y) } />
            <span>
                <span className={'arrests-level-panel'} style={{ color: getColorFromPalette(idx), backgroundColor: getColorFromPalette(idx) }}>OO</span>
                {y}
            </span>
        </Grid2>
    ))
    const groups = chunk(yearCheckboxes, ceil(yearCheckboxes.length / 4) || 1)
    return (
        <WhiteCard className='margin-top-1' title={i18n.previousYears} round>
            <Grid2 container spacing={2} className='padding-1'>
                <Grid2 size={3}>
                    { groups[0] || [] }
                </Grid2>
                <Grid2 size={3}>
                    { groups[1] || [] }
                </Grid2>
                <Grid2 size={3}>
                    { groups[2] || [] }
                </Grid2>
                <Grid2 size={3}>
                    { groups[3] || [] }
                </Grid2>
            </Grid2>
        </WhiteCard>
    )
}

HydroSuiviPastYearsTab2.propTypes = {
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    typeId: PropTypes.number,
    isPiezo: PropTypes.bool,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    measures: arrayOf(DtoHydroMeasureLight),
    time: PropTypes.oneOf([
        PropTypes.number,
        PropTypes.string,
    ]),
}

export default HydroSuiviPastYearsTab2