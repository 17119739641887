import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getDate } from '../../../utils/DateUtil'
import { groupBy, isNil, isUndefined, keys, maxBy, orderBy } from 'lodash'
import SupportAction from '../../../referencial/components/support/actions/SupportAction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2, Icon, IconButton } from '@mui/material'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'
import { getExport } from 'utils/linkUtils'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import useBoolean from 'utils/customHook/useBoolean'
import Fancybox from 'components/fancybox/Fancybox'
import { getFileNatureLabel } from 'utils/FileUtils'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { checkMandatoryFields } from 'utils/FormUtils'

const headers = ['support', 'startDate', 'endDate', 'x', 'y', 'projection', 'id', 'name', 'code', 'startDepth', 'endDepth']
const mandatoryFields = [{ field: 'startDate' }, { field: 'name' }, { field: 'codeSupport', i18n: 'support' }]

const SamplePointCard = ({
    samplePoint = {},
    onChange = () => { },
    disabled = false,
    readMode = false,
}) => {
    const {
        supports,
        pictures,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        pictures: store.StationReducer.pictures,
    }), shallowEqual)

    const projectionList = useSandreList(SANDRE.PROJECTION)
    const fileNatures = useSandreList(SANDRE.TYPE_PHOTO)

    const filteredPictures = pictures.filter(p => p.identifiantPoint === samplePoint.identifiant && p.startDatePoint === samplePoint.startDate)
    const groupedPictures = groupBy(orderBy(filteredPictures, 'date'), p => getFileNatureLabel(p.name, fileNatures))

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={samplePoint.startDate}
                            label={i18n.startDate}
                            onChange={v => onChange({ startDate: v })}
                            disabled={disabled}
                            readMode={readMode}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={samplePoint.endDate}
                            label={i18n.endDate}
                            onChange={v => onChange({ endDate: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.id}
                            value={samplePoint.identifiant}
                            onChange={v => onChange({ identifiant: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.code}
                            value={samplePoint.code}
                            onChange={v => onChange({ code: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.name}
                            value={samplePoint.name}
                            onChange={v => onChange({ name: v })}
                            disabled={disabled}
                            readMode={readMode}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={samplePoint.x}
                            title={'X'}
                            onChange={v => onChange({ x: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={samplePoint.y}
                            title={'Y'}
                            onChange={v => onChange({ y: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={projectionList}
                            label={i18n.projection}
                            value={samplePoint.projection}
                            onChange={v => onChange({ projection: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.xUpstream}
                            title={i18n.xUpstream}
                            onChange={v => onChange({ xUpstream: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.yUpstream}
                            title={i18n.yUpstream}
                            onChange={v => onChange({ yUpstream: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.xDownstream}
                            title={i18n.xDownstream}
                            onChange={v => onChange({ xDownstream: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.yDownstream}
                            title={i18n.yDownstream}
                            onChange={v => onChange({ yDownstream: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SuperMultiAutocomplete
                            options={supports}
                            onChange={(value) => onChange({ codeSupport: !isUndefined(value) ? parseInt(value) : undefined })}
                            keyValue='id'
                            label={i18n.support}
                            values={`${samplePoint.codeSupport}`}
                            disabled={disabled}
                            readMode={readMode}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.startDepth}
                            title={i18n.startDepth}
                            onChange={v => onChange({ startDepth: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.endDepth}
                            title={i18n.endDepth}
                            onChange={v => onChange({ endDepth: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.advisedDepth}
                            title={i18n.advisedDepth}
                            onChange={v => onChange({ advisedDepth: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.totalLength}
                            title={i18n.totalLength}
                            onChange={v => onChange({ totalLength: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={samplePoint.flowWidth}
                            title={i18n.flowWidth}
                            onChange={v => onChange({ flowWidth: v })}
                            disabled={disabled}
                            readMode={readMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.mainObject}
                            value={samplePoint.mainObject}
                            onChange={v => onChange({ mainObject: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={samplePoint.comment}
                            onChange={v => onChange({ comment: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleTextArea
                            title={i18n.advise}
                            value={samplePoint.advise}
                            onChange={v => onChange({ advise: v })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12} sx={{ overflowX: 'auto', display: 'flex' }}>
                        {keys(groupedPictures).map(labelGroup => (
                            <StyledFieldSet key={labelGroup} style={{ margin: '0 5' }}>
                                <StyledLegend>{labelGroup}</StyledLegend>
                                <div style={{ display: 'flex', alignItems: 'top' }}>
                                    {groupedPictures[labelGroup].map(picture => (
                                        <Fancybox key={picture.name}>
                                            <a href={picture.url} target='_blank' data-fancybox='images' data-caption={picture.name}>
                                                <img
                                                    src={picture.url}
                                                    style={{ width: '150px', height: '180px', objectFit: 'cover', borderRadius: '4px', margin: '0 5' }}
                                                />
                                            </a>
                                        </Fancybox>
                                    ))}
                                </div>
                            </StyledFieldSet>
                        ))}
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

SamplePointCard.propTypes = {
    samplePoint: PropTypes.shape({}),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
}

const SamplePointDialog = ({
    isOpen = false,
    defaultSamplePoint,
    onClose = () => { },
    onValidate = () => { },
}) => {
    const [samplePoint, setSamplePoint] = useState(defaultSamplePoint)

    useEffect(() => {
        if (!isOpen) return
        setSamplePoint(defaultSamplePoint)
    }, [isOpen])

    const onChange = (changes) => setSamplePoint(prev => ({ ...prev, ...changes }))

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
        >
            <DefaultDialogTitle title={isNil(defaultSamplePoint?.idPoint) ? i18n.newSamplePoint : i18n.pointPrelevement} onClose={onClose} />
            <DialogContent>
                <SamplePointCard
                    samplePoint={samplePoint}
                    onChange={onChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        checkMandatoryFields(mandatoryFields, samplePoint, () => {
                            onValidate(samplePoint)
                            onClose()
                        })
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SamplePointDialog.propTypes = {
    isOpen: PropTypes.bool,
    defaultSamplePoint: PropTypes.shape({
        idPoint: PropTypes.number,
        // other from link
    }),
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const SamplePointInfoDialog = ({
    isOpen = false,
    onClose = () => { },
    samplePoint = {},
}) => {
    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
            onClose={onClose}
        >
            <DefaultDialogTitle title={i18n.samplePoint} onClose={onClose} />
            <DialogContent>
                <SamplePointCard
                    samplePoint={samplePoint}
                    disabled
                />
            </DialogContent>
        </Dialog>
    )
}

SamplePointInfoDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    samplePoint: PropTypes.shape({}),
}

const SamplePointIconInfo = ({
    samplePoint,
    disabled = false,
}) => {
    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)

    const isDisabled = disabled || isNil(samplePoint)

    return (
        <>
            <IconButton
                sx={{ fontSize: '24px' }}
                onClick={onOpen}
                disabled={isDisabled}
            >
                <Icon fontSize='inherit' sx={{ color: isDisabled ? '#9e9e9e' : '#000' }}>info</Icon>
            </IconButton>
            <SamplePointInfoDialog isOpen={isOpen} onClose={onClose} samplePoint={samplePoint} />
        </>
    )
}

SamplePointIconInfo.propTypes = {
    samplePoint: PropTypes.shape({}),
    disabled: PropTypes.bool,
}

const QualitySamplePointsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        supportsIndex,
        supports,
    } = useSelector(store => ({
        supportsIndex: store.SupportReducer.supportsIndex,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const {
        value: isOpen,
        setFalse: onClose,
        setTrue: onOpen,
    } = useBoolean(false)
    const [selectedPoint, setSelectedPoint] = useState()

    useEffect(() => {
        if (supports.length === 0) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [])

    const tableData = station.link_samplePoints.map(sp => ({
        support: supportsIndex[sp.codeSupport]?.labelWithCode,
        startDate: getDate(sp.startDate),
        endDate: getDate(sp.endDate),
        x: sp.x,
        y: sp.y,
        projection: sp.projection,
        id: sp.identifiant,
        name: sp.name,
        code: sp.code,
        startDepth: sp.startDepth,
        endDepth: sp.endDepth,
        idPoint: sp.idPoint,
    }))

    const exportAction = getExport(tableData, i18n.samplePoint, headers, null, null, 'white')
    const actions = !readMode ? [{
        icon: 'note_add',
        tooltip: i18n.add,
        color: 'white',
        onClick: () => {
            setSelectedPoint({})
            onOpen()
        },
    }, exportAction] : [exportAction]

    const lineActions = [{
        icon: 'close',
        onClick: point => onChange({ link_samplePoints: station.link_samplePoints.filter(l => l.idPoint !== point.idPoint) }),
        displayed: !readMode,
    }, {
        icon: 'edit',
        onClick: point => {
            setSelectedPoint(station.link_samplePoints.find(l => point.idPoint === l.idPoint))
            onOpen()
        },
        displayed: !readMode,
    }]

    return (
        <>
            <CardTable
                title={i18n.samplePoint}
                rows={tableData}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
            />
            <SamplePointDialog
                isOpen={isOpen}
                defaultSamplePoint={selectedPoint}
                onValidate={newPoint => {
                    if (isNil(selectedPoint.idPoint)) {
                        const idPoint = (maxBy(station.link_samplePoints, 'idPoint')?.idPoint ?? 0) + 1
                        onChange({ link_samplePoints: [...station.link_samplePoints, { ...newPoint, idPoint, idQualitometer: station.id }] })
                        return
                    }
                    const newLinks = station.link_samplePoints.map(point => newPoint.idPoint === point.idPoint ? newPoint : point)
                    onChange({ link_samplePoints: newLinks })
                }}
                onClose={() => {
                    setSelectedPoint()
                    onClose()
                }}
            />
        </>
    )
}

QualitySamplePointsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoQualitometer),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default QualitySamplePointsPanel
export {
    SamplePointIconInfo,
    SamplePointInfoDialog,
    SamplePointCard,
}