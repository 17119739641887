/* eslint-disable camelcase */

export default class DtoPgsseFormWithStats {
    constructor(obj) {
        this.idDiag = obj.idDiag // Option[Long],
        this.idForm = obj.idForm // Option[Long],
        this.code = obj.code // Option[Long],
        this.stationType = obj.stationType // Option[Long],
        this.status = obj.status // Option[Long],
        this.formDate = obj.formDate // Option[DateTime],
        this.technicianLogin = obj.technicianLogin // Option[String],
        this.nbAnswered = obj.nbAnswered // Option[Int],
        this.nbTotal = obj.nbTotal // Option[Int],
        this.order = obj.order // Option[Int]
    }
}
