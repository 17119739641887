import i18n from 'simple-react-i18n'

const IMPORT_FORMATS = () => [
    /* {
            value: 'aquasysPivEau',
            label: i18n.aquasysPivEau,
            type: 'import'
        },
        {
            value: 'checkPiezo',
            label: i18n.checkPiezo,
            type: 'import'
        },*/
    {
        value: 'catchmentTrend',
        label: i18n.catchmentTrend,
        type: 'calcul',
        description: i18n.calculHypeTrendNitratesPesticides,
    },
    {
        value: 'wsAdesPiezo',
        label: i18n.wsAdesPiezo,
        type: 'import',
        description: i18n.usesAdesWebServiceImportPiezometers,
    },
    {
        value: 'wsAdesQualito',
        label: i18n.wsAdesQualito,
        type: 'import',
        description: i18n.usesAdesWebServiceImportQualitometers,
    },
    {
        value: 'importQuesu',
        label: i18n.importQuesu,
        type: 'import',
    },
    {
        value: 'importQesoutPiezo',
        label: 'import QESOUT Piezo',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'importQesoutQuality',
        label: i18n.importQesoutQuality,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'lowWater',
        label: i18n.lowWaterOnde,
        type: 'import',
    },
    {
        value: 'importEdilabo',
        label: 'EDILABO',
        type: 'import',
    },
    {
        value: 'importEdilaboDAI',
        label: 'EDILABO DAI',
        type: 'import',
    },
    {
        value: 'ddassdistri',
        label: i18n.ddassdistri,
        type: 'import',
    },
    {
        value: 'importPiveau',
        label: i18n.PIVEAU,
        type: 'import',
        description: 'Importe les données qualités d un fichier texte ou Excel en spécifiant les colonnes requises',
    },
    {
        value: 'importRsde',
        label: i18n.RSDE,
        type: 'import',
    },
    /* {
            value: 'meteofrance',
            label: i18n.meteofrance,
            type: 'import'
        },*/
    {
        value: 'synop',
        label: i18n.synop,
        type: 'import',
    },
    {
        value: 'selfControl',
        label: i18n.selfControl,
        type: 'import',
    },
    {
        value: 'ligura',
        label: i18n.ligura,
        type: 'import',
    },
    {
        value: 'hydro',
        label: i18n.hydro,
        type: 'import',
        description: 'Importe les stations hydro depuis le site Hydro Portail : https://hydro.eaufrance.fr/',
    },
    {
        value: 'tidegauge',
        label: i18n.tidegauge,
        type: 'import',
    },
    {
        value: 'oceanogram',
        label: i18n.oceanogram,
        type: 'import',
    },
    {
        value: 'surfaceQualitometer',
        label: i18n.surfaceQualitometer,
        type: 'import',
    },
    // {
    //     value: 'hubeau',
    //     label: i18n.hubeau,
    //     type: 'import'
    // },
    {
        value: 'vigicrues',
        label: i18n.vigicrues,
        type: 'import',
    },
    /* {
            value: 'SIAPLAB',
            label: i18n.SIAPLAB,
            type: 'import'
        },
        {
            value: 'ott',
            label: i18n.ott,
            type: 'import'
        },
        {
            value: 'diver',
            label: i18n.diver,
            type: 'import'
        },
        {
            value: 'paratronic',
            label: i18n.paratronic,
            type: 'import'
        },
        {
            value: 'ijinus',
            label: i18n.ijinus,
            type: 'import'
        },
        {
            value: 'excel',
            label: i18n.excel,
            type: 'import'
        },
        {
            value: 'csv',
            label: i18n.csv,
            type: 'import'
        },*/
    {
        value: 'csvAll',
        label: i18n.CSVGeneric,
        type: 'import',
        withFiles: true,
        description: 'Importe un fichier CSV ou Excel en spécifiant les colonnes requises',
    },
    {
        value: 'cityCenters',
        label: i18n.cityCentersImport,
        type: 'import',
    },
    {
        value: 'infoTerreBoreholesDepartment',
        label: i18n.InfoTerre,
        type: 'import',
    },
    {
        value: 'seba',
        label: 'SEBA',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'paratronicLNSXML',
        label: 'Paratronic LNS XML',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'MISfiles',
        label: 'Fichiers .MIS',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'processFileIAEau',
        label: 'Script IAEau',
        description: 'Permet de traiter des fichiers via script Python ou R dans IAEau.',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'HUBEAUpiezo',
        label: 'Hubeau piézométrie',
        type: 'import',
    },
    {
        value: 'HUBEAUquality',
        label: i18n.hubeauQuality,
        type: 'import',
    },
    {
        value: 'hydrobio',
        label: i18n.hubeauHydrobio,
        type: 'import',
    },
    {
        value: 'importOmnidia',
        label: i18n.OMNIDIA,
        type: 'import',
    },
    {
        value: 'quadrige',
        label: i18n.quadrige,
        type: 'import',
    },
    {
        value: 'distantFtp',
        label: 'Télécharger via FTP',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'scriptSSH',
        label: 'Lancer un script ssh',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'era5',
        label: 'ERA5',
        type: 'import',
    },
    {
        value: 'mfObs',
        label: 'Météo France',
        type: 'import',
    },
    {
        value: 'mfRadar',
        label: 'Météo France (Données Radar)',
        type: 'import',
    },
    {
        value: 'sieauInstance',
        label: 'Instance SIEau',
        type: 'import',
    },
    {
        value: 'mailUploadToFtp',
        label: 'Fichiers par email',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'veoliaPortalToFtp',
        label: 'Portail Veolia',
        type: 'import',
        withFiles: true,
    },
    {
        value: 'tapWater',
        label: 'Eau du robinet',
        type: 'import',
    },
    {
        value: 'quesoutAck',
        label: i18n.qesoutAck,
        type: 'export',
        withFiles: true,
    },
    {
        value: 'quesout',
        label: 'Export QESOUT PIEZO',
        type: 'export',
        withFiles: true,
    },
    {
        value: 'qesoutQualito',
        label: 'Export QESOUT Qualité',
        type: 'export',
        withFiles: true,
    },
    {
        value: 'exportQuesu',
        label: 'Export QUESU Qualité',
        type: 'export',
    },
    {
        value: 'hydroportailExport',
        label: 'Export HydroPortail',
        type: 'export',
        withFiles: true,
    },
    {
        value: 'edilaboExport',
        label: 'EDILABO DAI',
        type: 'export',
    },
    {
        value: 'postFacebook',
        label: i18n.postFacebook,
        type: 'export',
    },
    {
        value: 'modelCalculation',
        label: 'Calcul de modèles',
        type: 'calcul',
    },
    {
        value: 'piezoIndicatorsCalculation',
        label: 'Calcul d\'indicateurs piézo',
        type: 'calcul',
    },
    {
        value: 'qualityPostControl',
        label: i18n.qualityPostControl,
        type: 'controle',
    },
    {
        value: 'alertPluvio',
        label: i18n.alertPluvio,
        type: 'alert',
    },
    {
        value: 'alertHydro',
        label: i18n.alertHydro,
        type: 'alert',
    },
    {
        value: 'alertPiezo',
        label: i18n.alertPiezo,
        type: 'alert',
    },
    {
        value: 'outlierQuality',
        label: i18n.outlierQuality,
        type: 'alert',
    },
    {
        value: 'importMateriel',
        label: i18n.importMateriel,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'Veolia',
        label: i18n.veolia,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'importSiapLab',
        label: i18n.importSiapLab,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'veoliaVolume',
        label: i18n.veoliaVolume,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'aquadbSelection',
        label: i18n.aquadbSelection,
        type: 'import',
    },
    {
        value: 'saurVolume',
        label: i18n.saurVolume,
        type: 'import',
        withFiles: true,
    },
    {
        value: 'calypso',
        label: i18n.calypso,
        type: 'import',
    },
    {
        value: 'weatherMeasures',
        label: 'Weather Measures',
        type: 'import',
    },
    {
        value: 'edilaboRai',
        label: 'EDILABO RAI',
        type: 'export',
    },
    {
        value: 'activity',
        label: i18n.activities,
        type: 'import',
    },
    {
        value: 'establishment',
        label: i18n.establishment,
        type: 'import',
    },
    {
        value: 'jobPurgeMateriel',
        label: i18n.purgeMateriel,
        type: 'purge',
    },
    {
        value: 'jobPurgeQuality',
        label: i18n.purgeQuality,
        type: 'purge',
    },
    {
        value: 'contributorSynchro',
        label: i18n.contributorSynchroLabel,
        type: 'import',
    },
    {
        value: 'importEtatEco',
        label: i18n.importEtatEco,
        type: 'import',
    },
    {
        value: 'newslettersGeneration',
        label: i18n.newslettersGeneration,
        type: 'cms',
        description: i18n.usesCmsServiceGenerateNewsletters,
    },
    {
        value: 'CalculSeee',
        label: i18n.calculSeee,
        type: 'calcul',
        description: i18n.calculSeeeDesc,
    },
    {
        value: 'materielCompta',
        label: i18n.importMatAccounting,
        type: 'import',
        description: i18n.importMatAccounting,
    },
    {
        value: 'ArchiveJob',
        label: i18n.ArchiveJob,
        type: 'archive',
        description: i18n.ArchiveJobDesc,
    },
    {
        value: 'bathingWater',
        label: i18n.bathingWater,
        type: 'import',
        description: i18n.bathingWaterDesc,
    },
    {
        value: 'alerteClimatic',
        label: i18n.alertClimatic,
        type: 'alert',
        description: i18n.alertClimaticDesc,
    },
    {
        value: 'perimetersImport',
        label: i18n.perimetersImport,
        type: 'import',
    },

]

export { IMPORT_FORMATS }
