import { Accordion, AccordionSummary, Button, Card, CardContent, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import ProgressCard from 'components/card/ProgressCard'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { getStationTitle } from 'utils/StationUtils'
import { push } from '@lagunovsky/redux-react-router'
import { getStationArrowNav } from 'utils/ActionUtils'
import SimpleTabList from 'components/list/SimpleTabList'
import MessageCard from 'components/card/MessageCard'
import useBoolean from 'utils/customHook/useBoolean'
import OperationAction from '../../operation/actions/OperationAction'
import { statusIcon } from 'utils/StatusUtil'
import { getDate, getFullDate, getYear } from 'utils/DateUtil'
import { groupBy, isNil, orderBy, sumBy } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import ExportFileModal from 'components/modal/ExportFileModal'
import { exportModelFile, formatData, getModelFileType } from 'utils/ExportDataUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import ExportAction from 'export/actions/ExportAction'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { useParams } from 'react-router'
import { ALIGN, NewTable, SORT, WrapperAccordionDetails } from 'components/datatable/NewTable'
import OperationHydrobioStepper from './OperationHydrobioStepper'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import { SamplePointInfoDialog } from 'station/components/link/QualitySamplePointsPanel'

const YEAR = 'YEAR'
const PRODUCER = 'PRODUCER'
const DETERMINER = 'DETERMINER'

const FiltersField = ({
    onValidate = () => { },
}) => {
    const {
        supports,
        contributors,
        qualifications,
        listStatus,
        methods,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        listStatus: store.QualityReducer.status,
        methods: store.MethodReducer.methods,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    const [support, setSupport] = useState()
    const [status, setStatus] = useState()
    const [qualification, setQualification] = useState()
    const [samplers, setSamplers] = useState()
    const [determiners, setDeterminers] = useState()
    const [producers, setProducers] = useState()
    const [method, setMethod] = useState()

    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={3}>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={supports}
                            label={i18n.support}
                            displayWithCode
                            value={support}
                            onChange={setSupport}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={listStatus}
                            label={i18n.status}
                            nullLabel='&nbsp;'
                            value={status}
                            onChange={setStatus}
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={qualifications}
                            label={i18n.qualification}
                            nullLabel='&nbsp;'
                            value={qualification}
                            onChange={setQualification}
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.sampler}
                            onChange={setSamplers}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.determiner}
                            onChange={setDeterminers}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.producer}
                            onChange={setProducers}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={methods}
                            label={i18n.method}
                            value={method}
                            onChange={setMethod}
                            keyLabel='labelDisplay'
                            displayWithCode
                        />
                    </Grid2>
                    <Grid2 size={3} offset={9}>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => onValidate({
                                searchValue,
                                support,
                                status,
                                qualification,
                                samplers,
                                determiners,
                                producers,
                                method,
                            })}
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FiltersField.propTypes = {
    onValidate: PropTypes.func,
}

const ExportModal = ({
    open = false,
    onClose = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        typeEnvironmentModels,
        hydrobioOperations,
        qualitometer,
        supports,
        contributors,
        methods,
        qualifications,
        status,
    } = useSelector(store => ({
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        qualitometer: store.QualityReducer.qualitometer,
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        methods: store.MethodReducer.methods,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const onExport = (type) => {
        if (!hydrobioOperations.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const formatedOperation = hydrobioOperations.map(operation => {
            const point = qualitometer.link_samplePoints.find(({ idPoint }) => idPoint === operation.point)

            return {
                date: { value: getFullDate(operation.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                nbResults: { value: operation.nbResult, format: '0', cellType: 'number' },
                qualificationCode: { value: operation.qualification, format: '0', cellType: 'number' },
                qualification: getLabel(qualifications, operation.qualification),
                statusCode: { value: operation.status, format: '0', cellType: 'number' },
                status: getLabel(status, operation.status),
                supportCode: { value: operation.support, format: '0', cellType: 'number' },
                support: getLabel(supports, operation.support),
                sampler: getLabel(contributors, operation.sampler, 'labelDisplay'),
                determiner: getLabel(contributors, operation.determiner, 'labelDisplay'),
                producer: getLabel(contributors, operation.producer, 'labelDisplay'),
                methodCode: { value: operation.analysisMethod, format: '0', cellType: 'number' },
                method: getLabel(methods, operation.analysisMethod, 'labelWithCode'),
                samplePointCode: point?.identifiant || '',
                samplePoint: point?.name || '',
            }
        })
        const operationWithHeaders = [{ ...formatedOperation[0], headers: Object.keys(formatedOperation[0]) }, ...formatedOperation.slice(1, formatedOperation.length)]
        return dispatch(ExportAction.export(formatData(operationWithHeaders), type, `${qualitometer.code} - ${i18n.operations}`))
    }

    const tableExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => onExport('xlsx'),
        }, {
            type: i18n.csvFile,
            callback: () => onExport('csv'),
        }],
    }]
    const exportModel = typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                }),
            }],
        }
    })
    return (
        <ExportFileModal
            open={open}
            onClose={onClose}
            data={[...tableExport, ...exportModel]}
        />
    )
}

ExportModal.propTypes = {
    open: PropTypes.boolean,
    onClose: PropTypes.func,
}

const Group = ({
    formatedOperations = {},
    group = 'groupYear',
    idStation,
    order = 'asc',
}) => {
    const dispatch = useDispatch()

    const groupedOperations = useMemo(() => {
        return groupBy(formatedOperations, o => o[group] || i18n.unknown)
    }, [group, formatedOperations])

    const orderedKeys = orderBy(Object.keys(groupedOperations), key => key, order)

    return (
        <Grid2 container spacing={1}>
            {
                orderedKeys.map((key, i) => {
                    const nbResult = sumBy(groupedOperations[key], 'results')
                    return (
                        <Grid2 key={key} size={12}>
                            <Accordion defaultExpanded={i === 0}>
                                <AccordionSummary>
                                    <Grid2 container alignItems='center' sx={{ width: '100%' }}>
                                        <Grid2 size={8}>
                                            <span style={{ fontSize: '1.3rem' }}>
                                                {key}
                                            </span>
                                        </Grid2>
                                        <Grid2 size={2}>
                                            <span style={{ fontSize: '1.3rem' }}>
                                                {`${groupedOperations[key].length} ${groupedOperations[key].length > 1 ? i18n.operations : i18n.operation}`}
                                            </span>
                                        </Grid2>
                                        <Grid2 size={2}>
                                            <span style={{ fontSize: '1.3rem' }}>
                                                {`${nbResult} ${nbResult > 1 ? i18n.results : i18n.result}`}
                                            </span>
                                        </Grid2>
                                    </Grid2>
                                </AccordionSummary>
                                <NewTable
                                    rows={groupedOperations[key]}
                                    headers={[{ key: 'nullValue', tooltip: i18n.qualification }, 'date', 'nbResults', 'support', 'sampler', 'determiner', 'producer', 'method', 'samplePoint']}
                                    onClickRow={({ id }) => dispatch(push(`/station/quality/${idStation}/hydrobioOperation/${id}`))}

                                    defaultSort={{ column: 'date', direction: SORT.DESC }}
                                    WrapperComponent={WrapperAccordionDetails}
                                />
                            </Accordion>
                        </Grid2>
                    )
                })
            }
        </Grid2>
    )
}

Group.propTypes = {
    formatedOperations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        qualification: PropTypes.shape({
            value: PropTypes.element,
            className: PropTypes.string,
        }),
        date: PropTypes.shape({
            value: PropTypes.string,
            className: PropTypes.string,
            setTooltip: PropTypes.func,
        }),
        nbResults: PropTypes.shape({
            value: PropTypes.number,
        }),
        support: PropTypes.shape({
            value: PropTypes.string,
        }),
        sampler: PropTypes.shape({
            value: PropTypes.string,
        }),
        determiner: PropTypes.shape({
            value: PropTypes.string,
        }),
        producer: PropTypes.shape({
            value: PropTypes.string,
        }),
        method: PropTypes.shape({
            value: PropTypes.string,
        }),
        samplePoint: PropTypes.shape({
            value: PropTypes.string,
        }),
        groupYear: PropTypes.number,
        groupProducer: PropTypes.string,
        groupDeteminer: PropTypes.string,
        results: PropTypes.number,
    })),
    group: PropTypes.oneOf(['groupYear', 'groupProducer', 'groupDeteminer']),
    idStation: PropTypes.string,
    order: PropTypes.string,
}

const HydrobioOperationsApp = () => {
    const dispatch = useDispatch()
    const { id: idStation } = useParams()

    const {
        accountUser,
        qualitometer,
        qualitometers,
        hydrobioOperations,

        supports,
        contributors,
        methods,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        hydrobioOperations: store.OperationReducer.hydrobioOperations,

        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        methods: store.MethodReducer.methods,
    }), shallowEqual)

    const {
        value: isLoaded,
        setTrue: setLoadedToTrue,
    } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    const {
        value: isOpen,
        setTrue: openModal,
        setFalse: closeModal,
    } = useBoolean(false)

    const {
        value: isOperationPopupOpen,
        setTrue: openOperationPopup,
        setFalse: closeOperationPopup,
    } = useBoolean(false)

    const {
        value: isSamplePointOpen,
        setTrue: onOpenSamplePoint,
        setFalse: onCloseSamplePoint,
    } = useBoolean(false)

    const [selectedPoint, setSelectedPoint] = useState()

    const [filter, setFilter] = useState({})
    /*
    filter = {
        searchValue, // string
        support, // string
        status, // string
        qualification, // string
        samplers, // array[string]
        determiners, // array[string]
        producers, // array[string]
        method, // string
    }
    */

    useEffect(() => {
        dispatch(OperationAction.loadHydrobioOperations(idStation, setProgress)).then(setLoadedToTrue)
        dispatch(TaxonAction.fetchTaxons())
    }, [dispatch, idStation, setLoadedToTrue])

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${idStation}`,
    }, {
        title: i18n.hydrobioOperations,
        href: `station/quality/${idStation}/hydrobioOperation`,
    }], [qualitometer])

    useActions(() => {
        const actions = {
            exportList: [{
                onClick: openModal,
                label: i18n.excel,
            }],
            // new: () => dispatch(push(`/station/quality/${idStation}/hydrobioOperation/new`)),
            // links: [],
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer.id, s => dispatch(push(`/station/quality/${s.id}/hydrobioOperation`))),
        }
        if (accountUser.consultant === '1') return actions
        return {
            ...actions,
            new: openOperationPopup,
            calculateIndexes: {
                qualitometer: qualitometer.id,
                operations: hydrobioOperations.map(o => o.id),
            },
        }
    }, [qualitometer, qualitometers, hydrobioOperations])

    const formatedOperations = useMemo(() => {
        return orderBy(hydrobioOperations, 'date', ['desc']).map(operation => {
            const date = getDate(operation.date)

            const labelSupport = getLabel(supports, operation.support, 'labelWithCode')
            const labelProducer = getLabel(contributors, operation.producer, 'labelDisplay')
            const labelDeterminer = getLabel(contributors, operation.determiner, 'labelDisplay')
            const labelSampler = getLabel(contributors, operation.sampler, 'labelDisplay')
            const labelMethod = getLabel(methods, operation.analysisMethod, 'labelWithCode')

            const point = qualitometer.link_samplePoints.find(({ idPoint }) => idPoint === operation.point)
            const labelPoint = `${point?.name || ''} ${point?.identifiant ? `[${point.identifiant}]` : ''}`

            return {
                id: operation.id,

                nullValue: { value: statusIcon(operation, 25, true), noPadding: true, align: ALIGN.CENTER },
                date: { value: date, tooltip: getFullDate(operation.date), sortValue: operation.date },
                nbResults: { value: operation.nbResult },
                support: { value: labelSupport },
                sampler: { value: labelSampler },
                determiner: { value: labelDeterminer },
                producer: { value: labelProducer },
                method: { value: labelMethod },
                samplePoint: {
                    value: labelPoint,
                    align: ALIGN.SPACE_BETWEEN,
                    rightIcon: !isNil(operation.point) ? { icon: 'info', color: 'black' } : undefined,
                    onClick: () => {
                        if (!isNil(operation.point)) {
                            setSelectedPoint(operation.point)
                            onOpenSamplePoint()
                        }
                    },
                },

                groupYear: getYear(operation.date),
                groupProducer: labelDeterminer,
                groupDeteminer: labelProducer,
                results: operation.nbResult,
                searchValue: searchAllCharacters(`${date},${labelSupport},${labelProducer},${labelDeterminer},${labelSampler},${labelMethod},${labelPoint}`),
                qualificationId: operation.qualification,
                statusId: operation.status,
                supportId: operation.support,
                producerId: operation.producer,
                determinerId: operation.determiner,
                samplerId: operation.sampler,
                analysisMethodId: operation.analysisMethod,
            }
        })
    }, [contributors, hydrobioOperations, methods, qualitometer, supports])

    const filteredOperations = useMemo(() => {
        const {
            searchValue, // string
            support, // string
            status, // string
            qualification, // string
            samplers, // array[String]
            determiners, // array[String]
            producers, // array[String]
            method, // string
        } = filter

        const searchCharacters = searchAllCharacters(searchValue)

        const filterSearchValue = hasValue(searchValue) ? formatedOperations.filter(op => op.searchValue.includes(searchCharacters)) : formatedOperations
        const filterQualification = hasValue(qualification) ? filterSearchValue.filter(op => op.qualificationId === qualification) : filterSearchValue
        const filterStatus = hasValue(status) ? filterQualification.filter(op => op.statusId === status) : filterQualification
        const filtersupport = hasValue(support) ? filterStatus.filter(op => `${op.supportId}` === support) : filterStatus
        const filterProducer = producers?.length ? filtersupport.filter(op => producers.includes(op.producerId)) : filtersupport
        const filterDeterminer = determiners?.length ? filterProducer.filter(op => determiners.includes(op.determinerId)) : filterProducer
        const filterSample = samplers?.length ? filterDeterminer.filter(op => samplers.includes(op.samplerId)) : filterDeterminer
        const filterMethod = hasValue(method) ? filterSample.filter(op => `${op.analysisMethodId}` === method) : filterSample
        return filterMethod
    }, [filter, formatedOperations])

    const samplePoint = qualitometer.link_samplePoints.find(sp => sp.idPoint === selectedPoint)

    return (
        <div style={{ paddingTop: '10px', width: '1200px', marginRight: 'auto', marginLeft: 'auto' }}>
            {
                !isLoaded && <ProgressCard progress={progress} />
            }
            {
                isLoaded && (
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <FiltersField
                                onValidate={setFilter}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <SimpleTabList
                                defaultTab={YEAR}
                                tabs={[
                                    {
                                        constant: YEAR,
                                        label: i18n.perYear,
                                        icon: 'insert_invitation',
                                    },
                                    {
                                        constant: PRODUCER,
                                        label: i18n.byProducer,
                                        icon: 'business_center',
                                    },
                                    {
                                        constant: DETERMINER,
                                        label: i18n.byDeterminer,
                                        icon: 'colorize',
                                    },
                                ]}
                            >
                                {
                                    tab => {
                                        if (!filteredOperations.length) {
                                            return (
                                                <MessageCard>{i18n.noHydrobioOperation}</MessageCard>
                                            )
                                        }
                                        return (
                                            <div style={{ padding: '10' }}>
                                                {tab === YEAR && <Group formatedOperations={filteredOperations} group='groupYear' idStation={idStation} order='desc' />}
                                                {tab === PRODUCER && <Group formatedOperations={filteredOperations} group='groupProducer' idStation={idStation} />}
                                                {tab === DETERMINER && <Group formatedOperations={filteredOperations} group='groupDeteminer' idStation={idStation} />}
                                            </div>
                                        )
                                    }
                                }
                            </SimpleTabList>
                        </Grid2>
                    </Grid2>
                )
            }
            <ExportModal
                open={isOpen}
                onClose={closeModal}
            />
            <OperationHydrobioStepper
                isOpen={isOperationPopupOpen}
                onClose={closeOperationPopup}
            />
            <SamplePointInfoDialog
                isOpen={isSamplePointOpen}
                onClose={onCloseSamplePoint}
                samplePoint={samplePoint}
            />
        </div>
    )
}

export default HydrobioOperationsApp