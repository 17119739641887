import React, { forwardRef, useRef, useState } from 'react'
import { Grid, Popover } from '@mui/material'
import PropTypes from 'prop-types'
import Checkbox from '../forms/Checkbox'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import { ButtonMUI } from '../styled/Buttons'
import { StyledFieldSet, StyledLegend } from '../StyledElements'
import RadioButtons from '../forms/RadioButtons'
import Select from '../forms/Select'
import NumberField from '../forms/NumberField'
import { keys } from 'lodash'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../utils/StatusUtil'
import { AUTO, AVERAGE, BRUTE, MAX, MIN } from '../../utils/constants/MeasureConstants'
import { PIEZOMETRY_COLOR } from 'utils/constants/ColorTheme'
import { HYDRO_MEASURE_COTE } from 'hydrometry/constants/HydrometryConstants'
import { getHydroCote } from './ChartOptionsChip'

const DAY = 'DAY'
const HOUR = 'HOUR'

const CUMUL_MAX = 'CUMUL_PERSO_MAX'
const CUMUL_MIN = 'CUMUL_PERSO_MIN'
const CUMUL_SUM = 'CUMUL_PERSO_SUM'
const CUMUL_AVERAGE = 'CUMUL_PERSO_AVERAGE'

const INITIAL_BRUTE = 'initialBrute'
const PERSONALIZED_GROUPING = 'personalizedGrouping'

const ModeItemStatus = ({ label, checked, onClick, icon }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <Grid paddingRight={'1rem'}>{icon}</Grid>
        <Grid>{label}</Grid>
    </Grid>
)
ModeItemStatus.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.string,
}

const ModeItem = ({ label, color, checked, onClick, iconInfos }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <span>
            <span className={`${color} arrests-level-panel ${color}-text`}>OO</span>
            {label} {iconInfos ? <Icon icon={iconInfos.type} tooltip={iconInfos.tooltip} style={ { fontSize: 18, color: iconInfos.color } } /> : ''}
        </span>
    </Grid>
)
ModeItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    iconInfos: PropTypes.shape({
        type: PropTypes.string,
        color: PropTypes.string,
        tooltip: PropTypes.string,
    }),
}

const ReferenceLevelItem = ({ label, color, checked, onClick, disabled }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={!disabled ? onClick : () => {}}
    >
        <Checkbox checked={checked} disabled={disabled} />
        <Chip
            label={label}
            color={color}
            style={{ padding: '0px 6px' }}
        />
    </Grid>
)
ReferenceLevelItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

const ChipLabel = forwardRef(({
    label,
    onClick = () => {},
    sx = {},
    isOpen= false,
}, ref) => (
    <Grid
        ref={ref}
        onClick={onClick}
        sx={{
            cursor: 'pointer',
            backgroundColor: !isOpen ? 'rgba(53, 96, 159, 1)' : 'white',
            color: !isOpen ? 'white' : 'rgba(53, 96, 159, 1)',
            padding: '0px 9px',
            borderTop: '2px solid rgba(53, 96, 159, 1)',
            borderLeft: '2px solid rgba(53, 96, 159, 1)',
            borderRight: '2px solid rgba(53, 96, 159, 1)',
            borderRadius: isOpen ? '5px 5px 0 0' : '5px',
            ...sx,
        }}
        container
        justifyContent={'flex-start'}
        alignItems={'center'}
    >
        <Grid item padding={'4px'}>
            <Icon tooltip={ i18n.hydrometricGrouping } icon={'settings'}/>
        </Grid>
        <Grid item sx={{ padding: '5px 5px 5px 2px' }}>
            {getHydroCote(label).label}
        </Grid>
    </Grid>

))

ChipLabel.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    isOpen: PropTypes.bool,
    displayModes: PropTypes.shape({
        auto: PropTypes.bool,
        brute: PropTypes.bool,
        average: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
        personalizedGrouping: PropTypes.bool,
        personalizedGroupingValue: PropTypes.number,
    }),
}

const Chip = ({
    label = '',
    color = '',
    style = {},
}) => (
    <span
        style={{
            cursor: 'pointer',
            backgroundColor: color,
            color: 'white',
            padding: '0.3rem 0.6rem',
            borderRadius: '1rem',
            ...style,
        }}
    >
        {label}
    </span>
)

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
}


const SimpleChartTabsHydro = ({
    displayCote = HYDRO_MEASURE_COTE.HEIGHT,
    displayModes = {
        auto: false,
        brute: false,
        min: false,
        max: false,
        average: false,
        initialBrute: false,

        personalizedGrouping: false,
        personalizedGroupingValue: '',
    },
    changeDisplayCote = () => {},
    changeDisplayModes = () => {},
    displayModesUniqChoise = false,
    accuracyValues = {},
    changeAccuracy = {},
    hideAccuracy = true,
}) => {
    const anchorEl = useRef()
    const [openModal, setOpenModal] = useState(false)

    const [tmpReferenceLevelSelected, setTmpReferenceLevelSelected] = useState(displayCote)
    const [referenceLevelSelected, setReferenceLevelSelected] = useState(displayCote)
    const [referenceLevel, setReferenceLevel] = useState({
        height: displayCote === HYDRO_MEASURE_COTE.HEIGHT,
        NGF: displayCote === HYDRO_MEASURE_COTE.NGF,
    })

    const [optionDayHourSelected, setOptionDayHourSelected] = useState(HOUR)
    const [hoursCumul, setHoursCumul] = useState(1)
    const [optionCumulSelected, setOptionCumulSelected] = useState(CUMUL_MAX)

    const [accuracy, setAccuracy] = useState(keys(accuracyValues).length ? accuracyValues : {
        qualifications: getQualificationSelectOptions().map(q => q.code),
        status: getStatusSelectOptions().map(s => s.code),
    })
    const [showReferenceLevel, setShowReferenceLevel] = useState(true)
    const [showChoiceCurves, setShowChoiceCurves] = useState(true)
    const [showQualification, setShowQualification] = useState(false)
    const [showStatus, setShowStatus] = useState(false)

    const [group, setGroup] = useState(displayModes)

    const onChangeReferenceLevel = measureCote => {
        switch (measureCote) {
            case HYDRO_MEASURE_COTE.HEIGHT :
                setTmpReferenceLevelSelected(HYDRO_MEASURE_COTE.HEIGHT)
                setReferenceLevel({
                    height: true,
                    NGF: false,
                })
                break
            case HYDRO_MEASURE_COTE.NGF:
            default:
                setTmpReferenceLevelSelected(HYDRO_MEASURE_COTE.NGF)
                setReferenceLevel({
                    height: false,
                    NGF: true,
                })
                break
        }
    }


    const onSave = () => {
        const day = `${optionCumulSelected}_${hoursCumul * 24}`
        const hour = `${optionCumulSelected}_${hoursCumul}`
        const grp = { ...group, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour }

        setReferenceLevelSelected(tmpReferenceLevelSelected)
        setOpenModal(false)
        changeDisplayCote(tmpReferenceLevelSelected)
        changeDisplayModes(grp)
        if (!hideAccuracy) {
            changeAccuracy(accuracy)
        }
    }


    const handleStatusClick = (v) => {
        const newStatus = accuracy.status.includes(v) ? accuracy.status.filter((s) => s !== v) : [...accuracy.status, v]
        if (!newStatus.length) {
            setAccuracy(prev => ({ ...prev, status: getStatusSelectOptions().map(s => s.code) }))
        } else {
            setAccuracy(prev => ({ ...prev, status: newStatus }))
        }
    }
    const handleQualificationsClick = (v) => {
        const newQualifications = accuracy.qualifications.includes(v) ? accuracy.qualifications.filter((s) => s !== v) : [...accuracy.qualifications, v]
        if (!newQualifications.length) {
            setAccuracy(prev => ({ ...prev, qualifications: getQualificationSelectOptions().map(q => q.code) }))
        } else {
            setAccuracy(prev => ({ ...prev, qualifications: newQualifications }))
        }
    }


    const onChangesMode = (mode) => {
        const defaultGroup = {
            auto: false,
            brute: false,
            min: false,
            max: false,
            average: false,
            personalizedGrouping: false,
            initialBrute: false,
        }
        switch (mode) {
            case AUTO :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, auto: true })
                    break
                }
                setGroup(prev => !prev.auto ? ({
                    auto: !prev.auto,
                    max: prev.auto,
                    min: prev.auto,
                    average: prev.auto,
                    brute: prev.auto,
                    personalizedGrouping: prev.auto,
                    initialBrute: prev.auto,
                }) : {
                    auto: true,
                    max: false,
                    min: false,
                    average: false,
                    brute: false,
                    initialBrute: false,
                    personalizedGrouping: false,
                })
                break
            case BRUTE :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, brute: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(!prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    brute: !prev.brute,
                }))
                break
            case MIN :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, min: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || !prev.min || prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    min: !prev.min,
                }))
                break
            case MAX :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, max: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || prev.min || !prev.max || prev.average || prev.personalizedGrouping || prev.initialBrute),
                    max: !prev.max,
                }))
                break
            case AVERAGE :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, average: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || prev.min || prev.max || !prev.average || prev.personalizedGrouping || prev.initialBrute),
                    average: !prev.average,
                }))
                break
            case PERSONALIZED_GROUPING :
                const day = `${optionCumulSelected}_${hoursCumul * 24}`
                const hour = `${optionCumulSelected}_${hoursCumul}`
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, personalizedGrouping: true, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || prev.min || prev.max || prev.average || prev.initialBrute || !prev.personalizedGrouping),
                    personalizedGrouping: !prev.personalizedGrouping,
                    personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour,
                }))
                break
            case INITIAL_BRUTE : default :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, initialBrute: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    auto: !(prev.brute || prev.min || prev.max || prev.average || prev.personalizedGrouping || !prev.initialBrute),
                    initialBrute: !prev.initialBrute,
                }))
                break
        }
    }

    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]
    const optionCumul = [
        { value: CUMUL_MAX, label: i18n.max },
        { value: CUMUL_MIN, label: i18n.min },
        { value: CUMUL_SUM, label: i18n.sum },
        { value: CUMUL_AVERAGE, label: i18n.average },
    ]

    const optionsChoiceCurves = [
        { label: i18n.auto, color: 'black', checked: group.auto, onClick: () => onChangesMode(AUTO), iconInfos: { type: 'info', color: 'grey', tooltip: i18n.descriptionCumulAuto90 } },
        { label: i18n.brute, color: 'black', checked: group.brute, onClick: () => onChangesMode(BRUTE), iconInfos: { type: 'warning', color: 'orange' } },
        { label: i18n.min, color: 'red', checked: group.min, onClick: () => onChangesMode(MIN) },
        { label: i18n.max, color: 'blue', checked: group.max, onClick: () => onChangesMode(MAX) },
        { label: i18n.average, color: 'green', checked: group.average, onClick: () => onChangesMode(AVERAGE) },
        { label: i18n.personalizedGrouping, color: 'orange', checked: group.personalizedGrouping, onClick: () => onChangesMode(PERSONALIZED_GROUPING) },
    ]


    return (
        <Grid>
            <ChipLabel
                label={referenceLevelSelected}
                onClick={() => setOpenModal(prev => !prev)}
                ref={anchorEl}
                isOpen={openModal}
                sx={{ height: '2.7rem' }}
            />
            <Popover
                open={openModal}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '0 5px 5px 5px',
                        },
                    },
                }}
                onClose={() => setOpenModal(false)}
            >
                <Grid width={300} sx={{ border: '2px solid rgba(53, 96, 159, 1)', padding: '10px' }}>
                    <Grid
                        container
                        direction='column'
                        spacing={0.5}
                    >
                        <Grid
                            container
                            onClick={() => setShowReferenceLevel(prev => !prev)}
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                paddingTop: '5px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid item>{i18n.referenceLevel}</Grid>
                            <Grid item>{!showReferenceLevel ? '▼' : '▲'}</Grid>
                        </Grid>
                        {showReferenceLevel && (
                            <>
                                <ReferenceLevelItem
                                    label={i18n.waterHeight}
                                    color={PIEZOMETRY_COLOR.DEPTH}
                                    checked={referenceLevel.height}
                                    onClick={() => onChangeReferenceLevel(HYDRO_MEASURE_COTE.HEIGHT)}
                                />
                                <ReferenceLevelItem
                                    label={i18n.ngf}
                                    color={PIEZOMETRY_COLOR.NGF}
                                    checked={referenceLevel.NGF}
                                    onClick={() => onChangeReferenceLevel(HYDRO_MEASURE_COTE.NGF)}
                                    disabled // A retirer quand le back sera fait
                                />
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        spacing={0.5}
                    >
                        <Grid
                            onClick={() => setShowChoiceCurves(prev => !prev)}
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                            }}
                        >
                            <Grid item>{i18n.choiceCurves}</Grid>
                            <Grid item>{ !showChoiceCurves ? '▼' : '▲' }</Grid>
                        </Grid>
                        {
                            showChoiceCurves && (
                                <>
                                    {
                                        optionsChoiceCurves.map(o => (
                                            <ModeItem
                                                key={o.label}
                                                label={o.label}
                                                color={o.color}
                                                checked={o.checked}
                                                onClick={o.onClick}
                                                iconInfos={o.iconInfos}
                                            />
                                        ))
                                    }
                                    { !!group.personalizedGrouping && (
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.modeGrouping}</StyledLegend>
                                            <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                                <Grid item>
                                                    <RadioButtons
                                                        colOption={6}
                                                        label={i18n.modeDaysHours}
                                                        elements={optionDayHour}
                                                        selected={optionDayHourSelected}
                                                        onChange={setOptionDayHourSelected}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                </Grid>
                                                <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                                    <Select
                                                        label={i18n.modeGrouping}
                                                        options={optionCumul}
                                                        value={optionCumulSelected}
                                                        onChange={setOptionCumulSelected}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                    <NumberField
                                                        title={i18n.numberHoursDays}
                                                        value={hoursCumul}
                                                        onChange={setHoursCumul}
                                                        min={1}
                                                        disabled={!group.personalizedGrouping}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </StyledFieldSet>
                                    )}
                                </>
                            )
                        }
                        { !hideAccuracy && (
                            <>
                                <Grid
                                    onClick={() => setShowQualification(prev => !prev)}
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{
                                        paddingTop: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item>{i18n.qualification} ({accuracy.qualifications?.length}/4)</Grid>
                                    <Grid item>{ !showQualification ? '▼' : '▲' }</Grid>
                                </Grid>
                                {
                                    showQualification && (
                                        <>
                                            {
                                                getQualificationSelectOptions().map(q => (
                                                    <ModeItemStatus
                                                        key={q.code}
                                                        label={q.name}
                                                        icon={q.icon}
                                                        checked={accuracy.qualifications.includes(q.code)}
                                                        onClick={() => handleQualificationsClick(q.code)}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                                <Grid
                                    onClick={() => setShowStatus(prev => !prev)}
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{
                                        paddingTop: '5px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item>{i18n.status} ({accuracy.status?.length}/4)</Grid>
                                    <Grid item>{!showStatus ? '▼' : '▲'}</Grid>
                                </Grid>
                                {
                                    showStatus && (
                                        <>
                                            {
                                                getStatusSelectOptions().map(q => (
                                                    <ModeItemStatus
                                                        key={q.code}
                                                        label={q.name}
                                                        icon={q.icon}
                                                        checked={accuracy.status.includes(q.code)}
                                                        onClick={() => handleStatusClick(q.code)}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </>
                        )}
                        <Grid container item sx={{ cursor: 'pointer' }} >
                            <Grid item xs={12}>
                                <ButtonMUI
                                    fullWidth
                                    variant={'contained'}
                                    onClick={ onSave }
                                >
                                    { i18n.toLoad }
                                </ButtonMUI>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    )
}

SimpleChartTabsHydro.propTypes = {
    qualificationsParent: PropTypes.shape({
        unqualified: PropTypes.bool,
        correct: PropTypes.bool,
        incorrect: PropTypes.bool,
        uncertain: PropTypes.bool,
    }),
    displayCote: PropTypes.number,
    displayModes: PropTypes.shape({
        auto: PropTypes.bool,
        brute: PropTypes.bool,
        average: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
    }),
    accuracyValues: PropTypes.shape({
        status: PropTypes.arrayOf(PropTypes.number),
        qualifications: PropTypes.arrayOf(PropTypes.number),
    }),
    changeDisplayCote: PropTypes.func,
    changeDisplayModes: PropTypes.func,
    changeQualifacation: PropTypes.func,
    displayModesUniqChoise: PropTypes.bool,
    changeAccuracy: PropTypes.func,
    hideAccuracy: PropTypes.bool,
}

export default SimpleChartTabsHydro
