module.exports = {
    RECEIVE_ALL_QUALITOMETER_OPERATIONS: 'RECEIVE_ALL_QUALITOMETER_OPERATIONS',
    RECEIVE_ALL_QUALIFICATION: 'RECEIVE_ALL_QUALIFICATION',
    RECEIVE_OPERATION_ANALYSIS: 'RECEIVE_OPERATION_ANALYSIS',
    RECEIVE_ALL_REMARKS: 'RECEIVE_ALL_REMARKS',
    RECEIVE_ALL_FRACTIONS: 'RECEIVE_ALL_FRACTIONS',
    RECEIVE_OPERATION: 'RECEIVE_OPERATION',
    RECEIVE_CALCULATE_PARAMETERS: 'RECEIVE_CALCULATE_PARAMETERS',
    RESET_OPERATION: 'RESET_OPERATION',
    RESET_ANALYSIS_OPERATION: 'RESET_ANALYSIS_OPERATION',
    RESET_SINGLE_OPERATION: 'RESET_SINGLE_OPERATION',
    RECEIVE_OPERATION_INDICES: 'RECEIVE_OPERATION_INDICES',
    RECEIVE_OPERATION_FILES: 'RECEIVE_OPERATION_FILES',
    RECEIVE_ENVIRONMENTAL_CONDITION: 'RECEIVE_ENVIRONMENTAL_CONDITION',
    RECEIVE_HYDROBIO_OPERATIONS: 'RECEIVE_HYDROBIO_OPERATIONS',
    RECEIVE_HYDROBIO_OPERATION: 'RECEIVE_HYDROBIO_OPERATION',
    RECEIVE_HYDROBIO_LIST: 'RECEIVE_HYDROBIO_LIST',
    RECEIVE_ALL_HYDROBIO_LIST: 'RECEIVE_ALL_HYDROBIO_LIST',
    RECEIVE_OPERATION_SAMPLES: 'RECEIVE_OPERATION_SAMPLES',
    RECEIVE_ALL_OPERATION_SAMPLES: 'RECEIVE_ALL_OPERATION_SAMPLES',
    RECEIVE_OPERATION_COVERS: 'RECEIVE_OPERATION_COVERS',
    RECEIVE_ALL_OPERATION_COVERS: 'RECEIVE_ALL_OPERATION_COVERS',
    ANALYSIS: 'ANALYSIS',
    OPERATION: 'OPERATION',
}
