import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, removeNullKeys } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisIntervalFormatter,
    logIcon,
    setLogOptions, setYOptions,
    swapVertical,
    yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import EChart from '../../../../components/echart/EChart'
import Axis from '../../../../components/echart/Axis'
import moment from 'moment'
import { enumerateBetweenDates, getDate, getDateWithHour, getFullDate } from '../../../../utils/DateUtil'
import { groupBy, last, maxBy, orderBy, partition } from 'lodash'
import { hasValue, nFormatter, round } from '../../../../utils/NumberUtil'
import Line from '../../../../components/echart/series/Line'
import PropTypes from 'prop-types'
import { getLocalStorageJson } from '../../../../utils/FormUtils'
import {
    getEventGraph,
    getEventsBar,
    piezoMeasureIsValid,
} from '../../../../utils/PiezometryUtils'
import HydroSuiviAssociationsTab2 from './chartTabs2/HydroSuiviAssociationsTab2'
import HydroSuiviModelTab2 from './chartTabs2/HydroSuiviModelTab2'
import AppStore from '../../../../store/AppStore'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLinks, getStationTitle } from '../../../../utils/StationUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import {
    H_STATION_HYDRO_FOLLOW_UP,
} from '../../../../account/constants/AccessRulesConstants'
import { getMeasureJplus } from '../../../../iaeau/utils/IAEauUtils'
import { getStationArrowNav } from 'utils/ActionUtils'
import ActionComponent from 'components/ActionComponent'
import { push } from '@lagunovsky/redux-react-router'
import { repeatList } from '../../../../utils/ListUtil'
import {
    generateGradient,
    getColorCircleElement,
    getColorCircleEvent,
    getEventColor,
    getRGBColor,
} from '../../../../utils/ColorUtil'
import { chunkWithWords, getExportFormat } from 'utils/StringUtil'
import { exportFile } from 'utils/ExportDataUtil'
import SimpleTabSideList from '../../../../components/navbar/SimpleTabSideList'
import ChartOptionsChip from '../../../../components/echart/ChartOptionsChip'
import MUIChartTabs from '../../../../components/echart/MUIChartTabs'
import { CIVIL_YEAR } from '../../../../alerting/constants/ChartFollowContants'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { EVENT_TYPES_CODES } from '../../../../events/constants/EventsConstants'
import StationSuiviEventsTab from '../../../../piezometry/components/suivi/components/chartTabs2/StationSuiviEventsTab'
import { HYDRO_L_STORAGE, HYDRO_TYPEID } from '../../../constants/HydrometryConstants'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import HydrometerStationAction from '../../../../station/actions/HydrometerStationAction'
import DtoHydrometerChartOptions from '../../../../station/dto/hydrometricStation/DtoHydrometerChartOptions'
import DtoHydroStats from '../../../dto/chronicMeasures/DtoHydroStats'
import HydrometryAction from '../../../actions/HydrometryAction'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import HydroSuiviStatTab2 from './chartTabs2/HydroSuiviStatTab2'
import HydroSuiviPastYearsTab2 from './chartTabs2/HydroSuiviPastYearsTab2'
import OptionsToolPanel from '../../../../piezometry/components/validation/tools/OptionsToolPanel'
import HydroSuiviThresholds2 from './chartTabs2/HydroSuiviThresholds2'
import DtoHydroMeasureValidation from '../../../dto/chronicMeasures/DtoHydroMeasureValidation'

const STATISTICS = 'statistics'
const PREVIOUSYEARS = 'previousYears'
const LINKEDSTATIONS = 'linkedStations'
const CHARTOPTIONS = 'chartOptions'
const THRESHOLDS = 'thresholds'
const MODELS = 'models'
const EVENTS = 'events'

const defaultDisplayModes = {
    brute: false,
    min: false,
    max: true,
    average: false,
    personalizedGrouping: false,
    personalizedGroupingValue: undefined,
}

const filterMeasureStatusQualif = (m, statusQualif) => statusQualif.status.includes(m.status) && statusQualif.qualifications.includes(m.qualification)

class HydroSuiviChart2 extends ActionComponent {
    constructor(props) {
        super(props)

        this.state = {
            minDate: moment().startOf('year').valueOf(), // date min du graphique
            maxDate: null, // date max du graphique,
            typeId: HYDRO_TYPEID.HEIGHT, // Type de donnée qui est sélectionné, hauteur hydro par défaut

            statsSeries: [],
            yearSeries: [],
            associationsSeries: [],
            associationsAxis: [],
            selectedAssociations: [], // liste de String qui représente la liste des donnes associées qui sont affichées (réutilisé sur l'onglet des données prévi)
            modelsSeries: [],
            thresholds: [],
            hasValidMeasures: null, // true si les données piézos contiennent au moins une mesure valide
            chartOptions: [],
            chartOptionsAreLoaded: false,
            displayEvents: true,
            modeDate: 'dailyDate',
            time: CIVIL_YEAR, // tab temporelle sélectionnée
            measures: [], // max measures
            bruteMeasures: [], // brute measures
            bruteSerie: [],
            maxSerie: [],
            maxMeasures: [],
            minSerie: [],
            minMeasures: [],
            averageSerie: [],
            averageMeasures: [],
            personalizedGroupingSerie: [],
            personalizedGroupingMeasures: [],
            withBrutes: false,
            displayModes: getLocalStorageJson(HYDRO_L_STORAGE.DISPLAY_MODES_SUIVI) ?? defaultDisplayModes,
            filterStatusQualif: getLocalStorageJson(HYDRO_L_STORAGE.STATUS_QUALIF_SUIVI) ?? { // filtre sur statut et qualification (fait côté back)
                qualifications: getQualificationSelectOptions().map(q => q.code),
                status: getStatusSelectOptions().map(s => s.code),
            },
            tabLocked: false,
            // log mode
            openLogModal: false,
            displayLog: false,
            messageLogModal: '',

            // yZoom
            yZoom: {
                min: undefined,
                max: undefined,
            },
        }
        this.series = []
    }

    componentDidMount() {
        const { hydrometricStation, hydrometryDataTypes } = this.props
        this.setTitle(hydrometricStation)
        this.props.fetchHydroStatistics(parseInt(hydrometricStation.id))
        if (!componentHasHabilitations(H_STATION_HYDRO_FOLLOW_UP)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.getMaxMeasures()
        this.loadOtherDisplayMeasuresIfChecked()
        this.props.fetchHydrometerChartOptions(hydrometricStation.id, () => this.setState({ chartOptions: this.props.chartOptions, chartOptionsAreLoaded: true }))
        this.setActions({
            exportmodel: () => ({
                stationId: hydrometricStation.id.toString(),
                stationCode: hydrometricStation.code,
                stationType: hydrometricStation.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            links: getLinks(hydrometricStation, this.props),
            arrowNav: getStationArrowNav('hydrometry', this.props.hydrometricStations, hydrometricStation.id, s => this.props.push(`/station/hydrometry/${s.id}/hydrometricFollowUp`)),
        })
        if (!hydrometryDataTypes.length) {
            this.props.fetchHydrometryDataTypes()
        }
    }

    // chope tout l'historique des max au chargement de l'écran, car réutilisé dans plein d'endroits (ex: années précédentes)
    getMaxMeasures = () => {
        const { typeId } = this.state
        HydrometryAction.promiseHydroChronicMeasures({
            stationId: this.props.hydrometricStation.id,
            groupFunc: 'MAX',
            dataType: typeId,
        }).then(res => {
            const measures = res.map(m => new DtoHydroMeasureValidation(m))
            const series = this.calculateSerie(measures, this.props.hydroStatistics.find(t => t.typeId === typeId)?.label ?? i18n.chronic, '#3d72d2')
            this.setState({
                measures,
                maxSerie: series,
                hasValidMeasures: measures.some(m => piezoMeasureIsValid(m)),
                height: window.innerHeight - window.$('#chart').offset().top - 80,
            })
        })
    }

    loadOtherDisplayMeasuresIfChecked = () => {
        const otherSeriesToFetch = [
            { key: 'personalizedGrouping', groupFunc: this.state.displayModes.personalizedGroupingValue, color: '#ff0' },
            { key: 'min', groupFunc: 'MIN', color: '#f44336' },
            { key: 'average', groupFunc: 'AVERAGE', color: '#01cc01' },
            { key: 'brute', groupFunc: 'brute', color: '#000000' },
        ].filter(({ key }) => this.state.displayModes[key])
        if (otherSeriesToFetch.length) {
            Promise.all(otherSeriesToFetch.map(({ groupFunc }) => HydrometryAction.promiseHydroChronicMeasures({
                stationId: this.props.hydrometricStation.id,
                groupFunc,
                dataType: this.state.typeId,
                startDate: this.state.minDate,
                endDate: this.state.maxDate,
                distinctByCodePoint: false,
            }))).then(jsonTab => {
                const obj = jsonTab.reduce((acc, json, idx) => {
                    const measures = json.map(m => new DtoHydroMeasureValidation(m))
                    const { key, color } = otherSeriesToFetch[idx]
                    return {
                        ...acc,
                        [`${key}Serie`]: this.calculateSerie(measures, key === 'brute' ? i18n.bruteMeasures : i18n[key], color),
                        [`${key}Measures`]: measures,
                    }
                }, {})
                this.setState(obj)
            })
        }
    }

    setTitle = (station) => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: getStationTitle(station),
            href: `station/hydrometry/${station.id}`,
        }, {
            title: i18n.hydrometricFollowUp,
            href: `station/hydrometry/${station.id}/hydrometryFollowUp`,
        }]))
    }

    calculateSerie = (measures, labelSerie, baseColor) => {
        const { typeId } = this.state
        const unit = this.props.hydroStatistics.find(t => t.typeId === typeId)?.unit ?? ''
        const group = groupBy(measures, 'codepoint')

        const measuresEchart = Object.keys(group).map(k => {
            return orderBy(group[k].flatMap(obj => {
                if (obj.initialPoint === 1) {
                    return [
                        { value: [moment(obj.date).subtract(1, 'second').valueOf(), null, obj], unit },
                        { value: [obj.date, obj.value, obj], symbolSize: 5, unit },
                    ]
                }
                return [{ value: [obj.date, obj.value, obj], symbolSize: 0, unit }]
            }), m => m.value[0])
        })
        const colors = repeatList(generateGradient(baseColor, '#FFF', 5), 10)
        return measuresEchart.map((byPointMeasures, idx) => Line({
            data: byPointMeasures,
            name: labelSerie + (measuresEchart.length > 1 ? ` - ${this.props.hydroStatistics.find(s => s.typeId === typeId && s.codepoint === byPointMeasures[0].value[2].codepoint)?.namePoint}` : ''),
            connectNulls: false,
            showSymbol: false,
            yAxisIndex: 0,
            xAxisIndex: 0,
            color: colors[idx],
            serieId: 'measures',
        }))
    }

    getTooltip() {
        const { modeDate } = this.state
        return {
            trigger: 'axis',
            formatter: params => {
                const filtered = params.filter(p => !p.data.noTooltip && hasValue(p.value[1]) && p.seriesName !== i18n.events)
                if (!filtered.length) {
                    return ''
                }
                const isPeriod = params.some(p => p.data.isPeriod)
                const date = modeDate === 'allDate' ? getFullDate(moment(filtered[0].value[0])) : getDate(moment(filtered[0].value[0]))
                const categoryDate = params.find(p => p.axisType.includes('category'))?.axisValue

                const events = this.props.stationEvents.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1' && o.eventType !== EVENT_TYPES_CODES.TECHNIQUE)
                const labelEvents = events.length ? events.reduce((acc, v) => {
                    return `${acc}<br />${getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />${hasValue(v.ns) ? `${i18n.staticLevelMeasure} : ${v.ns}m<br />` : ''}${hasValue(v.nc) ? `${i18n.sensorInstantLevel} : ${v.nc}m<br />` : ''}`
                }, '') : ''

                const paramsOrder = orderBy(filtered.map((o, idx) => {
                    return {
                        ...o,
                        marker: o.marker,
                        seriesName: o.seriesName === i18n.enveloppe ? i18n.enveloppeMax : o.seriesName,
                        value: (() => {
                            if (isPeriod && o.seriesName.startsWith('>')) {
                                return filtered[idx - 1].data.realValue
                            }
                            return (o.data.realValue || o.value[1])
                        })(),
                    }
                }), ['value', 'seriesName'], ['desc', 'desc'])
                const result = paramsOrder.map(o => {
                    return `${o.marker} ${o.seriesName} : ${o.value} ${o.data.unit ?? ''} ${getMeasureJplus(o.data?.value[2])}`
                }).join('<br/>')
                return `${categoryDate ?? date} ${labelEvents}<br />${result}`
            },
        }
    }

    getExportData = (chartMinDate, chartMaxDate) => {
        const roundValue = this.props.hydrometryDataTypes.find(pdt => pdt.id === this.state.typeId)?.numberDecimal || 3
        const data = this.series.flatMap(chart => {
            if (chart.obj?.bands) {
                return chart.obj.bands.flatMap(band => !band?.name?.includes('undefined') ?
                    this.exportSerie(band, chartMinDate, chartMaxDate, roundValue) :
                    [],
                )
            }
            return this.exportSerie(chart.obj, chartMinDate, chartMaxDate, roundValue)
        })

        if (data?.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }

        return data
    }

    exportSerie = (serie, chartMinDate, chartMaxDate, roundValue) => serie.data.filter(d => d.value[0] >= chartMinDate && d.value[0] <= chartMaxDate).map(d => {
        return {
            stationCode: { value: this.props.hydrometricStation.code },
            stationName: { value: this.props.hydrometricStation.name },
            date: { value: getFullDate(d.value[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: round(d.value[1]), format: getExportFormat(roundValue), cellType: 'number' },
            type: { value: serie.name },
        }
    })

    getTabs = (minY) => {
        const { hydrometricStation } = this.props
        const { hasValidMeasures, time, typeId, tabLocked, selectedAssociations,
            displayEvents, minDate, maxDate, measures, chartOptionsAreLoaded } = this.state
        const chartMinDate = minDate || (measures[0]?.date) || moment().valueOf()
        const chartMaxDate = maxDate || moment().endOf('year').valueOf()
        const histoYears = measures.length ? moment(last(measures).date).year() - moment(measures[0].date).year() : 0
        const defaultOptions = {
            id: hydrometricStation.id,
            changeParent: changes => this.setState(changes),
            minDate: chartMinDate,
            maxDate: chartMaxDate,
            time,
            typeId,
            stationType: 'hydrometry',
        }

        const tabs = [{
            icon: 'multiline_chart',
            constant: STATISTICS,
            label: i18n.statistics,
        }, {
            icon: 'fast_rewind',
            constant: PREVIOUSYEARS,
            label: i18n.previousYears,
        },
        {
            icon: 'list',
            constant: LINKEDSTATIONS,
            label: `${i18n.associatedStations} ${i18n.and} ${i18n.additionalData}`,
        }, {
            icon: 'aspect_ratio',
            constant: CHARTOPTIONS,
            label: i18n.chartOptions,
        },
        {
            icon: 'report_problem',
            constant: THRESHOLDS,
            label: i18n.thresholds,
        },
        {
            icon: 'event_note',
            constant: EVENTS,
            label: i18n.events,
        },
        {
            icon: 'extension',
            constant: MODELS,
            label: i18n.prevData,
        }]
        if (hasValidMeasures === null || !chartOptionsAreLoaded) {
            return null
        }
        return (
            <SimpleTabSideList
                position='right'
                defaultTab={STATISTICS}
                tabs={tabs}
                cantChangeTabMessage={tabLocked ? i18n.validateOrCancelFirst : undefined}
            >
                {
                    tab => (
                        <>
                            <HydroSuiviStatTab2
                                {...defaultOptions}
                                tab={tab}
                                hasValidMeasures={hasValidMeasures ?? false}
                                histoYears={histoYears}
                                hydrometerChartOptions={this.state.chartOptions}
                                hydroStatistics={this.props.hydroStatistics}
                                changeTypeId={v => this.setState({ typeId: v, displayModes: defaultDisplayModes }, () => {
                                    this.getMaxMeasures()
                                })}
                                minY={minY}
                            />
                            <HydroSuiviPastYearsTab2
                                {...defaultOptions}
                                tab={tab}
                                measures={this.state.measures}
                            />
                            <OptionsToolPanel
                                {...defaultOptions}
                                tab={tab}
                                chartOptions={this.state.chartOptions}
                                yScale={this.yScale}
                                modeDate={this.state.modeDate}
                            />
                            <HydroSuiviThresholds2
                                tab={tab}
                                {...defaultOptions}
                                unit={this.props.hydroStatistics.find(t => t.typeId === typeId)?.unit}
                            />
                            <HydroSuiviAssociationsTab2
                                tab={tab}
                                {...defaultOptions}
                                hydrometricStation={hydrometricStation}
                            />
                            {
                                tab === EVENTS && (
                                    <StationSuiviEventsTab
                                        {...defaultOptions}
                                        displayEvents={displayEvents}
                                    />
                                )
                            }
                            {
                                this.props.hydroStatistics && (
                                    <HydroSuiviModelTab2
                                        tab={tab}
                                        {...defaultOptions}
                                        hydrometricStation={hydrometricStation}
                                        measures={this.state.measures}
                                        selectedAssociations={selectedAssociations}
                                        hydroStatistics={this.props.hydroStatistics}
                                    />
                                )
                            }
                        </>
                    )
                }
            </SimpleTabSideList>
        )
    }

    render() {
        const { hydrometricStation, stationEvents } = this.props
        const { displayModes, time, displayLog, openLogModal, messageLogModal, YZoom,
            openYZoomModal, typeId, filterStatusQualif, displayEvents, minDate, maxDate, measures,
            associationsSeries, associationsAxis, modelsSeries, statsSeries, yearSeries, thresholds,
            chartOptions } = this.state
        const chartMinDate = minDate || (measures[0]?.date) || moment().valueOf()
        const chartMaxDate = maxDate || moment().endOf('year').valueOf()

        const [xAxis, yAxis] = [[], []]

        const axisLabelObj = getAxisIntervalFormatter(moment(chartMaxDate), moment(chartMinDate))

        const [associationsInFirstAxis, otherSeries] = partition(associationsSeries, s => s.obj.isFirstAxis)

        const otherAxisObj = {}
        const otherAxis = [
            ...associationsAxis,
        ].map((axis, idx) => {
            otherAxisObj[axis.name] = idx + 1
            const yScale = yAutomaticScaleValues([
                ...([...otherSeries, ...modelsSeries].filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.data).flat().map(v => v.value[1])),
                ...otherSeries.filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.markLine?.data?.map(d => d.yAxis) || []),
            ])
            return Axis({
                type: 'value',
                nameLocation: 'middle',
                nameGap: 35,
                position: 'right',
                axisLabel: { formatter: nFormatter },
                offset: idx * 60,
                splitNumber: 5,
                ...yScale,
                ...(removeNullKeys(axis)),
                name: `${axis.name} ${axis?.unit ? `[${axis?.unit}]` : ''}`,
                inverse: axis.isPluvio,
            })
        })

        otherSeries.forEach(serie => {
            serie.updateObj({ yAxisIndex: otherAxisObj[serie.obj.axisName] })
        })
        modelsSeries.forEach(serie => {
            serie.updateObj({ yAxisIndex: otherAxisObj[serie.obj.axisName] ?? 0 })
        })

        const thisTypeIdSeries = ['brute', 'min', 'average', 'personalizedGrouping', 'max'].map(key => displayModes[key] ? this.state[`${key}Serie`] : []).flat()
        const withFilterStatusQualifications = thisTypeIdSeries.map(s => new Line({ ...s.obj, data: s.obj.data.filter(m => filterMeasureStatusQualif(m.value[2], filterStatusQualif)) }))

        const firstAxisSeries = [
            ...withFilterStatusQualifications,
            ...statsSeries,
            ...yearSeries,
            ...associationsInFirstAxis,
            ...thresholds,
            ...modelsSeries.filter(obj => obj.yAxisIndex === 0),
        ]

        this.series = [
            ...firstAxisSeries,
            ...otherSeries,
            ...modelsSeries.filter(obj => obj.yAxisIndex !== 0),
        ]

        const option = chartOptions.find(opt => parseInt(opt.dataType ?? HYDRO_TYPEID.HEIGHT) === typeId)

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            gridIndex: 0,
            showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
        }))
        if (otherSeries.some(s => s.obj.isPluvio)) {
            xAxis.push(Axis({
                type: 'category',
                position: 'up',
                data: enumerateBetweenDates(chartMinDate, chartMaxDate, 'days').map(d => d.format('DD/MM/YYYY')),
                axisLabel: { show: false },
                axisTick: { show: false },
                gridIndex: 0,
            }))
        }
        const allValues = firstAxisSeries.filter(s => !s.obj.noYScale)
            .flatMap(s => s.obj.isMultiBand ? s.obj.bands.filter(b => !b.noYScale).flatMap(b => b.data) : s.obj.data)
            .flat().map(v => v.value[1])
        const yScale = yAutomaticScaleValues([ ...allValues, ...thresholds.map(t => t.obj.markLine.data[0].yAxis)])
        this.yScale = yScale

        const stat = this.props.hydroStatistics.find(t => t.typeId === typeId)
        const unit = stat?.unit
        yAxis.push(Axis({
            type: displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name: `${stat?.label ?? ''} ${unit ? `[${unit}]` : ''}`,
            gridIndex: 0,
            nameGap: 40,
            showSplitLine: option && hasValue(option.displayYIntervalYear) ? option.displayYIntervalYear === '1' : true,
            yScale,
            ...setYOptions(option, yScale,),
            ...setLogOptions(displayLog, allValues),
            ...YZoom,
        }))
        otherAxis.forEach(axis => yAxis.push(axis))

        const gridTop = displayEvents ? 130 : 90
        const grids = [{
            top: gridTop,
            left: 100,
            right: `${4 + yAxis.length}%`,
            height: this.state.height - gridTop - (parseInt(this.series.length / 3) * 20),
        }]

        // / EVENTS
        if (displayEvents) {
            const events = stationEvents.filter(e => e.graph === '1' && e.eventType !== EVENT_TYPES_CODES.TECHNIQUE)
            const eventOptions = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        const eventsList = events.filter(e => {
                            const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                            const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                            return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                        })
                        const labelEvents = eventsList.reduce((acc, v) => {
                            const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                            return `${acc}<br />${getColorCircleEvent(v.eventType)}${comment}`
                        }, i18n.events)
                        return labelEvents
                    },
                },
                itemStyle: {
                    normal: {
                        opacity: 0.5,
                    },
                },
                yAxisIndex: yAxis.length,
                xAxisIndex: xAxis.length,
            }
            this.series.push(getEventGraph(events, eventOptions))
            this.series.push(getEventsBar(events, { yAxisIndex: yAxis.length, xAxisIndex: xAxis.length }))
            grids.push({
                top: 90,
                right: `${4 + yAxis.length}%`,
                height: 40,
                left: 100,
            })
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                gridIndex: 1,
            }))
            yAxis.push(Axis({
                type: 'value',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'right',
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                gridIndex: 1,
            }))
            yAxis.push(Axis({
                type: 'category',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'left',
                gridIndex: 1,
            }))
        }

        const options = {
            customTitle: {
                text: `[${hydrometricStation.code}]${hydrometricStation.name ? ` - [${hydrometricStation.name}]` : ''}`,
                top: 55,
                left: 'center',
            },
            series: this.series.filter(s => ((s.obj.yAxisIndex ?? 0) <= (yAxis.length - 1)) && ((s.obj.xAxisIndex ?? 0) <= (xAxis.length - 1))),
            xAxis,
            yAxis,
            legend: {
                bottom: 30,
                show: true,
                // type: 'scroll',
                data: this.series.flatMap(s => s.obj.bands ? s.obj.bands : [s.obj]).filter(s => !s.noLegend && s.name !== i18n.events)
                    .map(s => ({
                        name: s.name,
                        icon: ['fre', 'ENVELOPPE'].some(l => (s.code || '').includes(l)) ? 'roundRect' : 'circle',
                    })).filter(e => e.name !== i18n.enveloppeMin),
            },
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            tooltip: this.getTooltip(),
            setDataZoom: true,
            height: this.state.height,
            grid: grids,
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(chartMinDate, chartMaxDate),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                    myLog: {
                        show: true,
                        title: i18n.logarithm,
                        icon: logIcon,
                        onclick: () => {
                            this.setState({ displayLog: !displayLog, openLogModal: true, messageLogModal: displayLog ? i18n.logarithmicScaleOff : i18n.logarithmicScaleActivated })
                        },
                    },
                    myZoom: {
                        show: true,
                        title: i18n.zoomVertical,
                        icon: swapVertical,
                        onclick: () => this.setState({ openYZoomModal: true }),
                    },
                },
                right: 200,
            },
        }
        const minY = options.yAxis[0]?.min ?? 0
        return (
            <div style={{ marginLeft: 5, backgroundColor: 'white', overflow: 'hidden', position: 'relative' }} id='chart'>
                {this.getTabs(minY)}
                <div>
                    <div style={{ position: 'absolute', left: 637, top: 11, zIndex: 10 }}>
                        <ChartOptionsChip
                            displayModes={displayModes}
                            filterStatusQualif={filterStatusQualif}
                            displayModesKey={HYDRO_L_STORAGE.DISPLAY_MODES_SUIVI}
                            filterStatusQualifKey={HYDRO_L_STORAGE.STATUS_QUALIF_SUIVI}
                            changeParent={obj => this.setState(obj, this.loadOtherDisplayMeasuresIfChecked)}
                            withInitialDisplay={false}
                            noAuto
                        />
                    </div>
                    <div style={{ position: 'absolute', left: 20, top: 10, zIndex: 10 }}>
                        <MUIChartTabs
                            time={time}
                            onChangeTime={(newTime) => {
                                this.setState({ ...newTime }, this.loadOtherDisplayMeasuresIfChecked)
                            }}
                            withSuiviTabs
                        />
                    </div>
                    <EChart
                        options={options}
                        id='hydroSuiviChart'
                        // bandCorrection={isPiezo && !displayLog}
                        withLogModal
                        openLogModal={openLogModal}
                        messageLogModal={messageLogModal}
                        onCloseLogModal={() => this.setState({ openLogModal: false })}

                        withYZoomModal
                        openYZoomModal={openYZoomModal}
                        YZoom={YZoom}
                        setYZoom={v => this.setState({ YZoom: v, openYZoomModal: false })}
                    />
                </div>
            </div>
        )
    }
}

HydroSuiviChart2.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    fetchHydrometerChartOptions: PropTypes.func,
    chartOptions: arrayOf(DtoHydrometerChartOptions),
    hydroStatistics: arrayOf(DtoHydroStats),
    stationEvents: PropTypes.arrayOf(DtoEvent),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
}

const mapStateToProps = store => ({
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    chartOptions: store.HydrometerStationReducer.chartOptions,
    hydroStatistics: store.HydrometryReducer.hydroStatistics,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    stationEvents: store.EventsReducer.stationEvents,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    fetchHydrometerChartOptions: HydrometerStationAction.fetchHydrometerChartOptions,
    fetchHydroStatistics: HydrometryAction.fetchHydroStatistics,
    push,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydroSuiviChart2)
