/* eslint-disable consistent-return */
import accreditationPicto from 'assets/pictures/pictos/Accreditation.png'
import insitu from 'assets/pictures/pictos/insitu.png'
import labo from 'assets/pictures/pictos/labo.png'
import statusIconB from 'assets/pictures/pictos/statusIconB.png'
import statusIconI from 'assets/pictures/pictos/statusIconI.png'
import statusIconN1 from 'assets/pictures/pictos/statusIconN1.png'
import statusIconN2 from 'assets/pictures/pictos/statusIconN2.png'
import {
    ceil,
    find,
    groupBy,
    isNil,
    isUndefined,
    keys,
    max,
    maxBy,
    meanBy,
    minBy,
    orderBy,
    sum,
} from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from './FormUtils'
import { hasValue, round } from './NumberUtil'
import { getStatuses } from './QualityUtils'
import { getSetting } from './SettingUtils'
import { statusIcon } from './StatusUtil'
import { getLabel } from './StoreUtils'
import { PASTEL_COLOR } from './constants/ColorTheme'

const remarks = [
    // physico-chimique
    {
        id: 0,
        code: 0,
        plot: 'path://m 50 0 l -29.5 90.5 l 77 -56 l -95 0 l 77 56 l -29.5 -90.5 z',
        color: 'black',
        title: '',
        logo: '★',
    }, {
        id: 1,
        code: 1,
        plot: 'path://m 10 50 a 40 40 1 0 0 80 0 a 40 40 0 0 0 -80 0 z',
        color: 'black',
        title: '',
        logo: '●',
    }, {
        id: 2,
        code: 2,
        plot: 'path://m 15 15 l 0 70 l 70 0 l 0 -70 l -70 0 z',
        color: 'black',
        title: '',
        logo: '■',
    }, {
        id: 3,
        code: 3,
        plot: 'path://m 0 50 l 50 -50 l 50 50 l -50 50 l -50 -50 z',
        color: 'black',
        title: '',
        logo: '◆',
    }, {
        id: 7,
        code: 7,
        plot: 'path://m 50 10 l -43 75 l 86 0 l -43 -75 z',
        color: 'black',
        title: '',
        logo: '▲',
    }, {
        id: 10,
        code: 10,
        plot: 'path://m 50 90 l 43 -75 l -86 0 l 43 75 z',
        color: 'black',
        title: '',
        logo: '▼',
    }, {
        id: 11,
        code: 11,
        plot: 'path://m 45 0 h 10 v 45 h 45 v 10 h -45 v 45 h -10 v -45 h -45 v -10 h 45 v -45 z',
        color: 'black',
        title: '',
        logo: '+',
    }, {
        id: 12,
        code: 12,
        plot: 'path://m 18.5 11.5 l 31.5 31.5 l 31.5 -31.5 l 7 7 l -31.5 31.5 l 31.5 31.5 l -7 7 l -31.5 -31.5 l -31.5 31.5 l -7 -7 l 31.5 -31.5 l -31.5 -31.5 l 7 -7 z',
        color: 'black',
        title: '',
        logo: 'x',
    },
    // hydrobio
    {
        id: 4,
        code: 4,
        plot: 'path://m 50 10 l -43 75 l 86 0 l -43 -75 z',
        color: 'black',
        title: '',
        logo: '▲',
    }, {
        id: 5,
        code: 5,
        plot: 'path://m 50 0 l -29.5 90.5 l 77 -56 l -95 0 l 77 56 l -29.5 -90.5 z',
        color: 'black',
        title: '',
        logo: '★',
    }, {
        id: 6,
        code: 6,
        plot: 'path://m 0 50 l 50 -50 l 50 50 l -50 50 l -50 -50 z',
        color: 'black',
        title: '',
        logo: '◆',
    }, {
        id: 8,
        code: 8,
        plot: 'path://m 15 15 l 0 70 l 70 0 l 0 -70 l -70 0 z',
        color: 'black',
        title: '',
        logo: '■',
    }, {
        id: 9,
        code: 9,
        plot: 'path://m 50 90 l 43 -75 l -86 0 l 43 75 z',
        color: 'black',
        title: '',
        logo: '▼',
    },
]

const THRESHOLD_TYPE = {
    UNKNOWN: 'UNKNOWN',
    PH: 'PH',
    CONDUCTIVITY: 'CONDUCTIVITY',
    INCREASING: 'INCREASING',
    DECREASING: 'DECREASING',
}

const getThresholdType = (threshold) => {
    if (isUndefined(threshold)) {
        return THRESHOLD_TYPE.UNKNOWN
    }
    // specific ph
    if ((threshold.parameterCode === '1302' || threshold.parameterCode === '6488') && threshold.threshold1 < 7 && threshold.threshold2 > 7 && isNil(threshold.threshold3) && isNil(threshold.threshold4)) {
        return THRESHOLD_TYPE.PH
    }
    // specific conductivity
    if ((threshold.parameterCode === '1304' || threshold.parameterCode === '1303') && threshold.threshold1 < 500 && threshold.threshold2 > 500 && isNil(threshold.threshold3) && isNil(threshold.threshold4)) {
        return THRESHOLD_TYPE.CONDUCTIVITY
    }
    if (!isNil(threshold.threshold1) && !isNil(threshold.threshold2) && threshold.threshold1 > threshold.threshold2) {
        return THRESHOLD_TYPE.DECREASING
    }
    return THRESHOLD_TYPE.INCREASING
}

const getIndiceValue = (valueWithRemark, threshold = {}) => { // use only for sliderPanel
    if (isUndefined(valueWithRemark)) return []
    if (!threshold.threshold1 && threshold.threshold1 !== 0) return []

    const thresholdType = getThresholdType(threshold)
    const parse = parseFloat(valueWithRemark.replace('<', '').replace('>', ''))
    const value = valueWithRemark.includes('<') ? parse/2 : parse
    const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1

    if (valueWithRemark.includes('>')) {
        const formatThreshold = { ...threshold, threshold0: -500000 }
        return [nbThresholds, max([0, 1, 2, 3, 4].filter(nb => !isUndefined(formatThreshold[`threshold${nb}`]))) + 1]
    }

    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: {
            const valueIndice = value >= threshold.threshold2 && 3 || value <= threshold.threshold1 && 1 || 2
            return [3, valueIndice]
        }
        case THRESHOLD_TYPE.INCREASING: {
            const formatThreshold = { ...threshold, threshold0: -500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value >= formatThreshold[`threshold${nb}`])) + 1
            return [nbThresholds, valueIndice]
        }
        case THRESHOLD_TYPE.DECREASING: {
            const formatThreshold = { ...threshold, threshold0: 500000 }
            const valueIndice = nbThresholds - max([0, 1, 2, 3, 4].filter(nb => value < formatThreshold[`threshold${nb}`]))
            return [nbThresholds, valueIndice]
        }
        default: return []
    }
}

const THRESHOLD_COLOR = {
    blue: PASTEL_COLOR.blue,
    green: PASTEL_COLOR.green,
    yellow: PASTEL_COLOR.yellow,
    orange: PASTEL_COLOR.orange,
    red: PASTEL_COLOR.red,
    white: PASTEL_COLOR.white,
}

const tabColorsBySize = {
    1: ['blue'],
    2: ['blue', 'red'],
    3: ['blue', 'orange', 'red'],
    4: ['blue', 'yellow', 'orange', 'red'],
    5: ['blue', 'green', 'yellow', 'orange', 'red'],
    6: ['red', 'orange', 'yellow', 'green', 'blue', 'white'],
}

const tabPastelColorsBySize = {
    1: [THRESHOLD_COLOR.blue],
    2: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.red],
    3: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    4: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    5: [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.green, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.red],
    6: [THRESHOLD_COLOR.red, THRESHOLD_COLOR.orange, THRESHOLD_COLOR.yellow, THRESHOLD_COLOR.green, THRESHOLD_COLOR.blue, THRESHOLD_COLOR.white],
}

const getThresholdsColor = (threshold, value) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: {
            const color = value > threshold.threshold1 && value < threshold.threshold2 ? 'blue' : 'red'
            const valueIndice = value >= threshold.threshold2 && 2 || value <= threshold.threshold1 && 1 || 0
            return [valueIndice, color]
        }
        case THRESHOLD_TYPE.INCREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: -500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value >= formatThreshold[`threshold${nb}`]))
            return [valueIndice, colorTab[valueIndice]]
        }
        case THRESHOLD_TYPE.DECREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: 500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value < formatThreshold[`threshold${nb}`]))
            return [valueIndice, colorTab[valueIndice]]
        }
        default: return []
    }
}

const getThresholdColors = (threshold) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: {
            return [THRESHOLD_COLOR.blue, THRESHOLD_COLOR.red, THRESHOLD_COLOR.blue]
        }
        case THRESHOLD_TYPE.INCREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            return tabPastelColorsBySize[nbThresholds]
        }
        case THRESHOLD_TYPE.DECREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            return tabPastelColorsBySize[nbThresholds].toReversed()
        }
        default: return []
    }
}

const getTabColorsBySize = () => tabColorsBySize

const getNonConformSelectData = () => [
    { value: 1, label: i18n.conforms, conformity: ['blue'] },
    { value: 2, label: i18n.nonConform, conformity: ['red', 'orange', 'yellow', 'green'] },
    { value: 3, label: i18n.others, conformity: ['white'] },
]

const getRemarks = (id) => {
    return find(remarks, (o) => {
        return o.id == id
    }) || {}
}

const isQuantified = remark => remark !== '2' && remark !== '7' && remark !== '10'

const getResultFormat = ({ result, remark }) => {
    switch (remark) {
        case '0': return ''
        case '2':
        case '7':
        case '10': return isUndefined(result) ? '' : `<${result}`
        case '3': return isUndefined(result) ? '' : `>${result}`
        default:
            return isUndefined(result) ? '' : `${result}`
    }
}

const getValue = (value) => {
    try {
        return parseFloat(value.replace('<', '').replace('>', ''))
    } catch (e) {
        return parseFloat(value)
    }
}

const getValueAnalyse = analyse => {
    if (!analyse) {
        return NaN
    }
    const {
        remarkCode,
        value,
        saturationThreshold,
    } = analyse
    return remarkCode === '3' && saturationThreshold || getValue(value)
}

const getDisplayValueAnalyse = analyse => {
    if (!analyse || !analyse.value) {
        return ''
    }
    const {
        remarkCode,
        value,
        saturationThreshold,
    } = analyse
    return remarkCode === '3' && saturationThreshold && `${saturationThreshold}` || value
}

const calculatePercentile90 = (list = [], centile = 0.9) => { // list should be ordered
    // old
    // if (list.length <= 9) {
    //     return list[list.length-2]
    // }
    // return list[floor(90 / 100 * list.length)]

    // v6
    if (list.length < 10) {
        return list[list.length - 1]
    }
    return list[round(centile * list.length - 0.45, 0)] // (0.9 * length - 0.55) - 1
}

const getQualificationColor = (qualification) => {
    return qualification ? ['GREEN', 'RED', 'ORANGE', 'GREY'][parseInt(qualification) - 1] : 'GREY'
}

const getQualificationIcon = qualification => {
    switch (parseInt(qualification)) {
        case 2: return 'error'
        case 3: return 'warning'
        case 4: return 'help'
        default: return ''
    }
}

const getStatusIcon = (status, statusList, style, withTooltip=true) => {
    if (hasValue(status)) {
        const icon = status ? [statusIconB, statusIconN1, statusIconN2, statusIconI][parseInt(status) - 1] : statusIconB
        return (
            <img
                src={ icon }
                className={ 'statusIcon' }
                style={ style }
                {...(withTooltip ? sieauTooltip(() => getLabel(statusList || getStatuses(), status)) : {})}
            />
        )
    }
    return null
}

const getStatusColor = (status) => {
    return status ? ['GREY', 'GREEN', 'DARKGREEN', 'BLUE'][parseInt(status) - 1] : 'GREY'
}

const getStatusAndResult = (obj, result, color = 'white', withTooltip = false) => {
    const icon = statusIcon(obj, 30, withTooltip)
    return (
        <div className='valign-wrapper'>
            { icon }
            &nbsp;
            <div className={ `width-100 ${color}` } >
                { result }
            </div>
        </div>
    )
}

const getLocalizationPicto = (localization) => {
    switch (localization) {
        case '0': return <i className='material-icons clickable' {...sieauTooltip(() => i18n.unknownLocalization)}>live_help</i>
        case '1': return <img className='clickable' {...sieauTooltip(() => i18n.insitu)} src={insitu} />
        case '2': return <img className='clickable' {...sieauTooltip(() => i18n.laboratory)} src={labo} />
        default: return ''
    }
}

const getLocalizationLabel = (localization) => {
    switch (localization) {
        case '0': return i18n.unknownLocalization
        case '1': return i18n.insitu
        case '2': return i18n.laboratory
        default: return ''
    }
}

const getAccreditationPicto = (accreditation) => {
    if (accreditation) {
        return <img className='clickable' src={ accreditationPicto } />
    }
    return undefined
}

const filterThresholdLevel = (analysis, thresholdLevel) => {
    if (isNil(thresholdLevel)) return true
    if (isNil(analysis.threshold)) return false
    switch (thresholdLevel) {
        case '0':
            return hasValue(analysis.threshold.threshold1) && analysis.result >= analysis.threshold.threshold1 && !analysis.value.includes('<')
        case '1':
            return hasValue(analysis.threshold.threshold2) && analysis.result >= analysis.threshold.threshold2 && !analysis.value.includes('<')
        case '2':
            return hasValue(analysis.threshold.threshold3) && analysis.result >= analysis.threshold.threshold3 && !analysis.value.includes('<')
        case '3':
            return hasValue(analysis.threshold.threshold4) && analysis.result >= analysis.threshold.threshold4 && !analysis.value.includes('<')
        case '4':
            return hasValue(analysis.threshold.threshold1) && (analysis.result < analysis.threshold.threshold1 || analysis.value.includes('<'))
        case '5':
            return hasValue(analysis.threshold.threshold2) && (analysis.result < analysis.threshold.threshold2 || analysis.value.includes('<'))
        case '6':
            return hasValue(analysis.threshold.threshold3) && (analysis.result < analysis.threshold.threshold3 || analysis.value.includes('<'))
        case '7':
            return hasValue(analysis.threshold.threshold4) && (analysis.result < analysis.threshold.threshold4 || analysis.value.includes('<'))
        case '8':
            const maxThreshold = `threshold${max([1, 2, 3, 4].filter(t => hasValue(analysis.threshold[`threshold${t}`]))) || 1}`
            return analysis.result < analysis.threshold.threshold1 || analysis.result >= analysis.threshold[maxThreshold]
        default:
            return true
    }
}

const getIconQualification = qualification => {
    switch (parseInt(qualification)) {
        case 1: return 'check_circle'
        case 2: return 'error'
        case 3: return 'warning'
        case 4: return 'help'
        default: return 'help'
    }
}

/* ################################
##########      NEW      ##########
################################ */

/* code remarque
  0: Analyse non faite
  1: Domaine de validité
  2: < seuil de détection
  3: > seuil de saturation
  4: Présence ou Absence
  5: Incomptable
  6: Taxons non individualisables
  7: Traces
  8: Dénombrement > Valeur
  9: Dénombrement < Valeur
  10: < au seuil de quantification
  11: Echelle absente
  12: Echelle non exploitable
*/
const quantificationRemark = ['1', '4', '6', '8', '9']
const detectionRemarkCodes = ['1', '3', '7', '10']

// filtre une liste d'analyses
const filterValid = (analysisList = []) => analysisList.filter(a => a.status === '2' || a.status === '3')
const filterResult = (analysisList = []) => analysisList.filter(a => a.remark !== '0' && (!isUndefined(a.result) || a.remark === '7'))
const filterQuantification = (analysisList = []) => analysisList.filter(a => a.remark && !(['0', '2', '3', '5', '7', '10'].includes(a.remark)))
const filterDetection = (analysisList = []) => analysisList.filter(a => detectionRemarkCodes.includes(a.remark))

// fonctions utils
const getMinThresholdColor = threshold => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 'red' // or blue ?
        case THRESHOLD_TYPE.INCREASING: return 'blue'
        case THRESHOLD_TYPE.DECREASING: return 'red'
        default: return 'white'
    }
}

const getMaxThresholdColor = threshold => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 'red' // or blue ?
        case THRESHOLD_TYPE.INCREASING: return 'red'
        case THRESHOLD_TYPE.DECREASING: return 'blue'
        default: return 'white'
    }
}

const getMaxIndiceThreshold = (threshold = {}) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 2
        case THRESHOLD_TYPE.INCREASING: {
            const normalizeThreshold = { ...threshold, threshold0: -500000 }
            return max([0, 1, 2, 3, 4].filter(nb => !isUndefined(normalizeThreshold[`threshold${nb}`])))
        }
        case THRESHOLD_TYPE.DECREASING: return 0
        default: return
    }
}

const getMinIndiceThreshold = (threshold = {}) => {
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return 0
        case THRESHOLD_TYPE.INCREASING: return 0
        case THRESHOLD_TYPE.DECREASING: {
            const normalizeThreshold = { ...threshold, threshold0: 500000 }
            return max([0, 1, 2, 3, 4].filter(nb => !isUndefined(normalizeThreshold[`threshold${nb}`])))
        }
        default: return
    }
}

const getIncreasingThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    if (indice === 0) {
        return `< ${threshold.threshold1}`
    }
    const maxIndice = getMaxIndiceThreshold(threshold)
    if (indice === maxIndice) {
        return `>= ${threshold[`threshold${indice}`]}`
    }
    return `>= ${threshold[`threshold${indice}`]} ${i18n.and} < ${threshold[`threshold${indice + 1}`]}`
}

const getDecreasingThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    const minIndice = getMinIndiceThreshold(threshold)

    if (indice === 0) {
        return `>= ${threshold.threshold1}`
    }
    if (indice === minIndice) {
        return `< ${threshold[`threshold${minIndice}`]}`
    }
    return `>= ${threshold[`threshold${indice + 1}`]} ${i18n.and} < ${threshold[`threshold${indice}`]}`
}

const getSpecificThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    if (indice === 1) {
        return `<= ${threshold.threshold1}`
    }

    if (indice === 2) {
        return `>= ${threshold.threshold2}`
    }

    return `> ${threshold.threshold1} ${i18n.and} < ${threshold.threshold2}`
}

const getThresholdLabel = (indice, threshold) => {
    if (isNil(threshold) || isNil(indice)) return ''

    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: return getSpecificThresholdLabel(indice, threshold)
        case THRESHOLD_TYPE.INCREASING: return getIncreasingThresholdLabel(indice, threshold)
        case THRESHOLD_TYPE.DECREASING: return getDecreasingThresholdLabel(indice, threshold)
        default: return ''
    }
}

// const getThresholdWithIndexedList = (indexedThresholds = {}, parameter, unit, station) => {
//     const threshold = indexedThresholds[`${parameter}:${unit}:${station}`]
//     if (!isUndefined(threshold)) return threshold

//     const stationThreshold = indexedThresholds[`${parameter}:undefined:${station}`]
//     if (!isUndefined(stationThreshold)) return stationThreshold

//     const unitThreshold = indexedThresholds[`${parameter}:${unit}:undefined`]
//     if (!isUndefined(unitThreshold)) return unitThreshold

//     const parameterThreshold = indexedThresholds[`${parameter}:undefined:undefined`]
//     if (!isUndefined(parameterThreshold)) return parameterThreshold

//     return indexedThresholds['undefined:undefined:undefined']
// }

/*
Tout n'est pas géré par principe d'opti, si besoin les ajouter et faire de même coté back
il y a un ordre de priorité sur l'application des seuils, d'ou les 16 .find
Enjoy !!

1) paramètre unité station fraction -> yes
2) paramètre unité station -> yes
3) paramètre unité fraction -> yes
4) paramètre station fraction
5) unité station fraction
6) paramètre unité -> yes
7) paramètre station -> yes
8) unité station
9) paramètre fraction -> yes
10) unité fraction
11) station fraction
12) paramètre -> yes
13) unité -> yes
14) station -> yes
15) fraction
16) -> yes

Nommage:
P = paramètre, U = unité, S = station, F = fraction
Ex: thresholdPUS -> parameterCode, unit, station sont définie et fraction est undefined
*/
const getThreshold = (thresholds = [], parameter, unit, station, fraction) => {
    const thresholdPUSF = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && t.station === station && t.fraction === fraction)
    if (!isUndefined(thresholdPUSF)) return thresholdPUSF

    const thresholdPUS = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && t.station === station && isUndefined(t.fraction))
    if (!isUndefined(thresholdPUS)) return thresholdPUS

    const thresholdPUF = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && isUndefined(t.station) && t.fraction === fraction)
    if (!isUndefined(thresholdPUF)) return thresholdPUF

    // const thresholdPSF = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && t.station === station && t.fraction === fraction)
    // if (!isUndefined(thresholdPSF)) return thresholdPSF

    // const thresholdUSF = thresholds.find(t => isUndefined(t.parameterCode) && t.unit === unit && t.station === station && t.fraction === fraction)
    // if (!isUndefined(thresholdUSF)) return thresholdUSF

    const thresholdPU = thresholds.find(t => t.parameterCode === parameter && t.unit === unit && isUndefined(t.station) && isUndefined(t.fraction))
    if (!isUndefined(thresholdPU)) return thresholdPU

    const thresholdPS = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && t.station === station && isUndefined(t.fraction))
    if (!isUndefined(thresholdPS)) return thresholdPS

    // const thresholdUS = thresholds.find(t => isUndefined(t.parameterCode) && t.unit === unit && t.station === station && isUndefined(t.fraction))
    // if (!isUndefined(thresholdUS)) return thresholdUS

    const thresholdPF = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && isUndefined(t.station) && t.fraction === fraction)
    if (!isUndefined(thresholdPF)) return thresholdPF

    // const thresholdUF = thresholds.find(t => isUndefined(t.parameterCode) && t.unit === unit && isUndefined(t.station) && t.fraction === fraction)
    // if (!isUndefined(thresholdUF)) return thresholdUF

    // const thresholdSF = thresholds.find(t => isUndefined(t.parameterCode) && isUndefined(t.unit) && t.station === station && t.fraction === fraction)
    // if (!isUndefined(thresholdSF)) return thresholdSF

    const thresholdP = thresholds.find(t => t.parameterCode === parameter && isUndefined(t.unit) && isUndefined(t.station) && isUndefined(t.fraction))
    if (!isUndefined(thresholdP)) return thresholdP

    const thresholdU = thresholds.find(t => isUndefined(t.parameterCode) && t.unit === unit && isUndefined(t.station) && isUndefined(t.fraction))
    if (!isUndefined(thresholdU)) return thresholdU

    const thresholdS = thresholds.find(t => isUndefined(t.parameterCode) && isUndefined(t.unit) && t.station === station && isUndefined(t.fraction))
    if (!isUndefined(thresholdS)) return thresholdS

    // const thresholdF = thresholds.find(t => isUndefined(t.parameterCode) && isUndefined(t.unit) && isUndefined(t.station) && t.fraction === fraction)
    // if (!isUndefined(thresholdF)) return thresholdF

    // default threshold
    return thresholds.find(t => isUndefined(t.parameterCode) && isUndefined(t.unit) && isUndefined(t.station) && isUndefined(t.fraction))
}

const getThresholdResult = (threshold, value) => {
    if (isUndefined(threshold) || isUndefined(value)) {
        return {}
    }
    const thresholdType = getThresholdType(threshold)
    switch (thresholdType) {
        case THRESHOLD_TYPE.CONDUCTIVITY:
        case THRESHOLD_TYPE.PH: {
            const color = value > threshold.threshold1 && value < threshold.threshold2 ? 'blue' : 'red'
            const valueIndice = value >= threshold.threshold2 && 2 || value <= threshold.threshold1 && 1 || 0
            return { thresholdIndice: valueIndice, color }
        }
        case THRESHOLD_TYPE.INCREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: -500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value >= formatThreshold[`threshold${nb}`]))
            return { thresholdIndice: valueIndice, color: colorTab[valueIndice] }
        }
        case THRESHOLD_TYPE.DECREASING: {
            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(threshold[`threshold${nb}`])).length + 1
            const colorTab = tabColorsBySize[nbThresholds]
            const formatThreshold = { ...threshold, threshold0: 500000 }
            const valueIndice = max([0, 1, 2, 3, 4].filter(nb => value < formatThreshold[`threshold${nb}`]))
            return { thresholdIndice: valueIndice, color: colorTab[valueIndice] }
        }
        default: return {}
    }
}

const calculateResult = analysis => {
    if (!analysis) {
        return
    }
    const {
        remarkCode,
        result,
        saturationThreshold,
    } = analysis
    return remarkCode === '3' && saturationThreshold || result
}

const calculateValue = analysis => {
    if (isUndefined(analysis)) {
        return ''
    }
    switch (analysis.remark) {
        case '0': // Analyse non faite
            return ''
        case '2': // < seuil de détection
        case '7': // Traces
        case '10': // < au seuil de quantification
            return !isUndefined(analysis.result) ? `<${round(analysis.result, 5)}` : ''
        case '3': // > seuil de saturation
            return !isUndefined(analysis.result) ? `>${round(analysis.saturationThreshold ?? analysis.result, 5)}` : ''
        default:
            return !isUndefined(analysis.result) ? `${round(analysis.result, 5)}` : ''
    }
}

// formatage d'une analyse
const calculateThresholdResult = (analysis, thresholds = []) => {
    const threshold = getThreshold(thresholds, analysis.parameter, analysis.unit, analysis.qualitometer, analysis.fraction)
    switch (analysis.remark) {
        case '0': { // Analyse non faite
            const minIndice = getMinIndiceThreshold(threshold)
            return {
                value: '',
                color: 'white',
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} ${minIndice}`,
                threshold,
                thresholdIndice: minIndice,
            }
        }
        case '2': // < seuil de détection
        case '7': // Traces
        case '10': { // < au seuil de quantification
            const minIndice = getMinIndiceThreshold(threshold)
            return {
                value: hasValue(analysis.result) ? `<${round(analysis.result, 5)}` : '',
                color: getMinThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} ${minIndice}`,
                threshold,
                thresholdIndice: minIndice,
            }
        }
        case '3': // > seuil de saturation
            const maxIndice = getMaxIndiceThreshold(threshold)
            return {
                value: hasValue(analysis.result) ? `>${round(analysis.result, 5)}` : '',
                color: getMaxThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `> ${i18n.threshold} ${maxIndice}`,
                threshold,
                thresholdIndice: maxIndice,
            }
        default:
            const {
                thresholdIndice = 0,
                color = 'white',
            } = getThresholdResult(threshold, analysis.result)
            return {
                value: hasValue(analysis.result) ? `${round(analysis.result, 5)}` : '',
                color,
                textColor: 'black',
                thresholdLabel: thresholdIndice > 0 ? `> ${i18n.threshold} ${thresholdIndice}` : `< ${i18n.threshold} 1`,
                threshold,
                thresholdValue: threshold?.[`threshold${thresholdIndice}`],
                thresholdIndice,
            }
    }
}

// calcule sur une liste d'analyse
const searchMaxAnalysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))
    return maxBy([...superior, ...tab, ...inferior], calculateResult)
}

const searchMaxResult = (analysisList = []) => {
    const analysis = searchMaxAnalysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchMaxValue = (analysisList = []) => {
    const analysis = searchMaxAnalysis(analysisList)
    return calculateValue(analysis)
}

const searchMinAnalysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))
    return minBy([...inferior, ...tab, ...superior], calculateResult)
}

const searchMinResult = (analysisList = []) => {
    const analysis = searchMinAnalysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchMinValue = (analysisList = []) => {
    const analysis = searchMinAnalysis(analysisList)
    return calculateValue(analysis)
}

const searchP90Analysis = (analysisList = []) => {
    if (!analysisList.length) {
        return
    }
    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))

    const orderedInferior = orderBy(inferior, calculateResult)
    const orderedsSuperior = orderBy(superior, calculateResult)
    const orderedTab = orderBy(tab, calculateResult)

    return calculatePercentile90([...orderedInferior, ...orderedTab, ...orderedsSuperior])
}

const searchP90Result = (analysisList = []) => {
    const analysis = searchP90Analysis(analysisList)
    const result = calculateResult(analysis)
    if (isUndefined(result)) {
        return
    }
    return round(result, 4)
}

const searchP90Value = (analysisList = []) => {
    const analysis = searchP90Analysis(analysisList)
    return calculateValue(analysis)
}

const calculateAverage = (analysisList = [], settings = []) => {
    if (!analysisList.length) {
        return
    }

    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const tab = analysisList.filter(elem => !elem.value.includes('<'))

    if (!inferior.length) {
        return round(meanBy(tab, calculateResult), 3)
    }

    const methodLQ = getSetting(settings, 'TypeLimiteQuantification') || '3'

    if (methodLQ === '4') {
        const choosenLQValue = getSetting(settings, 'ValeurLimiteQuantification') || '0'
        const correctedInferior = inferior.map(a => ({ ...a, result: parseFloat(choosenLQValue) || 0 }))
        return round(meanBy([...correctedInferior, ...tab], calculateResult), 3)
    }

    if (methodLQ === '5') {
        if (!tab.length) {
            return
        }
        return round(meanBy(tab, calculateResult), 3)
    }
    // methodLQ === '3'
    const correctedInferior = inferior.map(a => ({ ...a, result: a.result / 2 }))
    return round(meanBy([...correctedInferior, ...tab], calculateResult), 3)
}

const calculateGeometricAverage = (list = []) => {
    if (!list.length) {
        return
    }
    const listResult = list.map(calculateResult)
    const filteredResult = listResult.filter(v => v > 0)
    const resultSum = filteredResult.reduce((acc, v) => acc + Math.log(v), 0)
    return round(Math.exp(resultSum / filteredResult.length), 3)
}

const searchQuartile = (arr, q) => {
    const pos = arr.length * q
    const index = ceil(pos)
    return getValueAnalyse(arr[index])
}

const calculateRangeInterquartile = analysisList => {
    if (analysisList.length < 4) {
        return 0
    }

    const inferior = analysisList.filter(elem => elem.value.includes('<'))
    const superior = analysisList.filter(elem => elem.value.includes('>'))
    const tab = analysisList.filter(elem => !elem.value.includes('<') && !elem.value.includes('>'))

    const orderedInferior = orderBy(inferior, calculateResult)
    const orderedsSuperior = orderBy(superior, calculateResult)
    const orderedTab = orderBy(tab, calculateResult)

    const orderedList = [...orderedInferior, ...orderedTab, ...orderedsSuperior]

    const q1 = searchQuartile(orderedList, 0.25)
    const q3 = searchQuartile(orderedList, 0.75)
    return round(q3 - q1, 4)
}

const calculateStandardDeviation = analysisList => {
    if (!analysisList.length) {
        return
    }
    if (analysisList.length === 1) {
        return 0
    }
    const mean = calculateAverage(analysisList)
    const v = sum(analysisList.map(a => (calculateResult(a) - mean) ** 2))
    return round(Math.sqrt(v / (analysisList.length - 1)), 3)
}

// analysisList should be filtered by parameter and unit and formatted with calculateThresholdResult
const getWorstAnalysis = (analysisList = []) => {
    if (analysisList.length === 0) {
        return
    }
    const groupByIndice = groupBy(analysisList, 'thresholdIndice')
    const maxIndice = max(keys(groupByIndice))
    const maxAnalysis = groupByIndice[maxIndice]
    const thresholdType = getThresholdType(maxAnalysis[0]?.threshold)

    if (thresholdType === THRESHOLD_TYPE.DECREASING) {
        return searchMinAnalysis(maxAnalysis)
    }
    if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
        if (maxIndice === 1) {
            return searchMinAnalysis(maxAnalysis)
        }
        return searchMaxAnalysis(maxAnalysis)
    }
    // increasing threshold or undefined
    return searchMaxAnalysis(maxAnalysis)
}

// formatage d'un dénombrement
const calculateTaxonThresholdResult = (list, thresholds = []) => {
    const threshold = getThreshold(thresholds, list.taxon, undefined, list.qualitometer)
    const result = (list.enumerationA ?? 0) + (list.enumerationB ?? 0) + (list.enumerationC ?? 0) + (list.enumerationC2 ?? 0)
    switch (list.remark) {
        case '0': // Analyse non faite
            return {
                value: '',
                result,
                color: 'white',
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} 1`,
                threshold,
                thresholdIndice: 0,
            }
        case '2': // < seuil de détection
        case '7': // Traces
        case '10': // < au seuil de quantification
            return {
                value: hasValue(result) ? `<${round(result, 5)}` : '',
                result,
                color: getMinThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `< ${i18n.threshold} 1`,
                threshold,
                thresholdIndice: 0,
            }
        case '3': // > seuil de saturation
            const maxIndice = getMaxIndiceThreshold(threshold)
            return {
                value: hasValue(result) ? `>${round(result, 5)}` : '',
                result,
                color: getMaxThresholdColor(threshold),
                textColor: 'black',
                thresholdLabel: `> ${i18n.threshold} ${maxIndice}`,
                threshold,
                thresholdIndice: maxIndice,
            }
        default:
            const {
                thresholdIndice = 0,
                color = 'white',
            } = getThresholdResult(threshold, result)
            return {
                value: hasValue(result) ? `${round(result, 5)}` : '',
                result,
                color,
                textColor: 'black',
                thresholdLabel: thresholdIndice > 0 ? `> ${i18n.threshold} ${thresholdIndice}` : `< ${i18n.threshold} 1`,
                threshold,
                thresholdValue: threshold?.[`threshold${thresholdIndice}`],
                thresholdIndice,
            }
    }
}

export {
    calculatePercentile90,
    getRemarks,
    isQuantified,
    remarks,

    THRESHOLD_COLOR,
    getThresholdsColor,
    getThresholdColors,
    getQualificationColor,
    getStatusIcon,
    getStatusAndResult,
    getStatusColor,
    getTabColorsBySize,
    getNonConformSelectData,
    getLocalizationPicto,
    getLocalizationLabel,
    getAccreditationPicto,
    getResultFormat,
    filterThresholdLevel,
    getQualificationIcon,
    getIconQualification,
    getIndiceValue,
    getValueAnalyse,
    getDisplayValueAnalyse,

    // new
    quantificationRemark,
    detectionRemarkCodes,

    filterValid,
    filterResult,
    filterQuantification,
    filterDetection,

    getThreshold,
    getMaxIndiceThreshold,
    getThresholdLabel,
    calculateThresholdResult,
    calculateResult,
    calculateValue,

    searchMaxAnalysis,
    searchMaxResult,
    searchMaxValue,
    searchMinAnalysis,
    searchMinResult,
    searchMinValue,
    searchP90Analysis,
    searchP90Result,
    searchP90Value,
    calculateAverage,
    calculateGeometricAverage,
    calculateRangeInterquartile,
    calculateStandardDeviation,
    getWorstAnalysis,

    getThresholdType,
    THRESHOLD_TYPE,
    tabPastelColorsBySize,
    getThresholdResult,

    calculateTaxonThresholdResult,
}
