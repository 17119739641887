/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getUdiSectorName } from 'pgsse/util/PgsseUtil'
import Form from './form/Form'
import { useParams } from 'react-router'
import PgsseAction from 'pgsse/action/PgsseAction'
import { getLabel } from 'utils/StoreUtils'

const PgsseFormApp = ({
}) => {
    const dispatch = useDispatch()
    const { idDiagnostic, idForm } = useParams()

    useEffect(() => {
        dispatch(PgsseAction.fetchDiagnostic(idDiagnostic))
    }, [idDiagnostic])

    const {
        distributionUnits,
        sectors,
        diagnostic,
        form,
        contributors,
    } = useSelector(store => ({
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        diagnostic: store.PgsseReducer.diagnostic,
        form: store.PgsseReducer.form,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    useTitle(() => {
        const udi = distributionUnits.find(du => du.id === diagnostic.idUdi)
        const sector = sectors.find(s => s.id === diagnostic.idSector)
        return [{
            title: i18n.pgsse,
            href: PATH_PGSSE,
        }, {
            title: i18n.phase1,
            href: `${PATH_PGSSE}/phase1`,
        }, {
            title: getUdiSectorName(udi, sector),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}`,
        }, {
            title: getLabel(contributors, form.code),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}/${idForm}`,
        }]
    }, [diagnostic, contributors])


    return <Form title={getLabel(contributors, form.code)} />
}

PgsseFormApp.propTypes = {
}

export default PgsseFormApp
