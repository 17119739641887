import { Button, DialogActions, DialogContent, Dialog, Grid2, Accordion, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { StyledFieldSet } from 'components/StyledElements'
import i18n from 'simple-react-i18n'
import Checkbox from 'components/forms/Checkbox'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import AnalysisAction from 'quality/actions/AnalysisAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OperationAction from '../operation/actions/OperationAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import QualityIntegrationOverviewAction from '../integrationOverview/actions/QualityIntegrationOverviewAction'
import useBoolean from 'utils/customHook/useBoolean'
import ProgressBar from 'components/progress/ProgressBar'
import { sieauTooltip } from 'utils/FormUtils'
import { substringText } from 'utils/StringUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import { chunk, countBy, flatten } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import { getFullDate } from 'utils/DateUtil'
import { calculateThresholdResult, getQualificationIcon, getStatusIcon } from 'utils/AnalyseUtils'
import { exportFile } from 'utils/ExportDataUtil'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import TooltipAnalyse from '../qualityComponents/TooltipAnalyse'
import { arrayLightToObject, promiseAllProgress } from 'utils/ActionUtils'
import useAbortController from 'utils/customHook/useAbortController'
import { SuperParameterGraphModal } from '../qualityComponents/ParameterGraph'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { AccordionTitle } from 'components/styled/Accordions'
import { ALIGN, NewTable, WrapperAccordionDetails } from 'components/datatable/NewTable'

const keysAnalysis = ['qualitometer', 'id', 'sample', 'operation', 'sampleDate', 'analysisDate', 'parameter', 'unit', 'result', 'remark', 'support', 'localization', 'lq', 'ld', 'saturationThreshold', 'qualification', 'status', 'fraction', 'labo', 'jobexecutionid', 'importDate', 'updateDate', 'updateLogin', 'numSampling', 'errors']
const specificFormat = { errors: v => v ? v.split(',') : [] }

const formatTitle = (label, nbElement) => `${label} (${nbElement} ${nbElement > 1 ? i18n.elements : i18n.element})`

const MultiLineLabel = ({
    label = '',
    listLabel = [],
}) => listLabel.length ? (
    <div style={{ margin: '0px 0px' }}>
        {label}
        <br />
        {listLabel.map((lbl, i) => {
            if (lbl.length > 80) {
                return (
                    <Tooltip key={i} title={lbl}>
                        <div key={i} style={{ paddingTop: '2px' }}>
                            {substringText(lbl, 80)}
                            <br />
                        </div>
                    </Tooltip>
                )
            }
            return (
                <div key={i} style={{ paddingTop: '2px' }}>
                    {lbl}
                    <br />
                </div>
            )
        })}
    </div>
) : label

MultiLineLabel.propTypes = {
    label: PropTypes.string,
    listLabel: PropTypes.arrayOf(PropTypes.string),
}

const HEADER_CONFORM = ['code', 'name', 'sample', 'labo', 'parameter', 'result', 'unit', 'support', 'fraction', 'remark']
const HEADER_NON_CONFORM = ['code', 'name', 'sample', 'labo', 'parameter', 'result', 'unit', 'support', 'fraction', 'remark', 'conformity', 'threshold', 'outrageous']

const ControlTable = ({
    title = '',
    analysis = [],
    headers = [],
    defaultExpanded = false,

    onOpenGraph = () => {},
}) => {
    const {
        parameters,
        units,
        supports,
        contributors,
        fractions,
        analyseErrorTypes,
        status,
        qualitosIndex,
        remarks,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        fractions: store.FractionReducer.fractions,
        analyseErrorTypes: store.QualityIntegrationOverviewReducer.analyseErrorTypes,
        status: store.QualityReducer.status,
        qualitosIndex: store.QualityReducer.qualitosIndex,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedFractions = useListIndexed(fractions, 'code')
    const indexedUnits = useListIndexed(units, 'code')
    const indexedSupports = useListIndexed(supports, 'code')
    const indexedContributors = useListIndexed(contributors, 'code')

    const formattedData = analysis.map(a => {
        const station = qualitosIndex[a.qualitometer]
        const errors = a.errors.map(err => analyseErrorTypes.find(({ code }) => code === err) || {})
        const labelCode = errors.map(({ label }) => label || '')

        const errorGroup = countBy(errors, e => e.controlType ?? 0)

        return {
            code: {
                value: (<MultiLineLabel label={station?.code} listLabel={a.errors} />),
                sortValue: station?.code,
                verticalAlign: ALIGN.TOP,
            },
            exportCode: a.errors.join(','),
            name: {
                value: (<MultiLineLabel label={station?.name} listLabel={labelCode} />),
                sortValue: station?.name,
                verticalAlign: ALIGN.TOP,
            },
            exportName: labelCode.join(','),
            sample: { value: getFullDate(a.sampleDate), sortValue: a.sampleDate, verticalAlign: ALIGN.TOP },
            labo: { value: indexedContributors[a.labo]?.labelDisplay, verticalAlign: ALIGN.TOP },
            parameter: { value: indexedParameters[a.parameter]?.displayLabel, verticalAlign: ALIGN.TOP },
            result: {
                value: (
                    <div className='valign-wrapper'>
                        {getStatusIcon(a.status, status, {}, false)}
                        {
                            a.qualification !== '1' && (
                                <i className='material-icons tiny right no-margin'>{getQualificationIcon(a.qualification)}</i>
                            )
                        }
                        <span style={{ marginLeft: a.qualification !== '1' ? '5px' : '0px' }}>{a.value}</span>
                    </div>
                ),
                setTooltip: () => (<TooltipAnalyse analyse={a} />),
                sortValue: a.result,
                verticalAlign: ALIGN.TOP,
            },
            unit: { value: indexedUnits[a.unit]?.symbolWithCode, verticalAlign: ALIGN.TOP },
            support: { value: indexedSupports[a.support]?.labelWithCode, verticalAlign: ALIGN.TOP },
            fraction: { value: indexedFractions[a.fraction]?.labelWithCode, verticalAlign: ALIGN.TOP },
            remark: { value: getLabel(remarks, a.remark), verticalAlign: ALIGN.TOP },
            conformity: { value: `${errorGroup[1] ?? 0}`, verticalAlign: ALIGN.TOP, positionCell: 'right' },
            threshold: { value: `${errorGroup[3] ?? 0}`, verticalAlign: ALIGN.TOP, positionCell: 'right' },
            outrageous: { value: `${errorGroup[2] ?? 0}`, verticalAlign: ALIGN.TOP, positionCell: 'right' },
            paramGraph: { parameter: a.parameter, qualitometer: a.qualitometer },
        }
    })

    const exportAction = {
        icon: 'file_download',
        color: 'white',
        onClick: () => {
            const exportData = formattedData.map(d => ({
                ...d,
                result: { value: d.result.sortValue },
                code: d.code.sortValue, // d.exportCode,
                name: d.name.sortValue, // d.exportName,
                errorCode: d.exportCode,
                errorLabel: d.exportName,
            }))
            exportFile({
                data: exportData.length ? [
                    { ...exportData[0], headers: ['errorCode', 'errorLabel', ...HEADER_NON_CONFORM] },
                    ...exportData.slice(1),
                ] : [],
                titleFile: title,
            })
        },
    }
    const actions = [exportAction]

    return (
        <>
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionTitle
                    title={formatTitle(title, formattedData.length)}
                    actions={actions}
                />
                <NewTable
                    headers={headers}
                    rows={formattedData}
                    rowsPerPageOptions={nbPerPageLabel}
                    onClickRow={({ paramGraph }) => onOpenGraph(paramGraph)}
                    maxHeight='45vh'
                    WrapperComponent={WrapperAccordionDetails}
                />
            </Accordion>
        </>
    )
}

ControlTable.propTypes = {
    title: PropTypes.string,
    analysis: PropTypes.arrayOf(PropTypes.shape({})),
    headers: PropTypes.arrayOf(PropTypes.string),
    defaultExpanded: PropTypes.bool,

    onOpenGraph: PropTypes.func,
}

const ControlPopin = ({
    isOpen = false,
    close = () => {},
    filters = {},

    onOpenGraph = () => {},
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(OperationAction.fetchRemarks())
        dispatch(FractionAction.fetchFractions())
        dispatch(SupportAction.fetchSupports())
        dispatch(ParameterAction.fetchParameters())
        dispatch(QualityIntegrationOverviewAction.fetchAnalyseErrorTypes())
    }, [dispatch])

    const [conformitySandre, setConformitySandre] = useLocalStorage('CONTROL_CONFORMITY_SANDRE', false)
    const [outlier, setOutlier] = useLocalStorage('CONTROL_OUTLIER', false)
    const [multiParam, setMultiParam] = useLocalStorage('CONTROL_MULTIPARAM', false)
    const [improbabilityThreshold, setImprobabilityThreshold] = useState()
    const [impossibilityThreshold, setImpossibilityThreshold] = useState()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const [progress, setProgress] = useState(0)
    const { value: isLoading, setTrue: loading, setFalse: notLoading } = useBoolean(false)
    const { value: isLoaded, setTrue: loaded } = useBoolean(false)
    const [analysis, setAnalysis] = useState([])

    useUpdateEffect(() => {
        if (!isOpen) {
            controllerRef.current.abort()
            setAnalysis([])
        }
        return () => controllerRef.current.abort()
    }, [isOpen])

    const formattedAnalysis = useMemo(() => analysis.map(a => ({ ...a, ...calculateThresholdResult(a) })), [analysis])

    const undefinedConformity = useMemo(() => formattedAnalysis.filter(a => a.errors.includes('UNDEFINED_CONFORMITY')), [formattedAnalysis])
    const conformData = useMemo(() => formattedAnalysis.filter(a => !a.errors.length), [formattedAnalysis])
    const nonConformData = useMemo(() => formattedAnalysis.filter(a => a.errors.length && !a.errors.includes('UNDEFINED_CONFORMITY')), [formattedAnalysis])

    return (
        <Dialog
            fullWidth
            // maxWidth='huge'
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '95vh',
                    maxHeight: '95vh',
                    minWidth: '75vw',
                    maxWidth: '75vw',
                },
            }}
        >
            <DefaultDialogTitle
                title={i18n.controlQuality}
                onClose={close}
            />
            <DialogContent sx={{ overflow: 'visible' }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={12}>
                        <StyledFieldSet>
                            <Grid2 container spacing={1} sx={{ padding: '5px' }}>
                                <Grid2 size={4}>
                                    <Checkbox
                                        label={i18n.controlSandre}
                                        checked={conformitySandre}
                                        onChange={setConformitySandre}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Checkbox
                                        label={i18n.controlOutlier}
                                        checked={outlier}
                                        onChange={setOutlier}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Checkbox
                                        label={i18n.controlMultiParam}
                                        checked={multiParam}
                                        onChange={setMultiParam}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <ThresholdSelect
                                        title={i18n.improbability}
                                        selected={improbabilityThreshold}
                                        onChange={setImprobabilityThreshold}
                                        fieldRef='CONTROL_IMPROBABILITY'
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <ThresholdSelect
                                        title={i18n.impossibility}
                                        selected={impossibilityThreshold}
                                        onChange={setImpossibilityThreshold}
                                        fieldRef='CONTROL_IMPOSSIBILITY'
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    {isLoading && (
                        <Grid2 size={12}>
                            <ProgressBar indeterminate={filters?.stations > 3} withMessage progress={progress}/>
                        </Grid2>
                    )}
                    {!isLoading && isLoaded && (
                        <>
                            {
                                nonConformData.length !== 0 && (
                                    <Grid2 size={12}>
                                        <ControlTable
                                            title={i18n.nonConformAnalysis}
                                            analysis={nonConformData}
                                            headers={HEADER_NON_CONFORM}
                                            defaultExpanded
                                            onOpenGraph={onOpenGraph}
                                        />
                                    </Grid2>
                                )
                            }
                            {
                                conformData.length !== 0 && (
                                    <Grid2 size={12}>
                                        <ControlTable
                                            title={i18n.conforms}
                                            analysis={conformData}
                                            headers={HEADER_CONFORM}
                                            onOpenGraph={onOpenGraph}
                                        />
                                    </Grid2>
                                )
                            }
                            {
                                undefinedConformity.length !== 0 && (
                                    <Grid2 size={12}>
                                        <ControlTable
                                            title={i18n.undefinedConformity}
                                            analysis={undefinedConformity}
                                            headers={HEADER_CONFORM}
                                            onOpenGraph={onOpenGraph}
                                        />
                                    </Grid2>
                                )
                            }
                        </>
                    )}
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        loading()
                        setProgress(0)

                        if (controllerRef.current.signal.aborted) {
                            initController()
                        }

                        const controlObject = {
                            conformitySandre,
                            outlier,
                            multiParam,
                            improbabilityThreshold,
                            impossibilityThreshold,
                        }

                        const group = Math.min(Math.floor(filters.stations.length / 25), 20)
                        const promises = chunk(filters.stations, group > 0 ? group : 1).map(stationsGroup => AnalysisAction.getControledAnalysis(controlObject, { ...filters, stations: stationsGroup }, controllerRef.current.signal)
                            .catch(() => []))
                        promiseAllProgress(promises, setProgress).then(list => {
                            setAnalysis(flatten(list).map(a => arrayLightToObject(a, keysAnalysis, specificFormat)))
                        })
                            .then(loaded)
                            .finally(notLoading)
                    }}
                    variant='contained'
                    color='primary'
                >
                    Controler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ControlPopin.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    filters: PropTypes.shape({}), // QualityFilter

    onOpenGraph: PropTypes.func,
}

const ControlAnalysisAction = ({
    filters = {},
}) => {
    const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false)
    const { value: isGraphOpen, setTrue: openGraph, setFalse: closeGraph } = useBoolean(false)
    const [paramGraph, setParamGraph] = useState({})
    return (
        <div className='action-wrapper'>
            <a
                className='right waves-effect nav-actions blue-arrow'
                { ...sieauTooltip(i18n.controlAnalysis, 'update_action_navbar', 'bottom') }
                id='calculate_indice_action_navbar'
                onClick={() => {
                    open()
                }}
            >
                <i className='material-icons left no-margin'>assignment_turned_in</i>
            </a>
            <ControlPopin
                isOpen={isOpen}
                close={close}
                filters={filters}
                onOpenGraph={params => {
                    setParamGraph(params)
                    openGraph()
                }}
            />
            <SuperParameterGraphModal
                isOpen={isGraphOpen}
                closeGraph={closeGraph}

                parameter={paramGraph.parameter}
                qualitometer={paramGraph.qualitometer}
            />
        </div>
    )
}

ControlAnalysisAction.propTypes = {
    filters: PropTypes.shape({}), // QualityFilter
}

export {
    ControlAnalysisAction,
    ControlPopin,
    ControlTable,
    HEADER_CONFORM,
    HEADER_NON_CONFORM,
}