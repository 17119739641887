import Select from '../../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getSetting } from '../../../../utils/SettingUtils'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SieauParameterDto from '../../../dto/SieauParameterDto'
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
    DefaultDialogTitle,
} from '../../../../components/styled/Dialog'
import Checkbox from 'components/forms/Checkbox'
import CmsAction from 'events/actions/CmsAction'
import { isNil } from 'lodash'
import LoadingButton from 'components/buttons/LoadingButton'

const ModalTestSms = ({ api, openModal, setOpenModal }) => {
    const dispatch = useDispatch()

    const [message, setMessage] = useState(i18n.smsDefault)
    const [phoneNumber, setPhoneNumber] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState()

    const sendSms = () => {
        setIsLoading(true)
        dispatch(CmsAction.sendTestSms({ message, phoneNumber })).then((response = {}) => {
            setStatus(response.result?.[0]?.status ?? i18n.unknown)
            setIsLoading(false)
        })
    }

    const disableButton = () => {
        switch (api) {
            case 'clickSend':
            case 'sendEthic':
                const regexSendEthic = /^\+33[1-9]\d{8}$/
                return regexSendEthic.test(phoneNumber)
            case 'smsFactor':
                const regexSmsFactor = /^33[1-9]\d{8}$/
                return regexSmsFactor.test(phoneNumber)
            default :
                return true
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={openModal}
        >
            <DefaultDialogTitle title={i18n.smsTest} onClose={() => setOpenModal(false)}/>
            <DialogContent>
                <Grid2 container spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Grid2 size={12}>
                        <Input
                            title={i18n.yourMessage}
                            value={ message }
                            onChange={setMessage}
                        />
                    </Grid2>
                    <Grid2 size={5}>
                        <Input
                            title={i18n.number}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                        />
                    </Grid2>
                    <Grid2 size={7}>
                        {api === 'smsFactor' && 'Format 33 (ex: 33644556677)'}
                        {(api === 'sendEthic' || api === 'clickSend') && 'Format + 33 (ex: +33644556677)'}
                    </Grid2>
                    <Grid2 size={12}>
                        {isNil(status) && i18n.smsDelay}
                        {!isNil(status) && `${i18n.status} ${api}: ${status}`}
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Grid2 container alignItems='center' justifyContent='right' columnSpacing={2} sx={{ padding: '0 10', width: '100%' }}>
                    <Grid2 container size={{ md: 4, sm: 6 }} justifyContent='flex-end'>
                        <LoadingButton
                            loading={isLoading}
                            variant='contained'
                            color='primary'
                            onClick={sendSms}
                            fullWidth
                            disabled={!disableButton()}
                        >
                            {i18n.validate}
                        </LoadingButton>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </Dialog>
    )
}

ModalTestSms.propTypes = {
    api: PropTypes.string,
    params: PropTypes.shape({
        numAccount: PropTypes.string,
        token: PropTypes.string,
        key: PropTypes.string,
    }),
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
}

const SendEthic = ({
    applicationSettings,
    change,
    disabled,
})=> {
    const [openModal, setOpenModal] = useState(false)
    return (
        <Grid2 container size={12} spacing={1}>
            {/* <Grid2 size={6}>
                <Input
                    title={i18n.emailSenderSendEthic}
                    value={(getSetting(applicationSettings, 'fromMailSendEthic') || '')}
                    onChange={(v) => change('fromMailSendEthic', v)}
                    {...disabled}
                />
            </Grid2>*/}
            <Grid2 size={12}>
                <Input
                    title={i18n.identifier}
                    value={(getSetting(applicationSettings, 'smsId') || '')}
                    onChange={(v) => change('smsId', v)}
                    {...disabled}
                />
            </Grid2>
            <Grid2 size={12}>
                <Textarea
                    title={i18n.token}
                    value={(getSetting(applicationSettings, 'smsToken') || '')}
                    onChange={v => change('smsToken', v)}
                    {...disabled}
                />
            </Grid2>
            {
                disabled.disabled && (
                    <Button style={{ top: 5, margin: 10 }} onClick={() => setOpenModal(true)} variant='outlined'>
                        {i18n.smsTest}
                    </Button>
                )
            }
            <ModalTestSms api={'sendEthic'} params={ { numAccount: getSetting(applicationSettings, 'smsId'), key: getSetting(applicationSettings, 'smsToken') } } openModal={openModal} setOpenModal={setOpenModal}/>
        </Grid2>
    )
}
SendEthic.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

const SmsFactor = ({
    applicationSettings,
    change,
    disabled,
})=> {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <div className='row no-margin padding-top-10-px'>
                <Textarea
                    col={12}
                    title={i18n.token}
                    value={(getSetting(applicationSettings, 'smsToken') || '')}
                    onChange={v => change('smsToken', v)}
                    {...disabled}
                />
                {
                    disabled.disabled && (
                        <Button style={{ top: 5, margin: 10 }} onClick={() => setOpenModal(true)} variant='outlined' >
                            {i18n.smsTest}
                        </Button>
                    )
                }
                <ModalTestSms api={'smsFactor'} params={ { token: getSetting(applicationSettings, 'smsToken') } } openModal={openModal} setOpenModal={setOpenModal}/>
            </div>
        </>
    )
}

SmsFactor.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

const ClickSend = ({
    applicationSettings,
    change,
    disabled,
})=> {
    const [openModal, setOpenModal] = useState(false)
    return (
        <Grid2 container size={12} spacing={1}>
            <Grid2 size={6}>
                <Input
                    title={i18n.identifier}
                    value={(getSetting(applicationSettings, 'smsId') || '')}
                    onChange={(v) => change('smsId', v)}
                    {...disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    title={i18n.token}
                    value={(getSetting(applicationSettings, 'smsToken') || '')}
                    onChange={(v) => change('smsToken', v)}
                    {...disabled}
                />
            </Grid2>
            {
                disabled.disabled && (
                    <Button style={{ top: 5, margin: 10 }} onClick={() => setOpenModal(true)} variant='outlined'>
                        {i18n.smsTest}
                    </Button>
                )
            }
            <ModalTestSms
                api={'clickSend'}
                params={ { numAccount: getSetting(applicationSettings, 'smsId'), key: getSetting(applicationSettings, 'smsToken') } }
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
        </Grid2>
    )
}

ClickSend.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

const SettingSms = ({
    applicationSettings,
    change,
    disabled,
}) => {
    const smsSetting = applicationSettings.find(a=> a.parameter === 'smsSettings')?.value

    return (
        <Grid2 container spacing={1}>
            <Grid2 container paddingTop={1} size={12}>
                <Grid2 size={6}>
                    <Select
                        label={i18n.setting}
                        options={[
                            { id: 'sendEthic', label: 'SendEthic' },
                            { id: 'clickSend', label: 'ClickSend' },
                            /* { id: 'smsFactor', label: 'smsFactor' },*/
                        ]}
                        value={getSetting(applicationSettings, 'smsSettings')}
                        onChange={(v) => change('smsSettings', v)}
                        {...disabled}
                    />
                </Grid2>
                { smsSetting === 'sendEthic' &&
                    <>
                        <Grid2 size={3}>
                            <Checkbox
                                label={i18n.sendEthicUse}
                                checked={!!getSetting(applicationSettings, 'sendEthicMail')}
                                onChange={() => change('sendEthicMail', !getSetting(applicationSettings, 'sendEthicMail'))}
                                componentClassName='margin-top-1'
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={3}>
                            <Input
                                title={i18n.emailSender}
                                value={(getSetting(applicationSettings, 'userSendEthicMail') || '')}
                                onChange={(v) => change('userSendEthicMail', v)}
                                {...disabled}
                            />
                        </Grid2>
                    </>
                }
            </Grid2>
            { smsSetting === 'sendEthic' &&
                <SendEthic
                    applicationSettings={applicationSettings}
                    change={change}
                    disabled={disabled}
                />
            }
            { smsSetting === 'smsFactor' &&
                <SmsFactor
                    applicationSettings={applicationSettings}
                    change={change}
                    disabled={disabled}
                />
            }
            { smsSetting === 'clickSend' &&
                <ClickSend
                    applicationSettings={applicationSettings}
                    change={change}
                    disabled={disabled}
                />
            }
        </Grid2>
    )
}

SettingSms.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    change: PropTypes.func,
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
}

export default SettingSms