import { Button, Grid } from '@mui/material'
import { PATH_ALERT, PATH_MODEL_CONFIGURATION } from 'home/constants/RouteConstants'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import DtoDataType from '../../../station/dto/DtoDataType'
import SieauParameterDto from '../../dto/SieauParameterDto'
import SearchTable from 'referencial/components/SearchTable'
import CmsAction from 'events/actions/CmsAction'
import { getMiniHour } from 'utils/DateUtil'
import Icon from 'components/icon/Icon'
import AlertModelStepper from 'alerting/components/map/AlertModelStepper'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { sortBy } from 'lodash'

const headers = ['nullValue', 'nullValue2', 'nullValue3', 'nullValue4', 'categoryId', 'title', 'planification', 'hourGeneration', 'hourPublication']

const AdminModelApp = ({

}) => {
    const {
        cmsModels,
        cmsCategories,
    } = useSelector(store => ({
        cmsModels: store.EventsReducer.cmsModels,
        cmsCategories: store.EventsReducer.cmsCategories,
    }), shallowEqual)

    const [modelToOpen, setModelToOpen] = useState({})
    const [openModel, setOpenModel] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.situation,
        href: PATH_ALERT,
    }, {
        title: i18n.modelOptions,
        href: PATH_MODEL_CONFIGURATION,
    }], [])

    useEffect(() => {
        dispatch(CmsAction.fetchAllCMSModels())
    }, [])

    useActions(() => ({
        new: () => {
            setModelToOpen({})
            setOpenModel(true)
        },
    }), [])

    const daysOptions = useMemo(() => [
        { value: '*', label: i18n.allDays },
        { value: '1', label: i18n.monday },
        { value: '2', label: i18n.tuesday },
        { value: '3', label: i18n.wednesday, disabled: true },
        { value: '4', label: i18n.thursday },
        { value: '5', label: i18n.friday },
        { value: '6', label: i18n.saturday },
        { value: '0', label: i18n.sunday },
    ], [])

    const dataFormatted = useMemo(() => sortBy(cmsModels, 'modelId').map(model => ({
        ...model,
        nullValue: (
            <Icon
                size='small'
                icon='close'
                onClick={() => {
                    setModelToOpen(model)
                    setOpenDelete(true)
                }}
                tooltip={i18n.delete}
            />
        ),
        nullValue2: (
            <Icon
                size='small'
                icon='edit'
                onClick={() => {
                    setModelToOpen(model)
                    setOpenModel(true)
                }}
                tooltip={i18n.change}
            />
        ),
        nullValue3: (
            <Icon
                size='small'
                icon='download'
                onClick={() => dispatch(CmsAction.exportJsonCmsModel(model.modelId))}
                tooltip={i18n.downloadJson}
            />
        ),
        nullValue4: (
            <Icon
                size='small'
                icon='play_circle_outline'
                onClick={() => dispatch(CmsAction.playCmsModel(model.modelId))}
                tooltip={i18n.create}
            />
        ),
        categoryId: cmsCategories.find(categ => categ.id === model.categoryId)?.title || i18n.unknown,
        planification: daysOptions.find(opt => opt.value === model.planification)?.label || i18n.unknown,
        hourGeneration: getMiniHour(model.hourGeneration),
        hourPublication: getMiniHour(model.hourPublication),
    })), [cmsCategories, cmsModels, daysOptions])

    const onValidate = () => {
        dispatch(CmsAction.deleteCMSModel(modelToOpen.modelId))
        setOpenDelete(false)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <SearchTable
                    title={i18n.newslettersModels}
                    data={dataFormatted}
                    headers={headers}
                    customHeaders={{
                        categoryId: i18n.category,
                        title: i18n.name,
                        planification: i18n.frequency,
                        hourPublication: i18n.sendingTime,
                    }}
                    customWidthHeaders={{
                        nullValue: '0%',
                        nullValue2: '0%',
                        nullValue3: '0%',
                        nullValue4: '5%',
                        categoryId: '15%',
                        title: '35%',
                        planification: '15%',
                        hourGeneration: '15%',
                        hourPublication: '15%',
                    }}
                />
                <AlertModelStepper
                    modelToOpen={modelToOpen}
                    isOpen={openModel}
                    setIsOpen={setOpenModel}
                />
                <DialogMUI
                    maxWidth='md'
                    open={openDelete}
                    PaperProps={{
                        sx: {
                            minHeight: '35vh',
                            maxHeight: '35vh',
                        },
                    }}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.areYouSureWantDeleteModel}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenDelete(false)} />
                    </DialogTitleMUI>
                    <DialogContentMUI style={{ paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container justifyContent='center'>
                            <Grid item sx={{ paddingRight: '2rem' }}>
                                <Icon
                                    size='large'
                                    icon={cmsCategories.find(categ => categ.id === modelToOpen.categoryId)?.icon}
                                />
                            </Grid>
                            <Grid item>
                                <h3>{modelToOpen.title}</h3>
                            </Grid>
                        </Grid>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button onClick={onValidate} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </DialogMUI>
            </Grid>
        </Grid>
    )
}

AdminModelApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDataType)),
    setTitle: PropTypes.func,
    updateDataTypesByProject: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    push: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

export default AdminModelApp
