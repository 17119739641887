/* eslint-disable camelcase */
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import { shallowEqual, useSelector } from 'react-redux'
// import { flatten, groupBy } from 'lodash'

const PGSSEFilters = ({
    onValidate = () => { },
}) => {
    const [filter, setFilter] = useState({})

    const {
        contributors,
        distributionUnits,
        sectors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    const onChange = (values) => setFilter(prev => ({ ...prev, ...values }))

    const launchSearch = () => {
        onValidate({
            search: filter.search,
            idCollectivity: filter.collectivity,
            idSector: filter.sector,
            idUdi: filter.udi,
        })
    }

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={2}>
                        <Input
                            title={i18n.search}
                            value={filter.search}
                            onChange={v => onChange({ search: v })}
                            onEnterKeyPress={launchSearch}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={contributors}
                            label={i18n.collectivity}
                            value={filter.collectivity}
                            onChange={v => onChange({ collectivity: v })}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={sectors}
                            label={i18n.sector}
                            value={filter.sector}
                            onChange={v => onChange({ sector: v })}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={distributionUnits}
                            label={i18n.distributionUnitName}
                            value={filter.udi}
                            onChange={v => onChange({ udi: v })}
                            keyValue='id'
                        />
                    </Grid2>
                    <Grid2 container offset={2} size={2} justifyContent='flex-end' alignItems='center'>
                        <Button onClick={launchSearch} variant='contained'>{i18n.search}</Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

PGSSEFilters.propTypes = {
    filter: PropTypes.shape({
        search: PropTypes.string,
        collectivity: PropTypes.integer,
        sector: PropTypes.integer,
        distributionUnitName: PropTypes.integer,
    }),
    onValidate: PropTypes.func,
}

export default PGSSEFilters