/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { CardTitle } from 'components/card/NewCard'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import Select from 'components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import useTitle from 'utils/customHook/useTitle'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { useParams } from 'react-router'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import LastUpdateCard from 'components/card/LastUpdateCard'
import ToastrAction from 'toastr/actions/ToastrAction'
import { compact } from 'lodash'
import PgsseAction from 'pgsse/action/PgsseAction'
import { push } from '@lagunovsky/redux-react-router'
import { getUdiSectorName } from 'pgsse/util/PgsseUtil'
import FormContributorPanel from './diagnostic/FormContributorPanel'
import { CampaignTabs } from './campaign/CampaignTabs'

const PgsseDiagnosticApp = () => {
    const dispatch = useDispatch()

    const { idDiagnostic } = useParams()

    const {
        contributors,
        distributionUnits,
        sectors,
        diagnostic: propsDiagnostic,
        surveys,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        diagnostic: store.PgsseReducer.diagnostic,
        surveys: store.PgsseReducer.surveys,
    }), shallowEqual)

    const [diagnostic, setDiagnostic] = useState(propsDiagnostic)
    const [readMode, setReadMode] = useState(true)
    const [sortBy, setSortBy] = useState()

    useEffect(() => {
        dispatch(PgsseAction.fetchDiagnostic(idDiagnostic))
    }, [idDiagnostic])

    useEffect(() => setDiagnostic(propsDiagnostic), [propsDiagnostic])

    const campaignStatus = useSandreList(SANDRE.CAMPAGNES_STATUT)

    useTitle(() => {
        const udi = distributionUnits.find(du => du.id === diagnostic.idUdi)
        const sector = sectors.find(s => s.id === diagnostic.idSector)
        return [{
            title: i18n.pgsse,
            href: PATH_PGSSE,
        }, {
            title: i18n.phase1,
            href: `${PATH_PGSSE}/phase1`,
        }, {
            title: getUdiSectorName(udi, sector),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}`,
        }]
    }, [diagnostic])

    useActions(() => {
        return readMode ? {
            edit: () => setReadMode(false),
        } : {
            cancel: () => {
                setDiagnostic(propsDiagnostic)
                setReadMode(true)
            },
            save: () => {
                dispatch(PgsseAction.updateDiagnostic(idDiagnostic, diagnostic)).then(() => setReadMode(true))
            },
        }
    }, [readMode, diagnostic, idDiagnostic])

    const campaignActions = compact([{
        icon: 'download',
        tooltip: i18n.export,
        onClick: () => {
            dispatch(ToastrAction.error(i18n.inDeveloppmentFunctionnality))
        },
    }, !readMode && {
        icon: 'note_add',
        tooltip: i18n.addCampaign,
        onClick: () => {
            dispatch(PgsseAction.createCampaign(idDiagnostic)).then(json => {
                dispatch(push(`${PATH_PGSSE}/phase1/${idDiagnostic}/campaign/${json.id}`))
            })
        },
    }])

    const onChangeDiagnostic = values => setDiagnostic(prev => ({ ...prev, ...values }))

    return (
        <Grid2 container spacing={1} sx={{ padding: '10 10 10 20' }}>
            <Grid2 size={9}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={1}>
                            <Grid2 container size={6}>
                                <Grid2 size={12}>
                                    <Select
                                        options={surveys}
                                        label={i18n.pgsse}
                                        value={propsDiagnostic.idSurvey}
                                        readMode
                                        keyLabel='description'
                                        keyValue='idSurvey'
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={contributors}
                                        label={i18n.collectivity}
                                        value={propsDiagnostic.idContributor}
                                        readMode
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={sectors}
                                        label={i18n.sector}
                                        value={propsDiagnostic.idSector}
                                        readMode
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={distributionUnits}
                                        label={i18n.distributionUnitName}
                                        value={propsDiagnostic.idUdi}
                                        keyValue='id'
                                        readMode
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container size={6} direction='column' justifyContent='flex-start'>
                                <Grid2 size={12}>
                                    <Select
                                        options={campaignStatus}
                                        label={i18n.state}
                                        value={diagnostic.status}
                                        onChange={v => onChangeDiagnostic({ status: v })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <SimpleTextArea
                                        title={i18n.comment}
                                        value={diagnostic.comment}
                                        onChange={v => onChangeDiagnostic({ comment: v })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={3}>
                <LastUpdateCard element={diagnostic} />
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.campaigns} actions={campaignActions} />
                    <CardContent sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                        <CampaignTabs
                            campaigns={diagnostic.link_campaigns}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        />
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <FormContributorPanel
                    idDiagnostic={parseInt(idDiagnostic)}
                    data={diagnostic.link_forms}
                    readMode={readMode}
                    onChange={onChangeDiagnostic}
                />
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.documents} />
                    <CardContent>
                        <DevelopmentApp />
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.activities} />
                    <CardContent>
                        <DevelopmentApp />
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    )
}

PgsseDiagnosticApp.PropTypes = {
}

export default PgsseDiagnosticApp