import fetch from 'isomorphic-fetch'
import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten, groupBy, keys, uniqBy } from 'lodash'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import LogAction from '../../log/actions/LogAction'
import QualityAction from '../../quality/actions/QualityAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import StationAction from '../../station/actions/StationAction'
import AppStore from '../../store/AppStore'
import {
    checkAuth,
    checkError,
    genericPromise,
    genericPromise2,
    getAbortSignal,
    getAuthorization,
    getJson,
    promiseAllProgress,
    promiseAllWithCancel,
} from '../../utils/ActionUtils'
import DtoHydroMeasureLight from '../dto/chronicMeasures/DtoHydroMeasureLight'
import DtoHydroMeasures from '../dto/chronicMeasures/DtoHydroMeasures'
import CampaignAction from 'campaign/actions/CampaignAction'
import DtoHydroMeasureValidation from '../dto/chronicMeasures/DtoHydroMeasureValidation'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { userActions } from 'administration/components/user/reducers/UserReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import { RECEIVE_HYDROMETRY_THRESHOLDS } from 'hydrometry/constants/HydrometryConstants'
import DtoHydroStats from 'hydrometry/dto/chronicMeasures/DtoHydroStats'


const HydrometryAction = {
    createHydrometer(newHydrometer, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.hydrometricStationPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newHydrometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(HydrometryActionConstant.createHydrometer({ ...newHydrometer, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.hydrometricStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.hydrometricStation))
                })
        }
    },
    updateHydrometer: hydrometer => dispatch => {
        return fetch(ApplicationConf.hydrometricStation.hydrometricStationPath(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(hydrometer),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(HydrometryAction.fetchHydrometricStation(hydrometer.id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.hydrometricStation} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.hydrometricStation))
            })
    },
    updateMeasures: (list, callback = () => {}) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return genericPromise2(ApplicationConf.hydrometricStation.chronicMeasures(), { method: 'PUT', body: list }).then(result => {
                dispatch(WaitAction.waitStop())
                if (result?.update || result?.insert) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    callback()
                } else {
                    dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.measures}`))
                }
                return result
            }).catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.errorChangeData} ${i18n.measures} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.errorChangeData} ${i18n.measures}`))
            })
        }
    },
    deleteHydrometer: (id, callback) => {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.hydrometricStationPath(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(HydrometryActionConstant.deleteHydrometer(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.hydrometricStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.hydrometricStation))
                })
        }
    },
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().HydrometryReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    fetchHydrometricStation(stationId) {
        if (stationId) {
            return (dispatch) => {
                dispatch(WaitAction.waitStart())
                return fetch(ApplicationConf.hydrometricStation.get(stationId), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(getJson)
                    .then(e => checkError(e, {
                        404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
                    }))
                    .then((json = []) => {
                        dispatch(WaitAction.waitStop())
                        dispatch(HydrometryActionConstant.receiveHydrometricStation(json))
                        // dispatch(StationAction.fetchHydrometryCentralAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometryPowerSupplyAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometrySensorAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometrySimAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometryTelecomAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometryVariousMaterielAssignment(json.id))
                        // dispatch(StationAction.fetchHydrometryEquipmentAssignment(json.id))
                    })
                    .catch((err) => {
                        dispatch(WaitAction.waitStop())
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrometricStation} : ${err}`))
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrometricStation))
                    })
            }
        }
        return (dispatch) => {
            dispatch(HydrometryActionConstant.receiveHydrometricStation({}))
        }
    },

    promiseHydrometricStations() {
        return fetch(ApplicationConf.hydrometricStation.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrometricStations() {
        return (dispatch) => {
            return HydrometryAction.promiseHydrometricStations()
                .then((json = []) => {
                    dispatch(UserAction.fetchBookmarks())
                    dispatch(HydrometryActionConstant.receiveAllHydrometricStations(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrometricStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrometricStations))
                })
        }
    },
    fetchHydrometricMeasuresBrut: (stationId, dataType) => () => {
        return HydrometryAction.loadHydroChronicMeasures([{
            stationId,
            dataType,
            chartMode: true,
        }])
    },

    receiveHydrometricThresholds: thresholds => ({
        type: RECEIVE_HYDROMETRY_THRESHOLDS,
        thresholds,
    }),

    promiseHydrometricThresholds: () => {
        return fetch(ApplicationConf.hydrometricStation.hydrometricThresholds(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    promiseHydroThresholds: (id) => genericPromise2(ApplicationConf.hydrometricStation.hydrometricThreshold(id)),
    fetchHydrometricThresholds: () => dispatch => {
        return HydrometryAction.promiseHydrometricThresholds()
            .then((json = []) => {
                dispatch(HydrometryActionConstant.receiveHydrometryThresholds(json))
            })
    },

    updateHydrometerThreshold(idHydrometer, thresholds) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.hydrometricThreshold(idHydrometer), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(thresholds),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(HydrometryAction.fetchHydrometricThresholds())
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.hydrometricStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.hydrometricStation))
                })
        }
    },

    fetchKeyFigures() {
        return (dispatch) => {
            return fetch(ApplicationConf.keyfigures.hydrometry(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydrometricStationKeyFigures(json))
                })
        }
    },

    promiseHydrometerMeasures: (id, startDate = undefined, endDate = Date.now()) => {
        return fetch(
            ApplicationConf.hydrometricStation.hydrometricStationFlow(id, startDate, endDate),
            {
                method: 'GET',
                headers: getAuthorization(),
            },
        )
            .then(checkAuth)
            .then(getJson)
    },

    promiseHydrometryBruteMeasures: (id, dates = {}) => {
        return fetch(ApplicationConf.hydrometricStation.hydrometricBruteMeasures(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ id, ...dates }),
        })
            .then(checkAuth).then(getJson).then(checkError).catch(err => {
                AppStore.dispatch(ToastrAction.error(i18n.dataError))
                AppStore.dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
                return []
            })
    },

    promiseHydrometryDataTypes() {
        return genericPromise2(ApplicationConf.hydrometricStation.dataTypes())
    },
    fetchHydrometryDataTypes() {
        return (dispatch) => {
            return HydrometryAction.promiseHydrometryDataTypes()
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydrometryDataTypes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    resetHydrometryObservations: () => dispatch => dispatch(HydrometryActionConstant.receiveHydrometryObservations([])),

    promiseHydrometryObservations(id) {
        return genericPromise2(ApplicationConf.hydrometricStation.getObservationsById(id))
    },
    fetchHydrometryObservations(id) {
        return (dispatch) => {
            return HydrometryAction.promiseHydrometryObservations(id)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydrometryObservations(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    createHydrometerObservation(newObservation) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.observation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newObservation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.observation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.observation))
                })
        }
    },

    updateHydrometerObservation(observation) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.observation(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(observation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(HydrometryAction.fetchHydrometryObservations(observation.siteCode))
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.observation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.observation))
                })
        }
    },

    deleteHydrometerObservation(observation) {
        return (dispatch) => {
            return fetch(ApplicationConf.hydrometricStation.observation(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(observation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(res => {
                    if (res.delete >= 1) {
                        dispatch(HydrometryAction.fetchHydrometryObservations(observation.siteCode))
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error('')
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.observation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.observation))
                })
        }
    },

    promiseHydroStatistics(id) {
        return genericPromise2(ApplicationConf.hydrometricStation.statistics(), { method: 'POST', body: { id } })
    },
    fetchHydroStatistics(id) {
        return (dispatch) => {
            return HydrometryAction.promiseHydroStatistics(id)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydroStatistics(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchSeveralHydroStatistics(ids, progressCallback = () => {}) {
        return (dispatch) => {
            const promises = ids.map(id => HydrometryAction.promiseHydroStatistics(id))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                return uniqBy(flatten(jsonResults), 'typeId').map(h => new DtoHydroStats(h))
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    promiseTideGaugeDatas(criterias) {
        return genericPromise2(ApplicationConf.hydrometricStation.tideGaugeDatas(), { method: 'POST', body: criterias })
    },
    fetchTideGaugeDatas(criterias) {
        return (dispatch) => {
            return HydrometryAction.promiseTideGaugeDatas(criterias)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveTideGaugeDatas(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.tideGauges} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.tideGauges))
                })
        }
    },

    promiseHydroChronicMeasures(input) {
        return genericPromise2(ApplicationConf.hydrometricStation.chronicMeasures(), { method: 'POST', body: input })
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return []
            })
    },

    fetchHydroChronicMeasures: (id) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return HydrometryAction.promiseHydroChronicMeasures(id)
                .then(json=> {
                    dispatch(WaitAction.waitStop())
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },


    promiseHydroChronicMeasuresLight(input) {
        return genericPromise2(ApplicationConf.hydrometricStation.chronicMeasures(), { method: 'POST', body: input })
            .then(json => json.map(j => new DtoHydroMeasureLight(j)))
            .catch((err) => {
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
            })
    },
    loadHydroChronicMeasures(
        listOfInputs, // liste d'objets correspondants à HydroMeasuresInput dans le service hydro
        progressCallback = () => { }) {
        return (dispatch) => {
            const promises = listOfInputs.map(input => HydrometryAction.promiseHydroChronicMeasures(input))
            return promiseAllProgress(promises, progressCallback).then(jsonResults => {
                const data = jsonResults.map((json, idx) => {
                    return new DtoHydroMeasures({ ...listOfInputs[idx], measures: listOfInputs[idx].chartMode ? json.map(j => new DtoHydroMeasureLight(j)) : json.map(j => new DtoHydroMeasureValidation(j)) })
                })
                dispatch(HydrometryActionConstant.receiveHydroChronicMeasures(data))
                return data
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    getHydroChronicMeasures: dataInput => dispatch => HydrometryAction.promiseHydroChronicMeasures(dataInput)
        .then(json => {
            return new DtoHydroMeasures({ ...dataInput, measures: json.map(j => new DtoHydroMeasureLight(j)) })
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
            dispatch(ToastrAction.error(i18n.measures))
        }),

    getHydrosChronicMeasures(
        listOfInputs, // liste d'objets correspondants à HydroMeasuresInput dans le service hydro
    ) {
        const promises = listOfInputs.map(input => HydrometryAction.promiseHydroChronicMeasures(input))
        return Promise.all(promises).then(jsonResults => {
            return jsonResults.map((json, idx) => {
                return new DtoHydroMeasures({ ...listOfInputs[idx], measures: listOfInputs[idx].chartMode ? json.map(j => new DtoHydroMeasureLight(j)) : json.map(j => new DtoHydroMeasureValidation(j)) })
            })
        })
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.measures))
            })
    },

    promiseHydroChronicsMeasures(input) {
        return genericPromise2(ApplicationConf.hydrometricStation.chronicsMeasures(), { method: 'POST', body: input })
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                return []
            })
    },

    // promiseHydroChronicsMeasuresSequentially(criterias, stations, dispatch) {
    //     return genericPromise(ApplicationConf.hydrometricStation.chronicsMeasures(), 'POST', { ...criterias, stationIds: stations })
    //         .catch((err) => {
    //             dispatch(WaitAction.waitStop())
    //             dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
    //             dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
    //         })
    // },

    // loadHydroChronicsMeasuresSequentially(criterias, idHydros, progressCallback = () => { }, cb = () => { }) {
    //     return (dispatch) => {
    //         const groupsSize = idHydros.length < 1000 ? 10 : idHydros.length / 100
    //         const [promises, params] = zip(...chunk(idHydros, groupsSize).map(stationsGroup => [HydrometryAction.promiseHydroChronicsMeasuresSequentially, [criterias, stationsGroup, dispatch]]))
    //         return callPromisesSequentially(promises, params, progressCallback, arrayResults => {
    //             const data = arrayResults.flatMap(dataArray => {
    //                 const measures = criterias.chartMode ? dataArray.map(j => new DtoHydroMeasureLight(j)) : dataArray.map(j => new DtoHydroMeasureValidation(j))
    //                 const groupedMeasures = groupBy(measures, 'hydroId')
    //                 return keys(groupedMeasures).map(stationId => new DtoHydroMeasures({
    //                     ...criterias,
    //                     stationId,
    //                     measures: groupedMeasures[stationId],
    //                 }))
    //             })
    //             if (data.some(m => !m.measures.length)) {
    //                 dispatch(ToastrAction.warning(i18n.noSelectedStationDataOverThisPeriod))
    //             }
    //             dispatch(HydrometryActionConstant.receiveHydroChronicMeasures(data))
    //             cb()
    //         })
    //     }
    // },

    loadHydroChronicsMeasures(criterias, hydroIds, progressCallback = () => { }) {
        return (dispatch) => {
            const promises = chunk(hydroIds, hydroIds.length > 10 ? 10 : 1).map(stationIds => HydrometryAction.promiseHydroChronicsMeasures({ ...criterias, stationIds }))
            return promiseAllProgress(promises, progressCallback).then(arrayResults => {
                const data = arrayResults.flatMap(dataArray => {
                    const measures = criterias.chartMode ? dataArray.map(j => new DtoHydroMeasureLight(j)) : dataArray.map(j => new DtoHydroMeasureValidation(j))
                    const groupedMeasures = groupBy(measures, 'hydroId')
                    return keys(groupedMeasures).map(stationId => new DtoHydroMeasures({
                        ...criterias,
                        stationId,
                        measures: groupedMeasures[stationId],
                    }))
                })
                if (data.some(m => !m.measures.length)) {
                    dispatch(ToastrAction.warning(i18n.noSelectedStationDataOverThisPeriod))
                }
                dispatch(HydrometryActionConstant.receiveHydroChronicMeasures(data))
                return data
            })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.measures))
                })
        }
    },

    loadHydrometersTable: (progressCallback = () => { }, cb = () => {}) => dispatch => promiseAllProgress([
        HydrometryAction.promiseHydrometricStations(),
        CampaignAction.promiseCampaigns('hydrometry'),
        CampaignAction.promiseCampaignsProgress('hydrometry'),
    ], progressCallback).then(jsonTab => {
        dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[0]))
        dispatch(HydrometryActionConstant.receiveHydrometryCampaigns(jsonTab[1]))
        dispatch(HydrometryActionConstant.receiveHydrometryCampaignsProgress(jsonTab[2]))
        cb()
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    promiseHydrometrySituation(criterias, stations, type) {
        return fetch(ApplicationConf.hydrometricStation.situation(type), {
            method: 'POST',
            headers: getAuthorization(),
            signal: getAbortSignal(),
            body: JSON.stringify({ ...criterias, hydroIds: stations }),
        }).then(checkAuth).then(getJson).then(checkError).catch(err => {
            if (err.name !== 'AbortError') {
                AppStore.dispatch(ToastrAction.error(i18n.dataError))
                AppStore.dispatch(LogAction.logError(`${i18n.dataError}: ${err}`))
            }
            return []
        })
    },
    fetchHydrometryTypeSituationWithDays(criterias, hydroIds, progressCallback = () => { }) {
        return (dispatch) => {
            const groupsSize = hydroIds.length / 50 > 20 ? hydroIds.length / 20 : 50
            const promises = chunk(hydroIds, groupsSize).map(stationsGroup => HydrometryAction.promiseHydrometrySituation(criterias, stationsGroup, 'type'))
            const allPromises = [...promises, HydrometryAction.promiseHydrometrySituation(criterias, hydroIds, 'days')]
            const count = allPromises.length
            return promiseAllWithCancel(allPromises, progressCallback, dispatch)
                .then(json => {
                    if (json.length === count) {
                        const days = json.pop()
                        dispatch(HydrometryActionConstant.receiveHydroSituations(flatten(json)))
                        dispatch(HydrometryActionConstant.receiveHydroSituationsDays(flatten(days)))
                    } else {
                        dispatch(HydrometryActionConstant.receiveHydroSituations([]))
                    }
                })
        }
    },

    loadHydroSituation: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        HydrometryAction.promiseHydrometricStations(),
        StationAction.promiseFilters(),
        UserAction.promiseBookmarks(),
        ContributorAction.promiseContributors(),
        ContactAction.promiseContacts(),
        CityAction.promiseCities(),
        ReferencialAction.promiseSandreCodes(),
        HydrometryAction.promiseHydrologicalContributors(),
    ], progressCallback).then(jsonTab => {
        dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[0]))
        dispatch(StationAction.receiveFilters(jsonTab[1]))
        dispatch(userActions.receiveAllBookmarks(jsonTab[2]))
        dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[3]))
        dispatch(ContactActionConstant.receiveAllContacts(jsonTab[4]))
        dispatch(CityAction.receiveCities(jsonTab[5]))
        dispatch(ReferencialAction.receiveSandreCodes(jsonTab[6]))
        dispatch(HydrometryActionConstant.receiveAllHydrologicalContributors(jsonTab[7]))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    promiseHydroLastMeasures(ids, dataType = 4) {
        return genericPromise2(ApplicationConf.hydrometricStation.lastMeasures(), { method: 'POST', body: { ids, dataType } })
    },
    fetchHydroLastMeasures(ids) {
        return (dispatch) => {
            return HydrometryAction.promiseHydroLastMeasures(ids)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydroLastMeasures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseHydroSituationLastMeasures(ids, date) {
        return genericPromise2(ApplicationConf.hydrometricStation.situationLastMeasures(), { method: 'POST', body: { ids, date } })
    },
    fetchHydroSituationLastMeasures(ids) {
        return (dispatch) => {
            return HydrometryAction.promiseHydroSituationLastMeasures(ids)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydroSituationLastMeasures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    loadHydrometryFollowUp(stationId, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return Promise.all([
                HydrometryAction.promiseHydrometryBruteMeasures(parseInt(stationId)),
                QualityAction.promiseStatus(),
                QualityAction.promiseQualifications(),
            ]).then(jsonTab => {
                dispatch(HydrometryActionConstant.receiveHydrometryBruteMeasures(jsonTab[0]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[1]))
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[2]))
                dispatch(WaitAction.waitStop())
                callback()
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },

    promiseHydrologicalContributors() {
        return fetch(ApplicationConf.hydrometricStation.getAllContributorLinks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrologicalContributors() {
        return dispatch => {
            return HydrometryAction.promiseHydrologicalContributors()
                .then((json = []) => {
                    dispatch(HydrometryActionConstant.receiveAllHydrologicalContributors(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                })
        }
    },

    promiseHydroStats: (id) => {
        return fetch(ApplicationConf.hydrometricStation.getStat(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ id }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    promiseHydroBookmarksStats: (id) => {
        return fetch(ApplicationConf.hydrometricStation.getBookmarksStat(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ id }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchHydroBookmarksStats: (ids) => {
        return (dispatch) => {
            const promises = ids.map(id =>
                HydrometryAction.promiseHydroBookmarksStats(id).then(json => json.map(stat => ({ ...stat, hydroId: id }))),
            )
            return Promise.all(promises)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydroBookmarksStats(flatten(json)))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError}${i18n.statsHydro} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.statsHydro))
                })
        }
    },

    fetchHydroStats: (ids) => {
        return (dispatch) => {
            const promises = ids.map(id =>
                HydrometryAction.promiseHydroStats(id).then(json => json.map(stat => ({ ...stat, hydroId: id }))),
            )
            return Promise.all(promises)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydroStats(flatten(json)))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError}${i18n.statsHydro} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.statsHydro))
                })
        }
    },

    purgeHydroMeasures(id, dates, dataType, codepoint, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.purgeHydroMeasures(id), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ ...dates, dataType, codepoint }),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(json.delete.toString() + i18n.nMeasuresDeleted))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },
    deleteHydroMeasures(id, dataToReset, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.deleteHydroMeasures(id), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(dataToReset),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(json.delete.toString() + i18n.nMeasuresDeleted))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },
    resetHydroMeasures(id, dates, dataType, codepoint, callback = () => {}) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.resetHydroMeasures(id), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ ...dates, dataType, codepoint }),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.insert > 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(json.insert.toString() + i18n.measuresUpdated))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.measures))
                })
        }
    },

    fetchCountObservations() {
        return (dispatch) => {
            return fetch(ApplicationConf.keyfigures.getCountObservationsHydro(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(HydrometryActionConstant.receiveHydrometricCountObservations(json))
                })
        }
    },

    promiseHydrometryNetworkLinks: () => genericPromise(ApplicationConf.hydrometricStation.networkLinks()),
    fetchHydrometryNetworkLinks: () => dispatch => HydrometryAction.promiseHydrometryNetworkLinks()
        .then((json = []) => {
            dispatch(HydrometryActionConstant.receiveHydrometryNetworkLinks(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.networks} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.networks))
        }),

    // insertHydrometryNetworkLink: (id, networkLinks) => () => fetch(ApplicationConf.hydrometricStation.networkLink(id), {
    //     method: 'POST',
    //     headers: getAuthorization(),
    //     body: JSON.stringify(networkLinks),
    // })
    //     .then(checkAuth)
    //     .then(getJson)
    //     .then(checkError),

}

export default HydrometryAction
