import React, { useEffect } from 'react'
import ImageCard from '../../../../components/card/ImageCard'
import { MODEL_OBJECTIVES, MODEL_STATUS, MODEL_TYPES } from '../../../../iaeau/constants/IAEauConstants'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import { Grid2, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { compact } from 'lodash'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'

const PREDEFINED_MODEL = -1
const DEFAULT_HORIZON = 10
const DEFAULT_TARRI_PARAMS = { sensibility: 3 }
const DEFAULT_AUGURE_PARAMS = { lineToTake: 'area', nbYearsToTake: 5, average: 7 }
const DEFAULT_ARIMA_PARAMS = {
    p: 0,
    q: 0,
    d: 0,
    P: 0,
    Q: 0,
    D: 0,
    learningMode: false,
    validOnly: false,
    area50: true,
    area90: true,
}
const DEFAULT_SCIKIT_PARAMS = {
    area50: true,
    area70: true,
    area90: true,
}
const DEFAULT_METEO_NAPPE_PARAMS = { dateMode: 'glissant' }

const ModelStepModel = ({
    selectedModel,
    changeModel = () => [],
    changeParams = () => {},
    stationType,
}) => {
    const statModels = compact([{
        selectedModel: 1,
        title: i18n.arima,
        description: i18n.modelArimaDescription,
        img: 'pictures/iaeauModels/arima.png',
        constant: MODEL_TYPES.ARIMA,
        horizon: DEFAULT_HORIZON,
    }, {
        selectedModel: 2,
        title: i18n.omenTrends,
        description: i18n.modelOmenTrendsDescription,
        img: 'pictures/iaeauModels/augures.png',
        constant: MODEL_TYPES.AUGURES,
        horizon: DEFAULT_HORIZON,
    }, (selectedModel.objective === MODEL_OBJECTIVES.ETIAGE) && {
        selectedModel: 3,
        title: i18n.dryingUp,
        description: i18n.modelDryingUpDescription,
        img: 'pictures/iaeauModels/etiage.jpg',
        constant: MODEL_TYPES.TARRI_BRGM,
        horizon: DEFAULT_HORIZON,
    }, stationType === STATION_TYPE_NAME.piezometry && {
        selectedModel: 4,
        title: i18n.weatherNappes,
        description: i18n.modelWeatherNappesDescription,
        img: 'pictures/iaeauModels/meteonappes.png',
        constant: MODEL_TYPES.AUGURES,
        horizon: DEFAULT_HORIZON,
        disabled: !selectedModel.meteoNappesExists,
    }, stationType === STATION_TYPE_NAME.hydrometry && {
        selectedModel: 8,
        title: i18n.cydre,
        description: i18n.modelCydreDescription,
        img: 'pictures/iaeauModels/cydre.png',
        constant: MODEL_TYPES.CYDRE,
        horizon: DEFAULT_HORIZON,
        disabled: !selectedModel.cydreExists,
    }])

    const predefinedModels = [{
        selectedModel: 5,
        title: i18n.genericIAModel3J,
        description: i18n.modelGenericAIDescription,
        img: 'pictures/iaeauModels/genericModel.png',
        constant: MODEL_TYPES.SCIKIT_LEARN_GENERIC,
        idScenario: PREDEFINED_MODEL,
        horizon: 3,
    }, {
        selectedModel: 6,
        title: i18n.genericIAModel14J,
        description: i18n.modelGenericAIDescription,
        img: 'pictures/iaeauModels/genericModel.png',
        constant: MODEL_TYPES.SCIKIT_LEARN_GENERIC,
        idScenario: PREDEFINED_MODEL,
        horizon: 14,
    }, {
        selectedModel: 7,
        title: i18n.customisableAIModel,
        description: i18n.modelCustomisableAIDescription,
        img: 'pictures/iaeauModels/genericModel.png',
        constant: MODEL_TYPES.PERCEPTRON,
        horizon: DEFAULT_HORIZON,
        disabled: true,
    }]

    useEffect(() => {
        if (selectedModel.typeModel === MODEL_TYPES.METEO_NAPPES) {
            changeParams({ ...DEFAULT_METEO_NAPPE_PARAMS, nbDaysCalculation: selectedModel.horizon })
        }
        if (selectedModel.typeModel === MODEL_TYPES.ARIMA) {
            changeParams({
                ...DEFAULT_ARIMA_PARAMS,
                measureGroupMode: selectedModel.objective === MODEL_OBJECTIVES.CRUE ? 'MAX': (
                    selectedModel.objective === MODEL_OBJECTIVES.ETIAGE ? 'MIN' : 'AVERAGE'
                ),
            })
        }
        if (selectedModel.typeModel === MODEL_TYPES.AUGURES) {
            changeParams(DEFAULT_AUGURE_PARAMS)
        }
        if (selectedModel.typeModel === MODEL_TYPES.TARRI_BRGM) {
            changeParams(DEFAULT_TARRI_PARAMS)
        }
        if (selectedModel.typeModel === MODEL_TYPES.SCIKIT_LEARN_GENERIC) {
            changeParams(DEFAULT_SCIKIT_PARAMS)
        }
    }, [selectedModel.selectedModel])

    const theme = useTheme()

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('xl'))

    return (
        <Grid2 container size={12} rowSpacing={1}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
                <h5>{i18n.chooseYourModel}</h5>
            </Grid2>
            <Grid2 size={12} sx={{ paddingLeft: '8px' }}>
                <Typography variant='h5' component='p'>
                    {i18n.statisticalModels}
                </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ overflow: 'auto hidden', padding: '8px' }}>
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='flex-start'
                    sx={{
                        width: 'max-content',
                    }}
                >
                    {statModels.map(m => (
                        <ImageCard
                            key={`Model_${m.title}`}
                            img={m.img}
                            alt={`Image du model ${m.title}`}
                            title={m.title}
                            description={m.description}
                            maxWidth={isMediumScreen ? '25vw' : '18vw'}
                            imgHeight={140}
                            active={selectedModel.selectedModel === m.selectedModel}
                            onClick={() => changeModel({
                                typeModel: m.constant,
                                horizon: m.horizon,
                                idScenario: m.idScenario,
                                statusCode: MODEL_STATUS.DEMAND_SENT,
                                selectedModel: m.selectedModel,
                            })}
                            disabled={m.disabled}
                        />
                    ))}
                </Stack>
            </Grid2>
            <Grid2 size={12} sx={{ padding: '8px 0 0 8px' }}>
                <Typography variant='h5' component='p'>
                    {i18n.aiModels}
                </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ overflow: 'auto hidden', padding: '8px' }}>
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='flex-start'
                    sx={{
                        width: 'max-content',
                    }}
                >
                    {predefinedModels.map(m => (
                        <ImageCard
                            key={`Model_${m.title}`}
                            img={m.img}
                            alt={`Image du model ${m.title}`}
                            title={m.title}
                            description={m.description}
                            maxWidth={isMediumScreen ? '25vw' : '18vw'}
                            imgHeight={140}
                            active={selectedModel.selectedModel === m.selectedModel}
                            onClick={() => changeModel({
                                typeModel: m.constant,
                                horizon: m.horizon,
                                idScenario: m.idScenario,
                                statusCode: MODEL_STATUS.DEMAND_SENT,
                                selectedModel: m.selectedModel,
                            })}
                            disabled={m.disabled}
                        />
                    ))}
                </Stack>
            </Grid2>
        </Grid2>
    )
}

ModelStepModel.propTypes = {
    selectedModel: PropTypes.instanceOf(DtoIAEauModel),
    changeModel: PropTypes.func,
    changeParams: PropTypes.func,
    stationType: PropTypes.string,
}

export default ModelStepModel