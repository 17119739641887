/* eslint-disable camelcase */

import DtoPgsseFormWithStats from './DtoPgsseFormWithStats'


export default class DtoPgsseCampaign {
    constructor(obj) {
        this.idCampaign = obj.idCampaign // Option[Long] = None,
        this.idDiag = obj.idDiag // Option[Long] = None,
        this.name = obj.name // Option[String] = None,
        this.comment = obj.comment // Option[String] = None,
        this.startDate = obj.startDate // Option[DateTime] = None,
        this.endDate = obj.endDate // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.status = obj.status // Option[String] = None,
        this.stationType = obj.stationType // Option[String] = None,

        this.link_stations = obj.link_stations ? obj.link_stations.map(d => new DtoPgsseFormWithStats(d)) : [] // Option[Seq[CampaignStationWithStats]]
    }
}
