import React from 'react'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'

const StatisticalIndicatorsLegend = () => {
    return (
        <Grid2 container>
            <Grid2 size={12}>
                <span className='red arrests-level-panel red-text'>O</span> {i18n.monthlyMin}
            </Grid2>
            <Grid2 size={12}>
                <span className='blue arrests-level-panel blue-text'>O</span> {i18n.monthlyMax}
            </Grid2>
            <Grid2 size={12}>
                <span className='orange arrests-level-panel orange-text'>O</span> {i18n.statistics}
            </Grid2>
        </Grid2>
    )
}

export default StatisticalIndicatorsLegend;
