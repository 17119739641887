import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'

const ImportQualityDefaultValue = ({
    defaultValue = {},
    isEditMode = false,
    hydrobio = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        contributors,
        qualifications,
        status,
        fractions,
        supports,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    useEffect(() => {
        if (contributors.length === 0) {
            dispatch(ContributorAction.fetchContributors())
        }
        if (qualifications.length === 0) {
            dispatch(QualityAction.fetchQualifications())
        }
        if (status.length === 0) {
            dispatch(QualityAction.fetchStatus())
        }
        if (fractions.length === 0) {
            dispatch(FractionAction.fetchFractions())
        }
        if (supports.length === 0) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [dispatch])

    return (
        <fieldset>
            <legend>{i18n.defaultValue}</legend>
            <div>
                <div className='row no-margin padding-top-1'>
                    {/*      <Input
                    col={3}
                    title={i18n.stationCodePrefix}
                    value={defaultValue.stationCodePrefix}
                    onChange={(id) => onChange({ stationCodePrefix: id })}
                    disabled={!isEditMode}
                /> */}
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.sampler}
                        col={3}
                        onChange={(id) => onChange({ sampler: id })}
                        values={defaultValue.sampler}
                        disabled={!isEditMode}
                        data-cy='defaultImportSampler'
                    />
                    <MultiContributorsAutocomplete
                        options={contributors}
                        label={i18n.producer}
                        col={3}
                        onChange={(id) => onChange({ producer: id }) }
                        values={defaultValue.producer}
                        disabled={!isEditMode}
                        data-cy='defaultImportProducer'
                    />
                    {!hydrobio && (
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.laboratory}
                            col={3}
                            onChange={(id) => onChange({ labo: id }) }
                            values={defaultValue.labo}
                            disabled={!isEditMode}
                            data-cy='defaultImportLaboratory'
                        />
                    )}
                    {hydrobio && (
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.determiner}
                            col={3}
                            onChange={(id) => onChange({ determiner: id }) }
                            values={defaultValue.determiner}
                            disabled={!isEditMode}
                            data-cy='defaultImportDeterminer'
                        />
                    )}
                </div>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={3}
                        label={i18n.support}
                        options={supports}
                        value={defaultValue.support}
                        onChange={(code) => onChange({ support: parseInt(code) || undefined })}
                        clearFunction
                        displayWithCode
                        active={isEditMode}
                        disabled={!isEditMode}
                        data-cy='defaultImportSupport'
                    />
                    {!hydrobio && (
                        <Select
                            col={3}
                            label={i18n.fractions}
                            options={fractions}
                            value={defaultValue.fraction}
                            onChange={(code) => onChange({ fraction: code })}
                            clearFunction
                            displayWithCode
                            active={isEditMode}
                            disabled={!isEditMode}
                            data-cy='defaultImportFraction'
                        />
                    )}
                    <Select
                        col={3}
                        label={i18n.status}
                        options={status}
                        value={defaultValue.status}
                        onChange={(v) => onChange({ status: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                        data-cy='defaultImportStatus'
                    />
                    <Select
                        col={3}
                        label={i18n.qualification}
                        options={qualifications}
                        value={defaultValue.qualification}
                        onChange={(v) => onChange({ qualification: v })}
                        nullLabel='&nbsp;'
                        active={isEditMode}
                        disabled={!isEditMode}
                        data-cy='defaultImportQualification'
                    />
                </div>
            </div>
        </fieldset>
    )
}

ImportQualityDefaultValue.propTypes = {
    defaultValue: PropTypes.shape({
        sampler: PropTypes.number,
        producer: PropTypes.number,
        labo: PropTypes.number,
        determiner: PropTypes.number,
        support: PropTypes.number,
        fraction: PropTypes.number,
        status: PropTypes.number,
        qualification: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    hydrobio: PropTypes.bool,
}

export default ImportQualityDefaultValue