export default class DtoIndice {
    constructor(obj) {
        this.id = obj.id
        this.qualitometerId = obj.qualitometerId
        this.operationId = obj.operationId
        this.indice = obj.indice
        this.parameter = obj.parameter
        this.unit = obj.unit
        this.result = obj.result
        this.accreditation = obj.accreditation
        this.remarkCode = obj.remarkCode
        this.approvalCode = obj.approvalCode
        this.comment = obj.comment
        this.calculated = obj.calcule
        this.jobexecutionid = obj.jobexecutionid
    }
}