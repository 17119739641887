import AccountAction from 'account/actions/AccountAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { groupBy, isNil, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import ParameterAction from '../../../referencial/components/parameter/actions/ParameterAction'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'
import { SELECTION } from 'quality/constants/QualityConstants'

const DEFAULT_VALUE = '-1'

const getLabel = type => {
    switch (type) {
        case SELECTION.PC: return i18n.parameters
        case SELECTION.TAXON: return i18n.taxons
        case SELECTION.INDICE: return i18n.indices
        case SELECTION.ENVIRONMENTAL_PARAMETER: return i18n.environmentalParameters
        case SELECTION.STATE: return i18n.states
        default: return ''
    }
}

const SelectionSelect = ({
    col,
    value,
    type = SELECTION.PC,
    disabled = false,
    label = '',
    onChange = () => {},
}) => {
    const SELECTION_FILTERS = `SELECTION_FILTERS_${type}`
    const {
        applicationSettings,
        accountUserSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
        accountUserSettings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const [selections, setSelections] = useState([])
    const [selection, setSelection] = useState()
    const [selectionResults, setSelectionResults] = useState([])

    useEffect(() => setSelection(value), [value])

    const dispatch = useDispatch()

    useEffect(() => {
        ParameterAction.promiseSelections(type).then(setSelections)
    }, [type])

    useEffect(() => {
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchApplicationSettings())
        }
        if (!accountUserSettings.length) {
            dispatch(AccountAction.fetchAccountUserSettings())
        }
    }, [])

    useUpdateEffect(() => {
        if (selection && selections.some(({ code }) => code == selection)) {
            dispatch(ParameterAction.fetchSelectionParameter(selection)).then((res = []) => {
                setSelectionResults(res.map(s => s.parameterCode))
            })
        }
    }, [selection, selections])

    const updateSettings = (parameter, newValue) => {
        dispatch(AdministrationAction.updateSetting(parameter, newValue))
    }

    useUpdateEffect(() => {
        if (selection) {
            onChange(selectionResults, selection)
        }
    }, [selectionResults])

    const formatSelections = useCallback((selectionFiltered = [], groupedSelections = {}) => {
        return selectionFiltered.map(({ code, name }) => {
            const childrens = formatSelections(groupedSelections[code], groupedSelections)
            return {
                id: code,
                code,
                name,
                elements: childrens.length > 0 ? childrens : undefined,
            }
        })
    }, [])

    const orderedSelections = useMemo(() => orderBy(selections, 'name', 'asc'), [selections])
    const groupedSelections = groupBy(orderedSelections, s => s.parentCode ?? 'headTree')
    const formattedGroupedSelections = formatSelections(groupedSelections.headTree, groupedSelections)

    return (
        <SuperMultiAutocomplete
            col={col}
            label={(label || i18n.selection) + (selection && selection != DEFAULT_VALUE ? ` (${selectionResults.length} ${getLabel(type)})` : '')}
            options={orderedSelections}
            optionsGroups={formattedGroupedSelections}
            onChange={(v) => {
                setSelection(v)
                updateSettings(SELECTION_FILTERS, !isNil(v) ? v : DEFAULT_VALUE)
                if (isNil(v)) {
                    onChange([], DEFAULT_VALUE)
                }
            }}
            values={selection !== DEFAULT_VALUE ? selection : null}
            disabled={disabled}
            keyLabel='name'
            keyValue='code'
            groupIcon='bubble_chart'
            groupsCanBeSelected
            groupsAreValues
            disablePortal={false}
        />
    )
}

SelectionSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.number,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func, // (list, id) => {}
}

export default SelectionSelect