/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { CardTable } from 'components/datatable/NewTable'
import { getSandreLabel } from 'utils/StringUtil'
import { getDate } from 'utils/DateUtil'
import { push } from '@lagunovsky/redux-react-router'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { compact } from 'lodash'
import SelectedFormDialog from './SelectedFormDialog'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'

const FormContributorPanel = ({
    idDiagnostic,
    data = {},
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)

    const {
        contributors,
        sandreCodes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const actions = compact([{
        icon: 'download',
        tooltip: i18n.export,
        onClick: () => {
            dispatch(ToastrAction.error(i18n.inDeveloppmentFunctionnality))
        },
    }, !readMode && {
        icon: 'compare_arrows',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
    }])

    const headers = ['code', 'city', 'name', { key: 'technicianLogin', value: i18n.mainTechnician }, 'status', { key: 'formDate', value: i18n.date }, 'nbAnswered', 'nbTotal']

    const formattedData = data.map(cd => {
        const foundElement = contributors.find(i => i.id === cd.code) || {}
        return {
            ...foundElement,
            ...cd,
            status: getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_STATUT, cd.status),
            formDate: getDate(cd.formDate),
        }
    })

    return (
        <Grid2 size={12}>
            <CardTable
                title={i18n.contributors}
                actions={actions}
                rows={formattedData}
                headers={headers}
                onClickRow={(element) => {
                    if (readMode && element.idForm) {
                        dispatch(push(`${PATH_PGSSE}/phase1/${idDiagnostic}/${element.idForm}`))
                    } else {
                        dispatch(ToastrAction.warning(i18n.pleaseSaveModifs))
                    }
                }}
            />
            <SelectedFormDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                elements={contributors}
                forms={data}
                stationType={STATION_TYPE_CONSTANT.CONTRIBUTOR}
                keyValue='id'
                onValidate={links => {
                    onChange({
                        link_forms: [
                            ...data.filter(lf => lf.stationType !== STATION_TYPE_CONSTANT.CONTRIBUTOR),
                            ...links,
                        ],
                    })
                    setIsOpen(false)
                }}
            />
        </Grid2>
    )
}

FormContributorPanel.propTypes = {
    idDiagnostic: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})), // DtoPgsseDiagnostic
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default FormContributorPanel