import PropTypes from 'prop-types'
import { MONTHS } from '../../../piezometry/components/suivi/constants/PiezometerSuiviConstants'
import { isUndefined, maxBy, meanBy, minBy, range, round, sumBy, zip } from 'lodash'
import moment from 'moment/moment'
import DtoMonthValues from '../../../piezometry/components/suiviTable/dto/DtoMonthValues'
import i18n from 'simple-react-i18n'
import DtoYearValues from '../../../piezometry/components/suiviTable/dto/DtoYearValues'
import React, { useState } from 'react'
import { setModal } from '../../../utils/FormUtils'
import DtoPluvioMeasureLight from '../../dto/measures/DtoPluvioMeasureLight'
import VirtualizedTable from '../../../components/datatable/virtualizedTable/VirtualizedTable'
import { darkBlue } from '../../../utils/constants/ColorTheme'
import { WhiteCard } from '../../../components/styled/Card'
import MessageCard from '../../../components/card/MessageCard'
import { GREY, ORANGE, WHITE } from '../../../components/constants/ColorConstant'
import { HISTORIC } from '../../../utils/constants/MeasureConstants'
import { Grid2 } from '@mui/material'
import StatisticalIndicatorsLegend from '../../../station/components/legends/StatisticalIndicatorsLegend'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { getBeginingOfTheYear, getEndOfTheYear, getFullDate } from '../../../utils/DateUtil'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import ExportAction from '../../../export/actions/ExportAction'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import PluviometerDto from '../../dto/PluviometerDto'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from '../../../utils/customHook/useActions'
import { getLinks } from '../../../utils/StationUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import { push } from '@lagunovsky/redux-react-router'
import { getLabel } from '../../../utils/StoreUtils'

const BLUE_TABLE = '#3465ff'
const RED_TABLE = '#F44336'


const ExportModalPluvioSuiviTable = ({
    data,
    dataTypeLabel,
    measures,
    horizon,
    pluviometer,
    openExportModal,
    setOpenExportModal,
    years,
}) => {
    const dispatch = useDispatch()
    const {
        typeEnvironmentModels,
        qualifications,
        status,
    } = useSelector(store => ({
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const exportData = (datas, type) => {
        if (!measures.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const measuresFiltered = horizon === HISTORIC ? measures : measures.filter(m => m.date >= getBeginingOfTheYear(years.minYear) && m.date <= getEndOfTheYear(years.maxYear))
        if (!measuresFiltered.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const exportDatas = measuresFiltered.map(line => {
            return {
                ...line,
                headers: ['code', 'dataType', 'name', 'date', 'value', 'status', 'qualification'],
                date: getFullDate(line.date),
                code: pluviometer.code,
                name: pluviometer.name,
                dataType: dataTypeLabel,
                qualification: getLabel(qualifications, line.qualification),
                status: getLabel(status, line.status),
            }
        })
        const dataFormatted = formatData(exportDatas, type)
        return dispatch(ExportAction.export(dataFormatted, type, i18n.graphicTracking))
    }

    const tableExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.csvFile,
            callback: () => exportData(data, 'csv'),
        },
        {
            type: i18n.excelFile,
            callback: () => exportData(data, 'xlsx'),
        }],
    }]

    const exportModel = typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: pluviometer.id.toString(),
                    stationCode: pluviometer.code,
                    stationType: pluviometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                }),
            }],
        }
    })

    return (
        <ExportFileModal
            open={openExportModal}
            onClose={() => setOpenExportModal(false)}
            data={[ ...tableExport, ...exportModel ]}
        />
    )
}

ExportModalPluvioSuiviTable.propTypes = {
    data: PropTypes.shape({
        values: PropTypes.shape({
            day: PropTypes.shape({
                value: PropTypes.string,
                color: PropTypes.string,
            }),
            min: PropTypes.shape({
                value: PropTypes.number,
                color: PropTypes.string,
            }),
            mean: PropTypes.shape({
                value: PropTypes.number,
                color: PropTypes.string,
            }),
            max: PropTypes.shape({
                value: PropTypes.number,
                color: PropTypes.string,
            }),
            sum: PropTypes.shape({
                value: PropTypes.number,
                color: PropTypes.string,
            }),
        }),
        nbMeasures: PropTypes.number,
    }),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    dataTypeLabel: PropTypes.string,
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasureLight)),
    horizon: PropTypes.string,
    openExportModal: PropTypes.bool,
    setOpenExportModal: PropTypes.func,
    years: PropTypes.shape({
        minYear: PropTypes.number,
        maxYear: PropTypes.number,
    }),
}


const getAnnualData = (measures) => {
    const allDaysByMonth = MONTHS.map(() => {
        return new Array(31).fill(undefined)
    })

    measures.map(measure => {
        const date = moment(measure.date)
        allDaysByMonth[date.month()][date.date() - 1] = measure.value
    })

    const coloredData = allDaysByMonth.map(monthDays => {
        const coloredMonthDays = monthDays.map(value => {
            return { value, color: WHITE }
        })
        const dataWithValues = coloredMonthDays.filter(data => data.value && !isUndefined(data.value))
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredMonthDays, 'value').value
            const minValue = minBy(coloredMonthDays, 'value').value
            coloredMonthDays.map(data => {
                if (data.value === maxValue) {
                    data.color = RED_TABLE
                }
                if (data.value === minValue) {
                    data.color = BLUE_TABLE
                }
                return data
            })
            coloredMonthDays.push({ value: round(minBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredMonthDays.push({ value: round(meanBy(dataWithValues, 'value'), 3), color: ORANGE })
            coloredMonthDays.push({ value: round(maxBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredMonthDays.push({ value: round(sumBy(dataWithValues, 'value'), 3), color: ORANGE })
        } else {
            range(4).map(() => coloredMonthDays.push({ value: undefined, color: ORANGE }))
        }
        return coloredMonthDays
    })

    const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
        .map((months, index) => new DtoMonthValues({ value: index + 1, color: GREY }, months))

    values[31] = Object.assign({}, values[31], {
        day: { value: i18n.min, color: GREY },
        min: { value: round(values[31].min.value, 3), color: RED_TABLE },
        mean: undefined,
        max: undefined,
        sum: undefined,
    })
    values[32] = Object.assign({}, values[32], {
        day: { value: i18n.mean, color: GREY },
        min: undefined,
        mean: { value: round(values[32].mean.value, 3), color: ORANGE },
        max: undefined,
        sum: undefined,
    })
    values[33] = Object.assign({}, values[33], {
        day: { value: i18n.max, color: GREY },
        min: undefined,
        mean: undefined,
        max: { value: round(values[33].max.value, 3), color: BLUE_TABLE },
        sum: undefined,
    })
    values[34] = Object.assign({}, values[34], {
        day: { value: i18n.sum, color: GREY },
        min: undefined,
        mean: undefined,
        max: undefined,
        sum: { value: round(values[34].sum.value, 3), color: ORANGE },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const getHistoricData = (measures, minYear, maxYear) => {
    const allDaysByYear = range(minYear, maxYear+1).map(year => {
        return {
            year,
            data: new Array(366).fill(undefined),
        }
    })

    measures.map(measure => {
        const correspondingYearData = allDaysByYear.find(year => parseInt(year.year) === parseInt(moment(measure.date).year())).data
        const bisextileDate = moment(measure.date).year(2016)
        correspondingYearData[bisextileDate.dayOfYear() - 1] = measure.value
    })

    const coloredData = allDaysByYear.map(yearData => {
        const coloredYearData = yearData.data.map(value => {
            return { value, color: WHITE }
        })
        const dataWithValues = coloredYearData.filter(data => data.value)
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredYearData, 'value').value
            const minValue = minBy(coloredYearData, 'value').value
            coloredYearData.map(data => {
                if (data.value === maxValue) {
                    data.color = RED_TABLE
                }
                if (data.value === minValue) {
                    data.color = BLUE_TABLE
                }
                return data
            })
            coloredYearData.push({ value: round(minBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredYearData.push({ value: round(meanBy(dataWithValues, 'value'), 3), color: ORANGE })
            coloredYearData.push({ value: round(maxBy(dataWithValues, 'value').value, 3), color: ORANGE })
            coloredYearData.push({ value: round(sumBy(dataWithValues, 'value'), 3), color: ORANGE })
        } else {
            range(4).map(() => coloredYearData.push({ value: undefined, color: ORANGE }))
        }
        return coloredYearData
    })

    const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
        .map((days, index) => new DtoYearValues({ value: moment('2016-01-01').dayOfYear(index + 1).format('DD/MM'), color: GREY }, days))

    values[366] = Object.assign({}, values[366], {
        day: { value: i18n.min, color: GREY },
        min: { value: round(values[366].min.value, 3), color: RED_TABLE },
        mean: undefined,
        max: undefined,
        sum: undefined,
    })
    values[367] = Object.assign({}, values[367], {
        day: { value: i18n.mean, color: GREY },
        min: undefined,
        mean: { value: round(values[367].mean.value, 3), color: ORANGE },
        max: undefined,
        sum: undefined,
    })
    values[368] = Object.assign({}, values[368], {
        day: { value: i18n.max, color: GREY },
        min: undefined,
        mean: undefined,
        max: { value: round(values[368].max.value, 3), color: BLUE_TABLE },
        sum: undefined,
    })
    values[369] = Object.assign({}, values[369], {
        day: { value: i18n.sum, color: GREY },
        min: undefined,
        mean: undefined,
        max: undefined,
        sum: { value: round(values[369].sum.value, 3), color: ORANGE },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const displayLegend = () => {
    setModal({
        title: i18n.legend,
        actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
        content: (
            <StatisticalIndicatorsLegend />
        ),
    })
}

const PluvioSuiviTable = ({
    pluviometer,
    dataType,
    measures,
    horizon,
    minYear,
    maxYear,
}) => {
    const dispatch = useDispatch()
    const [openExportModal, setOpenExportModal] = useState(false)

    const {
        pluviometers,
        pluviometerStatistics,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    }), shallowEqual)

    useActions(() => pluviometer.id ? ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
        links: getLinks(pluviometer, this),
        arrowNav: getStationArrowNav(STATION_TYPE_NAME.pluviometry, pluviometers, pluviometer.id, s => dispatch(push(`/station/pluviometry/${s.id}/pluviometricSuiviTable`))),
    }) : ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
    }), [pluviometer, pluviometers])

    if (measures.length !== 0) {
        const data = horizon === HISTORIC ? getHistoricData(measures, minYear, maxYear) : getAnnualData(measures)
        if (data.nbMeasures === 0) {
            return (
                <Grid2 size={12}>
                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                </Grid2>
            )
        }
        return (
            <WhiteCard
                title={`${i18n.dataFollowUp} (${data.nbMeasures} ${i18n.measures})`}
                actions={[{
                    iconName: 'info',
                    onClick: displayLegend,
                    tooltip: i18n.legend,
                    color: darkBlue,
                }]}
                round
                cardStyle={{ overflow: 'hidden' }}
            >
                <VirtualizedTable
                    data={data?.values}
                    headers={data?.values[0].headers}
                    columnWidth={100}
                    tableHeight={'100%'}
                />
                { openExportModal && (
                    <ExportModalPluvioSuiviTable
                        data={data}
                        dataTypeLabel={pluviometerStatistics.find(pluvioStat => pluvioStat.typeId === parseInt(dataType))?.label}
                        measures={measures}
                        horizon={horizon}
                        pluviometer={pluviometer}
                        openExportModal={openExportModal}
                        setOpenExportModal={setOpenExportModal}
                        years={{ minYear, maxYear }}
                    />
                )}
            </WhiteCard>
        )
    }
    return (
        <Grid2 size={12}>
            <MessageCard>{i18n.noDataToDisplay}</MessageCard>
        </Grid2>
    )
}

PluvioSuiviTable.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    dataType: PropTypes.number,
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasureLight)),
    horizon: PropTypes.string,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
}

export default PluvioSuiviTable
