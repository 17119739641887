import { Button, Grid2 } from '@mui/material'
import AssociatedSectorSelect from 'components/forms/specific/AssociatedSectorSelect'
import AssociatedStationSelect from 'components/forms/specific/AssociatedStationSelect'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { intersection, omit, orderBy, union } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { getListWithStatus } from 'referencial/util/StatusUtils'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useBoolean from 'utils/customHook/useBoolean'
import { H_QUALITO_RESEARCH_CRITERIAS } from '../../../account/constants/AccessRulesConstants'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import SelectionSelect from '../../../components/forms/specific/SelectionSelect'
import ThresholdSelect from '../../../components/forms/specific/ThresholdSelect'
import Icon from '../../../components/icon/Icon'
import SuivipcAction from '../../../station/components/suivipc/qualitometer/actions/SuivipcAction'
import { STATION_QUALITOMETER_NAMES, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { getDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import { getLabel } from '../../../utils/StoreUtils'
import QualityAction from '../../actions/QualityAction'
import { RESEARCH_TYPE, SELECTION, THRESHOLD } from '../../constants/QualityConstants'
import { thresholdLevels } from '../../constants/QualityResearchConstants'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import useActions from 'utils/customHook/useActions'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import AnalysisAction from 'quality/actions/AnalysisAction'
import useAbortController from 'utils/customHook/useAbortController'
import { QUALITY_CAMPAIGN_TYPES_CONSTANTS } from 'campaign/constants/CampaignConstants'
import useSandreList from 'utils/customHook/useSandreList'
import useListIndexed from 'utils/customHook/useListIndexed'

const DialogSaveResearch = ({
    isOpen = false,
    closePopup = () => {},
    defaultTitle = '',
    searchFilter = {},
}) => {
    const dispatch = useDispatch()
    const {
        savedResearchCriterias,
    } = useSelector(store => ({
        savedResearchCriterias: store.AdministrationReducer.savedResearchCriterias,
    }), shallowEqual)

    const [saveTitle, setSaveTitle] = useState(defaultTitle)

    useEffect(() => {
        setSaveTitle(defaultTitle)
    }, [defaultTitle])

    const onClosePopup = useCallback(() => {
        closePopup()
        setSaveTitle('')
    }, [])

    return (
        <DialogMUI
            fullWidth
            maxWidth='md'
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '30vh',
                    maxHeight: '30vh',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid2 container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid2 >
                        {i18n.saveResearch}
                    </Grid2>
                    <Grid2>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon='close'
                            onClick={() => {
                                setSaveTitle(defaultTitle)
                                closePopup()
                            }}
                        />
                    </Grid2>
                </Grid2>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Input
                        col={12}
                        title={i18n.title}
                        value={saveTitle}
                        onChange={setSaveTitle}
                    />
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid2 container justifyContent='flex-end' alignItems='flex-end'>
                    <Grid2 container justifyContent='flex-end'>
                        <Button
                            onClick={() => {
                                const filterSave = savedResearchCriterias.find(cr => cr.title === saveTitle)
                                const criterias = {
                                    ...omit(searchFilter, ['parameters', 'selectionResults']),
                                    title: saveTitle,
                                    parameter: searchFilter.parameters?.[0],
                                    filterCode: searchFilter.filter,
                                }
                                if (filterSave) {
                                    dispatch(AdministrationAction.updateSavedResearchCriterias(filterSave.id, criterias)).then(onClosePopup)
                                } else {
                                    dispatch(AdministrationAction.addSavedResearchCriterias(criterias)).then(onClosePopup)
                                }
                            }}
                            disabled={!saveTitle}
                            variant='contained'
                        >
                            {i18n.validate}
                        </Button>
                    </Grid2>
                </Grid2>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

DialogSaveResearch.propTypes = {
    isOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    defaultTitle: PropTypes.string,
    searchFilter: PropTypes.shape({}),
}

const SearchCriteria = ({
    defaultFilter = {},
    onSearchIndices = () => { },
    onSearchStates = () => { },
    onSearchHydrobios = () => {},
    onSearchAnalysis = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        qualitometers,
        citiesIndex,
        uniqPoints,
        networks,
        parametersList,
        unitsList,
        fractions,
        methods,
        supports,
        producersList,
        laboratoriesList,
        determinators,
        parameterGroupUsage,
        installations,
        distributionUnits,
        productionUnits,
        qualityCampaigns,
        taxonsList,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        citiesIndex: store.CityReducer.citiesIndex,
        uniqPoints: store.QualityReducer.uniqPoints,
        networks: store.NetworkReducer.networks,
        parametersList: store.ParameterReducer.parameters,
        unitsList: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        methods: store.MethodReducer.methods,
        supports: store.SupportReducer.supports,
        producersList: store.ContributorReducer.producers,
        laboratoriesList: store.ContributorReducer.laboratories,
        determinators: store.ContributorReducer.determinators,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        installations: store.InstallationReducer.installationsLight,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        taxonsList: store.TaxonReducer.taxons,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: openPopun,
        setFalse: closePopup,
    } = useBoolean(false)

    const [researchType, setResearchType] = useState(defaultFilter.researchType)
    const [stationType, setStationType] = useState(defaultFilter.stationType)
    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [qualitometerIds, setQualitometerIds] = useState(defaultFilter.qualitometerIds)
    const [point, setPoint] = useState(defaultFilter.point)
    const [network, setNetwork] = useState(defaultFilter.network)
    const [operationNetwork, setOperationNetwork] = useState(defaultFilter.operationNetwork)
    const [filter, setFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState([])
    const [campaign, setCampaign] = useState(defaultFilter.campaign)

    const [sector, setSector] = useState(defaultFilter.sector)
    const [sectorAssociatedStations, setSectorAssociatedStations] = useState([])
    const [installation, setInstallation] = useState(defaultFilter.installation)
    const [installationAssociatedStations, setInstallationAssociatedStations] = useState([])
    const [productionUnit, setProductionUnit] = useState(defaultFilter.productionUnit)
    const [prodAssociatedStations, setProdAssociatedStations] = useState([])
    const [distributionUnit, setDistributionUnit] = useState(defaultFilter.distributionUnit)
    const [distriAssociatedStations, setDistriAssociatedStations] = useState([])

    const [producers, setProducers] = useState(defaultFilter.producers)
    const [laboratories, setLaboratories] = useState(defaultFilter.laboratories)
    const [support, setSupport] = useState(defaultFilter.support)
    const [method, setMethod] = useState(defaultFilter.method)
    const [selection, setSelection] = useState(defaultFilter.selection)
    const [selectionResults, setSelectionResults] = useState(defaultFilter.selectionResults)
    const [groupCode, setGroupCode] = useState(defaultFilter.groupCode)
    const [taxonLevels, setTaxonLevels] = useState(defaultFilter.taxonLevels)
    const [parameters, setParameters] = useState(defaultFilter.parameters)
    const [units, setUnits] = useState(defaultFilter.units)
    const [fraction, setFraction] = useState(defaultFilter.fraction)
    const [threshold, setThreshold] = useState(defaultFilter.threshold)
    const [thresholdLevel, setThresholdLevel] = useState(defaultFilter.thresholdLevel)
    const [status, setStatus] = useState(defaultFilter.status)
    const [qualification, setQualification] = useState(defaultFilter.qualification)
    const [quantificationControl, setQuantificationControl] = useState(defaultFilter.quantificationControl)
    const [displayAdvancedStatistics, setDisplayAdvancedStatistics] = useState(defaultFilter.displayAdvancedStatistics)
    const [determiners, setDeterminers] = useState(defaultFilter.determiners)
    const [taxons, setTaxons] = useState(defaultFilter.taxons)
    const [phases, setPhases] = useState(defaultFilter.phases)

    const taxonLevelsList = useSandreList('TAXONS.NIVEAUX')
    const taxonPhases = useSandreList('PRELEVEMENTS.PHASE')

    const researchTypes = useMemo(() => {
        return [
            { value: RESEARCH_TYPE.WATER, label: i18n.waterReaserchType },
            { value: RESEARCH_TYPE.INDICES, label: i18n.indices },
            { value: RESEARCH_TYPE.STATES, label: i18n.states },
            { value: RESEARCH_TYPE.TAXON, label: i18n.taxons },
        ]
    }, [])

    const stationsTypeLabel = useMemo(() => {
        const stationsTypes = STATION_QUALITOMETER_NAMES.filter(s => qualitometers.some(q => q.stationType === `${s.code}`))
        return orderBy(stationsTypes, 'name', 'asc')
    }, [qualitometers])

    const groupsFormated = useMemo(() => {
        const stationTypes = hasValue(stationType) ? [STATION_QUALITOMETER_NAMES.find(s => s.code === stationType)] : STATION_QUALITOMETER_NAMES
        return stationTypes.map(({ id, code, name }) => {
            const childrens = qualitometers.filter(q => q.stationType == code)
            return {
                id,
                code,
                name,
                elements: childrens.map(c => ({ id: c.id, code: c.code, name: c.name })),
            }
        })
    }, [qualitometers, stationType])

    const qualitometersFormated = useMemo(() => {
        const qualitometersFiltered = hasValue(stationType) ? qualitometers.filter(q => q.stationType === `${stationType}`) : qualitometers
        return qualitometersFiltered.map(q => {
            const city = citiesIndex[q.townCode]
            const name = q.name || ''
            const nameFormatted = `${q.code ? `${name} [${q.code}]` : name} ${city?.name ? ` - ${city.name}` : ''}`
            return {
                ...q,
                name: nameFormatted,
            }
        })
    }, [citiesIndex, qualitometers, stationType])

    const parametersFormated = useMemo(() => {
        return getListWithStatus(parametersList)
    }, [parametersList])

    const unitsFormated = useMemo(() => {
        return getListWithStatus(unitsList)
    }, [unitsList])

    const statusList = useMemo(() => getStatusSelectOptions(), [])

    const qualificationsList = useMemo(() => getQualificationSelectOptions(), [])

    const filteredQualityCampaigns = useMemo(() => researchType === RESEARCH_TYPE.TAXON ? qualityCampaigns.filter(qc => qc.campaignType === QUALITY_CAMPAIGN_TYPES_CONSTANTS.HYDROBIO) : qualityCampaigns, [qualityCampaigns, researchType])

    const getStations = () => {
        if (prodAssociatedStations?.length || distriAssociatedStations?.length || sectorAssociatedStations?.length || installationAssociatedStations?.length || (filter && filter !== -1) || qualitometerIds?.length) {
            const ids = [
                prodAssociatedStations,
                distriAssociatedStations,
                sectorAssociatedStations,
                installationAssociatedStations,
                qualitometerIds,
                filter && filter !== -1 ? filterResults.map(s => s.id) : [],
            ].filter(f => !!f?.length)
            const listIds = intersection(...ids)
            return qualitometers.filter(s => listIds.find(id => id === s.id))
        }
        return qualitometers
    }

    const searchFilter = {
        researchType,
        stationType,
        startDate,
        endDate,
        qualitometerIds,
        point,
        network,
        operationNetwork,
        filter: hasValue(filter) && `${filter}` || undefined,
        // filterResults,
        campaign,

        sector,
        // sectorAssociatedStations,
        installation,
        // installationAssociatedStations,
        productionUnit,
        // prodAssociatedStations,
        distributionUnit,
        // distriAssociatedStations,

        producers,
        laboratories,
        determiners,
        taxons,
        taxonLevels,
        phases,
        support,
        method,
        selection: selection && selection !== '-1' ? selection : undefined,
        selectionResults: selection && selection !== '-1' ? selectionResults : undefined,
        groupCode,
        parameters,
        units,
        fraction,
        threshold,
        thresholdLevel,
        status,
        qualification,
        quantificationControl,
        displayAdvancedStatistics,
    }

    const onStartResearch = () => {
        if (!hasValue(researchType)) {
            dispatch(ToastrAction.info(i18n.searchCriteriasMessage))
            return
        }
        const stations = getStations()
        const stationsFiltered = hasValue(stationType) && stationType !== -1 ? stations.filter(s => s.stationType == stationType) : stations
        switch (researchType) {
            case RESEARCH_TYPE.INDICES:
                onSearchIndices({ ...searchFilter, stations: stationsFiltered.map(s => s.code), stationsIds: stationsFiltered.map(s => s.id) })
                break
            case RESEARCH_TYPE.STATES:
                onSearchStates({ ...searchFilter, stations: stationsFiltered.map(s => s.code), stationsIds: stationsFiltered.map(s => s.id) })
                break
            case RESEARCH_TYPE.TAXON:
                onSearchHydrobios({ ...searchFilter, stations: stationsFiltered.map(s => s.id) })
                break
            default:
                onSearchAnalysis({ ...searchFilter, stations: stationsFiltered.map(s => s.id) })
                break
        }
    }

    useUpdateEffect(() => {
        setResearchType(defaultFilter.researchType)
        setStationType(defaultFilter.stationType)
        setStartDate(defaultFilter.startDate)
        setEndDate(defaultFilter.endDate)
        setQualitometerIds(defaultFilter.qualitometerIds)
        setPoint(defaultFilter.point)
        setNetwork(defaultFilter.network)
        setOperationNetwork(defaultFilter.operationNetwork)
        const filterNumber = parseInt(defaultFilter.filter)
        setFilter(isNaN(filterNumber) ? -1 : filterNumber)
        setCampaign(defaultFilter.campaign)
        // setFilterResults([])
        setSector(defaultFilter.sector)
        // setSectorAssociatedStations([])
        setInstallation(defaultFilter.installation)
        // setInstallationAssociatedStations([])
        setProductionUnit(defaultFilter.productionUnit)
        // setProdAssociatedStations([])
        setDistributionUnit(defaultFilter.distributionUnit)
        // setDistriAssociatedStations([])
        setProducers(defaultFilter.producers)
        setLaboratories(defaultFilter.laboratories)
        setDeterminers(defaultFilter.determiners)
        setTaxons(defaultFilter.taxons)
        setTaxonLevels(defaultFilter.taxonLevels)
        setPhases(defaultFilter.phases)
        setSupport(defaultFilter.support)
        setMethod(defaultFilter.method)
        setSelection(defaultFilter.selection)
        // setSelectionResults([])
        setGroupCode(defaultFilter.groupCode)
        setParameters(defaultFilter.parameters)
        setUnits(defaultFilter.units)
        setFraction(defaultFilter.fraction)
        setThreshold(defaultFilter.threshold)
        setThresholdLevel(defaultFilter.thresholdLevel)
        setStatus(defaultFilter.status)
        setQualification(defaultFilter.qualification)
        setQuantificationControl(defaultFilter.quantificationControl)
        setDisplayAdvancedStatistics(defaultFilter.displayAdvancedStatistics)
    }, [defaultFilter])

    useActions(() => ({
        save: openPopun,
        clear: () => {
            setResearchType(RESEARCH_TYPE.WATER)
            setStationType()
            setStartDate()
            setEndDate()
            setQualitometerIds()
            setPoint()
            setNetwork()
            setOperationNetwork()
            setFilter()
            setFilterResults([])
            setCampaign()
            setSector()
            setSectorAssociatedStations([])
            setInstallation()
            setInstallationAssociatedStations([])
            setProductionUnit()
            setProdAssociatedStations([])
            setDistributionUnit()
            setDistriAssociatedStations([])
            setProducers([])
            setLaboratories([])
            setDeterminers([])
            setTaxons([])
            setPhases()
            setSupport()
            setMethod()
            setSelection('-1')
            setSelectionResults([])
            setGroupCode()
            setParameters([])
            setUnits([])
            setThreshold()
            setThresholdLevel()
            setStatus()
            setQualification()
            setQuantificationControl()
            setDisplayAdvancedStatistics()
        },
    }), [])

    const thresholdType = useMemo(() => {
        switch (researchType) {
            case RESEARCH_TYPE.TAXON: return THRESHOLD.TAXON
            case RESEARCH_TYPE.INDICES: return THRESHOLD.INDICE
            default: return THRESHOLD.PC
        }
    }, [researchType])

    return (
        <Card cardStyle={{ padding: '5' }}>
            <Grid2 container columnSpacing={2}>
                <Grid2 size={2}>
                    <SuperMultiAutocomplete
                        options={researchTypes}
                        label={i18n.researchType}
                        keyLabel={'label'}
                        keyValue={'value'}
                        onChange={setResearchType}
                        values={researchType}
                    />
                </Grid2>
                <Grid2 size={2}>
                    <SimpleDatePicker
                        id='startDate'
                        label={i18n.startDate}
                        value={startDate}
                        max={endDate}
                        onChange={setStartDate}
                    />
                </Grid2>
                <Grid2 size={2}>
                    <SimpleDatePicker
                        id='endDate'
                        label={i18n.endDate}
                        value={endDate}
                        onChange={setEndDate}
                        min={startDate}
                    />
                </Grid2>
                <Grid2 size={6} />
                <Grid2 size={6}>
                    <SimpleFilterSelect
                        stationType={STATION_TYPE_NAME.quality}
                        onChange={(res, f) => {
                            setFilter(f)
                            setFilterResults(res)
                        }}
                        stations={qualitometers}
                    />
                </Grid2>
                <Grid2 size={6} />
                <Grid2 size={2}>
                    <SuperMultiAutocomplete
                        options={stationsTypeLabel}
                        label={i18n.stationTypeLabel}
                        keyValue='code'
                        onChange={setStationType}
                        values={stationType}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <SuperMultiAutocomplete
                        options={qualitometersFormated}
                        optionsGroups={groupsFormated}
                        values={qualitometerIds}
                        label={i18n.watchpoints}
                        onChange={setQualitometerIds}
                        multiple
                    />
                </Grid2>
                <Grid2 size={6}>
                    <SuperMultiAutocomplete
                        options={uniqPoints}
                        values={point}
                        label={i18n.samplePoint}
                        keyValue='identifiant'
                        displayWithCode
                        onChange={setPoint}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <SuperMultiAutocomplete
                        label={i18n.network}
                        options={networks}
                        keyLabel='nameWithSandre'
                        values={network}
                        onChange={setNetwork}
                    />
                </Grid2>
                <Grid2 size={6}>
                    {
                        (researchType === RESEARCH_TYPE.WATER || researchType === RESEARCH_TYPE.TAXON) && (
                            <SuperMultiAutocomplete
                                label={i18n.campaign}
                                options={filteredQualityCampaigns}
                                values={campaign}
                                onChange={setCampaign}
                            />
                        )
                    }
                </Grid2>
                <HabilitationRequired habilitation={H_QUALITO_RESEARCH_CRITERIAS}>
                    <Grid2 size={12}>
                        <StyledFieldSet>
                            <StyledLegend>{'AEP'}</StyledLegend>
                            <Grid2 columnSpacing={2} container>
                                <Grid2 size={6}>
                                    <AssociatedSectorSelect
                                        value={sector}
                                        onChange={(list, value) => {
                                            setSector(value)
                                            setSectorAssociatedStations(list)
                                        }}
                                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <AssociatedStationSelect
                                        label={i18n.installation}
                                        value={installation}
                                        options={installations}
                                        onChange={(list, value) => {
                                            setInstallation(value)
                                            setInstallationAssociatedStations(list)
                                        }}
                                        stationType={STATION_TYPE_CONSTANT.INSTALLATION}
                                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                        keyValue='code'
                                        displayWithCode
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <AssociatedStationSelect
                                        label={i18n.productionUnit}
                                        value={productionUnit}
                                        options={productionUnits}
                                        onChange={(list, value) => {
                                            setProductionUnit(value)
                                            setProdAssociatedStations(list)
                                        }}
                                        stationType={STATION_TYPE_CONSTANT.PRODUCTION}
                                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                        keyValue='code'
                                        displayWithCode
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <AssociatedStationSelect
                                        label={i18n.distributionUnit}
                                        value={distributionUnit}
                                        options={distributionUnits}
                                        onChange={(list, value) => {
                                            setDistributionUnit(value)
                                            setDistriAssociatedStations(list)
                                        }}
                                        stationType={STATION_TYPE_CONSTANT.DISTRIBUTION}
                                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                        keyValue='code'
                                        displayWithCode
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                </HabilitationRequired>
                {
                    ([RESEARCH_TYPE.WATER, RESEARCH_TYPE.INDICES, RESEARCH_TYPE.TAXON].includes(researchType)) && (
                        <>
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.sample}</StyledLegend>
                                    <Grid2 columnSpacing={2} container>
                                        <Grid2 size={6}>
                                            <MultiContributorsAutocomplete
                                                label={i18n.producer}
                                                options={producersList}
                                                onChange={setProducers}
                                                values={producers}
                                                multiple
                                            />
                                        </Grid2>
                                        {
                                            researchType === RESEARCH_TYPE.WATER && (
                                                <Grid2 size={6}>
                                                    <MultiContributorsAutocomplete
                                                        label={i18n.laboratory}
                                                        options={laboratoriesList}
                                                        values={laboratories}
                                                        onChange={setLaboratories}
                                                        multiple
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        {
                                            researchType === RESEARCH_TYPE.TAXON && (
                                                <Grid2 size={6}>
                                                    <MultiContributorsAutocomplete
                                                        label={i18n.determiner}
                                                        options={determinators}
                                                        values={determiners}
                                                        onChange={setDeterminers}
                                                        displayWithCode
                                                        multiple
                                                        limit={2}
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        {
                                            researchType !== RESEARCH_TYPE.STATES && (
                                                <Grid2 size={6}>
                                                    <SuperMultiAutocomplete
                                                        label={i18n.support}
                                                        options={supports}
                                                        values={support}
                                                        onChange={setSupport}
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        <Grid2 size={6}>
                                            <SuperMultiAutocomplete
                                                label={i18n.method}
                                                options={methods}
                                                values={method}
                                                onChange={setMethod}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{researchType === RESEARCH_TYPE.TAXON ? i18n.hydrobiology : i18n.physicochemical}</StyledLegend>
                                    <Grid2 columnSpacing={2} container>
                                        <Grid2 size={(researchType === RESEARCH_TYPE.WATER || researchType === RESEARCH_TYPE.TAXON) ? 4 : 12}>
                                            <SelectionSelect
                                                value={selection}
                                                onChange={(res, s) => {
                                                    setSelection(s)
                                                    setSelectionResults(res)
                                                }}
                                                type={researchType === -3 ? SELECTION.TAXON : SELECTION.PC}
                                            />
                                        </Grid2>
                                        {
                                            researchType === RESEARCH_TYPE.WATER && (
                                                <Grid2 size={4}>
                                                    <SuperMultiAutocomplete
                                                        label={i18n.group}
                                                        options={parameterGroupUsage}
                                                        values={groupCode}
                                                        onChange={setGroupCode}
                                                        keyValue='groupCode'
                                                        keyLabel='labelWithCode'
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        {
                                            researchType === RESEARCH_TYPE.WATER && (
                                                <Grid2 size={4}>
                                                    <SuperMultiAutocomplete
                                                        label={i18n.operationNetwork}
                                                        options={networks}
                                                        keyLabel='nameWithSandre'
                                                        keyValue='sandreCode'
                                                        values={operationNetwork}
                                                        onChange={setOperationNetwork}
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        {
                                            researchType === RESEARCH_TYPE.TAXON && (
                                                <Grid2 size={6}>
                                                    <SuperMultiAutocomplete
                                                        label={i18n.taxonsLevel}
                                                        options={taxonLevelsList}
                                                        values={taxonLevels}
                                                        onChange={setTaxonLevels}
                                                        keyValue='code'
                                                        multiple
                                                    />
                                                </Grid2>
                                            )
                                        }
                                        {
                                            researchType === RESEARCH_TYPE.TAXON ? (
                                                <>
                                                    <Grid2 size={6}>
                                                        <SuperMultiAutocomplete
                                                            label={i18n.taxons}
                                                            options={taxonsList}
                                                            limit={2}
                                                            keyValue='code'
                                                            onChange={setTaxons}
                                                            values={taxons}
                                                            keyLabel='labelWithCode'
                                                            multiple
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={6}>
                                                        <SuperMultiAutocomplete
                                                            label={i18n.phases}
                                                            options={taxonPhases}
                                                            keyValue='code'
                                                            onChange={setPhases}
                                                            values={phases}
                                                            multiple
                                                        />
                                                    </Grid2>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid2 size={6}>
                                                        <SuperMultiAutocomplete
                                                            label={i18n.parameters}
                                                            options={parametersFormated}
                                                            limit={1}
                                                            keyValue='code'
                                                            onChange={setParameters}
                                                            values={parameters}
                                                            keyLabel='labelWithCode'
                                                            multiple
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={3}>
                                                        <SuperMultiAutocomplete
                                                            label={i18n.unit}
                                                            options={unitsFormated}
                                                            keyValue='code'
                                                            onChange={setUnits}
                                                            values={units}
                                                            keyLabel='symbolWithCode'
                                                            multiple
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={3}>
                                                        <SuperMultiAutocomplete
                                                            label={i18n.fraction}
                                                            options={fractions}
                                                            keyValue='id'
                                                            onChange={setFraction}
                                                            values={fraction}
                                                            keyLabel='labelWithCode'
                                                        />
                                                    </Grid2>
                                                </>
                                            )
                                        }
                                        <Grid2 size={3}>
                                            <ThresholdSelect
                                                selected={threshold}
                                                onChange={setThreshold}
                                                thresholdType={thresholdType}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <SuperMultiAutocomplete
                                                options={thresholdLevels}
                                                keyValue='code'
                                                label={i18n.thresholdLevel}
                                                onChange={setThresholdLevel}
                                                values={thresholdLevel}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <SuperMultiAutocomplete
                                                options={statusList}
                                                label={i18n.status}
                                                onChange={setStatus}
                                                values={status}
                                                noSort
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <SuperMultiAutocomplete
                                                options={qualificationsList}
                                                label={i18n.qualification}
                                                onChange={setQualification}
                                                values={qualification}
                                                noSort
                                            />
                                        </Grid2>
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                        </>
                    )
                }
                <Grid2 container size={12} style={{ margin: '10px' }} justifyContent='space-between'>
                    {researchType === RESEARCH_TYPE.WATER ? (
                        <>
                            <Grid2 size='auto' display='flex'>
                                <Checkbox
                                    label={i18n.quantificationControl}
                                    onChange={setQuantificationControl}
                                    checked={quantificationControl}
                                />
                                <Checkbox
                                    label={i18n.displayAdvancedStatistics}
                                    onChange={setDisplayAdvancedStatistics}
                                    checked={displayAdvancedStatistics}
                                    componentClassName='padding-left-2'
                                />
                            </Grid2>
                        </>
                    ) : (
                        <Grid2 size={10} />
                    )}
                    <Grid2 size='auto'>
                        <Button
                            onClick={onStartResearch}
                            variant='contained'
                        >
                            <Icon icon='search' />
                            <span style={{ marginLeft: 5 }}>
                                {i18n.search}
                            </span>
                        </Button>
                    </Grid2>
                </Grid2>
            </Grid2>
            <DialogSaveResearch
                isOpen={isOpen}
                closePopup={closePopup}
                defaultTitle={defaultFilter.saveTitle}
                searchFilter={searchFilter}
            />
        </Card>
    )
}

SearchCriteria.propTypes = {
    defaultFilter: PropTypes.shape({}),
    onSearchIndices: PropTypes.func,
    onSearchStates: PropTypes.func,
    onSearchHydrobios: PropTypes.func,
    onSearchAnalysis: PropTypes.func,
}

const TableSaveResearch = ({
    onSelectSavedCriterias = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        savedResearchCriterias,
        parameters,
        selections,
        filters,
    } = useSelector(store => ({
        savedResearchCriterias: store.AdministrationReducer.savedResearchCriterias,
        parameters: store.ParameterReducer.parameters,
        selections: store.ParameterReducer.selections,
        filters: store.StationReducer.filters,
    }), shallowEqual)

    const savedResearchCriteriasFormated = useMemo(() => {
        return savedResearchCriterias.map(criterias => {
            const {
                id,
                title,
                parameter,
                ...restCriterias
            } = criterias
            return {
                id,
                nullValue: (
                    <Icon
                        icon='reply'
                        tooltip={i18n.apply}
                        onClick={() => onSelectSavedCriterias({ ...restCriterias, parameters: [parameter], saveTitle: title })}
                    />
                ),
                title,
                dateStart: getDate(restCriterias.startDate),
                dateEnd: getDate(restCriterias.endDate),
                selection: getLabel(selections, restCriterias.selection),
                parameter: getLabel(parameters, parameter),
                researchType: restCriterias.researchType === RESEARCH_TYPE.WATER && i18n.waterReaserchType || hasValue(restCriterias.researchType) && i18n.indices || '',
                filter: getLabel(filters, restCriterias.filterCode),
            }
        })
    }, [filters, parameters, savedResearchCriterias, selections])

    return (
        <Table
            id='saved_research_criterias'
            title={i18n.savedResearchCriterias}
            showNbElements={false}
            condensed
            className='no-margin'
            data={savedResearchCriteriasFormated}
            sortable
            deletable
            type={{
                headers: ['nullValue', 'title', 'dateStart', 'dateEnd', 'selection', 'parameter', 'researchType', 'filter'],
            }}
            onDelete={criteriasObj => dispatch(AdministrationAction.deleteSavedResearchCriterias(criteriasObj.id))}
        />
    )
}

TableSaveResearch.propTypes = {
    onSelectSavedCriterias: PropTypes.func,
}


const SearchCriteriaPanel = ({
    defaultFilter,
    setFilter = () => {},
    onSelectSavedCriterias = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const indexedTaxons = useListIndexed(taxons, 'code')

    const [researchType, setResearchType] = useState(defaultFilter?.researchType)

    const {
        value: isSearching,
        setTrue: setSearchingToTrue,
        setFalse: setSearchingToFalse,
    } = useBoolean(false)
    const [searchProgress, setSearchProgress] = useState(0)

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const onSearchIndices = searchFilter => {
        setFilter(searchFilter)
        setResearchType(searchFilter.researchType)
        setSearchingToTrue()
        setSearchProgress(0)
        dispatch(QualityAction.fetchSearchIndices(searchFilter)).finally(setSearchingToFalse)
    }

    const onSearchStates = searchFilter => {
        setFilter(searchFilter)
        setResearchType(searchFilter.researchType)
        setSearchingToTrue()
        setSearchProgress(0)
        dispatch(QualityAction.fetchSearchStates(searchFilter)).finally(setSearchingToFalse)
    }

    const onSearchHydrobios = searchFilter => {
        setFilter(searchFilter)
        setResearchType(searchFilter.researchType)
        setSearchingToTrue()
        setSearchProgress(0)
        if (hasValue(searchFilter.threshold) && searchFilter.threshold !== '') {
            dispatch(SuivipcAction.fetchQualityThresholds(searchFilter.threshold))
        }
        if (controllerRef.current.signal.aborted) {
            initController()
        }

        const listTaxons = [
            searchFilter.taxons,
            searchFilter.selection && searchFilter.selectionResults || [],
        ].filter(l => !!l.length)
        const intersectTaxons = intersection(...listTaxons)

        const taxonsByLevels = searchFilter.taxonLevels.length > 0 ? intersectTaxons.filter(t => searchFilter.taxonLevels.includes(indexedTaxons[t]?.level)) : intersectTaxons

        const filter = {
            lightMode: true, // Option[Boolean] = None,

            stations: searchFilter.stations, // Option[Seq[Int]] = None
            support: searchFilter.support, // Option[String] = None,
            units: searchFilter.units.length ? searchFilter.units : undefined, // Option[Seq[String]] = None,
            method: searchFilter.method, // Option[String] = None,
            producers: searchFilter.producers.length ? searchFilter.producers : undefined, // Option[Seq[Int]] = None,
            laboratories: searchFilter.laboratories.length ? searchFilter.laboratories : undefined, // Option[Seq[Int]] = None,
            determiners: searchFilter.determiners.length ? searchFilter.determiners : undefined, // Option[Seq[Int]] = None,
            taxons: taxonsByLevels, // Option[Seq[String]] = None,
            phases: searchFilter.phases, // Option[Seq[Int]] = None,
            quantificationControl: searchFilter.quantificationControl, // Option[Boolean] = None,
            network: searchFilter.network, // Option[String] = None,
            operationNetwork: searchFilter.operationNetwork, // Option[String] = None,
            status: searchFilter.status, // Option[String] = None,
            qualification: searchFilter.qualification, // Option[String] = None,
            startDate: searchFilter.startDate, // Option[DateTime] = None,
            endDate: searchFilter.endDate, // Option[DateTime] = None,
            point: searchFilter.point, // Option[String] = None,
            groupCode: searchFilter.groupCode, // Option[Int] = None,
            campaign: searchFilter.campaign, // Option[Double] = None,
        }

        setFilter(searchFilter)
        dispatch(QualityAction.fetchSearchHydrobios(filter, controllerRef.current.signal, setSearchProgress))
            .finally(setSearchingToFalse)
    }

    const onSearchAnalysis = searchFilter => {
        setResearchType(searchFilter.researchType)
        setSearchingToTrue()
        setSearchProgress(0)
        if (hasValue(searchFilter.threshold) && searchFilter.threshold !== '') {
            dispatch(SuivipcAction.fetchQualityThresholds(searchFilter.threshold))
        }
        if (controllerRef.current.signal.aborted) {
            initController()
        }

        const parameters = [
            searchFilter.parameters,
            searchFilter.selection && searchFilter.selectionResults || [],
        ].filter(l => !!l.length)
        const intersectParams = intersection(...parameters)

        const taxonsByLevels = searchFilter.taxonLevels.length ? searchFilter.taxons.filter(t => searchFilter.taxonLevels.includes(t.level)) : []
        const unionTaxons = union(taxonsByLevels, searchFilter.taxons)

        const filter = {
            lightMode: true, // Option[Boolean] = None,

            stations: searchFilter.stations, // Option[Seq[Int]] = None
            support: searchFilter.support, // Option[String] = None,
            parameters: intersectParams.length ? intersectParams : undefined, // Option[Seq[String]] = None,
            units: searchFilter.units.length ? searchFilter.units : undefined, // Option[Seq[String]] = None,
            fraction: searchFilter.fraction, // Option[String] = None,
            method: searchFilter.method, // Option[String] = None,
            producers: searchFilter.producers.length ? searchFilter.producers : undefined, // Option[Seq[Int]] = None,
            laboratories: searchFilter.laboratories.length ? searchFilter.laboratories : undefined, // Option[Seq[Int]] = None,
            determiners: searchFilter.determiners.length ? searchFilter.determiners : undefined, // Option[Seq[Int]] = None,
            taxons: unionTaxons.length ? unionTaxons : undefined, // Option[Seq[Int]] = None,
            phases: searchFilter.phases, // Option[Seq[Int]] = None,
            quantificationControl: searchFilter.quantificationControl, // Option[Boolean] = None,
            network: searchFilter.network, // Option[String] = None,
            operationNetwork: searchFilter.operationNetwork, // Option[String] = None,
            status: searchFilter.status, // Option[String] = None,
            qualification: searchFilter.qualification, // Option[String] = None,
            startDate: searchFilter.startDate, // Option[DateTime] = None,
            endDate: searchFilter.endDate, // Option[DateTime] = None,
            point: searchFilter.point, // Option[String] = None,
            groupCode: searchFilter.groupCode, // Option[Int] = None,
            campaign: searchFilter.campaign, // Option[Double] = None,
        }
        setFilter(searchFilter)

        dispatch(AnalysisAction.fetchAnalysis(filter, controllerRef.current.signal, setSearchProgress))
            .finally(setSearchingToFalse)
    }

    return (
        <div style={{ paddingBottom: '100px' }}>
            <SearchCriteria
                defaultFilter={defaultFilter}
                onSearchIndices={onSearchIndices}
                onSearchStates={onSearchStates}
                onSearchHydrobios={onSearchHydrobios}
                onSearchAnalysis={onSearchAnalysis}
            />
            {
                isSearching && (
                    <Card>
                        <ProgressCard progress={searchProgress} withoutCard />
                        {
                            researchType === RESEARCH_TYPE.WATER && (
                                <div style={{ paddingBottom: 10, textAlign: 'center' }}>
                                    <Button
                                        onClick={() => controllerRef.current.abort()}
                                        variant='contained'
                                    >
                                        {i18n.cancel}
                                    </Button>
                                </div>
                            )
                        }
                    </Card>
                )
            }
            <TableSaveResearch onSelectSavedCriterias={onSelectSavedCriterias} />
        </div>
    )
}

SearchCriteriaPanel.propTypes = {
    defaultFilter: PropTypes.shape({
        researchType: PropTypes.number,
        // ...
    }),
    setFilter: PropTypes.func,
    onSelectSavedCriterias: PropTypes.func,
    onSearchResultsReceived: PropTypes.func,
}

export default SearchCriteriaPanel
