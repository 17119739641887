import { AEPTab, DEFAULT_SUPPORT, DEFAULT_SUPPORT_VALUE, SEUILS_LIM, SEUILS_REF } from 'quality/components/AEPoverview/AEPOverview'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import { useParams } from 'react-router'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import useActions from 'utils/customHook/useActions'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import moment from 'moment'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import QualityAction from 'quality/actions/QualityAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import OperationAction from 'quality/actions/OperationAction'
import AccountAction from 'account/actions/AccountAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import ExportAction from 'export/actions/ExportAction'
import { hasValue } from 'utils/NumberUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import { isNil, minBy, orderBy, range, uniq } from 'lodash'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import ProgressCard from 'components/card/ProgressCard'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import PropTypes from 'prop-types'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import Select from 'components/forms/Select'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import Button from 'components/forms/Button'
import { getI18nOrLabel } from 'utils/StringUtil'
import { LINKS } from 'productionUnit/constants/ProductionUnitConstants'

const ProbAEPCriterias = ({
    defaultFilter = {},
    onValidate = () => { },
    operations = [],
}) => {
    const {
        producers,
        supports,
        prodUnitLinks,
    } = useSelector(store => ({
        producers: store.ContributorReducer.producers,
        supports: store.SupportReducer.supports,
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [producer, setProducer] = useState(defaultFilter.producer)
    const [year, setYear] = useState(defaultFilter.year)
    const [referenceThreshold, setReferenceThreshold] = useState(defaultFilter.referenceThreshold)
    const [limitThreshold, setLimitThreshold] = useState(defaultFilter.limitThreshold)
    const [support, setSupport] = useState(defaultFilter.support)
    const [linkType, setLinkType] = useState(defaultFilter.linkType)

    useEffect(() => {
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [])

    const yearList = useMemo(() => {
        const minDate = minBy(operations, 'date')
        const list = minDate ? range(moment().year(), moment(minDate).year(), -1).map(y => ({ code: y, name: y })) : []
        return orderBy(list, 'code', 'desc')
    }, [operations])

    const linksTypes = uniq(prodUnitLinks.filter(l => l.siteType === 3).map(l => l.linkType)).map(lt => ({ id: lt, name: getI18nOrLabel(LINKS[lt]) }))

    return (
        <CollapseTopBar>
            <div className='card-content grey-background padding-1'>
                <div className='row no-margin'>
                    <Select
                        options={producers}
                        label={i18n.producers}
                        col={6}
                        value={producer}
                        nullLabel='&nbsp;'
                        onChange={setProducer}
                    />
                    <Select
                        options={linksTypes}
                        label={i18n.stationType}
                        col={3}
                        value={linkType}
                        onChange={setLinkType}
                        nullLabel='&nbsp;'
                        noSort
                    />
                    <Select
                        options={yearList}
                        label={i18n.year}
                        col={3}
                        value={year}
                        onChange={setYear}
                        nullLabel='&nbsp;'
                        noSort
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <ThresholdSelect
                        col={3}
                        title={i18n.referenceThreshold}
                        selected={referenceThreshold}
                        onChange={setReferenceThreshold}
                        fieldRef={SEUILS_REF}
                    />
                    <ThresholdSelect
                        col={3}
                        title={i18n.limitThreshold}
                        selected={limitThreshold}
                        onChange={setLimitThreshold}
                        fieldRef={SEUILS_LIM}
                    />
                    <Select
                        col={3}
                        label={i18n.support}
                        options={supports}
                        onChange={setSupport}
                        value={support}
                        keyValue={'id'}
                        displayWithCode
                    />
                    <Button
                        col={3}
                        title={i18n.search}
                        onClick={() => onValidate({
                            producer,
                            year,
                            referenceThreshold,
                            limitThreshold,
                            support,
                            linkType,
                        })}
                    />
                </div>
            </div>
        </CollapseTopBar>
    )
}

ProbAEPCriterias.propTypes = {
    onValidate: PropTypes.func,
    defaultFilter: PropTypes.shape({
        producer: PropTypes.number,
        year: PropTypes.number,
        threshold: PropTypes.number,
        support: PropTypes.string,
        filter: PropTypes.number,
        filterResults: PropTypes.arrayOf(PropTypes.number),
    }),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

const ProductionUnitAEPApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        productionUnit,
        prodUnitLinks,
        accountUserSettings,
        applicationSettings,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
        accountUserSettings: store.AccountReducer.accountUserSettings,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.productionUnit,
        href: 'productionUnit',
    }, {
        title: getStationTitle(productionUnit),
        href: `station/productionUnit/${id}`,
    }, {
        title: i18n.AEPoverview,
        href: `station/productionUnit/${id}/conformityOverview`,
    }], [])

    useActions(() => ({}), [])

    useEffect(() => {
        dispatch(ProductionUnitAction.fetchProductionUnitLinks(id))
    }, [dispatch, id])

    const thresholdRefSettings = useApplicationSetting(SEUILS_REF, setting => setting ? parseInt(setting) : undefined)
    const thresholdRefAccountSettings = useAccountSetting(SEUILS_REF, setting => setting ? parseInt(setting) : undefined)

    const thresholdLimSettings = useApplicationSetting(SEUILS_LIM, setting => setting ? parseInt(setting) : undefined)
    const thresholdLimAccountSettings = useAccountSetting(SEUILS_LIM, setting => setting ? parseInt(setting) : undefined)

    const defaultSupport = useAccountSetting(DEFAULT_SUPPORT, setting => setting || undefined)

    const [filter, setFilter] = useState({
        support: defaultSupport,
        year: moment().year() - 1,
        producer: undefined,
        referenceThreshold: thresholdRefAccountSettings ?? thresholdRefSettings,
        limitThreshold: thresholdLimAccountSettings ?? thresholdLimSettings,
    })
    const [isFirstSearchDone, setFirstSearchDone] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)
    const [modelExport, setModelExport] = useState([])

    const [operations, setOperations] = useState([])
    const [analysis, setAnalysis] = useState([])

    const {
        progress: criteriasProgress,
        isLoaded,
    } = useProgressDispatch(() => [
        dispatch(ParameterAction.fetchParameters()),
        dispatch(QualityAction.fetchRemarks()),
        dispatch(ContributorAction.fetchProducers()),
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(ContributorAction.fetchLaboratories()),
        dispatch(ContributorAction.fetchDeterminators()),
    ])

    const filteredIds = useMemo(() => prodUnitLinks.filter(ass => ass.siteType === 3 && ass.linkType === filter.linkType).map(ass => ass.siteCode), [filter.linkType, prodUnitLinks])

    useEffect(() => {
        const controller = new AbortController()
        const ids = prodUnitLinks.filter(ass => ass.siteType === 3).map(ass => ass.siteCode)
        if (ids.length) {
            setOperations([])
            dispatch(OperationAction.getAllOperation(ids, controller.signal)).then(op => setOperations(op.map(o => new DtoOperation(o))))
        }
        return () => {
            controller.abort()
        }
    }, [dispatch, prodUnitLinks])

    useEffect(() => {
        if (!accountUserSettings.length) {
            dispatch(AccountAction.fetchAccountUserSettings())
        }
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchSettings())
        }
        dispatch(ExportAction.getEnvironmentModelsByType('qualityResearch')).then(modelList => setModelExport(modelList))
    }, [])

    const onValidate = useCallback((newFilter) => {
        const {
            support,
            year,
            producer,
            referenceThreshold,
            limitThreshold,
            linkType,
        } = newFilter
        if (!hasValue(referenceThreshold) && !hasValue(limitThreshold)) {
            dispatch(ToastrAction.error(i18n.noThresholdSelectedError))
            return
        }
        if (isNil(linkType)) {
            dispatch(ToastrAction.error(i18n.noStationTypeSelected))
            return
        }
        const newIds = prodUnitLinks.filter(ass => ass.siteType === 3 && ass.linkType === linkType).map(ass => ass.siteCode)
        if (newIds.length === 0) {
            dispatch(ToastrAction.error(i18n.noAssociatedQualityStations))
            return
        }
        dispatch(AdministrationAction.updateSetting(DEFAULT_SUPPORT, newFilter.support || DEFAULT_SUPPORT_VALUE))
        setDataLoaded(false)
        setFirstSearchDone(true)
        setProgress(0)
        setFilter(newFilter)
        const criterias = {
            lightMode: true,
            stations: newIds,
            startDate: getBeginingOfTheYear(year),
            endDate: getEndOfTheYear(year),
            support: support !== DEFAULT_SUPPORT_VALUE ? support : undefined,
            producers: isNil(producer) ? undefined : [producer],
        }
        AnalysisAction.getAnalysis(criterias).then(an => {
            setAnalysis(an.map(a => new DtoAnalysisLight(a)))
            setDataLoaded(true)
        })
    }, [dispatch, prodUnitLinks])

    if (!isLoaded) {
        return <ProgressCard progress={criteriasProgress} />
    }

    return (
        <div style={{ margin: '0px 10px' }}>
            <ProbAEPCriterias
                onValidate={onValidate}
                defaultFilter={filter}
                operations={operations}
            />
            <div style={{ paddingTop: '10px' }}>
                <AEPTab
                    ids={filteredIds}
                    filter={filter}
                    operations={operations}
                    analysis={analysis}
                    isFirstSearchDone={isFirstSearchDone}
                    dataLoaded={dataLoaded}
                    progress={progress}
                    modelExport={modelExport}
                />
            </div>
        </div>
    )
}

export default ProductionUnitAEPApp