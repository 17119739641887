import { flatten, groupBy, isNil, keys, last, max, maxBy, min, orderBy, sum, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import {
    automaticValues,
    chartLine,
    chartSymbol,
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisIntervalFormatter,
    getChartDate,
    histogramIcon,
    legendSymbol,
    lineIcon,
    logIcon,
    setLogOptions,
    setYOptionsPiezo,
    thresholdIcon,
    toEchartsData,
    yAutomaticScale,
} from '../../../components/echart/EChartUtils'
import DiagnosticActionDto from '../../../events/dto/piezometryDiagnostic/DiagnosticActionDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoMeasureStats from '../../../station/dto/piezometer/DtoMeasureStats'
import DtoPiezometerChartOptions from '../../../station/dto/piezometer/DtoPiezometerChartOptions'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import {
    generateGradient,
    getColorCircleElement,
    getColorCircleEvent,
    getColorFromPalette,
    getEventColor,
    getRGBColor,
} from '../../../utils/ColorUtil'
import { getDateWithHour, getFullDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { hasBooleanValue, hasValue, nFormatter } from '../../../utils/NumberUtil'
import {
    getEventGraph,
    getEventsBar,
    getMeasureValue,
    getMeasureValueFromDepth,
    getPiezometerAdditionalMeasures,
    getPiezometerRawMeasures,
    getStaticDepthEvents,
} from '../../../utils/PiezometryUtils'
import { arrayOf, createIndex, getLabel, getObjectLabel, objectOf } from '../../../utils/StoreUtils'
import { chunkWithWords, getI18nOrLabel } from '../../../utils/StringUtil'
import { MEASURE_COTE } from '../../constants/PiezometryConstants'
import DtoPiezoChartMeasures from '../../dto/chart/DtoPiezoChartMeasures'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import DtoPiezometer from '../../dto/DtoPiezometer'
import DtoIAEauModel from '../../../iaeau/dto/DtoIAEauModel'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../utils/StatusUtil'
import MultiBand from '../../../components/echart/series/MultiBand'
import DtoEvent from '../../../events/dto/DtoEvent'
import Line from '../../../components/echart/series/Line'
import { getMeasureJplus } from '../../../iaeau/utils/IAEauUtils'
import { repeatList } from '../../../utils/ListUtil'
import { calculateValue, getRemarks } from '../../../utils/AnalyseUtils'
import Scatter from '../../../components/echart/series/Scatter'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import { getQualifications, getStatuses, UNQUALIFIED } from '../../../utils/QualityUtils'
import { BAR, LINE } from 'components/echart/QualityChart'

const THRESHOLD = 0
const NOTHING = 2

class PiezometryExploitedChartPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.calculateLastLandmarks(),
            chartType: LINE,
            stack: undefined,
            isLogActive: false,
            displayLegend: true,
            stateThreshold: THRESHOLD,
            displayMarker: false,
            displayLine: true,
        }
    }

    calculateLastLandmarks = () => {
        const piezo = this.props.piezometer
        const lastLandmark = maxBy(piezo.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? piezo.link_altimetrySystems?.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null

        const groundRefALtis = piezo.link_altimetrySystems?.filter(alt => alt.natureCode == 3)
        const groundRefAlti = groundRefALtis?.length ? maxBy(groundRefALtis, 'startDate') : null
        return { lastLandmark: lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null, groundRefAlti: groundRefAlti && groundRefAlti.altitude }
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: params => {
                const testDate = params?.[0]?.value?.[2]?.date ?? params[0].value[0] ?? ''
                const date = getFullDate(moment(testDate))
                const events = this.props.stationEvents.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1' && o.eventType !== 'T')
                const campaigns = events.map(({ campaignCode }) => this.props.piezometryCampaigns.find(({ id }) => id === campaignCode)).filter(c => !!c)
                const labelEvents = events.length ? events.reduce((acc, v) => {
                    const depthMeasure = this.getEventDepthMeasure(v.id)
                    return `${acc}<br />${
                        getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />${
                        hasValue(v.ns) ? `${i18n.staticLevelMeasure} : ${v.ns}m<br />` : ''
                    }${hasValue(v.nc) ? `${i18n.sensorInstantLevel} : ${v.nc}m<br />` : ''
                    }${hasValue(depthMeasure) ? `${i18n.depth} : ${depthMeasure}m<br />` : ''}`
                }, '') : ''
                const labelCampaigns = campaigns.length ? campaigns.reduce((acc, v) => {
                    return `${acc}<br />${v.name || i18n.campaign}`
                }, `<br /><br />${i18n.campaigns}:`) : ''
                const paramsOrder = orderBy(params, [o => o.axisIndex, o => o.data.realValue ?? o.value[1]], ['asc', this.props.displayCote === MEASURE_COTE.NGF ? 'desc' : 'asc']).filter(o => hasValue(o.value[2]) && o.seriesName !== i18n.events).map(o => {
                    return isNil(o.data.realValue ?? o.value[1]) ? {} : {
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.data.realValue ?? o.value[1],
                        unit: o.data.unit ? `[${o.data.unit}]` : '',
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        jPlus: getMeasureJplus(o.data?.value[2]?.measure),
                        isAnalysis: o.value[2]?.isAnalysis,
                        analysis: o.value[2]?.analysis,
                    }
                },
                )
                const result = paramsOrder.filter(objet => Object.keys(objet).length !== 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${o.isAnalysis ? calculateValue(o.analysis) : o.value} ${o.unit} ${o.jPlus}<span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                return `${date} ${labelEvents} <br /> ${labelCampaigns} ${result} `
            },
        }
    }

    getPiezometerOption = (type) => {
        if (type == 0) {
            return this.props.piezometerChartOptions.find(opt => !opt.dataType || parseInt(opt.dataType) <= 0) || {}
        }
        return this.props.piezometerChartOptions.find(opt => type == opt.dataType) || {}
    }

    getEventDepthMeasure = (eventId) => {
        const action = this.props.eventAllPiezometerActions.find(({ solutionCode, eventCode }) => eventCode === eventId && solutionCode === -109)
        if (action) {
            return `${action.value}m`
        }
        return null
    }

    addEvents = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, events) => {
        if (!this.props.hiddenCharts[i18n.events]) {
            const eventOptions = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        const eventsList = events.filter(e => {
                            const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                            const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                            return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                        })
                        const labelEvents = eventsList.reduce((acc, v) => {
                            const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                            return `${acc}<br />${getColorCircleEvent(v.eventType)}${comment}`
                        }, i18n.events)
                        return labelEvents
                    },
                },
                itemStyle: {
                    normal: {
                        opacity: 0.5,
                    },
                },
                yAxisIndex: 1,
            }
            series.push(getEventGraph(events, eventOptions))
            series.push(getEventsBar(events))
            grids.push({
                top: sum(gridsHeights),
                right: '2%',
                height: 40,
                left: 100,
            })
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
            }))
            yAxis.push(Axis({
                type: 'value',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'right',
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
            }))
            yAxis.push(Axis({
                type: 'category',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'left',
            }))
            gridsHeights.push(60)
        }
    }

    getModelSeries = (typeId, gridIndex) => {
        const { lastLandmark, groundRefAlti } = this.state
        const { piezometer, predMeasures, selectedPred, displayCote, iaeauModels } = this.props
        const toEchartMeasure = (v, measure) => ({ value: [measure.horizonMode === 'hours' ? measure.date : moment(measure.date).hour(12).valueOf(), getMeasureValue({ NGF: v }, displayCote, lastLandmark, groundRefAlti), { measure }, piezometer] })
        // const modelSeries = []
        if (selectedPred && selectedPred[typeId] && predMeasures[typeId]?.length) {
            const pred = selectedPred.idModel ? { ...selectedPred[typeId], model: iaeauModels.find(m => m.idModel === selectedPred[typeId].idModel) } : selectedPred[typeId]
            // if (predDisplayMode === 'default') {
            const allSeries = groupBy(predMeasures[typeId], 'serieName')
            const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
            return Object.keys(allSeries).flatMap((serieName, idx) => {
                const orderedMeasures = orderBy(allSeries[serieName], 'date')
                if (hasValue(orderedMeasures[0].doubtMin)) {
                    const lower = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMin, m)),
                        name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                        gridIndex,
                        xAxisIndex: gridIndex,
                        yAxisIndex: gridIndex + 1,
                    }
                    const upper = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMax, m)),
                        name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                        gridIndex,
                        xAxisIndex: gridIndex,
                        yAxisIndex: gridIndex + 1,
                    }
                    return [MultiBand({ bands: displayCote === MEASURE_COTE.NGF ? [lower, upper] : [upper, lower], noSort: true, noGap: true, stack: `${pred.source}${idx}`, areaOpacity: 0.2 })]
                }
                return Line({
                    data: orderedMeasures.map(m => toEchartMeasure(m.value, m)),
                    name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                    connectNulls: true,
                    gridIndex,
                    xAxisIndex: gridIndex,
                    yAxisIndex: gridIndex + 1,
                    lineStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                            width: pred?.model?.lineWidth || 2,
                            type: pred?.model?.lineType || 'dashed',
                            opacity: pred?.model?.lineOpacity || 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                        },
                    },
                    showSymbol: false,
                    isPiezo: true,
                })
            })
            // } else if (predDisplayMode === 'multiple') {
            //     predMultipleDates.forEach((simDate, idx2) => {
            //         const multipleSerie = orderBy(predMultipleResults[idx2].filter(m => hasValue(m.value)), 'date').map(m => ({ value: [m.date, getValue(m.value), m] }))
            //         modelSeries.push(getPiezometerRawMeasures(multipleSerie, piezometer, 1, [], [], {
            //             color: getColorFromPalette(idx2),
            //             name: `${selectedPred.source} du ${getDate(simDate)}`,
            //             lineStyle: {
            //                 type: 'dashed',
            //             },
            //             showSymbol: false,
            //         }, true))
            //     })
            // } else {
            //     const horizonSerie = orderBy(predHorizonResults, 'date').map(m => ({ value: [m.date, getValue(m.value), m] }))
            //     modelSeries.push(getPiezometerRawMeasures(horizonSerie, piezometer, 1, [], [], {
            //         color: '#0000ff',
            //         name: `${selectedPred.source}`,
            //         lineStyle: {
            //             type: 'dashed',
            //         },
            //         showSymbol: false,
            //     }, true))
            // }
        }
        return []
    }

    allMeasuresQualito = (idStat) => {
        const { qualitoMeasures, stats, minDate, maxDate } = this.props
        const dataType = stats.find(t => t.typeId == idStat)
        const allMeasures = keys(qualitoMeasures).map(key => {
            const measure = qualitoMeasures[key]
                .filter(q => q.parameter === dataType.codeParameter)
                .filter(m => m.analysisDate > minDate && m.analysisDate < maxDate)
                .sort((a, b) => a.analysisDate - b.analysisDate)
            return measure
        })
        return flatten(allMeasures).map(m => ({ ...m, value: [m.analysisDate, m.result] }))
    }

    getQualitoMeasures = (idStat, series, grid, color) => {
        const { qualitoMeasures, units, parameters, stats, minDate, maxDate } = this.props
        const dataType = stats.find(t => parseInt(t.typeId) === parseInt(idStat))
        const parameter = parameters.find(p => p.code === dataType?.codeParameter)
        keys(qualitoMeasures).forEach(key => {
            const measure = qualitoMeasures[key]
                .filter(q => q.parameter === dataType.codeParameter)
                .filter(m => m.analysisDate > minDate && m.analysisDate < maxDate)
                .sort((a, b) => a.analysisDate - b.analysisDate)
            if (measure.length) {
                const input = {
                    data: toEchartsData(uniqBy(measure, 'analysisDate').map(m => ({
                        date: m.analysisDate,
                        value: m.result,
                        symbol: getRemarks(m.remark)?.plot,
                        status: m.status,
                        qualification: m.qualification,
                        unit: getLabel(units, m.unit, 'symbol'),
                        isAnalysis: true,
                        analysis: m,
                    })), null, null, null, 10),
                    color: color ?? 'blue',
                    showSymbol: true,
                    name: `${i18n.oneAnalysis} ${i18n.station} [${qualitoMeasures[key][0].qualitometer}] ${parameter.shortLabelWithCode ?? parameter.labelWithCode}`,
                    serieId: `${dataType.label} ${dataType.codeParameter}`,
                    yAxisIndex: grid + 1,
                    xAxisIndex: grid,
                }
                series.push(Scatter(input))
            }
        })
    }

    addMeasures = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, chronicMeasures, additionalMeasures) => {
        if (!this.props.hiddenCharts[i18n.depth]) {
            grids.push({
                top: sum(gridsHeights),
                right: '2%',
                height: 210,
                left: 100,
            })
            const dataType = this.props.piezometerStatistics.find(t => t.typeId === -1)
            const piezoDt = this.props.piezometerStatistics.filter(t => t.isPiezo === true).map(s => s.typeId)
            const bruteColor = dataType?.color || '#222'

            // static level serie (from events)
            const eventsStaticDepth = this.props.stationEvents.filter(e => e.date && e.eventType !== 'T').filter(e => {
                const d = getDateWithHour(e.date, e.eventHour).valueOf()
                return hasValue(e.ns) && (!this.props.maxDate || d <= this.props.maxDate) && d >= this.props.minDate
            }).map(e => ({ value: [getDateWithHour(e.date, e.eventHour).valueOf(), getMeasureValueFromDepth({ value: e.ns }, this.props.displayCote, this.state.lastLandmark, this.state.groundRefAlti)] }))
            if (eventsStaticDepth.length) {
                series.push(getStaticDepthEvents(eventsStaticDepth, grids.length - 1, {}, true))
            }

            // thresholds
            const thresholds = (!this.props.hiddenCharts[i18n.thresholds] && this.state.stateThreshold === THRESHOLD) ? this.props.piezometerThresholds
                .filter(t => hasValue(t.name) && (parseInt(t.dataType) === -1 || piezoDt.includes(parseInt(t.dataType))))
                .map(t => ({
                    ...t,
                    lastLandmark: this.state.lastLandmark,
                    groundRefAlti: this.state.groundRefAlti,
                    displayCote: this.props.displayCote,
                    unit: dataType.unit,
                })) : []
            const otherMarkLines = chartMaxDate > moment().valueOf() ? [{ xAxis: moment().valueOf(), lineStyle: { color: '#000000', opacity: 0.5, type: 'solid', join: 'miter' }, label: { show: false }, symbol: 'none' }] : []

            const thresholdValues = thresholds.map(t => ({ ...t, value: [null, getMeasureValue({ NGF: t.NGF, landmark: t.lastLandmark, refAlti: 0 }, t.displayCote, t.lastLandmark, t.groundRefAlti) || 0] }))

            // piezo data on additionnal measures
            const addPiezoData = additionalMeasures.filter(typeObj => {
                const dt = this.props.piezometryDataTypes.find(t => t.id == typeObj.typeId)
                return dt && dt.id && dt.isPiezo && dt.showData && typeObj.measures.length && dt.id !== -1
            })

            const allpredMeasures = []
            if (chronicMeasures.raw.length) {
                this.getModelSeries(-1, grids.length - 1).forEach(modelSerie => {
                    series.push(modelSerie)
                    allpredMeasures.push(modelSerie.obj.bands ? modelSerie.obj.bands.flatMap(b => b.data) : modelSerie.obj.data)
                })
            }

            const allDatas = automaticValues(eventsStaticDepth, thresholdValues, chronicMeasures.raw, chronicMeasures.max, chronicMeasures.min, chronicMeasures.average, chronicMeasures.personalizedGrouping, ...(addPiezoData.map(t => t.measures)), allpredMeasures.flat(), this.allMeasuresQualito('-1'))
            const yScale = yAutomaticScale(eventsStaticDepth, thresholdValues, chronicMeasures.raw, chronicMeasures.max, chronicMeasures.min, chronicMeasures.average, chronicMeasures.personalizedGrouping, ...(addPiezoData.map(t => t.measures)), allpredMeasures.flat(), this.allMeasuresQualito('-1'))

            const defaultOptions = {
                lineStyle: {
                    type: dataType?.lineType ?? 'solid',
                    opacity: this.state.displayLine ? (dataType?.lineOpacity ?? 1) : 0,
                    width: dataType?.lineWidth ?? 2,
                },
                max: () => {
                    if (this.state.isLogActive) {
                        return undefined
                    }
                    return yScale.max
                },
                min: () => {
                    if (this.state.isLogActive) {
                        return undefined
                    }
                    if (this.state.chartType === BAR || this.state.stack) {
                        return 0
                    }
                    return yScale.min
                },
            }

            // série vide pour les seuils
            series.push(getPiezometerRawMeasures([], this.props.piezometer, grids.length - 1, thresholds, [], {
                color: bruteColor,
                name: i18n.chronic,
                ...defaultOptions,
            }, true, this.state.chartType !== LINE, otherMarkLines))

            if (chronicMeasures.raw.length && chronicMeasures.raw.find(m => chronicMeasures.raw[0].value[2].codepoint !== m.value[2].codepoint)) { // séries par point de prélèvement
                const colors = repeatList(generateGradient(bruteColor, '#FFF', 5), 10)
                const group = groupBy(chronicMeasures.raw, m => m.value[2].codepoint)
                Object.keys(group).forEach((key, idx) => {
                    series.push(getPiezometerRawMeasures(group[key], this.props.piezometer, grids.length - 1, [], [], {
                        color: colors[idx],
                        name: `${i18n.chronic} - ${this.props.piezometer.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`,
                        ...defaultOptions,
                    }, true, this.state.chartType !== LINE))
                })
            } else {
                // série unique si un seul point
                series.push(getPiezometerRawMeasures(chronicMeasures.raw, this.props.piezometer, grids.length - 1, [], [], {
                    color: bruteColor,
                    ...defaultOptions,
                }, true, this.state.chartType !== LINE))
            }

            // other display modes
            if (chronicMeasures.max.length) {
                series.push(getPiezometerRawMeasures(chronicMeasures.max, this.props.piezometer, grids.length - 1, [], [], {
                    color: '#2196F3',
                    name: i18n.max,
                    ...defaultOptions,
                }, true, this.state.chartType !== LINE))
            }
            if (chronicMeasures.min.length) {
                series.push(getPiezometerRawMeasures(chronicMeasures.min, this.props.piezometer, grids.length - 1, [], [], {
                    color: '#F44336',
                    name: i18n.min,
                    ...defaultOptions,
                }, true, this.state.chartType !== LINE))
            }
            if (chronicMeasures.average.length) {
                series.push(getPiezometerRawMeasures(chronicMeasures.average, this.props.piezometer, grids.length - 1, [], [], {
                    color: '#4CAF50',
                    name: i18n.average,
                    ...defaultOptions,
                }, true, this.state.chartType !== LINE))
            }

            if (chronicMeasures.personalizedGrouping.length) {
                series.push(getPiezometerRawMeasures(chronicMeasures.personalizedGrouping, this.props.piezometer, grids.length - 1, [], [], {
                    color: 'orange',
                    name: i18n.personalizedGrouping,
                    ...defaultOptions,
                }, true, this.state.chartType !== LINE))
            }
            this.getQualitoMeasures('-1', series, grids.length - 1, dataType.color)

            addPiezoData.forEach((typeObj, idx) => {
                const dt = this.props.piezometryDataTypes.find(t => t.id == typeObj.typeId)
                series.push(getPiezometerRawMeasures(typeObj.measures, this.props.piezometer, grids.length - 1, [], [], {
                    name: dt.label,
                    color: undefined,
                    lineStyle: {
                        normal: {
                            color: dt.color || getColorFromPalette(idx),
                            width: dt.lineWidth || 2,
                            type: dt.lineType || 'solid',
                            opacity: dt.lineOpacity || 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: dt.color || getColorFromPalette(idx),
                        },
                    },
                }, true))
            })

            // saved chart options (min, max, grid, ...)
            const option = this.getPiezometerOption(-1)
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                gridIndex: grids.length - 1,
                axisLabel: { show: true, formatter: axisLabelObj.formatter },
                axisLine: { show: true },
                axisTick: { show: true },
                showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
            }))

            yAxis.push(Axis({
                type: this.state.isLogActive ? 'log' : 'value',
                nameLocation: 'middle',
                name: `${i18n.chronic} ${dataType?.unit ? `[${dataType?.unit}]` : ''}`,
                gridIndex: grids.length - 1,
                inverse: this.props.displayCote !== MEASURE_COTE.NGF,
                nameGap: 40,
                showSplitLine: true,
                ...yScale,
                ...setYOptionsPiezo(option, -1, yScale, this.props.displayCote, this.state.lastLandmark, this.state.groundRefAlti),
                ...setLogOptions(this.state.isLogActive, allDatas),
            }))
            gridsHeights.push(235)
        }
    }

    addAdditionalMeasures = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, additionalMeasures, chronicMeasures) => {
        const { piezometryDataTypes, hiddenCharts, piezometerThresholds } = this.props
        orderBy(uniqBy(additionalMeasures, 'typeId'), ['order', 'typeId'], ['asc', 'asc']).forEach((typeObj, index) => {
            if (typeObj.typeId === -1) {
                this.addMeasures(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, chronicMeasures, additionalMeasures)
                return
            }
            if (typeObj.measures && !typeObj.measures.length) {
                return
            }
            const dataType = piezometryDataTypes.find(t => t.id == typeObj.typeId)
            if (dataType?.isPiezo) {
                return
            }
            const option = dataType && dataType.id ? this.getPiezometerOption(dataType.id) : {}
            if (dataType && !hiddenCharts[dataType.label] && (!hasBooleanValue(option.displayData) || !!option.displayData)) {
                const thresholds = (!hiddenCharts[i18n.thresholds] ? piezometerThresholds.filter(t => t.dataType == dataType.id) : []).map(t => ({ ...t, unit: dataType.unit }))
                const thresholdValues = thresholds.map(t => ({ value: [null, t.value ] }))
                grids.push({
                    top: sum(gridsHeights),
                    right: '2%',
                    height: 150,
                    left: 100,
                })

                const pushSerie = (measures, label, thre, color) => {
                    series.push(getPiezometerAdditionalMeasures(measures, label, undefined, typeObj.typeId, grids.length - 1, thre, {
                        lineStyle: {
                            normal: {
                                color,
                                width: dataType.lineWidth || 2,
                                type: dataType.lineType || 'solid',
                                opacity: dataType.lineOpacity || 1,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color,
                            },
                        },
                    }, true, dataType.id === -2))
                }
                this.getQualitoMeasures(typeObj.typeId, series, grids.length - 1, dataType.color)
                const dataColor = dataType.color || getColorFromPalette(index)
                // série vide pour les seuils
                pushSerie([], dataType.label, thresholds, dataColor)
                if (typeObj.measures.length && typeObj.measures.find(m => typeObj.measures[0].value[2].codepoint !== m.value[2].codepoint)) { // séries par point de prélèvement
                    const colors = repeatList(generateGradient(dataColor, '#FFF', 5), 10)
                    const group = groupBy(typeObj.measures, m => m.value[2].codepoint)
                    Object.keys(group).forEach((key, idx) => {
                        pushSerie(group[key], `${dataType.label} - ${this.props.piezometer.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`, [], colors[idx])
                    })
                } else {
                    pushSerie(typeObj.measures, dataType.label, [], dataColor) // série unique si un seul point
                }

                const allpredMeasures = []
                this.getModelSeries(typeObj.typeId, grids.length - 1).forEach(modelSerie => {
                    series.push(modelSerie)
                    allpredMeasures.push(modelSerie.obj.bands ? modelSerie.obj.bands.flatMap(b => b.data) : modelSerie.obj.data)
                })

                xAxis.push(Axis({
                    type: 'time',
                    position: 'bottom',
                    min: chartMinDate,
                    max: chartMaxDate,
                    gridIndex: grids.length - 1,
                    interval: axisLabelObj.interval,
                    axisLabel: { show: true, formatter: axisLabelObj.formatter },
                    axisLine: { show: true },
                    axisTick: { show: true },
                    showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
                }))
                const yScale = yAutomaticScale(typeObj.measures, thresholdValues, allpredMeasures.flat(), this.allMeasuresQualito(typeObj.typeId))
                const unit = dataType.unit
                const y = Axis(Object.assign({}, {
                    type: 'value',
                    nameLocation: 'middle',
                    name: `${dataType.label} ${unit ? `[${unit}]` : ''}`,
                    gridIndex: grids.length - 1,
                    splitNumber: 4,
                    axisLabel: { formatter: nFormatter },
                    nameGap: 40,
                    showSplitLine: option && hasValue(option.displayYIntervalYear) ? option.displayYIntervalYear === '1' : true,
                }, setYOptionsPiezo(option, dataType.id, yScale, null, null, null, dataType.id === -2 ? 0 : null)))
                yAxis.push(y)
                gridsHeights.push(180)
            }
        })
    }

    getMinDate = () => {
        if (this.props.minDate) {
            return this.props.minDate
        }
        return min([ ...this.props.piezometerStatistics.map(s => s.startDate), moment().valueOf()])
    }

    getExportData = (chronicMeasures, additionalMeasures) => {
        const isMultiPoint = this.props.piezometerStatistics.some(stat => this.props.piezometerStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint))
        const chronicDt = this.props.piezometerStatistics.find(dt => dt.typeId === -1)
        const series = [
            { name: `${chronicDt?.label ?? i18n.depth} (${i18n.max})`, data: chronicMeasures.max },
            { name: `${chronicDt?.label ?? i18n.depth} (${i18n.min})`, data: chronicMeasures.min },
            { name: `${chronicDt?.label ?? i18n.depth} (${i18n.average})`, data: chronicMeasures.average },
            { name: `${chronicDt?.label ?? i18n.depth} (${i18n.personalizedGrouping})`, data: chronicMeasures.personalizedGrouping },
        ]
        additionalMeasures.forEach((typeObj) => {
            series.push({ data: typeObj.measures, name: isMultiPoint ? `${typeObj.label} - ${this.props.piezometer.link_pointPrels.find(p => p.point === typeObj.codepoint)?.name ?? ''}` : typeObj.label })
        })
        if (chronicMeasures.raw.length) {
            this.getModelSeries().forEach(modelSerie => {
                series.push(modelSerie.obj)
            })
        }

        const statusIdx = createIndex(getStatuses(), 'code')
        const qualifIdx = createIndex(getQualifications(), 'code')
        const data = series.flatMap(chart => chart.data.map(d => ({
            stationCode: { value: this.props.piezometer.code },
            stationName: { value: this.props.piezometer.name },
            date: { value: getFullDate(d.value[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: d.value[1], format: '0.000', cellType: 'number' },
            type: { value: chart.name },
            unit: { value: d.unit },
            status: { value: statusIdx[d.value[2].status ?? 1]?.name },
            qualification: { value: qualifIdx[d.value[2].qualification ?? UNQUALIFIED]?.name },
            initialPoint: { value: d.value[2].initialPoint === 1 ? i18n.initialPoint : '' },
        })))

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'unit', 'status', 'qualification', 'initialPoint']
        }
        return data
    }

    addSymbolMeasures = measures => measures.map(m => ({
        ...m,
        symbol: this.state.displayMarker ? 'circle' : 'none',
    }))

    getMeasures = (typeId, group) => {
        const dataType = this.props.piezometerStatistics.find(t => t.typeId === typeId)
        if (group === 'CUSTOM') {
            const found = group ? this.props.chartMeasures.find(m => m.dataType === typeId && m.groupFunc.startsWith('CUMUL_PERSO')) : this.props.chartMeasures.find(m => m.dataType === typeId)
            return found ? toEchartsData(this.addSymbolMeasures(found.measures), null, null, dataType?.unit ?? '', this.state.displayMarker ? 6 : 0) : []
        }
        const found = group ? this.props.chartMeasures.find(m => m.dataType === typeId && m.groupFunc === group) : this.props.chartMeasures.find(m => m.dataType === typeId)
        return found ? toEchartsData(this.addSymbolMeasures(found.measures), null, null, dataType?.unit ?? '', this.state.displayMarker ? 6 : 0) : []
    }

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    getLegend = () => ({
        top: 20,
        left: '5%',
        right: `${30 * 8 + 50}px`,
        type: 'scroll',
        show: this.state.displayLegend,
    })

    getToolbox = (chronicMeasures, additionalMeasures) => {
        const {
            chartType,
            stack,
            isLogActive,
            displayLegend,
            stateThreshold,
            displayMarker,
            displayLine,
        } = this.state

        const {
            piezometer,
            citiesIndex,
            isSubComponent,
            hiddenCharts,
            piezometerThresholds,
        } = this.props

        const stationTitle = `[${piezometer.code}] ${getObjectLabel(citiesIndex[piezometer.townCode])} - ${piezometer.name}`

        const fullScreenFeature = isSubComponent ? {
            myToolFullScreen: {
                show: true,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: () => this.props.onChangeFullScreen(),
            },
        } : {}

        return {
            top: '25px',
            right: '35px',
            showTitle: false,
            itemSize: 18,
            tooltip: {
                show: true,
                position: 'bottom',
            },
            feature: {
                myToolLine: {
                    show: true,
                    title: i18n.lines,
                    icon: lineIcon,
                    onclick: () => this.setState({ stack: undefined, chartType: LINE }),
                    iconStyle: {
                        borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolBar: {
                    show: true,
                    title: i18n.histogram,
                    icon: histogramIcon,
                    onclick: () => this.setState({ stack: undefined, chartType: BAR, isLogActive: false }),
                    iconStyle: {
                        borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolLog: {
                    show: true,
                    title: i18n.logarithm,
                    icon: logIcon,
                    onclick: () => this.setState(prev => ({ stack: undefined, chartType: LINE, isLogActive: !prev.isLogActive })),
                    iconStyle: {
                        borderColor: isLogActive ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolThreshold: {
                    show: !hiddenCharts[i18n.thresholds] && !!piezometerThresholds.length,
                    title: i18n.toggleThreshold,
                    icon: thresholdIcon,
                    onclick: () => this.setState(prev => ({ stateThreshold: prev.stateThreshold === THRESHOLD ? NOTHING : THRESHOLD })),
                    iconStyle: {
                        borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleLegend: {
                    show: true,
                    title: i18n.toggleLegend,
                    icon: legendSymbol,
                    onclick: () => this.setState(prev => ({ displayLegend: !prev.displayLegend })),
                    iconStyle: {
                        borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleMarker: {
                    show: true,
                    title: i18n.toggleMarker,
                    icon: chartSymbol,
                    onclick: () => this.setState(prev => ({ displayMarker: !prev.displayMarker })),
                    iconStyle: {
                        borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleLine: {
                    show: true,
                    title: i18n.toggleLine,
                    icon: chartLine,
                    onclick: () => this.setState(prev => ({ displayLine: !prev.displayLine })),
                    iconStyle: {
                        borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                    },
                },
                saveAsImage: {
                    show: true,
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                    name: `${i18n.graph} ${stationTitle}`,
                },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: this.getExportData(chronicMeasures, additionalMeasures),
                            exportType: 'xlsx',
                            titleFile: `${i18n.overview} ${stationTitle}`,
                        })
                    },
                },
                ...fullScreenFeature,
                restore: {
                    show: true,
                    title: i18n.restore,
                },
            },
        }
    }

    render() {
        // get minDate of all data
        const { chartTab } = this.state
        const { maxDate } = this.props
        const { minDate: formattedMinDate, maxDate: formattedMaxDate } = getChartDate(this.getMinDate(), maxDate, chartTab)
        const chartMinDate = formattedMinDate || this.getMinDate()

        const predMeasuresMaxDate = Object.keys(this.props.predMeasures ?? {}).length ?
            [moment(max(Object.keys(this.props.predMeasures ?? {}).map(key => last(orderBy(this.props.predMeasures[key], 'date')).date))).add(2, 'day').valueOf()] : []
        const chartMaxDate = max([formattedMaxDate || this.props.maxDate || moment().valueOf(), ...predMeasuresMaxDate])

        // transform data to echarts format
        const modes = this.props.displayModes

        const chronicMeasures = {
            raw: modes.brute || modes.auto ? this.getMeasures(-1) : [],
            min: modes.min ? this.getMeasures(-1, 'MIN') : [],
            max: modes.max ? this.getMeasures(-1, 'MAX') : [],
            average: modes.average ? this.getMeasures(-1, 'AVERAGE') : [],
            personalizedGrouping: modes.personalizedGrouping ? this.getMeasures(-1, 'CUSTOM') : [],
        }

        const additionalMeasures = this.props.piezometerStatistics.filter(s => s.typeId >= -2 && (!hasBooleanValue(s.showData) || s.showData)).map(typeObj => ({ ...typeObj, measures: this.getMeasures(typeObj.typeId) }))

        // get events
        const events = this.props.stationEvents.filter(e => {
            if (e.eventType === 'T') {
                return false
            }
            if (e.date) {
                const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
                return eventDate >= chartMinDate && eventDate <= chartMaxDate && e.graph == '1'
            }
            return false
        })

        const series = []
        const grids = []
        const xAxis = []
        const yAxis = []
        const gridsHeights = [50]

        // construct echart options
        const axisLabelObj = getAxisIntervalFormatter(moment(formattedMaxDate), moment(formattedMinDate))
        this.addEvents(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, events)
        this.addAdditionalMeasures(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj, additionalMeasures, chronicMeasures)

        xAxis[xAxis.length - 1].obj = Object.assign({}, xAxis[xAxis.length - 1].obj, {
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
        })

        const options = {
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: true,
            height: sum(gridsHeights) - 25,
            toolbox: this.getToolbox(chronicMeasures, additionalMeasures),
            legend: this.getLegend(),
        }
        return (
            <EChart
                id='piezometryDashboardChart'
                options={options}
                scrollable={this.props.fullScreen}
            />
        )
    }
}

PiezometryExploitedChartPanel.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    chartMeasures: arrayOf(DtoPiezoChartMeasures),
    piezometerStatistics: arrayOf(DtoMeasureStats),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    setPiezometryEditActions: PropTypes.func,
    fullScreen: PropTypes.bool,
    editMode: PropTypes.bool,
    selectedSerie: PropTypes.string,
    onChangeFullScreen: PropTypes.func,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    piezometerChartOptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartOptions)),
    hiddenCharts: PropTypes.shape({}),
    isSubComponent: PropTypes.bool,
    displayCote: PropTypes.number,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    displayModes: PropTypes.objectOf(PropTypes.bool),
    citiesIndex: objectOf(CityDto),
    eventAllPiezometerActions: arrayOf(DiagnosticActionDto),
    predMeasures: PropTypes.arrayOf(PropTypes.shape({})),
    selectedPred: DtoIAEauModel,
    stationEvents: arrayOf(DtoEvent),
    predStats: PropTypes.arrayOf(PropTypes.shape({})),
    predDisplayMode: PropTypes.string,
    predMultipleDates: PropTypes.arrayOf(PropTypes.number),
    predMultipleResults: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    predHorizonResults: PropTypes.arrayOf(PropTypes.shape({})),
    iaeauModels: arrayOf(DtoIAEauModel),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    stats: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    qualitoMeasures: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = store => ({
    chartMeasures: store.PiezometerStationReducer.chartMeasures,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometer: store.StationReducer.piezometer,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    citiesIndex: store.CityReducer.citiesIndex,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    piezometerChartOptions: store.PiezometerStationReducer.piezometerChartOptions,
    eventAllPiezometerActions: store.EventsReducer.eventAllPiezometerActions,
    stationEvents: store.EventsReducer.stationEvents,
    iaeauModels: store.IAEauReducer.iaeauModels,
    parameters: store.ParameterReducer.parameters,
    units: store.UnitReducer.units,
    associatedSites: store.StationReducer.associatedSites,

})

export default connect(mapStateToProps)(PiezometryExploitedChartPanel)
