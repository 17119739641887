import DtoFile from 'components/file/dto/DtoFile'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ConfirmModal from 'components/modal/ConfirmModal'
import { push } from '@lagunovsky/redux-react-router'
import { isNil, isUndefined, maxBy, orderBy, template, uniq, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import Other from '../../../../../components/actions/Other'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import { getDate, getFullDate, getHour } from '../../../../../utils/DateUtil'
import { onChangeHour, setConfirmationModal } from '../../../../../utils/FormUtils'
import OperationAction from '../../actions/OperationAction'
import DtoSample from '../../../../dto/DtoSample'
import { getQualifications, getStatuses, UNQUALIFIED } from '../../../../../utils/QualityUtils'
import DtoSampling from '../../dto/DtoSampling'
import SamplingModal from './SamplingModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import OperationNetworkModal from './OperationNetworkModal'
import NetworkAction from '../../../../../referencial/components/network/actions/NetworkAction'
import { CardTable } from '../../../../../components/datatable/NewTable'
import { Box, Card, CardContent, Grid2 } from '@mui/material'
import { SamplePointIconInfo } from 'station/components/link/QualitySamplePointsPanel'

const OperationDescriptionPanel = ({
    id,
    code = '',
    uploadFile = () => { },
    files = [],
    getOperationFiles = () => {},
    sample,
    reloadOperation,
    changeParent = () => {},
    samplings,
    networks,
}) => {
    const dispatch = useDispatch()

    const {
        operation,
        operationAnalysis,
        qualitometer,
        qualityCampaignStations,
        qualityCampaigns,
        supports,
        methods,
        contacts,
        contributors,
        accountUser,
        referentialNetworks,
    } = useSelector(store => ({
        operation: store.OperationReducer.operation,
        operationAnalysis: store.OperationReducer.operationAnalysis,
        qualitometer: store.QualityReducer.qualitometer,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        supports: store.SupportReducer.supports,
        methods: store.MethodReducer.methods,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
        referentialNetworks: store.NetworkReducer.networks,
    }), shallowEqual)

    const sandreConservationMode = useSandreList(SANDRE.CONSERVATION_MODE)
    const sandreParticularSituation = useSandreList(SANDRE.PARTICULAR_SITUATION)
    const sandreSampleSpeed = useSandreList(SANDRE.SAMPLE_SPEED)
    const localizations = useSandreList(SANDRE.ANALYSES_LIEU)

    const [editMode, setEditMode] = useState(code === 'new')
    const [isOpen, setIsOpen] = useState(false)
    const [samplingPopupIsOpen, setSamplingPopupIsOpen] = useState(false)
    const [selectedSampling, setSelectedSampling] = useState({})

    const [networkPopupIsOpen, setNetworkPopupIsOpen] = useState(false)
    const [selectedNetwork, setSelectedNetwork] = useState(undefined)

    useEffect(() => {
        if (!referentialNetworks.length) {
            dispatch(NetworkAction.fetchNetworks())
        }
    }, [])


    const [op, setOp] = useState({
        ...operation,
        id: operation.id || 0, qualitometer: parseInt(id),
        point: (operation.point ?? qualitometer.link_samplePoints.find(p => p.identifiant === '031')?.idPoint) ?? -1,
    })
    const [samp, setSamp] = useState({ ...sample, id: sample.id || 0, qualitometer: parseInt(id) })

    const changeOp = changes => setOp(prev => ({ ...prev, ...changes }))
    const changeSamp = changes => setSamp(prev => ({ ...prev, ...changes }))

    const updateSamplings = newSamplings => {
        OperationAction.promiseUpdateSampleSamplings(parseInt(id), sample.id, newSamplings).then(() => {
            changeParent({ samplings: newSamplings })
            setSamplingPopupIsOpen(false)
        })
    }

    const updateNetworks = newNetworks => {
        OperationAction.promiseUpdateOperationNetworks(parseInt(id), operation.id, newNetworks).then(() => {
            changeParent({ networks: newNetworks })
            setNetworkPopupIsOpen(false)
        })
    }

    const filteredPoints = useMemo(() => {
        return qualitometer.link_samplePoints
            .filter(p => isNil(op.support) || isNil(p.codeSupport) || p.codeSupport === op.support)
            .filter(p => isNil(p.startDate) || p.startDate <= op.date)
            .filter(p => isNil(p.endtDate) || p.endtDate >= op.date)
    }, [op.date, op.support, qualitometer.link_samplePoints])

    useActions(() => {
        if (editMode) {
            return {
                save: () => {
                    if (isUndefined(op.point)) {
                        dispatch(ToastrAction.warning('Veillez sélection un point de prélévement'))
                        return
                    }
                    if (code === 'new') {
                        OperationAction.createOperation(id, op, samp).then(json => {
                            dispatch(push(`/station/quality/${id}/operation/${json.id}`))
                            reloadOperation()
                        })
                    } else {
                        setIsOpen(true)
                    }
                },
                cancel: () => {
                    if (code === 'new') {
                        dispatch(push(`/station/quality/${id}/operation`))
                    } else {
                        setOp(operation)
                        setSamp(sample)
                        setEditMode(false)
                    }
                },
                clear: () => {
                    setOp({})
                    setSamp({})
                },
                importFile: {
                    onClick: uploadFile,
                    format: '',
                    tooltip: i18n.importAnalysisFile,
                },
            }
        }
        const editAction = accountUser.consultant === '1' ? {} : {
            edit: () => {
                setEditMode(true)
            },
        }
        const importFileAction = files.length ? {
            other: {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={getOperationFiles}
                    />
                ),
            },
        } : {}
        return {
            ...editAction,
            ...importFileAction,
            delete: () => {
                OperationAction.deleteOperation(id, operation, sample).then(() => dispatch(push(`/station/quality/${id}/operation`)))
            },
            importFile: {
                onClick: uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
        }
    }, [op, samp, editMode])

    const campaigns = useMemo(() => {
        const stationId = parseInt(id)
        const campaignCodes = qualityCampaignStations.filter(cs => cs.stationId === stationId).map(cs => cs.campaignId)
        return uniq([...campaignCodes, op.campaign]).map(cCode => qualityCampaigns.find(c => c.id === cCode)).filter(c => !!c)
    }, [op.campaign, id, qualityCampaignStations, qualityCampaigns])

    useEffect(() => {
        if (filteredPoints.some(p => p.idPoint === op.point)) return

        if (filteredPoints.length === 1) {
            changeOp({ point: filteredPoints[0].idPoint })
            return
        }

        changeOp({ point: undefined })
    }, [filteredPoints, op.point])

    const formattedPoints = useMemo(() => {
        return filteredPoints.map(p => {
            const support = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (support?.name ?? 'Eau')} [${p.identifiant || (support?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [filteredPoints, supports])

    const orderedSamplings = orderBy(samplings, 'idSampling')
    const formattedSamplings = orderedSamplings.map((s, idx) => {
        return {
            sampling: s,
            date: { value: getFullDate(s.date) },
            arrivalDateLabo: { value: getFullDate(s.arrivalDate) },
            reference: { value: s.ref },
            refLabo: { value: s.refLabo },
            idx,
        }
    })

    const formattednetworks = networks.map((nSandreCode, idx) => {
        const found = referentialNetworks.find(n => n.sandreCode === nSandreCode)
        return {
            name: { value: found?.name },
            code: { value: found?.sandreCode },
            mnemonic: { value: found?.mnemonic },
            network: nSandreCode,
            idx,
        }
    })

    return (
        <Box sx={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto', padding: '10 0' }}>
            <Grid2 container spacing={1} alignItems='center'>
                <Grid2 size={3}>
                    <SimpleDatePicker
                        value={op.date}
                        id='startDate'
                        label={i18n.beginDate}
                        onChange={v => {
                            changeOp({ date: v })
                            changeSamp({ date: v })
                        }}
                        disabled={!editMode}
                        active={editMode}
                        obligatory
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Input
                        title={i18n.startHour}
                        value={getHour(op.date)}
                        onChange={v => onChangeHour(v, v2 => changeOp({ date: v2 }), {}, op.date)}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    {
                        !!operation.updateDate && (
                            <Card>
                                <CardContent>
                                    <span>
                                        {`${i18n.lastUpdateOn} ${getDate(operation.updateDate, 'DD/MM/YYYY  à HH:mm')} ${operation.updateLogin ? `${i18n.by} ${operation.updateLogin}` : ''}`}
                                    </span>
                                </CardContent>
                            </Card>
                        )
                    }
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        options={campaigns}
                        label={i18n.campaign}
                        value={op.campaign}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ campaign: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <Input
                        title={i18n.jobExecutionId}
                        value={op.jobExecutionId}
                        disabled={true}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={getStatuses()}
                        label={i18n.status}
                        value={op.status}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ status: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={getQualifications()}
                        label={i18n.qualification}
                        value={op.qualification || UNQUALIFIED}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ qualification: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        label={i18n.accreditation}
                        checked={op.accreditation === '1'}
                        onChange={v => {
                            changeOp({ accreditation: v ? '1' : '0' })
                            changeSamp({ accreditation: v ? '1' : '0' })
                        }}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        label={i18n.authorizedBanking}
                        checked={op.authorizationBanking === '1'}
                        onChange={v => changeOp({ authorizationBanking: v ? '1' : '0' })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 container size={3} spacing={0.2}>
                    <Grid2 size='grow'>
                        <Select
                            options={formattedPoints}
                            label={i18n.samplePoint}
                            value={op.point}
                            nullLabel='&nbsp;'
                            keyValue='idPoint'
                            onChange={v => changeOp({ point: v })}
                            disabled={!editMode}
                            active={editMode}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size='auto' paddingTop={'13px'}>
                        <SamplePointIconInfo samplePoint={qualitometer.link_samplePoints.find(sp => sp.idPoint === op.point)}/>
                    </Grid2>
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        label={i18n.support}
                        options={supports}
                        value={op.support}
                        onChange={v => changeOp({ support: v ? parseInt(v) : null })}
                        disabled={!editMode}
                        integerValue
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={6} />
                <Grid2 size={3}>
                    <Input
                        title={i18n.analysisNumber}
                        value={samp.numLabo}
                        onChange={v => changeSamp({ numLabo: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Input
                        title={i18n.refLabo}
                        value={samp.refLabo}
                        onChange={v => changeSamp({ refLabo: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        label={i18n.location}
                        value={op.localization}
                        options={localizations}
                        onChange={v => {
                            changeOp({ localization: v ? `${v}` : null })
                            changeSamp({ localization: v ? `${v}` : null })
                        }}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <NumberField
                        title={`${i18n.length} (m)`}
                        value={op.length}
                        floatValue
                        onChange={v => changeOp({ length: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <NumberField
                        title={i18n.averageWidth}
                        value={op.mediumWidth}
                        floatValue
                        onChange={v => changeOp({ mediumWidth: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={sandreConservationMode}
                        label={i18n.principalConservationMode}
                        value={op.methodOfPrincipalConservation}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ methodOfPrincipalConservation: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={sandreConservationMode}
                        label={i18n.secondConservationMode}
                        value={op.methodOfSecondConservation}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ methodOfSecondConservation: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Textarea
                        title={`${i18n.comment} ${i18n.operation}`}
                        value={op.comment}
                        onChange={v => changeOp({ comment: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Textarea
                        title={`${i18n.comment} ${i18n.sample}`}
                        value={samp.comment}
                        onChange={v => changeSamp({ comment: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Textarea
                        title={i18n.interpretation}
                        value={op.interpretation}
                        onChange={v => changeOp({ interpretation: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={sandreParticularSituation}
                        label={i18n.particularSituation}
                        value={op.particularSituation}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ particularSituation: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <NumberField
                        title={`${i18n.wetArea} (m2)`}
                        value={op.wetArea}
                        floatValue
                        onChange={v => changeOp({ wetArea: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        options={sandreSampleSpeed}
                        label={i18n.speed}
                        value={op.speed}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ speed: v ? `${v}` : null })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        label={i18n.continue}
                        checked={op.continu === '1'}
                        onChange={v => changeOp({ continu: v ? '1' : '0' })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <fieldset>
                        <legend>&nbsp;{i18n.producer}&nbsp;</legend>
                        <Grid2 container spacing={1}>
                            <Grid2 size={8}>
                                <Select
                                    options={contributors}
                                    label={i18n.producer}
                                    value={op.producer}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    onChange={v => {
                                        changeOp({ producer: v })
                                        changeSamp({ producer: v })
                                    }}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    options={contacts}
                                    label={i18n.contact}
                                    value={op.producerContact}
                                    nullLabel='&nbsp;'
                                    displayWithCode
                                    onChange={v => changeOp({ producerContact: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.producerRef}
                                    value={samp.producerRef}
                                    onChange={v => changeSamp({ producerRef: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                        </Grid2>
                    </fieldset>
                </Grid2>
                <Grid2 size={12}>
                    <fieldset>
                        <legend>&nbsp;{i18n.laboratory}&nbsp;</legend>
                        <Grid2 container spacing={1}>
                            <Grid2 size={8}>
                                <Select
                                    options={contributors}
                                    label={i18n.laboratory}
                                    value={samp.labo}
                                    nullLabel='&nbsp;'
                                    displayWithCode
                                    keyLabel='labelDisplay'
                                    onChange={v => changeSamp({ labo: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                        </Grid2>
                    </fieldset>
                </Grid2>
                <Grid2 size={12}>
                    <fieldset>
                        <legend>&nbsp;{i18n.sampler}&nbsp;</legend>
                        <Grid2 container spacing={1}>
                            <Grid2 size={8}>
                                <Select
                                    options={contributors}
                                    label={i18n.sampler}
                                    value={op.sampler}
                                    nullLabel='&nbsp;'
                                    displayWithCode
                                    keyLabel='labelDisplay'
                                    onChange={v => changeOp({ sampler: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    options={contacts}
                                    label={i18n.contact}
                                    value={op.samplerContact}
                                    nullLabel='&nbsp;'
                                    displayWithCode
                                    onChange={v => changeOp({ samplerContact: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                        </Grid2>
                    </fieldset>
                </Grid2>
                <Grid2 size={12}>
                    <fieldset>
                        <legend>&nbsp;{i18n.determiner}&nbsp;</legend>
                        <Grid2 container spacing={1}>
                            <Grid2 size={8}>
                                <Select
                                    options={contributors}
                                    label={i18n.determiner}
                                    value={op.determiner}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    onChange={v => changeOp({ determiner: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    options={contacts}
                                    label={i18n.contact}
                                    value={op.determinerContact}
                                    nullLabel='&nbsp;'
                                    displayWithCode
                                    onChange={v => changeOp({ determinerContact: v })}

                                    disabled={!editMode}
                                    active={editMode}
                                />
                            </Grid2>
                        </Grid2>
                    </fieldset>
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        options={methods}
                        label={i18n.protocol}
                        value={op.method}
                        nullLabel='&nbsp;'
                        onChange={v => changeOp({ method: v })}
                        disabled={!editMode}
                        integerValue
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={6} />
                <Grid2 size={6}>
                    <Textarea
                        title={i18n.resultComment}
                        value={op.resultComment}
                        onChange={v => changeOp({ resultComment: v })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size='auto'>
                    <Checkbox
                        label={i18n.microSampling}
                        checked={op.microSampling === '1'}
                        onChange={v => changeOp({ microSampling: v ? '1' : '0' })}
                        disabled={!editMode}
                        active={editMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.samplings}
                        rows={formattedSamplings}
                        headers={['reference', 'refLabo', 'date', 'arrivalDateLabo']}

                        lineActions={[
                            {
                                icon: 'edit', onClick: (v) => {
                                    setSelectedSampling(orderedSamplings[v.idx])
                                    setSamplingPopupIsOpen(true)
                                }, displayed: editMode,
                            },
                            {
                                icon: 'delete',
                                onClick: (v) => setConfirmationModal(() => updateSamplings(samplings.filter(s => s.idSampling !== v.sampling.idSampling))),
                                displayed: editMode,
                            },
                        ]}
                        actions={[{
                            icon: 'note_add',
                            onClick: () => {
                                setSelectedSampling({
                                    idSampling: (maxBy(samplings, 'idSampling')?.idSampling ?? 0) + 1,
                                    qualitometer: parseInt(id),
                                    idSample: sample.id,
                                })
                                setSamplingPopupIsOpen(true)
                            },
                            displayed: editMode,
                        }]}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.networks}
                        rows={formattednetworks}
                        headers={['code', 'name', 'mnemonic']}

                        lineActions={[
                            {
                                icon: 'edit', onClick: (v) => {
                                    setSelectedNetwork(v)
                                    setNetworkPopupIsOpen(true)
                                }, displayed: editMode,
                            },
                            {
                                icon: 'delete',
                                onClick: (v) => setConfirmationModal(() => updateNetworks(networks.filter(s => s !== v.network))),
                                displayed: editMode,
                            },
                        ]}
                        actions={[{
                            icon: 'note_add',
                            onClick: () => {
                                setSelectedNetwork(undefined)
                                setNetworkPopupIsOpen(true)
                            },
                            displayed: editMode,
                        }]}
                    />
                </Grid2>
            </Grid2>
            <ConfirmModal
                isOpen={isOpen}
                subTitle={template(i18n.thisModificationWillUpdateThsSampleAndTheAnalyzesAttachedToId)({ nbAnalysis: operationAnalysis.length })}
                onValidate={() => {
                    OperationAction.updateOperation(op, samp).then(() => {
                        setIsOpen(false)
                        reloadOperation()
                        setEditMode(false)
                    })
                }}
                onClose={() => setIsOpen(false)}
            />
            {samplingPopupIsOpen ? (
                <SamplingModal
                    onClose={() => setSamplingPopupIsOpen(false)}
                    isOpen={samplingPopupIsOpen}
                    onValidate={newSamp => updateSamplings(uniqBy([newSamp, ...samplings], 'idSampling'))}
                    selectedSampling={selectedSampling}
                />
            ) : null}
            {networkPopupIsOpen ? (
                <OperationNetworkModal
                    onClose={() => setNetworkPopupIsOpen(false)}
                    isOpen={networkPopupIsOpen}
                    onValidate={newNet => {
                        const updated = networks.slice()
                        updated[selectedNetwork?.idx ?? networks.length] = newNet
                        updateNetworks(updated)
                    }}
                    selectedNetwork={selectedNetwork?.network}
                    networks={referentialNetworks}
                />
            ) : null}
        </Box>
    )
}

OperationDescriptionPanel.propTypes = {
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    uploadFile: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    getOperationFiles: PropTypes.func,
    sample: PropTypes.instanceOf(DtoSample),
    reloadOperation: PropTypes.func,
    samplings: PropTypes.arrayOf(PropTypes.instanceOf(DtoSampling)),
    networks: PropTypes.arrayOf(PropTypes.string),
    changeParent: PropTypes.func,
}

export default OperationDescriptionPanel