module.exports = {

    DISPLAY_COTE: 'displayCote',
    CHRONIC_TYPE: 'chronicType',
    // Cumul Perso
    CUMUL_PERSO: 'CUMUL_PERSO',
    CUMUL_PERSO_MAX: 'CUMUL_PERSO_MAX',
    CUMUL_PERSO_MIN: 'CUMUL_PERSO_MIN',
    CUMUL_PERSO_SUM: 'CUMUL_PERSO_SUM',
    CUMUL_PERSO_AVERAGE: 'CUMUL_PERSO_AVERAGE',
    PERSONALIZED_GROUPING: 'personalizedGrouping',

    SUM_HOUR: 'SUM_HOUR',
    SUM_DAY: 'SUM_DAY',
    SUM_WEEK: 'SUM_WEEK',
    SUM_DECADE: 'SUM_DECADE',
    SUM_MONTH: 'SUM_MONTH',
    SUM_YEAR: 'SUM_YEAR',

    // Regroupement
    AUTO: 'auto',
    ALL: 'all',
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
    BRUTE: 'BRUTE',
    SUM: 'SUM',
    RAW: 'RAW',
    OTHER: 'OTHER',

    DATE: 'date',
    HOUR: 'hour',

    YEAR: 'year',
    HISTORIC: 'historic',
    HORIZON: 'horizon',

    QUALIFICATION: 'qualification',
    STATUS: 'status',
}
