import React, { useState } from 'react'
import { DefaultDialogTitle } from '../../../../components/styled/Dialog'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Input from '../../../../components/forms/Input'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import i18n from 'simple-react-i18n'
import FilterTypeStations from './FilterTypeStations'
import { isNil } from 'lodash'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import { MONITORING_OPTIONS } from '../../../constants/AlertConstants'
import PropTypes from 'prop-types'
import AdministrationAction from '../../../../administration/actions/AdministrationAction'
import GlobalParametersDto from '../../../../administration/dto/GlobalParameterDto'
import { useDispatch } from 'react-redux'

const DialogFilterAlertMap = ({
    isOpen,
    setIsOpen,
    date,
    displayStation,
    hiddenNoThresholdStations,
    searchValue,
    selectedStationType,
    monitoringType,
}) => {
    const dispatch = useDispatch()

    const [nameFilter, setNameFilter] = useState()

    const monitoringOptions = [{
        id: MONITORING_OPTIONS.ALL_MONITORING,
        name: i18n.allMonitoring,
    }, {
        id: MONITORING_OPTIONS.SITUATION_FLOOD,
        name: i18n.situationFlood,
    }, {
        id: MONITORING_OPTIONS.LOWWATER,
        name: i18n.lowWater,
    }]

    const onSave = () => {
        const json = JSON.stringify({
            name: nameFilter,
            date,
            searchValue,
            selectedStationType,
            displayPiezo: displayStation.displayPiezo,
            displayPluvio: displayStation.displayPluvio,
            displayHydro: displayStation.displayHydro,
            displayInst: displayStation.displayInst,
            hiddenNoThresholdStations,
            monitoringType,
        })
        dispatch(AdministrationAction.createGlobalParameter(new GlobalParametersDto({
            parameter: `${nameFilter}_MAP_SITUATION`,
            module: 'SIEAU',
            value: json,
        }))).then(() => dispatch(AdministrationAction.fetchGlobalParameters()))
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen}>
            <DefaultDialogTitle title={i18n.creatingNewFilter} onClose={() => setIsOpen(false)} />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 size={12}>
                            <Input
                                title={i18n.filterName}
                                value={nameFilter}
                                onChange={v => setNameFilter(v)}
                                obligatory
                            />
                            <StyledFieldSet>
                                <StyledLegend>{i18n.registeredFields}</StyledLegend>
                                <Grid2 container spacing={1}>
                                    <Input
                                        title={i18n.search}
                                        value={searchValue}
                                        disabled
                                    />
                                    <SimpleDatePicker
                                        value={date}
                                        id='date'
                                        withHour
                                        label={i18n.date}
                                        onChange={() => {}}
                                        disabled
                                    />
                                    <FilterTypeStations displayStation={displayStation} />

                                    <Grid2 size={8}>
                                        <Checkbox
                                            label={i18n.hiddenNoThresholdStations}
                                            checked={hiddenNoThresholdStations}
                                            disabled
                                        />
                                    </Grid2>

                                    <Grid2 size={12}>
                                        <Select
                                            options={monitoringOptions}
                                            label={i18n.monitoringType}
                                            value={monitoringType}
                                            disabled
                                        />
                                    </Grid2>
                                </Grid2>
                            </StyledFieldSet>

                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'right' }}>
                <Button
                    onClick={onSave}
                    variant='contained'
                    color='primary'
                    disabled={isNil(nameFilter)}
                >
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
DialogFilterAlertMap.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    searchValue: PropTypes.string,
    selectedStationType: PropTypes.number,
    date: PropTypes.number,
    displayStation: PropTypes.shape({
        displayPiezo: PropTypes.bool,
        displayPluvio: PropTypes.bool,
        displayHydro: PropTypes.bool,
        displayInst: PropTypes.bool,
    }),
    hiddenNoThresholdStations: PropTypes.bool,
    monitoringType: PropTypes.int,
}

export default DialogFilterAlertMap
