import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import HydrometryAction from '../../../actions/HydrometryAction'
import { WhiteCard } from 'components/styled/Card'
import { Grid, MobileStepper, useTheme } from '@mui/material'
import { ButtonMUI } from 'components/styled/Buttons'
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material'
import DtoTideGaugeData from 'hydrometry/dto/DtoTideGaugeData'
import { getDate, getMiniHour } from 'utils/DateUtil'
import { isNil, round } from 'lodash'
import { mainBlue, mainWhite, secondaryBlue } from 'utils/constants/ColorTheme'
import { TIDE_GAUGE_TYPE } from 'hydrometry/constants/HydrometryConstants'
import { getTextColorByBackground } from 'utils/ColorUtil'

const LOW_COEFF = 65
const HIGH_COEFF = 75

const TideGaugeTable = ({
    tideGauge,
    mobile,
}) => {
    const getCoeffColors = (coeff) => {
        if (isNil(coeff)) {
            return { backgroundColor: 'white', color: 'black' }
        }

        if (coeff < LOW_COEFF) {
            return { backgroundColor: '#D9E1F2', color: getTextColorByBackground('#D9E1F2') }
        } else if (coeff >= HIGH_COEFF) {
            return { backgroundColor: '#F4B084', color: getTextColorByBackground('#F4B084') }
        }

        return { backgroundColor: '#FFE699', color: getTextColorByBackground('#FFE699') }
    }

    return (
        <Grid container item xs={!mobile ? 4 : 12} sx={{ textAlign: 'center', borderLeft: `solid 1px ${mainBlue}`, borderRight: `solid 1px ${mainBlue}` }}>
            <Grid container item xs={12} sx={{ backgroundColor: mainBlue, color: mainWhite }}>
                <Grid item xs={3}>
                    <span>{i18n.date}</span>
                </Grid>
                <Grid item xs={3}>
                    <span>{i18n.hour}</span>
                </Grid>
                <Grid item xs={3}>
                    <span>{i18n.height}</span>
                </Grid>
                <Grid item xs={3}>
                    <span>{i18n.coeff}</span>
                </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
                <Grid item xs={3}>
                    <span>{getDate(tideGauge.date)}</span>
                </Grid>
                <Grid container item xs={9}>
                    <Grid container item xs={12} sx={{ color: secondaryBlue }}>
                        <Grid item xs={4}>
                            <span>{getMiniHour(tideGauge.morningPmHour) || '-'}</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>{tideGauge.morningPmHeight ? `${round(tideGauge.morningPmHeight, 2)} m` : '-'}</span>
                        </Grid>
                        <Grid item xs={4} sx={getCoeffColors(tideGauge.morningCoeff)}>
                            <span>{tideGauge.morningCoeff}</span>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <span>{getMiniHour(tideGauge.morningBmHour) || '-'}</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>{tideGauge.morningBmHeight ? `${round(tideGauge.morningBmHeight, 2)} m` : '-'}</span>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ color: secondaryBlue }}>
                        <Grid item xs={4}>
                            <span>{getMiniHour(tideGauge.eveningPmHour) || '-' }</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>{tideGauge.eveningPmHeight ? `${round(tideGauge.eveningPmHeight, 2)} m` : '-'}</span>
                        </Grid>
                        <Grid item xs={4} sx={getCoeffColors(tideGauge.eveningCoeff)}>
                            <span>{tideGauge.eveningCoeff}</span>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={4}>
                            <span>{getMiniHour(tideGauge.eveningBmHour) || '-'}</span>
                        </Grid>
                        <Grid item xs={4}>
                            <span>{tideGauge.eveningBmHeight ? `${round(tideGauge.eveningBmHeight, 2)} m` : '-'}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

TideGaugeTable.propTypes = {
    tideGauge: PropTypes.instanceOf(DtoTideGaugeData),
    mobile: PropTypes.bool,
}

const TideGaugeDetails = ({
    stationId,
    mobile = false,
}) => {
    const {
        hydrometricStation,
        tideGaugeDatas,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        tideGaugeDatas: store.HydrometryReducer.tideGaugeDatas,
    }), shallowEqual)

    const theme = useTheme()

    const maxSteps = tideGaugeDatas.length
    const initStep = () => maxSteps > 3 ? (maxSteps / 2) -1 : 0

    const [activeStep, setActiveStep] = useState(initStep())

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrometryAction.fetchTideGaugeDatas({
            stationId,
            startDate: moment().subtract(7, 'days').valueOf(),
            endDate: moment().add(7, 'days').valueOf(),
        }))
    }, [])

    useEffect(() => {
        setActiveStep(initStep())
    }, [maxSteps])

    const handleNext = () => {
        setActiveStep(prev => prev + 1)
    }

    const handleBack = () => {
        setActiveStep(prev => prev - 1)
    }

    const tideGaugeType = hydrometricStation.stationType === TIDE_GAUGE_TYPE

    if (!tideGaugeType) {
        return null
    }

    return !!maxSteps && (
        <WhiteCard round>
            <Grid container sx={{ borderStyle: 'solid', borderWidth: '0 1px 2px', borderColor: mainBlue }}>
                {tideGaugeDatas.slice(activeStep, (activeStep + (mobile ? 2 : 3))).map(tgd => <TideGaugeTable tideGauge={tgd} mobile={mobile} />)}
            </Grid>
            {maxSteps > 3 && (
                <MobileStepper
                    steps={maxSteps - 2}
                    position='static'
                    activeStep={activeStep}
                    nextButton={
                        <ButtonMUI
                            size='small'
                            onClick={handleNext}
                            color='primary'
                            variant='contained'
                            disabled={activeStep === maxSteps - 3}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeftOutlined />
                            ) : (
                                <KeyboardArrowRightOutlined />
                            )}
                        </ButtonMUI>
                    }
                    backButton={
                        <ButtonMUI
                            size='small'
                            onClick={handleBack}
                            color='primary'
                            variant='contained'
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRightOutlined />
                            ) : (
                                <KeyboardArrowLeftOutlined />
                            )}
                        </ButtonMUI>
                    }
                />
            )}
        </WhiteCard>
    )
}

TideGaugeDetails.propTypes = {
    stationId: PropTypes.number,
    mobile: PropTypes.bool,
}

export default TideGaugeDetails
