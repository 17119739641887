import React, { useState } from 'react'
import { Grid2, IconButton } from '@mui/material'
import Checkbox from '../../../components/forms/Checkbox'
import piezometryMarker from '../../../assets/pictures/markers/map_piezo.png'
import { styled } from '@mui/styles'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { ButtonMUI } from '../../../components/styled/Buttons'
import pluviometryMarker from '../../../assets/pictures/markers/map_pluvio.png'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import hydrometryMarker from '../../../assets/pictures/markers/map_hydro.png'
import { endsWith, isUndefined } from 'lodash'
import installationMarker from '../../../assets/pictures/markers/map_installation_defaut.png'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Select from '../../../components/forms/Select'
import { MONITORING_OPTIONS } from '../../constants/AlertConstants'
import Input from '../../../components/forms/Input'
import { Add } from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DialogFilterAlertMap from './filteredTypeStations/DialogFilterAlertMap'
import AdministrationAction from '../../../administration/actions/AdministrationAction'

const ALERT_STATIONS_DISPLAYED = 'ALERT_STATIONS_DISPLAYED'

const Img = styled('img')({
    width: '20px',
    height: '25px',
    cursor: 'pointer',
    margin: '0px 5px 0px 5px',
})

const AlertFilter = ({
    stationSubType,
    setStationSubType,
    showType,
    displayType,
    selectedDate,
    hiddenNoThresholdStations,
    monitoringType,
    selectedStationType,
    hydrometersTypes,
    installationsTypes,
    onChange,
    searchValue,
    isOpenFilter,
    setIsOpenFilter,
    reloadSituation = {},
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.AdministrationReducer.globalParameters,
    }), shallowEqual)
    const dispatch = useDispatch()

    const [tmpSearchValue, setTmpSearchValue] = useState(searchValue)
    const [filter, setFilter] = useState(undefined)

    const [tmpDisplayPiezo, setTmpDisplayPiezo] = useState(displayType.displayPiezo)
    const [tmpDisplayPluvio, setTmpDisplayPluvio] = useState(displayType.displayPluvio)
    const [tmpDisplayHydro, setTmpDisplayHydro] = useState(displayType.displayHydro)
    const [tmpDisplayInst, setTmpDisplayInst] = useState(displayType.displayInst)

    const [tmpSelectedDate, setTmpSelectedDate] = useState(selectedDate)
    const [tmpHiddenNoThresholdStations, setTmpHiddenNoThresholdStations] = useState(hiddenNoThresholdStations)
    const [tmpMonitoringType, setTmpMonitoringType] = useState(monitoringType)


    const filters = parameters.filter(p => endsWith(p.parameter, '_MAP_SITUATION'))
    const filtersParse = filters.map((f, idx) => ({
        id: idx,
        name: JSON.parse(f.value).name,
        ...f,
        value: JSON.parse(f.value),
    }))

    const stationTypesFiltered = (() => {
        switch (stationSubType) {
            case STATION_TYPE_NAME.installation :
                return selectedStationType[stationSubType]?.filter(st => installationsTypes.includes(st.id)) || []
            case STATION_TYPE_NAME.hydrometry :
                return selectedStationType[stationSubType]?.filter(st => hydrometersTypes.includes(st.id)) || []
            default :
                return selectedStationType[stationSubType] || []
        }
    })()

    const [tmpSelection, setTmpSelection] = useState(selectedStationType || {})

    const toggleSelection = (st) => {
        setTmpSelection(prev => ({
            ...prev,
            [stationSubType]: prev[stationSubType].map(s => st.id === s.id ? { ...st, selected: !st.selected } : s),
        }),
        )
    }

    const handleChangeDisplay = (object) => {
        const settingValue = JSON.stringify({
            [STATION_TYPE_NAME.piezometry]: object.displayPiezo ?? displayType.displayPiezo,
            [STATION_TYPE_NAME.hydrometry]: object.displayHydro ?? displayType.displayHydro,
            [STATION_TYPE_NAME.pluviometry]: object.displayPluvio ?? displayType.displayPluvio,
            [STATION_TYPE_NAME.installation]: object.displayInst ?? displayType.displayInst,
        })
        dispatch(AdministrationAction.updateSetting(ALERT_STATIONS_DISPLAYED, settingValue))
        onChange(object)
    }

    const onSave = () => {
        handleChangeDisplay({
            ...{ displayPiezo: tmpDisplayPiezo },
            ...{ displayPluvio: tmpDisplayPluvio },
            ...{ displayHydro: tmpDisplayHydro },
            ...{ displayInst: tmpDisplayInst },
        })
        onChange({
            searchValue: tmpSearchValue,
            selectedStationType: tmpSelection,
            selectedDate: tmpSelectedDate,
            hiddenNoThresholdStations: tmpHiddenNoThresholdStations,
            monitoringType: tmpMonitoringType,
        })
        setTimeout(() => {
            if (tmpSelectedDate !== selectedDate) {
                reloadSituation()
            }
        }, 0)
    }

    const monitoringOptions = [{
        id: MONITORING_OPTIONS.ALL_MONITORING,
        name: i18n.allMonitoring,
    }, {
        id: MONITORING_OPTIONS.SITUATION_FLOOD,
        name: i18n.situationFlood,
    }, {
        id: MONITORING_OPTIONS.LOWWATER,
        name: i18n.lowWater,
    }]

    const changeFilter = (v) => {
        if (!isUndefined(v)) {
            const filterValue = filtersParse.find(f => f.id === v).value
            setFilter(v)
            setTmpDisplayPiezo(filterValue.displayPiezo)
            setTmpDisplayPluvio(filterValue.displayPluvio)
            setTmpDisplayHydro(filterValue.displayHydro)
            setTmpDisplayInst(filterValue.displayInst)
            setTmpSearchValue(filterValue.searchValue)
            setTmpSelection(filterValue.selectedStationType)
            setTmpSelectedDate(filterValue.date)
            setTmpHiddenNoThresholdStations(filterValue.hiddenNoThresholdStations)
            setTmpMonitoringType(filterValue.monitoringType)
            handleChangeDisplay({
                ...{ displayPiezo: filterValue.displayPiezo },
                ...{ displayPluvio: filterValue.displayPluvio },
                ...{ displayHydro: filterValue.displayHydro },
                ...{ displayInst: filterValue.displayInst },
            })
            onChange({
                searchValue: filterValue.searchValue,
                selectedStationType: filterValue.selectedStationType,
                selectedDate: filterValue.date,
                hiddenNoThresholdStations: filterValue.hiddenNoThresholdStations,
                monitoringType: filterValue.monitoringType,
            })
            setTimeout(() => {
                if (!(filterValue.date === selectedDate)) {
                    reloadSituation()
                }
            }, 0)
        } else {
            setFilter(undefined)
        }
    }

    const isHydroStationSubType = stationSubType === STATION_TYPE_NAME.hydrometry

    return (
        <Grid2 container spacing={1}>
            <Grid2 container paddingTop={'0.75rem'} size={12} spacing={1} alignItems={'center'}>
                <Grid2 size={6}>
                    <Input
                        title={i18n.search}
                        value={tmpSearchValue}
                        onChange={v => setTmpSearchValue(v)}
                    />
                </Grid2>
                <Grid2 size={5}>
                    <Select
                        options={filtersParse}
                        label={i18n.filters}
                        onChange={v => changeFilter(v)}
                        value={filter}
                    />
                </Grid2>
                <Grid2 size={1}>
                    <IconButton
                        color='primary'
                        size='small'
                        onClick={() => setIsOpenFilter(true)}
                    >
                        <Add />
                    </IconButton>
                </Grid2>
            </Grid2>
            <Grid2 container alignItems={'center'} size={12} >
                {!!showType.showPiezo && (
                    <Grid2 container size={3} alignItems='center' sx={{ padding: '0 0.75rem' }} onClick={() => setTmpDisplayPiezo(prev => !prev)}>
                        <Checkbox
                            label={<Img src={piezometryMarker} />}
                            checked={tmpDisplayPiezo}
                            tooltip={i18n.piezometers}
                        />
                    </Grid2>
                )}
                {!!showType.showPluvio && (
                    <Grid2 container size={3} alignItems='center' sx={{ padding: '0 0.75rem' }} onClick={() => setTmpDisplayPluvio(prev => !prev)}>
                        <Checkbox
                            label={<Img src={pluviometryMarker} />}
                            checked={tmpDisplayPluvio}
                            tooltip={i18n.pluviometers}
                        />
                    </Grid2>
                )}
                {!!showType.showHydro && (
                    <Grid2 container size={3} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                        <Grid2
                            onClick={() => {
                                setTmpDisplayHydro(prev => !prev)
                            }}
                        >
                            <Checkbox
                                label={<Img src={hydrometryMarker} />}
                                checked={tmpDisplayHydro}
                                tooltip={i18n.hydrometricStations}
                            />
                        </Grid2>
                        <Grid2>
                            <i className='material-icons clickable' onClick={() => setStationSubType(!isHydroStationSubType ? STATION_TYPE_NAME.hydrometry : undefined)}>{isHydroStationSubType ? 'expand_less' : 'expand_more'}</i>
                        </Grid2>
                    </Grid2>
                )}
                {!!showType.showInst && (
                    <Grid2 container size={3} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                        <Grid2
                            onClick={() => {
                                setTmpDisplayInst(prev => !prev)
                            }}
                        >
                            <Checkbox
                                label={<Img src={installationMarker} />}
                                checked={tmpDisplayInst}
                                tooltip={i18n.installations}
                            />
                        </Grid2>
                        <Grid2>
                            <i className='material-icons clickable' onClick={() => setStationSubType(p => p !== STATION_TYPE_NAME.installation ? STATION_TYPE_NAME.installation : undefined)}>{stationSubType === STATION_TYPE_NAME.installation ? 'expand_less' : 'expand_more'}</i>
                        </Grid2>
                    </Grid2>
                )}
            </Grid2>
            {!!stationSubType && (
                <Grid2 container size={12} sx={{ marginTop: '1rem' }} alignItems='center' spacing={0}>
                    {stationTypesFiltered.map((st, i) => (
                        <Grid2 size={3} sx={{ padding: '0 0.75rem' }} key={i} onClick={() => toggleSelection(st)}>
                            <Checkbox
                                label={st.label}
                                checked={tmpSelection[stationSubType]?.find(s => s.id === st.id).selected}
                                tooltip={st.label}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            )}
            <Grid2 size={12} container alignItems={'center'} spacing={2}>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        value={selectedDate}
                        id='date'
                        withHour
                        label={i18n.date}
                        onChange={v => setTmpSelectedDate(v)}
                    />
                </Grid2>
                <Grid2 size={6} onClick={() => setTmpHiddenNoThresholdStations(prev => !prev)}>
                    <Checkbox
                        label={i18n.hiddenNoThresholdStations}
                        checked={tmpHiddenNoThresholdStations}
                    />
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Select
                    options={monitoringOptions}
                    label={i18n.monitoringType}
                    onChange={v => setTmpMonitoringType(v)}
                    value={tmpMonitoringType}
                    noNullValue
                />
            </Grid2>
            <Grid2 size={12}>
                <ButtonMUI
                    onClick={onSave}
                    variant='contained'
                    fullWidth
                >
                    {i18n.apply}
                </ButtonMUI>
            </Grid2>

            <DialogFilterAlertMap
                isOpen={ isOpenFilter }
                setIsOpen={ setIsOpenFilter }
                date={ selectedDate }
                displayStation={ {
                    displayPiezo: tmpDisplayPiezo,
                    displayPluvio: tmpDisplayPluvio,
                    displayHydro: tmpDisplayHydro,
                    displayInst: tmpDisplayInst,
                }}
                hiddenNoThresholdStations={ tmpHiddenNoThresholdStations }
                monitoringType={ tmpMonitoringType }
                searchValue={ tmpSearchValue }
                selectedStationType={ tmpSelection }
            />
        </Grid2>
    )
}
AlertFilter.propTypes = {
    stationSubType: PropTypes.string,
    showType: PropTypes.shape({
        showPiezo: PropTypes.bool,
        showPluvio: PropTypes.bool,
        showInst: PropTypes.bool,
        showHydro: PropTypes.bool,
    }),
    displayType: PropTypes.shape({
        displayPiezo: PropTypes.bool,
        displayPluvio: PropTypes.bool,
        displayInst: PropTypes.bool,
        displayHydro: PropTypes.bool,
    }),
    selectedDate: PropTypes.number,
    hiddenNoThresholdStations: PropTypes.bool,
    setStationSubType: PropTypes.func,
    monitoringType: PropTypes.number,
    selectedStationType: PropTypes.number,
    onChange: PropTypes.func,
    searchValue: PropTypes.string,
    isOpenFilter: PropTypes.bool,
    setIsOpenFilter: PropTypes.func,
    reloadSituation: PropTypes.func,
}
export default AlertFilter
