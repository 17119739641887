/* eslint-disable consistent-return */

import { isNil } from 'lodash'

const getUdiSectorName = (udi, sector) => {
    if (!isNil(udi)) {
        return `${udi.code} ${udi.name && `- ${udi.name}` || ''}`
    }
    return sector?.name
}

const isDanger = (answer) => answer.answerMultiChoice === 'no' || answer.answerBool

export {
    getUdiSectorName, isDanger,
}
