/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CardTable } from 'components/datatable/NewTable'
import { push } from '@lagunovsky/redux-react-router'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { getLabel } from 'utils/StoreUtils'
import PGSSEFilters from '../common/PGSSEFilters'
import useTitle from 'utils/customHook/useTitle'
import PgsseAction from 'pgsse/action/PgsseAction'

const Phase1List = ({ }) => {
    const dispatch = useDispatch()

    const {
        contributors,
        distributionUnits,
        sectors,
        diagnostics,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        diagnostics: store.PgsseReducer.diagnostics,
    }), shallowEqual)

    useEffect(() => {
        dispatch(PgsseAction.fetchDashboardPhase1List({}))
    }, [])

    const rows = diagnostics.map(d => {
        return {
            ...d,
            collectivity: getLabel(contributors, d.idContributor),
            sector: getLabel(sectors, d.idSector),
            udiCode: getLabel(distributionUnits, d.idUdi, 'code'),
            udi: getLabel(distributionUnits, d.idUdi),
        }
    })

    useTitle(() => [{
        title: i18n.pgsse,
        href: PATH_PGSSE,
    }, {
        title: i18n.phase1,
        href: `${PATH_PGSSE}/phase1`,
    }], [])

    return (
        <Grid2 container spacing={1} sx={{ padding: '10 10 10 20' }}>
            <Grid2 size={12}>
                <PGSSEFilters onValidate={filters => dispatch(PgsseAction.fetchDashboardPhase1List(filters))} />
            </Grid2>
            <Grid2 size={12}>
                <CardTable
                    title={i18n.diagnostics}
                    rows={rows}
                    headers={['collectivity', 'sector', 'udiCode', 'udi', { key: 'nbCampaigns', value: i18n.campaigns }, { key: 'nbForms', value: i18n.forms }, 'documents', 'activities']}
                    onClickRow={d => dispatch(push(`${PATH_PGSSE}/phase1/${d.idDiag}`))}
                />
            </Grid2>
        </Grid2>
    )
}

Phase1List.PropTypes = {
}

export default Phase1List