/* eslint-disable camelcase */
import React from 'react'
import { Button, Divider, Grid2 } from '@mui/material'
import { styled } from '@mui/styles'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import DtoCMSQuestion from 'events/dto/survey/DtoCMSQuestion'
import AddPicture from 'installation/components/campaign/components/AddPicture'
import VisitPicture from 'installation/components/campaign/components/VisitPicture'
import DtoAnswer from 'installation/components/campaign/dto/DtoAnswer'
import DtoAnswerDocument from 'installation/components/campaign/dto/DtoAnswerDocument'
import DtoDanger from 'installation/components/campaign/dto/DtoDanger'
import DtoVisit from 'installation/components/campaign/dto/DtoVisit'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { isDanger } from 'pgsse/util/PgsseUtil'

const CheckButton = styled(({ ...other }) => <Button variant='outlined' {...other} />)({
    borderRadius: props => props.radius === 1 ? '5px 0 0 5px' : props.radius === 3 ? '0 5px 5px 0' : 0,
    border: props => props.border === 'yes' ? 'solid 1px #79da4c' : props.border === 'no' ? 'solid 1px #f32525' : 'solid 1px grey',
})

const Question = ({
    question,
    dataForm,
    onChange,
    readMode,
}) => {
    const answer = dataForm.link_answers.find(la => la.idQuestion === question.idQuestion) || {}
    const answerWithIndexs = answer.link_documents && {
        ...answer,
        link_documents: answer.link_documents.map((d, index) => ({ ...d, index })),
    } || {}


    const updateQuestionAndAddDanger = (newAnswer, deleteDanger = false) => {
        const newAnswers = [
            ...dataForm.link_answers.filter(la => !(la.idQuestion === question.idQuestion && question.idParagraph === la.idParagraph)),
            newAnswer,
        ].filter(d => d.idQuestion)
        const newDangers = [
            ...dataForm.link_dangers.filter(d => !(d.idLinkedQuestion === question.idQuestion && d.idLinkedParagraph === question.idParagraph)),
            !deleteDanger && new DtoDanger({
                idSurvey: dataForm.idSurvey,
                siteCode: dataForm.code,
                siteType: DANGER_ELEMENT_TYPE.INSTALLATION,
                idVisit: dataForm.idForm,
                idCampaign: dataForm.idCampaign,
                idLinkedQuestion: question.idQuestion,
                idLinkedParagraph: question.idParagraph,
            }) || null,
        ].filter(d => !!d)

        onChange({
            link_answers: newAnswers,
            link_dangers: newDangers,
        })
    }

    const updateQuestion = (newAnswer) => {
        const newAnswers = [
            ...dataForm.link_answers.filter(la => !(la.idQuestion === question.idQuestion && question.idParagraph === la.idParagraph)),
            newAnswer,
        ].filter(d => d.idQuestion)
        onChange({ link_answers: newAnswers })
    }

    const onChangeQuestion = (value) => {
        const newObjectAnswer = new DtoAnswer({
            recipientCode: dataForm.code,
            idCampaign: dataForm.idCampaign,
            recipientType: DANGER_ELEMENT_TYPE.INSTALLATION,
            idSurvey: dataForm.idSurvey,
            idVisit: dataForm.idForm,
            idQuestion: question.idQuestion,
            idParagraph: question.idParagraph,
            ...answerWithIndexs,
            ...value,
        })
        const newAnswer = (newObjectAnswer.answerBool
            || newObjectAnswer.answerDate
            || newObjectAnswer.answerLong
            || newObjectAnswer.answerMultiChoice
            || newObjectAnswer.answerText
            || newObjectAnswer?.link_documents.length) ? newObjectAnswer : {}
        const isDangerOld = isDanger(answerWithIndexs)
        const isDangerNew = isDanger(newAnswer)

        if ((!isDangerOld && isDangerNew) || (isDangerOld && isDangerNew)) {
            updateQuestionAndAddDanger(newAnswer)
        } else if (isDangerOld && !isDangerNew) {
            updateQuestionAndAddDanger(newAnswer, true)
        } else {
            updateQuestion(newAnswer)
        }
    }

    const addNewDocument = (document) => {
        const newLinkDocuments = [
            ...(answerWithIndexs.link_documents || []),
            document,
        ]
        onChangeQuestion({ link_documents: newLinkDocuments })
    }

    const onChangeDocument = (document, value) => {
        const newLinkDocuments = answerWithIndexs.link_documents.map(d => {
            if (d.index === document.index) {
                return new DtoAnswerDocument({ ...document, ...value })
            }
            return d
        })
        onChangeQuestion({ link_documents: newLinkDocuments })
    }

    const onDeleteDocument = (document) => {
        onChangeQuestion({ link_documents: [...answerWithIndexs.link_documents.filter(d => d.index !== document.index)] })
    }

    const displayPictures = answerWithIndexs.link_documents && answerWithIndexs.link_documents.map(doc => {
        return (
            <VisitPicture
                key={doc.docName}
                doc={doc}
                onDeleteDocument={() => onDeleteDocument(doc)}
                onChangeDocument={value => onChangeDocument(doc, { description: value })}
                readMode={readMode}
                col={4}
            />
        )
    })

    const amc = answerWithIndexs.answerMultiChoice // answerWithIndexs.answerMultiChoice

    const sandreList = useSandreList(question.sandreField)

    return (
        <Grid2 container spacing={1}>
            <Grid2 container size={{ xs: 12, md: 8 }} direction='column'>
                <Grid2 size={12} container>
                    <Grid2 size={7}>
                        <h5>{question.description}</h5>
                    </Grid2>
                    <Grid2 container size={5} justifyContent='flex-end' alignItems='center'>
                        <Grid2>
                            <CheckButton
                                radius={1}
                                border={amc}
                                style={{
                                    backgroundColor: amc === 'yes' ? '#79da4c' : 'white',
                                    color: amc === 'yes' ? 'white' : 'black',
                                }}
                                onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'yes' ? undefined : 'yes' })}
                                disabled={readMode}
                            >
                                {i18n.yes}
                            </CheckButton>
                            <CheckButton
                                radius={2}
                                border={amc}
                                style={{
                                    backgroundColor: amc === 'no' ? '#f32525' : 'white',
                                    color: amc === 'no' ? 'white' : 'black',
                                }}
                                onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'no' ? undefined : 'no' })}
                                disabled={readMode}
                            >
                                {i18n.no}
                            </CheckButton>
                            <CheckButton
                                radius={3}
                                border={amc}
                                style={{
                                    backgroundColor: amc === 'withoutObject' ? 'grey' : 'white',
                                    color: amc === 'withoutObject' ? 'white' : 'black',
                                }}
                                onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'withoutObject' ? undefined : 'withoutObject' })}
                                disabled={readMode}
                            >
                                {i18n.withoutObject}
                            </CheckButton>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={12} container>
                    <Grid2 size={6}>
                        {question.sandreField && (
                            <Select
                                obligatory
                                options={sandreList}
                                keyValue='code'
                                keyLabel='name'
                                label={i18n.state}
                                value={answerWithIndexs.answerLong}
                                onChange={v => onChangeQuestion({ answerLong: v })}
                                disabled={readMode}
                            />
                        )}
                    </Grid2>
                    <Grid2 container size={6} justifyContent='flex-end' alignItems='center'>
                        <Grid2>
                            <Checkbox
                                col={12}
                                label={i18n.isStateProblem}
                                checked={answerWithIndexs.answerBool}
                                onChange={v => onChangeQuestion({ answerBool: v })}
                                disabled={readMode}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
                {question.inputComment && (
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={answerWithIndexs.answerText}
                            {...readMode}
                            onChange={v => onChangeQuestion({ answerText: v })}
                            disabled={readMode}
                            readMode={readMode}
                        />
                    </Grid2>
                )}
            </Grid2>
            <Grid2 container size={{ xs: 12, md: 4 }} justifyContent='space-evenly'>
                <Grid2 container spacing={2}>
                    {displayPictures}
                    {!readMode && (
                        <AddPicture
                            onUploadFile={newFileName => {
                                addNewDocument({
                                    idSurvey: dataForm.idSurvey,
                                    idInstallation: dataForm.idInstallation,
                                    idLinkedQuestion: question.idQuestion,
                                    docName: newFileName,
                                    idVisit: dataForm.idVisit,
                                    idCampaign: dataForm.idCampaign,
                                })
                            }}
                        />
                    )}
                </Grid2>
            </Grid2>
            <Grid2 size={12} sx={{ marginBottom: '10px' }}>
                <Divider />
            </Grid2>
        </Grid2>
    )
}

Question.propTypes = {
    question: PropTypes.instanceOf(DtoCMSQuestion),
    dataForm: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    uploadFile: PropTypes.func,
    readMode: PropTypes.bool,
}

export default Question