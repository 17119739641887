import { push } from '@lagunovsky/redux-react-router'
import {maxBy, meanBy, minBy, range, round as roundnum, sumBy, zip} from 'lodash'
import moment from 'moment'
import DtoPiezometer from 'piezometry/dto/DtoPiezometer'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getStationArrowNav } from 'utils/ActionUtils'
import { getLinks } from 'utils/StationUtils'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import ExportAction from '../../../export/actions/ExportAction'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import { setModal } from '../../../utils/FormUtils'
import DtoPiezoMeasureLight from '../../dto/chart/DtoPiezoMeasureLight'
import { MONTHS } from '../suivi/constants/PiezometerSuiviConstants'
import DtoMonthValues from './dto/DtoMonthValues'
import DtoYearValues from './dto/DtoYearValues'
import { hasValue } from '../../../utils/NumberUtil'
import useActions from 'utils/customHook/useActions'
import VirtualizedTable from 'components/datatable/virtualizedTable/VirtualizedTable'
import { getBeginingOfTheYear, getEndOfTheYear, getFullDate } from '../../../utils/DateUtil'
import MessageCard from '../../../components/card/MessageCard'
import { Card, Grid2 } from '@mui/material'
import { CardTitle } from '../../../components/card/NewCard'
import StatisticalIndicatorsLegend from '../../../station/components/legends/StatisticalIndicatorsLegend'
import { GREY, ORANGE, WHITE } from '../../../components/constants/ColorConstant'

const PiezometerSuiviTable = ({
    piezometer = {},
    filter = {},
    historic = false,
    measures = [],
    chronicTypes = [],
    tableHeight,
}) => {
    const {
        samples,
        piezometerAdditionalMeasures,
        typeEnvironmentModels,
        piezometers,
    } = useSelector(store => ({
        samples: store.PiezometerStationReducer.samples,
        piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const [openExportModal, setOpenExportModal] = useState(false)

    const dispatch = useDispatch()

    const dataType = chronicTypes.find(t => t.code === filter.chronicType)

    const datas = useMemo(() => {
        if (filter.chronicType === -2) {
            return samples
        } else if (`${dataType.code}`.includes('additional')) {
            return piezometerAdditionalMeasures.find(typeObj => typeObj.type === dataType.type)?.measures || []
        }
        return measures
    }, [dataType.code, dataType.type, filter.chronicType, measures, piezometerAdditionalMeasures, samples])

    const isMeasureData = filter.chronicType.toLowerCase().includes('measure')

    const minFunc = () => isMeasureData ? maxBy : minBy
    const maxFunc = () => isMeasureData ? minBy : maxBy

    const getHistoricData = () => {
        const dateKey = dataType.dateKey
        const allDaysByYear = range(moment().year(), moment(minBy(datas, dateKey)[dateKey]).year() - 1).map(year => {
            return {
                year,
                data: new Array(366).fill(undefined),
            }
        })

        const filteredMeasures = datas.filter(m => (!filter.qualification || m.qualification == filter.qualification)
            && (!filter.state || m.status == filter.state),
        )

        filteredMeasures.map(measure => {
            const date = moment(measure[dateKey])
            const correspondingYearData = allDaysByYear.find(year => parseInt(year.year) === parseInt(date.year())).data
            const bisextileDate = date.year(2016)
            if (correspondingYearData[bisextileDate.dayOfYear() - 1] === undefined) {
                correspondingYearData[bisextileDate.dayOfYear() - 1] = measure.value
            } else {
                correspondingYearData[bisextileDate.dayOfYear() - 1] = dataType.groupFunc([ correspondingYearData[bisextileDate.dayOfYear() - 1], measure.value])
            }
        })

        const coloredData = allDaysByYear.map(yearData => {
            const coloredYearData = yearData.data.map(value => {
                return { value: hasValue(value) ? roundnum(value, 2) : undefined, color: WHITE }
            })
            const dataWithValues = coloredYearData.filter(data => data.value)
            if (maxFunc()(dataWithValues, 'value')) {
                const maxValue = roundnum(maxFunc()(coloredYearData, 'value').value, 2)
                const minValue = roundnum(minFunc()(coloredYearData, 'value').value, 2)
                coloredYearData.map(data => {
                    if (data.value === maxValue) {
                        data.color = '#F44336'
                    }
                    if (data.value === minValue) {
                        data.color = '#3465ff'
                    }
                    return data
                })
                coloredYearData.push({ value: roundnum(minBy(dataWithValues, 'value').value, 2), color: ORANGE })
                coloredYearData.push({ value: roundnum(meanBy(dataWithValues, 'value'), 2), color: ORANGE })
                coloredYearData.push({ value: roundnum(maxBy(dataWithValues, 'value').value, 2), color: ORANGE })
                coloredYearData.push({ value: roundnum(sumBy(dataWithValues, 'value'), 2), color: ORANGE })
            } else {
                range(4).map(() => coloredYearData.push({ value: undefined, color: ORANGE }))
            }
            return coloredYearData
        })

        const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
            .map((days, index) => new DtoYearValues({ value: moment('2016-01-01').dayOfYear(index + 1).format('DD/MM'), color: GREY }, days))

        values[366] = {
            ...values[366],
            day: { value: i18n.min, color: GREY },
            min: { value: roundnum(values[366].min.value, 2), color: isMeasureData ? '#F44336' : '#3465ff' },
            mean: undefined,
            max: undefined,
            sum: undefined,
        }
        values[367] = {
            ...values[367],
            day: { value: i18n.mean, color: GREY },
            min: undefined,
            mean: { value: roundnum(values[367].mean.value, 2), color: ORANGE },
            max: undefined,
            sum: undefined,
        }
        values[368] = {
            ...values[368],
            day: { value: i18n.max, color: GREY },
            min: undefined,
            mean: undefined,
            max: { value: roundnum(values[368].max.value, 2), color: isMeasureData ? '#3465ff' : '#F44336' },
            sum: undefined,
        }
        values[369] = {
            ...values[369],
            day: { value: i18n.sum, color: GREY },
            min: undefined,
            mean: undefined,
            max: undefined,
            sum: { value: roundnum(values[369].sum.value, 2), color: ORANGE },
        }

        return {
            values,
            nbMeasures: filteredMeasures.length,
        }
    }

    const getAnnualData = () => {
        const dateKey = dataType.dateKey
        const allDaysByMonth = MONTHS.map(() => {
            return new Array(31).fill(undefined)
        })
        const momentYear = filter.year ? moment(filter.year, 'YYYY') : moment()
        const filteredMeasures = datas.filter(m => (!filter.qualification || m.qualification == filter.qualification)
            && (!filter.state || m.status == filter.state)
            && momentYear.isSame(moment(m[dateKey]), 'year'),
        )

        filteredMeasures.map(measure => {
            const date = moment(measure[dateKey])
            if (!allDaysByMonth[date.month()][date.date() - 1]) {
                allDaysByMonth[date.month()][date.date() - 1] = measure.value
            } else {
                allDaysByMonth[date.month()][date.date() - 1] = dataType.groupFunc([ allDaysByMonth[date.month()][date.date() - 1], measure.value])
            }
        })

        const coloredData = allDaysByMonth.map(monthDays => {
            const coloredMonthDays = monthDays.map(value => {
                return { value: hasValue(value) ? roundnum(value, 2) : undefined, color: WHITE }
            })
            const dataWithValues = coloredMonthDays.filter(data => data.value && hasValue(data.value))
            if (maxFunc()(dataWithValues, 'value')) {
                const maxValue = roundnum(maxFunc()(coloredMonthDays, 'value').value, 2)
                const minValue = roundnum(minFunc()(coloredMonthDays, 'value').value, 2)
                coloredMonthDays.map(data => {
                    if (data.value === maxValue) {
                        data.color = '#F44336'
                    }
                    if (data.value === minValue) {
                        data.color = '#3465ff'
                    }
                    return data
                })
                coloredMonthDays.push({ value: roundnum(minBy(dataWithValues, 'value').value, 2), color: ORANGE })
                coloredMonthDays.push({ value: roundnum(meanBy(dataWithValues, 'value'), 2), color: ORANGE })
                coloredMonthDays.push({ value: roundnum(maxBy(dataWithValues, 'value').value, 2), color: ORANGE })
                coloredMonthDays.push({ value: roundnum(sumBy(dataWithValues, 'value'), 2), color: ORANGE })
            } else {
                range(4).map(() => coloredMonthDays.push({ value: undefined, color: ORANGE }))
            }
            return coloredMonthDays
        })

        const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
            .map((months, index) => new DtoMonthValues({ value: index + 1, color: GREY }, months))

        values[31] = {
            ...values[31],
            day: { value: i18n.min, color: GREY },
            min: { value: roundnum(values[31].min.value, 2), color: isMeasureData ? '#F44336' : '#3465ff' },
            mean: undefined,
            max: undefined,
            sum: undefined,
        }
        values[32] = {
            ...values[32],
            day: { value: i18n.mean, color: GREY },
            min: undefined,
            mean: { value: roundnum(values[32].mean.value, 2), color: ORANGE },
            max: undefined,
            sum: undefined,
        }
        values[33] = {
            ...values[33],
            day: { value: i18n.max, color: GREY },
            min: undefined,
            mean: undefined,
            max: { value: roundnum(values[33].max.value, 2), color: isMeasureData ? '#3465ff' : '#F44336' },
            sum: undefined,
        }
        values[34] = {
            ...values[34],
            day: { value: i18n.sum, color: GREY },
            min: undefined,
            mean: undefined,
            max: undefined,
            sum: { value: roundnum(values[34].sum.value, 2), color: ORANGE },
        }

        return {
            values,
            nbMeasures: filteredMeasures.length,
        }
    }

    useEffect(() => {
        if (datas.length > 0) {
            $('#suiviTable').tableHeadFixer({
                head: true,
                left: 1,
                'z-index': 0,
            })
        }
    }, [datas.length])

    useActions(() => piezometer.id ? ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
        links: getLinks(piezometer),
        arrowNav: getStationArrowNav('piezometry', piezometers, piezometer.id, s => dispatch(push(`/station/piezometry/${s.id}/piezoSuiviTable`))),
    }) : ({
        exportList: [{
            onClick: () => setOpenExportModal(true),
            label: i18n.excel,
        }],
    }), [piezometer, piezometers])

    const onDisplayLegend = () => setModal({
        title: i18n.legend,
        actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
        content: (
            <StatisticalIndicatorsLegend />
        ),
    })

    const exportData = (data, type) => {
        if (!data.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const measuresFiltered = historic ? data : data.filter(m => m.date >= getBeginingOfTheYear(filter.year) && m.date <= getEndOfTheYear(filter.year))
        if (!measuresFiltered.length) {
            return dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const exportDatas = measuresFiltered.map(line => {
            return {
                ...line,
                headers: ['code', 'name', 'date', 'value', 'status', 'qualification', 'codepoint'],
                date: getFullDate(line.date),
                code: piezometer.code,
                name: piezometer.name,
            }
        })
        const dataFormatted = formatData(exportDatas, type)
        return dispatch(ExportAction.export(dataFormatted, type, i18n.graphicTracking))
    }

    const getExportModal = () => {
        const tableExport = [{
            name: i18n.resultsTable,
            formats: [{
                type: i18n.csvFile,
                callback: () => exportData(datas, 'csv'),
            },
            {
                type: i18n.excelFile,
                callback: () => exportData(datas, 'xlsx'),
            }],
        }]

        const exportModel = typeEnvironmentModels.map((model) => {
            const fileNameSplit = model.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            const ext = fileNameSplit[fileNameSplit.length - 1]
            return {
                name,
                formats: [{
                    type: getModelFileType(ext),
                    callback: () => exportModelFile({
                        stationId: piezometer.id.toString(),
                        stationCode: piezometer.code,
                        stationType: piezometer.typeName,
                        environmentModels: typeEnvironmentModels,
                        filenameModelExport: model,
                    }),
                }],
            }
        })

        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => setOpenExportModal(false)}
                data={[ ...tableExport, ...exportModel ]}
            />
        )
    }

    const formattedDatas = historic ? getHistoricData() : getAnnualData()

    return (!filter.chronicType || !formattedDatas.nbMeasures) ? (
        <Grid2 size={12}>
            <MessageCard>{i18n.noDataToDisplay}</MessageCard>
            {openExportModal && getExportModal({})}
        </Grid2>
    ) : (
        <Grid2 size={12}>
            <Card>
                <CardTitle
                    title={`${i18n.dataFollowUp} (${formattedDatas.nbMeasures} ${i18n.measures})`}
                    actions={[{
                        icon: 'info',
                        onClick: onDisplayLegend,
                        tooltip: i18n.legend,
                    }]}
                />
                <VirtualizedTable
                    data={formattedDatas.values}
                    headers={formattedDatas.values[0]?.headers}
                    columnWidth={100}
                    tableHeight={tableHeight}
                />
            </Card>
            {openExportModal && getExportModal(formattedDatas)}
        </Grid2>
    )
}

PiezometerSuiviTable.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    filter: PropTypes.shape({
        state: PropTypes.string,
        qualification: PropTypes.string,
    }),
    historic: PropTypes.boolean,
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoMeasureLight)),
    chronicTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
        dateKey: PropTypes.string,
        groupFunc: PropTypes.func,
    })),
    tableHeight: PropTypes.string,
}

export default PiezometerSuiviTable
