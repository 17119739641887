import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import {
    applicationName,
} from '../../conf/SieauConstants'
import aquasysLogoPath from 'assets/pictures/logoaQuasys.png'
import { getSetting } from '../../utils/SettingUtils'
import { cacheChecker } from 'utils/CacheChecker'
import Popup from '../../components/modal/Popup'
import Toastr from 'toastr/components/Toastr'
import Wait from '../../components/progress/Wait'
import AppStore from '../../store/AppStore'
const BubbleHelp = ({ location }) => {
    const dispatch = useDispatch()

    const {
        popup,
        applicationSettings,
    } = useSelector(store => ({
        popup: store.SieauReducer.popup,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    useEffect(() => {
        // cacheChecker()
        dispatch(AdministrationAction.fetchApplicationSettings())
    }, [dispatch])

    useEffect(() => {
        cacheChecker(true)
    }, [location.pathname])

    useEffect(() => {
        document.title = getSetting(applicationSettings, 'applicationName') || applicationName
        $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
    }, [applicationSettings])

    return (
        <>
            <Toastr store={AppStore} />
            <Wait />
            <Popup
                header={popup?.header}
                actions={popup?.actions}
                idPopup='generalPopup'
            >
                {popup?.content}
            </Popup>
        </>
    )
}

BubbleHelp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        href: PropTypes.string,
    }),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}

export default BubbleHelp
