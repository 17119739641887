module.exports = {
    nbPerPageLabelHuge: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        {
            label: '250',
            value: 250,
        },
        {
            label: '500',
            value: 500,
        },
        {
            label: '1000',
            value: 1000,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabel: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabel30: [
        { label: '30', value: 30 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelMedium: [
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelShorty: [
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelShort: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelTiny: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'Tout', value: -1 },
    ],
    RECEIVE_REFERENCIAL_STATUS: 'RECEIVE_REFERENCIAL_STATUS',
    RECEIVE_REFERENCIAL_SUPPORT: 'RECEIVE_REFERENCIAL_SUPPORT',
    RECEIVE_STATION_TYPES: 'RECEIVE_STATION_TYPES',
    RECEIVE_ALL_MATERIEL_TYPES: 'RECEIVE_ALL_MATERIEL_TYPES',
    RECEIVE_REFERENCIAL_DOMAIN: 'RECEIVE_REFERENCIAL_DOMAIN',
    RECEIVE_SANDRE_CODES: 'RECEIVE_SANDRE_CODES',
    RECEIVE_CHECK_DELETE: 'RECEIVE_CHECK_DELETE',
    RESET_CHECK_DELETE: 'RESET_CHECK_DELETE',
    RECEIVE_REFERENCIAL_SANDRE_CODES: 'RECEIVE_REFERENCIAL_SANDRE_CODES',
    RECEIVE_ALL_DATA_TYPE: 'RECEIVE_ALL_DATA_TYPE',
    SANDRE: {
        NATURE_EAU: 'NATURE_EAU',
        CODE_ETAT: 'CODE_ETAT',
        MODE_EXPLOITATION: 'MODE_EXPLOITATION',
        USAGES_GENERAL: 'USAGES',
        USAGES: 'PIEZOMETRES_PRELEVEMENTS.USAGES',
        ARRANGEMENT_TYPES: 'AMENAGEMENT.TYPE_AMENAGEMENT',
        LITHOLOGY: 'LITHOLOGIE.CODE_LITHOLOGIE',
        LITHOLOGY_TYPE: 'TYPE_LITHOLOGIQUE.CODETYPELITHOLOGIQUE',
        PROJECTION: 'PIEZOMETRES.TYPEPROJECTION',
        CONTRIBUTOR_TYPES: 'LIEN_PIEZO_INTERVENANTS.TYPEINTERVENANT',
        CASING_MATERIAL: 'TUBAGES.MATERIEAU',
        FIELD_MODES: 'PIEZOMETRES.MODEGISEMENT',
        PIEZOMETER_NATURE: 'PIEZOMETRES.CODENATURE',
        ALTIMETRIC_SYSTEM_NATURE: 'SYSTEME_ALTIMETRIQUE.CODENATURE',
        ALTIMETRIC_SYSTEM: 'SYSTEME_ALTIMETRIQUE.SYSTEMEALTIMETRIQUE',
        ALTIMETRIC_SYSTEM_OBTENTION: 'SYSTEME_ALTIMETRIQUE.MODEOBTENTION',
        ALTIMETRIC_SYSTEM_PRECISION: 'PRECISION.ALTIMETRIQUE',
        OBTAINING_MODE: 'CHRONIQUE.MODE_OBTENTION',
        STATE_CODES: 'QUALITOMETRES.CODEETAT',
        PIEZOMETER_MEASURE_NATURE: 'MESURES.NATURES',
        MEASURE_MODE: 'MODES_MESURES.METHODE',
        MEASURE_PERIODICITY: 'MODES_MESURES.PERIODICITE',
        PERIODICITE_ACQUISITION: 'PERIODICITE_ACQUISITION',
        PIEZOMETER_EXPRESSION_COTE: 'PIEZOMETRES.EXPRESSIONCOTE',
        ACCESSIBILITIES_TYPE_CONDITION: 'ACCESSIBILITES.TYPE_CONDITION',
        ACCESSIBILITIES_TYPE_FERMETURE: 'ACCESSIBILITES.TYPE_FERMETURE',
        CENTRALE_FREQUENCE_TRANSMISSION: 'CENTRALE.FREQUENCE_TRANSMISSION',
        INSTALLATION_TYPES: 'INSTALLATIONS.TYPE',
        CONSERVATION_MODE: 'QUALITOMETRES.MODE_CONSERVATION',
        PARTICULAR_SITUATION: 'QUALITOMETRES.SITUATION_PARTICULIERE',
        SAMPLE_SPEED: 'PRELEVEMENTS.VITESSE',
        REMARK_CODE: 'ANALYSES.CODEREMARQUE',
        CAVITY_TYPE: 'CAVITES.TYPE',
        CAVITY_NATURE: 'CAVITES.NATURE',
        INDUSTRIAL_SITE_FAMILY: 'SITES_INDUS.FAMILLE',
        INDUSTRIAL_SITE_SEVESO: 'SITES_INDUS.SEVESO',
        INDUSTRIAL_SITE_STATUS: 'SITES_INDUS.STATUT',
        FLOW_OBSTRUCTION_USAGE: 'OBSTACLE.USAGE',
        FLOW_OBSTRUCTION_TYPE: 'OBSTACLE.TYPE',
        FLOW_OBSTRUCTION_STATUT: 'OBSTACLE.STATUT',
        FLOW_OBSTRUCTION_HAUTEUR: 'OBSTACLE.HAUTEUR',
        FLOW_OBSTRUCTION_VALIDITE: 'OBSTACLE.VALIDITE',
        FLOW_OBSTRUCTION_DISPOSITIF: 'OBSTACLE.DISPOSITIF',
        DESPOLIATION: 'DEPOUILLEMENT',
        FONCTIONS: 'FONCTIONS',
        ORIGINE_REPERE: 'REPERES.ORIGINE',
        PIEZOMETRES_TYPE: 'PIEZOMETRES.TYPE',
        STATE_VALUE: 'ETATS.VALEURS',
        REPRESENTATIVNESS_QUALITY: 'QUALITOMETRES.CODEREPRESENTATIVITE',
        INSTALLATION_TYPE_ACCES: 'INSTALLATIONS.TYPEACCES',
        INSTALLATION_NATURE_ACCES: 'INSTALLATIONS.NATUREACCES',
        INSTALLATION_MODE_TELECOM: 'INSTALLATIONS.MODETELECOM',
        INSTALLATIONS_TRANSFORMATEUR: 'INSTALLATIONS.TRANSFORMATEUR',
        INSTALLATIONS_SECOURS: 'INSTALLATIONS.SECOURS',
        INSTALLATIONS_TYPEVERROUILLAGE: 'INSTALLATIONS.TYPEVERROUILLAGE',
        INSTALLATIONS_MODELEVERROUILLAGE: 'INSTALLATIONS.MODELEVERROUILLAGE',
        INSTALLATIONS_TYPECLOTURE: 'INSTALLATIONS.TYPECLOTURE',
        INSTALLATIONS_ETATEQUIPEMENT: 'INSTALLATIONS.ETATEQUIPEMENT',
        INSTALLATIONS_TYPEPORTAIL: 'INSTALLATIONS.TYPEPORTAIL',
        INSTALLATIONS_TYPECLES: 'INSTALLATIONS.TYPECLES',
        INSTALLATIONS_PROTECTIONPERSONNEL: 'INSTALLATIONS.PROTECTIONPERSONNEL',
        INSTALLATIONS_PROTECTION: 'INSTALLATIONS.PROTECTION',
        INSTALLATIONS_TYPEPROTECTION: 'INSTALLATIONS.TYPEPROTECTION',
        INSTALLATIONS_DECONNEXION: 'INSTALLATIONS.DECONNEXION',
        INSTALLATIONS_EMPLACEMENT: 'INSTALLATIONS.EMPLACEMENT',
        INSTALLATIONS_DEVEROUILLAGE: 'INSTALLATIONS.DEVEROUILLAGE',
        COMMUNES_SITUATION: 'COMMUNES.SITUATION',
        CONTACTS_FONCTIONS: 'CONTACTS.FONCTIONS',
        INTERVENANTS_TYPE_STRUCTURE: 'INTERVENANTS.TYPE_STRUCTURE',
        INTERVENANTS_TYPE_CODIFICATION: 'INTERVENANTS.TYPE_CODIFICATION',
        REF_TEMPORELLES: 'REF_TEMPORELLES',
        RESERVOIRS_ZONES: 'RESERVOIRS.ZONES',
        INSTALLATIONS_TYPEREJET: 'INSTALLATIONS.TYPEREJET',
        INSTALLATIONS_MESUREDEBIT: 'INSTALLATIONS.MESUREDEBIT',
        RADIO_SECURITE: 'RADIO_SECURITE',
        INSTALLATIONS_AVANCEMENTPROCEDURE: 'INSTALLATIONS.AVANCEMENTPROCEDURE',
        INSTALLATIONS_VULNERABILITE: 'INSTALLATIONS.VULNERABILITE',
        INSTALLATIONS_CAPOTAGE: 'INSTALLATIONS.CAPOTAGE',
        TUBAGES_MATERIAU: 'TUBAGES.MATERIAU',
        INSTALLATIONS_TYPECOLONNEEXHAURE: 'INSTALLATIONS.TYPECOLONNEEXHAURE',
        INSTALLATIONS_TYPEDETECTION: 'INSTALLATIONS.TYPEDETECTION',
        CUVES_MATERIAU: 'CUVES.MATERIAU',
        INSTALLATIONS_FILIERETRAITEMENT: 'INSTALLATIONS.FILIERETRAITEMENT',
        INSTALLATIONS_FILIERETRAITEMENTEBOUES: 'INSTALLATIONS.FILIERETRAITEMENTEBOUES',
        INSTALLATIONS_FILIEREELIMINATIONBOUES: 'INSTALLATIONS.FILIEREELIMINATIONBOUES',
        INSTALLATIONS_FILIERETRAITEMENTEAUXUSEES: 'INSTALLATIONS.FILIERETRAITEMENTEAUXUSEES',
        INSTALLATIONS_DISPOSITIFMESUREVOLUME: 'INSTALLATIONS.DISPOSITIFMESUREVOLUME',
        INSTALLATIONS_TYPERESERVOIR: 'INSTALLATIONS.TYPERESERVOIR',
        INSTALLATIONS_REVETEMENTCUVE: 'INSTALLATIONS.REVETEMENTCUVE',
        INSTALLATIONS_DISPOSITIFREMPLISSAGE: 'INSTALLATIONS.DISPOSITIFREMPLISSAGE',
        INSTALLATIONS_MODEREMPLISSAGE: 'INSTALLATIONS.MODEREMPLISSAGE',
        INSTALLATIONS_TYPEREMPLISSAGE: 'INSTALLATIONS.TYPEREMPLISSAGE',
        INSTALLATIONS_TYPEPOMPE: 'INSTALLATIONS.TYPEPOMPE',
        COLONNEEXHAURE_MATERIAU: 'COLONNEEXHAURE.MATERIAU',
        INSTALLATIONS_BATIMENT: 'INSTALLATIONS.BATIMENT',
        INSTALLATIONS_BASSINTAMPON: 'INSTALLATIONS.BASSINTAMPON',
        INSTALLATIONS_AUTREPROTECTION: 'INSTALLATIONS.AUTREPROTECTION',
        EQUIPEMENTS_ST_TRAITEMENT: 'EQUIPEMENTS.ST_TRAITEMENT',
        EQUIPEMENTS_POSTES_RELEVAGES: 'EQUIPEMENTS.POSTES_RELEVAGES',
        EQUIPEMENTS_ST_POMPAGES_AEP: 'EQUIPEMENTS.ST_POMPAGES_AEP',
        EQUIPEMENTS_RESERVOIRS: 'EQUIPEMENTS.RESERVOIRS',
        EQUIPEMENTS_STEP: 'EQUIPEMENTS.STEP',
        EQUIPEMENTS_CAPTAGES: 'EQUIPEMENTS.CAPTAGES',
        RESERVOIRS_ZONES_ETATS: 'RESERVOIRS.ZONES_ETATS',
        REFS_PIVEAU: 'REFS_PIVEAU',
        INSTALLATIONS_NATUREEQUIPEMENT: 'INSTALLATIONS.NATUREEQUIPEMENT',
        EVENEMENTS_ACTION: 'EVENEMENTS.ACTION',
        DISPOSITIFS_ETATS: 'DISPOSITIFS.ETATS',
        CMS_STATUT: 'CMS.STATUS',
        DECLARATIONS_STATUT: 'DECLARATIONS.STATUT',
        VISITES_ACTIONS_CATEGORIE: 'VISITES_ACTIONS.CATEGORIE',
        VISITES_ACTIONS_RISQUE: 'VISITES_ACTIONS.RISQUE',
        VISITES_ACTIONS_RESPONSABILITE: 'VISITES_ACTIONS.RESPONSABILITE',
        VISITES_ACTIONS_ECHEANCE: 'VISITES_ACTIONS.ECHEANCE',
        VISITES_ACTIONS_TYPE_INTERVENTION: 'VISITES_ACTIONS.TYPE_INTERVENTION',
        VISITES_ACTIONS_SECTEUR: 'VISITES_ACTIONS.SECTEUR',
        VISITES_TYPE: 'VISITES.TYPE',
        SITE_INDUS_EFFECTIF: 'SITE_INDUS.EFFECTIF',
        RESERVOIRS_TYPEDETECTIONNIVEAU: 'RESERVOIRS.TYPEDETECTIONNIVEAU',
        VISITES_STATUT: 'VISITES.STATUT',
        ACTIVITES_CATEGORIE: 'ACTIVITES.CATEGORIE',
        EQUIPEMENTS_TYPE_AEP: 'EQUIPEMENTS.TYPE_AEP',
        EQUIPEMENTS_TYPE_ASST: 'EQUIPEMENTS.TYPE_ASST',
        CAMPAGNES_TYPE: 'CAMPAGNES.TYPE',
        ACTIONS_STATUT: 'ACTIONS.STATUT',
        PAYS_RGPD: 'PAYS.RGPD',
        INSTALLATIONS_DANGERS: 'INSTALLATIONS.DANGERS',
        MILIEU_EAU: 'MILIEU_EAU',
        ETAT_DOSSIER: 'ETAT_DOSSIER',
        ETAT_PLAN: 'ETAT_PLAN',
        AGENCES: 'AGENCES',
        COMPTA_UNITE: 'COMPTA.UNITE',
        COMPTA_OCCURENCE: 'COMPTA.OCCURENCE',
        ANALYSES_ACCREDITATION: 'ANALYSES.ACCREDITATION',
        ANALYSES_DIFFICULTE: 'ANALYSES.DIFFICULTE',
        ANALYSES_LIEU: 'ANALYSES.LIEU',
        IRRIGATION_CATEGORIES: 'IRRIGATION.CATEGORIES',
        USAGES_TYPES_SOLS: 'USAGES.TYPES_SOLS',
        USAGES_PROFONDEUR_SOLS: 'USAGES.PROFONDEUR_SOLS',
        USAGES_TYPES: 'USAGES.TYPES',
        STATION_POMPAGE_TYPE: 'STATION_POMPAGE.TYPE',
        PREL_AGRI_TYPE_PRELEVEMENT: 'PREL_AGRI.TYPE_PRELEVEMENT',
        RESEAUX_TYPE: 'RESEAUX.TYPE',
        RESEAUX_NATURE: 'RESEAUX.NATURE',
        RESEAUX_MATIERE: 'RESEAUX.MATIERE',
        TYPE_PHOTO: 'TYPE_PHOTO',
        TYPE_DOCUMENT: 'TYPE_DOCUMENT',
        TYPE_QUALITOMETRE: 'TYPE_QUALITOMETRE',
        HYDRO_OBSERVATIONS: 'HYDRO.OBSERVATIONS',
        HYDRO_OBSERVATIONS_MODALITES_4: 'HYDRO.OBSERVATIONS_MODALITES_4',
        HYDRO_OBSERVATIONS_MODALITES_5: 'HYDRO.OBSERVATIONS_MODALITES_5',
        HYDRO_OBSERVATIONS_MODALITES_5_ID: 2,
        HYDROGEOLOGICAL_ENTITY_NATURE: 'ENTITES_HYDROGEOLOGIQUES.NATURE',
        QUALITOMETRES_HER1: 'QUALITOMETRES.HER1',
        QUALITOMETRES_HER2: 'QUALITOMETRES.HER2',
        QUALITOMETRES_TYPE_FR_PLAN: 'QUALITOMETRES.TYPE_FR.PLAN',
        QUALITOMETRES_TYPE_FR_RIVIERE: 'QUALITOMETRES.TYPE_FR.RIVIERE',
        QUALITOMETRES_RANG_STRAHLER: 'QUALITOMETRES.RANG_STRAHLER',
        QUALITE_POINT_RESSOURCE: 'QUALITE.POINT.RESSOURCE',
        MODE_GESTION: 'SECTEUR.MODE_GESTION',
        RESERVOIRS_USAGES: 'RESERVOIRS.USAGES',
        BACHE_TYPE: 'BACHE.TYPE',
        OBSTACLE_BIEF: 'OBSTACLE.BIEF',
        OBSTACLE_TYPE: 'OBSTACLE.TYPE',
        OBSTACLE_MOBILE: 'OBSTACLE.MOBILE',
        OBSTACLE_NAVIGUATION: 'OBSTACLE.NAVIGUATION',
        OBSTACLE_PISCICOLE: 'OBSTACLE.PISCICOLE',
        CAMPAGNES_STATUT: 'CAMPAGNES.STATUT',
        STEP_TYPE_RESEAU: 'STEP.TYPE_RESEAU',
        STEP_POINTS_REFERENTIELS: 'STEP.POINTS_REFERENTIELS',
        MILIEU_RECEPTEUR: 'MILIEU.RECEPTEUR',
        TAXONS_THEME: 'TAXONS.THEME',
        TAXONS_NIVEAUX: 'TAXONS.NIVEAUX',
        PGSSE_ACTIVITE: 'PGSSE_ACTIVITE',
        PGSSE_FAMILLE: 'PGSSE_FAMILLE',
        PGSSE_ETAPE: 'PGSSE_ETAPE',
        PGSSE_TYPE_DANGER: 'PGSSE_TYPE_DANGER',
    },
    REFERENCIAL_LABELS: [
        { single: 'city', plural: 'cities' },
        { single: 'contact', plural: 'contacts' },
        { single: 'network', plural: 'networks' },
        { single: 'fraction', plural: 'fractions' },
        { single: 'contributor', plural: 'contributors' },
        { single: 'watermass', plural: 'watermasses' },
        { single: 'method', plural: 'methods' },
        { single: 'parameter', plural: 'parameters' },
        { single: 'support', plural: 'supports' },
        { single: 'unit', plural: 'units' },
        { single: 'hydrogeologicalEntity', plural: 'hydrogeologicalEntities' },
        { single: 'watershed', plural: 'watersheds' },
        { single: 'taxon', plural: 'taxons' },
        { single: 'aquifer', plural: 'aquifers' },
    ],
    REFERENCIAL_TYPE_NAME: {
        activity: 'activity',
        aquifer: 'aquifer',
        watershed: 'watershed',
        city: 'city',
        contact: 'contact',
        contactGroup: 'contactGroup',
        culture: 'culture',
        cultureRPG: 'cultureRPG',
        culturesRPG: 'culturesRPG',
        culturesFamily: 'culturesFamily',
        hydrogeologicalEntity: 'hydrogeologicalEntity',
        fraction: 'fraction',
        contributor: 'contributor',
        contributorGroup: 'contributorGroup',
        lexicon: 'lexicon',
        watermass: 'watermass',
        method: 'method',
        sandreCode: 'sandreCode',
        parameter: 'parameter',
        parameterGroup: 'parameterGroup',
        country: 'country',
        network: 'network',
        support: 'support',
        taxon: 'taxon',
        materielType: 'materielType',
        unit: 'unit',
        managementUnit: 'managementUnit',
        sector: 'sector',
    },
    FR: 'FR',
    SUPPORTED_LANGUAGES: [
        { label: 'french', value: 'FR' },
        { label: 'english', value: 'EN' },
    ],
}
