import moment from 'moment'
import 'moment/locale/fr'
import { orderBy, take } from 'lodash'
import i18n from 'simple-react-i18n'
import { hasValue } from '../../../../utils/NumberUtil'
import { MODEL_PERF_CRITERIAS_CODES } from '../../../../iaeau/constants/IAEauConstants'
import { IAEAU_SCALE_COLORS } from '../../../../utils/constants/ColorTheme'
import React from 'react'

const PBiasWidth = 12 / 13
moment.locale('fr')

const frequencyColors = (nb, isInverse = false) => {
    return orderBy(take([
        [15, 'blue', '#b8b8ff'],
        [4, 'red', '#ff9494'],
        [7, 'orange', '#ffe6b8'],
        [12, 'green', '#b8dcb8'],
        [16, 'darkblue', '#9494ce'],
        [8, 'yellow', '#ffffb8'],
        [13, 'darkgreen', '#94be94'],
        [6, 'brown', '#d29b9b'],
        [17, 'purple', '#dcb8dc'],
        [18, 'darkmagenta', '#ae66ad'],
        [10, 'white', '#ffffff'],
        [1, 'black', '#b8b8b8'],
        [11, 'lightgreen', '#e0fbe0'],
        [2, 'pink', '#ffc0cb66'],
        [14, 'lightblue', '#afe2f3'],
        [3, 'magenta', '#dfb8df'],
        [5, 'indianred', '#cd5c5c'],
        [9, 'lightyellow', '#ffffe0'],
    ], nb), v => v[0], [isInverse ? 'asc' : 'desc']).map(v => v[2])
}

const getFrequencyStats = (histoYears, filteredPeriods, isInverse = false) => { // ex: filteredPeriods = ['3','5','10'] pour récupérer que triennale, quinquennale et décennale
    const stats = [
        { code: 'fre_100_H', name: i18n.fre_100_H, color: '#c2002b', nb: 0.99, minYear: 100 },
        { code: 'fre_80_H', name: i18n.fre_80_H, color: '#aa05f3', nb: 0.9875, minYear: 80 },
        { code: 'fre_50_H', name: i18n.fre_50_H, color: '#8d5d35', nb: 0.98, minYear: 50 },
        { code: 'fre_20_H', name: i18n.fre_20_H, color: '#32928b', nb: 0.95, minYear: 20 },
        { code: 'fre_10_H', name: i18n.fre_10_H, color: '#661886', nb: 0.9, minYear: 10 },
        { code: 'fre_5_H', name: i18n.fre_5_H, color: '#0c5033', nb: 0.8, minYear: 5 },
        { code: 'fre_3_H', name: i18n.fre_3_H, color: '#10436a', nb: 0.667, minYear: 3 },
        { code: 'fre_3_S', name: i18n.fre_3_S, color: '#10436a', nb: 0.333, minYear: 3 },
        { code: 'fre_5_S', name: i18n.fre_5_S, color: '#0c5033', nb: 0.2, minYear: 5 },
        { code: 'fre_10_S', name: i18n.fre_10_S, color: '#661886', nb: 0.1, minYear: 10 },
        { code: 'fre_20_S', name: i18n.fre_20_S, color: '#32928b', nb: 0.05, minYear: 20 },
        { code: 'fre_50_S', name: i18n.fre_50_S, color: '#8d5d35', nb: 0.02, minYear: 50 },
        { code: 'fre_80_S', name: i18n.fre_80_S, color: '#aa05f3', nb: 0.0125, minYear: 80 },
        { code: 'fre_100_S', name: i18n.fre_100_S, color: '#c2002b', nb: 0.01, minYear: 100 },
    ].filter(s => !hasValue(histoYears) ? true : s.minYear <= histoYears)
    const statsFiltered = filteredPeriods ? stats.filter(s => filteredPeriods.includes(s.code.split('_')[1])) : stats
    const colors = frequencyColors(statsFiltered.length, isInverse)
    return statsFiltered.map((s, idx) => ({ ...s, color: colors[idx] }))
}

const MODEL_PERF_CRITERIAS = [{
    title: 'Critère de Nash-Sutcliffe',
    code: MODEL_PERF_CRITERIAS_CODES.NASH,
    description: <span>Ce critère compare l'erreur quadratique de modélisation par rapport au carré de l'erreur entre l'observé et sa moyenne.
    Ainsi, ce critère estime la qualité du modèle par rapport à la valeur moyenne des données observées.<br/><br/>
    Si on modélise une droite égale à la valeur moyenne des données observées, le critère de Nash sera à 0.
    Si on est en dessous, le modèle est plus mauvais que cette droite moyenne, si on est à 1, la courbe prédite et la courbe observée se superpose.</span>,
    math: (<p><i>C<sub>NSE</sub></i> = 1 - <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>p,k</sub>)<sup>2</sup></sup><sub>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - <span className='average-border'>Y<sub>obs</sub></span>)<sup>2</sup></sub></span></p>),
    mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k et Yp,k les données prédites au temps k',
    mathRef: '(Nash & Sutcliffe, 1970)',
    scale: [['Très mauvais', 3, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 3, IAEAU_SCALE_COLORS.BAD, '0'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.2, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
    minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
maxScale: '1',
}, {
    title: 'Critère de persistance',
        code: MODEL_PERF_CRITERIAS_CODES.PERSISTANCE,
        description: <span>Ce critère va comparer l'erreur quadratique de modélisation avec la prévision dite « naïve », correspondant à la chronique observé décalée dans le temps d'un temps h égal à l'horizon de prévision.<br/><br/>
    Le modèle peut avoir ce comportement naïf en fonction des données qu'il reçoit, en mettant une importance beaucoup plus forte sur la variable d'intérêt et une faible importance sur les variables explicatives.<br/><br/>
    Ainsi, ce critère va pouvoir évaluer ce phénomène, en évaluant si le modèle a tendance à répéter ce qu'il a vu au temps k+h.
    Si le critère est inférieur à 0, la prévision faite est moins bonne que la prévision naïve. Quand la persistance est à 0, on modélise aussi bien que la prévision naïve et à 1, la prévision est parfaite.</span>,
        math: (<p><i>C<sub>p</sub></i> = 1 - <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k+<i>h</i></sub> - Y<sub>p,k+<i>h</i></sub>)<sup>2</sup></sup><sub>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>obs,k+<i>h</i></sub>)<sup>2</sup></sub></span></p>),
        mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k et h l\'horizon de prévision',
        mathRef: '(Kitanidis & Bras, 1980)',
        scale: [['Très mauvais', 2.4, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 2.4, IAEAU_SCALE_COLORS.BAD, '0'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.4'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.5'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.6'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.7'], ['Excellent', 2.4, IAEAU_SCALE_COLORS.EXCELLENT, '0.8']],
        minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
        maxScale: '1',
}, {
    title: 'Pourcentage de biais',
        code: MODEL_PERF_CRITERIAS_CODES.PBIAS,
        description: <span>Ce critère définit une tendance moyenne afin de savoir si les valeurs prédites sont surestimées ou sous-estimées par rapport aux valeurs mesurées.<br/><br/>
    Ce pourcentage est nul lorsque qu\'en moyenne il y a autant de surestimations que de sous-estimations.
    Si ce critère est négatif, cela signifie que le modèle a tendance à surestimer la sortie et vice-versa.</span>,
        math: (<p><i>C<sub>PBIAS</sub></i> = 100 . <span className='frac'><sup>&sum;<sup>n</sup><sub>t</sub>(Y<sub>obs,k</sub> - Y<sub>p,k</sub>)</sup><sub>&sum;<sup>n</sup><sub>t</sub>Y<sub>obs,k</sub></sub></span></p>),
        mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k',
        mathRef: '(Gupta et al., 1999)',
        scale: [['Très mauvais', PBiasWidth, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', PBiasWidth, IAEAU_SCALE_COLORS.BAD, '-10'], ['Médiocre', PBiasWidth, IAEAU_SCALE_COLORS.MEDIOCRE, '-5'], ['Moyen', PBiasWidth, IAEAU_SCALE_COLORS.MEDIUM, '-3'], ['Bon', PBiasWidth, IAEAU_SCALE_COLORS.GOOD, '-2'], ['Très bon', PBiasWidth, IAEAU_SCALE_COLORS.VERY_GOOD, '-1'], ['Excellent', PBiasWidth, IAEAU_SCALE_COLORS.EXCELLENT, '-0.5'], ['Très bon', PBiasWidth, IAEAU_SCALE_COLORS.VERY_GOOD, '0.5'], ['Bon', PBiasWidth, IAEAU_SCALE_COLORS.GOOD, '1'], ['Moyen', PBiasWidth, IAEAU_SCALE_COLORS.MEDIUM, '2'], ['Médiocre', PBiasWidth, IAEAU_SCALE_COLORS.MEDIOCRE, '3'], ['Mauvais', PBiasWidth, IAEAU_SCALE_COLORS.BAD, '5'], ['Très mauvais', PBiasWidth, IAEAU_SCALE_COLORS.VERY_BAD, '10']],
        minScale: <span style={{ fontSize: '16px' }}>-&infin;</span>,
        maxScale: <span style={{ fontSize: '16px' }}>&infin;</span>,
}, {
    title: 'Prediction Interval Coverage Probability',
        code: MODEL_PERF_CRITERIAS_CODES.PICP,
        description: <span>Ce critère dénombre le nombre moyen de valeurs de sortie incluses dans un intervalle donné.<br/><br/>
    Ce critère est à combiner avec l'intervalle de prévision moyen pour vérifier si le modèle est bon ou si le modèle génère beaucoup d'incertitudes.</span>,
        math: (<p><i>C<sub>PICP</sub></i> = <span className='frac'><sup>1</sup><sub>n</sub></span>&sum;<sup>n</sup><sub>k</sub> ƒ(Y<sub>p,k</sub>)</p>),
        mathDescription: 'avec Yobs,k les données observées de la variable d\'intérêt au temps k, Yp,k les données prédites au temps k',
        mathRef: '(Shrestha et Solomatine, 2006)',
        scale: [['Très mauvais', 4.8, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 1.2, IAEAU_SCALE_COLORS.BAD, '0.4'], ['Médiocre', 1.2, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1.2, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.2, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
        minScale: '0',
        maxScale: '1',
}, {
    title: 'Area Under Curve - Receiver Operating Characteristic',
        code: MODEL_PERF_CRITERIAS_CODES.AUC,
        description: <span>Ce critère correspond à la probabilité pour qu'un événement positif soit classé comme positif par le test sur l'étendue des valeurs seuil possibles.<br/><br/>
    Également appelée courbe sensibilité/spécificité, la courbe ROC est une mesure de la performance d'un classificateur binaire,
    c'est-à-dire d'un système qui a pour objectif de catégoriser des éléments en deux groupes distincts sur la base d'une ou plusieurs des caractéristiques de chacun de ces éléments.</span>,
        math: (<p>AUC(ƒ) = <span className='frac'><sup>&sum;<sub>t<sub>0</sub>ϵD<sup>0</sup></sub> &sum;<sub>t<sub>1</sub>ϵD<sup>1</sup></sub> 1[ƒ(t<sub>0</sub>) {'<'} ƒ(t<sub>1</sub>)]</sup><sub>|D<sup>0</sup>| ⦁ |D<sup>1</sup>|</sub></span></p>),
        mathDescription: 'avec le numérateur étant la fonction de Mann-Whitney, D0 le nombre de valeurs négatives et D1 le nombre de valeurs positives',
        mathRef: '(Mason, 1982 - Hanley & McNeil 1982)',
        scale: [['Très mauvais', 5, IAEAU_SCALE_COLORS.VERY_BAD], ['Mauvais', 1, IAEAU_SCALE_COLORS.BAD, '0.4'], ['Médiocre', 1, IAEAU_SCALE_COLORS.MEDIOCRE, '0.5'], ['Moyen', 1, IAEAU_SCALE_COLORS.MEDIUM, '0.6'], ['Bon', 1.2, IAEAU_SCALE_COLORS.GOOD, '0.7'], ['Très bon', 1.2, IAEAU_SCALE_COLORS.VERY_GOOD, '0.8'], ['Excellent', 1.6, IAEAU_SCALE_COLORS.EXCELLENT, '0.9']],
        minScale: '0',
        maxScale: '1',
}]

export { getFrequencyStats, MODEL_PERF_CRITERIAS }