import { Card, CardContent, Grid2, Typography } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import ProgressCard from 'components/card/ProgressCard'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { PATH_ALERT, PATH_ALERT_CONFIGURATION } from 'home/constants/RouteConstants'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { flatten, isNil, isNumber, pick } from 'lodash'
import moment from 'moment'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import InstallationAction from 'installation/actions/InstallationAction'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import { hasValue } from 'utils/NumberUtil'
import DtoDataType from '../../../station/dto/DtoDataType'
import SieauParameterDto from '../../dto/SieauParameterDto'
import { SIEAU } from '../user/constants/StatisticConstants'
import NumberField from 'components/forms/NumberField'
import Checkbox from 'components/forms/Checkbox'
import { PIEZO, HYDRO, PLUVIO, INSTALLATION } from '../user/constants/ApplicationHabilitationConstants'
import UserAction from '../user/actions/UserAction'
import Input from 'components/forms/Input'
import SimpleMoveMapPanel from 'referencial/components/map/SimpleMoveMapPanel'
import { CardTitle } from '../../../components/card/NewCard'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from '../../../components/styled/Accordions'
import { NewTable } from '../../../components/datatable/NewTable'
import { greyBlue, mainWhite } from '../../../utils/constants/ColorTheme'
import CloudOffIcon from '@mui/icons-material/CloudOff'

const HEADERS = ['code', 'name', 'city']
const ADMIN_ALERT_FILTERS = 'ADMIN_ALERT_FILTERS'
const DEFAULT_FILTER = -1

const AdminStations = ({
    setFilter = () => {},
    defaultFilter = {},
    stationsFormatted = [],
    stationType,
    title,
    readMode = true,
}) => {
    const {
        propsFilterResults,
    } = useSelector(store => ({
        propsFilterResults: store.StationReducer.filterResults,
    }), shallowEqual)

    const cache = pick(AdministrationAction.getCache(stationType), ['filter'])

    const firstFilteredResults = (() => {
        if (hasValue(cache.filter) && cache.filter !== DEFAULT_FILTER && propsFilterResults.length) {
            return flatten(propsFilterResults.map(stationId => stationsFormatted.find(station => station.id === stationId) || []))
        }
        return stationsFormatted || []
    })

    const [filter, setSimpleFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState(firstFilteredResults)
    const [alertLimit, setAlertLimit] = useState(defaultFilter.alertLimit)
    const [displayByDefault, setDisplayByDefault] = useState(defaultFilter.displayByDefault)
    const [displayModule, setDisplayModule] = useState(defaultFilter.displayModule)

    const data = useMemo(() => {
        if (stationType !== STATION_TYPE_NAME.installation && (!hasValue(filter) || filter === DEFAULT_FILTER)) {
            return stationsFormatted
        }
        return filterResults
    }, [filter, filterResults, stationType, stationsFormatted])

    const [openAccordion, setOpenAccordion] = useState(false)

    return (
        <>
            <AccordionMUI noUnmount round style={{ marginBottom: 10, marginTop: 10 }} expanded={openAccordion} onChange={() => setOpenAccordion(prev => !prev)}>
                <AccordionSummaryMUI round iconColor={mainWhite} style={{ background: greyBlue, color: mainWhite }}>{title}</AccordionSummaryMUI>
                <AccordionDetailsMUI>
                    <Grid2 container spacing={2}>
                        <Grid2 size={12}>
                            <Checkbox
                                componentClassName='margin-top-1'
                                label={i18n.visibleModule}
                                checked={displayModule}
                                onChange={(newDisplay) => {
                                    setDisplayModule(newDisplay)
                                    setFilter(prev => ({ ...prev, displayModule: newDisplay }))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        {displayModule ? (
                            <>
                                <Grid2 size={6}>
                                    <SimpleFilterSelect
                                        stationType={stationType}
                                        onChange={(results, newFilter) => {
                                            setFilterResults(results)
                                            setFilter(prev => ({ ...prev, filter: newFilter }))
                                            setSimpleFilter(newFilter)
                                        }}
                                        stations={stationsFormatted}
                                        value={filter}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <NumberField
                                        title={`${i18n.alertLimit} (${i18n.hours})`}
                                        value={alertLimit}
                                        onChange={(newAlertLimit) => {
                                            setAlertLimit(newAlertLimit)
                                            setFilter(prev => ({ ...prev, alertLimit: newAlertLimit }))
                                        }}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <Checkbox
                                        componentClassName='margin-top-1'
                                        label={i18n.displayByDefault}
                                        checked={displayByDefault}
                                        onChange={(newDisplay) => {
                                            setDisplayByDefault(newDisplay)
                                            setFilter(prev => ({ ...prev, displayByDefault: newDisplay }))
                                        }}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12} sx={{ padding: '5px 10px 10px' }}>
                                    {data?.length ? (<NewTable
                                        headers={HEADERS}
                                        rows={data}
                                        rowsPerPageOptions={nbPerPageLabelTiny}
                                        condensed
                                    />) : (
                                        <Grid2
                                            container
                                            size={12}
                                            justifyContent='center'
                                            alignItems='center'
                                            spacing={1}
                                            sx={{ padding: '5 0' }}
                                            color={readMode ? 'grey' : 'black'}
                                        >
                                            <CloudOffIcon />
                                            <Typography variant='h6' fontWeight='bold'>Aucune donnée à afficher</Typography>
                                        </Grid2>
                                    )}
                                </Grid2>
                            </>
                        ) : ''}
                    </Grid2>
                </AccordionDetailsMUI>
            </AccordionMUI>
        </>
    )
}

AdminStations.propTypes = {
    setFilter: PropTypes.func,
    defaultFilter: PropTypes.number,
    stationsFormatted: PropTypes.arrayOf(PropTypes.shape({
        city: PropTypes.number,
        code: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        townCode: PropTypes.string,
    })),
    stationType: PropTypes.number.isRequired,
    title: PropTypes.string,
    readMode: PropTypes.bool,
}

const AdminAlertApp = ({

}) => {
    const {
        globalParameters,
        accountUser,
        piezometersLight,
        hydrometricStations,
        pluviometers,
        installationsLight,
        cities,
        userHabilitations,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
        accountUser: store.AccountReducer.accountUser,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        installationsLight: store.InstallationReducer.installationsLight,
        cities: store.CityReducer.cities,
        userHabilitations: store.UserReducer.userHabilitations,
    }), shallowEqual)

    const adminAlertFiltersValue = useMemo(() => {
        const value = globalParameters.find(param => param.parameter === ADMIN_ALERT_FILTERS)?.value
        return !isNil(value) ? JSON.parse(value) : {}
    }, [globalParameters])

    const getFilterValue = (filter) => isNumber(filter) ? filter : DEFAULT_FILTER
    const getAlertLimit = (alertLimit, defaultLimit) => isNumber(alertLimit) ? alertLimit : defaultLimit
    const getDefaultDisplayed = (displayByDefault, defaultDisplay) => hasValue(displayByDefault) ? displayByDefault : defaultDisplay
    const getDisplayedModule = (displayModule, defaultDisplay) => hasValue(displayModule) ? displayModule : defaultDisplay

    const getDefaultFilter = (stationType) => ({
        filter: getFilterValue(adminAlertFiltersValue[stationType]?.filter),
        alertLimit: getAlertLimit(adminAlertFiltersValue[stationType]?.alertLimit, stationType !== STATION_TYPE_NAME.installation ? 120 : 72),
        displayByDefault: getDefaultDisplayed(adminAlertFiltersValue[stationType]?.displayByDefault, stationType !== STATION_TYPE_NAME.installation),
        displayModule: getDisplayedModule(adminAlertFiltersValue[stationType]?.displayModule, true),
    })

    const [readMode, setReadMode] = useState(true)
    const [piezoFilter, setPiezoFilter] = useState(getDefaultFilter(STATION_TYPE_NAME.piezometry))
    const [hydroFilter, setHydroFilter] = useState(getDefaultFilter(STATION_TYPE_NAME.hydrometry))
    const [pluvioFilter, setPluvioFilter] = useState(getDefaultFilter(STATION_TYPE_NAME.pluviometry))
    const [instFilter, setInstFilter] = useState(getDefaultFilter(STATION_TYPE_NAME.installation))

    const [territoryStateLink, setTerritoryStateLink] = useState(adminAlertFiltersValue?.territoryStateLink)
    const [territoryImageLink, setTerritoryImageLink] = useState(adminAlertFiltersValue?.territoryImageLink)
    const [widgetMeteofrance, setWidgetMeteofrance] = useState(adminAlertFiltersValue?.widgetMeteofrance)
    const [automaticRefresh, setAutomaticRefresh] = useState(adminAlertFiltersValue?.automaticRefresh)

    const [longitude, setLongitude] = useState(adminAlertFiltersValue?.longitude)
    const [latitude, setLatitude] = useState(adminAlertFiltersValue?.latitude)
    const [zoom, setZoom] = useState(adminAlertFiltersValue?.zoom)

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.situation,
        href: PATH_ALERT,
    }, {
        title: i18n.alertOptions,
        href: PATH_ALERT_CONFIGURATION,
    }], [])

    const {
        isLoaded: dataIsLoaded,
        progress: dataProgress,
    } = useProgressDispatch(() => {
        const promises = [AdministrationAction.fetchGlobalParameters, CityAction.fetchCities]
        const promisesPiezo = !piezometersLight.length ? [...promises, PiezometryAction.fetchPiezometersLight] : promises
        const promisesHydro = !hydrometricStations.length ? [...promisesPiezo, HydrometryAction.fetchHydrometricStations] : promisesPiezo
        const promisesPluvio = !pluviometers.length ? [...promisesHydro, PluviometryAction.fetchPluviometers] : promisesHydro
        const promisesInst = !installationsLight.length ? [...promisesPluvio, InstallationAction.fetchInstallationsLight] : promisesPluvio
        const promisesHabs = !userHabilitations.length ? [...promisesInst, () => UserAction.fetchUserHabilitations(accountUser.login)] : promisesInst
        return promisesHabs.map(p => dispatch(p()))
    }, [])

    const initValues = () => {
        setPiezoFilter(getDefaultFilter(STATION_TYPE_NAME.piezometry))
        setHydroFilter(getDefaultFilter(STATION_TYPE_NAME.hydrometry))
        setPluvioFilter(getDefaultFilter(STATION_TYPE_NAME.pluviometry))
        setInstFilter(getDefaultFilter(STATION_TYPE_NAME.installation))

        setTerritoryStateLink(adminAlertFiltersValue?.territoryStateLink)
        setTerritoryImageLink(adminAlertFiltersValue?.territoryImageLink)
        setWidgetMeteofrance(adminAlertFiltersValue?.widgetMeteofrance)
        setAutomaticRefresh(adminAlertFiltersValue?.automaticRefresh)

        setLongitude(adminAlertFiltersValue?.longitude)
        setLatitude(adminAlertFiltersValue?.latitude)
        setZoom(adminAlertFiltersValue?.zoom)
    }

    useEffect(() => {
        if (dataIsLoaded) {
            initValues()
        }
    }, [dataIsLoaded])

    const onSave = () => {
        const param = new GlobalParametersDto({
            parameter: ADMIN_ALERT_FILTERS,
            module: SIEAU,
            value: JSON.stringify({
                [STATION_TYPE_NAME.piezometry]: piezoFilter,
                [STATION_TYPE_NAME.hydrometry]: hydroFilter,
                [STATION_TYPE_NAME.pluviometry]: pluvioFilter,
                [STATION_TYPE_NAME.installation]: instFilter,

                territoryStateLink,
                territoryImageLink,
                widgetMeteofrance,
                automaticRefresh,

                longitude,
                latitude,
                zoom,
            }),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(json => {
            if (json > 0) {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            }
        })
        setReadMode(true)
    }

    useActions(() => {
        if (readMode) {
            return {
                edit: () => setReadMode(false),
            }
        }
        return {
            save: onSave,
            cancel: () => {
                setReadMode(true)
                initValues()
            },
        }
    }, [readMode, piezoFilter, hydroFilter, pluvioFilter, instFilter, territoryStateLink, territoryImageLink, widgetMeteofrance, automaticRefresh, longitude,
        latitude, zoom])


    const formatData = (data) => data.map(({ id, code, name, townCode }) => {
        const city = cities.find(c => c.id === townCode) || {}
        return {
            id,
            code,
            name,
            townCode,
            city: city.labelWithCode || `${townCode || ''} ${city.name || ''}`,
        }
    })

    const piezoFormatted = useMemo(() => formatData(piezometersLight), [cities, piezometersLight])
    const hydroFormatted = useMemo(() => formatData(hydrometricStations), [cities, hydrometricStations])
    const pluvioFormatted = useMemo(() => formatData(pluviometers), [cities, pluviometers])
    const instFormatted = useMemo(() => formatData(installationsLight), [cities, installationsLight])

    const hasPiezoHabilitation = useMemo(() => userHabilitations.some(h => h.habilitation === PIEZO), [userHabilitations])
    const hasHydroHabilitation = useMemo(() => userHabilitations.some(h => h.habilitation === HYDRO), [userHabilitations])
    const hasPluvioHabilitation = useMemo(() => userHabilitations.some(h => h.habilitation === PLUVIO), [userHabilitations])
    const hasInstHabilitation = useMemo(() => userHabilitations.some(h => h.habilitation === INSTALLATION), [userHabilitations])

    return (
        <Grid2 container size={12}>
            {!dataIsLoaded ? (
                <Grid2 size={12}>
                    <ProgressCard progress={dataProgress} className='padding-top-1' />
                </Grid2>
            ) : (
                <Grid2 container size={12} spacing={1} sx={{ marginBottom: '100px', padding: '0 5 0 10' }} alignItems='flex-start'>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.defaultSettings} />
                            <CardContent>
                                <Grid2 container size={12} px={1} spacing={1} justifyContent='space-between'>
                                    <Grid2 size={6}>
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.newsletter}</StyledLegend>
                                            <Grid2 container sx={{ paddingTop: '0.5rem' }}>
                                                <Grid2 size={6}>
                                                    <NumberField
                                                        title={`${i18n.automaticRefresh} (min)`}
                                                        value={automaticRefresh}
                                                        onChange={setAutomaticRefresh}
                                                        disabled={readMode}
                                                        min={0.5}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </StyledFieldSet>
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.map}</StyledLegend>
                                            <Grid2 container spacing={1}>
                                                <Grid2 size={4}>
                                                    <NumberField
                                                        title={i18n.x}
                                                        value={longitude}
                                                        onChange={setLongitude}
                                                        disabled={readMode}
                                                        floatValue
                                                    />
                                                </Grid2>
                                                <Grid2 size={4}>
                                                    <NumberField
                                                        title={i18n.y}
                                                        value={latitude}
                                                        onChange={setLatitude}
                                                        disabled={readMode}
                                                        floatValue
                                                    />
                                                </Grid2>
                                                <Grid2 size={4}>
                                                    <NumberField
                                                        title={i18n.zoom}
                                                        value={zoom}
                                                        onChange={setZoom}
                                                        disabled={readMode}
                                                        floatValue
                                                    />
                                                </Grid2>
                                                <Grid2 size={12}>
                                                    <SimpleMoveMapPanel
                                                        station={{ x: adminAlertFiltersValue?.longitude, y: adminAlertFiltersValue?.latitude, projection: 16 }}
                                                        zoom={adminAlertFiltersValue?.zoom}
                                                        editMap={!readMode}
                                                        handleChangeValue={newLoc => {
                                                            setLongitude(newLoc.x)
                                                            setLatitude(newLoc.y)
                                                            setZoom(newLoc.zoom)
                                                        }}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </StyledFieldSet>
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.linksSetting} />
                            <CardContent>
                                <Grid2 container sx={{ padding: '0 10px 10px' }}>
                                    <Grid2 size={12}>
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.vigicrues}</StyledLegend>
                                            <Grid2 container spacing={2}>
                                                <Grid2 size={6}>
                                                    <Input
                                                        title={i18n.territoryStateLink}
                                                        value={territoryStateLink}
                                                        onChange={setTerritoryStateLink}
                                                        disabled={readMode}
                                                    />
                                                </Grid2>
                                                <Grid2 size={6}>
                                                    <Input
                                                        title={i18n.territoryImageLink}
                                                        value={territoryImageLink}
                                                        onChange={setTerritoryImageLink}
                                                        disabled={readMode}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </StyledFieldSet>
                                        <StyledFieldSet>
                                            <StyledLegend>{i18n.meteofrance}</StyledLegend>
                                            <Grid2 container spacing={2}>
                                                <Grid2 size={6}>
                                                    <Input
                                                        title={i18n.widgetMeteofrance}
                                                        value={widgetMeteofrance}
                                                        onChange={setWidgetMeteofrance}
                                                        disabled={readMode}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </StyledFieldSet>
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Card>
                        <CardTitle title={i18n.stationsSetting} />
                        <CardContent>
                            <Grid2 container sx={{ padding: '0 10px 10px' }}>
                                {(piezoFormatted.length && hasPiezoHabilitation) ? (
                                    <Grid2 size={12}>
                                        <AdminStations
                                            defaultFilter={piezoFilter}
                                            setFilter={setPiezoFilter}
                                            stationsFormatted={piezoFormatted}
                                            stationType={STATION_TYPE_NAME.piezometry}
                                            title={i18n.piezometers}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                ) : ''}
                                {(hydroFormatted.length && hasHydroHabilitation) ? (
                                    <Grid2 size={12}>
                                        <AdminStations
                                            defaultFilter={hydroFilter}
                                            setFilter={setHydroFilter}
                                            stationsFormatted={hydroFormatted}
                                            stationType={STATION_TYPE_NAME.hydrometry}
                                            title={i18n.hydrometricStations}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                ) : ''}
                                {(pluvioFormatted.length && hasPluvioHabilitation) ? (
                                    <Grid2 size={12}>
                                        <AdminStations
                                            defaultFilter={pluvioFilter}
                                            setFilter={setPluvioFilter}
                                            stationsFormatted={pluvioFormatted}
                                            stationType={STATION_TYPE_NAME.pluviometry}
                                            title={i18n.pluviometricStations}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                ) : ''}
                                {(instFormatted.length && hasInstHabilitation) ? (
                                    <Grid2 size={12}>
                                        <AdminStations
                                            defaultFilter={instFilter}
                                            setFilter={setInstFilter}
                                            stationsFormatted={instFormatted}
                                            stationType={STATION_TYPE_NAME.installation}
                                            title={i18n.installations}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                ) : ''}
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            )}
        </Grid2>
    )
}

AdminAlertApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDataType)),
    setTitle: PropTypes.func,
    updateDataTypesByProject: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    push: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

export default AdminAlertApp
