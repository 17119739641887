let jsonObj = {}
let jsonArr = []

const foundLang = (obj, lang) => {
    const t = obj.find(el => el.lang[0] === lang[0] && el.lang[1] === lang[1])
    if (t) return t
    return obj.find(el => el.lang[0] === lang[0])
}


const register = (json, lang) => {
    const jsonFound = jsonArr.find(j => j.lang.toString() == lang.toString())
    if (jsonFound) {
        jsonArr.splice(jsonArr.indexOf(jsonFound), 1)
        jsonFound.json = Object.assign({}, jsonFound.json, json)
        jsonArr.push(jsonFound)
    } else {
        jsonArr.push({ lang, json })
    }
}

const init = () => {
    const userLg = (navigator.language || navigator.userLanguage).match(/([A-z]){2}/g)
    jsonObj = (() => {
        const jsonlg = foundLang(jsonArr, userLg)
        const defaut = jsonArr.find((element) => element.lang == 'default')
        if (jsonlg) return jsonlg.json
        else if (defaut) return defaut.json
        throw new Error('Cannot found lang')
    })()
}

const clear = () => {
    jsonArr = []
    jsonObj = {}
}

module.exports = new Proxy({}, {
    get(_, prop) {
        if (prop === 'import' || prop === 'addLang') return register
        else if (prop === 'init') return init
        else if (prop === '__esModule') return false
        else if (prop === 'clear') return clear
        else if (prop in jsonObj) return jsonObj[prop]
        throw new Error(`[i18n] Cannot found ${prop} property`)
    },
})
