import React, { useEffect, useMemo } from 'react'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import { SANDRE } from '../../constants/ReferencialConstants'
import NumberField from '../../../components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { orderBy, uniqBy } from 'lodash'
import { Grid2 } from '@mui/material'
import Textarea from 'components/forms/Textarea'
import { shallowEqual, useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import useSandreList from 'utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'
import { useParams } from 'react-router'

const SandreCodePanel = ({
    sandreCode = {},
    readMode = {},
    onChange = () => { },
    field,
    isSandre,
}) => {
    const { field: uriField } = useParams()
    const {
        referencialSandreCodes,
        sandreCodes,
    } = useSelector(store => ({
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const dispState = useSandreList(SANDRE.DISPOSITIFS_ETATS)

    const isReferencialSC = useMemo(() => {
        return (hasValue(field) && referencialSandreCodes.some(s => s.field === field && s.code)) || isSandre
    }, [])

    useEffect(() => {
        if (uriField === 'new') {
            onChange({ code: undefined, field: undefined })
        }
    }, [])

    const onChangeField = (value) => {
        if (uriField === 'new') {
            onChange({ ...value, code: Math.max(...sandreCodes.filter(s => s.field === value.field).map(s => s.code)) + 1 })
        } else {
            onChange(value)
        }
    }

    return (
        <Grid2 container>
            <Grid2 container size={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid2 size={3}>
                    <NumberField
                        title={i18n.code}
                        value={sandreCode.code}
                        onChange={v => onChange({ code: v })}
                        disabled={readMode}
                        obligatory
                        data-cy='code_lexicon'
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Input
                        title={i18n.name}
                        value={sandreCode.name}
                        onChange={v => onChange({ name: v })}
                        maxLength={100}
                        disabled={readMode}
                        obligatory
                        data-cy='name_lexicon'
                    />
                </Grid2>
                {!isReferencialSC && (
                    <Grid2 size={6}>
                        <Select
                            label={i18n.lexicons}
                            value={sandreCode.field}
                            options={orderBy(uniqBy(sandreCodes.filter(sc => !referencialSandreCodes.some(rsf => rsf.code && rsf.field === sc.field)), 'field'), 'field')}
                            onChange={v => onChangeField({ field: v })}
                            keyValue='field'
                            keyLabel='field'
                            disabled={readMode}
                            noNullValue
                            obligatory
                            data-cy='field_lexicon'
                        />
                    </Grid2>
                )}
                {isReferencialSC && (
                    <Grid2 size={6}>
                        <Select
                            label={i18n.nomenclature}
                            value={sandreCode.field}
                            options={orderBy(uniqBy(referencialSandreCodes.filter(rsf => rsf.code), 'field'), 'field')}
                            onChange={v => onChangeField({ field: v })}
                            keyValue='field'
                            keyLabel='field'
                            disabled={readMode}
                            noNullValue
                            obligatory
                        />
                    </Grid2>
                )}
            </Grid2>
            <Grid2 container size={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid2 size={6}>
                    <Input
                        title={i18n.mnemonique}
                        value={sandreCode.mnemonique}
                        onChange={v => onChange({ mnemonique: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='mnemonique_lexicon'
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Input
                        title={i18n.reference}
                        value={sandreCode.reference}
                        onChange={v => onChange({ reference: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='reference_lexicon'
                    />
                </Grid2>
            </Grid2>
            <Grid2 container size={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid2 size={6}>
                    <Input
                        title={i18n.author}
                        value={sandreCode.author}
                        onChange={v => onChange({ author: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='author_lexicon'
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        options={dispState}
                        label={i18n.status}
                        value={sandreCode.status}
                        onChange={v => onChange({ status: v })}
                        disabled={readMode}
                        data-cy='status_lexicon'
                    />
                </Grid2>
            </Grid2>
            <Grid2 container size={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        id='creation_lexicon'
                        label={i18n.creationDate}
                        value={sandreCode.creation}
                        onChange={(v) => onChange({ creation: v })}
                        disabled={readMode}
                        data-cy='creation_lexicon'
                    />
                </Grid2>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        id='updateDate_lexicon'
                        label={i18n.updateDate}
                        value={sandreCode.updateDate}
                        onChange={(v) => onChange({ updateDate: v })}
                        disabled={readMode}
                        data-cy='updateDate_lexicon'
                    />
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <Textarea
                    title={i18n.comment}
                    value={sandreCode.comment}
                    onChange={v => onChange({ comment: v })}
                    disabled={readMode}
                    data-cy='comment_lexicon'
                />
            </Grid2>
        </Grid2>
    )
}

SandreCodePanel.propTypes = {
    sandreCode: PropTypes.shape({}),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    field: PropTypes.string,
    isSandre: PropTypes.bool,
}

export default SandreCodePanel