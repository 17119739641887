import { MONTHS } from '../../suivi/constants/PiezometerSuiviConstants'
import { maxBy, meanBy, minBy, round, sumBy } from 'lodash'

export default class DtoMonthValues {
    constructor(day, arrayValues) {
        this.day = day
        MONTHS.map((month, index) => {
            this[month] = arrayValues[index]
        })
        const dataWithValues = arrayValues.filter(data => data.value && data.value !== null)
        this.min = minBy(dataWithValues, 'value') ? { value: minBy(dataWithValues, 'value').value, color: 'orange' } : { value: null, color: 'orange' }
        this.mean = meanBy(dataWithValues, 'value') ? { value: round(meanBy(dataWithValues, 'value'), 2), color: 'orange' } : { value: null, color: 'orange' }
        this.max = maxBy(dataWithValues, 'value') ? { value: maxBy(dataWithValues, 'value').value, color: 'orange' } : { value: null, color: 'orange' }
        this.sum = sumBy(dataWithValues, 'value') ? { value: round(sumBy(dataWithValues, 'value'), 2), color: 'orange' } : { value: null, color: 'orange' }
        this.headers = ['day'].concat(MONTHS).concat(['min', 'mean', 'max', 'sum'])
    }
}
