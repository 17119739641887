import { Button, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { SELECTION, THRESHOLD } from 'quality/constants/QualityConstants'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'

const HydrobioMonitoringFilter = ({
    onValidate = () => { },
    defaultFilter = {},
}) => {
    const {
        taxonsProps,
        parameters,
        contributors,
        supports,
        hydrobioOperations,
        hydrobioLists,
        searchIndices,
    } = useSelector(store => ({
        taxonsProps: store.TaxonReducer.taxons,
        parameters: store.ParameterReducer.parameters,
        contributors: store.ContributorReducer.contributors,
        supports: store.SupportReducer.supports,
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        hydrobioLists: store.OperationReducer.hydrobioLists,
        searchIndices: store.QualityReducer.searchIndices,
    }), shallowEqual)

    const sandrePhase = useSandreList('PRELEVEMENTS.PHASE')

    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [taxons, setTaxons] = useState(defaultFilter.taxons)
    const [indices, setIndices] = useState(defaultFilter.indices)

    const [taxonSelection, setTaxonSelection] = useState(defaultFilter.taxonSelection)
    const [taxonSelectionResults, setTaxonSelectionResults] = useState(defaultFilter.taxonSelectionResults)
    const [taxonThreshold, setTaxonThreshold] = useState(defaultFilter.taxonThreshold)
    const [indiceSelection, setIndiceSelection] = useState(defaultFilter.indiceSelection)
    const [indiceSelectionResults, setIndiceSelectionResults] = useState(defaultFilter.indiceSelectionResults)
    const [indiceThreshold, setIndiceThreshold] = useState(defaultFilter.indiceThreshold)

    const [producers, setProducers] = useState(defaultFilter.producers ?? [])
    const [samplers, setSamplers] = useState(defaultFilter.samplers ?? [])
    const [determiners, setDeterminers] = useState(defaultFilter.determiners ?? [])
    const [point, setPoint] = useState(defaultFilter.point)
    const [support, setSupport] = useState(defaultFilter.support)
    const [phases, setPhases] = useState(defaultFilter.phases ?? [])

    const producerList = useMemo(() => {
        const uniqByProducer = uniqBy(hydrobioOperations, 'producer')
        const contributorList = uniqByProducer.map(op => hasValue(op.producer) && contributors.find(p => p.id === op.producer))
        return contributorList.filter(c => !!c)
    }, [contributors, hydrobioOperations])

    const samplerList = useMemo(() => {
        const uniqBySampler = uniqBy(hydrobioOperations, 'sampler')
        const contributorList = uniqBySampler.map(op => hasValue(op.sampler) && contributors.find(p => p.id === op.sampler))
        return contributorList.filter(c => !!c)
    }, [contributors, hydrobioOperations])

    const determinerList = useMemo(() => {
        const uniqByDeterminer = uniqBy(hydrobioOperations, 'determiner')
        const contributorList = uniqByDeterminer.map(op => hasValue(op.determiner) && contributors.find(p => p.id === op.determiner))
        return contributorList.filter(c => !!c)
    }, [contributors, hydrobioOperations])

    const supportsFiltered = useMemo(() => {
        const uniqSupport = uniqBy(hydrobioOperations, 'support').map(o => `${o.support}`)
        return uniqSupport.map(code => supports.find(s => s.code === code))
    }, [hydrobioOperations, supports])

    const taxonsFiltered = useMemo(() => {
        const listCode = uniqBy(hydrobioLists, 'taxon').map(l => l.taxon)
        return taxonsProps.filter(t => listCode.includes(t.code))
    }, [hydrobioLists, taxonsProps])

    const filteredParameters = useMemo(() => {
        const listCode = uniqBy(searchIndices, 'parameter').map(l => l.parameter)
        return parameters.filter(t => listCode.includes(t.code))
    }, [parameters, searchIndices])

    return (
        <div style={{ margin: '0 20px 10px 20px' }}>
            <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832', padding: '10px 0 5px 0' }}>
                <Grid2 container columnSpacing={2} style={{ padding: '0 20' }} alignItems='center'>
                    <Grid2 size={8/3}>
                        <SelectionSelect
                            onChange={(results, value) => {
                                setTaxonSelection(value)
                                setTaxonSelectionResults(results)
                            }}
                            value={taxonSelection}
                            type={SELECTION.TAXON}
                            label={i18n.taxonSelection}
                        />
                    </Grid2>
                    <Grid2 size={8/3}>
                        <SuperMultiAutocomplete
                            label={i18n.taxons}
                            options={taxonsFiltered}
                            values={taxons}
                            onChange={setTaxons}
                            keyLabel='labelWithCode'
                            keyValue='code'
                            multiple
                            limit={2}
                        />
                    </Grid2>
                    <Grid2 size={8/3}>
                        <ThresholdSelect
                            onChange={setTaxonThreshold}
                            selected={taxonThreshold}
                            thresholdType={THRESHOLD.TAXON}
                            title={i18n.taxonThreshold}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker
                            id='startDate'
                            label={i18n.startDate}
                            value={startDate}
                            onChange={setStartDate}
                            max={endDate}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker
                            id='endDate'
                            label={i18n.endDate}
                            value={endDate}
                            onChange={setEndDate}
                            min={startDate}
                        />
                    </Grid2>

                    <Grid2 size={8/3}>
                        <SelectionSelect
                            onChange={(results, value) => {
                                setIndiceSelection(value)
                                setIndiceSelectionResults(results)
                            }}
                            value={indiceSelection}
                            type={SELECTION.INDICE}
                            label={i18n.indiceSelection}
                        />
                    </Grid2>
                    <Grid2 size={8/3}>
                        <SuperMultiAutocomplete
                            label={i18n.indices}
                            options={filteredParameters}
                            values={indices}
                            onChange={setIndices}
                            keyLabel='labelWithCode'
                            keyValue='code'
                            multiple
                            limit={1}
                        />
                    </Grid2>
                    <Grid2 size={8/3}>
                        <ThresholdSelect
                            onChange={setIndiceThreshold}
                            selected={indiceThreshold}
                            thresholdType={THRESHOLD.INDICE}
                            title={i18n.indiceThreshold}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Button
                            fullWidth
                            onClick={() => onValidate({
                                startDate,
                                endDate,
                                taxons,
                                indices,

                                taxonSelection,
                                taxonSelectionResults,
                                taxonThreshold,
                                indiceSelection,
                                indiceSelectionResults,
                                indiceThreshold,

                                producers,
                                samplers,
                                determiners,
                                point,
                                support,
                                phases,
                            })}
                            variant='contained'
                        >
                            {i18n.visualize}
                        </Button>
                    </Grid2>
                </Grid2>
            </div>
            <CollapseTopBar>
                <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832' }}>
                    <Grid2 container columnSpacing={2} style={{ padding: '0 20 5 20' }}>
                        <Grid2 size={4}>
                            <MultiContributorsAutocomplete
                                options={producerList}
                                label={i18n.producer}
                                onChange={setProducers}
                                keyLabel='labelDisplay'
                                displayWithCode
                                multiple
                                limit={2}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <MultiContributorsAutocomplete
                                options={determinerList}
                                label={i18n.determiner}
                                onChange={setDeterminers}
                                keyLabel='labelDisplay'
                                displayWithCode
                                multiple
                                limit={2}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <MultiContributorsAutocomplete
                                options={samplerList}
                                label={i18n.sampler}
                                onChange={setSamplers}
                                keyLabel='labelDisplay'
                                displayWithCode
                                multiple
                                limit={2}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Select
                                options={[]}
                                value={point}
                                label={i18n.samplePoint}
                                keyLabel='labelWithCode'
                                keyValue='idPoint'
                                integerValue
                                disabled
                                onChange={setPoint}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Select
                                label={i18n.support}
                                options={supportsFiltered}
                                value={support}
                                onChange={setSupport}
                                keyValue='id'
                                clearFunction
                                displayWithCode
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <SuperMultiAutocomplete
                                label={i18n.phases}
                                options={sandrePhase}
                                values={phases}
                                onChange={setPhases}
                                multiple
                                limit={4}
                                keyValue='code'
                            />
                        </Grid2>
                    </Grid2>
                </div>
            </CollapseTopBar>
        </div>
    )
}

HydrobioMonitoringFilter.propTypes = {
    onValidate: PropTypes.func,
    defaultFilter: PropTypes.shape({
        groupEquivalences: PropTypes.bool,
    }),
}

export default HydrobioMonitoringFilter