import { Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import DtoWaterTurnsRestriction from 'territory/dto/DtoWaterTurnsRestriction'
import i18n from 'simple-react-i18n'
import { getSquare } from 'exploitations/utils/AgriUtils'

const RestictionsDisplay = ({
    restrictions = [],
}) => {
    const xRestrictions = useMemo(() => [
        ...restrictions,
        {
            color: '#52D100',
            label: 'Aucune restriction',
            level: 'X',
        },
    ], [restrictions])
    return (
        <Grid2 container size={12} justifyContent='space-evenly'>
            {xRestrictions.length ? xRestrictions.map(restriction => (
                <Grid2 key={`restriction_${restriction.level}`}>
                    {getSquare(restriction.color, true)} {restriction.label} ({restriction.level})
                </Grid2>
            )) : i18n.noRestrictions}
        </Grid2>
    )
}

RestictionsDisplay.propTypes = {
    restrictions: PropTypes.arrayOf(DtoWaterTurnsRestriction),
}

export default RestictionsDisplay