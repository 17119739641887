/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getUdiSectorName } from 'pgsse/util/PgsseUtil'
import InstallationAction from 'installation/actions/InstallationAction'
import Form from './form/Form'
import PgsseAction from 'pgsse/action/PgsseAction'
import { useParams } from 'react-router'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { getLabel } from 'utils/StoreUtils'

const PgsseCampaignFormApp = ({
}) => {
    const dispatch = useDispatch()
    const { idCampaign, idDiagnostic, idForm } = useParams()

    useEffect(() => {
        dispatch(PgsseAction.fetchDiagnostic(idDiagnostic))
        dispatch(PgsseAction.fetchCampaign(idCampaign))
    }, [idCampaign, idDiagnostic])

    const {
        campaign,
        distributionUnits,
        sectors,
        diagnostic,
        form,
        installation,
        installations,
    } = useSelector(store => ({
        campaign: store.PgsseReducer.campaign,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        diagnostic: store.PgsseReducer.diagnostic,
        form: store.PgsseReducer.form,
        installation: store.InstallationReducer.installation,
        installations: store.InstallationReducer.installationsLight,
    }), shallowEqual)

    useTitle(() => {
        const udi = distributionUnits.find(du => du.id === diagnostic.idUdi)
        const sector = sectors.find(s => s.id === diagnostic.idSector)
        return [{
            title: i18n.pgsse,
            href: PATH_PGSSE,
        }, {
            title: i18n.phase1,
            href: `${PATH_PGSSE}/phase1`,
        }, {
            title: getUdiSectorName(udi, sector),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}`,
        }, {
            title: campaign.name,
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}/campaign/${campaign.idCampaign}`,
        }, {
            title: getLabel(installations, form.code),
            href: `${PATH_PGSSE}/phase1/${diagnostic.idDiag}/campaign/${campaign.idCampaign}/${idForm}`,
        }]
    }, [diagnostic, installation, distributionUnits, sectors, campaign, installations, form])

    useEffect(() => {
        if (form.stationType === STATION_TYPE_CONSTANT.INSTALLATION) {
            dispatch(InstallationAction.fetchInstallation(form.code))
        }
    }, [form])

    return <Form title={getLabel(installations, form.code)} />
}

PgsseCampaignFormApp.propTypes = {
}

export default PgsseCampaignFormApp
