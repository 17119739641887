import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import Icon from 'components/icon/Icon'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, maxBy, minBy, orderBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import DtoAnalysis from 'quality/dto/DtoAnalysis'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import WaitAction from 'wait/WaitAction'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'
import DtoHydrometricBruteMeasure from '../../../../../hydrometry/dto/DtoHydrometricBruteMeasure'
import DtoHydrometryDataTypeSituation from '../../../../../hydrometry/dto/DtoHydrometryDataTypeSituation'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../../../store/AppStore'
import { getDate } from '../../../../../utils/DateUtil'
import { getHardHydroDataTypes } from '../../../../../utils/HydroUtils'
import { hasBooleanValue } from '../../../../../utils/NumberUtil'
import { execByType } from '../../../../../utils/StationUtils'
import { arrayOf, getLabel, instanceOf } from '../../../../../utils/StoreUtils'
import { STATION_TYPE_NAME } from '../../../../constants/StationConstants'
import DtoHydrometerAdditionalMeasures from '../../../../dto/hydrometricStation/DtoHydrometerAdditionalMeasures'
import DtoMeasureStats from '../../../../dto/piezometer/DtoMeasureStats'
import DtoPiezometerAdditionalMeasures from '../../../../dto/piezometer/DtoPiezometerAdditionalMeasures'
import DtoPiezometerSample from '../../../../dto/piezometer/DtoPiezometerSample'
import DtoPiezometryStationMeasure from '../../../../dto/piezometer/DtoPiezometryStationMeasure'
import DtoOperation from '../../../suivipc/qualitometer/dto/DtoOperation'
import DtoQualitoDashboard from '../../../../../quality/components/dashboard/dto/DtoQualitoDashboard'

moment.locale('fr')

class StationDashboardKeyFigurePanel extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        if (this.props.stationTypes.includes('hydrometry')) {
            this.props.fetchHydrometryDataTypes().then(() => {
                const dataTypes = [ ...getHardHydroDataTypes(), ...this.props.hydrometryDataTypes ]
                this.props.loadHydrometryRawSituations([parseInt(this.props.id)], dataTypes.map(d => d.id))
            })
        }
    }

    getDateByMinOrMax = (list, filter) => {
        const result = filter(list, (o) => {
            return o.date
        })
        if (result) {
            return getDate(result.date)
        }
        return ''
    }

    getLastDateWithStatus = (tabMeasures, status) => {
        const filteredMeasures = tabMeasures.filter(measure => measure.validity == status)
        return filteredMeasures.length !== 0 ? getDate(moment(maxBy(filteredMeasures, 'date').date)) : ''
    }

    getMeasuresData = () => {
        if (this.props.measures.length) {
            return {
                title: 'Chronique',
                begin: getDate(moment(minBy(this.props.measures, 'date').date)),
                end: getDate(moment(maxBy(this.props.measures, 'date').date)),
                nbOperation: this.props.measures.length,
                control: this.getLastDateWithStatus(this.props.measures, '2'),
                validation: this.getLastDateWithStatus(this.props.measures, '3'),
            }
        }
        return []
    }

    getKeyFiguresObj = (measures, title, dateKey = 'date') => {
        return {
            title,
            begin: measures.length ? getDate(minBy(measures, dateKey)[dateKey]) : '',
            end: measures.length ? getDate(maxBy(measures, dateKey)[dateKey]) : '',
            nbOperation: measures.length,
            control: this.getLastDateWithStatus(measures, 2),
            validation: this.getLastDateWithStatus(measures, 3),
        }
    }


    getHistory = () => {
        return flatten(execByType(this.props.stationTypes[0], {
            quality: () => {
                const hydrobioBeginDate = this.getDateByMinOrMax(this.props.hydrobioOperations, minBy)
                const hydrobioEndDate = this.getDateByMinOrMax(this.props.hydrobioOperations, maxBy)
                const operationsData = (() => {
                    const stats = this.props.qualitoDashboard?.stats
                    if (stats) {
                        return [{
                            title: i18n.physicochemical,
                            begin: getDate(stats.startDate),
                            end: getDate(stats.endDate),
                            nbOperation: stats.nbOperations,
                            nbAnalysis: stats.nbAnalysis,
                            control: getDate(stats.nv1),
                            validation: getDate(stats.nv2),
                        }, {
                            title: i18n.hydrobiology,
                            begin: hydrobioBeginDate,
                            end: hydrobioEndDate,
                            nbOperation: this.props.hydrobioOperations.length,
                            nbAnalysis: '',
                            validation: hydrobioEndDate,
                            control: '',
                        }].filter(s => s.nbOperation)
                    }
                    return []
                })()
                return operationsData.concat(this.getMeasuresData())
            },
            piezometry: () => {
                return orderBy(this.props.piezometerStatistics, 'order', 'asc').map(stat => {
                    const comment = stat?.comment ? (<Icon
                        icon='info'
                        style={{ fontSize: 18, color: 'grey' } }
                        tooltip={ stat.comment }
                    />) : ''
                    const displayOff = stat?.showData === false ? (<Icon
                        icon='visibility_off'
                        style={{ fontSize: 18, color: 'grey' } }
                        tooltip='Affichage désactivé'
                    />) : ''
                    return {
                        title: <>{stat.label} {comment} {displayOff}</>,
                        ...(this.isMultiPoint() ? { point: stat.namePoint } : {}),
                        begin: getDate(stat.startDate),
                        end: getDate(stat.endDate),
                        nbOperation: stat.countTotal || '',
                        control: getDate(stat.control),
                        validation: getDate(stat.validation),
                    }
                }).filter(s => s.nbOperation)
            },
            hydrometry: () => {
                const dataTypes = [ ...getHardHydroDataTypes(), ...this.props.hydrometryDataTypes ]
                return this.props.hydrometryDataTypeSituation.filter(m => dataTypes.find(t => t.id === m.dataTypeId).displayData || !hasBooleanValue(dataTypes.find(t => t.id === m.dataTypeId).displayData)).map(stat => ({
                    title: getLabel(dataTypes, stat.dataTypeId, null, 'id'),
                    begin: stat.startDate ? getDate(stat.startDate) : '',
                    end: stat.lastMeasureDate ? getDate(stat.lastMeasureDate) : '',
                    nbOperation: stat.allMeasuresCount || '',
                })).filter(s => s.nbOperation)
            },
            default: () => {
                return []
            },
        }))
    }

    isMultiPoint = () => execByType(this.props.stationTypes[0], {
        piezometry: () => this.props.piezometerStatistics.some(stat => this.props.piezometerStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint)),
        default: () => false,
    })

    getHeader = () => {
        return execByType(this.props.stationTypes[0], {
            hydrometry: () => {
                const headers = [i18n.statistics, i18n.begin, i18n.end, i18n.nbMeasures]
                const result = headers.map((o, i) => {
                    return (<th className={`${i === 0 ? 'left' : 'center'}`} style={{ color: 'black', lineHeight: '15px', padding: '10px', fontSize: '14.3px' }}>{o}</th>)
                })
                return (<thead>
                    <tr>{result}</tr>
                </thead>)
            },
            default: () => {
                const headers = [i18n.statistics, this.isMultiPoint() ? i18n.pointPrelevement : null, i18n.begin, i18n.end, this.props.stationTypes[0] === STATION_TYPE_NAME.piezometry ? i18n.nbMeasures : i18n.nbOperations, this.props.stationTypes[0] === STATION_TYPE_NAME.quality ? i18n.nbAnalysis : null, i18n.control, i18n.validation].filter(s => !!s)
                const result = headers.map((o, i) => {
                    return (<th className={`${i === 0 ? 'left' : 'center'}`} style={{ color: 'black', lineHeight: '15px', padding: '10px', fontSize: '14.3px' }}>{o}</th>)
                })
                return (<thead>
                    <tr>{result}</tr>
                </thead>)
            },
        })
    }

    redirect = (key) => {
        const arr = this.props.location.pathname.split('/')
        arr.splice(arr.length - 1, 1)
        const pathname = arr.join('/')
        const path = (() => {
            switch (key) {
                case 'Physico-chimique':
                    return `${pathname}/suiviPC`
                default:
                    return null
            }
        })()
        if (path) {
            AppStore.dispatch(push(path))
        } else {
            AppStore.dispatch(WaitAction.waitStop())
        }
    }

    handleClick(key) {
        AppStore.dispatch(WaitAction.waitStart())
        setTimeout(() => this.redirect(key), 1)
    }

    render() {
        const history = this.getHistory()
        const filteredHistory = history.filter(e => e && e.nbOperation !== 0)

        return (
            <Grid container item justifyContent='center'>
                <Card round noBoxShadow cardStyle={{ border: '1px solid grey', borderRadius: '5px', width: '80%' }}>
                    <table className='table condensed'>
                        {this.getHeader()}
                        {!!filteredHistory.length && (
                            <tbody>
                                {filteredHistory.map((o) => {
                                    const result = Object.keys(o).map((key, index) => {
                                        if (index === 0) {
                                            return (<td className='right right-align bold valign-wrapper'> {o[key]}</td>)
                                        }
                                        return (<td className='center'> {o[key]}</td>)
                                    })
                                    return (<tr className='clickable' onClick={() => this.handleClick(o.title)} style={{ fontSize: '14.3px', lineHeight: '20px' }}>{result}</tr>)
                                })}
                            </tbody>
                        )}
                    </table>
                </Card >
            </Grid>
        )
    }
}

StationDashboardKeyFigurePanel.propTypes = {
    id: PropTypes.string.isRequired,
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    hydrobioOperations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    router: PropTypes.object,
    includesCardTitle: PropTypes.bool,
    piezometerMeasureBrute: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometerMeasureMin: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometerAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    samples: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerSample)),
    piezometerStatistics: arrayOf(DtoMeasureStats),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    hydrometryBruteMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricBruteMeasure)),
    hydrologicalAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometerAdditionalMeasures)),
    hydrometryDataTypeSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometryDataTypeSituation)),
    loadHydrometryRawSituations: PropTypes.func,
    fetchHydrometryDataTypes: PropTypes.func,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    qualitoDashboard: instanceOf(DtoQualitoDashboard),
}

StationDashboardKeyFigurePanel.defaultProps = {
    includesCardTitle: true,
}

const mapStateToProps = store => ({
    operations: store.SuivipcReducer.operations,
    hydrobioOperations: store.OperationReducer.hydrobioOperations,
    analysis: store.SuivipcReducer.analysis,
    measures: store.PiezometerStationReducer.measures,
    router: store.router.locationBeforeTransitions,
    piezometerMeasureBrute: store.PiezometerStationReducer.piezometerMeasureBrute,
    piezometerMeasureMin: store.PiezometerStationReducer.piezometerMeasureMin,
    piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    samples: store.PiezometerStationReducer.samples,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,

    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    hydrometryBruteMeasures: store.HydrometryReducer.hydrometryBruteMeasures,
    hydrologicalAdditionalMeasures: store.HydrometerStationReducer.hydrologicalAdditionalMeasures,
    hydrometryDataTypeSituation: store.HydrometryReducer.hydrometryDataTypeSituation,
    qualitoDashboard: store.QualityReducer.qualitoDashboard,
})


const mapDispatchToProps = {
    loadHydrometryRawSituations: HydrometryAction.loadHydrometryRawSituations,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationDashboardKeyFigurePanel)
