import { useDispatch } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import i18n from 'simple-react-i18n'
import { Alert, Card, CardContent, Grid2, Icon, IconButton } from '@mui/material'
import Input from '../../../../../components/forms/Input'
import Button from '../../../../../components/forms/Button'
import ColorPicker from '../../../../../components/forms/ColorPicker'
import Checkbox from '../../../../../components/forms/Checkbox'
import NumberField from '../../../../../components/forms/NumberField'
import { range, chunk, zip, round, padStart } from 'lodash'
import { getMonthList } from '../../../../../utils/DateUtil'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getThresholdColorHtml } from '../../../../../utils/ColorUtil'
import Line from '../../../../../components/echart/series/Line'
import { MEASURE_COTE, PIEZO_TYPEID } from '../../../../constants/PiezometryConstants'
import { getMonthThreshold } from '../../../../../components/echart/EChartUtils'
import { getUser } from 'utils/SettingUtils'
import { THRESHOLDS } from '../../constants/PiezometerSuiviConstants'
import { WhiteCard } from '../../../../../components/styled/Card'

const getMonthKey = (m) => `month${padStart(m, 2, '0')}`

const PiezoSuiviThresholds2 = ({
    id,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    time, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
    unit,
    tab,
    isPiezo,
    typeId,
}) => {
    const dispatch = useDispatch()
    const isConsultant = getUser().consultant === '1'

    const [baseThresholds, setBaseThresholds] = useState([]) // data at start
    const [tmpThresholds, setTmpThresholds] = useState([]) // data during updates in this form
    const [thresholds, setThresholds] = useState([]) // data applied (visible on chart) but not saved yet

    const [readyApply, setReadyApply] = useState(false)

    const change = (idx, changes) => {
        const newThresholds = tmpThresholds.slice()
        newThresholds[idx] = { ...newThresholds[idx], ...changes }
        setTmpThresholds(newThresholds)
        if (!readyApply) {
            setReadyApply(true)
            changeParent({ tabLocked: true })
        }
    }
    const changeIsMonth = (idx, threshold, v) => {
        if (v) {
            change(idx, { value: null, isMonth: true, month01: threshold.value || 1 })
        } else {
            const nullMonth = range(1, 13).reduce((acc, m) => ({ ...acc, [getMonthKey(m)]: null }), {})
            change(idx, { value: threshold.month01, ...nullMonth, isMonth: false })
        }
    }

    const saveChanges = () => {
        const backendThredholds = displayCote === MEASURE_COTE.NGF ? tmpThresholds : tmpThresholds.map(t => ({ ...t, value: t.valueDepth }))
        dispatch(PiezometerStationAction.updatePiezometerThresholds(id, backendThredholds, typeId, () => {
            setBaseThresholds(tmpThresholds)
            setThresholds(tmpThresholds)
        }, displayCote))
        setReadyApply(false)
        changeParent({ tabLocked: false })
    }

    const onCancel = () => {
        setTmpThresholds(baseThresholds)
        setThresholds(baseThresholds)
        setReadyApply(false)
        changeParent({ tabLocked: false })
    }

    // fetching thresholds
    useEffect(() => {
        PiezometerStationAction.promisePiezometerThresholds(id, isPiezo ? MEASURE_COTE.NGF : undefined).then(list => {
            const newList = list.filter(t => parseInt(t.dataType ?? PIEZO_TYPEID.CHRONIC) === typeId).map(th => {
                const isMonth = range(1, 13).some(m => hasValue(th[getMonthKey(m)]))
                return isMonth ? { ...th, value: null, isMonth } : th
            })
            const listWithDepth = newList.map(th => {
                if (th.isMonth) {
                    return range(1, 13).reduce((acc, m) => {
                        const key = getMonthKey(m)
                        return { ...acc, [`${key}Depth`]: hasValue(th[key]) ? round(landmarkValue - th[key], 3) : null }
                    }, th)
                }
                return { ...th, valueDepth: round(landmarkValue - th.value, 3) }
            })
            setTmpThresholds(listWithDepth)
            setThresholds(listWithDepth)
            setBaseThresholds(listWithDepth)
        })
    }, [typeId])

    // recalculate thresholds on chart
    useEffect(() => {
        const thresholdLines = thresholds.filter(t => parseInt(t.dataType ?? PIEZO_TYPEID.CHRONIC) === typeId).map(t => {
            const thresholdValue = !isPiezo || displayCote === MEASURE_COTE.NGF ? t.value : t.valueDepth
            if (t.isMonth) {
                return getMonthThreshold(t, minDate, maxDate, {}, true, true, { isNGF: displayCote === MEASURE_COTE.NGF, landmarkValue })
            }
            return Line({
                name: '',
                data: [],
                isPiezo,
                connectNulls: false,
                markLine: { silent: false, symbol: 'none', data: [{
                    yAxis: thresholdValue + (isPiezo ? 1000 : 0), // bandCorrection
                    lineStyle: {
                        color: getThresholdColorHtml(t),
                    },
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: () => `${t.name ? `${t.name} : ${round(thresholdValue, 3)}` : ''} ${unit}`,
                    },
                }] },
            })
        })
        changeParent({ thresholds: thresholdLines })
    }, [thresholds, displayCote, time])

    const isThresholdsCorrect = useMemo(() => {
        return !tmpThresholds.some(threshold => !threshold.name)
    }, [tmpThresholds])

    if (tab !== THRESHOLDS) {
        return null
    }

    return (
        <div>
            <WhiteCard className='margin-top-1' title={i18n.thresholds} round/>
            {
                tmpThresholds.map((threshold, idx) => {
                    const months = range(1, 13).map(m => {
                        const monthKey = getMonthKey(m)
                        return (
                            <Grid2 size={3} key={`${threshold.id}-${m}`}>
                                <NumberField title={getMonthList()[m-1].name}
                                    value={threshold[displayCote === MEASURE_COTE.NGF ? monthKey : `${monthKey}Depth`]}
                                    onChange={v => {
                                        if (displayCote === MEASURE_COTE.NGF) {
                                            change(idx, { [monthKey]: v, [`${monthKey}Depth`]: round(landmarkValue - v, 3) })
                                        } else {
                                            change(idx, { [monthKey]: round((v*-1) + landmarkValue, 3), [`${monthKey}Depth`]: v })
                                        }
                                    }}
                                    disabled={!threshold.isMonth}
                                    floatValue
                                />
                            </Grid2>
                        )
                    })
                    const groups = chunk(months, 3)
                    const values = Reflect.apply(zip, {}, groups) // transpose matrix !
                    return (
                        <Grid2 container justifyContent='center' alignItems='center' spacing={3} className='margin-top-1' key={`threshold_${threshold.id}`}>
                            <Card sx={{ width: '100%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid2 container alignItems='center' size={12} spacing={1}>
                                        { !isConsultant && (
                                            <IconButton
                                                tooltip={i18n.delete}
                                                size={'custom'}
                                                sx={{
                                                    height: '25px',
                                                    width: '25px',
                                                    color: 'white',
                                                    backgroundColor: 'red',
                                                }}
                                                onClick={() => {
                                                    setTmpThresholds(tmpThresholds.filter((_, idx2) => idx !== idx2))
                                                    setReadyApply(true)
                                                }}
                                            >
                                                <Icon style={{ fontSize: 15 }}>delete</Icon>
                                            </IconButton>
                                        )}
                                        <Grid2 container alignItems={'center'} size={ isConsultant ? 12 : 11}>
                                            <Grid2 size={7}>
                                                <Input
                                                    title={i18n.name}
                                                    value={threshold.name}
                                                    onChange={v => change(idx, { name: v })}
                                                    obligatory
                                                />
                                            </Grid2>
                                            <Grid2 size={5}>
                                                <ColorPicker label={i18n.color} value={getThresholdColorHtml(threshold)} onChange={v => change(idx, { htmlColor: v, color: null })}/>
                                            </Grid2>

                                            <Grid2 container alignItems='center' spacing={1}>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={i18n.valueLabel}
                                                        value={displayCote === MEASURE_COTE.NGF ? threshold.value : round(threshold.valueDepth, 3)}
                                                        onChange={v => {
                                                            if (displayCote === MEASURE_COTE.NGF) {
                                                                change(idx, { value: v, valueDepth: round(landmarkValue - v, 3) })
                                                            } else {
                                                                change(idx, { valueDepth: v, value: round(landmarkValue - v, 3) })
                                                            }
                                                        }}
                                                        disabled={threshold.isMonth}
                                                        floatValue
                                                    />
                                                </Grid2>
                                                <Grid2 size={5}>
                                                    <Checkbox
                                                        checked={threshold.isOverrunThreshold === '1'}
                                                        label={i18n.thresholdOverrun}
                                                        onChange={v => change(idx, { isOverrunThreshold: v ? '1' : '0' })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={4}>
                                                    <Checkbox
                                                        checked={threshold.isMonth}
                                                        label={i18n.isMonthThreshold}
                                                        onChange={v => changeIsMonth(idx, threshold, v)}
                                                    />
                                                </Grid2>
                                            </Grid2>

                                            { !threshold.name && (
                                                <Grid2 size={12}>
                                                    <Alert severity='warning' sx={{ fontSize: '1rem', lineHeight: 'inherit' }}>{i18n.pleaseFillInAllTheRequiredFields}</Alert>
                                                </Grid2>
                                            )}
                                            {
                                                threshold.isMonth && (
                                                    <>
                                                        <Grid2 container justifyContent='center' alignItems='center' spacing={1}>
                                                            { values[0]}
                                                        </Grid2>
                                                        <Grid2 container justifyContent='center' alignItems='center' spacing={1}>
                                                            { values[1]}
                                                        </Grid2>
                                                        <Grid2 container justifyContent='center' alignItems='center' spacing={1}>
                                                            { values[2]}
                                                        </Grid2>
                                                    </>
                                                )
                                            }
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                    )
                })
            }
            <Grid2 container justifyContent='center' alignItems='center' spacing={1} className='padding-bottom-1 margin-top-1 center-align'>
                <Button
                    tooltip={ i18n.add }
                    onClick={() => {
                        setTmpThresholds([...thresholds, {}])
                        setReadyApply(true)
                    }}
                    icon='note_add'
                    className='green btn-floating btn-large margin-left-2 '
                />
                <Button
                    tooltip={ i18n.cancel }
                    onClick={ onCancel }
                    icon='cancel'
                    className='red btn-floating btn-large margin-left-2 margin-right-2'
                    disabled={ !readyApply }
                />
                <Button
                    tooltip={ i18n.save }
                    onClick={ saveChanges }
                    icon='save'
                    className={`btn-floating btn-large ${isThresholdsCorrect && readyApply ? 'pulse' : ''}`}
                    disabled={ !isThresholdsCorrect || !readyApply }
                />
            </Grid2>
        </div>
    )
}

PiezoSuiviThresholds2.propTypes = {
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    unit: PropTypes.string,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    time: PropTypes.string,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    isPiezo: PropTypes.bool,
    typeId: PropTypes.number,
}

export default PiezoSuiviThresholds2