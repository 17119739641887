import pdf from 'assets/pictures/export/pdf.png'
import ades from 'assets/pictures/logos/ades.jpg'
import bdlisa from 'assets/pictures/logos/bdlisa.png'
import eaufrance from 'assets/pictures/logos/eaufrance.jpg'
import googleMap from 'assets/pictures/logos/googleMap.png'
import infoterre from 'assets/pictures/logos/infoterre.jpg'
import shom from 'assets/pictures/logos/shom.png'
import sandre from 'assets/pictures/logos/sandre.png'
import { push } from '@lagunovsky/redux-react-router'
import IndustrialSiteDescriptionPanel from 'installation/components/industrialSite/IndustrialSiteDescriptionPanel'
import FlowObstructionDescriptionPanel from 'installation/components/installationDefault/FlowObstructionDescriptionPanel'
import NetworkDescriptionPanel from 'installation/components/network/NetworkDescriptionPanel'
import { flatten } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import ResourceDescriptionPanel from 'resource/components/ResourceDescriptionPanel'
import ResourceDto from 'resource/dto/ResourceDto'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { geti18n } from 'utils/StringUtil'
import {
    H_STATION_HYDRO_DESCRIPTION,
    H_STATION_INSTALLATION_DESCRIPTION,
    H_STATION_PIEZO_DESCRIPTION,
    H_STATION_PLUVIO_DESCRIPTION,
    H_STATION_QUALITO_DESCRIPTION,
    H_STATION_RESOURCE_DESCRIPTION,
    H_UNITS_DESCRIPTION,
} from '../../../account/constants/AccessRulesConstants'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import { googleMapsPath, infoterreLink } from '../../../conf/SieauConstants'
import DistributionUnitDescriptionPanel from '../../../distributionUnit/components/DistributionUnitDescriptionPanel'
import HydrometryDescriptionPanel from '../../../hydrometry/components/description/HydrometryDescriptionPanel'
import InstallationAction from '../../../installation/actions/InstallationAction'
import AgriPrelDescriptionPanel from '../../../installation/components/agriPrel/AgriPrelDescriptionPanel'
import BoreholeDescriptionPanel from '../../../installation/components/borehole/BoreholeDescriptionPanel'
import CaptureStationDescriptionPanel from '../../../installation/components/capture/CaptureStationDescriptionPanel'
import InstallationDescriptionPanel from '../../../installation/components/installationDefault/InstallationDescriptionPanel'
import LiftingStationDescriptionPanel from '../../../installation/components/liftingStation/LiftingStationDescriptionPanel'
import PumpingStationDescriptionPanel from '../../../installation/components/pumping/PumpingStationDescriptionPanel'
import STEPDescriptionPanel from '../../../installation/components/step/STEPDescriptionPanel'
import TankStationDescriptionPanel from '../../../installation/components/tank/TankStationDescriptionPanel'
import TreatmentStationDescriptionPanel from '../../../installation/components/treatment/TreatmentStationDescriptionPanel'
import { INSTALLATION_TYPE, INSTALLATION_TYPES } from '../../../installation/constants/InstallationConstants'
import DtoInstallationType from '../../../installation/dto/installation/DtoInstallationType'
import PiezometerDescriptionPanel from '../../../piezometry/components/description/PiezometerDescriptionPanel'
import PiezometerDescriptionTablesPanel from '../../../piezometry/components/description/PiezometerDescriptionTablesPanel'
import PluviometerDescriptionPanel from '../../../pluviometry/components/description/PluviometerDescriptionPanel'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import ProductionUnitDescriptionPanel from '../../../productionUnit/components/ProductionUnitDescriptionPanel'
import QualitometerDescriptionPanel from '../../../quality/components/description/QualitometerDescriptionPanel'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ManagementUnitAction from '../../../referencial/components/managementUnit/actions/ManagementUnitAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getWGS84Coordinate } from '../../../utils/mapUtils/CoordinateUtils'
import { execByType, getStation, getStationTitle } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import StationAction from '../../actions/StationAction'
import { NEW } from '../../constants/StationConstants'
import DtoAccessibility from '../../dto/DtoAccessibility'
import DtoAltimetrySystemAltimetrySystem from '../../dto/DtoAltimetrySystemAltimetrySystem'
import DtoAltimetrySystemNature from '../../dto/DtoAltimetrySystemNature'
import DtoAltimetrySystemProductionMode from '../../dto/DtoAltimetrySystemProductionMode'
import DtoContributor from '../../dto/DtoContributor'
import DtoFacility from '../../dto/DtoFacility'
import DtoFunction from '../../dto/DtoFunction'
import DtoLandmark from '../../dto/DtoLandmark'
import DtoLocation from '../../dto/DtoLocation'
import DtoNetwork from '../../dto/DtoNetwork'
import DtoProjection from '../../dto/DtoProjection'
import StationMapDashboardPanel from '../dashboard/component/map/StationMapDashboardPanel'
import StationDescription from '../description/StationDescription'
import StationUpdatePanel from '../update/StationUpdatePanel'


const storeProps = {
    qualitometer: false,
    piezometer: false,
    hydrometricStation: false,
    productionUnit: false,
    distributionUnit: false,
    installation: false,
}

class StationDescriptionApp extends Component {
    getLinks = (station) => {
        if (station && station.code) {
            const googleLink = station.localisation && station.localisation.x && station.localisation.y && station.localisation.projection ?
                this.getGoogleMapLink(station.localisation) : []

            const otherLink = station.code.indexOf('X') === -1 ? [] :
                [{
                    img: eaufrance,
                    href: `http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:STQ:FRA:code:${station.code}:::referentiel:3.1:html`,
                }]
            const shomLink = station.stationTypes[0] === 4 ?
                [{
                    img: shom,
                    href: `https://data.shom.fr/donnees/refmar/${station.code}`,
                }]
                : []

            return flatten([{
                img: pdf,
                href: '',
            }, {
                img: ades,
                href: `http://www.ades.eaufrance.fr/FichePtEau.aspx?code=${station.code}/${station.designation}`,
            }, {
                img: infoterre,
                href: `${infoterreLink}${station.code.split('/')[0]}/${station.designation}`,
            }, {
                img: bdlisa,
                href: 'http://www.ades.eaufrance.fr/fmasseseau/2009/FRFG017.pdf',
            }, {
                img: sandre,
                href: 'http://www.ades.eaufrance.fr/fhydro/566a.pdf',
            }, googleLink, otherLink, shomLink])
        }
        return []
    }

    getGoogleMapLink = (localisation) => {
        const center = getWGS84Coordinate(localisation)
        return {
            img: googleMap,
            href: `${googleMapsPath + center[0]},${center[1]}`,
            label: 'Google Maps',
        }
    }

    setTitleNewStation = () => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n[this.props.stationTypes[0]],
            href: this.props.stationTypes[0],
        }, {
            title: i18n.new,
            href: `station/${this.props.stationTypes[0]}/new/description`,
        }]))
    }

    setTitle = ({ code = '', id, name = '', designation = '', installationType }) => {
        const installationTypeObj = INSTALLATION_TYPES.find(it => it.code === installationType) || {}
        const instLabel = geti18n(installationTypeObj.label)
        this.props.forceFetch('title', [{
            title: i18n[this.props.stationTypes[0]],
            href: this.props.stationTypes[0],
        }, {
            title: (instLabel && instLabel !== '' ? `${instLabel} - ` : '') + getStationTitle({ code, id, name, designation }),
            href: `station/${this.props.stationTypes[0]}/${id}`,
        }, {
            title: i18n.description,
            href: `station/${this.props.stationTypes[0]}/${id}/description`,
        }])
    }

    componentDidMount() {
        const isAuthorized = execByType(this.props.stationTypes[0], {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_DESCRIPTION),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_DESCRIPTION),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_DESCRIPTION),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_DESCRIPTION),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_DESCRIPTION),
            units: () => componentHasHabilitations(H_UNITS_DESCRIPTION),
            resource: () => componentHasHabilitations(H_STATION_RESOURCE_DESCRIPTION),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.params.id === NEW) {
            this.setTitleNewStation()
        } else {
            const station = getStation(this.props, this.props.stationTypes[0])
            this.setTitle(station, INSTALLATION_TYPES)
        }
        execByType(this.props.stationTypes[0], {
            stations: () => {
                this.props.fetchIfNeeded('contacts', ContactAction.fetchContacts)
                this.props.fetchHydrogeologicalEntities()
            },
            installation: () => {
                if (!this.props.installationsTypes.length) {
                    this.props.fetchInstallationTypes()
                }
                if (!this.props.managementUnits.length) {
                    this.props.fetchManagementUnits()
                }
                if (!this.props.sectors.length) {
                    this.props.fetchSectors()
                }
            },
            units: () => {
                if (!this.props.managementUnits.length) {
                    this.props.fetchManagementUnits()
                }
                if (!this.props.sectors.length) {
                    this.props.fetchSectors()
                }
            },

        })
    }

    componentWillReceiveProps(nextProps) {
        const station = getStation(this.props, this.props.stationTypes[0])
        const nextStation = getStation(nextProps, this.props.stationTypes[0])
        if (nextStation.name && !station.name) {
            this.setTitle(getStation(nextProps, this.props.stationTypes[0]), INSTALLATION_TYPES)
        }
    }

    render() {
        const panels = execByType(this.props.stationTypes[0], {
            installation: () => {
                switch (this.props.installation.installationType) {
                    case INSTALLATION_TYPE.BOREHOLE:
                        return { component: <BoreholeDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.LIFTING_STATION:
                        return { component: <LiftingStationDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.CAPTURE:
                        return { component: <CaptureStationDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.TANK:
                        return { component: <TankStationDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.TREATMENT:
                        return { component: <TreatmentStationDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.PUMPING:
                        return { component: <PumpingStationDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.STEP:
                        return { component: <STEPDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.AGRI_PREL:
                        return { component: <AgriPrelDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                        return { component: <IndustrialSiteDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.NETWORK:
                        return { component: <NetworkDescriptionPanel onRemount={this.props.onRemount} /> }
                    case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
                    case INSTALLATION_TYPE.BRIDGE:
                    case INSTALLATION_TYPE.SLUICE:
                        return { component: <FlowObstructionDescriptionPanel onRemount={this.props.onRemount} /> }
                    default:
                        return { component: <InstallationDescriptionPanel onRemount={this.props.onRemount} /> }
                }
            },
            quality: () => ({
                component: <QualitometerDescriptionPanel onRemount={this.props.onRemount} id={this.props.params.id} />,
            }),
            pluviometry: () => ({
                component: <PluviometerDescriptionPanel id={this.props.params.id} />,
            }),
            hydrometry: () => ({
                component: <HydrometryDescriptionPanel id={this.props.params.id} />,
            }),
            piezometry: () => ({
                component: this.props.piezometer.id ? (<PiezometerDescriptionPanel onRemount={this.props.onRemount} />) : <div />,
            }),
            stations: () => {
                return {
                    stationsInfos:
                        <div>
                            <PiezometerDescriptionTablesPanel />
                        </div>,
                    description:
                        <StationDescription id={this.props.params.id} stationTypes={this.props.stationTypes} extended noOwner />,
                    localisations:
                        <Table title={i18n.location} data={[]} type={{ headers: [] }} />,
                    UnitDescription: null,
                }
            },
            productionUnit: () => ({
                component: <ProductionUnitDescriptionPanel id={this.props.params.id} stationTypes={this.props.stationTypes} />,
            }),
            distributionUnit: () => {
                return {
                    component: <DistributionUnitDescriptionPanel id={this.props.params.id} stationTypes={this.props.stationTypes} />,
                }
            },
            resource: () => ({
                component: <ResourceDescriptionPanel id={this.props.params.id} />,
            }),
        })

        if (panels.component) {
            return panels.component
        }
        const station = getStation(this.props, this.props.stationTypes[0])
        return (
            <div className='row no-margin'>
                <div className='col s9'>
                    {panels.description}
                    {panels.stationsInfos}
                    {panels.UnitDescription}
                </div>
                <div className='col s3 no-padding'>
                    {this.props.params.id == -1 && (<StationUpdatePanel station={station} />)}
                    <StationMapDashboardPanel
                        noMarkerTooltip
                        station={station}
                        type={this.props.stationTypes[0]}
                    />
                    {panels.localisations}
                </div>
            </div>
        )
    }
}

StationDescriptionApp.propTypes = getPropTypes(storeProps, {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetwork)),
    locations: PropTypes.arrayOf(PropTypes.instanceOf(DtoLocation)),
    projections: PropTypes.arrayOf(PropTypes.instanceOf(DtoProjection)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributor)),
    functions: PropTypes.arrayOf(PropTypes.instanceOf(DtoFunction)),
    landmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoLandmark)),
    facilities: PropTypes.arrayOf(PropTypes.instanceOf(DtoFacility)),
    accessibility: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccessibility)),
    altimetricSystemProductionMode: PropTypes.arrayOf(PropTypes.instanceOf(DtoAltimetrySystemProductionMode)),
    altimetricSystemNature: PropTypes.arrayOf(PropTypes.instanceOf(DtoAltimetrySystemNature)),
    altimetricSystemAltimetricSystem: PropTypes.arrayOf(PropTypes.instanceOf(DtoAltimetrySystemAltimetrySystem)),
    installationTypes: arrayOf(DtoInstallationType),
    resource: PropTypes.instanceOf(ResourceDto),
    getLink: PropTypes.func,
    onRemount: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    push: PropTypes.func,
    fetchSectors: PropTypes.func,
})

StationDescriptionApp.defaultProps = {
    getLink: () => { },
}


const mapStateToProps = store => getMapStateToProps(storeProps, {
    pluviometer: store.PluviometryReducer.pluviometer,
    networks: store.StationReducer.networks,
    locations: store.StationReducer.locations,
    projections: store.StationReducer.projections,
    contributors: store.ContributorReducer.contributors,
    functions: store.StationReducer.functions,
    facilities: store.StationReducer.facilities,
    accessibility: store.StationReducer.accessibility,
    landmarks: store.StationReducer.landmarks,
    altimetricSystemProductionMode: store.StationReducer.altimetricSystemProductionMode,
    altimetricSystemNature: store.StationReducer.altimetricSystemNature,
    altimetricSystemAltimetricSystem: store.StationReducer.altimetricSystemAltimetricSystem,
    installationsTypes: store.InstallationReducer.installationsTypes,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    resource: store.ResourceReducer.resource,
    sectors: store.SectorReducer.sectors,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchIfNeeded: ContactAction.fetchIfNeeded,
    fetchHydrogeologicalEntities: StationAction.fetchHydrogeologicalEntities,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
    push,
    fetchSectors: SectorAction.fetchSectors,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationDescriptionApp)
