import moment from 'moment'
import 'moment/locale/fr'
import { orderBy, take } from 'lodash'
import i18n from 'simple-react-i18n'
import { hasValue } from '../../../../utils/NumberUtil'

moment.locale('fr')

const frequencyColors = (nb, isInverse = false) => {
    return orderBy(take([
        [15, 'blue', '#b8b8ff'],
        [4, 'red', '#ff9494'],
        [7, 'orange', '#ffe6b8'],
        [12, 'green', '#b8dcb8'],
        [16, 'darkblue', '#9494ce'],
        [8, 'yellow', '#ffffb8'],
        [13, 'darkgreen', '#94be94'],
        [6, 'brown', '#d29b9b'],
        [17, 'purple', '#dcb8dc'],
        [18, 'darkmagenta', '#ae66ad'],
        [10, 'white', '#ffffff'],
        [1, 'black', '#b8b8b8'],
        [11, 'lightgreen', '#e0fbe0'],
        [2, 'pink', '#ffc0cb66'],
        [14, 'lightblue', '#afe2f3'],
        [3, 'magenta', '#dfb8df'],
        [5, 'indianred', '#cd5c5c'],
        [9, 'lightyellow', '#ffffe0'],
    ], nb), v => v[0], [isInverse ? 'asc' : 'desc']).map(v => v[2])
}

const getFrequencyStats = (histoYears, filteredPeriods, isInverse = false) => { // ex: filteredPeriods = ['3','5','10'] pour récupérer que triennale, quinquennale et décennale
    const stats = [
        { code: 'fre_100_H', name: i18n.fre_100_H, color: '#c2002b', nb: 0.99, minYear: 100 },
        { code: 'fre_80_H', name: i18n.fre_80_H, color: '#aa05f3', nb: 0.9875, minYear: 80 },
        { code: 'fre_50_H', name: i18n.fre_50_H, color: '#8d5d35', nb: 0.98, minYear: 50 },
        { code: 'fre_20_H', name: i18n.fre_20_H, color: '#32928b', nb: 0.95, minYear: 20 },
        { code: 'fre_10_H', name: i18n.fre_10_H, color: '#661886', nb: 0.9, minYear: 10 },
        { code: 'fre_5_H', name: i18n.fre_5_H, color: '#0c5033', nb: 0.8, minYear: 5 },
        { code: 'fre_3_H', name: i18n.fre_3_H, color: '#10436a', nb: 0.667, minYear: 3 },
        { code: 'fre_3_S', name: i18n.fre_3_S, color: '#10436a', nb: 0.333, minYear: 3 },
        { code: 'fre_5_S', name: i18n.fre_5_S, color: '#0c5033', nb: 0.2, minYear: 5 },
        { code: 'fre_10_S', name: i18n.fre_10_S, color: '#661886', nb: 0.1, minYear: 10 },
        { code: 'fre_20_S', name: i18n.fre_20_S, color: '#32928b', nb: 0.05, minYear: 20 },
        { code: 'fre_50_S', name: i18n.fre_50_S, color: '#8d5d35', nb: 0.02, minYear: 50 },
        { code: 'fre_80_S', name: i18n.fre_80_S, color: '#aa05f3', nb: 0.0125, minYear: 80 },
        { code: 'fre_100_S', name: i18n.fre_100_S, color: '#c2002b', nb: 0.01, minYear: 100 },
    ].filter(s => !hasValue(histoYears) ? true : s.minYear <= histoYears)
    const statsFiltered = filteredPeriods ? stats.filter(s => filteredPeriods.includes(s.code.split('_')[1])) : stats
    const colors = frequencyColors(statsFiltered.length, isInverse)
    return statsFiltered.map((s, idx) => ({ ...s, color: colors[idx] }))
}

export { getFrequencyStats }