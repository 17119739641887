import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { PRODUCTION_UNIT_LINK_TYPES } from 'productionUnit/constants/ProductionUnitConstants'
import ProdLinkDialog from './ProdLinkDialog'
import { push } from '@lagunovsky/redux-react-router'
import { getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { useDispatch } from 'react-redux'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'

const ProdUnitLinkType = ({
    data = {},
    linkType,
    readMode = false,
    dispatchStateLinks = () => { },
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const params = (() => {
        switch (linkType) {
            case PRODUCTION_UNIT_LINK_TYPES.RESOURCES:
                return {
                    title: i18n.resources,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'type', 'name', 'nbFollowPoints'],
                    filterTypes: ['resource'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.MONITORING:
                return {
                    title: i18n.monitoring,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'piezometry', 'hydrometry'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.PRODUCTION:
                return {
                    title: i18n.samplesProd,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'piezometry', 'hydrometry', 'installation'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.JOB:
                return {
                    title: i18n.job,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'installation'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.DISTRIBUTION:
                return {
                    title: i18n.distributionUnit,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.UDI:
                return {
                    title: i18n.distributionUnitName,
                    headers: [{ key: 'nullLabel', value: '' }, 'code', 'nature', 'sector', 'cityNumber', 'subscriberNumber'],
                    filterTypes: ['distributionUnit'],
                }
            default:
                return {}
        }
    })()

    const actions = [readMode && {
        icon: 'file_download',
        tooltip: i18n.export,
        color: 'white',
        onClick: () => { },
    } || {
        icon: 'edit',
        color: 'white',
        tooltip: i18n.edit,
        onClick: () => setIsOpen(true),
    }]

    return (
        <>
            <CardTable
                title={params.title}
                rows={data}
                headers={params.headers}
                actions={actions}
                color={greyBlue}
                onClickRow={(d) => {
                    dispatch(push(`/station/${getNewStationTypeNameFromTypeCode(d.siteType)}/${d.siteCode}`))
                }}
            />
            {isOpen && (
                <ProdLinkDialog
                    links={data}
                    open={isOpen}
                    setOpen={setIsOpen}
                    filterTypes={params.filterTypes}
                    linkType={linkType}
                    title={params.title}
                    dispatchStateLinks={dispatchStateLinks}
                />
            )}
        </>
    )
}

ProdUnitLinkType.propTypes = {
    data: PropTypes.array,
    linkType: PropTypes.number.isRequired,
    readMode: PropTypes.bool,
    stateLinks: PropTypes.array,
    dispatchStateLinks: PropTypes.func,
}

export default ProdUnitLinkType