import { Card, CardContent, Grid2, Icon } from '@mui/material'
import { groupBy, isUndefined, maxBy, orderBy, round, sum, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import { getQualificationColor, getStatusColor, calculateThresholdResult } from '../../../utils/AnalyseUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import { getDate } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { SuperParameterGraphModal } from '../qualityComponents/ParameterGraph'
import PopupParameterAdministration from './PopupParameterAdministration'
import NewMoleculeDetectedChart from './NewMoleculeDetectedChart'
import OperationsCountChart from './OperationsCountChart'
import useBoolean from 'utils/customHook/useBoolean'
import SliderPanel from 'components/slider/SliderPanel'
import useListIndexed from 'utils/customHook/useListIndexed'
import OperationAction from '../operation/actions/OperationAction'
import SmallPie from 'components/echart/SmallPie'
import { THRESHOLD } from 'quality/constants/QualityConstants'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoPrettyAnalysis from 'quality/dto/analyse/DtoPrettyAnalysis'
import { CardTitle } from 'components/card/NewCard'

const QualityIndices = ({
    stationType,
    analysis = [],
}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        qualityIndicators,
        qualityThresholds,
        parameters,
        units,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualityIndicators: store.QualityReducer.qualityIndicators,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'id')

    const filteredIndicators = useMemo(() => {
        const foundIndicators = qualityIndicators.find(q => q.stationType == stationType)?.indicators || []
        return foundIndicators.filter(i => indexedParameters[i.id]?.typeParam !== '3')
    }, [indexedParameters, qualityIndicators, stationType])

    useEffect(() => {
        const unloadThresholds = uniqBy(filteredIndicators, 'threshold').map(i => `${i.threshold}`).filter(code => !qualityThresholds.some(t => t.thresholdCode === code && t.thresholdType === THRESHOLD.PC))
        unloadThresholds.forEach(code => dispatch(QualityAction.fetchQualityThresholds([{ code, thresholdType: THRESHOLD.PC }])))
    }, [dispatch, filteredIndicators])

    const [parameter, setParameter] = useState()

    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)

    const groupParam = groupBy(analysis, 'parameter')

    const datas = orderBy(filteredIndicators.map((indicator) => {
        const paramCode = `${indicator.id}`
        const thresholdCode = `${indicator.threshold}`
        const thresholds = qualityThresholds.find(qt => qt.thresholdCode === thresholdCode)?.thresholds || []
        const lastAnalysis = maxBy((groupParam[paramCode] || []).filter(a => !isUndefined(a.result)), a => a.sampleDate ?? a.analysisDate)

        if (isUndefined(lastAnalysis?.result)) {
            return {
                title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: i18n.noResults,
                parameterCode: paramCode,
            }
        }

        const formattedAnalysis = { ...lastAnalysis, ...calculateThresholdResult(lastAnalysis, thresholds) }
        if (formattedAnalysis?.threshold) {
            return {
                title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
                threshold: formattedAnalysis.threshold,
                analysis: formattedAnalysis,
                parameterCode: paramCode,
            }
        }

        return {
            title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
            subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
            parameterCode: paramCode,
        }
    }), ['indices'], ['asc'])

    const thresholds = useMemo(() => {
        const found = datas.find(d => d.parameterCode === parameter)
        return found ? [{ ...found.threshold, parameterCode: parameter }] : []
    }, [parameter, datas])

    return (
        <>
            <Grid2 container spacing={1}>
                {
                    datas.map((data, i) => {
                        return (
                            <Grid2 key={data.parameterCode} size={12}>
                                <SliderPanel
                                    key={i}
                                    title={data.title}
                                    subtitle={data.subtitle}
                                    analysis={data.analysis}
                                    threshold={data.threshold}
                                    onOpen={() => {
                                        setParameter(data.parameterCode)
                                        openGraph()
                                    }}
                                />
                            </Grid2>
                        )
                    })
                }
                {
                    !datas.length && (
                        <Grid2 container size={12} sx={{ fontSize: 14, color: 'black' }} justifyContent='center' alignItems='center' direction='column' spacing={0}>
                            <Grid2 size='auto'>
                                <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                            </Grid2>
                            <Grid2 size='auto'>
                                {i18n.noResults}
                            </Grid2>
                        </Grid2>
                    )
                }
            </Grid2>
            <SuperParameterGraphModal
                isOpen={isGraphOpen}
                closeGraph={closeGraph}

                parameter={parameter}
                qualitometer={qualitometer.id}
                thresholds={thresholds}
            />
        </>
    )
}

QualityIndices.propTypes = {
    stationType: PropTypes.string,
    analysis: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPrettyAnalysis)),
        PropTypes.arrayOf(PropTypes.shape({ })),
    ]),
}

// todo optimize the rerender when openning the admin popin
const DashboardQualitySituationPanel = ({
    stationType,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometer,
        qualitoDashboard,
        qualifications,
        status,
        accountUser,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitoDashboard: store.QualityReducer.qualitoDashboard,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(OperationAction.fetchRemarks())
        dispatch(QualityAction.fetchQualifications())
        dispatch(QualityAction.fetchStatus())
    }, [])

    useEffect(() => {
        notLoaded()
        dispatch(QualityAction.fetchQualitoDashboard(qualitometer.id)).finally(loaded)
    }, [qualitometer.id])

    const {
        value: isAdminOpen,
        setTrue: openAdmin,
        setFalse: closeAdmin,
    } = useBoolean(false)

    const { stats = {} } = qualitoDashboard

    const listQualification = [
        { id: 4, value: stats.qbrute },
        { id: 3, value: stats.qincertaine },
        { id: 2, value: stats.qincorrecte },
        { id: 1, value: stats.qcorrecte },
    ]

    const countQualification = sum(listQualification.map(q => q.value))
    const dataQualifications = listQualification.map(q => {
        const percentage = countQualification ? round(q.value * 100 / countQualification, 2) : 0
        const percentageLabel = `${percentage}%`
        const color = getRGBColor(getQualificationColor(q.id))
        return {
            name: percentageLabel,
            value: percentage,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.qualification} (${percentageLabel})</br>
                        ${getColorCircleElement(params.color)}${getLabel(qualifications, q.id) || i18n.notDefined}: ${q.value} ${i18n.of} ${countQualification} ${i18n.operations}`
                },
            },
        }
    })

    const listStatus = [
        { id: 1, value: stats.sbrute },
        { id: 2, value: stats.snv1 },
        { id: 3, value: stats.snv2 },
        { id: 4, value: stats.sinterpretee },
    ]

    const countStatus = sum(listStatus.map(s => s.value))

    const dataStatus = listStatus.map(s => {
        const percentage = countStatus ? round(s.value * 100 / countStatus, 2) : 0
        const percentageLabel = `${percentage}%`
        const color = getRGBColor(getStatusColor(s.id))
        return {
            name: percentageLabel,
            value: percentage,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.status} (${percentageLabel})</br>
                        ${getColorCircleElement(params.color)}${getLabel(status, s.id) || i18n.notDefined}: ${s.value} ${i18n.of} ${countQualification} ${i18n.operations}`
                },
            },
        }
    })

    const action = accountUser.isAdmin === '1' ? [{ onClick: openAdmin, icon: 'settings', color: 'black' }] : []

    return (
        <>
            {
                !isLoaded && (
                    <ProgressCard indeterminate />
                )
            }
            {
                isLoaded && qualitoDashboard?.stationsData?.length && (
                    <>
                        <Card>
                            <CardTitle
                                title={i18n.qualitySituationPC}
                                actions={action}
                            />
                            <CardContent>
                                <Grid2 container >
                                    <Grid2 size={4}>
                                        <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>{i18n.qualityIndicators}</h6>
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <h6 style={{ fontWeight: 'bold', textAlign: 'center' }}>{i18n.mostDetectedMolecules}</h6>
                                    </Grid2>
                                    <Grid2 size={2} />
                                    <Grid2 size={4}>
                                        <QualityIndices
                                            stationType={stationType}
                                            analysis={qualitoDashboard?.stationsData?.find(s => s.stationId === qualitometer.id)?.analysis}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NewMoleculeDetectedChart quantifications={qualitoDashboard?.quantifications || []} />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <SmallPie data={dataQualifications} name={i18n.qualification} />
                                        <SmallPie data={dataStatus} name={i18n.status} />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <h6 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{i18n.operationsHistory}</h6>
                                        <OperationsCountChart />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                        <PopupParameterAdministration
                            isOpen={isAdminOpen}
                            onClose={ (parametersHasBeenSaved) => {
                                if (parametersHasBeenSaved) {
                                    notLoaded()
                                    dispatch(QualityAction.fetchQualitoDashboard(qualitometer.id)).finally(() => {
                                        loaded()
                                        closeAdmin()
                                    })
                                } else {
                                    closeAdmin()
                                }
                            }}
                        />
                    </>
                )
            }
        </>
    )
}

DashboardQualitySituationPanel.propTypes = {
    stationType: PropTypes.string,
}

export default DashboardQualitySituationPanel
export {
    QualityIndices,
}
