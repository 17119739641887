/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectionTable from 'components/datatable/SelectionTable'
import i18n from 'simple-react-i18n'
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Input from 'components/forms/Input'
import { isNil, partition, uniqBy } from 'lodash'
import { searchAllCharacters } from 'utils/StringUtil'
import DtoPgsseFormWithStats from 'pgsse/dto/DtoPgsseFormWithStats'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'
import { useParams } from 'react-router'
import Select from 'components/forms/Select'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { hasValue } from 'utils/NumberUtil'

const SelectedFormDialog = ({
    isOpen = false,
    setIsOpen = () => { },
    onValidate = () => { },
    elements = [],
    forms = [],
    stationType,
    keyValue = 'id',
}) => {
    const dispatch = useDispatch()

    const {
        citiesIndex,
        installationsTypes,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const { idDiagnostic } = useParams()

    const [search, setSearch] = useState('')

    const [selectedForms, setSelectedForms] = useState([])
    const [installationType, setInstallationType] = useState()

    useEffect(() => setSelectedForms(forms), [forms])

    const displayElements = elements.map(element => {
        const formInfos = selectedForms.find(sf => sf.code === element[keyValue])
        return {
            ...element,
            ...formInfos,
            displayCode: element.code,
            city: citiesIndex[element.townCode || element.city]?.name,
        }
    })

    const its = uniqBy(elements, 'installationType').map(d => d.installationType)

    const [selectedElements, nonSelectedElements] = partition(displayElements, element => {
        return selectedForms.some(sf => sf.code === element[keyValue])
    })

    const itFiltered = hasValue(installationType) ?
        nonSelectedElements.filter((nse) => nse.installationType === installationType) :
        nonSelectedElements

    const filteredElements = !(isNil(search) || search === '') ?
        itFiltered.filter(({ displayCode, name, city }) => searchAllCharacters(`${displayCode}${name}${city}`).includes(searchAllCharacters(search))) :
        itFiltered

    const onAdd = (element) => {
        setSelectedForms(prev => [
            ...prev,
            new DtoPgsseFormWithStats({
                code: element[keyValue],
                stationType,
                idDiag: parseInt(idDiagnostic),
            }),
        ])
    }

    const onDelete = (element) => {
        if (element.idForm && element.status > 0) {
            dispatch(ToastrAction.error(i18n.noDeleteFormExists))
        } else {
            setSelectedForms(prev => prev.filter(p => p.code !== element.code))
        }
    }

    const addAll = () => {
        setSelectedForms(prev => [
            ...prev,
            ...nonSelectedElements.map(se => new DtoPgsseFormWithStats({
                idDiag: parseInt(idDiagnostic),
                code: se[keyValue],
                stationType,
            })),
        ])
    }

    const deleteAll = () => {
        setSelectedForms(prev => prev.filter(p => p.idForm && p.status > 0))
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth='lg'
        >
            <DefaultDialogTitle title={i18n.manageForms} onClose={() => setIsOpen(false)} />
            <DialogContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.search}
                            value={search}
                            onChange={setSearch}
                            data-cy='searchInput'
                        />
                    </Grid2>
                    {stationType === STATION_TYPE_CONSTANT.INSTALLATION && (
                        <Grid2 size={6}>
                            <Select
                                label={i18n.installationsTypes}
                                value={installationType}
                                onChange={setInstallationType}
                                options={installationsTypes.filter(it => its.includes(it.id))}
                                data-cy='searchInput'
                            />
                        </Grid2>
                    )}
                    <Grid2 size={12}>
                        <SelectionTable
                            listData={filteredElements}
                            listHeaders={[{ key: 'displayCode', value: i18n.code }, 'city', 'name']}
                            listTitle={i18n.nonSelectedElements}

                            selectedData={selectedElements}
                            selectedHeaders={[{ key: 'displayCode', value: i18n.code }, 'city', 'name']}
                            selectedTitle={i18n.selectedElements}

                            onAdd={onAdd}
                            onDelete={onDelete}
                            addAll={addAll}
                            deleteAll={deleteAll}
                        />
                    </Grid2>
                </Grid2>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(selectedForms)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

SelectedFormDialog.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onValidate: PropTypes.func,
    elements: PropTypes.arrayOf(PropTypes.shape({})),
    forms: PropTypes.arrayOf(PropTypes.instanceOf(DtoPgsseFormWithStats)),
    stationType: PropTypes.number,
    keyValue: PropTypes.string,
}

export default SelectedFormDialog