import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../components/forms/Checkbox'
import Job from '../../../import/dto/DtoJob'
import { DEFAULT_TIME_BEFORE_ALERT } from '../../constants/AlertConstants'
import TabSelectThreshold from './TabSelectThreshold'
import SmsModalContact from '../../../administration/components/setting/smsModal/SmsModalContact'
import { Button, Grid } from '@mui/material'
import { getSetting } from '../../../utils/SettingUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { connect } from 'react-redux'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import NumberField from '../../../components/forms/NumberField'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import { execByType } from '../../../utils/StationUtils'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import AppStore from '../../../store/AppStore'
import WaitAction from '../../../wait/WaitAction'
import { hasValue } from '../../../utils/NumberUtil'


class JobAlert extends Component {
    state = {
        selected: [],
        emailsString: '',
        contactsIds: undefined,
        isOpenSmsModal: false,
        alertTypes: {
            alertEmail: false,
            alertSms: false,
            alertFacebook: false,
        },
        stationsStats: {},
    }

    componentDidMount() {
        const { emailsAlert = [], contactsIds = [], parameters = [], alertTypes = [] } = this.props.job.parameters
        const selected = parameters.reduce((acc, param) => {
            const parse = JSON.parse(param)
            const station = this.props.stations.find(s => s.id === parse.stationId) || {}
            return [
                ...acc,
                {
                    code: station.code,
                    id: parse.stationId,
                    thresholdId: parse.thresholdId,
                    typeId: parse.typeId,
                    timeLinitMissingData: parse.timeLinitMissingData,
                },
            ]
        }, [])
        const emailsString = emailsAlert.join(';')
        const contactsIdsFormat = contactsIds.join(';')
        const alert = alertTypes.reduce((acc, a) => ({ ...acc, [a]: true }), {})
        this.setState({ selected, emailsString, contactsIds: contactsIdsFormat, alertTypes: alert })
        if (selected.some(station => !(hasValue(station.id) && hasValue(station.typeId)))) {
            this.changeJobStations(selected)
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.stations.length !== prevProps.stations.length) {
            this.setState(({ selected }) => ({
                selected: selected.map(station => {
                    const stationFound = this.props.stations.find(s => s.id === station.id) || {}
                    return {
                        ...station,
                        code: stationFound.code,
                    }
                }),
            }))
        }
    }

    handleChangeEmails = emailsString => {
        this.setState({ emailsString })
        const emailsList = emailsString ? emailsString.split(';') : []
        this.props.onChangeEmails(emailsList.filter(e => e))
    }

    handleChangeStation = ({ id, code } = {}, index) => {
        if (!code) {
            const selected = this.state.selected.map((s, i) => {
                if (i === index) {
                    return { id: undefined, code: undefined, timeLinitMissingData: DEFAULT_TIME_BEFORE_ALERT }
                }
                return s
            })
            this.setState({ selected })
            this.changeJobStations(selected)
        } else {
            AppStore.dispatch(WaitAction.waitStart())
            const promiseFunc = execByType(this.props.stationType, {
                piezometry: () => PiezometerStationAction.promisePiezoMeasuresStats,
                hydrometry: () => HydrometryAction.promiseHydroStats,
                pluviometry: () => PluviometryAction.promisePluviometerMeasuresStats,
            })
            promiseFunc(id).then(stats => {
                AppStore.dispatch(WaitAction.waitStop())
                const selectedOnlyTypeId = stats.length === 1 ? { typeId: stats[0].typeId } : {}
                const stationThresholdIfOnlyTypeId = stats.length === 1 ? this.props.thresholds.filter(th => {
                    if (this.props.stationType === 'hydrometry') {
                        return th.stationId === id?.toString() && parseInt(th.dataType ?? this.props.defaultDt) === stats[0].typeId
                    }
                    return th.code === code && parseInt(th.dataType ?? this.props.defaultDt) === stats[0].typeId
                }) : []
                const selectedOnlyThreshold = stationThresholdIfOnlyTypeId.length === 1 ? { thresholdId: stationThresholdIfOnlyTypeId[0].id } : {}
                const selected = this.state.selected.map((s, i) => {
                    if (i === index) {
                        return { id, code, timeLinitMissingData: DEFAULT_TIME_BEFORE_ALERT, ...selectedOnlyTypeId, ...selectedOnlyThreshold }
                    }
                    return s
                })
                this.setState({ selected, stationsStats: { ...this.state.stationsStats, [id]: stats.map(s => ({ ...s, id: s.typeId, title: s.label })) } })
                this.changeJobStations(selected)
            })
        }
    }

    handleChange = (key, value, index) => {
        const selected = this.state.selected.map((s, i) => {
            if (i === index && key === 'typeId') {
                const stationThresholdWithTypeId = this.props.thresholds.filter(th => {
                    if (this.props.stationType === 'hydrometry') {
                        return th.stationId === s?.id?.toString() && parseInt(th.dataType ?? this.props.defaultDt) === value
                    }
                    return th.code === s.code && parseInt(th.dataType ?? this.props.defaultDt) === value
                })
                const selectedThreshold = stationThresholdWithTypeId.length === 1 ? { thresholdId: stationThresholdWithTypeId[0].id } : { thresholdId: undefined }
                return { ...s, typeId: value, ...selectedThreshold }
            } else if (i === index) {
                return { ...s, [key]: value }
            }
            return s
        })
        this.setState({ selected })
        this.changeJobStations(selected)
    }

    changeJobStations = stationsSelected => {
        const formattedStations = stationsSelected.filter(e => e.id).map(({ id, thresholdId, typeId, timeLinitMissingData }) => JSON.stringify({
            stationId: id,
            thresholdId,
            typeId,
            timeLinitMissingData,
        }))
        this.props.onChangeStation(formattedStations, { canSaveJob: !stationsSelected.some(s => !(hasValue(s.id) && hasValue(s.typeId))) })
    }

    handleDeleteStation = (_, index) => {
        const selected = this.state.selected.filter((__, i) => i !== index)
        this.setState({ selected })
        this.changeJobStations(selected)
    }

    handleDuplicateStation = (_, index) => {
        const { id, code, timeLinitMissingData } = this.state.selected[index]
        const selected = [...this.state.selected, { id, code, timeLinitMissingData }]
        this.setState({ selected })
    }

    handleChangeAlertMail = alert => {
        const alertTypes = { ...this.state.alertTypes, [alert]: !this.state.alertTypes[alert] }
        this.setState({ alertTypes, emailsString: '' })
        this.props.onChangeEmails([])
        this.props.onChangeAlertTypes(Object.keys(alertTypes).filter(a => alertTypes[a]))
    }

    handleChangeAlertSms = alert => {
        const alertTypes = { ...this.state.alertTypes, [alert]: !this.state.alertTypes[alert] }
        this.setState({ alertTypes, contactsIds: undefined })
        this.props.onChangeContactsIds(undefined)
        this.props.onChangeAlertTypes(Object.keys(alertTypes).filter(a => alertTypes[a]))
    }

    render() {
        const addAction = this.props.isEditMode ? [{
            onClick: () => this.setState({ selected: [...this.state.selected, {}] }),
            iconName: 'add_box',
            tooltip: `${i18n.addAlert}`,
        }] : []


        return (
            <>
                <Grid container alignItems={'center'} padding={'1rem'} spacing={1}>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={this.state.alertTypes.alertEmail}
                            label={i18n.emailAlert}
                            onChange={() => this.handleChangeAlertMail('alertEmail')}
                            disabled={!this.props.isEditMode}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {
                            !!this.state.alertTypes.alertEmail && (
                                <Input
                                    value={this.state.emailsString}
                                    onChange={this.handleChangeEmails}
                                    disabled={!this.props.isEditMode}
                                    placeholder={i18n.emailSelection}
                                />
                            )
                        }
                    </Grid>
                    {
                        hasValue(getSetting(this.props.applicationSettings, 'smsSettings')) && (
                            <>
                                <Grid item xs={2}>
                                    <Checkbox
                                        checked={this.state.alertTypes.alertSms}
                                        label={i18n.alertSms}
                                        onChange={() => this.handleChangeAlertSms('alertSms')}
                                        disabled={!this.props.isEditMode}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    {
                                        !!this.state.alertTypes.alertSms && (
                                            <>
                                                <Grid item xs={3} alignItems={'center'}>
                                                    <Button
                                                        onClick={() => this.setState({ isOpenSmsModal: true })}
                                                        variant='contained'
                                                        color='primary'
                                                        disabled={!this.props.isEditMode}
                                                    >
                                                        {`${i18n.smsSelection} (${this.props.job?.parameters?.contactsIds?.length ?? 0})`}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <SmsModalContact
                                                        contactsIdsParameters={this.props.job.parameters.contactsIds}
                                                        isOpen={this.state.isOpenSmsModal}
                                                        onClose={() => this.setState({ isOpenSmsModal: false })}
                                                        setContactsIdsParameters={this.props.onChangeContactsIds}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </>
                        )
                    }
                    {
                        !(this.props.stationType === 'pluviometry') ? undefined : (
                            <>
                                <Grid item xs={4}>
                                    <RadioButtons
                                        elements={[{ code: 'LAST_MEASURE', name: 'Dernière mesure' }, { code: 'SUM_DAY', name: 'Cumul journalier' }, { code: 'SUM_PERSO', name: 'Cumul glissant personnalisé' }]}
                                        selected={this.props.filters?.modeCumulPluvio}
                                        title='Mode de cumul pluvio'
                                        onChange={v => this.props.onChangeFilters({ modeCumulPluvio: v })}
                                        disabled={!this.props.isEditMode}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        value={this.props.filters?.nbHoursCumulPerso}
                                        title='Cumul de pluie glissant en Heures'
                                        onChange={v => this.props.onChangeFilters({ nbHoursCumulPerso: v })}
                                        min={1}
                                        disabled={this.props.filters?.modeCumulPluvio !== 'SUM_PERSO' || !this.props.isEditMode}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>
                <div className='padding-top-1'>
                    <TabSelectThreshold
                        stations={this.props.stations}
                        dataTypes={this.props.dataTypes}
                        thresholds={this.props.thresholds}
                        stationsSelected={this.state.selected}
                        onChangeStation={this.handleChangeStation}
                        onChange={this.handleChange}
                        onDeleteStation={this.handleDeleteStation}
                        onDuplicateStation={this.handleDuplicateStation}
                        disabled={!this.props.isEditMode}
                        actions={addAction}
                        exportButtonOnHeader
                        stationType={this.props.stationType}
                        stationsStats={this.state.stationsStats}
                    />
                </div>
            </>
        )
    }
}

JobAlert.propTypes = {
    stationType: PropTypes.string,
    onChangeFilters: PropTypes.func,
    filters: PropTypes.shape({}),
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.object),
    thresholds: PropTypes.arrayOf(PropTypes.object),
    applicationSettings: arrayOf(SieauParameterDto),
    dataTypes: arrayOf(DtoParametrageDataType),
    defaultDt: PropTypes.string,
}
const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(JobAlert)