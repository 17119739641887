import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { formatParameterSelectedPopup } from 'campaign/utils/CampaignUtils'
import SelectionTable from 'components/datatable/SelectionTable'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { difference, intersection } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import SelectionSelect from '../../../components/forms/specific/SelectionSelect'
import { getSetting } from '../../../utils/SettingUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'

const PopupParameterAdministration = ({
    isOpen = false,
    onClose = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        applicationSettings,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const formatedParameters = useMemo(() => {
        return formatParameterSelectedPopup(parameters.filter(p => p.active))
    }, [parameters])

    const [searchValue, setSearchValue] = useState('')
    const [selection, setSelection] = useState(-1)
    const [selectionListParam, setSelectionListParam] = useState([])

    const [nbParameter, setNbParameter] = useState(() => {
        const setting = getSetting(applicationSettings, 'NB_PARAMETRE')
        return setting ? parseInt(setting) : undefined
    })
    const [shouldDisplay, setShouldDisplay] = useState(() => getSetting(applicationSettings, 'SHOULD_DISPLAY_PARAMETER') === '1')

    const [selectedParameters, setSelectedParameters] = useState(() => {
        const setting = getSetting(applicationSettings, 'SELECTED_PARAMETERS')
        return setting ? setting.split(',') : []
    })
    const [unselectedParameters, setUnselectedParameters] = useState(() => difference(formatedParameters.map(({ id }) => id), selectedParameters))


    useEffect(() => {
        setUnselectedParameters(difference(formatedParameters.map(({ id }) => id), selectedParameters))
        setSelectedParameters(selectedParameters)
    }, [formatedParameters, selectedParameters])

    const addAll = listElem => {
        const listId = listElem.map(({ id }) => id)
        const newSelectionList = [...selectedParameters, ...listId]
        const newUnselectedParameters = difference(unselectedParameters, listId)
        setSelectedParameters(newSelectionList)
        setUnselectedParameters(newUnselectedParameters)
    }

    const deleteAll = () => {
        setSelectedParameters([])
        setUnselectedParameters(formatedParameters.map(({ id }) => id))
    }

    const onAdd = elem => {
        const newSelectionList = [...selectedParameters, elem.id]
        const newUnselectedParameters = unselectedParameters.filter(id => id !== elem.id)
        setSelectedParameters(newSelectionList)
        setUnselectedParameters(newUnselectedParameters)
    }

    const onDelete = elem => {
        const newSelectionList = selectedParameters.filter(id => id !== elem.id)
        const newUnselectedParameters = [...unselectedParameters, elem.id]
        setSelectedParameters(newSelectionList)
        setUnselectedParameters(newUnselectedParameters)
    }

    const unselectedParametersFormated = useMemo(() => {
        const filterSelection = parseInt(selection) !== -1 ? intersection(unselectedParameters, selectionListParam) : unselectedParameters
        const formatedList = filterSelection.map(id => formatedParameters.find(d => d.id === id))
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? formatedList.filter(p => p.labelSearch.includes(searchValueFormat)) : formatedList
    }, [formatedParameters, searchValue, selection, selectionListParam, unselectedParameters])

    const selectedParametersFormated = useMemo(() => {
        return selectedParameters.map(id => formatedParameters.find(d => d.id === id))
    }, [formatedParameters, selectedParameters])


    const onValidate = () => {
        const newApplicationSetting = [
            { parameter: 'NB_PARAMETRE', value: `${nbParameter || ''}` },
            { parameter: 'SELECTED_PARAMETERS', value: selectedParameters.join(',') },
            { parameter: 'SHOULD_DISPLAY_PARAMETER', value: shouldDisplay ? '1' : '0' },
        ]
        dispatch(AdministrationAction.updateSieauParameters(newApplicationSetting)).then(() => onClose(true)) // true ici pour indiquer qu'il faut recharger le dashboard pour prendre en compte les nouvelles valeurs
    }

    useEffect(() => {
        setSearchValue('')
        setSelection(-1)
        setSelectionListParam([])

        const settingNbParameter = getSetting(applicationSettings, 'NB_PARAMETRE')
        setNbParameter(settingNbParameter ? parseInt(settingNbParameter) : undefined)

        setShouldDisplay(getSetting(applicationSettings, 'SHOULD_DISPLAY_PARAMETER') === '1')

        const settingSelectedParameters = getSetting(applicationSettings, 'SELECTED_PARAMETERS')
        const settingSelectedParametersList = settingSelectedParameters ? settingSelectedParameters.split(',') : []
        setSelectedParameters(settingSelectedParametersList)

        setUnselectedParameters(difference(formatedParameters.map(({ id }) => id), settingSelectedParametersList))
    }, [applicationSettings, formatedParameters, isOpen])

    return (
        <Dialog
            fullWidth
            maxWidth='xl'
            open={isOpen}
        >
            <DefaultDialogTitle
                title={i18n.administrationMostDetectedMolecules}
                onClose={onClose}
            />
            <DialogContent style={{ height: '70vh' }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={6}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.filter}</StyledLegend>
                            <Grid2 container spacing={1} style={{ paddingTop: '5px' }}>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.search}
                                        value={searchValue}
                                        onChange={setSearchValue}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <SelectionSelect
                                        value={selection}
                                        onChange={(newListParam, newSelection) => {
                                            setSelection(newSelection)
                                            setSelectionListParam(newListParam)
                                        }}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    <Grid2 size={6}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.parameter}</StyledLegend>
                            <Grid2 container alignItems='center' spacing={1} style={{ paddingTop: '5px' }}>
                                <Grid2 size={6}>
                                    <NumberField
                                        title={i18n.nbParametersDisplayed}
                                        value={nbParameter}
                                        min={1}
                                        floatValue={false}
                                        onChange={setNbParameter}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Checkbox
                                        label={i18n.displaySelectedParameters}
                                        checked={shouldDisplay}
                                        onChange={setShouldDisplay}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    <Grid2 size={12}>
                        <SelectionTable
                            maxHeight={'53vh'}

                            listData={unselectedParametersFormated}
                            listHeaders={['name', 'type', 'nature', 'status']}
                            listTitle={i18n.parameters}

                            selectedData={selectedParametersFormated}
                            selectedHeaders={['name', 'type', 'nature', 'status']}
                            selectedTitle={shouldDisplay ? i18n.displayedParameters : i18n.excludeParameters}

                            onAdd={onAdd}
                            addAll={addAll}
                            onDelete={onDelete}
                            deleteAll={deleteAll}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onValidate} variant='contained'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PopupParameterAdministration.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default PopupParameterAdministration