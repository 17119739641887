import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Job from '../../../dto/DtoJob'
import { Grid2 } from '@mui/material'

const ProcessFileIAEauJob = ({
    filters,
    isEditMode,
    onChangeFilters,
}) => {
    const disabled = !isEditMode
    useEffect(() => {
        if (!Object.keys(filters).length) {
            onChangeFilters({
                inputDir: 'DATA',
                outputDir: 'DATA',
                filter: '*.xlsx',
            })
        }
    }, [filters])
    return (
        <Grid2 container rowSpacing={1}>
            <Grid2 container className='padding-top-1' spacing={1} justifyContent='center' alignItems='center'>
                <Grid2 size={4}><Input title={'Répertoire (fichiers d\'entrée)'} value={filters.inputDir} onChange={v => onChangeFilters({ inputDir: v })} disabled={disabled} /></Grid2>
                <Grid2 size={4}><Input title={'Répertoire (fichiers de sortie)'} value={filters.outputDir} onChange={v => onChangeFilters({ outputDir: v })} disabled={disabled} /></Grid2>
                <Grid2 size={4}><Input title={'Code chaine IAEau'} value={filters.chainCode} onChange={v => onChangeFilters({ chainCode: v })} disabled={disabled} /></Grid2>
                <Grid2 size={4}><Input title={i18n.filter} value={filters.filter} onChange={v => onChangeFilters({ filter: v })} disabled={disabled} /></Grid2>
                <Grid2 size={4}><Checkbox label={i18n.renameFic} onChange={v => onChangeFilters({ renameProcessedFiles: v })} disabled={disabled} checked={filters.renameProcessedFiles} /></Grid2>
                <Grid2 size={4}><Checkbox label={i18n.dontProcessFilesInLogs} onChange={v => onChangeFilters({ dontProcessFilesInLogs: v })} disabled={disabled} checked={filters.dontProcessFilesInLogs} /></Grid2>
            </Grid2>
        </Grid2>
    )
}

ProcessFileIAEauJob.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.object,
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeFilters: PropTypes.func,
}

export default ProcessFileIAEauJob