import CatchmentReducer, { initialState as CatchmentReducerStore } from 'catchment/reducers/CatchmentReducer'
import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { createHashHistory } from 'history'
import { combineReducers } from 'redux'
import logger from 'redux-logger'
import { store as ToastrReducerStore, ToastrReducer } from 'toastr/reducers/ToastrReducer'
import WaitReducer from 'wait/WaitReducer'
import { AccountReducer, store as AccountReducerStore } from '../account/reducers/AccountReducer'
import UserReducer, { initialState as UserReducerStore } from '../administration/components/user/reducers/UserReducer'
import {
    AdministrationReducer,
    store as AdministrationReducerStore,
} from '../administration/reducers/AdministrationReducer'
import AgriReducer, { initialState as AgriReducerStore } from '../agriAdministration/reducers/AgriReducer'
import { AlertReducer, store as AlertReducerStore } from '../alerting/reducers/AlertReducer'
import CampaignReducer, { initialState as CampaignReducerStore } from 'campaign/reducers/CampaignReducer'
import { ApiCartoReducer, store as ApiCartoReducerStore } from '../carto/reducers/ApiCartoReducer'
import { SieauReducer, store as SieauReducerStore } from '../components/sieau/SieauReducer'
import DistributionUnitReducer, { initialState as DistributionUnitReducerStore } from '../distributionUnit/reducers/DistributionUnitReducer'
import { EventsReducer, store as EventsReducerStore } from '../events/reducers/EventsReducer'
import ExportReducer, { initialState as ExportReducerStore } from 'export/reducers/ExportReducer'
import { GeoApiReducer, store as GeoApiReducerStore } from '../geoApi/reducer/GeoApiReducer'
import { HomeReducer, store as HomeReducerStore } from '../home/reducers/HomeReducer'
import HydrometryReducer, { initialState as HydrometryReducerStore } from '../hydrometry/reducers/HydrometryReducer'
import { IAEauReducer, store as IAEauReducerStore } from '../iaeau/reducers/IAEauReducer'
import { JobReducer, store as JobReducerStore } from '../import/reducers/JobReducer'
import InstallationReducer, { initialState as InstallationReducerStore } from '../installation/reducers/InstallationReducer'
import { CentralReducer, store as CentralReducerStore } from '../materiel/components/central/reducers/CentralReducer'
import {
    EquipmentReducer,
    store as EquipmentReducerStore,
} from '../materiel/components/equipment/reducers/EquipmentReducer'
import {
    PowerSupplyReducer,
    store as PowerSupplyReducerStore,
} from '../materiel/components/powerSupply/reducers/PowerSupplyReducer'
import { SensorReducer, store as SensorReducerStore } from '../materiel/components/sensor/reducers/SensorReducer'
import { SimReducer, store as SimReducerStore } from '../materiel/components/sim/reducers/SimReducer'
import {
    store as SubscriptionReducerStore,
    SubscriptionReducer,
} from '../materiel/components/subscription/reducer/SubscriptionReducer'
import { store as TelecomReducerStore, TelecomReducer } from '../materiel/components/telecom/reducers/TelecomReducer'
import {
    store as VariousMaterielReducerStore,
    VariousMaterielReducer,
} from '../materiel/components/variousMateriel/reducers/VariousMaterielReducer'
import MaterielReducer, { initialState as MaterielReducerStore } from '../materiel/reducers/MaterielReducer'
import { MessagesReducer, store as MessagesReducerStore } from '../messages/reducers/MessagesReducer'
import PiezometryReducer, { initialState as PiezometryReducerStore } from '../piezometry/reducers/PiezometryReducer'
import PluviometryReducer, { initialState as PluviometryReducerStore } from '../pluviometry/reducers/PluviometryReducer'
import ProductionUnitReducer, { initialState as ProductionUnitReducerStore } from '../productionUnit/reducers/ProductionUnitReducer'
import {
    QualityIntegrationOverviewReducer,
    store as QualityIntegrationOverviewReducerStore,
} from '../quality/components/integrationOverview/reducers/QualityIntegrationOverviewReducer'
import {
    OperationReducer,
    store as OperationReducerStore,
} from '../quality/components/operation/reducers/OperationReducer'
import QualityReducer, { initialState as QualityReducerStore } from '../quality/reducers/QualityReducer'
import {
    ActivityReducer,
    store as ActivityReducerStore,
} from '../referencial/components/activity/reducers/ActivityReducer'
import {
    AquiferReducer,
    store as AquiferReducerStore,
} from '../referencial/components/aquifers/reducers/AquiferReducer'
import { CityReducer, store as CityReducerStore } from '../referencial/components/city/reducers/CityReducer'
import ContactReducer, { initialState as ContactReducerStore } from '../referencial/components/contact/reducers/ContactReducer'
import ContributorReducer, { initialState as ContributorReducerStore } from '../referencial/components/contributor/reducers/ContributorReducer'
import { CountryReducer, store as CountryReducerStore } from '../referencial/components/country/reducers/CountryReducer'
import CultureReducer, { initialState as CultureReducerStore } from '../referencial/components/cultures/reducers/CultureReducer'
import {
    CulturesFamilyReducer,
    store as CulturesFamilyReducerStore,
} from '../referencial/components/culturesFamilies/reducers/CulturesFamilyReducer'
import {
    DashboardReducer,
    store as DashboardReducerStore,
} from '../referencial/components/dashboard/reducers/DashboardReducer'
import {
    FractionReducer,
    store as FractionReducerStore,
} from '../referencial/components/fraction/reducers/FractionReducer'
import {
    HydrogeologicalEntityReducer,
    store as HydrogeologicalEntityReducerStore,
} from '../referencial/components/hydrogeologicalEntity/reducers/HydrogeologicalEntityReducer'
import {
    ManagementUnitReducer,
    store as ManagementUnitReducerStore,
} from '../referencial/components/managementUnit/reducers/ManagementUnitReducer'
import { MethodReducer, store as MethodReducerStore } from '../referencial/components/methods/reducers/MethodReducer'
import { NetworkReducer, store as NetworkReducerStore } from '../referencial/components/network/reducers/NetworkReducer'
import {
    ParameterReducer,
    store as ParameterReducerStore,
} from '../referencial/components/parameter/reducers/ParameterReducer'
import { ResourceReducer, store as ResourceReducerStore } from '../resource/reducers/ResourceReducer'
import { store as SupportReducerStore, SupportReducer } from '../referencial/components/support/reducers/SupportReducer'
import { store as TaxonReducerStore, TaxonReducer } from '../referencial/components/taxon/reducers/TaxonReducer'
import { store as UnitReducerStore, UnitReducer } from '../referencial/components/unit/reducers/UnitReducer'
import { store as UsageReducerStore, UsageReducer } from '../referencial/components/usage/reducers/UsageReducer'
import {
    store as WatermassReducerStore,
    WatermassReducer,
} from '../referencial/components/watermass/reducers/WatermassReducer'
import {
    store as WatershedReducerStore,
    WatershedReducer,
} from '../referencial/components/watershed/reducers/WatershedReducer'
import { ReferencialReducer, store as ReferencialReducerStore } from '../referencial/reducers/ReferencialReducer'
import { SectorReducer, store as SectorReducerStore } from '../referencial/components/sector/reducers/SectorReducer'
import { SSEReducer, SSEStore as SSEReducerStore } from '../sse/reducers/SSEReducer'
import {
    store as SuivipcReducerStore,
    SuivipcReducer,
} from '../station/components/suivipc/qualitometer/reducers/SuivipcReducer'
import {
    HydrometerStationReducer,
    store as HydrometerStationReducerStore,
} from '../station/reducers/HydrometerStationReducer'
import {
    PiezometerStationReducer,
    store as PiezometerStationReducerStore,
} from '../station/reducers/PiezometerStationReducer'
import { StationReducer, store as StationReducerStore } from '../station/reducers/StationReducer'
import DataManagerReducer, { initialState as DataManagerReducerStore } from './DataManagerReducer'
import TmpReducer, { initialState as TmpReducerStore } from './TmpReducer'
import PerimetersReducer, { initialState as PerimetersReducerStore } from '../perimeters/reducer/PerimetersReducer'
import PgsseReducer, { initialState as PgsseReducerStore } from '../pgsse/reducer/PgsseReducer'
import { configureStore } from '@reduxjs/toolkit'

export const history = createHashHistory()

const initialStore = {
    JobReducer: JobReducerStore,
    AlertReducer: AlertReducerStore,
    HomeReducer: HomeReducerStore,
    EventsReducer: EventsReducerStore,
    DashboardReducer: DashboardReducerStore,
    AccountReducer: AccountReducerStore,
    StationReducer: StationReducerStore,
    ReferencialReducer: ReferencialReducerStore,
    SupportReducer: SupportReducerStore,
    ContactReducer: ContactReducerStore,
    HydrogeologicalEntityReducer: HydrogeologicalEntityReducerStore,
    FractionReducer: FractionReducerStore,
    NetworkReducer: NetworkReducerStore,
    ContributorReducer: ContributorReducerStore,
    WatershedReducer: WatershedReducerStore,
    QualityReducer: QualityReducerStore,
    ParameterReducer: ParameterReducerStore,
    TaxonReducer: TaxonReducerStore,
    MethodReducer: MethodReducerStore,
    WatermassReducer: WatermassReducerStore,
    CityReducer: CityReducerStore,
    CountryReducer: CountryReducerStore,
    UnitReducer: UnitReducerStore,
    QualityIntegrationOverviewReducer: QualityIntegrationOverviewReducerStore,
    OperationReducer: OperationReducerStore,
    SuivipcReducer: SuivipcReducerStore,
    CampaignReducer: CampaignReducerStore,
    UserReducer: UserReducerStore,
    PgsseReducer: PgsseReducerStore,
    PiezometerStationReducer: PiezometerStationReducerStore,
    PiezometryReducer: PiezometryReducerStore,
    HydrometryReducer: HydrometryReducerStore,
    ProductionUnitReducer: ProductionUnitReducerStore,
    DistributionUnitReducer: DistributionUnitReducerStore,
    InstallationReducer: InstallationReducerStore,
    SieauReducer: SieauReducerStore,
    AdministrationReducer: AdministrationReducerStore(),
    HydrometerStationReducer: HydrometerStationReducerStore,
    MaterielReducer: MaterielReducerStore,
    CentralReducer: CentralReducerStore,
    PowerSupplyReducer: PowerSupplyReducerStore,
    SensorReducer: SensorReducerStore,
    SimReducer: SimReducerStore,
    VariousMaterielReducer: VariousMaterielReducerStore,
    TelecomReducer: TelecomReducerStore,
    AquiferReducer: AquiferReducerStore,
    CultureReducer: CultureReducerStore,
    CulturesFamilyReducer: CulturesFamilyReducerStore,
    PluviometryReducer: PluviometryReducerStore,
    ExportReducer: ExportReducerStore,
    SSEReducer: SSEReducerStore,
    SectorReducer: SectorReducerStore,
    EquipmentReducer: EquipmentReducerStore,
    IAEauReducer: IAEauReducerStore,
    GeoApiReducer: GeoApiReducerStore,
    MessagesReducer: MessagesReducerStore,
    AgriReducer: AgriReducerStore,
    SubscriptionReducer: SubscriptionReducerStore,
    ManagementUnitReducer: ManagementUnitReducerStore,
    ApiCartoReducer: ApiCartoReducerStore,
    ActivityReducer: ActivityReducerStore,
    UsageReducer: UsageReducerStore,
    WaitReducer: {
        isWait: false,
        name: 'ball-grid-pulse',
        color: 'blue',
        fadeIn: 'none',
    },
    ToastrReducer: ToastrReducerStore,
    DataManagerReducer: DataManagerReducerStore,
    ResourceReducer: ResourceReducerStore,
    CatchmentReducer: CatchmentReducerStore,
    TmpReducer: TmpReducerStore,
    PerimetersReducer: PerimetersReducerStore,
}

const appReducers = combineReducers(
    {
        router: createRouterReducer(history),
        ReferencialReducer,
        AlertReducer,
        CityReducer,
        CountryReducer,
        UnitReducer,
        ParameterReducer,
        TaxonReducer,
        MethodReducer,
        WatermassReducer,
        SupportReducer,
        HydrogeologicalEntityReducer,
        FractionReducer,
        NetworkReducer,
        ContactReducer,
        ContributorReducer,
        WatershedReducer,
        QualityReducer,
        JobReducer,
        AccountReducer,
        HomeReducer,
        EventsReducer,
        StationReducer,
        QualityIntegrationOverviewReducer,
        OperationReducer,
        SuivipcReducer,
        WaitReducer,
        ToastrReducer,
        CampaignReducer,
        DashboardReducer,
        UserReducer,
        PiezometerStationReducer,
        ProductionUnitReducer,
        DistributionUnitReducer,
        InstallationReducer,
        PiezometryReducer,
        HydrometryReducer,
        SieauReducer,
        AdministrationReducer,
        HydrometerStationReducer,
        MaterielReducer,
        CentralReducer,
        PowerSupplyReducer,
        SensorReducer,
        SimReducer,
        VariousMaterielReducer,
        TelecomReducer,
        AquiferReducer,
        CultureReducer,
        ActivityReducer,
        CulturesFamilyReducer,
        PluviometryReducer,
        ExportReducer,
        SSEReducer,
        SectorReducer,
        EquipmentReducer,
        IAEauReducer,
        GeoApiReducer,
        MessagesReducer,
        AgriReducer,
        SubscriptionReducer,
        ManagementUnitReducer,
        ApiCartoReducer,
        UsageReducer,
        DataManagerReducer,
        ResourceReducer,
        CatchmentReducer,
        TmpReducer,
        PerimetersReducer,
        PgsseReducer,
    })

const RootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.AdministrationReducer
        const administrationReducer = {
            ...initialStore.AdministrationReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            AdministrationReducer: administrationReducer,
        }
    }
    return appReducers(state, action)
}

const loggerMiddleware = process.env.NODE_ENV === 'development' ? [logger] : []

const AppStore = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        ...loggerMiddleware,
        createRouterMiddleware(history),
    ],
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: initialStore,
})

export default AppStore
