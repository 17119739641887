/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { CardTable } from 'components/datatable/NewTable'
import { getSandreLabel } from 'utils/StringUtil'
import { getDate } from 'utils/DateUtil'
import { push } from '@lagunovsky/redux-react-router'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import { compact } from 'lodash'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import PgsseAction from 'pgsse/action/PgsseAction'
import SelectedFormDialog from '../diagnostic/SelectedFormDialog'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'

const FormInstallationPanel = ({
    idDiagnostic,
    idCampaign,
    link_stations = [],
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)

    const {
        installations,
        installationsIndex,
        sandreCodes,
        citiesIndex,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installations: store.InstallationReducer.installationsLight,
        installationsIndex: store.InstallationReducer.installationsIndex,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const actions = compact([{
        icon: 'download',
        tooltip: i18n.export,
        onClick: () => {
            dispatch(ToastrAction.error(i18n.inDeveloppmentFunctionnality))
        },
    }, !readMode && {
        icon: 'compare_arrows',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
    }])

    const headers = ['code', 'city', 'name', { key: 'technicianLogin', value: i18n.mainTechnician }, 'status', { key: 'formDate', value: i18n.date }, 'nbAnswered', 'nbTotal']

    const data = link_stations.filter(ls => ls.stationType === STATION_TYPE_CONSTANT.INSTALLATION
        && ![INSTALLATION_TYPE.BOREHOLE, INSTALLATION_TYPE.CAPTURE, INSTALLATION_TYPE.NETWORK].includes(installationsIndex[ls.code]?.installationType))

    const formattedData = data.map(cd => {
        const foundElement = installationsIndex[cd.code] || {}
        return {
            ...foundElement,
            ...cd,
            code: foundElement.code,
            city: citiesIndex[foundElement.townCode]?.name,
            status: getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_STATUT, cd.status),
            formDate: getDate(cd.formDate),
        }
    })

    return (
        <Grid2 size={12}>
            <CardTable
                title={i18n.installations}
                actions={actions}
                rows={formattedData}
                headers={headers}
                onClickRow={(element) => {
                    if (readMode) {
                        if (element.idForm) {
                            dispatch(push(`${PATH_PGSSE}/phase1/${idDiagnostic}/campaign/${idCampaign}/${element.idForm}`))
                        } else {
                            dispatch(PgsseAction.createCampaignForm(idCampaign, element.stationType, element.id)).then(json => {
                                dispatch(push(`${PATH_PGSSE}/phase1/${idDiagnostic}/campaign/${idCampaign}/${json.id}`))
                            })
                        }
                    } else {
                        dispatch(ToastrAction.warning(i18n.pleaseSaveModifs))
                    }
                }}
            />
            <SelectedFormDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                elements={installations.filter(i => ![INSTALLATION_TYPE.BOREHOLE, INSTALLATION_TYPE.CAPTURE, INSTALLATION_TYPE.NETWORK].includes(i.installationType))}
                forms={data}
                stationType={STATION_TYPE_CONSTANT.INSTALLATION}
                keyValue='id'
                onValidate={links => {
                    onChange({
                        link_stations: [
                            ...link_stations.filter(ls => !data.map(d => d.code).includes(ls.code)),
                            ...links,
                        ],
                    })
                    setIsOpen(false)
                }}
            />
        </Grid2>
    )
}

FormInstallationPanel.propTypes = {
    idDiagnostic: PropTypes.number,
    idCampaign: PropTypes.number,
    link_stations: PropTypes.arrayOf(PropTypes.shape({})),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default FormInstallationPanel