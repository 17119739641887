import ApplicationConf from 'conf/ApplicationConf'
import { genericPromise2 } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    RECEIVE_OPERATION_COVERS,
    RECEIVE_OPERATION_SAMPLES,
    RECEIVE_ALL_OPERATION_SAMPLES,
    RECEIVE_ALL_OPERATION_COVERS,
} from 'quality/components/operation/constants/OperationConstants'

const HydrobioAction = {
    createHydrobioList: (qualitometer, operation, hydrobioList) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioLists(qualitometer, operation), { method: 'POST', body: hydrobioList })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    createAllHydrobioList: (qualitometer, operation, hydrobioLists) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.allHydrobioList(qualitometer, operation), { method: 'POST', body: hydrobioLists })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    updateHydrobioList: (qualitometer, operation, list, hydrobioList) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioList(qualitometer, operation, list), { method: 'PUT', body: hydrobioList })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    deleteHydrobioList: (qualitometer, operation, list) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioList(qualitometer, operation, list), { method: 'DELETE' })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),

    fetchAllOperationSamples: (qualitometerId) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.allHydrobioOperationSample(qualitometerId))
        .then((res = []) => {
            dispatch({ type: RECEIVE_ALL_OPERATION_SAMPLES, payload: res })
            return res
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.samples} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.samples))
        }),
    fetchOperationSamples: (qualitometerId, operationId) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationSamples(qualitometerId, operationId))
        .then((res = []) => {
            dispatch({ type: RECEIVE_OPERATION_SAMPLES, payload: res })
            return res
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.samples} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.samples))
        }),
    createOperationSample: (qualitometerId, operationId, operationSample) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationSamples(qualitometerId, operationId), { method: 'POST', body: operationSample })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.createError + i18n.samples} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.samples))
        }),
    updateOperationSample: (qualitometerId, operationId, operationSample) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationSamples(qualitometerId, operationId), { method: 'PUT', body: operationSample })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.samples} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.samples))
        }),
    deleteOperationSample: (qualitometerId, operationId, id) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationSample(qualitometerId, operationId, id), { method: 'DELETE' })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.deleteError + i18n.samples} : ${err}`))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.samples))
        }),

    fetchAllOperationCovers: (qualitometerId) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.allHydrobioOperationCover(qualitometerId))
        .then((res = []) => {
            dispatch({ type: RECEIVE_ALL_OPERATION_COVERS, payload: res })
            return res
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.covers} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.covers))
        }),
    fetchOperationCovers: (qualitometerId, operationId) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationCover(qualitometerId, operationId))
        .then((res = []) => {
            dispatch({ type: RECEIVE_OPERATION_COVERS, payload: res })
            return res
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.covers} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.covers))
        }),
    createOperationCover: (qualitometerId, operationId, operationCover) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationCover(qualitometerId, operationId), { method: 'POST', body: operationCover })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.createError + i18n.covers} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.covers))
        }),
    updateOperationCover: (qualitometerId, operationId, operationCover) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationCover(qualitometerId, operationId), { method: 'PUT', body: operationCover })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.covers} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.covers))
        }),
    deleteOperationCover: (qualitometerId, operationId) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioOperationCover(qualitometerId, operationId), { method: 'DELETE' })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.deleteError + i18n.covers} : ${err}`))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.covers))
        }),
}

export default HydrobioAction