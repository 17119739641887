/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Card, CardContent, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { CardTitle } from 'components/card/NewCard'
import PGSSEFilters from '../common/PGSSEFilters'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'

const DangerList = ({ }) => {
    const [filter, setFilter] = useState({})

    return (
        <Grid2 container spacing={1} sx={{ padding: '10 10 10 20' }}>
            <Grid2 size={12}>
                <PGSSEFilters filter={filter} onValidate={setFilter} />
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.dangers} />
                    <CardContent>
                        <DevelopmentApp />
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    )
}

DangerList.PropTypes = {
}

export default DangerList