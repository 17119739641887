import React from 'react'
import { Grid2 } from '@mui/material'
import Checkbox from '../../../../components/forms/Checkbox'
import piezometryMarker from '../../../../assets/pictures/markers/map_piezo.png'
import i18n from 'simple-react-i18n'
import pluviometryMarker from '../../../../assets/pictures/markers/map_pluvio.png'
import hydrometryMarker from '../../../../assets/pictures/markers/map_hydro.png'
import installationMarker from '../../../../assets/pictures/markers/map_installation_defaut.png'
import { styled } from '@mui/styles'
import PropTypes from 'prop-types'

const Img = styled('img')({
    width: '20px',
    height: '25px',
    cursor: 'pointer',
    margin: '0px 5px 0px 5px',
})
const FilterTypeStations = ({
    displayStation,
}) => {
    return (
        <Grid2 container size={12} alignItems={'center'} spacing={3}>
            <Grid2 size={3}>
                <Checkbox
                    label={<Img src={piezometryMarker} />}
                    checked={displayStation.displayPiezo}
                    tooltip={i18n.piezometers}
                    disabled
                />
            </Grid2>
            <Grid2 size={3}>
                <Checkbox
                    label={<Img src={pluviometryMarker} />}
                    checked={displayStation.displayPluvio}
                    tooltip={i18n.pluviometers}
                    disabled
                />
            </Grid2>
            <Grid2 size={3}>
                <Checkbox
                    label={<Img src={hydrometryMarker} />}
                    checked={displayStation.displayHydro}
                    tooltip={i18n.hydrometricStations}
                    disabled
                />
            </Grid2>
            <Grid2 size={3}>
                <Checkbox
                    label={<Img src={installationMarker} />}
                    checked={displayStation.displayInst}
                    tooltip={i18n.installations}
                    disabled
                />
            </Grid2>
        </Grid2>
    )
}
FilterTypeStations.propTypes = {
    displayStation: PropTypes.shape({
        displayPiezo: PropTypes.bool,
        displayPluvio: PropTypes.bool,
        displayHydro: PropTypes.bool,
        displayInst: PropTypes.bool,
    }),
}
export default FilterTypeStations
