import { ceil, floor, isNil, round } from 'lodash'
import moment from 'moment'

const PLUVIO = 'PLUVIO'
const HYDRO = 'HYDRO'
const PIEZO = 'PIEZO'
const QUALITO = 'QUALITO'

const AUTO_Y_SCALE = 0
const MANUAL_Y_SCALE = 1

const DEFAULT_NB_POINT_MOVING_AVERAGE = 1

const AUTO = 'AUTO'
const YEAR = 'year'
const MONTH = 'month'
const WEEK = 'week'
const DAY = 'day'
const HOUR = 'hour'

const OTHER = 'OTHER'
const ONE_WEEK = 'ONE_WEEK'
const ONE_MONTH = 'ONE_MONTH'
const ONE_YEAR = 'ONE_YEAR'

const THRESHOLD = 0
const AREA = 1
const NOTHING = 2

const DISPLAY_COTE = 'DISPLAY_COTE'
const PIEZO_TAB_DISPLAY_MODES = 'PIEZO_TAB_DISPLAY_MODES'
const CHART_SELECTED_TIME = 'CHART_SELECTED_TIME'
const CHART_SELECTED_TIME_FOLLOW_UP = 'CHART_SELECTED_TIME_FOLLOW_UP'
const CHART_SELECTED_TIME_OTHER = 'CHART_SELECTED_TIME_OTHER'
const CHART_SELECTED_TIME_FOLLOW_UP_OTHER = 'CHART_SELECTED_FOLLOW_UP_TIME_OTHER'

const yearIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 365.5,
    formatter: timestamp => moment(timestamp).year(),
}

const monthIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 30.5,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const weekIntervalFormatter = {
    interval: 3600 * 24 * 1000 * 7,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const dayIntervalFormatter = {
    interval: 3600 * 24 * 1000,
    formatter: timestamp => {
        const date = moment(timestamp)
        return `${date.format('DD/MM')}\n${date.format('YYYY')}`
    },
}

const hourIntervalFormatter = {
    interval: 3600 * 1000,
    formatter: timestamp => moment(timestamp).format('HH:mm:ss'),
}

const categorylDateFormatter = timestamp => {
    const date = moment(timestamp)
    return `${date.format('DD/MM/YYYY')}\n${date.format('HH:mm:ss')}`
}

const getIntervalFormatter = (maxDate, minDate, maxInterval = AUTO) => { // maxInterval in [AUTO, YEAR, MONTH, WEEK, DAY, HOUR]
    switch (maxInterval) {
        case YEAR: return yearIntervalFormatter
        case MONTH: return monthIntervalFormatter
        case WEEK: return weekIntervalFormatter
        case DAY: return dayIntervalFormatter
        case HOUR: return hourIntervalFormatter
        default: {
            const duration = moment.duration(maxDate.diff(minDate))
            if (duration.years() > 30) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 4 }
            }
            if (duration.years() > 20) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 3 }
            }
            if (duration.years() > 10) {
                return { ...yearIntervalFormatter, interval: yearIntervalFormatter.interval * 2 }
            }
            if (duration.years() > 2) {
                return yearIntervalFormatter
            }
            if (duration.years() === 2) {
                return { ...monthIntervalFormatter, interval: monthIntervalFormatter.interval * 6 }
            }
            if (duration.years() === 1) {
                return { ...monthIntervalFormatter, interval: monthIntervalFormatter.interval * 2 }
            }
            if (duration.months() > 2) {
                return monthIntervalFormatter
            }
            if (duration.months() > 1) {
                return { ...weekIntervalFormatter, interval: weekIntervalFormatter.interval * 2 }
            }
            if (duration.days() > 14) {
                return weekIntervalFormatter
            }
            if (duration.days() > 7) {
                return { ...dayIntervalFormatter, interval: dayIntervalFormatter.interval * 2 }
            }
            if (duration.days() >= 2) {
                return dayIntervalFormatter
            }
            if (duration.days() === 1) {
                return { ...hourIntervalFormatter, interval: hourIntervalFormatter.interval * 6 }
            }
            if (duration.hours() > 12) {
                return { ...hourIntervalFormatter, interval: hourIntervalFormatter.interval * 3 }
            }
            return hourIntervalFormatter
        }
    }
}
const SCALE = [1, 2, 3, 5, 8, 10, 20, 40, 50, 80, 100, 120, 150, 200, 250, 400, 500, 800, 1000, 1200, 1400, 1500, 2000, 2500, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000]
const DECIMAL = [1, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.0000001, 0.00000001]

const getLowerScale = (number) => {
    if (number < 1) {
        const roundNumber = round(number, 10)
        const decimal = DECIMAL.findIndex(d => d < roundNumber)
        return floor(roundNumber, decimal)
    }

    return SCALE.findLast.find(s => s < number)
}

const getUpperScale = (number) => {
    if (number < 0) {
        return -getLowerScale(Math.abs(number))
    }

    if (number < 1) {
        const roundNumber = round(number, 10)
        const decimal = DECIMAL.findIndex(d => d < roundNumber)
        return ceil(roundNumber, decimal)
    }

    return SCALE.find(s => s > number)
}

const getYScale = (min, max) => {
    if (isNil(max) || isNil(min) || max < min) {
        return {}
    }

    const displayedMax = getUpperScale(max)
    const displayedMin = min < 0 ? -getUpperScale(Math.abs(min)) : 0
    return {
        min: displayedMin,
        max: displayedMax,
        splitNumber: 5,
        interval: round((displayedMax - displayedMin) / 5, 5),
    }
}

export {
    PLUVIO,
    HYDRO,
    PIEZO,
    QUALITO,

    AUTO,
    YEAR,
    MONTH,
    WEEK,
    DAY,
    HOUR,

    OTHER,
    ONE_WEEK,
    ONE_MONTH,
    ONE_YEAR,

    DEFAULT_NB_POINT_MOVING_AVERAGE,

    AUTO_Y_SCALE,
    MANUAL_Y_SCALE,

    THRESHOLD,
    AREA,
    NOTHING,

    DISPLAY_COTE,
    PIEZO_TAB_DISPLAY_MODES,
    CHART_SELECTED_TIME,
    CHART_SELECTED_TIME_OTHER,
    CHART_SELECTED_TIME_FOLLOW_UP,
    CHART_SELECTED_TIME_FOLLOW_UP_OTHER,

    getIntervalFormatter,
    getYScale,
    categorylDateFormatter,
}
