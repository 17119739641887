/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CardTitle } from 'components/card/NewCard'
import PGSSEFilters from '../common/PGSSEFilters'
import useActions from 'utils/customHook/useActions'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Select from 'components/forms/Select'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import useTitle from 'utils/customHook/useTitle'
import { PATH_PGSSE } from 'home/constants/RouteConstants'
import PgsseAction from 'pgsse/action/PgsseAction'
import { isNil } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import { push } from '@lagunovsky/redux-react-router'
import { GroupCampaignByStatus } from '../phase1/campaign/CampaignTabs'
import { getUser } from 'utils/SettingUtils'

const PgsseDashboard = () => {
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)

    const [survey, setSurvey] = useState()
    const [collectivity, setCollectivity] = useState(getUser()?.contributorCode)
    const [sector, setSector] = useState()
    const [udi, setUDI] = useState()

    const {
        contributors,
        distributionUnits,
        sectors,
        dashboardPhase1,
        surveys,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        sectors: store.SectorReducer.sectors,
        dashboardPhase1: store.PgsseReducer.dashboardPhase1,
        surveys: store.PgsseReducer.surveys,
    }), shallowEqual)

    useActions(() => ({
        new: () => setIsOpen(true),
    }), [])

    useTitle(() => [{
        title: i18n.pgsse,
        href: PATH_PGSSE,
    }, {
        title: i18n.dashboard,
        href: `${PATH_PGSSE}/dashboard`,
    }], [])

    useEffect(() => {
        dispatch(PgsseAction.fetchDashboardPhase1({}))
    }, [])

    return (
        <>
            <Grid2 container spacing={1} sx={{ padding: '10 10 10 20' }}>
                <Grid2 size={12}>
                    <PGSSEFilters onValidate={filters => dispatch(PgsseAction.fetchDashboardPhase1(filters))} />
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.phase1Current} />
                        <CardContent sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <GroupCampaignByStatus campaigns={dashboardPhase1} />
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.phase2} />
                        <CardContent>
                            <DevelopmentApp />
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.phase3} />
                        <CardContent>
                            <DevelopmentApp />
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.phase4} />
                        <CardContent>
                            <DevelopmentApp />
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
            <Dialog open={isOpen}>
                <DefaultDialogTitle title={i18n.createPGSSE} onClose={() => setIsOpen(false)} />
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Grid2 container spacing={1}>
                                <Grid2 size={12}>
                                    <Select
                                        options={surveys}
                                        label={i18n.pgsse}
                                        value={survey}
                                        onChange={setSurvey}
                                        keyLabel='description'
                                        keyValue='idSurvey'
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={contributors}
                                        label={i18n.collectivity}
                                        value={collectivity}
                                        onChange={setCollectivity}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={sectors}
                                        label={i18n.sector}
                                        value={sector}
                                        onChange={setSector}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        options={distributionUnits}
                                        label={i18n.distributionUnitName}
                                        value={udi}
                                        onChange={setUDI}
                                        keyValue='id'
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (isNil(survey) || isNil(collectivity) || (isNil(sector) && isNil(udi))) {
                                dispatch(ToastrAction.error(i18n.pleaseCompleteAllField))
                            } else {
                                dispatch(PgsseAction.createDiagnostic({
                                    idSurvey: survey,
                                    idCollectivity: collectivity,
                                    idSector: sector,
                                    idUdi: udi,
                                })).then((json) => {
                                    dispatch(push(`${PATH_PGSSE}/phase1/${json.id}`))
                                })
                                setIsOpen(false)
                            }
                        }}
                        variant='contained'
                        color='primary'
                    >
                        {i18n.register}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

PgsseDashboard.PropTypes = {
}

export default PgsseDashboard