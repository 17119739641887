import React from 'react'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import PropTypes from 'prop-types'
import DtoHydroStats from '../../dto/chronicMeasures/DtoHydroStats'
import { orderBy } from 'lodash'
import { Card, CardContent, Grid2 } from '@mui/material'
import RadioButtons from '../../../components/forms/RadioButtons'
import { AUTO, AVERAGE, MAX, MIN, SUM } from '../../../utils/constants/MeasureConstants'
import { ButtonMUI } from '../../../components/styled/Buttons'

const HEIGHT_DATA_TYPE_ID = 4

const HydroSuiviCriterias = ({
    reloadMeasures,
    hydroStatistics,
    filter,
    setFilter,
    years,
}) => {
    const currentDataType = filter.dataType ?? (hydroStatistics.some(h => h.typeId === HEIGHT_DATA_TYPE_ID) ? HEIGHT_DATA_TYPE_ID : (hydroStatistics[0]?.typeId || HEIGHT_DATA_TYPE_ID))
    const groupModes = [
        { code: MAX, name: i18n.max },
        { code: MIN, name: i18n.min },
        { code: AVERAGE, name: i18n.average },
        { code: SUM, name: i18n.sum },
    ]
    return (
        <Grid2 container size={12} direction={'row'}>
            <Card sx={{ width: '100%' }}>
                <CardContent>
                    <Grid2 container size={12} alignItems={'center'} spacing={1}>
                        <Grid2 size={2}>
                            <Select
                                options={ orderBy(hydroStatistics, ['order', 'typeId'], 'asc').map(h => ({ ...h, code: h.typeId, name: h.label })) }
                                label={ i18n.chronicType }
                                onChange={ v => setFilter(prev => ({ ...prev, dataType: v }))}
                                value={ currentDataType }
                                noSort
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <Select
                                options={ groupModes }
                                label={ i18n.dailyRegroup }
                                onChange={ v => setFilter(prev => ({ ...prev, group: v })) }
                                value={ filter.group }
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <Select
                                options={ getStatusSelectOptions() }
                                label={ i18n.status } nullLabel='&nbsp;'
                                onChange={ v => setFilter(prev => ({ ...prev, status: v }))} value={ filter.status }
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <Select
                                options={ getQualificationSelectOptions() }
                                label={ i18n.qualification }
                                nullLabel='&nbsp;'
                                onChange={ v => setFilter(prev => ({ ...prev, qualification: v }))}
                                value={ filter.qualification }
                            />
                        </Grid2>
                        <Grid2 size={1.5}>
                            <RadioButtons
                                colOption={ 12 }
                                selected={filter.horizon}
                                title={i18n.horizon}
                                onChange={(v) => setFilter(prev => ({ ...prev, horizon: v }))}
                                elements={[
                                    { code: 'year', name: i18n.perYear },
                                    { code: 'historic', name: i18n.historic },
                                ]}
                            />
                        </Grid2>
                        { filter.horizon === 'year' ? (
                            <Grid2 size={1}>
                                <Select
                                    options={ orderBy(years, 'code', 'desc') }
                                    noSort
                                    noNullValue
                                    onChange={ e => setFilter(prev => ({ ...prev, year: e })) }
                                    label={ i18n.year }
                                    value={ filter.year }
                                    data-cy={'select-year'}
                                />
                            </Grid2>
                        ) : null }
                        <ButtonMUI
                            onClick={() => reloadMeasures(filter)}
                            sx={{ marginLeft: AUTO }}
                            variant='contained'
                            data-cy={'search'}
                        >
                            {i18n.search}
                        </ButtonMUI>
                    </Grid2>
                </CardContent>
            </Card>
        </Grid2>
    )
}

HydroSuiviCriterias.propTypes = {
    reloadMeasures: PropTypes.func,
    setFilter: PropTypes.func,
    hydroStatistics: PropTypes.arrayOf(DtoHydroStats),
    years: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.number,
    })),
    filter: PropTypes.shape({
        year: PropTypes.string,
        status: PropTypes.string,
        qualification: PropTypes.string,
        horizon: PropTypes.string,
        group: PropTypes.string,
        dataType: PropTypes.string,
    }),
}

export default HydroSuiviCriterias
