/* eslint-disable consistent-return */
import { userActions } from 'administration/components/user/reducers/UserReducer'
import CampaignAction from 'campaign/actions/CampaignAction'
import ApplicationConf from 'conf/ApplicationConf'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import { DistributionUnitActionConstant } from 'distributionUnit/reducers/DistributionUnitReducer'
import InstallationAction from 'installation/actions/InstallationAction'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import fetch from 'isomorphic-fetch'
import { chunk, flatten, groupBy, isUndefined, uniq, uniqBy, ceil, isNil } from 'lodash'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import { ProductionUnitActionConstant } from 'productionUnit/reducers/ProductionUnitReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import ResourceAction from 'resource/actions/ResourceAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, genericPromise2, genericFetchNew, getJson } from 'utils/ActionUtils'
import WaitAction from 'wait/WaitAction'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import SieauAction from '../../components/sieau/SieauAction'
import ExportAction from '../../export/actions/ExportAction'
import IAEauAction from '../../iaeau/IAEauAction'
import LogAction from '../../log/actions/LogAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import MethodAction from '../../referencial/components/methods/actions/MethodAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import ParameterAction from '../../referencial/components/parameter/actions/ParameterAction'
import SupportAction from '../../referencial/components/support/actions/SupportAction'
import UnitAction from '../../referencial/components/unit/actions/UnitAction'
import StationAction from '../../station/actions/StationAction'
import AppStore from '../../store/AppStore'
import { checkError, genericPromise, getAuthorization, promiseAllProgress } from '../../utils/ActionUtils'
import { hasValue } from '../../utils/NumberUtil'
import QualityIntegrationOverviewAction
    from '../components/integrationOverview/actions/QualityIntegrationOverviewAction'
import OperationAction from '../components/operation/actions/OperationAction'
import DtoQualitoDashboard from '../components/dashboard/dto/DtoQualitoDashboard'
import { SELECTION } from 'quality/constants/QualityConstants'

const QualityAction = {
    fetchIfNeeded: (prop, fetchFunc, param1) => {
        return function (dispatch) {
            const elem = AppStore.getState().QualityReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    promiseQualitometers() {
        return fetch(ApplicationConf.qualitometer.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchQualitometers() {
        return (dispatch) => {
            return QualityAction.promiseQualitometers()
                .then(json => {
                    dispatch(UserAction.fetchBookmarks())
                    dispatch(QualityActionConstant.receiveAllQualitometers(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometers))
                })
        }
    },
    promiseQualitometersLight() {
        return genericPromise(`${ApplicationConf.qualitometer.getAll()}?lightMode=true`)
    },
    fetchQualitometersLight(forceLoad = false, cb = () => {}) {
        return (dispatch, getState) => {
            const { qualitometersLight } = getState().QualityReducer
            if (qualitometersLight.length && !forceLoad) {
                return Promise.resolve()
            }
            return QualityAction.promiseQualitometersLight()
                .then(json => {
                    dispatch(QualityActionConstant.receiveQualitometersLight(json))
                    cb()
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometers))
                })
        }
    },
    promiseQualitometersListSpecific() {
        return genericPromise(ApplicationConf.qualitometer.listSpecific())
    },
    fetchQualitometersListSpecific: () => {
        return (dispatch) => {
            return QualityAction.promiseQualitometersListSpecific()
                .then(json => {
                    dispatch(QualityActionConstant.receiveQualitometersListSpecific(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometers))
                })
        }
    },
    promiseQualitometer: id => fetch(ApplicationConf.qualitometer.get(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(e => checkError(e, {
            404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
        })),
    fetchQualitometer: (id, withMat = true) => dispatch => {
        dispatch(WaitAction.waitStart())
        return QualityAction.promiseQualitometer(id)
            .then(json => {
                dispatch(WaitAction.waitStop())
                dispatch(QualityActionConstant.receiveQualitometer(json))
                if (withMat) {
                    dispatch(StationAction.fetchQualitometerCentralAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerPowerSupplyAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerSensorAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerSimAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerTelecomAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerVariousMaterielAssignment(json.id))
                    dispatch(StationAction.fetchQualitometerEquipmentAssignment(json.id))
                }
            })
            .catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometer} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometer))
            })
    },
    createQualitometer(newQualito, callback = () => { }) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.qualitometerPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newQualito),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(QualityActionConstant.createQualitometer({ ...newQualito, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.qualitometer))
                })
        }
    },
    updateQualitometer: (qualitometer, callback = () => { }) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.getAll(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(qualitometer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(QualityActionConstant.updateQualitometer(qualitometer))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.qualitometer))
                })
        }
    },
    deleteQualitometer: (id, callback = () => {}) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.getAll(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(QualityActionConstant.deleteQualitometer(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.qualitometer))
                })
        }
    },
    updateQualitometerContributorLinks: (id, links, callback = () => {}) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.updateContributors(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(links),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(QualityActionConstant.updateQualitometerContributors(links))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.contributors))
                })
        }
    },
    promiseQualitySelections(codeTab, progressCallback = () => {}) {
        return promiseAllProgress(codeTab.map(code => fetch(ApplicationConf.referencial.selectionParameter(code), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson)), progressCallback)
    },
    fetchQualitySelections(codeTab) {
        return (dispatch) => {
            return QualityAction.promiseQualitySelections(codeTab)
                .then((json = []) => {
                    const newSelections = json.map((selectionList, index) => ({
                        code: codeTab[index],
                        selections: selectionList,
                    }))
                    dispatch(QualityActionConstant.receiveQualitySelection(uniqBy(newSelections, 'code')))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.selections} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.selections))
                })
        }
    },

    promiseQualityProducers(id, bss) {
        return fetch(ApplicationConf.qualitometer.getStationProducers(id, bss), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    fetchQualityProducers(id, bss) {
        return (dispatch) => {
            return QualityAction.promiseQualityProducers(id, bss)
                .then((json = []) => {
                    dispatch(QualityActionConstant.receiveQualityProducers(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.selections} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.selections))
                })
        }
    },

    updateThreshold(threshold) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.threshold(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(threshold),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ToastrAction.success(i18n.thresholdUpdateSuccess))
                        dispatch(QualityAction.fetchThresholds(true))
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.threshold}, ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.threshold))
                })
        }
    },
    promiseThresholds() {
        return fetch(ApplicationConf.referencial.threshold(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchThresholds() {
        return (dispatch) => {
            return QualityAction.promiseThresholds()
                .then((json = []) => {
                    dispatch(QualityActionConstant.receiveThresholds(json))
                    dispatch(SieauAction.receiveProps('thresholds'))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.thresholds}, ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.thresholds))
                })
        }
    },

    promiseQualityThreshold(threshold) {
        return fetch(ApplicationConf.referencial.qualityThreshold(threshold.code, threshold.thresholdType), {
            method: 'GET',
            headers: getAuthorization(),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    getAllQualityThreshold: (thresholds, onProgress) => {
        const promises = thresholds.map(th => QualityAction.promiseQualityThreshold(th))
        return promiseAllProgress(promises, onProgress)
            .then((jsonTab = []) => {
                return jsonTab.map((json, index) => {
                    return {
                        thresholdCode: thresholds[index].code,
                        thresholdType: thresholds[index].thresholdType,
                        thresholds: json,
                    }
                })
            })
            .catch(() => [])
    },
    fetchAllQualityThresholds: (thresholds, callback = () => { }, progressCallback = () => { }) => dispatch =>
        promiseAllProgress(
            thresholds.map(th =>
                fetch(ApplicationConf.referencial.qualityThreshold(th.code, th.thresholdType), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(getJson)
                    .then(checkError)),
            progressCallback,
        ).then((json = []) => {
            const qualityThresholds = json.map((thresholdList, index) => ({
                thresholdCode: thresholds[index].code,
                thresholdType: thresholds[index].thresholdType,
                thresholds: thresholdList,
                error: thresholdList.error,
                errorMessage: thresholdList.message,
            }))
            dispatch(QualityActionConstant.receiveStationQualityThreshold(uniqBy(qualityThresholds, th => `${th.thresholdCode}:${th.thresholdType}`)))
            callback()
        })
            .catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.qualityThresholds))
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualityThresholds}, ${err}`))
            }),
    fetchQualityThresholds(thresholds, callback = () => {}, progressCallback = () => {}) {
        return (dispatch) => {
            const uniqIds = uniq(thresholds.map(th => `${th.code}:${th.thresholdType}`))
            if (uniqIds.length) {
                return promiseAllProgress(uniqIds.map(id => fetch(ApplicationConf.referencial.qualityThreshold(id.split(':')[0], id.split(':')[1]), {
                    method: 'GET',
                    headers: getAuthorization(),
                }).then(checkAuth).then(getJson)), progressCallback)
                    .then((json = []) => {
                        const newThresholds = [
                            ...AppStore.getState().QualityReducer.qualityThresholds,
                            ...json.map((thresholdList, index) => {
                                if (hasValue(thresholdList.length)) {
                                    return {
                                        thresholdCode: uniqIds[index].split(':')[0],
                                        thresholdType: uniqIds[index].split(':')[1],
                                        thresholds: thresholdList,
                                    }
                                }
                                return {
                                    thresholdCode: uniqIds[index].split(':')[0],
                                    thresholdType: uniqIds[index].split(':')[1],
                                    thresholds: [],
                                    error: thresholdList.error,
                                    errorMessage: thresholdList.message,
                                }
                            }),
                        ]
                        dispatch(QualityActionConstant.receiveStationQualityThreshold(uniqBy(newThresholds, th => `${th.thresholdCode}:${th.thresholdType}`)))
                        callback()
                    })
                    .catch(err => {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.qualityThresholds))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualityThresholds}, ${err}`))
                    })
            }
            return { type: '' }
        }
    },

    promiseStatus() {
        return fetch(ApplicationConf.referencial.status(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchStatus: () => (dispatch, getState) => {
        const {
            QualityReducer: { status },
        } = getState()
        if (status.length) {
            return Promise.resolve()
        }
        return QualityAction.promiseStatus()
            .then((json = []) => {
                dispatch(QualityActionConstant.receiveStatus(json))
            })
            .catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.status))
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.status}, ${err}`))
            })
    },

    promiseQualifications() {
        return fetch(ApplicationConf.referencial.qualification(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchQualifications: () => (dispatch, getState) => {
        const {
            QualityReducer: { qualifications },
        } = getState()
        if (qualifications.length) {
            return Promise.resolve()
        }
        return QualityAction.promiseQualifications()
            .then((json = []) => {
                dispatch(QualityActionConstant.receiveQualifications(json))
            })
            .catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.qualifications))
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualifications}, ${err}`))
            })
    },

    promiseFilterStations() {
        return fetch(ApplicationConf.referencial.filterStation(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchFilterStations() {
        return (dispatch) => {
            return QualityAction.promiseFilterStations()
                .then((json = []) => {
                    dispatch(QualityActionConstant.receiveFilterStations(json))
                })
                .catch(err => {
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.filters))
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.filters}, ${err}`))
                })
        }
    },

    fetchSample(qualitometerId, sampleId) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.getSample(qualitometerId, sampleId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(QualityActionConstant.receiveSample(json))
                })
        }
    },

    promiseSearchAnalysis(queries, lightMode = true) { // Les critères correspondent à l'objet QualityFilter dans la Dao QualityDao de la Daeau
        return fetch(lightMode ? ApplicationConf.analysis.searchAnalysis() : ApplicationConf.analysis.searchAnalysisObjects(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(queries),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSearchAnalysis: (queries, stations = [], callback = () => { }, progressCallback = () => { }) => dispatch => {
        const group = Math.floor(stations.length / 20)
        const promises = chunk(stations, group > 0 ? group : 1).map(stationsGroup => QualityAction.promiseSearchAnalysis({ ...queries, stations: stationsGroup }))
        return promiseAllProgress(promises, progressCallback)
            .then(jsonResults => {
                const results = flatten(jsonResults)
                dispatch(QualityActionConstant.receiveSearchAnalysis(results))
                callback(results.length)
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.searchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.searchError))
                callback()
            })
    },

    promiseSearchIndices(criterias) {
        return fetch(ApplicationConf.analysis.searchIndices(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(criterias),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSearchIndices(criterias) {
        return (dispatch) => {
            return QualityAction.promiseSearchIndices(criterias)
                .then(json => {
                    dispatch(QualityActionConstant.receiveSearchIndices(json))
                    return json.length
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.searchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.searchError))
                })
        }
    },
    getIndices: (filters, signal) => genericPromise2(ApplicationConf.analysis.searchIndices(), { method: 'POST', body: filters, signal }),
    promiseSearchStates(criterias) {
        return fetch(ApplicationConf.qualitometer.searchQualityStates(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(criterias),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSearchStates(criterias) {
        return (dispatch) => {
            return QualityAction.promiseSearchStates(criterias)
                .then(json => {
                    dispatch(QualityActionConstant.receiveQualitometerStates(json))
                    return json.length
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.searchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.searchError))
                })
        }
    },

    fetchSearchHydrobios: (filter = {}, signal, onProgress = () => {}) => dispatch => {
        const {
            stations,
            ...searchFilter
        } = filter
        const group = Math.floor(stations.length / 25)
        const promises = chunk(stations, group > 0 ? group : 1).map(stationsGroup => genericPromise2(ApplicationConf.analysis.searchQualityHydrobios(), { method: 'POST', body: { ...searchFilter, stations: stationsGroup }, signal })
            .catch(() => []))
        return promiseAllProgress(promises, onProgress)
            .then(jsonTab => {
                if (signal.aborted) {
                    return 0
                }
                const results = flatten(jsonTab)
                if (filter.ultraLightMode) {
                    dispatch(QualityActionConstant.receiveSearchHydrobiosUltraLight(results))
                } else if (filter.lightMode) {
                    dispatch(QualityActionConstant.receiveSearchHydrobiosLight(results))
                } else {
                    dispatch(QualityActionConstant.receiveSearchHydrobios(results))
                }
                return results.length
            })
    },

    fetchKeyFigures() {
        return (dispatch) => {
            return fetch(ApplicationConf.keyfigures.quality(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(QualityActionConstant.receiveKeyFigures(json))
                })
        }
    },

    updateQualityIndicators(updateActions, qualityIndicators, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.analysis.qualityIndicators(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(updateActions),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 0) {
                        dispatch(QualityActionConstant.updateQualityIndicators(qualityIndicators))
                        callback()
                        dispatch(ToastrAction.success(i18n.saveSuccess))
                    } else {
                        throw new Error('')
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError} : ${i18n.qualityIndicators}${err}`))
                    dispatch(ToastrAction.error(`${i18n.updateError} : ${i18n.qualityIndicators}`))
                })
        }
    },
    updateSelections: updateActions => dispatch => fetch(ApplicationConf.referencial.updateSelections(), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(updateActions),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError}${i18n.selections}${err}`))
            dispatch(ToastrAction.error(`${i18n.updateError}${i18n.selections}`))
        }),
    updateThresholds: updateActions => dispatch => fetch(ApplicationConf.referencial.updateThresholds(), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(updateActions),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError}${i18n.thresholds}${err}`))
            dispatch(ToastrAction.error(`${i18n.updateError}${i18n.thresholds}`))
        }),
    promiseQualityIndicators() {
        return fetch(ApplicationConf.analysis.qualityIndicators(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.length) {
                    const grouped = groupBy(json, 'stationType')
                    return Object.keys(grouped).map(stationType => ({
                        stationType: parseInt(stationType),
                        indicators: grouped[stationType].map(ind => ({
                            id: parseInt(ind.parameterCode),
                            threshold: ind.thresholdType,
                        })),
                    }))
                }
                return []
            })
    },
    fetchQualityIndicators() {
        return (dispatch) => {
            return QualityAction.promiseQualityIndicators()
                .then(indicators => {
                    dispatch(QualityActionConstant.receiveQualityIndicators(indicators))
                    return indicators
                })
        }
    },

    promiseQualityIndicatorsResults: (id, params, signal) => genericPromise2(ApplicationConf.analysis.qualityIndicatorsResults(id), { method: 'POST', body: params, signal }),

    promiseQualitometerStates(id) {
        return genericPromise(ApplicationConf.qualitometer.qualityStates(id))
    },
    fetchQualitometerStates(id) {
        return (dispatch) => {
            return QualityAction.promiseQualitometerStates(id)
                .then(json => {
                    dispatch(QualityActionConstant.receiveQualitometerStates(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometers))
                })
        }
    },
    promiseQualityStates() {
        return genericPromise(ApplicationConf.qualitometer.allQualityStates())
    },
    fetchQualityStates() {
        return (dispatch) => {
            return QualityAction.promiseQualityStates()
                .then(json => {
                    dispatch(QualityActionConstant.receiveAllQualityStates(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometers))
                })
        }
    },
    updateQualitometerState(state) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.qualityStates(state.qualitometer), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(state),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.qualitometer))
                })
        }
    },
    createQualitometerState(state) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.qualityStates(state.qualitometer), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(state),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.create >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.qualitometer))
                })
        }
    },
    deleteQualitometerState(id, state) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.qualitometer.qualityStates(id), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id, state }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.qualitometer))
                })
        }
    },

    fetchHypeTrends(stationCode, parameter, unit, measures) {
        return (dispatch) => {
            dispatch(IAEauAction.fetchHypeTrendsFromIAEau(measures, () => {}, stationCode, parameter, unit))
        }
    },

    promiseQualityNetworkLinks: () => fetch(ApplicationConf.qualitometer.networkLinks(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchQualityNetworkLinks: () => dispatch => QualityAction.promiseQualityNetworkLinks()
        .then(json => dispatch(QualityActionConstant.receiveQualityNetworkLinks(json))),

    insertQualityNetworkLink: (id, networkLinks) => () => fetch(ApplicationConf.qualitometer.networkLink(id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(networkLinks),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    promiseQualitometerPoints: id => fetch(ApplicationConf.qualitometer.points(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchQualitometerPoints: id => dispatch => QualityAction.promiseQualitometerPoints(id)
        .then(json => dispatch(QualityActionConstant.receiveQualitometerPoints(json))),

    promiseUniqQualitometerPoints: () => fetch(ApplicationConf.qualitometer.uniqPoints(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchUniqQualitometerPoints: () => dispatch => QualityAction.promiseUniqQualitometerPoints()
        .then(json => dispatch(QualityActionConstant.receiveUniqQualitometerPoints(json))),

    loadSampleTable(callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return Promise.all([
                QualityIntegrationOverviewAction.promiseOperations(),
                QualityAction.promiseQualifications(),
                QualityAction.promiseStatus(),
            ]).then(jsonTab => {
                dispatch(WaitAction.waitStop())
                dispatch(QualityIntegrationOverviewAction.receiveOperations(jsonTab[0]))
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[1]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[2]))
                callback()
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
    loadQualityResearchCriterias: (progressCallback = () => { }) => dispatch => {
        const promises = [
            UnitAction.promiseUnits(),
            MethodAction.promiseMethods(),
            ParameterAction.promiseParameters(),
            SupportAction.promiseSupports(),
            ContributorAction.promiseProducers(),
            ContributorAction.promiseContributors(),
            NetworkAction.promiseNetworks(),
            CityAction.promiseCities(),
            ContactAction.promiseContacts(),
            QualityAction.promiseQualitometersLight(),
            AdministrationAction.promiseSettings(),
            ParameterAction.promiseSelections(),
            QualityAction.promiseQualifications(),
            QualityAction.promiseFilterStations(),
            QualityAction.promiseStatus(),
            AdministrationAction.promiseSavedResearchCriterias(),
            ParameterAction.promiseParameterGroupUsage(),
            QualityAction.promiseUniqQualitometerPoints(),
            InstallationAction.promiseInstallationsLight(),
            ProductionUnitAction.promiseProductionUnits(),
            DistributionUnitAction.promiseDistributionUnits(),
            ContributorAction.promiseLaboratories(),
            ContributorAction.promiseDeterminators(),
        ]
        return promiseAllProgress(promises, progressCallback).then(jsonTab => {
            dispatch(UnitAction.receiveUnits(jsonTab[0]))
            dispatch(MethodAction.receiveMethods(jsonTab[1]))
            dispatch(ParameterAction.receiveParameters(jsonTab[2]))
            dispatch(SupportAction.receiveSupports(jsonTab[3]))
            dispatch(ContributorActionConstant.receiveProducers(jsonTab[4]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[5]))
            dispatch(NetworkAction.receiveNetworks(jsonTab[6]))
            dispatch(CityAction.receiveCities(jsonTab[7]))
            dispatch(ContactActionConstant.receiveAllContacts(jsonTab[8]))
            dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[9]))
            dispatch(AdministrationAction.receiveSettings(jsonTab[10]))
            dispatch(ParameterAction.receiveSelections(jsonTab[11]))
            dispatch(QualityActionConstant.receiveQualifications(jsonTab[12]))
            dispatch(QualityActionConstant.receiveFilterStations(jsonTab[13]))
            dispatch(QualityActionConstant.receiveStatus(jsonTab[14]))
            dispatch(AdministrationAction.receiveSavedResearchCriterias(jsonTab[15]))
            dispatch(ParameterAction.receiveParameterGroupUsage(jsonTab[16]))
            dispatch(QualityActionConstant.receiveUniqQualitometerPoints(jsonTab[17]))
            dispatch(InstallationActionConstant.ALL_INSTALLATIONS_LIGHT(jsonTab[18]))
            dispatch(ProductionUnitActionConstant.receiveAllProductionUnits(jsonTab[19]))
            dispatch(DistributionUnitActionConstant.receiveAllDistributionUnits(jsonTab[20]))
            dispatch(ContributorActionConstant.receiveLaboratories(jsonTab[21]))
            dispatch(ContributorActionConstant.receiveDeterminators(jsonTab[22]))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
    loadAEPOverview: (ids, progressCallback = () => { }) => dispatch => promiseAllProgress([
        QualityIntegrationOverviewAction.promiseOperationsWithIds(ids),
        ParameterAction.promiseParameters(),
        OperationAction.promiseRemarks(),
        ContributorAction.promiseProducers(),
        QualityAction.promiseQualifications(),
        QualityAction.promiseStatus(),
        ContributorAction.promiseLaboratories(),
        ContributorAction.promiseDeterminators(),
        SupportAction.promiseSupports(),
    ], progressCallback).then(jsonTab => {
        dispatch(QualityIntegrationOverviewAction.receiveOperations(jsonTab[0]))
        dispatch(ParameterAction.receiveParameters(jsonTab[1]))
        dispatch(OperationAction.receiveRemarks(jsonTab[2]))
        dispatch(ContributorActionConstant.receiveProducers(jsonTab[3]))
        dispatch(QualityActionConstant.receiveQualifications(jsonTab[4]))
        dispatch(QualityActionConstant.receiveStatus(jsonTab[5]))
        dispatch(ContributorActionConstant.receiveLaboratories(jsonTab[6]))
        dispatch(ContributorActionConstant.receiveDeterminators(jsonTab[7]))
        dispatch(SupportAction.receiveSupports(jsonTab[8]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),
    loadQualityAEPOverview: (progressCallback = () => { }) => dispatch => promiseAllProgress([
        ParameterAction.promiseParameters(),
        OperationAction.promiseRemarks(),
        ExportAction.promiseEnvironmentModelsByType('qualityResearch'),
        InstallationAction.promiseInstallations(),
        ProductionUnitAction.promiseProductionUnits(),
        DistributionUnitAction.promiseDistributionUnits(),
        ResourceAction.promiseResources(),
        SupportAction.promiseSupports(),
        ContributorAction.promiseProducers(),
        QualityAction.promiseQualifications(),
        QualityAction.promiseStatus(),
        UnitAction.promiseUnits(),
        ContributorAction.promiseLaboratories(),
        ContributorAction.promiseDeterminators(),
    ], progressCallback).then(jsonTab => {
        dispatch(ParameterAction.receiveParameters(jsonTab[0]))
        dispatch(OperationAction.receiveRemarks(jsonTab[1]))
        dispatch(ExportAction.receiveEnvironmentModelsByType(jsonTab[2]))
        dispatch(InstallationActionConstant.ALL_INSTALLATIONS(jsonTab[3]))
        dispatch(ProductionUnitActionConstant.receiveAllProductionUnits(jsonTab[4]))
        dispatch(DistributionUnitActionConstant.receiveAllDistributionUnits(jsonTab[5]))
        dispatch(ResourceAction.receiveResources(jsonTab[6]))
        dispatch(SupportAction.receiveSupports(jsonTab[7]))
        dispatch(ContributorActionConstant.receiveProducers(jsonTab[8]))
        dispatch(QualityActionConstant.receiveQualifications(jsonTab[9]))
        dispatch(QualityActionConstant.receiveStatus(jsonTab[10]))
        dispatch(UnitAction.receiveUnits(jsonTab[11]))
        dispatch(ContributorActionConstant.receiveLaboratories(jsonTab[12]))
        dispatch(ContributorActionConstant.receiveDeterminators(jsonTab[13]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),
    loadAEPAnalysisAndOperation: (criterias, ids, progressCallback) => dispatch => {
        const operationGroup = Math.min(Math.floor(ids.length / 20), 150)
        const operationPromises = chunk(ids, operationGroup > 0 ? operationGroup : 1).map(group => QualityIntegrationOverviewAction.promiseOperationsWithIds(group))

        const analysisGroup = Math.floor(ids.length / 20)
        const analysisPromises = chunk(ids, analysisGroup > 0 ? analysisGroup : 1).map(group => QualityAction.promiseSearchAnalysis({ ...criterias, stations: group }))

        return promiseAllProgress([
            ...operationPromises,
            ...analysisPromises,
        ], progressCallback).then(jsonTab => {
            const operations = jsonTab.slice(0, operationPromises.length)
            const analysis = jsonTab.slice(operationPromises.length)

            dispatch(QualityIntegrationOverviewAction.receiveOperations(flatten(operations)))
            dispatch(QualityActionConstant.receiveSearchAnalysis(flatten(analysis)))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
    loadQualityIndicatorsAdmin(callback = () => {}, progressCallback = () => {}) {
        return function (dispatch) {
            return promiseAllProgress([
                QualityAction.promiseQualityIndicators(),
                QualityAction.promiseThresholds(),
                QualityAction.promiseQualitometersLight(),
                ParameterAction.promiseParameters(),
            ], progressCallback).then(jsonTab => {
                dispatch(QualityActionConstant.receiveQualityIndicators(jsonTab[0]))
                dispatch(QualityActionConstant.receiveThresholds(jsonTab[1]))
                dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[2]))
                dispatch(ParameterAction.receiveParameters(jsonTab[3]))
                callback()
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
    loadQualitometersTable: (progressCallback = () => { }, cb = () => {}) => dispatch => promiseAllProgress([
        QualityAction.promiseQualitometersListSpecific(),
        StationAction.promiseFilters(),
        UserAction.promiseBookmarks(),
        CityAction.promiseCities(),
        CampaignAction.promiseCampaigns('quality'),
    ], progressCallback).then(jsonTab => {
        dispatch(QualityActionConstant.receiveQualitometersListSpecific(jsonTab[0]))
        dispatch(StationAction.receiveFilters(jsonTab[1]))
        dispatch(userActions.receiveAllBookmarks(jsonTab[2]))
        dispatch(CityAction.receiveCities(jsonTab[3]))
        dispatch(QualityActionConstant.receiveQualityCampaigns(jsonTab[4]))
        cb()
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    }),

    fetchModelsFiles(stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.getModelsFiles(stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(QualityActionConstant.receiveModelsFiles(json))
                })
                .catch((err) => {
                    if (!err.toString().includes('404')) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.models))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.models} : ${err}`))
                    }
                })
        }
    },

    loadQualityGraphicApp(callback = () => { }, progressCallback = () => { }) {
        return (dispatch) => {
            return promiseAllProgress([
                CityAction.promiseCities(),
                SupportAction.promiseSupports(),
                OperationAction.promiseRemarks(),
                QualityAction.promiseQualifications(),
                QualityAction.promiseStatus(),
                FractionAction.promiseFractions(),
                QualityAction.promiseQualitometersLight(),
            ], progressCallback).then(jsonTab => {
                dispatch(CityAction.receiveCities(jsonTab[0]))
                dispatch(SupportAction.receiveSupports(jsonTab[1]))
                dispatch(OperationAction.receiveRemarks(jsonTab[2]))
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[3]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[4]))
                dispatch(FractionAction.receiveFractions(jsonTab[5]))
                dispatch(QualityActionConstant.receiveQualitometersLight(jsonTab[6]))
                callback()
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },

    promiseEsuLight() {
        return genericPromise(ApplicationConf.station.getStationEsu())
    },
    fetchEsuLight: () => dispatch => QualityAction.promiseEsuLight()
        .then(json => dispatch(QualityActionConstant.receiveEsuLight(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.station} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.station))
        }),

    /* filterField -> SpecificAnalysisInput
    stationIds: Seq[Int]
    startDate: Option[DateTime]
    endDate: Option[DateTime]
    excludeProducer: Option[Seq[Int]]
    parameters: Option[Seq[String]]
    lightMode: Option[Boolean]
    */
    getSpecificAnalysis: filterField => dispatch => genericPromise(ApplicationConf.analysis.specific(), 'POST', filterField)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.analysis} : ${err}`))
            dispatch(ToastrAction.error(`${i18n.fetchError}${i18n.analysis}`))
        }),
    fetchQualitoDashboard(id) {
        return genericFetchNew(QualityAction.promiseQualitoDashboard(id), QualityActionConstant.receiveQualitoDashboard)
    },
    promiseQualitoDashboard(id) {
        return genericPromise(ApplicationConf.qualitometer.qualitoDashboard(id)).then(json => new DtoQualitoDashboard(json))
    },

    // new

    fetchRemarks: () => (dispatch, getState) => {
        const {
            OperationReducer: { remarks },
        } = getState()
        if (!remarks.length) {
            return OperationAction.promiseRemarks()
                .then((json = []) => {
                    dispatch(OperationAction.receiveRemarks(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.remarkCode} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.remarkCode))
                })
        }
        return Promise.resolve()
    },

    fetchOperationAndAnalysis: (ids, signal, progressCallback = () => { }) => async () => {
        if (!ids.length) {
            return { operations: [], analysis: [] }
        }
        const operations = await Promise.all(ids.map(id => genericPromise2(ApplicationConf.qualitometer.operations(id), { signal }).catch(() => [])))
        const promiseAnalysis = ids.map((id, index) => {
            const operationIds = operations[index].map(o => o.id)
            const groupSize = operationIds.length / 15 > 10 ? 15 : ceil(operationIds.length / 15)
            return chunk(operationIds, groupSize).flatMap(group => {
                const filterAnalysis = {
                    stations: [id],
                    operations: group,
                    lightMode: true,
                }
                return genericPromise2(ApplicationConf.analysis.default(), {
                    method: 'POST',
                    body: filterAnalysis,
                    signal,
                })
                    .catch(() => [])
            })
        })
        const analysis = await promiseAllProgress(flatten(promiseAnalysis), progressCallback)
        return { operations: flatten(operations), analysis: flatten(analysis) }
    },

    fetchAllThresholds: (setProgress = () => { }) => async (dispatch) => {
        const thresholds = await QualityAction.promiseThresholds().catch(() => [])

        if (isUndefined(thresholds) || thresholds.length === 0) {
            dispatch(QualityActionConstant.receiveThresholds([]))
            dispatch(QualityActionConstant.receiveStationQualityThreshold([]))
            return { thresholds: [], qualityThresholds: [] }
        }

        const qualityThresholds = await QualityAction.getAllQualityThreshold(thresholds, setProgress)

        dispatch(QualityActionConstant.receiveThresholds(thresholds))
        dispatch(QualityActionConstant.receiveStationQualityThreshold(qualityThresholds))
        return { thresholds, qualityThresholds }
    },

    fetchAllSelections: (setProgress = () => { }) => async (dispatch) => {
        const selections = await Promise.all([
            ParameterAction.promiseSelections(SELECTION.PC).catch(() => []),
            ParameterAction.promiseSelections(SELECTION.TAXON).catch(() => []),
            ParameterAction.promiseSelections(SELECTION.INDICE).catch(() => []),
            // ParameterAction.promiseSelections(SELECTION.ENVIRONMENTAL_PARAMETER).catch(() => []),
            // ParameterAction.promiseSelections(SELECTION.STATE).catch(() => []),
        ]).then(jsonTab => flatten(jsonTab))

        if (isUndefined(selections) || selections.length === 0) {
            dispatch(ParameterAction.receiveSelections([]))
            dispatch(QualityActionConstant.receiveQualitySelection([]))
            return { selections: [], qualitySelections: [] }
        }

        const listCode = selections.map(s => s.code)

        const qualitySelections = await QualityAction.promiseQualitySelections(listCode, setProgress).then(json => {
            return json.map((selectionList, index) => ({
                code: listCode[index],
                selections: selectionList.filter(s => !s.childCode),
            }))
        })

        dispatch(ParameterAction.receiveSelections(selections))
        dispatch(QualityActionConstant.receiveQualitySelection(qualitySelections))
        return { selections, qualitySelections }
    },

    synchronizeDescription: (id, body) => dispatch => genericPromise2(ApplicationConf.qualitometer.synchronizeDescription(id), { method: 'PUT', body })
        .then(res => {
            if (isNil(res.update)) {
                throw new Error('Error update')
            }
            if (res.update === 0) {
                dispatch(ToastrAction.success(i18n.noUpdate))
            } else {
                dispatch(ToastrAction.success(i18n.updateSuccess))
                dispatch(QualityAction.fetchQualitometer(id, false))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError}${i18n.station} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.station))
        }),

    getEnvironmentalCondition: (filter, signal) => genericPromise2(ApplicationConf.environmentalCondition.default(), { method: 'POST', body: filter, signal }).catch((err) => {
        if (err.name === 'AbortError') return
        console.error('An error happened while fetching environmental condition')
    }),
}

export default QualityAction
