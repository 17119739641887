import { useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import Checkbox from '../../../../../components/forms/Checkbox'
import { partition, orderBy, take, uniqBy } from 'lodash'
import { getFrequencyStats } from '../../utils/SuiviStatsUtils'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import moment from 'moment'
import Button from '../../../../../components/forms/Button'
import { MEASURE_COTE, PIEZO_TYPEID } from '../../../../constants/PiezometryConstants'
import Line from '../../../../../components/echart/series/Line'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import WaitAction from '../../../../../wait/WaitAction'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoPiezometerChartOptions from '../../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import MultiBand from '../../../../../components/echart/series/MultiBand'
import { round } from '../../../../../utils/NumberUtil'
import { MAP_SITUATION_CLASS } from '../../../../../station/constants/piezo/PiezometerStationConstants'
import { getUser } from 'utils/SettingUtils'
import { Grid2 } from '@mui/material'
import { STATISTICS } from '../../constants/PiezometerSuiviConstants'
import Select from '../../../../../components/forms/Select'
import DtoMeasureStats from '../../../../../station/dto/piezometer/DtoMeasureStats'
import { WhiteCard } from '../../../../../components/styled/Card'

const seriesProps = () => [
    { code: 'ENVELOPPE_AVERAGE', name: i18n.enveloppeAvg, color: 'green', nb: 0.5 },
    { code: 'ENVELOPPE_MEDIAN', name: i18n.enveloppeMedian, color: 'orange' },
    { code: 'ENVELOPPE_MIN', name: i18n.enveloppeMin, color: 'grey', nb: 0 },
    { code: 'ENVELOPPE_MAX', name: i18n.enveloppe, color: 'grey', nb: 1 },
]

const PiezoSuiviStatTab2 = ({
    id,
    displayCote,
    histoYears,
    hasValidMeasures, // true si il a des données valides
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent = () => {}, // met à jour les state du parent (dont les séries liées à cette tab)
    piezometerChartOptions,
    tab,
    typeId,
    piezometerStatistics = [],
    changeTypeId = () => {},
    isPiezo = true,
    minY,
}) => {
    const dispatch = useDispatch()

    const typeIdOption = piezometerChartOptions.find(opt => parseInt(opt.dataType ?? PIEZO_TYPEID.CHRONIC.toString()) === typeId)

    const [statSeries, setStatSeries] = useState(['ENVELOPPE', 'ENVELOPPE_AVERAGE'])
    const [tmpStatSeries, setTmpStatSeries] = useState(['ENVELOPPE', 'ENVELOPPE_AVERAGE'])
    const [readyApply, setReadyApply] = useState(false)
    const [validOnly, setValidOnly] = useState(hasValidMeasures)
    const [linearMode, setLinearMode] = useState(false)
    const [startDate, setStartDate] = useState(typeIdOption?.statsCalculationStartDate)
    const [endDate, setEndDate] = useState(typeIdOption?.statsCalculationEndDate)
    const changeStat = (bool, stat) => {
        if (bool) {
            setTmpStatSeries([...tmpStatSeries, stat])
        } else {
            setTmpStatSeries(tmpStatSeries.filter(s => s !== stat))
        }
        setReadyApply(true)
    }

    const change = (v, func) => {
        func(v)
        setReadyApply(true)
    }

    const saveOptions = () => {
        const newOptions = [...piezometerChartOptions.filter(opt => parseInt(opt.dataType ?? -1) !== typeId), { ...typeIdOption, statsCalculationStartDate: startDate, statsCalculationEndDate: endDate }]
        dispatch(PiezometerStationAction.updatePiezometerChartOptions(id, newOptions))
    }

    const recalculateStats = useCallback(() => {
        const toEchartMeasure = (m) => ({ value: [
            moment(m[0]).hour(12).valueOf(),
            isPiezo ? (displayCote === MEASURE_COTE.NGF ? m[1] : round(landmarkValue - m[1])) : m[1],
            { NGF: m[1], depth: round(landmarkValue - m[1]) },
        ], isPiezo })

        dispatch(WaitAction.waitStart())
        const baseInput = {
            stationId: id,
            displayCote: isPiezo ? MEASURE_COTE.NGF : undefined,
            dataType: typeId,
            validOnly,
            calculationStartDate: startDate,
            calculationEndDate: endDate,
            chartMode: true,
        }
        const endEnveloppe = moment().add(1, 'year').endOf('year').valueOf()
        const allInputs = statSeries.reduce((acc, stat) => {
            if (stat === 'ENVELOPPE') {
                return [...acc, { ...baseInput, groupFunc: 'ENVELOPPE_MIN', endEnveloppe }, { ...baseInput, groupFunc: 'ENVELOPPE_MAX', endEnveloppe }]
            }
            if (stat.includes('PERIOD')) {
                return [...acc, { ...baseInput, groupFunc: stat, endEnveloppe }]
            }
            if (stat.startsWith('ENVELOPPE_AVERAGE')) {
                return [...acc, { ...baseInput, groupFunc: 'ENVELOPPE_AVERAGE', endEnveloppe }]
            }
            if (stat.startsWith('ENVELOPPE_MEDIAN')) {
                return [...acc, { ...baseInput, groupFunc: 'ENVELOPPE_MEDIAN', endEnveloppe }]
            }
            return [...acc, { ...baseInput, groupFunc: stat }]
        }, [])
        const promises = allInputs.map(p => PiezometerStationAction.promisePiezoChartMeasures(p))
        return Promise.all(promises).then(jsonTab => {
            const allSeries = allInputs.map((s, idx) => ({ stat: s.groupFunc, json: jsonTab[idx] }))
            const [bandSeries, others] = partition(allSeries, s => {
                if (linearMode) {
                    return false
                }
                return !s.stat.includes('AVERAGE') && !s.stat.includes('MEDIAN') && (s.stat.includes('ENVELOPPE') || s.stat.includes('PERIOD'))
            })
            const periods = allSeries.filter(s => s.stat.includes('PERIOD')).map(s => s.stat.split('_')[1])
            const key = displayCote === MEASURE_COTE.NGF ? 'NGF' : 'depth'

            const bandsWithProps = bandSeries.map(b => ({ ...b, ...[...getFrequencyStats(histoYears, periods), ...seriesProps()].find(p => b.stat.replace('PERIOD', 'fre') === p.code) }))

            const hasPeriods = bandsWithProps.some(s => s.stat.includes('PERIOD'))

            const bands = (() => {
                if (!bandsWithProps.length) {
                    return []
                } else if (!hasPeriods) {
                    // afficher les enveloppes min max uniquement
                    return bandsWithProps.map((b, idx) => ({
                        color: 'grey',
                        isPiezo,
                        showSymbol: false,
                        ...b,
                        data: b.json.map(m => toEchartMeasure(m)),
                        idx,
                        lineStyle: {
                            normal: {
                                width: 1,
                            },
                        },
                    }))
                }
                // afficher les mêmes couleurs que sur les cartes de situation
                const statsColors = orderBy(take(MAP_SITUATION_CLASS, bandsWithProps.length + 1), 'order', 'asc')
                const ordered = orderBy(bandsWithProps, 'nb', 'asc')
                const lowerBand = { ...ordered[0], json: ordered[0].json.map(d => [d[0], minY, d[2], d[3]]), noYScale: true, otherProps: { NGF: { noLegend: true, noTooltip: true }, depth: {} } }
                const upperBand = { ...ordered[ordered.length - 1], json: ordered[0].json.map(d => [d[0], 600, d[2], d[3]]), noYScale: true, otherProps: { NGF: {}, depth: { noLegend: true, noTooltip: true } } }
                const baseTab = ([lowerBand, ...ordered, upperBand])
                const returned = baseTab.map((b, idx) => ({
                    isPiezo,
                    showSymbol: false,
                    ...b,
                    initialName: b.name,
                    NGFName: idx === baseTab.length -1 ? `> ${b.name}` : `< ${b.name}`,
                    depthName: idx === baseTab.length -1 ? `> ${baseTab[idx+1]?.name}` : `< ${baseTab[idx+1]?.name}`,
                    depthColor: statsColors[idx]?.chartColor || '#00008b66',
                    NGFColor: statsColors[idx - 1]?.chartColor || '#ff000066',
                    data: b.json.map(m => toEchartMeasure(m)),
                    idx,
                    ...((b.otherProps ?? {})[key] ?? {}),
                    isPeriod: true,
                }))
                return returned.map(b => ({ ...b, name: displayCote === MEASURE_COTE.NGF ? b.NGFName : b.depthName, color: displayCote === MEASURE_COTE.NGF ? b.NGFColor : b.depthColor }))
            })()

            const sortedBands = orderBy(bands, 'idx', displayCote === MEASURE_COTE.NGF ? 'asc' : 'desc')

            if (displayCote === MEASURE_COTE.DEPTH && hasPeriods && isPiezo) {
                sortedBands[1].data = sortedBands[1].data.map(d => ({ ...d, noDepthHackValue: true }))
                sortedBands[1].name = sortedBands[1].name.replace('<', '>')
            }

            const multiBands = MultiBand({ bands: sortedBands, noSort: true, noGap: !isPiezo })


            const otherSeries = others.map(serie => {
                const { stat, json } = serie
                const serieData = json
                const serieParams = [...getFrequencyStats(histoYears, periods), ...seriesProps()].find(p => stat.replace('PERIOD', 'fre') === p.code)
                return Line({
                    data: serieData.map(m => toEchartMeasure(m)),
                    name: serieParams.name,
                    isPiezo,
                    connectNulls: false,
                    showSymbol: false,
                    color: serieParams.color,
                    lineStyle: {
                        normal: {
                            color: serieParams.color,
                            type: 'dashed',
                            width: 1,
                        },
                    },
                })
            })
            changeParent({ statsSeries: [ multiBands, ...otherSeries] })
            setReadyApply(false)
            dispatch(WaitAction.waitStop())
        })
    }, [changeParent, dispatch, displayCote, endDate, histoYears, id, isPiezo, landmarkValue, linearMode, startDate, statSeries, typeId, validOnly])

    useEffect(() => {
        setValidOnly(hasValidMeasures)
        setStatSeries(['ENVELOPPE', 'ENVELOPPE_AVERAGE'])
        setTmpStatSeries(['ENVELOPPE', 'ENVELOPPE_AVERAGE'])
    }, [typeId, hasValidMeasures])

    useEffect(() => {
        recalculateStats()
    }, [statSeries])

    const statsUsed = useMemo(() => uniqBy(orderBy(piezometerStatistics, ['order', 'typeId'], 'asc').map(h => ({
        id: h.typeId,
        label: h.label,
    })), 'id'), [piezometerStatistics])

    const freChecks = getFrequencyStats(histoYears).map(period => (
        <Grid2 container key={ period.name }>
            <Grid2 size={12}>
                <Checkbox col={ 6 } label={ period.name } checked={ tmpStatSeries.includes(period.code.replace('fre', 'PERIOD')) } onChange={ v => changeStat(v, period.code.replace('fre', 'PERIOD')) }/>
            </Grid2>
        </Grid2>
    ))
    const validRadioElements = [
        { code: true, name: i18n.yes },
        { code: false, name: i18n.no },
    ]
    const disabled = getUser().consultant === '1'
    if (tab !== STATISTICS) {
        return null
    }
    return (
        <WhiteCard className='margin-top-1' title={i18n.statistics} round>
            <div className='padding-1'>
                <StyledFieldSet>
                    <StyledLegend>{i18n.dataType}</StyledLegend>
                    <Select
                        col={10}
                        value={typeId}
                        options={statsUsed}
                        onChange={v => changeTypeId(v)}
                        noNullValue
                        keyValue='id'
                        noSort
                        integerValue
                    />
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend>{i18n.options}</StyledLegend>
                    <Grid2 container size={12} spacing={1} alignItems='center'>
                        <Grid2 size={12}>
                            <RadioButtons
                                col={12}
                                elements={validRadioElements}
                                selected={validOnly}
                                onChange={v => change(v, setValidOnly)}
                                disabled={!hasValidMeasures}
                                title={i18n.calcValidDataOnly}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <h5>Dates des données à utiliser pour calculer les statistiques</h5>
                            <h6>Si la date de fin est vide, l'année en cours ne sera pas prise en compte pour les
                                calculs des statistiques.</h6>
                        </Grid2>
                        <Grid2 size={4}>
                            <SimpleDatePicker value={startDate} label={i18n.startDate}
                                onChange={v => change(v, setStartDate)} disabled={disabled}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <SimpleDatePicker value={endDate} label={i18n.endDate}
                                onChange={v => change(v, setEndDate)} max={moment().startOf('year').valueOf()}
                                disabled={disabled}
                            />
                        </Grid2>
                        {getUser().consultant !== '1' && <Grid2 size={4}><Button title={i18n.register} onClick={saveOptions}/></Grid2>}
                        <Grid2 size={12}>
                            <Checkbox checked={linearMode} onChange={setLinearMode} label='Mode linéaire'/>
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend>{i18n.statistics}</StyledLegend>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Checkbox label={i18n.enveloppeMinMax} checked={tmpStatSeries.includes('ENVELOPPE')}
                                onChange={v => changeStat(v, 'ENVELOPPE')}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <Checkbox label={i18n.averageStatistics}
                                checked={tmpStatSeries.includes('ENVELOPPE_AVERAGE')}
                                onChange={v => changeStat(v, 'ENVELOPPE_AVERAGE')}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <Checkbox label={i18n.median} checked={tmpStatSeries.includes('ENVELOPPE_MEDIAN')}
                                onChange={v => changeStat(v, 'ENVELOPPE_MEDIAN')}
                            />
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend>{i18n.frequencyStats}</StyledLegend>
                    {freChecks}
                </StyledFieldSet>
                <Grid2 container alignItems='center' justifyContent='center' spacing={1}>
                    <Grid2 size={1}>
                        <Button tooltip={i18n.apply} onClick={() => setStatSeries(tmpStatSeries)} icon='border_color'
                            className={`btn-floating btn-large ${readyApply ? 'pulse' : ''}`}
                        />
                    </Grid2>
                </Grid2>
            </div>
        </WhiteCard>
    )
}

PiezoSuiviStatTab2.propTypes = {
    tab: PropTypes.string,
    typeId: PropTypes.number,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    histoYears: PropTypes.number,
    hasValidMeasures: PropTypes.bool,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    piezometerStatistics: arrayOf(DtoMeasureStats),
    changeTypeId: PropTypes.func,
    isPiezo: PropTypes.bool,
    minY: PropTypes.number,
}

export default PiezoSuiviStatTab2