import { createSlice } from '@reduxjs/toolkit'
import HydrometryThunk from 'hydrometry/actions/HydrometryThunk'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import AppStore from 'store/AppStore'
import CampaignDto from '../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../campaign/dto/DtoStationCampaign'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import DtoKeyFigure from '../../station/dto/DtoKeyFigure'
import { arrayOf, createIndex, instanceOf } from '../../utils/StoreUtils'
import HydrometryAction from '../actions/HydrometryAction'
import DtoHydroSituation from '../components/bilanIntegration/dto/DtoHydroSituation'
import DtoHydroSituationDays from '../components/bilanIntegration/dto/DtoHydroSituationDays'
import DtoHydroMeasure from '../dto/chronicMeasures/DtoHydroMeasure'
import DtoHydroStats from '../dto/chronicMeasures/DtoHydroStats'
import DtoHydrologicalContributorLink from '../dto/DtoHydrologicalContributorLink'
import DtoHydrometricBruteMeasure from '../dto/DtoHydrometricBruteMeasure'
import DtoHydrometricStation from '../dto/DtoHydrometricStation'
import DtoHydrometryDataTypeSituation from '../dto/DtoHydrometryDataTypeSituation'
import DtoHydrometryRawSituation from '../dto/DtoHydrometryRawSituation'
import DtoHydrometrySituation from '../dto/DtoHydrometrySituation'
import DtoHydrometryThreshold from '../dto/DtoHydrometryThreshold'
import DtoTideGaugeData from 'hydrometry/dto/DtoTideGaugeData'
import DtoHydrometryBookmarksStats from '../dto/DtoHydrometryBookmarksStats'
import NetworkLinkDto from 'referencial/components/network/dto/NetworkLinkDto'

export const initialState = {
    hydrometricStation: {},
    hydrometryKeyfigures: [],
    hydrometricStations: [],
    hydrosIndex: {},
    hydrosIndexCode: {},
    hydrometrySituation: [],
    hydrometryRawSituation: [],
    hydrometryThresholds: [],
    hydrometryDataTypes: [],
    hydrometryBruteMeasures: [],
    hydroStats: [],
    hydrometryCampaigns: [],
    hydrometryCampaignStations: [],
    hydrometryCampaignsProgress: [],
    hydrologicalContributors: [],
    networkLinks: [],
    hydrometryDataTypeSituation: [],
    cachePiezometrySituation: null,
    hydroBookmarksStats: [],
    hydroStatistics: [],
    hydroMeasures: [],
    hydroSituations: [],
    hydroSituationsDays: [],
    hydroLastMeasures: [],
    hydroSituationLastMeasures: [],
    hydrometryObservations: [],
    hydrometryCountObservations: [],
    tideGaugeDatas: [],
}

const store = createSlice({
    name: 'hydrometry',
    initialState,
    reducers: {
        createHydrometer: (state, action) => {
            state.hydrometricStations = [...state.hydrometricStations, new DtoHydrometricStation(action.payload)]
        },
        deleteHydrometer: (state, action) => {
            state.hydrometricStations = state.hydrometricStations.filter(hs => hs.id !== action.payload)
        },
        receiveHydrometricStation: (state, action) => {
            state.hydrometricStation = new DtoHydrometricStation(action.payload)
        },
        receiveHydroBookmarksStats: (state, action) => {
            state.hydroBookmarksStats = action.payload.map(m => new DtoHydrometryBookmarksStats(m))
        },
        receiveHydroStatistics: (state, action) => {
            state.hydroStatistics = action.payload.map(m => new DtoHydroStats(m))
        },
        receiveHydroChronicMeasures: (state, action) => {
            state.hydroMeasures = action.payload // liste de DtoHydroMeasures
        },
        receiveTideGaugeDatas: (state, action) => {
            state.tideGaugeDatas = action.payload.map(p => new DtoTideGaugeData(p))
        },
        receiveHydrometricStationKeyFigures: (state, action) => {
            state.hydrometryKeyfigures = action.payload.map(m => new DtoKeyFigure(m))
        },
        receiveHydrometricCountObservations: (state, action) => {
            state.hydroCountObservations = action.payload.map(m => new DtoKeyFigure(m))
        },
        receiveHydrometryThresholds: (state, action) => {
            state.hydrometryThresholds = action.payload.map(m => new DtoHydrometryThreshold(m))
        },
        receiveAllHydrometricStations: (state, action) => {
            const hydros = action.payload.map(hydro => new DtoHydrometricStation(hydro))
            state.hydrometricStations = hydros
            state.hydrosIndex = createIndex(hydros)
            state.hydrosIndexCode = createIndex(hydros, 'code')
        },
        receiveHydrometrySituation: (state, action) => {
            state.hydrometrySituation = action.payload.map(m => new DtoHydrometrySituation(m))
        },
        receiveHydrometryDataTypes: (state, action) => {
            state.hydrometryDataTypes = action.payload.map(m => new DtoParametrageDataType(m))
        },
        receiveHydrometryNetworkLinks: (state, action) => {
            state.networkLinks = action.payload.map(e => new NetworkLinkDto(e))
        },
        receiveHydrometryObservations: (state, action) => {
            state.hydrometryObservations = action.payload.map(m => new DtoHydrometryObservation(m))
        },
        receiveHydrometryRawSituation: (state, action) => {
            state.hydrometryRawSituation = action.payload.map(m => new DtoHydrometryRawSituation(m))
        },
        receiveHydrometryDataTypeSituation: (state, action) => {
            state.hydrometryDataTypeSituation = action.payload.map(m => new DtoHydrometryDataTypeSituation(m))
        },
        receiveHydrometryBruteMeasures: (state, action) => {
            state.hydrometryBruteMeasures = action.payload.map(m => new DtoHydrometricBruteMeasure(m))
        },
        receiveHydrometryCampaigns: (state, action) => {
            state.hydrometryCampaigns = action.payload.map(m => new CampaignDto(m))
        },
        receiveHydrometryCampaignsProgress: (state, action) => {
            state.hydrometryCampaignsProgress = action.payload.map(m => new DtoCampaignProgress(m))
        },
        receiveHydrometryCampaignStations: (state, action) => {
            state.hydrometryCampaignStations = action.payload.map(m => new DtoStationCampaign(m))
        },
        resetStation: (state) => {
            state.hydrometryBruteMeasures = []
            state.hydroStatistics = []
            state.hydroMeasures = []
        },
        receiveAllHydrologicalContributors: (state, action) => {
            state.hydrologicalContributors = action.payload.map(m => new DtoHydrologicalContributorLink(m))
        },
        cacheHydroSituation: (state, action) => {
            state.cacheHydrometrySituation = action.payload
        },
        receiveHydroSituations: (state, action) => {
            state.hydroSituations = action.payload.map(m => new DtoHydroSituation(m))
        },
        receiveHydroSituationsDays: (state, action) => {
            state.hydroSituationsDays = action.payload.map(m => new DtoHydroSituationDays(m))
        },
        receiveHydroLastMeasures: (state, action) => {
            state.hydroLastMeasures = action.payload.map(m => new DtoHydroMeasure(m))
        },
        receiveHydroSituationLastMeasures: (state, action) => {
            state.hydroSituationLastMeasures = action.payload.map(m => new DtoHydroMeasure(m))
        },
        receiveHydroStats: (state, action) => {
            state.hydroStats = action.payload.map(m => new DtoHydroStats(m))
        },
        resetHydroStats: (state) => {
            state.hydroStats = []
        },
    },
    extraReducers: {
        [HydrometryThunk.fetchHydrometricStations.fulfilled]: (state, action) => {
            state.hydrometricStations = action.payload.map(hydro => new DtoHydrometricStation(hydro))
        },
        [HydrometryThunk.fetchHydroBookmarksStats.fulfilled]: (state, action) => {
            state.hydroBookmarksStats = action.payload.map(e => new DtoHydrometryBookmarksStats(e))
        },
    },
})

export const HydrometryActionConstant = store.actions
export default store.reducer

export const HydrometryStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        hydrometricStation: {
            fetch: HydrometryAction.fetchHydrometricStation,
            store: appStore.HydrometryReducer.hydrometricStation,
            propType: instanceOf(DtoHydrometricStation),
        },
        hydrometricStations: {
            fetch: HydrometryAction.fetchHydrometricStations,
            store: appStore.HydrometryReducer.hydrometricStations,
            propType: arrayOf(DtoHydrometricStation),
        },
        hydrometryKeyfigures: {
            fetch: HydrometryAction.fetchKeyFigures,
            store: appStore.HydrometryReducer.hydrometryKeyfigures,
            propType: arrayOf(DtoKeyFigure),
        },
        hydrometrySituation: {
            fetch: HydrometryAction.fetchHydrometrySituation,
            store: appStore.HydrometryReducer.hydrometrySituation,
            propType: arrayOf(DtoHydrometrySituation),
        },
    }
}