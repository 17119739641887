import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import { searchAllCharacters } from '../../utils/StringUtil'
import Input from './Input'
import { registerLocale } from 'react-datepicker'
import { fr } from 'date-fns/locale/fr'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker as DateTimePickerMUI } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { darkBlue, borderInputColor, secondaryBlue, disabledBorderInputColor } from 'utils/constants/ColorTheme'
registerLocale('fr', fr)

const dateStyle = {
    border: `1px solid ${borderInputColor}`,
    backgroundColor: 'white',
    color: 'black',
    cursor: 'text',
}

const viewsDate = ['year', 'month', 'day']
const viewsDateTime = ['year', 'month', 'day', 'hours', 'minutes', 'seconds']

const SimpleDatePicker = ({
    col, // @deprecated to remove when no more row / col in app
    label = '',
    value,
    onChange = () => { },
    id,
    disabled,
    readMode,
    className,
    min,
    max,
    obligatory,
    'data-cy': dataCy,
    withHour = false,
    locale = 'fr',
    ...otherProps
}) => {
    const inputRef = useRef()
    const momentDate = withHour ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'

    const componentId = id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('numero', '').replace('nom', 'libelle')

    const isActive = document.activeElement === inputRef.current
    const color = disabled ? 'rgba(0, 0, 0, 0.42)' : darkBlue

    return (
        <div className={col && `col s${col}`} style={{ position: 'relative' }}>
            {readMode ? (
                <DisplayedValue
                    label={label}
                    obligatory={obligatory}
                    value={value ? moment(value).format(momentDate) : ''}
                />
            ): (
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                    {label && (
                        <div
                            style={{
                                position: 'absolute',
                                transform: 'translate(1.2rem, -5px)',
                            }}
                        >
                            <label
                                style={{
                                    color: isActive ? secondaryBlue : color,
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                }}
                            >
                                {label}
                            </label>
                            {obligatory && (
                                <span
                                    style={{
                                        color: isActive ? secondaryBlue : color,
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {i18n.obligatoryField}
                                </span>
                            )}
                        </div>

                    )}
                    <DateTimePickerMUI
                        id={componentId}
                        className={className}
                        value={value ? moment(value) : undefined}
                        onChange={(v) => onChange(moment(v).valueOf())}
                        minDate={min ? moment(min) : undefined}
                        maxDate={max ? moment(max) : undefined}
                        data-cy={dataCy}
                        disabled={disabled}
                        views={withHour ? viewsDateTime : viewsDate}
                        sx={{
                            margin: '14px 0 6px',
                            padding: 0,
                            height: '2rem',
                            position: 'relative',
                            backgroundColor: 'white',
                            borderRadius: MAIN_RADIUS,
                            '& input': {
                                border: 'none !important',
                                height: '2rem !important',
                                margin: '0 !important',
                                color: 'black !important',
                                '&:focus': {
                                    boxShadow: 'none !important',
                                },
                            },
                            '& .MuiOutlinedInput-root': {
                                paddingRight: '15px',
                                '&:hover': {
                                    outline: 'none',
                                },
                                '&:focus-visible': {
                                    outline: 'none',
                                },
                            },
                            '& fieldset': {
                                border: '1px solid !important',
                                borderColor: !disabled ? `${borderInputColor} !important` : `${disabledBorderInputColor} !important`,
                                '&:focus-visible': {
                                    outline: 'none !important',
                                },
                            },
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                placeholder: withHour ? 'JJ/MM/AAAA HH:mm' : 'JJ/MM/AAAA',
                                'data-cy': dataCy,
                            },
                        }}
                        {...otherProps}
                    />
                </LocalizationProvider>
            )}
        </div>
    )
}

SimpleDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    /**
     * @deprecated remove all row / col and use Grid2 instead
     */
    col: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    obligatory: PropTypes.bool,
    className: PropTypes.string,
    'data-cy': PropTypes.string,
    withHour: PropTypes.bool,
    locale: PropTypes.string,
    otherProps: PropTypes.shape({}),
}

const OldDatePicker = ({
    label = '',
    value,
    onChange = () => { },
    col,
    id,
    disabled,
    readMode,
    style,
    styleLabel,
    dateClassName,
    min,
    max,
    obligatory,
    calendarPosition = 'bottom',
    'data-cy': dataCy,
    withHour = false,
}) => {
    const pickerDate = withHour ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'
    const momentDate = withHour ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'

    const componentId = id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('numero', '').replace('nom', 'libelle')
    if (readMode) {
        return (
            <div
                className={col ? `col s${col}` : 'col'}
                style={{ display: 'block', ...style }}
            >
                <DisplayedValue
                    label={label}
                    obligatory={obligatory}
                    value={value ? moment(value).format(momentDate) : ''}
                />
            </div>
        )
    }
    const date = (
        <DatePicker
            id={componentId}
            dateFormat={pickerDate}
            placeholderText={withHour ? 'JJ/MM/AAAA HH:mm' : 'JJ/MM/AAAA'}
            selected={value ? new Date(value) : null}
            className={`sieau-input form-control input-sm ${dateClassName}`}
            onChange={v => onChange(v && moment(v).valueOf())}
            minDate={min ? new Date(min) : null}
            maxDate={max ? new Date(max) : null}
            style={dateStyle}
            popperPlacement={calendarPosition}
            showTimeSelect={withHour}
            locale='fr'
        />
    )

    // DatePicker value is not reset on cancel update, so we have to use this trick
    const dateDisabled = (
        <Input
            id={componentId}
            value={value ? moment(value).format(momentDate) : ''}
            placeholder='JJ/MM/AAAA'
            disabled
        />
    )

    if (!label && !col) {
        return disabled ? dateDisabled : date
    }
    return (
        <div
            className={col ? `col s${col}` : ''}
            style={style}
        >
            <div className='input-field simpleDatePicker' data-cy={dataCy}>
                <label
                    htmlFor={componentId}
                    style={disabled
                        ? { ...styleLabel }
                        : { color: '#161832', ...styleLabel }
                    }
                >
                    {label}
                    {obligatory ? <span className='primary-color-text'>{i18n.obligatoryField}</span> : null}
                </label>
                {disabled ? dateDisabled : date}
            </div>
        </div>
    )
}

OldDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    col: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    obligatory: PropTypes.bool,
    style: PropTypes.shape({}),
    styleLabel: PropTypes.shape({}),
    dateClassName: PropTypes.string,
    calendarPosition: PropTypes.oneOf(['auto-start', 'auto', 'auto-end', 'top-start', 'top', 'top-end', 'right-start', 'right', 'right-end', 'bottom-end', 'bottom', 'bottom-start', 'left-end', 'left', 'left-start']),
    'data-cy': PropTypes.string,
    withHour: PropTypes.bool,
}

export default SimpleDatePicker
export { OldDatePicker }