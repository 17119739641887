import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { PgsseActionConstant } from 'pgsse/reducer/PgsseReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise2 } from 'utils/ActionUtils'
import { hasValue } from 'utils/NumberUtil'
import WaitAction from 'wait/WaitAction'

const PgsseAction = {
    promiseSurveys() {
        return genericPromise2(ApplicationConf.pgsse.surveys())
    },
    fetchSurveys: () => dispatch => {
        return PgsseAction.promiseSurveys()
            .then((json = []) => {
                dispatch(PgsseActionConstant.receiveSurveys(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    promiseSurvey(idSurvey, body) {
        return genericPromise2(ApplicationConf.pgsse.survey(idSurvey), { method: 'POST', body })
    },
    fetchSurvey: (idSurvey, body) => dispatch => {
        return PgsseAction.promiseSurvey(idSurvey, body)
            .then((json = []) => {
                dispatch(PgsseActionConstant.receiveSurvey(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    promiseDashboardPhase1(body) {
        return genericPromise2(ApplicationConf.pgsse.dashboardPhase1(), { method: 'POST', body })
    },
    fetchDashboardPhase1: (filters) => dispatch => {
        return PgsseAction.promiseDashboardPhase1(filters)
            .then((json = []) => {
                dispatch(PgsseActionConstant.receiveDashboardPhase1(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    promiseCreateDiagnostic: (body) => genericPromise2(ApplicationConf.pgsse.create(), { method: 'POST', body }),
    createDiagnostic: (body) => dispatch => {
        return PgsseAction.promiseCreateDiagnostic(body)
            .then((json = []) => {
                if (hasValue(json?.id)) {
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                }
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.diagnostic} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.diagnostic))
            })
    },

    promiseDashboardPhase1List(body) {
        return genericPromise2(ApplicationConf.pgsse.phase1(), { method: 'POST', body })
    },
    fetchDashboardPhase1List: (filters) => dispatch => {
        return PgsseAction.promiseDashboardPhase1List(filters)
            .then((json = []) => {
                dispatch(PgsseActionConstant.receivePhase1List(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    promiseDiagnostic: (idDiagnostic) => genericPromise2(ApplicationConf.pgsse.diagnostic(idDiagnostic)),
    fetchDiagnostic: (idDiagnostic) => {
        return (dispatch) => {
            return PgsseAction.promiseDiagnostic(idDiagnostic)
                .then((json = []) => {
                    dispatch(PgsseActionConstant.receiveDiagnostic(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseUpdateDiagnostic: (idDiagnostic, body) => genericPromise2(ApplicationConf.pgsse.diagnostic(idDiagnostic), { method: 'PUT', body }),
    updateDiagnostic: (idDiagnostic, diagnostic) => dispatch => {
        return PgsseAction.promiseUpdateDiagnostic(idDiagnostic, diagnostic)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                dispatch(PgsseAction.fetchDiagnostic(idDiagnostic))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.diagnostic} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.diagnostic))
            })
    },

    promiseDeleteDiagnostic: idDiagnostic => genericPromise2(ApplicationConf.pgsse.diagnostic(idDiagnostic), { method: 'DELETE' }),
    deleteDiagnostic: idDiagnostic => dispatch => {
        return PgsseAction.promiseDeleteDiagnostic(idDiagnostic)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.diagnostic} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.diagnostic))
            })
    },

    promiseCampaign: (idCampaign) => genericPromise2(ApplicationConf.pgsse.campaign(idCampaign)),
    fetchCampaign: (idCampaign) => {
        return (dispatch) => {
            return PgsseAction.promiseCampaign(idCampaign)
                .then((json = []) => {
                    dispatch(PgsseActionConstant.receiveCampaign(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseUpdateCampaign: (idCampaign, body) => genericPromise2(ApplicationConf.pgsse.campaign(idCampaign), { method: 'PUT', body }),
    updateCampaign: (idCampaign, campaign) => dispatch => {
        return PgsseAction.promiseUpdateCampaign(idCampaign, campaign)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                dispatch(PgsseAction.fetchCampaign(idCampaign))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.campaign} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.campaign))
            })
    },

    promiseDeleteCampaign: idCampaign => genericPromise2(ApplicationConf.pgsse.campaign(idCampaign), { method: 'DELETE' }),
    deleteCampaign: idCampaign => dispatch => {
        return PgsseAction.promiseDeleteCampaign(idCampaign)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.campaign} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.campaign))
            })
    },

    promiseForm: (idForm) => genericPromise2(ApplicationConf.pgsse.form(idForm)),
    fetchForm: (idForm) => {
        return (dispatch) => {
            return PgsseAction.promiseForm(idForm)
                .then((json = []) => {
                    dispatch(PgsseActionConstant.receiveForm(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseUpdateForm: (idForm, body) => genericPromise2(ApplicationConf.pgsse.form(idForm), { method: 'PUT', body }),
    updateForm: (idForm, Form) => dispatch => {
        return PgsseAction.promiseUpdateForm(idForm, Form)
            .then(() => {
                dispatch(PgsseAction.fetchForm(idForm))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.form} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.form))
            })
    },

    promiseDeleteForm: idForm => genericPromise2(ApplicationConf.pgsse.form(idForm), { method: 'DELETE' }),
    deleteForm: idForm => dispatch => {
        return PgsseAction.promiseDeleteForm(idForm)
            .then(() => {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.form} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.form))
            })
    },

    promiseCreateCampaignForm: (idCampaign, stationType, idInstallation) => genericPromise2(ApplicationConf.pgsse.createCampaignForm(idCampaign, stationType, idInstallation), { method: 'POST' }),
    createCampaignForm: (idCampaign, stationType, idInstallation) => dispatch => {
        return PgsseAction.promiseCreateCampaignForm(idCampaign, stationType, idInstallation)
            .then((json = []) => {
                if (hasValue(json?.id)) {
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                }
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.form} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.form))
            })
    },

    promiseCreateForm: (body) => genericPromise2(ApplicationConf.pgsse.createForm(), { method: 'POST', body }),
    createForm: (body) => dispatch => {
        return PgsseAction.promiseCreateForm(body)
            .then((json = []) => {
                if (hasValue(json?.id)) {
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                }
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.form} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.form))
            })
    },

    promiseCreateCampaign: (idDiagnostic) => genericPromise2(ApplicationConf.pgsse.createCampaign(idDiagnostic), { method: 'POST' }),
    createCampaign: (idDiagnostic) => dispatch => {
        return PgsseAction.promiseCreateCampaign(idDiagnostic)
            .then((json = []) => {
                if (hasValue(json?.id)) {
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                }
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.campaign} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.campaign))
            })
    },
}

export default PgsseAction
