import { groupBy, orderBy, uniqBy } from 'lodash'
import { ButtonGroup, Icon as IconMui, Popover } from '@mui/material'
import { ButtonMUI } from '../../../components/styled/Buttons'
import Row from '../../../components/react/Row'
import Checkbox from '../../../components/forms/Checkbox'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import React, { useEffect, useRef, useState } from 'react'
import IAEauAction from '../../../iaeau/IAEauAction'
import AppStore from '../../../store/AppStore'
import AlertAction from '../../../alerting/actions/AlertAction'
import { execByType } from '../../../utils/StationUtils'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'


const ChartTabPredData = ({
    stationType,
    id,
    useFromSituation,
    setParentModelData,
    cumulPluvio,
}) => {
    let modelListPopupRef = useRef(undefined)
    const [modelData, setModelData] = useState({ predMeasures: [], selectedPred: undefined, predDate: undefined, predStats: [] })
    const [modelListPopupOpen, setModelListPopupOpen] = useState(false)

    const {
        piezometerStatistics,
        hydroStatistics,
        pluviometerStatistics,
    } = useSelector(store => ({
        piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    }), shallowEqual)

    useEffect(() => {
        IAEauAction.promisePredStats(stationType, parseInt(id)).then(predStats => {
            const usedPredStats = useFromSituation ? predStats.filter(s => s.model?.displaySituation) : predStats
            const selecteds = uniqBy(orderBy(usedPredStats.filter(p => p.maxSimulationDate), 'maxSimulationDate', 'desc'), 'typeId')
            if (selecteds.length) {
                // const lastSimulationDate = selected.maxSimulationDate
                Promise.all(selecteds.map(s => IAEauAction.promiseModelMeasures(stationType, parseInt(id), s.idModel, s.source, s.maxSimulationDate, cumulPluvio, s.typeId))).then(resultsTab => {
                    const stateObj = resultsTab.reduce((acc, r, idx) => {
                        acc.selectedPred[selecteds[idx].typeId] = selecteds[idx]
                        acc.predMeasures[selecteds[idx].typeId] = r
                        return acc
                    }, { selectedPred: {}, predStats: usedPredStats, predMeasures: {} })
                    setParentModelData(stateObj)
                    setModelData(stateObj)
                    if (useFromSituation) {
                        AppStore.dispatch(AlertAction.setThresholdPreviListData({
                            predMeasures: resultsTab[0],
                            thresholds: execByType(stationType, {
                                piezometry: () => AppStore.getState().PiezometerStationReducer.piezometerThresholds.map(t => ({ ...t, value: selecteds[0].typeId === -1 ? t.NGF : t.value })),
                                hydrometry: () => AppStore.getState().HydrometryReducer.hydrometryThresholds,
                            }),
                        }))
                    }
                })
            } else {
                setParentModelData({ selectedPred: {}, predStats: usedPredStats })
                setModelData({ selectedPred: {}, predStats: usedPredStats })
            }
        })
    }, [])

    const { selectedPred, predStats, predMeasures } = modelData
    const predStatsByTypeId = groupBy(orderBy(predStats, 'typeId'), 'typeId')

    const changeModel = (pred, v) => {
        const newSelectedPred = { ...selectedPred, [pred.typeId]: v ? pred : undefined }

        setModelData({ selectedPred: newSelectedPred })
        if (pred.maxSimulationDate) {
            IAEauAction.promiseModelMeasures(stationType, parseInt(id), pred.idModel, pred.source, pred.maxSimulationDate, cumulPluvio, pred.typeId).then(results => {
                const newPredMeasures = { ...predMeasures, [pred.typeId]: results }
                setModelData({ predMeasures: newPredMeasures, selectedPred: newSelectedPred, predStats })
                setParentModelData({ predMeasures: newPredMeasures, selectedPred: newSelectedPred, predStats })
            })
        }
    }

    useEffect(() => {
        if (stationType === 'pluviometry' && selectedPred?.['1']) {
            changeModel(modelData.selectedPred['1'], true)
        }
    }, [cumulPluvio])


    if (!predStats?.length) {
        return null
    }


    const getStationStatistics = () => execByType(stationType, {
        piezometry: () => piezometerStatistics,
        hydrometry: () => hydroStatistics,
        pluviometry: () => pluviometerStatistics,
    })

    return (
        <div className='padding-left-1'>
            <ButtonGroup>
                <ButtonMUI
                    variant={'outlined'}
                    onClick={() => setModelListPopupOpen(!modelListPopupOpen)}
                    ref={modelListPopupRef}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: 2, fontWeight: 600 }}
                ><IconMui style={{ fontSize: 22, color: 'rgba(53, 96, 159, 1)' }}>extension</IconMui></ButtonMUI>
            </ButtonGroup>
            <Popover
                open={modelListPopupOpen}
                anchorEl={modelListPopupRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setModelListPopupOpen(false)}
            >
                <div style={ { minWidth: 300, overflow: 'hidden' }}>
                    <Row className='padding-top-1 padding-left-1'>
                        {predStats.length} Série{predStats.length > 1 ? 's' : ''} de prévison disponible{predStats.length > 1 ? 's' : ''}
                    </Row>
                    {
                        Object.keys(predStatsByTypeId).map(key => {
                            const preds = predStatsByTypeId[key].map(pred => (
                                <Row className='padding-top-1 valign-wrapper'>
                                    <div className='col s1'><Checkbox checked={selectedPred[key]?.source === pred.source} onChange={ (v) => changeModel(pred, v) } /></div>
                                    <div className='col s11'>
                                        <span className='arrests-level-panel' style={{ color: pred.color || 'black', backgroundColor: pred.color || 'black' }}>OO</span>
                                        {pred.source} ({pred.horizon} {i18n[pred.horizonMode || 'days']}) du {getDate(pred.maxSimulationDate)}
                                    </div>
                                </Row>
                            ))
                            return (
                                <>
                                    <Row className='padding-top-1 padding-left-1 valign-wrapper'>
                                        { getStationStatistics().find(stat => stat.typeId === parseInt(key))?.label ?? 'TYPE INCONNU' }
                                    </Row>
                                    {preds}
                                </>
                            )
                        })
                    }
                </div>
            </Popover>
        </div>
    )
}

ChartTabPredData.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.number,
    useFromSituation: PropTypes.bool,
    setParentModelData: PropTypes.func,
    cumulPluvio: PropTypes.string,
}

export default ChartTabPredData