import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { CardTable } from '../../../../components/datatable/NewTable'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { Grid2 } from '@mui/material'
import RadioButtons from '../../../../components/forms/RadioButtons'


class HydroPanel extends Component {
    state = {
    }

    componentDidMount() {
        if (!(this.props.job?.parameters?.dataTypes ?? []).length) {
            this.props.onChangeDataTypes(['stationIntegration', 'DATA_ADD_NEW_DATA', 'heightIntegration', 'debitIntegration'])
        }
    }

    onDeleteHydroCode = ({ hydroCode }) => {
        const filters = this.getFilters()
        if (this.props.isEditMode) {
            if (filters.stationsMode === 'stations') {
                this.props.addHydroCode(this.props.job.parameters.parameters.filter(p => p !== hydroCode))
            } else {
                const list = (filters[filters.stationsMode] ?? []).filter(p => p !== hydroCode)
                this.onChangeFilters({ [filters.stationsMode]: list })
            }
        }
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    addHydroCode = (filters) => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddHydroCode.value.replaceAll(' ', '')
            if (filters.stationsMode === 'stations') {
                const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
                if (!code) {
                    this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
                } else if (exist) {
                    this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
                } else {
                    this.props.addHydroCode([
                        code,
                        ...this.props.job.parameters.parameters,
                    ])
                    this.refs.txtAddHydroCode.value = ''
                }
            } else {
                const exist = (filters[filters.stationsMode] ?? []).find(p => p.toLowerCase() == code.toLowerCase())
                if (!code) {
                    this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
                } else if (exist) {
                    this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
                } else {
                    this.onChangeFilters({ [filters.stationsMode]: [code, ...(filters[filters.stationsMode] ?? [])] })
                    this.refs.txtAddHydroCode.value = ''
                }
            }
        }
    }

    getStations = (filters) => {
        switch (filters.stationsMode) {
            case 'cities':
                return filters.cities ?? []
            case 'departments':
                return filters.departments ?? []
            default:
                return this.props.job.parameters.parameters
        }
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })().filter(s => !s.includes('data'))
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(dataTypes)
    }

    handleChangeHeightIntegration = integration => {
        this.changeDataTypes(integration, 'heightIntegration')
    }
    handleChangeDebitIntegration = integration => {
        this.changeDataTypes(integration, 'debitIntegration')
    }

    changeDataTypes = (integration, integrationValue) => {
        const { parameters } = this.props.job
        const dataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes(integrationValue))
            if (integration) {
                return [...data, integrationValue]
            }
            return data
        })()
        this.props.onChangeDataTypes(dataTypes)
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        return this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : { stationsMode: 'stations' }
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        const stationsCodes = this.getStations(filters).map(hydroCode => ({ hydroCode }))
        const codesTypes = [
            { code: 'stations', name: i18n.stations },
            { code: 'cities', name: i18n.cities },
            { code: 'departments', name: i18n.departments },
        ]
        return (
            <Grid2 container spacing={2} alignItems='center' sx={{ padding: '10' }}>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('station')}
                        label={i18n.integrateStationInfo}
                        onChange={this.handleChangeStationIntegration}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Select
                        value={this.getDataTypes('DATA')}
                        options={getIntegrationModes()}
                        label={i18n.dataIntegration}
                        labelSpan={integrationModeHelpIcon()}
                        onChange={this.handleChangeDataIntegration}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <RadioButtons elements={codesTypes} selected={filters.stationsMode}
                        onChange={v => this.onChangeFilters({ stationsMode: v })}
                        title={i18n.schemeAgencyID} {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('withPred')}
                        label={`${i18n.importPrevData} Vigicrues`}
                        onChange={v => this.changeDataTypes(v, 'withPred')}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <label>{i18n.addCredential}</label>
                </Grid2>
                <Grid2 size={9}>
                    <div className='input-field no-padding'>
                        <div className='col s8'>
                            <input id='txtAddHydroCode' type='text' placeholder={i18n.HYDROExample}
                                ref='txtAddHydroCode'
                            />
                            <label className='tinyLabel'>{i18n.stationHYDROCode}</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={() => this.addHydroCode(filters)}
                        >
                            {i18n.add}
                        </a>
                    </div>
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('heightIntegration')}
                        label={i18n.heightObs}
                        onChange={this.handleChangeHeightIntegration}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('debitIntegration')}
                        label={i18n.debitObs}
                        onChange={this.handleChangeDebitIntegration}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('withEvents')}
                        label={i18n.importEvents}
                        onChange={v => this.changeDataTypes(v, 'withEvents')}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={3}>
                    <Checkbox
                        checked={!!this.getDataTypes('withThresholds')}
                        label={i18n.importThresholds}
                        onChange={v => this.changeDataTypes(v, 'withThresholds')}
                        {...disabled}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <CardTable
                        headers={['hydroCode']}
                        rows={stationsCodes}
                        title={filters.stationsMode === 'stations' ? i18n.credentials : i18n[filters.stationsMode] }
                        lineActions={[
                            { icon: 'delete', onClick: this.onDeleteHydroCode, displayed: this.props.isEditMode },
                        ]}
                        actions={[{
                            icon: 'download',
                            onClick: () => exportFile({
                                data: [{ ...(stationsCodes[0]), headers: ['hydroCode'] }, ...(stationsCodes.slice(1, stationsCodes.length))],
                                titleFile: i18n.credentials,
                            }),
                            tooltip: i18n.download,
                        }]}
                        displayHeaders={false}
                    />
                </Grid2>
            </Grid2>
        )
    }
}

HydroPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addHydroCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
    onChangeJob: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps,
)(HydroPanel)
