module.exports = {
    RECEIVE_IAEAU_LEARNINGS: 'RECEIVE_IAEAU_LEARNINGS',
    RECEIVE_GENERIC_MEASURES: 'RECEIVE_GENERIC_MEASURES',
    UPDATE_GENERIC_MEASURES: 'UPDATE_GENERIC_MEASURES',
    RECEIVE_IAEAU_MODELS: 'RECEIVE_IAEAU_MODELS',
    OBJECT_TYPES: {
        GLOBAL: 1, // données globales à toute l'application
        LAND: 2, // parcelles
    },
    MEASURES_TYPES: {
        RSEau_Land_Parameters: 1, // paramètres RSEau utilisés par Optirrig, uniques à chaque parcelle
        RSEau_Chrono_Tension: 2, // input Chrono_Tension pour Optirrig
    },
    MODEL_TYPES: {
        PERCEPTRON: 'PERCEPTRON',
        AUGURES: 'AUGURES',
        ARIMA: 'ARIMA',
        TARISSEMENT: 'TARISSEMENT',
        METEO_NAPPES: 'METEO_NAPPES',
        HYPE: 'HYPE',
        TARRI_BRGM: 'TARRI_BRGM',
        SCIKIT_LEARN_GENERIC: 'SCIKIT_LEARN_GENERIC',
        CYDRE: 'CYDRE',
    },
    HORIZON_TYPE: {
        days: 'J',
        weeks: 'S',
        months: 'M',
        hours: 'H',
    },
    HORIZON_TYPE_LABEL: {
        days: 'jours',
        weeks: 'semaines',
        months: 'mois',
    },
    MODEL_PERF_CRITERIAS_CODES: {
        NASH: 'Nash',
        PERSISTANCE: 'Persistance',
        AUC: 'AUC',
        PICP: 'PICP',
        PBIAS: 'PBias',
    },
    MODEL_TYPES_LABEL: {
        PERCEPTRON: 'pmc',
        AUGURES: 'omenTrends',
        ARIMA: 'arima',
        TARISSEMENT: 'dryingUp',
        METEO_NAPPES: 'meteoNappes',
        TARRI_BRGM: 'dryingUp',
        SCIKIT_LEARN_GENERIC: 'scikit',
    },
    MODEL_OBJECTIVES: {
        CRUE: 1,
        ETIAGE: 2,
        GLOBAL: 3,
    },
    MODEL_OBJECTIVES_LABEL: {
        1: 'flood',
        2: 'lowWater',
        3: 'global',
    },
    MODEL_STATUS: {
        SUCCESS: 1,
        WARNING: 2,
        ERROR: 3,
        PROGRESS: 4,
        DEMAND_SENT: 5,
        DEMAND_PROGRESS: 6,
        WAITING: 7,
        LEARNING_PROGRESS: 8,
        LEARNING_FINISHED: 9,
    },
    MODEL_STATUS_LABEL: {
        1: 'success',
        2: 'warning',
        3: 'error',
        4: 'inProgress',
        5: 'demandSent',
        6: 'demandProgress',
        7: 'waiting',
        8: 'learningProgress',
        9: 'learningFinished',
    },
    METEO_NAPPES_SERIES_DEFAULT: ['meteeauNappes_prev_20', 'meteeauNappes_prev_50', 'meteeauNappes_prev_80'], // séries affichées par défaut lors du chargement du graphique
    HORIZON_MODES: [{ value: 'days', label: 'Jours' }, { value: 'weeks', label: 'Semaine' }, { value: 'months', label: 'Mois' }],
}