import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import Button from '../../components/forms/Button'
import Checkbox from '../../components/forms/Checkbox'
import RadioButtons from '../../components/forms/RadioButtons'
import DtoPublicPicture from '../../station/dto/DtoPublicPicture'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class PopupExportFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            additionalDataChecked: false,
            radioChoice: 0,
            radioChoiceModel: 0,
            radioChoiceIndustrial: 0,
            startDate: '',
            endDate: '',
            activeEstablishments: false,
        }
    }

    getBtnExportModel = (modelsElements) => {
        const { radioChoiceModel } = this.state
        const { exportFuncModel } = this.props
        const { name = '' } = modelsElements.find(me => me.code === radioChoiceModel) || {}
        if (radioChoiceModel === 0) {
            return this.getBtnExport()
        }
        return (
            <Row noMargin={false} className='margin-bottom-0 margin-top-3'>
                <Button
                    title='Excel'
                    onClick={() => exportFuncModel(name)}
                    className='right modal-close'
                />
                <Button title={i18n.close} className='left red modal-close' />
            </Row>
        )
    }

    getIndustrialSites = () => {
        const { startDate, endDate, activeEstablishments } = this.state
        const { industrialSiteExport } = this.props
        if (industrialSiteExport.data && industrialSiteExport.data.length) {
            const industrialSites = activeEstablishments ? industrialSiteExport.data.filter(i => !i.activityStatus || i.activityStatus != 2) : industrialSiteExport.data
            return {
                ...industrialSiteExport,
                data: industrialSites.filter(e => (!startDate || (!e.agreementStartDate || e.agreementStartDate <= startDate)) && (!endDate || (!e.agreementEndDate || e.agreementEndDate >= endDate))),
            }
        }
        return {}
    }

    getBtnExport = () => {
        const { radioChoice, additionalDataChecked, radioChoiceIndustrial } = this.state
        const { agencyOption, exportFunc } = this.props
        if (agencyOption && radioChoice === 3) {
            return (
                <Row noMargin={false} className='margin-bottom-0 margin-top-3'>
                    <Button
                        title='Xml'
                        onClick={() => { }}
                        className='right modal-close'
                    />
                    <div className='right padding-left-3' />
                    <Button title={i18n.close} className='left red modal-close' />
                </Row>
            )
        }
        return (
            <Row noMargin={false} className='margin-bottom-0 margin-top-3'>
                <Button
                    title='Excel'
                    onClick={() => exportFunc('xlsx', additionalDataChecked, radioChoice, radioChoiceIndustrial, this.getIndustrialSites())}
                    className='right modal-close margin-left-1'
                />
                <div className='right padding-left-3' />
                <Button
                    title='CSV'
                    onClick={() => exportFunc('csv', additionalDataChecked, radioChoice, radioChoiceIndustrial, this.getIndustrialSites())}
                    className='right modal-close'
                />
                <Button title={i18n.close} className='left red modal-close' />
            </Row>
        )
    }

    getModelsExport = (modelsElements) => {
        const { radioChoiceModel } = this.state
        return (
            <Row>
                <RadioButtons
                    col={10}
                    activeOneBelowOther={true}
                    className={'offset-s2'}
                    elements={modelsElements}
                    selected={radioChoiceModel}
                    onChange={(v) => this.setState({ radioChoiceModel: v })}
                />
            </Row>
        )
    }

    onResetFilters = () => this.setState({ startDate: '', endDate: '', activeEstablishments: false })

    render() {
        const { additionalDataChecked, radioChoice, radioChoiceIndustrial, startDate, endDate, activeEstablishments } = this.state
        const { useAdditionalData, agencyOption, modelsExport, industrialSiteExport } = this.props
        const elementsRadioButton = [{
            code: 0,
            name: i18n.statementsList,
        }, {
            code: 2,
            name: i18n.agencyExport,
        }, {
            code: 3,
            name: i18n.oasis,
        }]
        const modelsElements = modelsExport.map((model, i) => {
            return { name: model, code: i + 1 }
        })
        const modelsElementsWithOriginModel = [{ name: i18n.resultTable, code: 0 }, ...modelsElements]
        return (
            <div>
                <Row>
                    <h5>{i18n.chooseExportFormat}<span style={{ fontStyle: 'italic', fontSize: '18px' }}>&nbsp;({i18n.utf8Format})</span></h5>
                </Row>
                {useAdditionalData && (<Row>
                    <Checkbox
                        col={6}
                        label={i18n.exportAdditionalData}
                        checked={additionalDataChecked}
                        onChange={(v) => this.setState({ additionalDataChecked: v })}
                    />
                </Row>)}
                {modelsExport.length > 0 && this.getModelsExport(modelsElementsWithOriginModel)}
                {!!(industrialSiteExport.data && industrialSiteExport.data.length) && (
                    <Row>
                        <RadioButtons
                            col={10}
                            activeOneBelowOther={true}
                            className={'offset-s2'}
                            elements={[
                                {
                                    code: 0,
                                    name: i18n.resultsTable,
                                },
                                {
                                    code: 1,
                                    name: i18n.potentialSourcesPollution,
                                },
                            ]}
                            selected={radioChoiceIndustrial}
                            onChange={(v) => this.setState({ radioChoiceIndustrial: v })}
                        />
                        {radioChoiceIndustrial === 1 && (
                            <fieldset>
                                <legend>{i18n.criterias}</legend>
                                <Row>
                                    <SimpleDatePicker
                                        col={3}
                                        label={i18n.startDate}
                                        onChange={v => this.setState({ startDate: v })}
                                        id='startDate'
                                        value={startDate}
                                        max={endDate}
                                    />
                                    <SimpleDatePicker
                                        col={3}
                                        label={i18n.endDate}
                                        onChange={v => this.setState({ endDate: v })}
                                        id='endDate'
                                        value={endDate}
                                        min={startDate}
                                    />
                                    <Checkbox
                                        col={3}
                                        componentClassName='padding-top-10-px'
                                        checked={activeEstablishments}
                                        onChange={v => this.setState({ activeEstablishments: v })}
                                        label={i18n.activeEstablishments}
                                    />
                                    <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={this.onResetFilters}>
                                        {i18n.reinit}
                                    </a>
                                </Row>
                            </fieldset>
                        )}
                    </Row>
                )}
                {agencyOption && (<Row>
                    <RadioButtons
                        col={7}
                        activeOneBelowOther={true}
                        className={'offset-s2'}
                        elements={elementsRadioButton}
                        selected={radioChoice}
                        onChange={(v) => this.setState({ radioChoice: v })}
                    />
                </Row>)}
                {modelsExport.length > 0 ? this.getBtnExportModel(modelsElementsWithOriginModel) : this.getBtnExport()}
            </div>
        )
    }
}

PopupExportFile.propTypes = {
    useAdditionalData: PropTypes.bool,
    agencyOption: PropTypes.bool,
    exportFunc: PropTypes.func,
    modelsExport: PropTypes.arrayOf(PropTypes.instanceOf(DtoPublicPicture)),
    exportFuncModel: PropTypes.func,
    industrialSiteExport: PropTypes.shape({
        data: PropTypes.array,
        exportType: PropTypes.string,
        titleFile: PropTypes.string,
    }),
}

PopupExportFile.defaultProps = {
    useAdditionalData: false,
    agencyOption: false,
    exportFuncModel: () => { },
}

export default PopupExportFile