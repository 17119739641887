/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoTankStation from '../dto/DtoTankStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'

const TankCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const modeList = useSandreList(SANDRE.INSTALLATIONS_MODEREMPLISSAGE)
    const typeList = useSandreList(SANDRE.INSTALLATIONS_TYPEREMPLISSAGE)

    const onChangeCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: tank.idStation,
                    ...tank.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const civilEng = tank.link_civilEngs[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <Select
                    value={civilEng.fillingMode}
                    label={i18n.fillingMode}
                    keyValue='code'
                    options={modeList}
                    onChange={(v) => {
                        onChangeCivilEng({ fillingMode: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MODEREMPLISSAGE, i18n.fillingMode, civilEng.fillingMode, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.fillingType}
                    label={i18n.fillingType}
                    keyValue='code'
                    options={typeList}
                    onChange={(v) => {
                        onChangeCivilEng({ fillingType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEREMPLISSAGE, i18n.fillingType, civilEng.fillingType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

TankCivilEngPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default TankCivilEngPanel
