import {
    Accordion,
    AccordionDetails,
    Box,
    Grid2,
} from '@mui/material'
import { AccordionTitle } from 'components/styled/Accordions'
import { groupBy, keys, maxBy, minBy, orderBy, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getTextColorByBackground, getThresholdColorCode, getVigilanceColor } from 'utils/ColorUtil'
import { execByType } from 'utils/StationUtils'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { hasValue } from 'utils/NumberUtil'
import { getSetting, getSettingJson } from 'utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import VigilanceCard from 'home/components/vigilance/VigilanceCard'
import VigilanceModal from 'home/components/vigilance/VigilanceModal'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CardMapStation from 'components/map/popup/CardMapStation'
import { THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'
import { LARGE_RADIUS } from 'utils/constants/Theme'
import { MONITORING_OPTIONS } from 'alerting/constants/AlertConstants'
import AlertFilter from './AlertFilter'

const ALERT_STATIONS_DISPLAYED = 'ALERT_STATIONS_DISPLAYED'

const AlertMapTabs = ({
    sites = [],
    onClick = () => { },
    onChange = () => { },
    displayPiezo = true,
    displayPluvio = true,
    displayHydro = true,
    displayInst = false,
    selectedDate,
    hiddenNoThresholdStations,
    searchValue = '',
    onHover = () => { },
    dataTypePiezo = {},
    dataTypeHydro = {},
    dataTypePluvio = {},
    stationsIdByTypeName = {},
    selectedStationType = {},
    monitoringType = MONITORING_OPTIONS.ALL_MONITORING,
    reloadSituation = {},
}) => {
    const {
        accountUser,
        vigilances,
        applicationSettings,
        installationsLight,
        hydrometricStations,
        piezometryDataTypes,
        pluviometryDataTypes,
        hydrometryDataTypes,
        cities,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        vigilances: store.HomeReducer.vigilances,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [vigilance, setVigilance] = useState({})
    const [stationSubType, setStationSubType] = useState(undefined)
    const [isOpenFilter, setIsOpenFilter] = useState(false)

    const dispatch = useDispatch()

    const showPiezo = !!stationsIdByTypeName[STATION_TYPE_NAME.piezometry]?.length
    const showHydro = !!stationsIdByTypeName[STATION_TYPE_NAME.hydrometry]?.length
    const showPluvio = !!stationsIdByTypeName[STATION_TYPE_NAME.pluviometry]?.length
    const showInst = !!stationsIdByTypeName[STATION_TYPE_NAME.installation]?.length

    const installationsTypes = uniq([ -1, ...installationsLight.filter(i => !!i.installationType && (stationsIdByTypeName[STATION_TYPE_NAME.installation] || []).includes(i.id)).map(i => i.installationType) ])
    const hydrometersTypes = uniq([ -1, ...hydrometricStations.filter(i => !!i.stationType && (stationsIdByTypeName[STATION_TYPE_NAME.hydrometry] || []).includes(i.id)).map(i => i.stationType) ])

    const defaultDepartment = useMemo(() => {
        const contribSettings = hasValue(accountUser.contributorCode) && getSettingJson(applicationSettings, linkContributorsDepartments)?.find(s => s.contributor === accountUser.contributorCode)
        return contribSettings?.departments || getSetting(applicationSettings, 'meteoAlertDepartments')
    }, [accountUser.contributorCode, applicationSettings])

    useEffect(() => {
        if (hasValue(defaultDepartment) && defaultDepartment.length >= 2) {
            dispatch(HomeAction.fetchVigilances(defaultDepartment))
        }
    }, [defaultDepartment, dispatch])

    useEffect(() => {
        if (keys(vigilance).length) {
            setOpen(true)
        }
    }, [vigilance])

    useEffect(() => {
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [])

    const toControlKey = `${i18n.toControl}#-#${THRESHOLD_COLORS_CODES.LIGHT_GREY}`
    const noThresholdsKey = `${i18n.noThresholds}#-#${THRESHOLD_COLORS_CODES.LIGHT_BLUE}`
    const unknownThresholdKey = `${i18n.unknownThreshold}#-#${THRESHOLD_COLORS_CODES.ORANGE}`
    const monitoringKey = `${i18n.monitoring}#-#${THRESHOLD_COLORS_CODES.GREEN}`

    const orderedThresholds = [i18n.noThresholds, i18n.unknownThreshold, i18n.toControl, i18n.monitoring]

    const thresholdGroup = sites.reduce((acc, s) => {
        s.thresholds.forEach(t => {
            const labelThreshold = t.name || t.title || ''
            if (labelThreshold && !acc[`${labelThreshold}#-#${t.htmlColor || getThresholdColorCode(t.color)}`]) {
                acc[`${labelThreshold}#-#${t.htmlColor || getThresholdColorCode(t.color)}`] = []
            }
        })
        return acc
    }, { [toControlKey]: [], [monitoringKey]: [] })

    const groupCardSites = orderBy(sites, 'bookmarked', 'asc').reduce((acc, s) => {
        const dataType = s.dataType || execByType(s.typeName, {
            piezometry: () => dataTypePiezo,
            hydrometry: () => dataTypeHydro,
            pluviometry: () => dataTypePluvio,
            installation: () => ({ ...s?.thresholds[0], alertLimit: 72 }),
            default: () => {},
        })
        const {
            alertLimit = 120,
        } = dataType
        const dateNow = moment()
        const dateBeforeNbHours = moment(dateNow.subtract(alertLimit, 'hour'))
        const site = (() => {
            switch (monitoringType) {
                case MONITORING_OPTIONS.SITUATION_FLOOD:
                    return { ...s, thresholds: s.thresholds.filter(t => t.isOverrunThreshold === '1') }
                case MONITORING_OPTIONS.LOWWATER:
                    return { ...s, thresholds: s.thresholds.filter(t => t.isOverrunThreshold === '0') }
                case MONITORING_OPTIONS.ALL_MONITORING:
                default :
                    return s
            }
        })()
        const level = (() => {
            if (site.typeName === STATION_TYPE_NAME.installation && dataType.id) {
                return dataType.id
            }
            if ((site.measureDate && site.measureDate.isBefore(dateBeforeNbHours)) || !site.lastMeasure.length) {
                return -1
            }
            if (!site.thresholds.length) {
                return 0
            }
            return s.trend
        })()
        const content = {
            content: (
                <CardMapStation
                    site={site}
                    onClick={onClick}
                    onHover={onHover}
                />
            ),
            color: site.color,
            level,
        }
        if (site.typeName === STATION_TYPE_NAME.installation && site.thresholds?.length > 0) {
            const threshold = site.thresholds[0] || {}
            const thresholdName = `${threshold.name}#-#${threshold.color || THRESHOLD_COLORS_CODES.LIGHT_GREY}`
            if (!acc[thresholdName]) {
                acc[thresholdName] = []
            }
            acc[thresholdName].push(content)
        } else if ((site.measureDate && site.measureDate.isBefore(dateBeforeNbHours)) || !site.lastMeasure.length) {
            if (!acc[toControlKey]) {
                acc[toControlKey] = []
            }
            acc[toControlKey].push(content)
        } else if (!site.thresholds.length) {
            if (!acc[noThresholdsKey]) {
                acc[noThresholdsKey] = []
            }
            acc[noThresholdsKey].push(content)
        } else if (site.thresholds.filter(t => !t.name && !t.title).length) {
            if (!acc[unknownThresholdKey]) {
                acc[unknownThresholdKey] = []
            }
            acc[unknownThresholdKey].push(content)
        } else if (site.trendThreshold.length) {
            site.trendThreshold.forEach(t => {
                if (t.name || t.title) {
                    acc[`${t.name || t.title || ''}#-#${t.htmlColor || getThresholdColorCode(t.color)}`].push(content)
                } else {
                    if (!acc[unknownThresholdKey]) {
                        acc[unknownThresholdKey] = []
                    }
                    acc[unknownThresholdKey].push(content)
                }
            })
        } else {
            if (!acc[monitoringKey]) {
                acc[monitoringKey] = []
            }
            acc[monitoringKey].push(content)
        }
        return acc
    }, thresholdGroup)

    const getThresholdOrder = (name, level, minLevel) => {
        if (orderedThresholds.some(t => t === name)) {
            return (orderedThresholds.findIndex(t => t === name) + minLevel) - orderedThresholds.length
        }
        return level
    }

    const cardList = orderBy(keys(groupCardSites).map(key => {
        const {
            [key]: cards,
        } = groupCardSites
        const maxAlert = maxBy(cards, 'level')
        const minAlert = minBy(cards, 'level')?.level || 0
        const [name, color] = key.split('#-#')
        const cardsLength = cards.length
        const level = hasValue(maxAlert?.level) ? maxAlert.level : -2
        return {
            title: `${name} (${cardsLength})`,
            titleColor: cardsLength ? color : THRESHOLD_COLORS_CODES.LIGHT_GREY,
            cards: orderBy(cards, 'level', 'desc'),
            level,
            order: getThresholdOrder(name, level, minAlert),
        }
    }), 'order', 'desc')

    const groupedVigilances = groupBy(vigilances, 'typeId')

    const handleChangeDisplay = (object) => {
        const settingValue = JSON.stringify({
            [STATION_TYPE_NAME.piezometry]: object.displayPiezo ?? displayPiezo,
            [STATION_TYPE_NAME.hydrometry]: object.displayHydro ?? displayHydro,
            [STATION_TYPE_NAME.pluviometry]: object.displayPluvio ?? displayPluvio,
            [STATION_TYPE_NAME.installation]: object.displayInst ?? displayInst,
        })
        dispatch(AdministrationAction.updateSetting(ALERT_STATIONS_DISPLAYED, settingValue))
        onChange(object)
    }

    return (
        <Box>
            <Box sx={{ padding: '0.25rem 0.75rem 0.75rem', backgroundColor: '#DDD', borderRadius: LARGE_RADIUS }}>
                <AlertFilter
                    showType={{ showPluvio, showPiezo, showInst, showHydro }}
                    displayType={{ displayPluvio, displayPiezo, displayInst, displayHydro }}
                    selectedDate={selectedDate}
                    hiddenNoThresholdStations={hiddenNoThresholdStations}
                    monitoringType={monitoringType}
                    stationSubType={stationSubType}
                    setStationSubType={setStationSubType}
                    selectedStationType={selectedStationType}
                    hydrometersTypes={hydrometersTypes}
                    installationsTypes={installationsTypes}
                    onChange={onChange}
                    searchValue={searchValue}
                    isOpenFilter={isOpenFilter}
                    setIsOpenFilter={setIsOpenFilter}
                    reloadSituation={reloadSituation}
                />
            </Box>
            {!!vigilances.length && (
                <StyledFieldSet>
                    <StyledLegend>{i18n.weatherWarnings}</StyledLegend>
                    {keys(groupedVigilances).map((key, i) => {
                        const bgColor = getVigilanceColor(groupedVigilances[key][0]?.vigilance)
                        const color = getTextColorByBackground(bgColor)
                        return (
                            <Accordion
                                key={key}
                                // className={`${groupedVigilances[key][0]?.vigilance ? bgColor : color} margin-bottom-1`}
                                className='margin-bottom-1'
                                defaultExpanded={i === 0}
                                round
                            >
                                {/* <AccordionSummary
                                    className='smallSubListTitle'
                                    expandIcon={<i className='material-icons' style={{ color }}>expand_more</i>}
                                    style={{ color, minHeight: 40, height: 40, borderRadius: '5px' }}
                                    round
                                >
                                    {groupedVigilances[key][0]?.info || i18n.unknown}
                                </AccordionSummary> */}
                                <AccordionTitle
                                    title={groupedVigilances[key][0]?.info || i18n.unknown}
                                    color={bgColor}
                                    fontColor={color}
                                />
                                <AccordionDetails nopadding>
                                    {groupedVigilances[key]?.map((vg, j) => (
                                        <a key={`${key}_${j}`} onClick={() => setVigilance(vg)} target='_blank' style={{ cursor: 'pointer', width: '100%' }}>
                                            <VigilanceCard vigilance={vg} smallCard />
                                        </a>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    <VigilanceModal
                        open={open}
                        vigilance={vigilance}
                        closePopin={() => {
                            setOpen(false)
                            setVigilance({})
                        }}
                    />
                </StyledFieldSet>
            )}
            <StyledFieldSet>
                <StyledLegend>{i18n.situationByThresholds}</StyledLegend>
                {cardList.filter(cd => cd.cards.length).map((list, i) => {
                    const color = getTextColorByBackground(list.titleColor)
                    return (
                        <Accordion
                            key={list.level}
                            defaultExpanded={i === 0}
                            round
                            sx={{
                                marginBottom: (i < (cardList.filter(cd => cd.cards.length).length - 1)) && '1rem',
                                marginTop: i === 0 && '1rem',
                            }}
                        >
                            {/* <AccordionSummary
                                className='smallSubListTitle'
                                expandIcon={<i className='material-icons' style={{ color }}>expand_more</i>}
                                style={{ backgroundColor: list.titleColor, color, minHeight: 40, height: 40, borderRadius: '5px' }}
                                round
                            >
                                {list.title}
                            </AccordionSummary> */}
                            <AccordionTitle
                                title={list.title}
                                color={list.titleColor}
                                fontColor={color}
                            />
                            <AccordionDetails sx={{ padding: '1rem' }}>
                                {list.cards.map((card, iC) => (
                                    <Grid2 container key={iC} sx={{ paddingBottom: (iC < (list.cards.length - 1)) && '1rem' }}>
                                        {card.content}
                                    </Grid2>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </StyledFieldSet>
        </Box>
    )
}

AlertMapTabs.propTypes = {
    sites: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onChange: PropTypes.func,
    displayPiezo: PropTypes.bool,
    displayPluvio: PropTypes.bool,
    displayHydro: PropTypes.bool,
    displayInst: PropTypes.bool,
    selectedDate: PropTypes.number,
    hiddenNoThresholdStations: PropTypes.bool,
    searchValue: PropTypes.string,
    dataTypePiezo: PropTypes.shape({}),
    dataTypeHydro: PropTypes.shape({}),
    dataTypePluvio: PropTypes.shape({}),
    stationsIdByTypeName: PropTypes.shape({
        [STATION_TYPE_NAME.piezometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.hydrometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.pluviometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.installation]: PropTypes.arrayOf(PropTypes.number),
    }),
    selectedStationType: PropTypes.shape({}),
    monitoringType: PropTypes.number,
    reloadSituation: PropTypes.func,
}

export default AlertMapTabs
