import { Card, CardContent, Grid2 } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import i18n from 'simple-react-i18n'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OperationAction from '../operation/actions/OperationAction'
import { countBy, groupBy, isUndefined, minBy, orderBy, range, round, uniqBy } from 'lodash'
import { getColorCircleElement, getColorFromPalette, getRGBColor } from 'utils/ColorUtil'
import { lightGrey } from 'utils/constants/ColorTheme'
import { calculateThresholdResult, getQualificationColor, getStatusColor } from 'utils/AnalyseUtils'
import { getBeginingOfTheYear, getDate, getEndOfTheYear, getYear } from 'utils/DateUtil'
import moment from 'moment'
import { push } from '@lagunovsky/redux-react-router'
import SmallPie from 'components/echart/SmallPie'
import useFetch from 'utils/customHook/useFetch'
import ApplicationConf from 'conf/ApplicationConf'
import Icon from 'components/icon/Icon'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { SimpleFilterField, SimpleFilterFunction } from 'components/datatable/SimpleSelectionTable'
import useBoolean from 'utils/customHook/useBoolean'
import { searchAllCharacters } from 'utils/StringUtil'
import { IndiceRadarGraph } from '../qualityComponents/IndiceGraph'
import { CardTitle } from 'components/card/NewCard'
import { DEFAULT_INDEXES, LIST_INDEXES } from 'quality/constants/HydrobioConstant'
import useListIndexed from 'utils/customHook/useListIndexed'
import QualityAction from 'quality/actions/QualityAction'
import { THRESHOLD } from 'quality/constants/QualityConstants'
import SliderPanel from 'components/slider/SliderPanel'

const HydrobioIndices = () => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        qualityIndicators,
        qualityThresholds,
        hydrobioOperations,
        parameters,
        units,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualityIndicators: store.QualityReducer.qualityIndicators,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'id')

    const opts = useMemo(() => {
        return {
            method: 'POST',
            body: {
                stations: [qualitometer.id],
                parameters: LIST_INDEXES,
            },
        }
    }, [qualitometer.id])

    const {
        data: indices = [],
    } = useFetch(ApplicationConf.qualitometer.lastYearIndices(), opts)

    const filteredIndicators = useMemo(() => {
        const foundIndicators = qualityIndicators.find(q => q.stationType == qualitometer.stationType)?.indicators || []
        return foundIndicators.filter(i => indexedParameters[i.id]?.typeParam === '3')
    }, [indexedParameters, qualitometer.stationType, qualityIndicators])

    useEffect(() => {
        const unloadThresholds = uniqBy(filteredIndicators, 'threshold').map(i => `${i.threshold}`).filter(code => !qualityThresholds.some(t => t.thresholdCode === code))
        unloadThresholds.forEach(code => dispatch(QualityAction.fetchQualityThresholds([{ code, thresholdType: THRESHOLD.INDICE }])))
    }, [dispatch, filteredIndicators])

    const groupParam = groupBy(indices, 'parameter')

    const datas = orderBy(filteredIndicators.map((indicator) => {
        const paramCode = `${indicator.id}`
        const thresholdCode = `${indicator.threshold}`
        const thresholds = qualityThresholds.find(qt => qt.thresholdCode === thresholdCode && qt.thresholdType === THRESHOLD.INDICE)?.thresholds || []
        const index = (groupParam[paramCode] || []).filter(a => !isUndefined(a.result))[0]
        const lastAnalysis = {
            ...index,
            parameter: paramCode,
            sampleDate: hydrobioOperations.find(o => o.id === index?.operationId)?.date,
        }

        if (isUndefined(lastAnalysis?.result)) {
            return {
                title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: i18n.noResults,
                parameterCode: paramCode,
            }
        }

        const formattedAnalysis = { ...lastAnalysis, ...calculateThresholdResult(lastAnalysis, thresholds) }

        if (formattedAnalysis?.threshold) {
            return {
                title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
                threshold: formattedAnalysis.threshold,
                analysis: formattedAnalysis,
                parameterCode: paramCode,
            }
        }

        return {
            title: indexedParameters[paramCode]?.name ?? `<${paramCode}>`,
            subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
            parameterCode: paramCode,
        }
    }), ['indices'], ['asc'])

    return (
        <Grid2 container spacing={1}>
            {
                datas.map((data, i) => {
                    return (
                        <Grid2 key={data.parameterCode} size={12}>
                            <SliderPanel
                                key={i}
                                title={data.title}
                                subtitle={data.subtitle}
                                analysis={data.analysis}
                                threshold={data.threshold}
                            />
                        </Grid2>
                    )
                })
            }
            {
                !datas.length && (
                    <Grid2 container size={12} sx={{ fontSize: 14, color: 'black' }} justifyContent='center' alignItems='center' direction='column' spacing={0}>
                        <Grid2 size='auto'>
                            <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                        </Grid2>
                        <Grid2 size='auto'>
                            {i18n.noResults}
                        </Grid2>
                    </Grid2>
                )
            }
        </Grid2>
    )
}

const OperationsGraph = ({

}) => {
    const dispatch = useDispatch()
    const {
        hydrobioOperations,
        qualitometer,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const years = range(getYear(minBy(hydrobioOperations, 'date')?.date), moment().year() + 1, 1)

    const numberByYear = countBy(hydrobioOperations, o => getYear(o.date))
    const data = years.map(year => numberByYear[year] || 0)

    const associatedStations = []
    const options = {
        series: [{
            type: 'bar',
            name: `${qualitometer.code || ''} ${i18n.operations}`,
            color: getColorFromPalette(0),
            data,
        },
        ...associatedStations,
        ],
        xAxis: [{
            type: 'category',
            data: years,
        }],
        yAxis: [{
            type: 'value',
            minInterval: 1,
            showSplitLine: true,
        }],
        grid: {
            top: '15%',
            left: '2%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
            height: 150,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                animation: false,
                label: {
                    backgroundColor: '#505765',
                },
            },
        },
    }

    return (
        <>
            <h6 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{i18n.operationsHistory}</h6>
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 230 }}
                onEvents={{
                    click: ({ name: year }) => {
                        const startDate = getBeginingOfTheYear(year)
                        const endDate = getEndOfTheYear(year)
                        dispatch(push(`/station/quality/${qualitometer.id}/hydrobioMonitoring?startDate=${startDate}&endDate=${endDate}`))
                    },
                }}
            />
        </>
    )
}

const SituationHydrobioPanel = ({

}) => {
    const dispatch = useDispatch()
    const {
        hydrobioOperations,
        qualitometer,
        qualifications,
        statusProps,
        accountUser,
        parameters,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        qualitometer: store.QualityReducer.qualitometer,
        qualifications: store.QualityReducer.qualifications,
        statusProps: store.QualityReducer.status,
        accountUser: store.AccountReducer.accountUser,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const selectedIndexes = useApplicationSetting('SELECTED_INDEXES', listStringParser)

    const {
        value: isAdminOpen,
        setTrue: openAdmin,
        setFalse: closeAdmin,
    } = useBoolean(false)


    const indexes = useMemo(() => {
        const filteredIndexes = parameters.filter(p => LIST_INDEXES.includes(p.code))
        return filteredIndexes.map(i => ({
            id: i.code,
            code: i.code,
            name: i.name,
            labelSearch: searchAllCharacters(`${i.name}#${i.code}`),
        }))
    }, [parameters])

    const opts = useMemo(() => {
        return {
            method: 'POST',
            body: {
                stations: [qualitometer.id],
                parameters: selectedIndexes.length ? selectedIndexes : DEFAULT_INDEXES,
            },
        }
    }, [qualitometer.id, selectedIndexes])

    const {
        data: indices = [],
    } = useFetch(ApplicationConf.qualitometer.lastYearIndices(), opts)

    useEffect(() => {
        if (!hydrobioOperations.length) {
            dispatch(OperationAction.fetchQualitometerHydrobioOperations(qualitometer.id))
        }
    }, [dispatch, hydrobioOperations.length, qualitometer.id])

    const countQualification = countBy(hydrobioOperations, 'qualification')
    const dataQualifications = Object.keys(countQualification).map(key => {
        const idQualification = parseInt(key)
        const qualification = qualifications.find(q => q.code === idQualification)
        const name = qualification?.name || i18n.notDefined

        const percentage = hydrobioOperations.length ? `${round(countQualification[key] * 100 / hydrobioOperations.length)}%` : '0%'

        const color = qualification ? getRGBColor(getQualificationColor(key)) : lightGrey

        return {
            value: countQualification[key],
            name,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.qualification} (${percentage})</br>
                        ${getColorCircleElement(params.color)}${name}: ${params.value} ${i18n.of} ${hydrobioOperations.length} ${i18n.operations}`
                },
            },
        }
    })

    const countStatus = countBy(hydrobioOperations, 'status')
    const dataStatus = Object.keys(countStatus).map(key => {
        const idStatus = parseInt(key)
        const status = statusProps.find(q => q.code === idStatus)
        const name = status?.name || i18n.notDefined

        const percentage = hydrobioOperations.length ? `${round(countStatus[key] * 100 / hydrobioOperations.length)}%` : '0%'

        const color = status ? getRGBColor(getStatusColor(key)) : lightGrey

        return {
            value: countStatus[key],
            name,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.status} (${percentage})</br>
                        ${getColorCircleElement(params.color)}${name}: ${params.value} ${i18n.of} ${hydrobioOperations.length} ${i18n.operations}`
                },
            },
        }
    })

    const actions = accountUser.isAdmin === '1' ? [{ onClick: openAdmin, icon: 'settings', color: 'black' }] : []

    return !!hydrobioOperations.length && (
        <Card>
            <CardTitle
                title={i18n.hydrobioSituation}
                actions={actions}
            />
            <CardContent>
                <Grid2 container>
                    <Grid2 size={4}>
                        <HydrobioIndices />
                    </Grid2>
                    <Grid2 size={6}>
                        {indices.length !== 0 && <IndiceRadarGraph indices={indices} />}
                        {indices.length === 0 && (
                            <Grid2 className='text-align-center' style={{ fontSize: 14.3, color: 'black', verticalAlign: 'middle' }}>
                                <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                                <Grid2>{i18n.noResults}</Grid2>
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 size={2}>
                        <SmallPie
                            name={i18n.qualification}
                            data={dataQualifications}
                        />
                        <SmallPie
                            name={i18n.status}
                            data={dataStatus}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <OperationsGraph />
                    </Grid2>
                </Grid2>
                <SelectionTableModal
                    isOpen={isAdminOpen}
                    onClose={closeAdmin}
                    onValidate={list => {
                        const newApplicationSetting = [
                            { parameter: 'SELECTED_INDEXES', value: list.join(',') },
                        ]
                        dispatch(AdministrationAction.updateSieauParameters(newApplicationSetting)).finally(closeAdmin)
                    }}
                    title={i18n.selectIndexesToAdd}

                    listData={indexes}
                    defaultSelectionList={selectedIndexes}

                    listHeaders={['code', 'name']}
                    listTitle={i18n.nonSelectedIndexes}
                    selectionListTitle={i18n.selectedIndexes}
                    maxHeightTable={'53vh'}

                    filterField={SimpleFilterField}
                    filterFunction={SimpleFilterFunction}
                />
            </CardContent>
        </Card>
    )
}

export default SituationHydrobioPanel