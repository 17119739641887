import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { getCampaignIcon, getCampaignProgress } from 'campaign/utils/CampaignUtils'
import ColorfulCard from 'components/card/ColorfulCard'
import { PATH_PGSSE, PATH_REFERENCIAL_CONTACT_LINK } from 'home/constants/RouteConstants'
import DtoPgsseCampaignWithStats from 'pgsse/dto/DtoPgsseCampaignWithStats'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getColorCampaign } from 'utils/CampaignUtil'
import { getDate } from 'utils/DateUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { getSandreLabel } from 'utils/StringUtil'

const PgsseCampaignCard = ({
    campaign = {},
    contact = {},
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)
    const dispatch = useDispatch()
    const {
        idDiag,
        idCampaign,
        name,
        status,
        startDate,
        endDate,
        nbValide,
        nbInst,
    } = campaign
    const progressValue = getCampaignProgress(nbValide, nbInst)
    return (
        <ColorfulCard color={getColorCampaign(status === 4, progressValue, progressValue)} >
            <div className={`row no-margin valign-wrapper clickable ${status === 1 ? 'grey lighten-2' : ''}`} style={{ height: '60px' }}>
                <div className='col s11 valign-wrapper' onClick={() => dispatch(push(`${PATH_PGSSE}/phase1/${idDiag}/campaign/${idCampaign}`))}>
                    <div className='col s1 valign-wrapper' style={{ placeContent: 'center' }}>
                        {getCampaignIcon(null, 'medium')}
                    </div>
                    <div className='col s5'>
                        <p className='padding-top-1 collection-title' style={{ fontWeight: 'bold' }}>{name}</p>
                        {
                            status === 2 && (
                                <div className='padding-top-1 padding-bottom-1 collection-content'>{`${i18n.nbInstallationsValidated} : ${nbValide || 0} /${nbInst}`}</div>
                            )
                        }
                    </div>
                    <div className='col s3'>
                        <div className='padding-top-1 padding-bottom-1 collection-content'>{getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_STATUT, status) ?? i18n.unknowStatus}</div>
                    </div>
                    {
                        status === 1 && (
                            <div className='col s3 center-align'>
                                <i className='material-icons medium'>create</i>
                            </div>
                        )
                    }
                    {
                        status !== 1 && (
                            <div className='col s3'>
                                <p>{getDate(startDate)} - {getDate(endDate)}</p>
                            </div>
                        )
                    }
                </div>
                <div className='col s1'>
                    <div className='clickable event-img-max-size center-align'
                        {...sieauTooltip(contact.name || i18n.unknown, null, 'bottom')}
                        onClick={() => contact.code && dispatch(push(`${PATH_REFERENCIAL_CONTACT_LINK}${contact.code}`))}
                    >
                        <i className={'material-icons medium'}>account_circle</i>
                    </div>
                </div>
            </div>
        </ColorfulCard>
    )
}

PgsseCampaignCard.propTypes = {
    campaign: PropTypes.arrayOf(PropTypes.instanceOf(DtoPgsseCampaignWithStats)),
    contact: PropTypes.instanceOf(ContactDto),
    onClickLink: PropTypes.func,
}

export default PgsseCampaignCard
