import React from 'react'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import PropTypes from 'prop-types'
import DtoPluviometerStats from '../../dto/DtoHydroStats'
import { orderBy } from 'lodash'
import { Card, CardContent, Grid2 } from '@mui/material'
import RadioButtons from '../../../components/forms/RadioButtons'
import { ButtonMUI } from '../../../components/styled/Buttons'
import { AVERAGE, HISTORIC, MAX, MIN, SUM, YEAR } from '../../../utils/constants/MeasureConstants'

const PluvioSuiviCriterias = ({
    reloadMeasures,
    pluviometerStatistics,
    filter,
    setFilter,
    years,
}) => {
    const currentDataType = filter.dataType ?? (pluviometerStatistics.some(h => h.typeId === 1) ? 1 : (pluviometerStatistics[0]?.typeId || 1))
    const groupModes = [
        { code: MAX, name: i18n.max },
        { code: MIN, name: i18n.min },
        { code: AVERAGE, name: i18n.average },
        { code: SUM, name: i18n.sum },
    ]

    return (
        <Card>
            <CardContent>
                <Grid2 container size={12} alignItems={'center'} spacing={1}>
                    <Grid2 size={2}>
                        <Select
                            options={ orderBy(pluviometerStatistics, ['order', 'typeId'], 'asc').map(h => ({ ...h, code: h.typeId, name: h.label })) }
                            label={ i18n.chronicType }
                            onChange={ v => setFilter(prev => ({ ...prev, dataType: v })) }
                            value={ currentDataType }
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={ groupModes }
                            label={ i18n.dailyRegroup }
                            onChange={ v => setFilter(prev => ({ ...prev, group: v })) }
                            value={ filter.group }
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={ getStatusSelectOptions() }
                            label={ i18n.status }
                            nullLabel='&nbsp;'
                            onChange={ v => setFilter(prev => ({ ...prev, status: v })) }
                            value={ filter.status }
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={ getQualificationSelectOptions() }
                            label={ i18n.qualification }
                            nullLabel='&nbsp;'
                            onChange={ v => setFilter(prev => ({ ...prev, qualifications: v })) }
                            value={ filter.qualification }
                        />
                    </Grid2>
                    <Grid2 size={1.5}>
                        <RadioButtons
                            colOption={ 12 }
                            selected={filter.horizon}
                            title={i18n.horizon}
                            onChange={(v) => setFilter(prev => ({ ...prev, horizon: v }))}
                            elements={[
                                { code: YEAR, name: i18n.perYear },
                                { code: HISTORIC, name: i18n.historic },
                            ]}
                        />
                    </Grid2>
                    <Grid2 size={1}>
                        { filter.horizon === YEAR && (
                            <Select
                                options={ years }
                                onChange={ v => setFilter(prev => ({ ...prev, year: v })) }
                                label={ i18n.year }
                                value={ filter.year }
                                noSort
                                noNullValue
                                data-cy={'select-year'}
                            />
                        )}
                    </Grid2>
                    <ButtonMUI
                        onClick={() => reloadMeasures(filter)}
                        sx={{ marginLeft: 'auto' }}
                        variant='contained'
                        data-cy={'search'}
                    >
                        {i18n.search}
                    </ButtonMUI>
                </Grid2>
            </CardContent>
        </Card>
    )
}

PluvioSuiviCriterias.propTypes = {
    reloadMeasures: PropTypes.func,
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    years: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.number,
    })),
    filter: PropTypes.shape({
        year: PropTypes.number,
        status: PropTypes.string,
        qualification: PropTypes.string,
        horizon: PropTypes.string,
        group: PropTypes.string,
        dataType: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

export default PluvioSuiviCriterias
