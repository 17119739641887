import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { max, minBy, orderBy, range } from 'lodash'
import { getYear } from '../../../utils/DateUtil'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import { i18nize } from '../../../utils/StringUtil'
import { getValidPiezometrySeriesParams } from '../../../utils/PiezometryUtils'
import PiezometerSuiviTable from './PiezometerSuiviTable'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_FOLLOW_UP } from '../../../account/constants/AccessRulesConstants'
import { hasValue } from '../../../utils/NumberUtil'
import { getStationTitle } from '../../../utils/StationUtils'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import { push } from '@lagunovsky/redux-react-router'
import { MEASURE_COTE, MEASURE_COTES } from '../../constants/PiezometryConstants'
import useTitle from 'utils/customHook/useTitle'
import {
    Alert, Card,
    CardContent,
    Grid2,
} from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { ButtonMUI } from 'components/styled/Buttons'
import RadioButtons from '../../../components/forms/RadioButtons'
import DtoPiezometer from '../../dto/DtoPiezometer'
import DtoPiezometerAdditionalMeasures from '../../../station/dto/piezometer/DtoPiezometerAdditionalMeasures'
import DtoMeasureStats from '../../../station/dto/piezometer/DtoMeasureStats'
import {
    AUTO,
    CHRONIC_TYPE,
    DATE,
    DISPLAY_COTE, HISTORIC, HORIZON,
    HOUR,
    MAX,
    QUALIFICATION,
    YEAR,
} from '../../../utils/constants/MeasureConstants'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import MessageCard from '../../../components/card/MessageCard'

const DEFAULT_CHRONIC_TYPE = 'measures'
const CHRONIC_DATA_TYPE = -1

const YearsSelectComponent = ({
    tmpFilter,
    measures,
    onChangeFilter = () => {},
}) => {
    const minMeasure = minBy(measures, 'date')
    const minYear = !!minMeasure?.date && getYear(minMeasure.date)
    const years = !!minYear && range(parseInt(minYear), moment().year() + 1).map(y => ({ code: y, name: y })).reverse()

    return (tmpFilter.horizon === YEAR && !minMeasure) ? (
        <Grid2 size={12}>
            <Select
                label={i18n.year}
                disabled
            />
            <Alert severity='warning' sx={{ fontSize: '13px', '& .MuiAlert-message': { padding: '10px 0 8px' } }}>
                {i18n.nothingToDoOrProcess}
            </Alert>
        </Grid2>
    ) : (tmpFilter.horizon === YEAR) ? (
        <Grid2 size={12}>
            <Select
                options={years}
                noSort
                onChange={e => onChangeFilter(YEAR, e)}
                label={i18n.year}
                value={tmpFilter.year}
                data-cy={'select-year'}
            />
        </Grid2>
    ) : (
        <Grid2 size={12}>
            <Select
                label={i18n.year}
                disabled
            />
        </Grid2>
    )
}

YearsSelectComponent.propTypes = {
    tmpFilter: PropTypes.shape({
        horizon: PropTypes.string,
        state: PropTypes.number,
        qualification: PropTypes.number,
        year: PropTypes.number,
        chronicType: PropTypes.string,
        displayCote: PropTypes.number,
    }),
    measures: PropTypes.arrayOf(PropTypes.shape({})),
    onChangeFilter: PropTypes.func,
}

const PiezometerSuiviTableApp = ({}) => {
    const {
        piezometer,
        piezometerAdditionalMeasures,
        piezometerStatistics,
    } = useSelector(store => ({
        piezometer: store.StationReducer.piezometer,
        piezometerAdditionalMeasures: store.PiezometerStationReducer.piezometerAdditionalMeasures,
        piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    }), shallowEqual)

    const defaultFilter = {
        horizon: YEAR,
        state: null,
        qualification: null,
        year: moment().year(),
        chronicType: DEFAULT_CHRONIC_TYPE,
        displayCote: MEASURE_COTE.NGF,
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter)
    const [measures, setMeasures] = useState([])

    const dispatch = useDispatch()

    const {
        isLoaded: dataIsLoaded,
        progress: dataProgress,
    } = useProgressDispatch(() => hasValue(piezometer.id) ? [
        dispatch(PiezometerStationAction.getPiezoChartMeasures({
            dataType: CHRONIC_DATA_TYPE,
            displayCote: filter.displayCote,
            groupFunc: MAX,
            stationId: piezometer.id,
        })).then(result => setMeasures(result.measures.filter(m => !!m.value))),
        dispatch(PiezometerStationAction.loadPiezometerAdditionalMeasures(piezometer.id)),
    ] : [], [filter.chronicType, filter.displayCote])

    useEffect(() => {
        if (!componentHasHabilitations(H_STATION_PIEZO_FOLLOW_UP)) {
            dispatch(push('/unauthorized'))
        }

        if (hasValue(piezometer.id)) {
            dispatch(PiezometerStationAction.fetchPiezometerSamples(piezometer.id))
            dispatch(PiezometerStationAction.fetchPiezoMeasuresStats(piezometer.id))
        }
    }, [])

    useTitle(() => [{
        title: i18n.piezometry,
        href: STATION_TYPE_NAME.piezometry,
    }, {
        title: getStationTitle(piezometer),
        href: `station/piezometry/${piezometer.id}`,
    }, {
        title: i18n.followUpTable,
        href: `station/piezometry/${piezometer.id}/piezoSuiviTable`,
    }], [])

    const onChangeFilter = (filterName, value) => setTmpFilter(prev => ({ ...prev, [filterName]: value }))

    const chronicTypeOptions = useMemo(() => {
        const additionnalSeries = piezometerAdditionalMeasures.reduce((acc, typeObj) => {
            const dataType = piezometerStatistics.find(t => t.typeId === typeObj.type)
            if (dataType) {
                return [
                    ...acc,
                    {
                        code: `additional${typeObj.type}`,
                        label: dataType.label,
                        dateKey: DATE,
                        hourKey: HOUR,
                        groupFunc: max,
                        type: typeObj.type,
                        order: dataType.order,
                    },
                ]
            }
            return acc
        }, [])

        return orderBy(i18nize(getValidPiezometrySeriesParams()).concat(additionnalSeries), 'order')
    }, [piezometerAdditionalMeasures, piezometerStatistics])

    return (
        <Grid2 container padding={1} spacing={1}>
            <Grid2 container size={12} direction={'row'}>
                <Card sx={{ width: '100%' }}>
                    <CardContent>
                        <Grid2 container alignItems={'center'} spacing={1}>
                            <Grid2 size={2}>
                                <Select
                                    options={chronicTypeOptions}
                                    noSort
                                    label={i18n.chronicType}
                                    onChange={e => onChangeFilter(CHRONIC_TYPE, e)}
                                    value={tmpFilter.chronicType}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <Select
                                    options={MEASURE_COTES}
                                    noSort
                                    keyValue='key'
                                    keyLabel='label'
                                    label={i18n.displayMode}
                                    onChange={e => onChangeFilter(DISPLAY_COTE, e)}
                                    value={tmpFilter.displayCote}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <Select
                                    options={getStatusSelectOptions()}
                                    label={i18n.status}
                                    nullLabel='&nbsp;'
                                    onChange={e => onChangeFilter('state', e)}
                                    value={tmpFilter.state}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <Select
                                    options={getQualificationSelectOptions()}
                                    label={i18n.qualification}
                                    nullLabel='&nbsp;'
                                    onChange={e => onChangeFilter(QUALIFICATION, e)}
                                    value={tmpFilter.qualification}
                                />
                            </Grid2>
                            <Grid2 size={1.5}>
                                <RadioButtons
                                    colOption={ 12 }
                                    selected={tmpFilter.horizon}
                                    title={i18n.horizon}
                                    onChange={(e) => onChangeFilter(HORIZON, e)}
                                    elements={[
                                        { code: YEAR, name: i18n.perYear },
                                        { code: HISTORIC, name: i18n.historic },
                                    ]}
                                />
                            </Grid2>
                            {
                                tmpFilter.horizon === YEAR && (
                                    <Grid2 size={1}>
                                        <YearsSelectComponent
                                            tmpFilter={tmpFilter}
                                            measures={measures}
                                            onChangeFilter={onChangeFilter}
                                        />
                                    </Grid2>
                                )
                            }
                            <ButtonMUI
                                onClick={() => setFilter(tmpFilter)}
                                sx={{ marginLeft: AUTO }}
                                variant='contained'
                                data-cy={'search'}
                            >
                                {i18n.search}
                            </ButtonMUI>
                        </Grid2>
                    </CardContent>
                </Card>
                {
                    !dataIsLoaded ? (
                        <Grid2 size={12}>
                            <ProgressCard progress={dataProgress} withMessage />
                        </Grid2>
                    ): (
                        <Grid2 container size={12}>
                            { !measures.length ? (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            ) : (
                                <Grid2 container size={12} sx={{ height: '70vh' }}>
                                    <PiezometerSuiviTable
                                        piezometer={piezometer}
                                        filter={filter}
                                        historic={filter.horizon === HISTORIC}
                                        measures={measures}
                                        chronicTypes={chronicTypeOptions}
                                        tableHeight='100%'
                                    />
                                </Grid2>
                            )}
                        </Grid2>
                    )
                }
            </Grid2>
        </Grid2>
    )
}

PiezometerSuiviTableApp.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    piezometerAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    piezometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
}


export default PiezometerSuiviTableApp
