/* eslint-disable camelcase */

export default class DtoPgsseCampaignWithStats {
    constructor(obj) {
        this.idDiag = obj.idDiag // Option[Long],
        this.idCampaign = obj.idCampaign // Option[Long],
        this.name = obj.name // Option[String],
        this.nbValide = obj.nbValide // Option[Int],
        this.nbInst = obj.nbInst // Option[Int],
        this.status = obj.status // Option[Long],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
    }
}
