import { createSlice } from '@reduxjs/toolkit'
import DtoCampaignWithStats from 'pgsse/dto/DtoPgsseCampaignWithStats'
import DtoPgsseCampaign from 'pgsse/dto/DtoPgsseCampaign'
import DtoPgsseDiagnostic from 'pgsse/dto/DtoPgsseDiagnostic'
import DtoPgsseDiagnosticWithStats from 'pgsse/dto/DtoPgsseDiagnosticWithStats'
import DtoPgsseForm from 'pgsse/dto/DtoPgsseForm'
import DtoPgsseSurvey from 'pgsse/dto/DtoPgsseSurvey'
import DtoCMSSurvey from 'events/dto/DtoCMSSurvey'

export const initialState = {
    surveys: [],
    dashboardPhase1: [],
    diagnostics: [],
    diagnostic: new DtoPgsseDiagnostic({}),
    campaign: new DtoPgsseCampaign({}),
    form: new DtoPgsseForm({}),
    survey: new DtoCMSSurvey({}),
}

const store = createSlice({
    name: 'pgsse',
    initialState,
    reducers: {
        receiveDashboardPhase1: (state, action) => {
            state.dashboardPhase1 = action.payload.map(p => new DtoCampaignWithStats(p))
        },
        receivePhase1List: (state, action) => {
            state.diagnostics = action.payload.map(p => new DtoPgsseDiagnosticWithStats(p))
        },
        receiveDiagnostic: (state, action) => {
            state.diagnostic = new DtoPgsseDiagnostic(action.payload)
        },
        receiveCampaign: (state, action) => {
            state.campaign = new DtoPgsseCampaign(action.payload)
        },
        receiveForm: (state, action) => {
            state.form = new DtoPgsseForm(action.payload)
        },
        receiveSurveys: (state, action) => {
            state.surveys = action.payload.map(p => new DtoPgsseSurvey(p))
        },
        receiveSurvey: (state, action) => {
            state.survey = new DtoCMSSurvey(action.payload)
        },
    },
})

export const PgsseActionConstant = store.actions
export default store.reducer