import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Select from '../../../../components/forms/Select'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { CardTable } from '../../../../components/datatable/NewTable'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { Grid2 } from '@mui/material'


class LiguraPanel extends Component {
    addCodeToJob = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                this.props.addCode([
                    code,
                    ...this.props.job.parameters.parameters,
                ])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    onDeleteCode = ({ code }) => this.props.addCode(this.props.job.parameters.parameters.filter(p => p != code))

    render = () => {
        const {
            job,
            isEditMode,
            onChangeDataTypes,
        } = this.props

        const stationTypes = [
            {
                value: 'Pluvio',
                label: i18n.pluviometry,
            },
            {
                value: 'Idro', // en italien
                label: i18n.hydrometry,
            },
        ]

        const getDataTypes = job.parameters.dataTypes && job.parameters.dataTypes.length ? job.parameters.dataTypes[0] : ''
        const stationsCodes = job.parameters.parameters.map(station => ({ station }))
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-bottom-2'>
                    <div className='col s4 no-margin no-padding'>
                        <Select
                            value={getDataTypes}
                            options={stationTypes}
                            label={ i18n.dataIntegration }
                            col={12}
                            onChange={v => onChangeDataTypes([v])}
                            disabled={!isEditMode}
                            labelSpan={ integrationModeHelpIcon() }
                        />
                    </div>
                </div>
                <div className='row padding-left-1 padding-right-1 valign-wrapper'>
                    <div className='col s3'>
                        <label>{i18n.addCredential}</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddCode' type='text' placeholder={i18n.LIGURAExample} ref='txtAddCode' />
                            <label className='tinyLabel'>{i18n.stationCode}</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={this.addCodeToJob}
                        >
                            {i18n.add}
                        </a>
                    </div>
                </div>
                <Grid2 size={12}>
                    <CardTable
                        headers={ ['credentials']}
                        title={ i18n.credentials }
                        rows={stationsCodes}
                        lineActions={[
                            { icon: 'delete', onClick: this.onDeleteCode, displayed: this.props.isEditMode },
                        ]}
                        actions={[{
                            icon: 'download',
                            onClick: () => exportFile({
                                data: [{ ...(stationsCodes[0]), headers: ['credentials'] }, ...(stationsCodes.slice(1, stationsCodes.length))],
                                titleFile: i18n.credentials,
                            }),
                            tooltip: i18n.download,
                        }]}
                        displayHeaders={false}
                    />
                </Grid2>
            </div>
        )
    }
}

LiguraPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps,
)(LiguraPanel)
