import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { getDate, getHour } from '../../utils/DateUtil'
import { Card, CardContent } from '@mui/material'

const LastUpdateCard = ({
    element = undefined,
    withHour = false,
    className,
}) => {
    const label = (() => {
        if (element) {
            const updateLogin = element.updateLogin ? element.updateLogin : i18n.unknown
            const updateDate = getDate(element.updateDate, 'DD/MM/YYYY')
            if (withHour) {
                const updateHour = getHour(element.updateDate, 'HH:mm')
                return `${i18n.lastUpdate} ${i18n.by} ${updateLogin} ${i18n.atDate} ${updateDate} ${i18n.atHour} ${updateHour}`
            }
            return `${i18n.lastUpdate} ${i18n.by} ${updateLogin} ${i18n.atDate} ${updateDate}`
        }
        return i18n.noUpdate
    })()
    return (
        <Card className={className}>
            <CardContent>
                <div className='no-padding'>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <h6>{label}</h6>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

LastUpdateCard.propTypes = {
    element: PropTypes.shape({
        updateLogin: PropTypes.string,
        updateDate: PropTypes.number,
    }),
    withHour: PropTypes.bool,
    className: PropTypes.string,
}

export default LastUpdateCard