/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import DtoAnswer from 'installation/components/campaign/dto/DtoAnswer'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import { orderBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Question from './Question'
import { CardTitle } from 'components/card/NewCard'
import useActions from 'utils/customHook/useActions'
import { useParams } from 'react-router'
import PgsseAction from 'pgsse/action/PgsseAction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import InstallationAction from 'installation/actions/InstallationAction'
import { hasValue } from 'utils/NumberUtil'
import ProgressBar from 'components/progress/ProgressBar'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { push } from '@lagunovsky/redux-react-router'
import moment from 'moment'
import { searchAllCharacters } from 'utils/StringUtil'
import Checkbox from 'components/forms/Checkbox'
import { isDanger } from 'pgsse/util/PgsseUtil'

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 35,
        maxHeight: 35,
        '&.Mui-expanded': {
            minHeight: 45,
            maxHeight: 45,
        },
    },
}))

const Form = ({
    title = '',
}) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { idCampaign, idForm } = useParams()

    const {
        form: propsForm,
        survey,
        installation,
    } = useSelector(store => ({
        form: store.PgsseReducer.form,
        survey: store.PgsseReducer.survey,
        installation: store.InstallationReducer.installation,
    }), shallowEqual)

    const [readMode, setReadMode] = useState(true)
    const [form, setForm] = useState(propsForm)
    const [isFormLoaded, setIsFormLoaded] = useState(false)
    const [isSurveyLoaded, setIsSurveyLoaded] = useState(false)
    const [expanded, setExpanded] = useState('panel0')
    const [search, setSearch] = useState('')
    const [noAns, setNoAns] = useState(false)
    const [errQ, setErrQ] = useState(false)
    const [filter, setFilter] = useState({})

    useEffect(() => setForm(propsForm), [propsForm])

    useEffect(() => {
        setIsFormLoaded(false)
        dispatch(PgsseAction.fetchForm(idForm)).then(() => setIsFormLoaded(true))
    }, [idForm])

    useEffect(() => {
        if (propsForm.stationType === 7 && propsForm.code) {
            dispatch(InstallationAction.fetchInstallation(propsForm.code))
        }
    }, [propsForm])

    const saveForm = (finaliseForm) => {
        if (form.formStatus === 1 && form.stationType === STATION_TYPE_CONSTANT.INSTALLATION) {
            dispatch(InstallationAction.addInstallationEvent(
                form.code,
                {
                    code: installation.id,
                    eventDate: moment().valueOf(),
                    eventType: '',
                    comment: i18n.formEvent,
                    campaignCode: parseInt(idCampaign),
                    campaignType: 1,
                }))
        }
        dispatch(PgsseAction.updateForm(form.idForm, {
            ...form,
            formStatus: finaliseForm || form.formStatus === 3 ? 3 : 2,
        })).then(() => {
            setReadMode(true)
        })
    }

    useActions(() => {
        return readMode ? {
            edit: () => setReadMode(false),
        } : {
            save: saveForm,
            cancel: () => {
                setForm(propsForm)
                setReadMode(true)
            },
        }
    }, [readMode, form, propsForm])

    const onChangeForm = values => setForm(prev => ({ ...prev, ...values }))

    useEffect(() => {
        if (hasValue(propsForm.idSurvey)) {
            if (propsForm.stationType === 7 && hasValue(installation.installationType)) {
                setIsSurveyLoaded(false)
                dispatch(PgsseAction.fetchSurvey(propsForm.idSurvey, {
                    stationType: form.stationType,
                    installationType: installation.installationType,
                })).then(() => setIsSurveyLoaded(true))
            } else if (propsForm.stationType !== 7) {
                setIsSurveyLoaded(false)
                dispatch(PgsseAction.fetchSurvey(propsForm.idSurvey, { stationType: propsForm.stationType }))
                    .then(() => setIsSurveyLoaded(true))
            }
        }
    }, [propsForm, installation])

    const getColorFromAnswers = (paragraph, answers) => {
        if (paragraph.link_questions.length > 0 && paragraph.link_questions.length === answers.length) {
            return 'green'
        }
        return 'grey'
    }

    const onSearch = value => setFilter({ search: searchAllCharacters(value), noAns, errQ })

    return (
        <Grid2 container sx={{ paddding: '10px', margin: '10 10 10 20' }} spacing={2}>
            <Grid2 size={12}>
                {isFormLoaded && (
                    <Card>
                        <CardTitle title={title} />
                        <CardContent>
                            <Grid2 container spacing={1}>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        label={i18n.visitDate}
                                        value={form.formDate}
                                        onChange={v => onChangeForm({ formDate: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Input
                                        title={i18n.mainTechnician}
                                        value={form.technicianLogin}
                                        onChange={v => onChangeForm({ technicianLogin: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Input
                                        title={i18n.otherTechnicians}
                                        value={form.otherTechnicians}
                                        onChange={v => onChangeForm({ otherTechnicians: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Input
                                        title={i18n.participants}
                                        value={form.participants}
                                        onChange={v => onChangeForm({ participants: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <SimpleTextArea
                                        title={i18n.comment}
                                        value={form.comments}
                                        onChange={v => onChangeForm({ comments: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12} container justifyContent='flex-end' spacing={2}>
                                    <Grid2>
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                if (form.stationType === STATION_TYPE_CONSTANT.CONTRIBUTOR) {
                                                    dispatch(push(`/referencial/contributor/${form.code}/dashboard`))
                                                } else if (form.stationType === STATION_TYPE_CONSTANT.INSTALLATION) {
                                                    dispatch(push(`/station/installation/${form.code}/description`))
                                                }
                                            }}
                                        >
                                            {i18n.accessToTheFile}
                                        </Button>
                                    </Grid2>
                                    <Grid2>
                                        <Button
                                            variant='contained'
                                            onClick={() => saveForm(true)}
                                        >
                                            {i18n.finalized}
                                        </Button>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                )}
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <Grid2 container alignItems='center' spacing={1} justifyContent='space-between'>
                            <Grid2 size={4}>
                                <Input
                                    title={i18n.search}
                                    value={search}
                                    onChange={setSearch}
                                    onEnterKeyPress={onSearch}
                                />
                            </Grid2>
                            <Grid2>
                                <Checkbox
                                    label={'Questions sans réponses'}
                                    checked={noAns}
                                    onChange={setNoAns}
                                />
                            </Grid2>
                            <Grid2>
                                <Checkbox
                                    label={'Questions en erreur'}
                                    checked={errQ}
                                    onChange={setErrQ}
                                />
                            </Grid2>
                            <Grid2 container justifyContent='flex-end'>
                                <Button variant='contained' onClick={() => onSearch(filter.search)}>
                                    {i18n.search}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12} container>
                {
                    isSurveyLoaded && survey.link_paragraphs.map((paragraph, index) => {
                        const answers = form.link_answers.filter(la => la.idParagraph === paragraph.idParagraph)
                        const nbAnswer = answers.filter(a => !!a.idQuestion)
                        const paragraphAnswer = answers.find(a => !a.idQuestion)
                        const bckgroundColor = getColorFromAnswers(paragraph, nbAnswer)
                        const searchFiltered = filter.search ?
                            paragraph.link_questions.filter(question => question.description.includes(filter.search)) :
                            paragraph.link_questions
                        const filteredQuestions = searchFiltered.filter(sf => {
                            const answer = answers.find(a => a.idQuestion === sf.idQuestion) || {}
                            if (filter.errQ && filter.noAns) {
                                return isDanger(answer) || !answer.idQuestion
                            } else if (filter.errQ) {
                                return isDanger(answer)
                            } else if (filter.noAns) {
                                return !answer.idQuestion
                            }
                            return true
                        })
                        if (!filteredQuestions.length) return null
                        return (
                            <Grid2 size={12} key={`paragraph_${index}`}>
                                <AccordionMUI
                                    expanded={expanded === `panel${index}`}
                                    onChange={(_, isExpanded) => setExpanded(isExpanded ? `panel${index}` : false)}
                                    sx={{ border: `solid 1px ${bckgroundColor}` }}
                                >
                                    <AccordionSummaryMUI
                                        sx={{ color: 'white', fontSize: '16px', background: bckgroundColor }}
                                        classes={classes}
                                    >
                                        {`${paragraph.description} (${paragraph.link_questions.length} ${i18n.questions} - ${nbAnswer.length} ${i18n.answers})`}
                                    </AccordionSummaryMUI>
                                    <AccordionDetailsMUI>
                                        <>
                                            {
                                                orderBy(paragraph.link_questions, 'order').map(q =>
                                                    filteredQuestions.some(fq => fq.idQuestion === q.idQuestion) && (
                                                        <Question
                                                            key={q.idQuestion}
                                                            question={q}
                                                            dataForm={form}
                                                            onChange={onChangeForm}
                                                            readMode={readMode}
                                                        />
                                                    ),
                                                )
                                            }
                                            {search !== '' && <Grid2 size={12}>
                                                <SimpleTextArea
                                                    title={i18n.generalComment}
                                                    value={paragraphAnswer?.answerText}
                                                    rows={2}
                                                    onChange={v => {
                                                        onChangeForm({
                                                            link_answers: [
                                                                ...form.link_answers.filter(la => !(la.idParagraph === paragraph.idParagraph && !la.idQuestion)),
                                                                new DtoAnswer({
                                                                    recipientCode: form.idInstallation,
                                                                    idCampaign: form.idCampaign,
                                                                    recipientType: DANGER_ELEMENT_TYPE.INSTALLATION,
                                                                    idSurvey: form.idSurvey,
                                                                    idVisit: form.idVisit,
                                                                    ...paragraph,
                                                                    answerText: v,
                                                                }),
                                                            ],
                                                        })
                                                    }}
                                                    disabled={readMode}
                                                />
                                            </Grid2>
                                            }
                                        </>
                                    </AccordionDetailsMUI>
                                </AccordionMUI>
                            </Grid2>
                        )
                    }) || <ProgressBar indeterminate />
                }
            </Grid2>
        </Grid2 >
    )
}

Form.propTypes = {
    title: PropTypes.string,
}

export default Form
