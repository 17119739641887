import Input from 'components/forms/Input'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import ConfirmModal from 'components/modal/ConfirmModal'
import { groupBy, isNil, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import DtoAnalysis from 'quality/dto/DtoAnalysis'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import SieauParameterDto from '../../../../../administration/dto/SieauParameterDto'
import ActionComponent from '../../../../../components/ActionComponent'
import Other from '../../../../../components/actions/Other'
import DtoFile from '../../../../../components/file/dto/DtoFile'
import CityDto from '../../../../../referencial/components/city/dto/CityDto'
import SupportDto from '../../../../../referencial/components/support/dto/SupportDto'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import DtoStation from '../../../../../station/dto/DtoStation'
import {
    getLocalizationLabel,
    getLocalizationPicto,
    getQualificationIcon,
    THRESHOLD_COLOR,
} from '../../../../../utils/AnalyseUtils'
import { getDate, getFullDate } from '../../../../../utils/DateUtil'
import { hasValue } from '../../../../../utils/NumberUtil'
import { statusIcon } from '../../../../../utils/StatusUtil'
import {
    arrayOf, createIndex,
    getLabel,
    getLabelTruncate,
    getMapStateToProps,
    getObjectLabel,
    getPropTypes,
    objectOf,
} from '../../../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import OperationAction from '../../actions/OperationAction'
import DtoDetailOperation from '../../dto/DtoDetailOperation'
import DtoRemark from '../../dto/DtoRemark'
import AnalysisPopin from './AnalysisPopin'
import DtoPrettyAnalysisLight from '../../../dashboard/dto/DtoPrettyAnalysisLight'
import ContributorItem from '../../../../../referencial/components/contributor/dto/ContributorItem'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import { getQualifications, getStatuses, UNQUALIFIED } from '../../../../../utils/QualityUtils'
import UnitDto from '../../../../../referencial/components/unit/dto/UnitDto'
import { SuperParameterGraphModal } from '../../../qualityComponents/ParameterGraph'
import AnalysisStepper from '../stepper/AnalysisStepper'
import { Button, Grid2 } from '@mui/material'
import { calculateThresholdResult } from '../../../../../utils/AnalyseUtils'
import DtoSampling from '../../dto/DtoSampling'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import ExportFileModal from 'components/modal/ExportFileModal'
import { exportModelFile, formatData, getModelFileType } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import { ALIGN, NewTable, WrapperBox } from 'components/datatable/NewTable'


const propsToFetch = {
    qualityThresholds: false,
    fractions: false,
    methods: false,
}

const tableHeaders = ['nullValue', 'place', 'parameter', 'result', 'unit', 'remarkCode', 'detectionLimit', 'quantificationLimit', 'support', 'fraction', 'laboratory', 'sampling', 'analysisDate']
const exportHeaders = ['parameterCode', 'parameter', 'result', 'value', 'unit', 'unitLabel', 'remarkCode', 'remark', 'detectionLimit', 'quantificationLimit', 'supportCode', 'support', 'fractionCode', 'fraction', 'laboratory', 'sampling', 'analysisDate', 'sampleDate', 'statusCode', 'status', 'qualificationCode', 'qualification', 'place']

class OperationAnalysisPanel extends ActionComponent {
    state = {
        selections: [],
        selection: -1,
        searchValue: '',
        isOpenChartPopin: false,
        isOpenAnalysisPopin: false,
        isOpenConfirmDelete: false,
        isOpenAnalysisStepper: false,
        selectedParam: undefined,
        analysis: {},
        isExportModalOpen: false,
    }

    componentDidMount() {
        const actions = {
            importFile: {
                onClick: this.props.uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
            new: () => this.setState({ isOpenAnalysisStepper: true }),
            calculate: [this.props.operation.id],
            exportList: [{
                onClick: () => this.setState({ isExportModalOpen: true }),
                label: i18n.excel,
            }],
            controlAnalysis: { stations: [this.props.qualitometer.id], operations: [this.props.operation.id] },
        }
        if (this.props.files.length) {
            actions.other = {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.operation}
                        icon='attach_file'
                        onClick={this.props.getOperationFiles}
                    />
                ),
            }
        }
        this.setActions(actions)
    }

    reloadOperations = () => {
        AppStore.dispatch(OperationAction.fetchAnalysis(parseInt(this.props.operation.id), parseInt(this.props.id), this.props.defaultThreshold))
    }

    getAnalysisExport = () => {
        const { analysis } = this.props
        const formatedAnalysis = analysis.map(a => {
            return {
                ...a,
                qualification: parseInt(a.qualification),
                status: parseInt(a.status),
                producer: a.producerId,
            }
        })
        const result = this.formatAnalysis(formatedAnalysis)

        const qualifications = getQualifications()

        const dataExport = result.map(a => {
            return {
                parameterCode: { value: a.paramCode, cellType: 'string' },
                parameter: { value: a.parameter.value },
                result: { value: a.resultValue, format: '0.00', cellType: 'number' },
                value: { value: a.value, color: a.color },
                unit: { value: a.unitCode, cellType: 'right' },
                unitLabel: { value: a.unit.value },
                remarkCode: { value: a.remark, format: '0', cellType: 'number' },
                remark: { value: a.remarkCode.value },
                detectionLimit: { value: a.detectionLimit.value, format: '0.00', cellType: 'number' },
                quantificationLimit: { value: a.quantificationLimit.value, format: '0.00', cellType: 'number' },
                supportCode: { value: a.supportCode, format: '0', cellType: 'number' },
                support: { value: a.support.value },
                fractionCode: { value: a.fractionCode, format: '0', cellType: 'number' },
                fraction: { value: a.fraction.value },
                laboratory: { value: a.laboratory.value },
                sampling: { value: a.sampling.value },
                analysisDate: { value: a.analysisDate.value, format: 'dd/MM/yyyy', cellType: 'date' },
                sampleDate: { value: getDate(a.sampleDate), format: 'dd/MM/yyyy', cellType: 'date' },
                statusCode: { value: a.status, format: '0', cellType: 'number' },
                status: { value: getLabel(getStatuses(), a.status) },
                qualificationCode: { value: a.qualification, format: '0', cellType: 'number' },
                qualification: { value: getLabel(qualifications, a.qualification || UNQUALIFIED) },
                place: { value: a.place?.value?.props?.['data-tooltip'] },
            }
        })
        if (dataExport.length) {
            dataExport[0].headers = exportHeaders
        }
        return dataExport
    }

    formatAnalysis = analysis => {
        const defaultThreshold = this.props.qualityThresholds.find(t => t.thresholdCode == this.props.threshold) || {}

        const samplingMap = createIndex(this.props.samplings, 'idSampling')

        const filteredSandreCodes = this.props.sandreCodes.filter(sc => sc.field.startsWith('PARAMETRE.'))
        const parametersSandre = groupBy(filteredSandreCodes, sc => sc.field.split('.')[1])

        const datas = analysis.filter(a => this.state.selection == -1 ? true : this.state.selections.includes(a.parameter)).map(analyse => {
            const remark = getLabel(this.props.remarks, analyse.remark)
            const parameter = this.props.parametersIndex[analyse.parameter]
            const parameterValue = parameter ? {
                value: getLabelTruncate(parameter.shortLabel || parameter.name, parameter.code),
                tooltip: parameter.name,
            } : {
                value: `<${analyse.parameter}>`,
            }
            const unit = this.props.unitsIndex[analyse.unit]
            const labelUnit = unit?.symbolWithCode

            const fraction = getLabel(this.props.fractions, analyse.fraction)
            const fractionTooltip = fraction.length > 20 ? {
                tooltip: fraction,
            } : {}
            const support = getLabel(this.props.supports, analyse.support)
            const supportTooltip = support.length > 20 ? {
                tooltip: support,
            } : {}
            // const method = hasValue(analyse.method) ? getLabel(this.props.methods, analyse.method) || `<${analyse.method}>` : ''
            // const methodTooltip = method.length > 30 ? {
            //     tooltip: method,
            // } : {}
            const icon = getQualificationIcon(analyse.qualification)
            const result = calculateThresholdResult(analyse, defaultThreshold.thresholds)

            const producer = hasValue(this.props.operation.producer) && getObjectLabel(this.props.contributorsIndex[this.props.operation.producer], 'mnemonique')
            const laboratory = hasValue(analyse.labo) && getObjectLabel(this.props.contributorsIndex[analyse.labo], 'mnemonique')
            const sampler = hasValue(this.props.operation.sampler) && getObjectLabel(this.props.contributorsIndex[this.props.operation.sampler], 'mnemonique')

            const foundSampling = samplingMap[analyse.numSampling]
            const sampling = foundSampling ? `${foundSampling.ref || ''} ${foundSampling.refLabo ? `[${foundSampling.refLabo}]` : ''}` : ''

            const parameterSandre = parametersSandre[analyse.parameter]?.find(ps => ps.code === analyse.result)
            const displayResult = !isNil(parameterSandre) ? `${parameterSandre.name} [${analyse.result}]` : result.value

            return {
                ...analyse,
                ...result,
                nullValue: { value: statusIcon(analyse, 15, true) },
                qualification: analyse.qualification,
                quantificationLimit: {
                    value: hasValue(analyse.lq) ? analyse.lq : '',
                    align: ALIGN.RIGHT,
                },
                detectionLimit: {
                    value: hasValue(analyse.ld) ? analyse.ld : '',
                    align: ALIGN.RIGHT,
                },
                remarkCode: { value: remark },
                supportCode: analyse.support,
                support: {
                    value: getLabelTruncate(support, analyse.support, 20),
                    ...supportTooltip,
                },
                fractionCode: analyse.fraction,
                fraction: {
                    value: getLabelTruncate(fraction, analyse.fraction, 20),
                    ...fractionTooltip,
                },
                parameter: parameterValue,
                paramCode: analyse.parameter,
                parameterName: getObjectLabel(parameter),
                producer: { value: producer || '' },
                laboratory: { value: laboratory || '' },
                sampler: { value: sampler || '' },
                value: analyse.value,
                resultValue: analyse.result,
                result: {
                    color: THRESHOLD_COLOR[result.color],
                    value: analyse.qualification !== '1' ? (
                        <div>
                            <i className='material-icons tiny right no-margin'>{icon}</i>
                            <span className='right' style={{ marginRight: '5px' }}>{displayResult}</span>
                        </div>
                    ) : displayResult,
                    tooltip: (
                        <TooltipAnalyse
                            title={parameter?.shortLabelWithCode || ''}
                            analyse={analyse}
                            threshold={result.threshold}
                        />
                    ),
                    sortValue: analyse.result,
                    align: ALIGN.RIGHT,
                },
                unit: { value: labelUnit || analysis.unit },
                unitCode: analyse.unit,
                analysisDate: { value: getDate(analyse.analysisDate) },
                place: { value: getLocalizationPicto(analyse.localization) },
                // method: { value: substringText(method, 30), color: 'w', ...methodTooltip },
                localization: { value: getLocalizationLabel(analyse.localization) },
                sampling: { value: sampling },
                searchValue: searchAllCharacters(`${analyse.parameter}${parameter?.name}${parameter?.shortLabel}${fraction}${labelUnit}${laboratory ?? ''}${sampling}${unit}${remark}${analyse.result}`),
            }
        }).filter(a => a.searchValue.includes(this.state.searchValue))

        return sortBy(datas, 'parameterName')
    }

    getContentPanel = () => {
        const {
            analysis,
        } = this.props
        if (!analysis.length) {
            return (
                <h5 className='padding-top-2 padding-left-2 padding-bottom-2'>{i18n.noAnalysis}</h5>
            )
        }
        const formatedAnalysis = analysis.map(a => {
            return {
                ...a,
                qualification: parseInt(a.qualification),
                status: parseInt(a.status),
                producer: a.producerId,
            }
        })
        const result = this.formatAnalysis(formatedAnalysis)
        return (
            <div className='card card-content no-margin no-padding'>
                <div className='row no-margin'>
                    <div className='col s12 no-padding'>
                        <div className='row no-margin padding-top-1 padding-bottom-1'>
                            <Input
                                col={4}
                                onChange={v => this.setState({ searchValue: v })}
                                title={i18n.search}
                                value={this.state.searchValue}
                            />
                            <ThresholdSelect
                                col={4}
                                onChange={this.props.onChangeThreshold}
                                selected={this.props.threshold}
                                nullLabel='&nbsp;'
                            />
                            <SelectionSelect
                                col={4}
                                onChange={(parameters, value) => this.setState({ selections: parameters, selection: value })}
                            />
                        </div>
                        <NewTable
                            rows={result}
                            headers={tableHeaders}
                            rowsPerPageOptions={nbPerPageLabel}
                            onClickRown={({ paramCode }) => this.setState({ isOpenChartPopin: true, selectedParam: paramCode })}
                            lineActions={[
                                { icon: 'delete', onClick: ({ id }) => this.setState({ isOpenConfirmDelete: true, analysis: this.props.analysis.find(a => a.id === id) }) },
                                { icon: 'edit', onClick: ({ id }, index) => this.setState({ isOpenAnalysisPopin: true, analysis: this.props.analysis.find(a => a.id === id), index }) },
                            ]}
                            WrapperComponent={WrapperBox}
                        />
                    </div>
                </div>
            </div>
        )
    }

    getExportModel = () => this.props.typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: this.props.qualitometer.id.toString(),
                    stationCode: this.props.qualitometer.code,
                    stationType: this.props.qualitometer.typeName,
                    environmentModels: this.props.typeEnvironmentModels,
                    filenameModelExport: model,
                    qualityFilter: {
                        stations: [this.props.qualitometer.id],
                        operations: [this.props.operation.id],
                    },
                }),
            }],
        }
    })

    render() {
        const {
            isOpenAnalysisPopin,
            isOpenConfirmDelete,
            isOpenChartPopin,
            selectedParam,
            isOpenAnalysisStepper,
            analysis,
            index,
        } = this.state
        const thresholds = (this.props.qualityThresholds.find(t => t.thresholdCode == this.props.threshold) || {})?.thresholds || []
        return (
            <div>
                {this.getContentPanel()}
                <AnalysisPopin
                    isOpen={isOpenAnalysisPopin}
                    onClose={() => this.setState({ isOpenAnalysisPopin: false, analysis: {} })}
                    onValidate={() => {
                        this.setState({ isOpenAnalysisPopin: false, analysis: {} })
                        this.reloadOperations()
                    }}

                    analysisId={analysis.id}
                    stationId={parseInt(this.props.id)}
                    sampleId={this.props.sampleId}
                    operationId={parseInt(this.props.code)}

                    additionalButtons={(
                        <>
                            <Grid2 container size={3} justifyContent='center'>
                                <Button
                                    onClick={() => {
                                        const prevIndex = index - 1
                                        this.setState({ index: prevIndex, analysis: this.props.analysis[prevIndex] })
                                    }}
                                    variant='outlined'
                                    disabled={index <= 0}
                                    color='primary'
                                >
                                    {i18n.previous}
                                </Button>
                            </Grid2>
                            <Grid2 container size={3} justifyContent='center'>
                                <Button
                                    onClick={() => {
                                        const nextIndex = index + 1
                                        this.setState({ index: nextIndex, analysis: this.props.analysis[nextIndex] })
                                    }}
                                    variant='outlined'
                                    disabled={index >= this.props.analysis.length - 1}
                                    color='primary'
                                >
                                    {i18n.next}
                                </Button>
                            </Grid2>
                        </>
                    )}
                />
                <ConfirmModal
                    isOpen={isOpenConfirmDelete}
                    title={i18n.sureDeleteAnalysis}
                    onValidate={() => {
                        OperationAction.deleteAnalysis(analysis)
                            .then(res => {
                                if (res.delete > 0) {
                                    AppStore.dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                                    this.reloadOperations()
                                    this.setState({ isOpenConfirmDelete: false, analysis: {} })
                                } else {
                                    AppStore.dispatch(ToastrAction.error(i18n.deleteError + i18n.analysis))
                                }
                            })
                    }}
                    onClose={() => this.setState({ isOpenConfirmDelete: false, analysis: {} })}
                />
                <AnalysisStepper
                    isOpen={isOpenAnalysisStepper}
                    onClose={() => this.setState({ isOpenAnalysisStepper: false })}

                    sampleId={this.props.sampleId}
                />
                <SuperParameterGraphModal
                    isOpen={isOpenChartPopin}
                    closeGraph={() => this.setState({ isOpenChartPopin: false })}

                    parameter={selectedParam}
                    qualitometer={parseInt(this.props.id)}
                    thresholds={thresholds}
                    graphOptions={{
                        regroupAxis: false,
                    }}
                />
                <ExportFileModal
                    open={this.state.isExportModalOpen}
                    onClose={() => this.setState({ isExportModalOpen: false })}
                    data={[
                        {
                            name: i18n.resultsTable,
                            formats: [{
                                type: i18n.excelFile,
                                callback: () => this.props.export(formatData(this.getAnalysisExport()), 'xlsx', `${this.props.qualitometer.code} - ${i18n.operations} - ${getFullDate(this.props.operation.dateStart)}`),
                            }, {
                                type: i18n.csvFile,
                                callback: () => this.props.export(formatData(this.getAnalysisExport()), 'csv', `${this.props.qualitometer.code} - ${i18n.operations} - ${getFullDate(this.props.operation.dateStart)}`),
                            }],
                        },
                        ...this.getExportModel(),
                    ]}
                />
            </div>
        )
    }
}

OperationAnalysisPanel.propTypes = getPropTypes(propsToFetch, {
    id: PropTypes.number.isRequired,
    code: PropTypes.number.isRequired,
    threshold: PropTypes.number,
    onChangeThreshold: PropTypes.func,
    operation: PropTypes.instanceOf(DtoDetailOperation),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoPrettyAnalysisLight)),
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    contributorsIndex: objectOf(ContributorItem),
    parametersIndex: objectOf(ParameterDto),
    unitsIndex: objectOf(UnitDto),
    allAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    qualitometer: PropTypes.instanceOf(DtoStation),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    getOperationFiles: PropTypes.func,
    defaultThreshold: PropTypes.string,
    sampleId: PropTypes.number,
    samplings: arrayOf(DtoSampling),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
})

const mapStateToProps = store => ({
    ...getMapStateToProps(propsToFetch),
    applicationSettings: store.AdministrationReducer.applicationSettings,
    supports: store.SupportReducer.supports,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    parametersIndex: store.ParameterReducer.parametersIndex,
    unitsIndex: store.UnitReducer.unitsIndex,
    allAnalysis: store.SuivipcReducer.analysis,
    qualitometer: store.QualityReducer.qualitometer,
    citiesIndex: store.CityReducer.citiesIndex,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    export: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationAnalysisPanel)
