import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Play extends IAction {
    fn = () => {
        return this.props.onPlay
    }
    icon = () => {
        return 'play_circle_outline'
    }
    label = () => {
        return i18n.launch
    }

    id = () =>{
        return 'play_action_navbar'
    }
}

Play.propTypes = {
    onPlay: PropTypes.func.isRequired,
}

export default Play
