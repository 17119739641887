import { Card, CardContent, Grid2, IconButton } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, isNil, template } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from '../../../administration/components/user/actions/UserAction'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationAccessibilitiesPanel from '../../../station/components/link/StationAccessibilitiesPanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import StationCasingPanel from '../../../station/components/link/StationCasingPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationFunctionPanel from '../../../station/components/link/StationFunctionPanel'
import StationHydrogeologicalEntityPanel from '../../../station/components/link/StationHydrogeologicalEntityPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import QualitySamplePointsPanel from '../../../station/components/link/QualitySamplePointsPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationWatermassPanel from '../../../station/components/link/StationWatermassPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { NEW, STATION_QUALITOMETER_NAMES } from '../../../station/constants/StationConstants'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks, isUndergroundWater } from '../../../utils/StationUtils'
import QualityAction from '../../actions/QualityAction'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { CardTitle } from 'components/card/NewCard'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import StationContributorPanel from '../../../station/components/link/StationContributorPanel'
import useSandreList from 'utils/customHook/useSandreList'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { favOrange } from 'utils/constants/ColorTheme'
import useDebounce from 'utils/customHook/useDebounce'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import StationAction from 'station/actions/StationAction'
import useActions from 'utils/customHook/useActions'
import { useParams } from 'react-router'

const QualitySyncOptions = ({
    setSynchronizeOption = () => { },
}) => {
    const [description, setDescription] = useState(false)
    const [descriptionOption, setDescriptionOption] = useState(0)
    const [networks, setNetworks] = useState(false)
    const [networksOption, setNetworksOption] = useState(0)
    const [samplePoints, setSamplePoints] = useState(false)
    const [samplePointsOption, setSamplePointsOption] = useState(0)

    const selectOptions = useMemo(() => [{
        id: 0,
        name: i18n.complete,
    }, {
        id: 1,
        name: i18n.replace,
    }], [])

    useEffect(() => {
        setSynchronizeOption({
            description,
            descriptionOption,
            networks,
            networksOption,
            samplePoints,
            samplePointsOption,
        })
    }, [description, descriptionOption, networks, networksOption, samplePoints, samplePointsOption])

    return (
        <div style={{ textAlign: 'left' }}>
            <StyledFieldSet>
                <StyledLegend>{i18n.synchronizeName}</StyledLegend>
                <Grid2 container justifyContent='center'>
                    <Grid2 container size={11}>
                        <Grid2 size={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.description}
                                checked={description}
                                onChange={setDescription}
                            />
                        </Grid2>
                        {description && (
                            <Grid2 size={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={descriptionOption}
                                    onChange={setDescriptionOption}
                                    clearIcon={false}
                                />
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 container size={11}>
                        <Grid2 size={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.networks}
                                checked={networks}
                                onChange={setNetworks}
                            />
                        </Grid2>
                        {networks && (
                            <Grid2 size={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={networksOption}
                                    onChange={setNetworksOption}
                                    clearIcon={false}
                                />
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 container size={11}>
                        <Grid2 size={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.catchmentPoints}
                                checked={samplePoints}
                                onChange={setSamplePoints}
                            />
                        </Grid2>
                        {samplePoints && (
                            <Grid2 size={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={samplePointsOption}
                                    onChange={setSamplePointsOption}
                                    clearIcon={false}
                                />
                            </Grid2>
                        )}
                    </Grid2>
                </Grid2>
            </StyledFieldSet>
        </div>
    )
}

QualitySyncOptions.propTypes = {
    setSynchronizeOption: PropTypes.func,
}

const DescriptionCard = ({
    qualitometer = {},
    setQualitometer = () => {},
    isReadMode = true,
}) => {
    const dispatch = useDispatch()
    const {
        userBookmarks,
        qualitometerTypes,
        waterSourceTypes,
        qualities,
        stationStates,
        natures,
        fieldModes,
        accountUser,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        qualitometerTypes: store.StationReducer.qualitometerType,
        waterSourceTypes: store.StationReducer.types,
        qualities: store.StationReducer.qualities,
        stationStates: store.StationReducer.states,
        natures: store.StationReducer.natures,
        fieldModes: store.StationReducer.fieldModes,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const qualityRepresentativness = useSandreList(SANDRE.REPRESENTATIVNESS_QUALITY)

    const [qualito, setQualito] = useState(qualitometer)
    const shouldBouce = useRef(false)

    useUpdateEffect(() => {
        if (isEqual(qualitometer, qualito)) return
        setQualito(qualitometer)
        shouldBouce.current = false
    }, [qualitometer])

    useDebounce(() => {
        if (shouldBouce.current) {
            setQualitometer(qualito)
        }
    }, 500, [qualito])

    const qualitometerBookmarks = useMemo(() => {
        return userBookmarks.filter(b => b.stationType === 3).map(b => b.identifiant)
    }, [userBookmarks])

    const isFav = qualitometerBookmarks.some(code => code === qualitometer.code)

    const toggleBookmark = () => {
        if (isFav) {
            dispatch(UserAction.deleteBookmark({ identifiant: qualitometer.code, login: accountUser.login, stationType: 3 }))
        } else {
            dispatch(UserAction.createBookmark({ identifiant: qualitometer.code, login: accountUser.login, stationType: 3 }))
        }
    }

    const onChangeQualito = obj => {
        setQualito(prev => ({ ...prev, ...obj }))
        shouldBouce.current = true
    }

    return (
        <Card>
            <CardTitle title={i18n.description} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    {!isUndergroundWater(qualito) && (
                        <Grid2 size={6}>
                            <Input
                                value={qualito.code}
                                title={i18n.code}
                                disabled
                                readMode={isReadMode}
                            />
                        </Grid2>
                    )}
                    {
                        isUndergroundWater(qualito) && (
                            <>
                                <Grid2 size={3}>
                                    <Input
                                        value={`${qualito.code}${(isReadMode && `/${qualito.designation || ''}`) || ''}`}
                                        title={i18n.code}
                                        disabled
                                        readMode={isReadMode}
                                    />
                                </Grid2>
                                <Grid2 container size={1} justifyContent='center' alignItems='center'> / </Grid2>
                                <Grid2 size={2}>
                                    <Input
                                        value={qualito.designation}
                                        title={i18n.designation}
                                        onChange={v => onChangeQualito({ designation: v })}
                                        readMode={isReadMode}
                                    />
                                </Grid2>
                            </>
                        )
                    }
                    <Grid2 size='grow'>
                        <Input
                            value={qualito.name}
                            title={i18n.name}
                            onChange={v => onChangeQualito({ name: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 container size='auto' justifyContent='flex-end' alignItems='center'>
                        <IconButton
                            sx={{ fontSize: '36px' }}
                            onClick={toggleBookmark}
                        >
                            {isFav && (<StarRoundedIcon fontSize='inherit' sx={{ color: favOrange }}/>)}
                            {!isFav && (<StarBorderRoundedIcon fontSize='inherit' sx={{ color: favOrange }}/>)}
                        </IconButton>
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={qualito.creation}
                            id='creation'
                            label={i18n.creationDate}
                            max={qualito.close}
                            onChange={(_, v) => onChangeQualito({ creation: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={qualito.close}
                            id='close'
                            label={i18n.closeDate}
                            min={qualito.creation}
                            onChange={(_, v) => onChangeQualito({ close: v })}
                            style={{ padding: 0 }}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={parseInt(qualito?.stationType)}
                            label={i18n.stationTypeLabel}
                            options={STATION_QUALITOMETER_NAMES}
                            onChange={v => {
                                const frType = v !== 1 && v !== 2 ? {
                                    frType: null,
                                    her1: null,
                                    her2: null,
                                    strahlerRank: null,
                                } : {}
                                onChangeQualito({ stationType: !isNil(v) && v.toString() || '', ...frType })
                            }}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.qualitometerType}
                            label={i18n.qualitometerType}
                            options={qualitometerTypes}
                            onChange={v => onChangeQualito({ qualitometerType: v })}
                            integerValue
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.representativenessCode}
                            label={i18n.representiveness}
                            options={qualityRepresentativness}
                            onChange={v => onChangeQualito({ representativenessCode: v })}
                            integerValue
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.waterSourceType}
                            label={i18n.type}
                            options={waterSourceTypes}
                            onChange={v => onChangeQualito({ waterSourceType: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.qualityCode}
                            label={i18n.qualityCode}
                            options={qualities}
                            onChange={v => onChangeQualito({ qualityCode: v })}
                            integerValue
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.stateCode}
                            label={i18n.state}
                            options={stationStates}
                            onChange={v => onChangeQualito({ stateCode: v })}
                            integerValue
                            readMode={isReadMode}
                        />
                    </Grid2>
                    {isUndergroundWater(qualito) && (
                        <Grid2 size={6}>
                            <Select
                                value={qualito.nature}
                                label={i18n.nature}
                                options={natures}
                                integerValue
                                onChange={v => onChangeQualito({ nature: v })}
                                readMode={isReadMode}
                            />
                        </Grid2>
                    )}
                    <Grid2 size={6}>
                        <Select
                            value={qualito.fieldMode}
                            label={i18n.fieldMode}
                            options={fieldModes}
                            integerValue
                            onChange={v => onChangeQualito({ fieldMode: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Input
                            value={qualito.finality}
                            title={i18n.finality}
                            onChange={v => onChangeQualito({ finality: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            value={qualito.comment}
                            title={i18n.comment}
                            onChange={v => onChangeQualito({ comment: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

DescriptionCard.propTypes = {
    qualitometer: PropTypes.shape({}),
    setQualitometer: PropTypes.func,
    isReadMode: PropTypes.bool,
}

const ContextCard = ({
    qualitometer = {},
    setQualitometer = () => {},
    isReadMode = true,
}) => {
    const {
        aquifers,
        watersheds,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
        watersheds: store.WatershedReducer.watersheds,
    }), shallowEqual)

    const lithologyType = useSandreList(SANDRE.LITHOLOGY_TYPE)
    const listHer1 = useSandreList(SANDRE.QUALITOMETRES_HER1)
    const listHer2 = useSandreList(SANDRE.QUALITOMETRES_HER2)
    const strahlerRank = useSandreList(SANDRE.QUALITOMETRES_RANG_STRAHLER)
    const frTypeRiver = useSandreList(SANDRE.QUALITOMETRES_TYPE_FR_RIVIERE)
    const frTypePlan = useSandreList(SANDRE.QUALITOMETRES_TYPE_FR_PLAN)

    const [qualito, setQualito] = useState(qualitometer)
    const shouldBouce = useRef(false)

    useUpdateEffect(() => {
        if (isEqual(qualitometer, qualito)) return
        setQualito(qualitometer)
        shouldBouce.current = false
    }, [qualitometer])

    useDebounce(() => {
        if (shouldBouce.current) {
            setQualitometer(qualito)
        }
    }, 500, [qualito])

    const qualitoType = parseInt(qualito?.stationType)

    const onChangeQualito = obj => {
        setQualito(prev => ({ ...prev, ...obj }))
        shouldBouce.current = true
    }

    return (
        <Card id='context_card'>
            <CardTitle title={i18n.context} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12} sx={{ marginBottom: '10px' }}>
                        <StationWatermassPanel
                            station={qualito}
                            onChange={onChangeQualito}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    {isUndergroundWater(qualito) && (
                        <Grid2 size={12}>
                            <Select
                                value={qualito.link_aquifers[0] && qualito.link_aquifers[0].aquiferCode}
                                label={i18n.aquifer}
                                options={aquifers}
                                displayWithCode
                                onChange={v => onChangeQualito({ link_aquifers: [{ idStation: qualito.id, aquiferCode: v }] })}
                                readMode={isReadMode}
                            />
                        </Grid2>

                    )}
                    <Grid2 size={6}>
                        <Select
                            value={qualito.watershed}
                            label={i18n.watershed}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeQualito({ watershed: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.watershed2}
                            label={i18n.watershed2}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeQualito({ watershed2: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.watershed3}
                            label={i18n.watershed3}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeQualito({ watershed3: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={qualito.watershed4}
                            label={i18n.watershed4}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeQualito({ watershed4: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    {(qualitoType === 1 || qualitoType === 2) && (
                        <>
                            <Grid2 size={6}>
                                <Select
                                    value={qualito.her1}
                                    label={i18n.her}
                                    options={listHer1}
                                    keyValue='id'
                                    onChange={v => onChangeQualito({ her1: v })}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={qualito.her2}
                                    label={i18n.her2}
                                    options={listHer2}
                                    keyValue='id'
                                    onChange={v => onChangeQualito({ her2: v })}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={qualito.strahlerRank}
                                    label={i18n.strahlerRank}
                                    options={strahlerRank}
                                    keyValue='id'
                                    onChange={v => onChangeQualito({ strahlerRank: v })}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                            {qualitoType === 1 && (
                                <Grid2 size={6}>
                                    <Select
                                        value={qualito.frType}
                                        label={i18n.frType}
                                        options={frTypeRiver}
                                        keyValue='id'
                                        onChange={v => onChangeQualito({ frType: v })}
                                        readMode={isReadMode}
                                    />
                                </Grid2>
                            )}
                            {qualitoType === 2 && (
                                <Grid2 size={6}>
                                    <Select
                                        value={qualito.frType}
                                        label={i18n.frType}
                                        options={frTypePlan}
                                        keyValue='id'
                                        onChange={v => onChangeQualito({ frType: v })}
                                        readMode={isReadMode}
                                    />
                                </Grid2>
                            )}
                            {[1, 3].includes(qualitoType) && (
                                <Grid2 size={6}>
                                    <Input
                                        value={qualito.river}
                                        title={i18n.river}
                                        onChange={v => onChangeQualito({ river: v })}
                                        readMode={isReadMode}
                                    />
                                </Grid2>
                            )}
                        </>
                    )}
                    {isUndergroundWater(qualito) && (
                        <Grid2 size={12}>
                            <Select
                                value={qualito.link_lithologicType[0] && qualito.link_lithologicType[0].lithologyType}
                                label={i18n.lithologyType}
                                options={lithologyType}
                                nullLabel='&nbsp;'
                                onChange={v => onChangeQualito({ link_lithologicType: [{ idStation: qualito.id, lithologyType: v }] })}
                                readMode={isReadMode}
                            />
                        </Grid2>
                    )}
                    {isUndergroundWater(qualito) && (
                        <>
                            <Grid2 size={12}>
                                <StationLithologyPanel
                                    station={qualito}
                                    onChange={onChangeQualito}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <StationHydrogeologicalEntityPanel
                                    station={qualito}
                                    onChange={onChangeQualito}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </CardContent>
        </Card>
    )
}

ContextCard.propTypes = {
    qualitometer: PropTypes.shape({}),
    setQualitometer: PropTypes.func,
    isReadMode: PropTypes.bool,
}

const TechnicalCharacteristicCard = ({
    qualitometer = {},
    setQualitometer = () => {},
    isReadMode = true,
}) => {
    const [qualito, setQualito] = useState(qualitometer)
    const shouldBouce = useRef(false)

    useUpdateEffect(() => {
        if (isEqual(qualitometer, qualito)) return
        setQualito(qualitometer)
        shouldBouce.current = false
    }, [qualitometer])

    useDebounce(() => {
        if (shouldBouce.current) {
            setQualitometer(qualito)
        }
    }, 500, [qualito])

    const work = qualito.link_work?.[0] ?? {}

    const setWork = obj => {
        shouldBouce.current = true
        setQualito(prev => ({
            ...prev,
            link_work: [{
                ...work,
                idStation: qualitometer.id,
                ...obj,
            }],
        }))
    }

    return (
        <Card id='technical_Characteristics' >
            <CardTitle title={i18n.technicalCharacteristics} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.stationType}
                            value={work.stationType}
                            onChange={v => setWork({ stationType: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.phone}
                            value={work.phone}
                            onChange={v => setWork({ phone: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.sensorType}
                            value={work.sensorType}
                            onChange={v => setWork({ sensorType: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.serialNumber}
                            value={work.serialNumber}
                            onChange={v => setWork({ serialNumber: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title={i18n.workDepth}
                            value={work.workDepth}
                            onChange={v => setWork({ workDepth: v })}
                            readMode={isReadMode}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title={i18n.sensorDepth}
                            value={work.sensorDepth}
                            onChange={v => setWork({ sensorDepth: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.internalDiameter}
                            value={work.internalDiameter}
                            onChange={v => setWork({ internalDiameter: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.externalDiameter}
                            value={work.externalDiameter}
                            onChange={v => setWork({ externalDiameter: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            value={work.comment}
                            onChange={v => setWork({ comment: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    {
                        isUndergroundWater(qualito) && (
                            <Grid2 size={12}>
                                <StationCasingPanel
                                    className='blue'
                                    station={qualito}
                                    onChange={obj => {
                                        setQualito(prev => ({ ...prev, ...obj }))
                                        shouldBouce.current = true
                                    }}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                        )
                    }
                </Grid2>
            </CardContent>
        </Card>
    )
}

TechnicalCharacteristicCard.propTypes = {
    qualitometer: PropTypes.shape({}),
    setQualitometer: PropTypes.func,
    isReadMode: PropTypes.bool,
}

const LocationCard = ({
    qualitometer = {},
    setQualitometer = () => {},
    isReadMode = true,
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const projections = useSandreList(SANDRE.PROJECTION)

    const [qualito, setQualito] = useState(qualitometer)
    const shouldBouce = useRef(false)

    useUpdateEffect(() => {
        if (isEqual(qualitometer, qualito)) return
        setQualito(qualitometer)
        shouldBouce.current = false
    }, [qualitometer])

    useDebounce(() => {
        if (shouldBouce.current) {
            setQualitometer(qualito)
        }
    }, 500, [qualito])

    const geoData = qualito.link_geo?.[0] ?? {}

    const setGeoData = obj => {
        shouldBouce.current = true
        setQualito(prev => ({
            ...prev,
            link_geo: [{
                ...geoData,
                idStation: qualitometer.id,
                ...obj,
            }],
        }))
    }

    const setLocalisation = obj => {
        shouldBouce.current = true
        setQualito(prev => ({
            ...prev,
            ...obj,
            localisation: {
                ...qualito.localisation,
                ...obj,
            },
        }))
    }

    const onChangeQualito = obj => {
        shouldBouce.current = true
        setQualito(prev => ({ ...prev, ...obj }))
    }

    return (
        <Card id='location_card'>
            <CardTitle title={i18n.location} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12}>
                        <Input
                            title={i18n.address}
                            value={qualito.address}
                            onChange={v => onChangeQualito({ location: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Select
                            value={qualito.townCode}
                            label={i18n.city}
                            options={cities}
                            keyValue='id'
                            displayWithCode
                            onChange={v => onChangeQualito({ townCode: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    {
                        (qualito.declarationTownCode !== qualito.townCode || !isReadMode) && (
                            <Grid2 size={12}>
                                <Select
                                    value={qualito.declarationTownCode}
                                    label={i18n.declarationTownCode}
                                    options={cities}
                                    keyValue='id'
                                    displayWithCode
                                    onChange={v => onChangeQualito({ declarationTownCode: v })}
                                    readMode={isReadMode}
                                />
                            </Grid2>
                        )
                    }
                    <Grid2 size={12}>
                        <Input
                            value={qualito.countryCode}
                            title={i18n.countryCode}
                            onChange={v => onChangeQualito({ countryCode: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            noMargin={false}
                            classname={!isReadMode && 'margin-bottom-1' || ''}
                            title={i18n.localisation}
                            value={qualito.location}
                            onChange={v => onChangeQualito({ location: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title='X'
                            value={qualito.x}
                            floatValue
                            onChange={v => setLocalisation({ x: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title='Y'
                            value={qualito.y}
                            floatValue
                            onChange={v => setLocalisation({ y: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.projection}
                            value={qualito.projection}
                            options={projections}
                            onChange={v => setLocalisation({ projection: v })}
                            readMode={isReadMode}
                            integerValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={qualito.altitude}
                            title={i18n.altitude}
                            floatValue
                            onChange={v => setLocalisation({ altitude: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.ignMap}
                            value={geoData.ignMap}
                            onChange={v => setGeoData({ ignMap: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.geologicalMap}
                            value={geoData.geologicalMap}
                            onChange={v => setGeoData({ geologicalMap: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.section}
                            value={geoData.parcel}
                            onChange={v => setGeoData({ parcel: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.parcel}
                            value={geoData.section}
                            onChange={v => setGeoData({ section: v })}
                            readMode={isReadMode}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

LocationCard.propTypes = {
    qualitometer: PropTypes.shape({}),
    setQualitometer: PropTypes.func,
    isReadMode: PropTypes.bool,
}

const QualitometerDescriptionPanel = ({
}) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const {
        qualitometer,
        qualitometers,
        typeEnvironmentModels,
        accountUser,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [qualito, setQualito] = useState(qualitometer)
    const [isReadMode, setReadMode] = useState(id !== NEW)
    const [synchronizeOption, setSynchronizeOption] = useState({})

    const setQualitometer = obj => {
        setQualito(p => ({ ...p, ...obj }))
    }

    useEffect(() => {
        setQualito({ ...qualitometer })
    }, [qualitometer])

    useEffect(() => {
        dispatch(StationAction.fetchNatures())
        dispatch(StationAction.fetchQualities())
        dispatch(StationAction.fetchFieldModes())
        dispatch(StationAction.fetchQualitometerType())
        dispatch(StationAction.fetchTypes())
        dispatch(StationAction.fetchStates())
        dispatch(WatershedAction.fetchWatersheds())
        dispatch(UserAction.fetchBookmarks())
        dispatch(AquiferAction.fetchAquifers())
        dispatch(WatermassAction.fetchWatermasses())
        dispatch(NetworkAction.fetchNetworks())
    }, [dispatch])

    useActions(() => {
        const baseActions = {
            exportmodel: () => ({
                stationId: id,
                stationCode: qualitometer.code,
                stationType: qualitometer.typeName,
                environmentModels: typeEnvironmentModels,
            }),
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer.id, s => dispatch(push(`/station/quality/${s.id}/description`))),
        }
        const additionalActions = {
            referencialActions: {
                actions: {
                    sync: {
                        updateAction: () => dispatch(QualityAction.synchronizeDescription(qualitometer.id, synchronizeOption)),
                        webServiceContent: (
                            <QualitySyncOptions
                                setSynchronizeOption={obj => setSynchronizeOption(p => ({ ...p, synchronizeOption, ...obj }))}
                            />
                        ),
                    },
                },
            },
        }
        if (accountUser.consultant === '1') {
            return {
                ...baseActions,
                ...additionalActions,
            }
        }
        if (isReadMode) {
            return {
                ...baseActions,
                ...additionalActions,
                edit: () => setReadMode(false),
                delete: () => dispatch(QualityAction.deleteQualitometer(qualitometer.id)).then(() => dispatch(push('/quality'))),
            }
        }
        return {
            ...baseActions,
            save: () => {
                if (!qualito.code) {
                    dispatch(ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code })))
                } else if (id === NEW && qualitometers.find(q => q.code === qualitometer.code)) {
                    dispatch(ToastrAction.error(i18n.bssCodeAlreadyExists))
                } else {
                    dispatch(QualityAction.updateQualitometer(qualito, () => setReadMode(true)))
                }
            },
            cancel: () => {
                if (id === NEW) {
                    dispatch(push('/quality'))
                } else {
                    setQualito(qualitometer)
                    setReadMode(true)
                }
            },
        }
    }, [isReadMode, id, qualitometer, qualito, qualitometers, accountUser, typeEnvironmentModels, synchronizeOption])

    return (
        <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
            <Grid2 container size={9}>
                <Grid2 size={12}>
                    <DescriptionCard
                        qualitometer={qualito}
                        setQualitometer={setQualitometer}
                        isReadMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <ContextCard
                        qualitometer={qualito}
                        setQualitometer={setQualitometer}
                        isReadMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationContributorPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <QualitySamplePointsPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationNetworkPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationMeasureMethodPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <TechnicalCharacteristicCard
                        qualitometer={qualito}
                        setQualitometer={setQualitometer}
                        isReadMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationFunctionPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationUsagePanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationArrangementPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationAccessibilitiesPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationContactsPanel
                        station={qualito}
                        onChange={setQualitometer}
                        readMode={isReadMode}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container size={3}>
                <Grid2 size={12}>
                    <StationUpdatePanel station={qualito} />
                </Grid2>
                <Grid2 size={12}>
                    <ContactCard
                        id='contact_card'
                        title={i18n.contact}
                        contactCode={qualito.contactCode}
                        onChange={v => setQualitometer({ contactCode: v })}
                        readMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationMapDashboardPanel
                        noMarkerTooltip
                        station={qualito}
                        type={'quality'}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <LocationCard
                        qualitometer={qualito}
                        setQualitometer={setQualitometer}
                        isReadMode={isReadMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <LinkedStationsPanel
                        noMargin={false}
                        station={qualito}
                        editMode={!isReadMode}
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

export default QualitometerDescriptionPanel
