/* eslint-disable camelcase */

export default class DtoPgsseDiagnosticWithStats {
    constructor(obj) {
        this.idDiag = obj.idDiag // Option[Long],
        this.idContributor = obj.idContributor // Option[String],
        this.idSector = obj.idSector // Option[Long],
        this.idUdi = obj.idUdi // Option[Long],
        this.nbCampaigns = obj.nbCampaigns // Option[Int],
        this.nbForms = obj.nbForms // Option[Int],
        this.nbOtherDiags = obj.nbOtherDiags // Option[DateTime],
        this.nbDocuments = obj.nbDocuments // Option[DateTime],
        this.nbActivities = obj.nbActivities // Option[String]
    }
}


