import { Button, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Card from 'components/card/Card'
import { ALIGN, CardTable } from 'components/datatable/NewTable'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { isNil, sumBy } from 'lodash'
import HydrobioAction from 'quality/actions/HydrobioAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import i18n from 'simple-react-i18n'
import useBoolean from 'utils/customHook/useBoolean'
import useSandreList from 'utils/customHook/useSandreList'
import useSandreListIndexed from 'utils/customHook/useSandreListIndexed'
import { useShallowEqualSelector } from 'utils/customHook/useShallowEqualSelector'
import PropTypes from 'prop-types'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import ConfirmModal from 'components/modal/ConfirmModal'

const SampleDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    selectedSample,
}) => {
    const {
        hydrobioOperation,
    } = useSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const sandreFacies = useSandreList('PRELEVEMENTS.FACIES')
    const sandreDiatoms = useSandreList('PRELEVEMENTS.DIATOMEES')
    const sandreSealingType = useSandreList('PRELEVEMENTS.TYPECOLMATAGE')
    const sandreHeight = useSandreList('PRELEVEMENTS.HAUTEUR')
    const sandreMaterial = useSandreList('PRELEVEMENTS.MATERIELS')
    const sandreShade = useSandreList('PRELEVEMENTS.OMBRAGE')
    const sandreSealing = useSandreList('PRELEVEMENTS.COLMATAGE')
    const sandreSpeed = useSandreList('PRELEVEMENTS.VITESSE')
    const sandrePhase = useSandreList('PRELEVEMENTS.PHASE')
    const sandreSubstrat = useSandreList('SUBSTRAT')
    const sandreStability = useSandreList('PRELEVEMENTS.STABILITE')

    const [sample, setSample] = useState({})

    useEffect(() => {
        if (!isOpen) return
        setSample(selectedSample ?? {})
    }, [isOpen, selectedSample])

    const formattedSandreHeight = useMemo(() => {
        return sandreHeight.map(h => ({
            ...h,
            name: `${h.name} (${h.comment})`,
        }))
    }, [sandreHeight])

    const formattedSandreSubstrat = useMemo(() => {
        return sandreSubstrat.map(h => ({
            ...h,
            name: `${h.name} [${h.mnemonique}]`,
        }))
    }, [sandreSubstrat])

    const formattedSandreMaterial = useMemo(() => {
        return sandreMaterial.map(h => ({
            ...h,
            name: `${h.name} [${h.mnemonique}]`,
        }))
    }, [sandreMaterial])

    return (
        <Dialog
            maxWidth='lg'
            onClose={onClose}
            open={isOpen}
        >
            <DefaultDialogTitle
                title={i18n.sample}
                onClose={onClose}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container spacing={1} alignItems='center'>
                            {hydrobioOperation.support === 13 && (
                                <>
                                    <Grid2 size={2}>
                                        <Input
                                            title={i18n.sample}
                                            maxLength={10}
                                            value={sample.sampleCode}
                                            onChange={v => setSample(p => ({ ...p, sampleCode: v }))}
                                        />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <Select
                                            label={i18n.phase}
                                            options={sandrePhase}
                                            value={sample.phase}
                                            onChange={v => setSample(p => ({ ...p, phase: v }))}
                                        />
                                    </Grid2>
                                </>
                            )}
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.substrate}
                                    options={formattedSandreSubstrat}
                                    value={sample.substrateCode}
                                    onChange={v => setSample(p => ({ ...p, substrateCode: v }))}
                                    keyLabel='name'
                                    keyValue='mnemonique'
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.secondSubstrate}
                                    options={formattedSandreSubstrat}
                                    value={sample.secondSubstrateCode}
                                    onChange={v => setSample(p => ({ ...p, secondSubstrateCode: v }))}
                                    keyLabel='name'
                                    keyValue='mnemonique'
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.speedClass}
                                    options={sandreSpeed}
                                    value={sample.speedClass}
                                    onChange={v => setSample(p => ({ ...p, speedClass: v }))}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.height}
                                    options={formattedSandreHeight}
                                    value={sample.waterHeight}
                                    onChange={v => setSample(p => ({ ...p, waterHeight: v }))}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <NumberField
                                    title={`${i18n.distanceShore} (m)`}
                                    value={sample.distanceShore}
                                    onChange={v => setSample(p => ({ ...p, distanceShore: v }))}
                                    floatValue
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.material}
                                    options={formattedSandreMaterial}
                                    value={sample.material}
                                    onChange={v => setSample(p => ({ ...p, material: v }))}
                                    keyLabel='name'
                                    keyValue='mnemonique'
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.sealingType}
                                    options={sandreSealingType}
                                    value={sample.sealingType}
                                    onChange={v => setSample(p => ({ ...p, sealingType: v }))}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.sealingIntensity}
                                    options={sandreSealing}
                                    value={sample.sealing}
                                    onChange={v => setSample(p => ({ ...p, sealing: v }))}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input
                                    title={i18n.vegetation}
                                    value={sample.vegetation}
                                    onChange={v => setSample(p => ({ ...p, vegetation: v }))}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.shade}
                                    options={sandreShade}
                                    value={sample.shade}
                                    onChange={v => setSample(p => ({ ...p, shade: v }))}
                                />
                            </Grid2>
                            {hydrobioOperation.support === 13 && (
                                <Grid2 size={4}>
                                    <Select
                                        label={i18n.stability}
                                        options={sandreStability}
                                        value={sample.stability}
                                        onChange={v => setSample(p => ({ ...p, stability: v }))}
                                    />
                                </Grid2>
                            )}
                            {hydrobioOperation.support === 10 && (
                                <>
                                    <Grid2 size={4}>
                                        <Select
                                            label={i18n.morphologicalFacies}
                                            options={sandreFacies}
                                            value={sample.secondaryFacies}
                                            onChange={v => setSample(p => ({ ...p, secondaryFacies: v }))}
                                        />
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <Select
                                            label={i18n.diatoms}
                                            options={sandreDiatoms}
                                            value={sample.diatoms}
                                            onChange={v => setSample(p => ({ ...p, diatoms: v }))}
                                        />
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <NumberField
                                            title={i18n.nbSupports}
                                            value={sample.nbSupports}
                                            onChange={v => setSample(p => ({ ...p, nbSupports: v }))}
                                        />
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <Input
                                            title={i18n.blades}
                                            value={sample.blade}
                                            onChange={v => setSample(p => ({ ...p, blade: v }))}
                                            maxLength={12}
                                        />
                                    </Grid2>
                                </>
                            )}
                            <Grid2 size={12}>
                                <Textarea
                                    title={i18n.comment}
                                    value={sample.comment}
                                    onChange={v => setSample(p => ({ ...p, comment: v }))}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(sample)} variant='contained' data-cy='validate_sdage'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SampleDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    selectedSample: PropTypes.shape({}),
}

const SampleTable = () => {
    const dispatch = useDispatch()
    const {
        hydrobioOperation,
        operationSamples,
    } = useShallowEqualSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
        operationSamples: store.OperationReducer.operationSamples,
    }), shallowEqual)

    const indexedSandreFacies = useSandreListIndexed('PRELEVEMENTS.FACIES')
    const indexedSandreDiatoms = useSandreListIndexed('PRELEVEMENTS.DIATOMEES')
    const indexedSandreSealingType = useSandreListIndexed('PRELEVEMENTS.TYPECOLMATAGE')
    const indexedSandreHeight = useSandreListIndexed('PRELEVEMENTS.HAUTEUR')
    const indexedSandreMaterial = useSandreListIndexed('PRELEVEMENTS.MATERIELS', 'mnemonique')
    const indexedSandreShade = useSandreListIndexed('PRELEVEMENTS.OMBRAGE')
    const indexedSandreSealing = useSandreListIndexed('PRELEVEMENTS.COLMATAGE')
    const indexedSandreSpeed = useSandreListIndexed('PRELEVEMENTS.VITESSE')
    const indexedSandrePhase = useSandreListIndexed('PRELEVEMENTS.PHASE')
    const indexedSandreSubstrat = useSandreListIndexed('SUBSTRAT', 'mnemonique')
    const indexedSandreStability = useSandreListIndexed('PRELEVEMENTS.STABILITE')

    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)
    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)
    const [selectedSample, setSelectedSample] = useState()

    const sampleHeaders = useMemo(() => {
        if (hydrobioOperation.support === 10) { // Diatomées
            return ['substrate', 'secondSubstrate', 'speedClass', 'height', 'distanceShore', 'sealingType', 'sealingIntensity', 'material', 'vegetation', 'shade', 'morphologicalFacies', 'diatoms', 'nbSupports', 'blades', 'comment']
        }
        // Macroinvertébrés
        return [{ key: 'sample', label: i18n.sample, sticky: ALIGN.LEFT }, 'phase', 'substrate', 'secondSubstrate', 'speedClass', 'height', 'distanceShore', 'sealingType', 'sealingIntensity', 'material', 'vegetation', 'shade', 'stability', 'comment']
    }, [hydrobioOperation])

    const formattedOperationSamples = useMemo(() => {
        return operationSamples.map(os => {
            const height = indexedSandreHeight[os.waterHeight]
            const substrate = indexedSandreSubstrat[os.substrateCode]
            const secondSubstrate = indexedSandreSubstrat[os.secondSubstrateCode]
            return {
                sample: os.sampleCode,
                phase: indexedSandrePhase[os.phase]?.name,
                substrate: !isNil(substrate) ? `${substrate.name} [${os.substrateCode}]` : undefined,
                secondSubstrate: !isNil(secondSubstrate) ? `${secondSubstrate.name} [${os.secondSubstrateCode}]` : undefined,
                speedClass: indexedSandreSpeed[os.speedClass]?.name,
                height: !isNil(height) ? `${height.comment} [${height.name}]` : undefined,
                distanceShore: !isNil(os.distanceShore) ? `${os.distanceShore} m` : undefined,
                sealingType: indexedSandreSealingType[os.sealingType]?.name,
                sealingIntensity: indexedSandreSealing[os.sealing]?.name,
                material: indexedSandreMaterial[os.material]?.name,
                vegetation: os.vegetation,
                shade: indexedSandreShade[os.shade]?.name,
                morphologicalFacies: indexedSandreFacies[os.secondaryFacies]?.name,
                stability: indexedSandreStability[os.stability]?.name,
                diatoms: indexedSandreDiatoms[os.diatoms]?.name,
                nbSupports: os.nbSupports,
                blades: os.blade,
                comment: os.comment,
                id: os.id,
            }
        })
    }, [indexedSandreDiatoms, indexedSandreFacies, indexedSandreHeight, indexedSandreMaterial, indexedSandrePhase, indexedSandreSealing, indexedSandreSealingType, indexedSandreShade, indexedSandreSpeed, indexedSandreStability, indexedSandreSubstrat, operationSamples])

    const operationSample = operationSamples.find(os => os.id === selectedSample)

    return (
        <>
            <CardTable
                title={i18n.samplesProd}
                actions={[
                    { icon: 'note_add', onClick: onOpen },
                ]}

                rows={formattedOperationSamples}
                headers={sampleHeaders}
                rowsPerPageOptions={[{ value: 100, label: '100' }]}

                lineActions={[
                    {
                        icon: 'delete',
                        onClick: ({ id }) => {
                            setSelectedSample(id)
                            onOpenConfirm()
                        },
                    },
                    {
                        icon: 'edit',
                        onClick: ({ id }) => {
                            setSelectedSample(id)
                            onOpen()
                        },
                    },
                ]}
            />
            <SampleDialog
                isOpen={isOpen}
                onClose={() => {
                    setSelectedSample()
                    onClose()
                }}
                onValidate={sample => {
                    const {
                        qualitometer,
                        id,
                    } = hydrobioOperation
                    const newSample = { qualitometerId: qualitometer, operationId: id, ...sample }
                    const promise = isNil(operationSample) ? HydrobioAction.createOperationSample : HydrobioAction.updateOperationSample
                    dispatch(promise(qualitometer, id, newSample)).then(() => {
                        dispatch(HydrobioAction.fetchOperationSamples(qualitometer, id)).then(() => {
                            setSelectedSample()
                            onClose()
                        })
                    })
                }}
                selectedSample={operationSample}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    const { qualitometerId, operationId, id } = operationSample
                    dispatch(HydrobioAction.deleteOperationSample(qualitometerId, operationId, id)).then(() => {
                        dispatch(HydrobioAction.fetchOperationSamples(qualitometerId, operationId)).then(() => {
                            setSelectedSample()
                            onCloseConfirm()
                        })
                    })
                }}
                onClose={onCloseConfirm}
            />
        </>
    )
}

const COVERS = [
    { substrateCode: 'S1', code: 'A' },
    { substrateCode: 'S2', code: 'B' },
    { substrateCode: 'S3', code: 'C' },
    { substrateCode: 'S28', code: 'D' },
    { substrateCode: 'S24', code: 'E' },
    { substrateCode: 'S30', code: 'F' },
    { substrateCode: 'S9', code: 'G' },
    { substrateCode: 'S10', code: 'H' },
    { substrateCode: 'S11', code: 'I' },
    { substrateCode: 'S25', code: 'J' },
    { substrateCode: 'S18', code: 'K' },
    { substrateCode: 'S29', code: 'L' },
    { substrateCode: 'S31', code: 'M' },
]

const CoverDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    selectedCover = {},
}) => {
    const indexedSandreSubstrat = useSandreListIndexed('SUBSTRAT', 'mnemonique')
    const sandreCoverType = useSandreList('RECOUVREMENT.TYPE')

    const [typeCover, setTypeCover] = useState()
    const [cover, setCover] = useState(0)

    useEffect(() => {
        if (!isOpen) return
        setTypeCover(selectedCover.typeCover)
        setCover(selectedCover.cover)
    }, [isOpen, selectedCover.cover, selectedCover.typeCover])

    const formattedSubstrate = COVERS.map(c => ({
        id: c.substrateCode,
        label: `${indexedSandreSubstrat[c.substrateCode]?.name ?? i18n.unknow} [${c.substrateCode}]`,
    }))

    return (
        <Dialog
            maxWidth='md'
            onClose={onClose}
            open={isOpen}
        >
            <DefaultDialogTitle
                title={i18n.covering}
                onClose={onClose}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container spacing={1} alignItems='center'>
                            <Grid2 size={12}>
                                <Select
                                    options={formattedSubstrate}
                                    label={i18n.substrate}
                                    value={selectedCover.substrateCode}
                                    disabled
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={sandreCoverType}
                                    label={i18n.type}
                                    value={typeCover}
                                    onChange={setTypeCover}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={`${i18n.covering} (%)`}
                                    value={cover}
                                    onChange={setCover}
                                    floatValue
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate({ typeCover: isNil(typeCover) ? undefined : `${typeCover}`, cover, substrateCode: selectedCover.substrateCode })} variant='contained' data-cy='validate_sdage'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

CoverDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    selectedCover: PropTypes.shape({}),
}

const CoverTable = () => {
    const dispatch = useDispatch()
    const {
        hydrobioOperation,
        operationCovers,
    } = useShallowEqualSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
        operationCovers: store.OperationReducer.operationCovers,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)

    const indexedSandreSubstrat = useSandreListIndexed('SUBSTRAT', 'mnemonique')
    const indexedSandreCoverType = useSandreListIndexed('RECOUVREMENT.TYPE')

    const [selectedSubstrate, setSelectedSubstrate] = useState()

    const formattedOperationCovers = useMemo(() => {
        const formattedCovers = COVERS.map(({ substrateCode, code }) => {
            const substrate = indexedSandreSubstrat[substrateCode]
            const oc = operationCovers.find(c => c.substrateCode === substrateCode)
            const typeCover = indexedSandreCoverType[oc?.typeCover]
            return {
                substrate: `${code} - ${substrate?.name ?? i18n.unknown} [${substrateCode}]`,
                type: !isNil(oc?.typeCover) ? `${typeCover?.name ?? i18n.unknown} [${oc?.typeCover}]` : undefined,
                covering: `${oc?.cover ?? 0} %`,
                cover: oc?.cover ?? 0,
                substrateCode,
            }
        })
        const percet = sumBy(formattedCovers, 'cover')
        const sumCover = {
            substrate: i18n.sum,
            covering: {
                value: `${percet} %`,
                color: percet > 100 && '#F44336' || percet !== 100 && '#FF8300' || '#4CAF50',
            },
            lineAction: false,
        }
        return [
            ...formattedCovers,
            sumCover,
        ]
    }, [indexedSandreCoverType, indexedSandreSubstrat, operationCovers])

    const operationCover = operationCovers.find(oc => oc.substrateCode === selectedSubstrate)

    return (
        <>
            <CardTable
                title={i18n.covering}

                rows={formattedOperationCovers}
                headers={['substrate', 'type', { key: 'covering', value: `${i18n.covering} (%)` }]}
                rowsPerPageOptions={[{ value: 100, label: '100' }]}

                lineActions={[
                    {
                        icon: 'edit',
                        onClick: ({ substrateCode }) => {
                            setSelectedSubstrate(substrateCode)
                            onOpen()
                        },
                    },
                ]}
            />
            <CoverDialog
                isOpen={isOpen}
                onClose={onClose}
                onValidate={cover => {
                    const {
                        qualitometer,
                        id,
                    } = hydrobioOperation
                    const newCover = { qualitometerId: qualitometer, operationId: id, id: operationCover?.id, ...cover }
                    const promise = isNil(operationCover) ? HydrobioAction.createOperationCover : HydrobioAction.updateOperationCover
                    dispatch(promise(qualitometer, id, newCover)).then(() => {
                        dispatch(HydrobioAction.fetchOperationCovers(qualitometer, id))
                        onClose()
                    })
                }}
                selectedCover={operationCover ?? { substrateCode: selectedSubstrate }}
            />
        </>
    )
}

const HydrobioSample = () => {
    const dispatch = useDispatch()
    const { id: qualitometerId, idOperation: operationId } = useParams()
    const {
        hydrobioOperation,
    } = useShallowEqualSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    useEffect(() => {
        if (!qualitometerId || !operationId) return
        dispatch(HydrobioAction.fetchOperationSamples(qualitometerId, operationId))
        dispatch(HydrobioAction.fetchOperationCovers(qualitometerId, operationId))
    }, [dispatch])

    return (
        <Card className='no-box-shadow' cardStyle={{ padding: '10' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={12}>
                    <SampleTable />
                </Grid2>
                {hydrobioOperation.support === 13 && (
                    <Grid2 size={12}>
                        <CoverTable />
                    </Grid2>
                )}
            </Grid2>
        </Card>
    )
}

export default HydrobioSample