import { push } from '@lagunovsky/redux-react-router'
import BoundaryError from 'log/components/BoundaryError'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import { H_PGSSE_MODULE } from '../account/constants/AccessRulesConstants'
import FixedSlideNav from '../components/navbar/FixedSlideNav'
import { PATH_DASHBOARD, PATH_PGSSE } from '../home/constants/RouteConstants'
import ReferencialAction from '../referencial/action/ReferencialAction'
import { componentHasHabilitations } from '../utils/HabilitationUtil'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import PgsseAction from './action/PgsseAction'
import InstallationAction from 'installation/actions/InstallationAction'
import CityAction from 'referencial/components/city/actions/CityAction'

const PgsseApp = () => {
    const dispatch = useDispatch()
    const {
        accountUser = [],
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const checkAbillitation = () => {
        if (hasValue(accountUser.login)) {
            if (!componentHasHabilitations(H_PGSSE_MODULE)) {
                dispatch(ToastrAction.error(i18n.AccessRightDeny))
                dispatch(push('/'))
            }
        }
    }

    useEffect(() => {
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(DistributionUnitAction.fetchDistributionUnits())
        dispatch(SectorAction.fetchSectors())
        dispatch(ContributorAction.fetchContributors())
        dispatch(PgsseAction.fetchSurveys())
        dispatch(InstallationAction.fetchInstallationsLight())
        dispatch(InstallationAction.fetchInstallationTypes())
        dispatch(CityAction.fetchCities())
    }, [])

    useEffect(() => {
        checkAbillitation()
    }, [accountUser])

    const getLinks = () => {
        return [
            {
                href: `${PATH_PGSSE}/${PATH_DASHBOARD}`,
                icons: 'dashboard',
                name: i18n.dashboard,
                habilitation: H_PGSSE_MODULE,
            },
            {
                href: `${PATH_PGSSE}/phase1`,
                icons: 'looks_one',
                name: `${i18n.phase}1`,
                habilitation: H_PGSSE_MODULE,
            },
            {
                href: `${PATH_PGSSE}/phase2`,
                icons: 'looks_two',
                name: `${i18n.phase}2`,
                habilitation: H_PGSSE_MODULE,
            },
            {
                href: `${PATH_PGSSE}/phase3`,
                icons: 'looks_3',
                name: `${i18n.phase}3`,
                habilitation: H_PGSSE_MODULE,
            },
            {
                href: `${PATH_PGSSE}/phase4`,
                icons: 'looks_4',
                name: `${i18n.phase}4`,
                habilitation: H_PGSSE_MODULE,
            },
        ]
    }

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={getLinks()} />
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PgsseApp
