/* eslint-disable react-hooks/exhaustive-deps */
import Calculate from 'components/actions/Calculate'
import CalculateIndexes from 'components/actions/CalculateIndice'
import Cancel from 'components/actions/Cancel'
import Clear from 'components/actions/Clear'
import Delete from 'components/actions/Delete'
import DeleteCheck from 'components/actions/DeleteCheck'
import Dropdown from 'components/actions/Dropdown'
import Duplicate from 'components/actions/Duplicate'
import Edit from 'components/actions/Edit'
import Email from 'components/actions/Email'
import Export from 'components/actions/Export'
import ExportChoice from 'components/actions/ExportChoice'
import ExportList from 'components/actions/ExportList'
import ExportModel from 'components/actions/ExportModel'
import FastArrowNav from 'components/actions/FastArrowNav'
import Import from 'components/actions/Import'
import ImportFile from 'components/actions/ImportFile'
import LanguagesList from 'components/actions/LanguagesList'
import Links from 'components/actions/Links'
import Loading from 'components/actions/Loading'
import New from 'components/actions/New'
import PdfList from 'components/actions/PdfList'
import Play from 'components/actions/Play'
import Purge from 'components/actions/Purge'
import Replace from 'components/actions/Replace'
import Save from 'components/actions/Save'
import Shared from 'components/actions/Shared'
import Update from 'components/actions/Update'
import UpdateReferencial from 'components/actions/UpdateReferencial'
import Validate from 'components/actions/Validate'
import VisitLinks from 'components/actions/VisitLinks'
import HomeAction from 'home/actions/HomeAction'
import { ControlAnalysisAction } from 'quality/components/control/ControlAnalysis'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

const useActions = (callback, dependencies = []) => {
    const dispatch = useDispatch()

    const actionsMemoize = useMemo(callback, dependencies)

    const onClickAction = useCallback(onClick => {
        $('.tooltipped').tooltip('remove')
        onClick()
    }, [])

    const addAction = useCallback((key, arrayActions, actions) => {
        switch (key) {
            case 'languages':
                arrayActions[28] = (
                    <LanguagesList
                        onClick={(e) => {
                            $('.tooltipped').tooltip('remove')
                            actions[key](e)
                        }}
                    />
                )
                break
            case 'email':
                arrayActions[27] = <Email onSend={() => onClickAction(actions[key])} />
                break
            case 'dropdown':
                arrayActions[26] = <Dropdown {...actions[key]} />
                break
            case 'referencialActions':
                arrayActions[25] = (
                    <UpdateReferencial
                        actions={actions[key].actions}
                    />
                )
                break
            case 'visitLinks':
                arrayActions[24] = <VisitLinks links={actions[key]} />
                break
            case 'pdf':
                arrayActions[23] = <PdfList pdfList={actions[key]} />
                break
            case 'exportList':
                arrayActions[22] = <ExportList exportList={actions[key]} />
                break
            case 'deleteCheck':
                arrayActions[21] = <DeleteCheck onDelete={() => onClickAction(actions[key])} />
                break
            case 'duplicate':
                arrayActions[20] = <Duplicate onDuplicate={() => onClickAction(actions[key])} />
                break
            case 'arrowNav':
                arrayActions[19] = <FastArrowNav nav={actions[key]} />
                break
            case 'edit':
                arrayActions[18] = <Edit onEdit={() => onClickAction(actions[key])} />
                break
            case 'clear':
                arrayActions[17] = <Clear onClear={() => onClickAction(actions[key])} />
                break
            case 'cancel':
                arrayActions[16] = <Cancel onCancel={() => onClickAction(actions[key])} />
                break
            case 'validate':
                arrayActions[15] = <Validate onValidate={() => onClickAction(actions[key])} />
                break
            case 'save':
                arrayActions[14] = <Save onSave={() => onClickAction(actions[key])} />
                break
            case 'delete':
                arrayActions[13] = <Delete onDelete={() => onClickAction(actions[key])} />
                break
            case 'new':
                arrayActions[12] = <New onNew={() => onClickAction(actions[key])} />
                break
            case 'replace':
                arrayActions[11] = <Replace onReplace={() => onClickAction(actions[key])} />
                break
            case 'update':
                arrayActions[10] = <Update onUpdate={() => onClickAction(actions[key])} />
                break
            case 'loading':
                arrayActions[9] = <Loading loads={actions[key]} />
                break
            case 'purge':
                arrayActions[8] = <Purge onPurge={() => onClickAction(actions[key])} />
                break
            case 'links':
                arrayActions[7] = <Links links={actions[key]} />
                break
            case 'exportChoice':
                arrayActions[6] = <ExportChoice data={actions[key]} />
                break
            case 'exportmodel':
                arrayActions[5] = <ExportModel onExport={actions[key]} />
                break
            case 'export':
                arrayActions[4] = <Export onExport={actions[key]} />
                break
            case 'import':
                arrayActions[3] = <Import onClick={actions[key]} />
                break
            case 'importFile':
                arrayActions[2] = <ImportFile {...actions[key]} />
                break
            case 'share':
                arrayActions[1] = <Shared links={actions[key]} />
                break
            case 'calculate':
                arrayActions[0] = <Calculate operationIds={actions[key]}/>
                break
            case 'calculateIndexes':
                arrayActions[29] = <CalculateIndexes {...actions[key]}/>
                break
            case 'controlAnalysis':
                arrayActions[30] = <ControlAnalysisAction filters={actions[key]}/>
                break
            case 'play':
                arrayActions[31] = <Play onPlay={() => onClickAction(actions[key])} />
                break
            default:
                arrayActions.unshift(actions[key])
                break
        }
    }, [onClickAction])

    useEffect(() => {
        const arrayActions = new Array(31)
        const keys = Object.keys(actionsMemoize)
        keys.filter(k => k !== 'other').forEach((key) => {
            addAction(key, arrayActions, actionsMemoize)
        })
        if (actionsMemoize.other) {
            Object.keys(actionsMemoize.other).forEach((key) => {
                addAction(key, arrayActions, actionsMemoize.other)
            })
        }
        dispatch(HomeAction.setActions(arrayActions))
    }, [actionsMemoize, addAction, dispatch])

    useEffect(() => { // clean actions
        return () => dispatch(HomeAction.setActions([]))
    }, [])
}

export default useActions